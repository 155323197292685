<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;" >
        
        <v-card-text>
                      
                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="pagamentoliberado" data-cy="pagamentoliberado"> </v-switch>
                        <label style="font-size:18px;">Pagamento Liberado?</label>
                    </div>
                </div>

                <div style="overflow:auto;margin-top:20px;margin-bottom:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                        <label for="emailliberado" style="font-size:18px;">Restringir ao Email abaixo:</label>
                        <input id="emailliberado" v-model="emailliberado" data-cy="emailliberado" maxlength="50" type="text"  placeholder="Digite um Email para Restringir" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                    </div>
                </div>

               
                
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>
        
        </v-card-text>

    </v-card>
</template>

<script>
  
  export default {
    name: 'ValidarEmailHospedeFake',
    components: {
      
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        pagamentoliberado:true,
        emailliberado:null,
    }),
    methods:{
        salvar(){

            let parametro = {
                    chave:'PAGAMENTOS',
                    valor:this.pagamentoliberado ? 'true' : 'false',
                    valor2:this.emailliberado
                }

            this.$http.put('pdv/updateparametros',parametro)
            .then(() =>{
                
                this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
               
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })
            
            // this.$emit('salvar',obj)
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'PAGAMENTOS'})
            .then(resp =>{
                this.pagamentoliberado = resp.data[0].valor === 'true'? true : false
                this.emailliberado = resp.data[0].valor2
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>