var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000","max-width":"80vw"},model:{value:(_vm.modalaberto),callback:function ($$v) {_vm.modalaberto=$$v},expression:"modalaberto"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-8",attrs:{"color":"#1976d2"}},[_c('v-flex',{staticClass:"pa-2",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"width":"95%","color":"white","font-weight":"bold"}},[_vm._v(" Itens do pedido ")]),_c('span',{staticStyle:{"width":"5%"}},[_c('v-btn',{attrs:{"x-small":"","color":"red","title":"Fechar"},on:{"click":function($event){return _vm.fechar()}}},[_c('v-icon',{staticStyle:{"color":"white"},attrs:{"x-small":""}},[_vm._v("mdi-close")])],1)],1)])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itens,"items-per-page":10},scopedSlots:_vm._u([{key:"item.codprod",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.codprod))])]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.descricao))])]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.data))])]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.preco.toFixed(2)))])]}},{key:"item.quant",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.quant))])]}},{key:"item.valortotal",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s((parseFloat(item.preco) * parseFloat(item.quant)).toFixed(2)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }