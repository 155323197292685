var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"large":"","elevation":"","color":"primary"},on:{"click":function($event){return _vm.voltar()}}},[_vm._v(" VOLTAR ")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"item-text":"regra","item-value":"id_comissao_regra","items":_vm.comissoes,"filled":"","label":"Regras"},on:{"change":function($event){return _vm.recarregarProdutos()}},model:{value:(_vm.id_comissao_regra),callback:function ($$v) {_vm.id_comissao_regra=$$v},expression:"id_comissao_regra"}})],1)],1),_vm._m(0),_c('div',{staticClass:"es-painel"},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.pdvsNaRegra,"item-text":"pdv","item-value":"idpdv","label":"PDV"},model:{value:(_vm.idpdvNaRegra),callback:function ($$v) {_vm.idpdvNaRegra=$$v},expression:"idpdvNaRegra"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.catNaRegra,"item-text":"categoria","item-value":"idcategoria","label":"Categoria"},model:{value:(_vm.idCatNaRegra),callback:function ($$v) {_vm.idCatNaRegra=$$v},expression:"idCatNaRegra"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.subCatNaRegra,"item-text":"subcategoria","item-value":"idsubcategoria","label":"Subcategoria"},model:{value:(_vm.idSubCatNaRegra),callback:function ($$v) {_vm.idSubCatNaRegra=$$v},expression:"idSubCatNaRegra"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.cabsNaRegra,"items":_vm.produtosNaRegraFiltrado,"item-key":"item_key","show-select":"","dense":""},scopedSlots:_vm._u([{key:"item.produto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.produto) + " (" + (item.codprodutopms) + ")")))])]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.preco)))])]}}],null,true),model:{value:(_vm.selecionadosNaRegra),callback:function ($$v) {_vm.selecionadosNaRegra=$$v},expression:"selecionadosNaRegra"}})],1),_c('div',{staticClass:"es-adicionar-remover"},[_c('v-icon',{staticClass:"light-blue",attrs:{"disabled":!_vm.ehMaster,"color":"primary","title":"Adicionar","size":"36"},on:{"click":function($event){return _vm.adicionarSelecionados()}}},[_vm._v(" mdi-chevron-double-left ")]),_c('v-icon',{staticClass:"light-blue",attrs:{"disabled":!_vm.ehMaster,"color":"primary","title":"Remover","size":"36"},on:{"click":function($event){return _vm.removerSelecionados()}}},[_vm._v(" mdi-chevron-double-right ")])],1),_c('v-card',{staticClass:"mr-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.pdvsForaDaRegra,"item-text":"pdv","item-value":"idpdv","label":"PDV"},model:{value:(_vm.idpdvForaDaRegra),callback:function ($$v) {_vm.idpdvForaDaRegra=$$v},expression:"idpdvForaDaRegra"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.catForaDaRegraFiltrada,"item-text":"categoria","item-value":"idcategoria","label":"Categoria"},model:{value:(_vm.idCatForaDaRegra),callback:function ($$v) {_vm.idCatForaDaRegra=$$v},expression:"idCatForaDaRegra"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.subCatForaDaRegraFiltrada,"item-text":"subcategoria","item-value":"idsubcategoria","label":"Subcategoria"},model:{value:(_vm.idSubCatForaDaRegra),callback:function ($$v) {_vm.idSubCatForaDaRegra=$$v},expression:"idSubCatForaDaRegra"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.cabsForaDaRegra,"items":_vm.produtosForaDaRegraFiltrado,"item-key":"item_key","show-select":"","dense":""},scopedSlots:_vm._u([{key:"item.produto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.produto) + " (" + (item.codprodutopms) + ")")))])]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.preco)))])]}}],null,true),model:{value:(_vm.selecionadosForaDaRegra),callback:function ($$v) {_vm.selecionadosForaDaRegra=$$v},expression:"selecionadosForaDaRegra"}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-title"},[_c('h2',{staticClass:"text-center pb-2"},[_vm._v("Produtos na Regra")]),_c('h2',{staticClass:"text-center pb-2"},[_vm._v("Produtos Fora da Regra")])])}]

export { render, staticRenderFns }