<template>
  <v-dialog v-model="localExibirModal" max-width="600px">
    <v-card class="modal-card">
      <v-card-title>
        <h2 class="title">Escolha {{ multiselecao ? 'os opcionais' : 'o opcional' }} para: {{ produto.descproduto }}</h2>
      </v-card-title>

      <div class="modal-content">
        <div v-if="multiselecao">
          <v-checkbox
            v-for="opcao in produto.opcoes"
            :key="opcao.id"
            :label="opcao.descricao"
            :value="opcao.id"
            v-model="opcoesSelecionadas"
            class="checkbox-item"
          ></v-checkbox>
        </div>
        <div v-else>
          <v-radio-group v-model="opcoesSelecionadas" mandatory>
            <v-radio
              v-for="opcao in produto.opcoes"
              :key="opcao.id"
              :label="opcao.descricao"
              :value="opcao.id"
              class="radio-item"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>

      <v-card-actions class="modal-actions">
        <v-btn color="primary" @click="continuar">Continuar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalOpcionaisEscolha',
  props: ["exibirModal", "produto"],
  data: () => ({
    opcoesSelecionadas: [],
    teste: [],
    multiselecao: false,
    localExibirModal: false, 
  }),
  beforeMount() {
    this.localExibirModal = this.exibirModal;
  },
  watch: {
    exibirModal(newValue) {
      this.localExibirModal = newValue;
    },
    opcoesSelecionadas(value) {
      let novoValor = value
      if(Array.isArray(value)) {
        novoValor = value
      } else {
        novoValor = [value]
      }

      this.$emit('setOpcoesSelecionadas', novoValor)
    },
    produto(value) {
      if(value != null) {
        this.opcoesSelecionadas = [];
        this.multiselecao = value?.configuracao_multiselecao?.multiselecao || false
        this.localExibirModal = true;
        this.opcoes = value.opcoes;
      }
    },
    localExibirModal(newValue) {
      this.$emit("update:exibirModal", newValue);
    },
  },
  methods: {
    continuar() {
      this.$emit("add", this.produto);
      console.log(this.produto);
      this.localExibirModal = false;
      // this.$emit('fecharModal');
      // this.$destroy();
    }
  },
};
</script>

<style scoped>
.modal-card {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
  overflow: hidden;
}
.title {
  font-size: 1.5rem !important;
  margin-bottom: 20px;
}

.modal-content {
  padding: 0;
}

.checkbox-item {
  margin: -10px 0 -10px 20px;
}

.radio-item {
  margin-left: 20px;
}

.modal-actions {
  text-align: right;
}
</style>