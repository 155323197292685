<template>
    <div class="cc-principal">

            <div class="" style="">
                <div style="display:flex;justify-content:center;text-align:center;margin-top:20px;margin-bottom:5px;">
                    <div style="width:70%;font-size:20px;">
                        {{ textoPagamento }}
                    </div>
                </div>
            </div>

            

            
 
    </div>
</template>
<script>
// import dayjs from 'dayjs'
export default {
    props:["dados"],
    data: () => {
        return {
            textoPagamento:"Pagamento será Iniciado"
        }
    },
    methods: {
        iniciarPagamento(){
            this.textoPagamento = "Iniciando Pagamento..."

            var mmaaParts = this.$store.getters.get_venctocartao.split('/')
            let ano = parseInt(mmaaParts[1]) + 2000
            let validade = mmaaParts[0] + '/' + ano

            var params = {
                "idhotel": localStorage.getItem('idhotel'),
                "valor": this.$store.getters.get_valorpagto,
                "nomecartao": this.$store.getters.get_nomecartao,
                "nucartao": this.$store.getters.get_numerocartao,
                "dtexpiracaocartao": validade,
                "nucvvcartao": this.$store.getters.get_codcartao,
                "bandeiracartao": this.$store.getters.get_bandeiracartao,
                "comandas": this.$store.getters.get_comandas.listadepedidos,
                "consumos": [],
                "tipoPagamento":this.$store.getters.get_tipopagto,
                "parcelas":this.$store.getters.get_parcelascartao,
                "idpagamento":this.$store.getters.get_idpagamento
            }

            console.log(JSON.stringify(params,null,2))

            this.$http.post("/pagamento/efetuarPagamento",params)
                .then(result => {
                    console.log(result)
                    if(result && result.data && result.data.urlredirecionamento){
                        window.open(result.data.urlredirecionamento, '_blank');
                    }else{
                        this.$router.push('/Hospede/pagamento-aprovado')
                    }

                })
            .catch((e) => {
                console.error(e)
                this.$router.push('/Hospede/pagamento-negado')
            })
        }
    },
    mounted() {
        this.iniciarPagamento()
    }
}
</script>

<style scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    font-weight: normal;
    color: white!important;
    margin-bottom: 1.5rem;
    width: 90vw;
    align-self: center;
}

.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

#video {
  /* border: 1px solid white;
  box-shadow: 0px 0px 30px 20px blue;
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: relative; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:250px;
  height:250px;
  border-radius: 50%;
  position: relative;
}

#photo {
  /* border: 1px solid black;
  box-shadow: 0px 0px 30px 20px blue;
  width: 90vw;
  height: auto;
  border-radius: 50%; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:280px;
  height:300px;
  border-radius: 50%;
  /* position: relative; */
  
}

#canvas {
  display:none;
}

.camera {
    position: relative;
    width: 100%;
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
    display:inline-block;
    vertical-align: top; */
    position: absolute;
    top:10px;
    width: 70vh;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#startbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  bottom:32px;
}

.continuar {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-around; */
    align-items: center;
    font-weight: normal;
    width: 30vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 25px;
    padding: 0.5rem;
    margin-top:10px;
}

.contentarea {
    display: flex;
    flex-direction: column;
    align-content: center;
    /*width: 760px;*/
}
</style>