<template>
    
    
    <div class="d-flex align-center item" >
        
        <div class="colB" @click="direcionar()" data-cy="pedido">
            <div class="colBItemTitle">
                <div style="width:90%;">
                    <div>ID: {{pedido.numpedido }}</div>
                    <div style="margin-top:5px;" >Data: {{pedido.datahoraabertura | formatarDataHora}}</div>
                </div>
                <div style="width:10%;">
                    <v-badge v-if="pedido.idcomanda" color="red accent-4" icon="mdi-close"  style="margin-left:20%;"></v-badge>
                </div>
            </div>
            
            <div  class="colBItemPrice">
                <div> {{pedido.statuscomanda }}</div>
                <div> {{pedido.valor | formatarMoeda}}</div>
            </div>
        </div>
            

        <!-- <ModalConfirmacao :showModal="exibirModalConfirmacaoProd" :titulo="`Remover ${produto.descproduto} do Carrinho?`"
            textosim="Remover" textonao="Cancelar" @sim="()=>confirmarRetiradaDoCarrinho()" @nao="()=>fecharModal()"  /> -->
    </div>

    
    
    

</template>

<script>
// import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
export default {
    props:["pedido",'corTexto'],
    components: {
    //   ModalConfirmacao,
    },
    data: () => ({
        // exibirModalConfirmacaoProd:false,
    }),
    methods:{
        direcionar(){
            this.$router.push('/Hospede/Pedido/'+this.pedido.numpedido)
        },
        // retirarDoCarrinho(){
        //     this.exibirModalConfirmacaoProd = true
        // },
        // fecharModal(){
        //     this.exibirModalConfirmacaoProd = false
        // },
        // confirmarRetiradaDoCarrinho(){
        //     this.exibirModalConfirmacaoProd = false
        //     this.$emit('retirarProdutoCarrinho',this.produto)
        // }
    }
}
</script>

<style scoped>
    .item{
        font-size:1.2em;
        color:rgb(70, 70, 70);
        /* background-color: pink; */
        height: 80px;
        width:330px;
        max-width:90%;
        margin-bottom: 2%;
        margin-right:2%;
        border: 1px solid rgb(156, 156, 156);
        border-radius: 10px;
        padding:5px;
    }
    .item .colA{
        /* background-color:red; */
        width: 60px;
        padding-left:2%; 
        padding-right: 2%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item .colA .colAimg{
        border-radius: 100%;
        height:50px;
        max-height:50px;
        width:50px;
        max-width:50px;
    }

    .item .colB{
        /* background-color:Blue; */
        width: 270px;
        padding-left:2%; 
        height:100%;
        word-break: break-all;
    }

    .item .colB .colBItemTitle{
        /* background-color:red; */
        font-size:18px;
        line-height: 1.1;
        height: 70%;
        display: flex;
    }

    .item .colB .colBItemPrice{
        /* background-color:Blue; */
        display: flex;
        font-size:18px;
        line-height: 1.1;
        height: 30%;
        padding-bottom:10%;
        padding-right: 10px;
        justify-content: space-between;
        font-weight: bold;
    }



</style>