<template>
    <div class="cc-principal">

            <div class="" style="">
                <div style="display:flex;justify-content:center;text-align:center;margin-top:20px;margin-bottom:5px;">
                    <div style="width:70%;font-size:20px;">
                        Por gentileza, retire bonés, óculos ou máscara e centralize seu rosto para a foto!
                    </div>
                </div>
            </div>

            <div class="camera">
                <video id="video" >Video stream not available.</video>
                <button v-show="!previewPhoto" class="continuar" id="startbutton" @click="takepicture">
                    Tirar Self
                </button>
                <div v-show="previewPhoto" class="output">
                    <img id="photo" alt="The screen capture will appear in this box."> 
                    <div style="display:flex;flex-direction:column;padding-top:20px;margin-top:20px;">
                        <button class="continuar" id="startbutton" @click="tirarOutra">
                            Tirar Outra
                        </button>
                        <button class="continuar" id="startbutton" @click="avancar">
                            {{txtContinuar}}
                        </button>
                    </div>
                </div>
            </div>
            <canvas id="canvas">
            </canvas>

            
 
    </div>
</template>
<script>
// import dayjs from 'dayjs'
export default {
    props:["dados"],
    data: () => {
        return {
            txtContinuar:'Continuar',
            streaming: false,
            width: 320,
            height: 240,
            imagePoolSize: 6,
            imagePool: [],
            previewPhoto: false,
            lastFace: {
                imagem: null
            }
        }
    },
    methods: {
        tirarOutra() {
            this.previewPhoto = false
        },
        voltar() {
            this.$emit('direcionar', {from: 'self', to: 'pagamento'})
        },
        async avancar() {
            this.txtContinuar='Enviando...'
            //this.$emit('registrarPagamento', this.dados)
            let idpagamento = await this.gerarNovoPagamento()
            if(idpagamento){
                this.$store.dispatch("set_idpagamento" ,idpagamento);
                let dados =   {
                    "token":'3c8478c895580214e0ff389448054854',
                    // "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                    "tipo" : this.$store.getters.get_tipodocfrente,
                    "nome" : "Img",
                    "dados" : this.$store.getters.get_imgdocfrente,
                    "tabela": "wout_pagamento",
                    "fk": "imgdoc",
                    "chave": "id",
                    "valor": idpagamento,
                    "idusuario":1
                }
               
                this.$http.post(`/imagem/upinsert`, dados).then(()=>{
                    var self = this
                    setTimeout(() => {
                        self.compararFaces(idpagamento)
                    }, 2000);   
                    
                }).catch(()=>{
                    this.txtContinuar='Continuar'
                    this.$alertar('warning',"Não foi possivel Realizar o Movimento!",'=(')
                })
            }
            // 
        },
        showViewLiveResultButton() {
            if (window.self !== window.top) {
                // Ensure that if our document is in a frame, we get the user
                // to first open it in its own tab or window. Otherwise, it
                // won’t be able to request permission for camera access.
                document.querySelector(".contentarea").remove();
                const button = document.createElement("button");
                button.textContent = "View live result of the example code above";
                document.body.append(button);
                button.addEventListener('click', () => window.open(location.href));
                return true;
            }
            return false;
        },
        clearphoto() {
            var canvas = document.getElementById('canvas');
            var photo = document.getElementById('photo');
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);
            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
        },
        async gerarNovoPagamento(){
            let obj = {
                valor:this.$store.getters.get_valorpagto,
                nucartao:this.$store.getters.get_numerocartao.substr(-4),
                bandeira:this.$store.getters.get_bandeiracartao,
                idhospedepagante:localStorage.getItem("idcheckin"),
                numeroreserva:localStorage.getItem("numreserva"),
                tipoPagamento:this.$store.getters.get_tipopagto,
                parcelas:this.$store.getters.get_parcelascartao,
            }
            
            let dados = await this.$http.post(`/pagamento/registrarInicioPagamento`, obj)
            return dados.data.idpagamento[0]
        },
        compararFaces(idpagamento) {

            const params = {
                "id" : idpagamento,
                "dados" : this.lastFace.imagem,
                "tipo": "image/png",
                "nome": "imgcheckout_" + idpagamento
            }
            //console.error(JSON.stringify(params))
            console.error('Comparando com documentos...')
            // this.$http.post(`/hospedes/compararfaces`, params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
            this.$http.post(`/hospedes/compararfaces`, params)
            .then(async resp => {
                if (resp.status == 200) {
                    // console.log("resp.data",resp.data)
                    let objResponse = resp.data
                    if (objResponse && objResponse.FaceMatches && objResponse.FaceMatches.length > 0) {
                        // Similaridade (Rekognition) ou Distância (DeepFace) ?
                        let simiDist = `${objResponse.FaceMatches[0]['Similarity']?'Similarity: '+objResponse.FaceMatches[0]['Similarity']:'Distance: '+objResponse.Result.distance}`
                        console.log("this.lastFace",this.lastFace)
                        // console.log('OK - Matches: '+objResponse.FaceMatches.length+' - '+simiDist+' - id: '+this.lastFace.idreservahospede)
                        console.log('OK - Matches: '+objResponse.FaceMatches.length+' - '+simiDist)
                        // Para testar o reconhecimneto facial...
                        // TODO: Para código em produção, descomentar a linha abaixo
                        // this.$emit('registrarPagamento', this.dados.dadoscartao)
                        // this.$alertar('sucess',"OK!",'=(')
                        this.$router.push('/Hospede/EfetuarPagamento')
                    } else {
                        this.txtContinuar='Continuar'
                        // this.$emit('direcionar', {from: 'self', to: 'falhareconhecimento'})
                        this.$alertar('warning',"Não foi possivel Reconhecer o Rosto!",'=(')
                    }
                }
            }).catch(error =>{ 
                console.log(error) 
                this.$alertar('sucess',error,'=(')
            })            
        },
        holdLastFace(e) {
            this.lastFace.imagem = e.src
        },
        // Captura a foto da câmera
        takepicture() {
            let photo = document.getElementById('photo');
            let video = document.getElementById('video');
            let canvas = document.getElementById('canvas');
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(video, 0, 0, this.width, this.height);
                var data = canvas.toDataURL('image/png');
                photo.onload = () => {
                    this.holdLastFace(photo)
                }
                photo.setAttribute('src', data);
            } else {
                this.clearphoto();
            }
            this.previewPhoto = true
        },
        startup() {
            if (this.showViewLiveResultButton()) { return; }
            let video = document.getElementById('video');
            let photo = document.getElementById('photo');
            let canvas = document.getElementById('canvas');
            
            navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(function(stream) {
                video.srcObject = stream;
                video.play();
            })
            .catch(function(err) {
                console.log("An error occurred: " + err);
            });
            // O 'this' dentro da função abaixo não se refere à instância Vue
            // Assim, é preciso guardar sua referência...
            const este = this
            video.addEventListener('canplay', function() {
                if (!este.streaming) {
                    este.height = video.videoHeight / (video.videoWidth/este.width);
                    // Bug do Firefox
                    if (isNaN(este.height)) {
                        este.height = este.width / (4/3);
                    }
                    video.setAttribute('width', este.width);
                    video.setAttribute('height', este.height);
                    canvas.setAttribute('width', este.width);
                    canvas.setAttribute('height', este.height);
                    photo.setAttribute('width', este.width);
                    photo.setAttribute('height', este.height);
                    este.streaming = true;
                }
            }, false);
            
            this.clearphoto();
        }        
    },
    mounted() {
        this.startup()
    }
}
</script>

<style scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    font-weight: normal;
    color: white!important;
    margin-bottom: 1.5rem;
    width: 90vw;
    align-self: center;
}

.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

#video {
  /* border: 1px solid white;
  box-shadow: 0px 0px 30px 20px blue;
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: relative; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:250px;
  height:250px;
  border-radius: 50%;
  position: relative;
}

#photo {
  /* border: 1px solid black;
  box-shadow: 0px 0px 30px 20px blue;
  width: 90vw;
  height: auto;
  border-radius: 50%; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:280px;
  height:300px;
  border-radius: 50%;
  /* position: relative; */
  
}

#canvas {
  display:none;
}

.camera {
    position: relative;
    width: 100%;
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
    display:inline-block;
    vertical-align: top; */
    position: absolute;
    top:10px;
    width: 70vh;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#startbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  bottom:32px;
}

.continuar {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-around; */
    align-items: center;
    font-weight: normal;
    width: 30vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 25px;
    padding: 0.5rem;
    margin-top:10px;
}

.contentarea {
    display: flex;
    flex-direction: column;
    align-content: center;
    /*width: 760px;*/
}
</style>