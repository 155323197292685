<template>
  <div class="ddpms" data-v-step-cardapio="1">
    <span style="font-weight: bold; color: #036097"
      >ITENS DISPONÍVEIS PARA SELEÇÃO</span
    >
    <v-card
      elevation="2"
      outlined
      style="padding-bottom: 0px !important; height: 19%; max-height: 110px; padding:2px;"

    >
      <v-card-text class="" style="margin-top: 0px; margin-bottom: 0px" >
        <div style="height: 70px; max-height: 70px; display: flex">
          <div style="width: 48%; margin-left: 0%">
            <label data-v-step-cardapio="2" for="" style="display:flex;flex-direction:row;" >PDV
                <div id="qtdeDePdvsNaLista" v-if="listaPDVsPMS && listaPDVsPMS.length > 1">
                  (S)
                </div>
            </label>
            <select
              v-model="idpdvpms"
              data-cy="idpdvpms"
              class="select"
              @change="getCategoriasPMS()"
            >
              <option
                v-for="pdv in listaPDVsPMS"
                :key="pdv.idpdv"
                :value="pdv.idpdv"
              >
                {{ pdv.descpdv }}
              </option>
            </select>
          </div>
          <div style="width: 48%; margin-left: 2%">
            <label for="">Categorias</label>
            <select
              data-cy="idcategoriapms"
              v-model="idcategoriapms"
              class="select"
              @change="getProdutosPMS()"
              :disabled="idpdvpms === null"
            >
              <option
                v-for="categoria in listaCategoriasPMS"
                :key="categoria.idcategoriaprod"
                :value="categoria.idcategoriaprod"
              >
                {{ categoria.desccategoriaprod }}
              </option>
            </select>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      elevation="2"
      outlined
      class="mt-2"
      height="80.5%"
      style="overflow: auto; max-height: 65vh"
    >
      <v-card-text class="">
              <v-text-field data-v-step-cardapio="3" outlined clearable label="Buscar itens" v-model="produtoPesquisa" data-cy="produtoPesquisa"></v-text-field>
        <v-card
          v-for="prod in itensFiltrados"
          :key="prod.idpdvproduto"
          :style="prod.cardapios ? 'background-color:#d1deeb;' : ''"
          elevation="2"
          outlined
          class="mt-2"
          height="80.5%"
        >
          <v-card-text>
            <div style="display: flex">
              <div style="width: 90%; color: #5a5858; font-weight: bold" data-cy="prod.descproduto">
                <div v-if="prod.nomedepartamento">
                  ({{prod.nomedepartamento}})
                </div>
                <div>
                  {{ prod.descproduto }} <v-spacer></v-spacer> {{ prod.precounitario | formatarMoeda }}
                </div>
              </div>
              <div style="width: 10%">
                <v-icon
                  color="info"
                  style="cursor: pointer"
                  large
                  data-v-step-cardapio="4"
                  data-cy="adicionarProduto"
                >
                  mdi-arrow-right-bold-box
                </v-icon>
              </div>
              <v-icon
                @click="
                  [(listaPdvAssoc = prod.cardapios), exibirPDVsAssociados()]
                "
                >mdi-eye</v-icon
              >
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="exibeListaPDVAssoc" max-width="600px">
      <v-card width="600px">
        <v-toolbar color="primary"><v-tooblar-title style="color: white;">PDVs Associados</v-tooblar-title>
        <v-spacer></v-spacer><v-icon color="red" @click="exibeListaPDVAssoc = false">mdi-close-circle</v-icon>
        </v-toolbar>
        
        <v-card-text>
          <v-row no-gutters class="ma-1">
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> PDV </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Categoria </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Sub Categoria </v-card>
            </v-col>
          </v-row>
          <div class="ma-1">

          <v-row no-gutters v-for="pdv in listaPdvAssoc" :key="pdv.id" >
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomepdv && pdv.nomepdv.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nome && pdv.nome.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomesub && pdv.nomesub.toUpperCase() }}
              </v-card>
            </v-col>
          </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: "ColunaPMS",
    props: ["url", "atualizar"],
    data: () => ({
        listaPDVsPMS: [],
        idpdvpms: null,
        listaCategoriasPMS: [],
        idcategoriapms: null,
        listaProdutosPMS: [],
        listaProdutosPMSFiltrados: [],
        idprodutopms: null,
        idprodutoclicado: null,
        listaPdvAssoc: [],
        exibeListaPDVAssoc: false,
        produtoPesquisa: "",
    }),
    computed: {
        itensFiltrados(){
            return this.listaProdutosPMS.filter(item => {
              if(this.produtoPesquisa.toLowerCase().trim() === '') {
                  return item.descproduto
              } else {
                  return item.descproduto.toLowerCase().includes(this.produtoPesquisa.toLowerCase())
              }                   
            })
        },
    },
    watch: {
        atualizar() {
            this.getProdutosPMS();
        },
    },
    async mounted() {
        await this.getPDVSPMS();
    },
    methods: {
        exibirPDVsAssociados() {
            this.exibeListaPDVAssoc = true;
        },
        getPDVSPMS() {
          const mockResponseAPI = [
            {
              "idpdv": 4,
              "descpdv": "Departamento PMS"
            },
            {
              "idpdv": 2,
              "descpdv": "Outro Departamento PMS"
            },

          ]
          this.listaPDVsPMS = [
            {
              idpdv: null,
              descpdv: "Todos"
            },
            ...mockResponseAPI
          ]
          this.idpdvpms = this.listaPDVsPMS[0].idpdv;
          if(this.idpdvpms === null){
            this.getProdutosPMS();
          }
        },
        getCategoriasPMS() {
            this.listaCategoriasPMS = [];
            if(this.idpdvpms === null){
              this.idpdvpms = null,
              this.idcategoriapms = null,
              this.getProdutosPMS()
              return;
            } 
            this.$http(`pdv/getCategoriasPMS/${this.idpdvpms}/0`)
                .then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaCategoriasPMS = resp.data;
                    this.idcategoriapms = this.listaCategoriasPMS[0].idcategoriaprod;
                    await this.getProdutosPMS();
                }
            })
                .catch(() => {
                this.$alertar("warning", "Não foi possivel listar as Categorias do PMS", "=(");
            });
        },
        getProdutosPMS() {
            const mockResponseAPI = [
              {
                "idproduto": 1,
                "descproduto": "Produto Importado HITS",
                "precounitario": 15,
                "idpdvproduto": 1,
                "coddepartamentos": "5",
                "nomedepartamento": "Departamento HITS",
                "cardapios": [
                  {
                    "id": 17,
                    "nome": "Categoria Associada Cardápio Digital",
                    "nomesub": null,
                    "idproduto": 1,
                    "nomepdv": "PDV Associado Cardápio Digital"
                  }
                ]
              },
              {
                "idproduto": 2,
                "descproduto": "Outro Produto Importado HITS",
                "precounitario": 3.5,
                "idpdvproduto": 2,
                "coddepartamentos": "6",
                "nomedepartamento": "Outro Departamento HITS",
                "cardapios": null
              },
            ]
            this.listaProdutosPMS = mockResponseAPI;
            this.listaProdutosPMSFiltrados = this.listaProdutosPMS;
            this.$emit("atualizarListaItensPdv", this.listaProdutosPMSFiltrados);
        },
    },
};
</script>

<style scoped>
.ddpms {
  /* background-color: white; */
  width: 35%;
  height: 100% !important;
}

.ddpmsprod {
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid black;
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}
</style>
