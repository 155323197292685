<template>
    <!-- <v-card 
        id="container"
        elevation="2" 
    > -->
    <v-stepper  id="container" v-model="e1" v-show="!carregando">
        <v-stepper-header>
            <v-stepper-step editable step="1">
                Configurações ROBO
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
              Comandas impressas relatório
            </v-stepper-step>

            <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card-text id="content">
                    <h2 style="color: red">Atenção! A funcionalidade de personalização dos robôs de impressão estará indisponível até que seja realizada uma atualização essencial. A partir de 21 de julho de 2023, é imprescindível que todos os robôs de impressão sejam atualizados para garantir o funcionamento correto. Solicite com o suporte a atualização do robô de impressão</h2>
                    <div id="content-container">
                        <div>
                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarObsProduto" data-cy="configs.mostrarObsProduto"></v-switch>
                                <label style="font-size:18px;">Mostrar obs por produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarTaxaProduto" data-cy="configs.mostrarTaxaProduto"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar taxa por produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarGarcom" data-cy="configs.mostrarGarcom"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar garcom</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarTotalTaxaProduto" data-cy="configs.mostrarTotalTaxaProduto"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar total de taxa de produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarLocal" data-cy="configs.mostrarLocal"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar local</label>
                            </div>

                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarPrecoProduto" data-cy="configs.mostrarLocal"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar preço do produto</label>
                            </div>

                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarCPF" data-cy="configs.mostrarLocal"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar CPF</label>
                            </div>

                            <div class="switch-button">
                                <v-switch v-model="configs.mostrarTotalComanda" data-cy="configs.mostrarLocal"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar total comanda</label>
                            </div>
                        </div>
                        <!-- 
                        <div>
                            <div class="switch-button">
                                <v-switch data-cy="configs."></v-switch>
                                <label for="" style="font-size:18px;">label</label>
                            </div>
                        </div>
                        -->
                    </div>
                    <v-btn
                        id="save-button"
                        color="primary"   
                        @click="() => salvar()" 
                        data-cy="salvar"
                    >SALVAR</v-btn>
                </v-card-text>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card-text id="content">
                    <div id="content-container">
                        <div>
                            <div class="switch-button">
                                <v-switch v-model="configsImpressaoRelatorio.mostrarObsProduto" data-cy="configsImpressaoRelatorio.mostrarObsProduto"></v-switch>
                                <label style="font-size:18px;">Mostrar obs por produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configsImpressaoRelatorio.mostrarTaxaProduto" data-cy="configsImpressaoRelatorio.mostrarTaxaProduto"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar taxa por produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configsImpressaoRelatorio.mostrarGarcom" data-cy="configsImpressaoRelatorio.mostrarGarcom"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar garcom</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configsImpressaoRelatorio.mostrarTotalTaxaProduto" data-cy="configsImpressaoRelatorio.mostrarTotalTaxaProduto"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar total de taxa de produto</label>
                            </div>
                            <div class="switch-button">
                                <v-switch v-model="configsImpressaoRelatorio.mostrarCPF" data-cy="configsImpressaoRelatorio.mostrarLocal"></v-switch>
                                <label for="" style="font-size:18px;">Mostrar CPF</label>
                            </div>
                        </div>
                    </div>

                    <v-btn
                        id="save-button"
                        color="primary"   
                        @click="() => salvarComandasRelatorio()" 
                        data-cy="salvar"
                    >
                        SALVAR
                    </v-btn>
                    </v-card-text>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
    <!-- </v-card>    -->
</template>

<script>
  export default {
    name: 'Comandas Configuracoes',
    components: {},
    props:[],
    async mounted(){
        await this.buscarDados()
    },
    watch:{},
    data: () => ({
        configs: {
            mostrarObsProduto: false,
            mostrarTaxaProduto: false,
            mostrarGarcom: false,
            mostrarTotalTaxaProduto: false,
            mostrarLocal: false,
            mostrarPrecoProduto: false,
            mostrarCPF: false,
            mostrarTotalComanda: false,
        },
        configsImpressaoRelatorio: {
            mostrarObsProduto: false,
            mostrarTaxaProduto: false,
            mostrarTotalTaxaProduto: false,
            mostrarGarcom: false,
            mostrarCPF: false,
        }
    }),
    methods:{
        salvar(){
            const valor = JSON.stringify(this.configs, null, 2)

            console.log(valor)

            const parametro = {
                chave: 'CONFIGS_ROBO',
                valor,
            }

            this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
        },
        salvarComandasRelatorio(){
            const valor = JSON.stringify(this.configsImpressaoRelatorio, null, 2)

            console.log(valor)

            const parametro = {
                chave: 'CONFIGS_COMANDAS_RELATORIO_IMPRESSAO',
                valor,
            }

            this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'CONFIGS_ROBO'})
            .then(res => {
                const response = JSON.parse(res.data[0].valor)
                if(response) {
                    this.configs = JSON.parse(res.data[0].valor)
                }
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'CONFIGS_COMANDAS_RELATORIO_IMPRESSAO'})
            .then(res =>{
                const response = JSON.parse(res.data[0].valor)
                if(response) {
                    this.configsImpressaoRelatorio = JSON.parse(res.data[0].valor)
                }
            }).catch(error =>{
                alert(error)
            })
        }
    }
  }
</script>

<style scoped>

#container {
    padding-bottom: 5px!important;
    height: 95%;
    max-height: 95%;
    margin-top: 1%;
    padding:10px
}

#save-button {
    padding: 20px 30px;
}

#content {
    display:flex;  
    justify-content: flex-end; 
    align-content: flex-end; 
    flex-direction: column;
    height: 100%;
}

#content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.switch-button {
    display:flex;
    width:100%;
    align-items:center;
}

</style>