import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    titulo:JSON.parse(sessionStorage.getItem('titulo'))||{},
    qtdenotificacao:JSON.parse(sessionStorage.getItem('qtdenotificacao'))||{},
    idperfil:JSON.parse(sessionStorage.getItem('idperfil'))||{},
    nomecartao:JSON.parse(sessionStorage.getItem('nomecartao'))||"",
    numerocartao:JSON.parse(sessionStorage.getItem('numerocartao'))||"",
    venctocartao:JSON.parse(sessionStorage.getItem('venctocartao'))||"",
    codcartao:JSON.parse(sessionStorage.getItem('codcartao'))||"",
    parcelascartao:JSON.parse(sessionStorage.getItem('parcelascartao'))||"",
    bandeiracartao:JSON.parse(sessionStorage.getItem('bandeiracartao'))||"",
    imgdocfrente:JSON.parse(sessionStorage.getItem('imgdocfrente'))||"",
    tipodocfrente:JSON.parse(sessionStorage.getItem('tipodocfrente'))||"",
    tipopagto:JSON.parse(sessionStorage.getItem('tipopagto'))||"",
    valorpagto:JSON.parse(sessionStorage.getItem('valorpagto'))||"",
    comandas:JSON.parse(sessionStorage.getItem('comandas'))||"",
    idpagamento:JSON.parse(sessionStorage.getItem('idpagamento'))||"",
    idioma:JSON.parse(sessionStorage.getItem('idioma'))||"",
    vizualizou_patch_notes:JSON.parse(sessionStorage.getItem('vizualizou_patch_notes'))||"false",
  },
  mutations: {
    mutate_titulo(state, payload) {
      sessionStorage.setItem(`titulo`, JSON.stringify(payload))
      state.titulo = payload
    },
    mutate_qtdenotificacao(state, payload) {
      sessionStorage.setItem(`qtdenotificacao`, JSON.stringify(payload))
      state.qtdenotificacao = payload
    },
    mutate_idperfil(state, payload) {
      sessionStorage.setItem(`idperfil`, JSON.stringify(payload))
      state.idperfil = payload
    },
    mutate_nomecartao(state, payload) {
      sessionStorage.setItem(`nomecartao`, JSON.stringify(payload))
      state.nomecartao = payload
    },
    mutate_numerocartao(state, payload) {
      sessionStorage.setItem(`numerocartao`, JSON.stringify(payload))
      state.numerocartao = payload
    },
    mutate_venctocartao(state, payload) {
      sessionStorage.setItem(`venctocartao`, JSON.stringify(payload))
      state.venctocartao = payload
    },
    mutate_codcartao(state, payload) {
      sessionStorage.setItem(`codcartao`, JSON.stringify(payload))
      state.codcartao = payload
    },
    mutate_parcelascartao(state, payload) {
      sessionStorage.setItem(`parcelascartao`, JSON.stringify(payload))
      state.parcelascartao = payload
    },
    mutate_bandeiracartao(state, payload) {
      sessionStorage.setItem(`bandeiracartao`, JSON.stringify(payload))
      state.bandeiracartao = payload
    },
    mutate_imgdocfrente(state, payload) {
      sessionStorage.setItem(`imgdocfrente`, JSON.stringify(payload))
      state.imgdocfrente = payload
    },
    mutate_tipodocfrente(state, payload) {
      sessionStorage.setItem(`tipodocfrente`, JSON.stringify(payload))
      state.tipodocfrente = payload
    },
    mutate_tipopagto(state, payload) {
      sessionStorage.setItem(`tipopagto`, JSON.stringify(payload))
      state.tipopagto = payload
    },
    mutate_valorpagto(state, payload) {
      sessionStorage.setItem(`valorpagto`, JSON.stringify(payload))
      state.valorpagto = payload
    },
    mutate_comandas(state, payload) {
      sessionStorage.setItem(`comandas`, JSON.stringify(payload))
      state.comandas = payload
    },
    mutate_idpagamento(state, payload) {
      sessionStorage.setItem(`idpagamento`, JSON.stringify(payload))
      state.idpagamento = payload
    },
    mutate_idioma(state, payload) {
      sessionStorage.setItem(`idioma`, JSON.stringify(payload))
      state.idioma = payload
    },
    mutate_vizualizou_patch_notes(state, payload) {
        sessionStorage.setItem(`vizualizou_patch_notes`, JSON.stringify(payload))
        state.vizualizou_patch_notes = payload
    }
  },
  actions: {
    set_titulo({ commit }, payload) { commit('mutate_titulo', payload) },
    set_qtdenotificacao({ commit }, payload) { commit('mutate_qtdenotificacao', payload) },
    set_idperfil({ commit }, payload) { commit('mutate_idperfil', payload) },
    set_nomecartao({ commit }, payload) { commit('mutate_nomecartao', payload) },
    set_numerocartao({ commit }, payload) { commit('mutate_numerocartao', payload) },
    set_venctocartao({ commit }, payload) { commit('mutate_venctocartao', payload) },
    set_codcartao({ commit }, payload) { commit('mutate_codcartao', payload) },
    set_parcelascartao({ commit }, payload) { commit('mutate_parcelascartao', payload) },
    set_bandeiracartao({ commit }, payload) { commit('mutate_bandeiracartao', payload) },
    set_imgdocfrente({ commit }, payload) { commit('mutate_imgdocfrente', payload) },
    set_tipodocfrente({ commit }, payload) { commit('mutate_tipodocfrente', payload) },
    set_tipopagto({ commit }, payload) { commit('mutate_tipopagto', payload) },
    set_valorpagto({ commit }, payload) { commit('mutate_valorpagto', payload) },
    set_comandas({ commit }, payload) { commit('mutate_comandas', payload) },
    set_idpagamento({ commit }, payload) { commit('mutate_idpagamento', payload) },
    set_idioma({ commit }, payload) { commit('mutate_idioma', payload) },
    set_vizualizou_patch_notes({ commit }, payload) { commit('mutate_vizualizou_patch_notes', payload) },
  },
  getters: {
    get_titulo(state) { return state.titulo },
    get_qtdenotificacao(state) { return state.qtdenotificacao },
    get_idperfil(state) { return state.idperfil },
    get_nomecartao(state) { return state.nomecartao },
    get_numerocartao(state) { return state.numerocartao },
    get_venctocartao(state) { return state.venctocartao },
    get_codcartao(state) { return state.codcartao },
    get_parcelascartao(state) { return state.parcelascartao },
    get_bandeiracartao(state) { return state.bandeiracartao },
    get_imgdocfrente(state) { return state.imgdocfrente },
    get_tipodocfrente(state) { return state.tipodocfrente },
    get_tipopagto(state) { return state.tipopagto },
    get_valorpagto(state) { return state.valorpagto },
    get_comandas(state) { return state.comandas },
    get_idpagamento(state) { return state.idpagamento },
    get_idioma(state) { return state.idioma },
    get_vizualizou_patch_notes(state) { return state.vizualizou_patch_notes },
  }
})
