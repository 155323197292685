import Vue from 'vue'
import VueRouter from 'vue-router'
// import Categories from '../views/Categories.vue'
// import Items from '../views/Items.vue'
// import Product from '../views/Product.vue'
// import ShoppingCar from '../views/ShoppingCar.vue'


import rotas_hospedes from './rotas_cardapio_hospedes'
import rotas_adm from './rotas_cardapio_adm'

// import Home from '../views/hospede/HomeHospede.vue'
// import AdmHome from '../views/adm/HomeAdm.vue'

import Login from '../views/Login.vue'
import LoginADM from '../views/LoginADM.vue'
import LoginQRCODE from '../views/LoginQRCODE.vue'
import LoginQRCODEPDVMESA from '../views/LoginQRCODEPDVMESA.vue'
import PasswordRecovery from '../views/PasswordRecovery.vue'
import DownloadApp from '../views/DownloadApp.vue'
import FormPasswordRecovery from '../views/FormPasswordRecovery.vue'
import CheckinFake from '../views/CheckinFake.vue'
import VerificarEmailHospedeFake from '../views/VerificarEmailHospedeFake.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login, meta: { mostrarFAQ: false } },
    { path: '/admin', name: 'LoginADM', component: LoginADM, meta: { mostrarFAQ: false } },
    { path: '/loginqrcode', name: 'LoginQRCode', component: LoginQRCODE, meta: { mostrarFAQ: false } },
    { path: '/loginpdvmesa', name: 'LoginQRCODEPDVMESA', component: LoginQRCODEPDVMESA, meta: { mostrarFAQ: false } },
    { path: '/app', name: 'Download APP', component: DownloadApp },
    { path: '/passrecovery', name: 'Password Recovery', component: PasswordRecovery },
    { path: '/formpassrecovery', name: 'Password Recovery', component: FormPasswordRecovery },
    { path: '/verificar/:token', name: 'Verificar Email Hospede Fake', component: VerificarEmailHospedeFake },
    { path: '/checkin/temp',name: 'CheckinFake',component: CheckinFake},

    { path: '/Logout',name: 'Logout',component: Login, meta: { mostrarFAQ: false } },
    rotas_hospedes,
    rotas_adm,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

import { BUILD_API_URL } from '../constantes'

const rotasPublicas = [
    '/',
    '/admin',
    '/loginqrcode',
    '/app',
    '/loginpdvmesa',
    '/checkin/temp'
]

router.beforeEach(async (to, from, next) => {
    if(to.path == from.path){
      return next()
    }

    if(to.path == '/loginpdvmesa') {
        localStorage.clear();
    }

    if(rotasPublicas.includes(to.path)){
      return next()
    }

    if(to.name == 'FazerPedido') {
        const tuorCardapio = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        if(tuorCardapio?.fazerPedido) {
            return next()
        } else {
            return next({name: 'FazerPedidoTuor', params: {idreservasfront: to.params.idreservasfront, idhospede: to.params.idhospede, idcheckin: to.params.idcheckin}})
        }
    }

    await fetch(
      `${BUILD_API_URL}/auth/validarauthtoken`,
      {
        method: 'POST',
        headers: {'x-access-token': localStorage.getItem('token')}
      }
    ).then(res => {
      if(res.status === 200) {
        return next();
      } else {
        return next({name: 'Login'});
      }
    })
    .catch(() => {
      return next({name: 'Login'});
    })
})


export default router
