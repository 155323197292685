<template>
<div class="bar" style="height: 6.5vh">
    <v-toolbar color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
      <!-- <v-img alt="Vuetify Logo" class="shrink mr-2" contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition"
          width="40" /> -->
      <v-toolbar-title>{{titulo}}</v-toolbar-title>

      <v-spacer></v-spacer>
        <v-text-field
          v-if="this.tipousuario != 'HOSPEDE'"
          label="Consulte por nome, reserva, comanda ou uh"
          prepend-inner-icon="mdi-magnify"
         hide-details
          filled
          rounded
          dense
          v-on:keyup.enter="abrirBusca"
          v-model="busca"
        ></v-text-field>
      <v-spacer></v-spacer>
      

      <div style="font-size: 1.5rem; font-weight: bold; margin-right: 12px">{{ nomeHotel }}</div>

      <div @click="exibirbandeira=true" style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
          <flag :iso="bandeira"  style="border-radius:100px;width:27px;height:27px;" />
      </div>


      <div>
        <v-col cols="auto" >
          <v-dialog
            transition="dialog-top-transition"
            max-width="1000"
            v-model="dialogComandasNaoIntegradas"
            :persistent="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-if="perfil.includes(1) && tipoIntegracao.toLocaleLowerCase() == 'hits'"
                v-bind="attrs"
                v-on="on"
                style="display: flex; align-items: center; cursor: pointer; position: relative;"
              >
                <div
                  style="
                    position: absolute;
                    background-color: red;
                    color: white;
                    top: -5px;
                    left: 18px;
                    border-radius: 9999px;
                    font-size: 9px;
                    z-index: 999;
                    padding: 2px;"
                    >
                    <p style="display: flex; justify-content: center; align-items: center">{{ comandasNaoIntegradas.length > 10 ? '10+' : comandasNaoIntegradas.length}}</p>
                    <!-- <p style="display: flex; justify-content: center; align-items: center">10+</p> -->
                </div>
                <v-icon>mdi-alert-circle-outline</v-icon>
              </div>
            </template>
            <template v-slot:default="">
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="pesquisaDeComanda"
                    append-icon="mdi-magnify"
                    label="Pesquise por comanda"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    @click="() => getComadasNaoIntegradas()"
                    style="margin-left: 10px;"
                  >Atualizar</v-btn>
                </v-card-title>
                <v-data-table
                  :headers="headersComandas"
                  :items="comandasNaoIntegradas"
                  :search="pesquisaDeComanda"
                ></v-data-table>
                <v-card-actions class="justify-end">
                  <v-btn
                    @click="() => fecharModalComandasNaoIntegradas()"
                    color="success"
                  >Estou ciente</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        </div>

      <v-icon  class="mr-2" @click="direcionarHome(pathHome)">mdi-home</v-icon>

      <!-- <v-badge

          color="info"
          :content="'+'+this.$store.getters.get_qtdenotificacao"
          overlap
          style="margin-right:10px;"
        >
              <v-icon @click="enviarparanotificacao()">mdi-bell</v-icon>

      </v-badge> -->

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width='18em'
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="/imgs/defaultuser.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{nomeusuario}}</v-list-item-title>
          <v-list-item-title v-if="tipousuario == 'HOSPEDE'">{{$t('ap.texto')}}: {{coduh}}</v-list-item-title>
          <v-list-item-title v-if="tipousuario == 'HOSPEDE'">{{$t('reserva.texto')}}: {{numeroreserva}}</v-list-item-title>
        </v-list-item-content>

      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <div v-for="(link, i) in items" :key="i">

          <v-list-item
              v-if="!link.subLinks"
              :to="link.path"
              class="v-list-item"
          >
            <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.title" />
          </v-list-item>

          <v-list-group
            v-else
            :key="link.title"
            no-action
            :prepend-icon="link.icon"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
            </template>

            <v-list-item
                v-for="sublink in link.subLinks"
                :to="sublink.path"
                :key="sublink.title"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>

            </v-list-item>

          </v-list-group>

        </div>
      </v-list>
    </v-navigation-drawer>

    <div class="busca-container" v-if="modalbusca === true">
      <span style="flex: 1; display: flex; align-items: center; z-index: 202; background: rgba(255,255,255, 0.5); border-radius: 1em; padding: 1em;">
        <v-text-field
          label="Consulte por nome, reserva, comanda ou uh"
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          dense
          v-on:keyup.enter="abrirBusca"
          v-model="busca"
          autofocus
        ></v-text-field>
        <button
          type="button"
          v-if="modalbusca"
          @click="fecharBusca"
          style="margin-left: 1em;"
          >
          <v-icon color="#fff">mdi-close-circle-outline</v-icon>
        </button>
      </span>
      <div class="busca-content">
        <div v-for="(resultado, idx) in resultadobusca" :key="idx" class="resultado">
          <div>
            <p>Número da mesa:</p>
            <p>{{resultado['numerolocal']}}</p>
          </div>
          <div>
            <p>Número da reserva:</p>
            <p>{{resultado['numreserva']}}</p>
          </div>
          <div>
            <p>Nome do Hóspede:</p>
            <p>{{resultado['nome']}}</p>
          </div>
          <div>
            <p>Status:</p>
            <p>{{resultado['statuscomanda']}}</p>
          </div>
          <div v-if="resultado['idstatus'] != 7">
            <!-- <v-btn @click="requistarSetorDaBusca(resultado)">ver pedido</v-btn> -->
            <v-btn @click="requistarSetorDaBuscaRefatorado(resultado)">ver pedido</v-btn>
          </div>
        </div>
        <div class="resultado sem-resultado" v-if="resultadobusca && resultadobusca.length === 0">sem resultados</div>
      </div>
    </div>


    <v-dialog v-model="exibirbandeira" width="70%"  style="min-height:90vh;">
        <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;padding-left:10%;padding-right:10%;">


              <div @click="setarIdioma('pt-BR')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                  <div style="width:30%;">
                      <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                          <flag iso="br" style="border-radius:100px;width:27px;height:27px;" />
                      </div>
                  </div>
                  <div style="width:70%;display:flex;align-items:center;">
                      Português<v-icon  v-if="bandeira == 'br'">mdi-check</v-icon>
                  </div>
              </div>

              <div @click="setarIdioma('en')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                  <div style="width:30%;">
                      <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                          <flag iso="us" style="border-radius:100px;width:27px;height:27px;" />
                      </div>
                  </div>
                  <div style="width:70%;display:flex;align-items:center;">
                      English<v-icon  v-if="bandeira == 'us'">mdi-check</v-icon>
                  </div>
              </div>

              <div @click="setarIdioma('es')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                  <div style="width:30%;">
                      <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                          <flag iso="es" style="border-radius:100px;width:27px;height:27px;" />
                      </div>
                  </div>
                  <div style="width:70%;display:flex;align-items:center;">
                      Spanish<v-icon  v-if="bandeira == 'es'">mdi-check</v-icon>
                  </div>
              </div>


              
                  <v-btn class="botaoVoltar" color="error" @click="exibirbandeira = false">FECHAR</v-btn>

            
        </v-card>
    </v-dialog>

</div>
</template>

<script>

import Vue from 'vue';
import FlagIcon from 'vue-flag-icon'

Vue.use(FlagIcon);

export default {
    props:[],
    computed: {
      titulo(){
        return this.$store.getters.get_titulo
      },
      perfil(){
        return this.$store.getters.get_idperfil
      }
    },
    data: () => ({
        nomeHotel: "",
        bandeira:'br',
        exibirbandeira:false,
        drawer:false,
        coduh:null,
        numeroreserva:null,
        tipousuario:null,
        nomeusuario:null,
        pathHome : localStorage.getItem("tipo_usuario") == "HOSPEDE"  ? '/Hospede/Bemvindo' : '/Adm/Caixa',
        items:[
          // {icon:'silverware',title:"CARDÁPIO", path:'/Hospede/Menu'},
          {icon:'silverware',title:"CARDÁPIO", path:'/Hospede/Menu'},
          {icon:'cart',title:"CARRINHO", path:'/Hospede/Carrinho'},
          {icon:'clipboard-edit-outline',title:"PEDIDOS", path:'/Hospede/Pedidos'},
          {icon:'bell-badge',title:"NOTIFICAÇÕES", path:'/Hospede/Menu'},
          {icon:'logout',title:"SAIR", path:'logout'},
        ],
        modalbusca: false,
        busca: null,
        resultadobusca: [],
        menucardapio:'CARDÁPIO',
        menucarrinho    :'CARRINHO',
        menupedidos     :'PEDIDOS',
        menunotificacoes:'NOTIFICAÇÕES',
        menupagar       :'PAGAR',
        menucomprovantes:'COMPROVANTES',
        menusair        :'SAIR',

        headersComandas: [
          { text: 'Comanda', value: 'id_comanda' },
          { text: 'Produto', value: 'nome_produto' },
          { text: 'Comanda Aberta?', value: 'comanda_aberta' },
          { text: 'Status', value: 'status' },
          { text: 'Local', value: 'local_entrega' },
          { text: 'Motivo', value: 'motivo' }
        ],
        pesquisaDeComanda: '',
        comandasNaoIntegradas: [],

        dialogComandasNaoIntegradas: false,
        tipoIntegracao: 'HITS'

    }),
    methods:{
      setPathHome() {
        const pathInItens = this.items.findIndex(x => x == {icon:'cart',title:"CARRINHO", path:'/Hospede/Carrinho'})

        if(localStorage.getItem("tipo_usuario") == "HOSPEDE") {
          const logadoPelaMesa = JSON.parse(localStorage.getItem("login_mesa"));

          if(logadoPelaMesa) {
            const params = JSON.parse(localStorage.getItem("params_login_mesa"));
            this.pathHome = '/Hospede/PDVMesa'
            this.pathHome += `?pdvs=${params.pdvs.join(",")}`;
            if(params.mesa) {
              this.pathHome += `&mesa=${params.mesa}`;
            }
          } else {
            this.pathHome = '/Hospede/Bemvindo'
          }
        } else {
          this.pathHome = '/Adm/Caixa'
        }

        if(pathInItens != -1) {
          this.items[pathInItens].path = this.pathHome
        }
      },
      verificaPathCardapioHospede(items) {
        const logadoPelaMesa = JSON.parse(localStorage.getItem("login_mesa"));

        let path;

        let params;

        if(logadoPelaMesa) {
          params = JSON.parse(localStorage.getItem("params_login_mesa"));
          path = '/Hospede/PDVMesa'

          const pdvs = params.pdvs.split(",")

          for(const pdv of pdvs) {
            if(!path.includes("?")) {
              path += `?pdvs=${pdv}`
            } else {
              path += `&pdvs=${pdv}`
            }
          }

          if(params.mesa) {
            path += `&mesa=${params.mesa}`;
          }
        } else {
          path = '/Hospede/Bemvindo'
        }

        const findIndexMenu = items.findIndex(x => x.path == '/Hospede/Menu')
        const findLogOut = items.findIndex(x => x.path == '/logout')

        if(findIndexMenu != -1) {
          items[findIndexMenu].path = path

          let pathLogout = '/loginpdvmesa'

          if(params) {
            pathLogout += `?pdvs=${params.pdvs}`
            if(params.mesa) {
              pathLogout += `&mesa=${params.mesa}`
            }
          }

          items[findLogOut].path = pathLogout
        }

        return items;
      },
      fecharModalComandasNaoIntegradas(){

        const idusuario = localStorage.getItem("idusuario")

        const data = {
          usuarioCiente: idusuario
        }

        this.$http.post('/pdv/cienteComandaNaoIntegradas', data).then(response => {
          if(response.data == 'OK'){
            this.dialogComandasNaoIntegradas = false
          }
        })
        .catch(error => {
          console.log(error)
        })

        this.dialogComandasNaoIntegradas = false
      },
      setarIdioma(idioma){

            if(idioma == 'pt-BR'){
                this.$i18n.locale = 'pt-BR'
                this.bandeira = 'br'
            }

            if(idioma == 'en'){
                this.$i18n.locale = 'en'
                this.bandeira = 'us'
            }

            if(idioma == 'es'){
                this.$i18n.locale = 'es'
                this.bandeira = 'es'
            }

           if(this.$store.getters.get_idioma == 'us'){

                this.menucardapio  = 'MENU'
                this.menucarrinho      = 'CART'
                this.menupedidos       = 'ORDERS'
                this.menunotificacoes  = 'NOTIFICATIONS'
                this.menupagar         = 'PAY'
                this.menucomprovantes  = 'VOUCHERS'
                this.menusair          = 'LOG OFF'
          }

          if(this.$store.getters.get_idioma == 'es'){

                this.menucardapio  = 'MENÚ'
                this.menucarrinho      = 'CARRO'
                this.menupedidos       = 'PETICIONES'
                this.menunotificacoes  = 'NOTIFICACIONES'
                this.menupagar         = 'PAGAR'
                this.menucomprovantes  = 'BONOS'
                this.menusair          = 'SALIR'
          }

            this.$store.dispatch("set_idioma",  this.bandeira);
            // this.localStorage.setItem('idioma',this.bandeira)
            window.location.reload()

      },
      direcionar(path){
        if(path === 'logout'){
          localStorage.clear();
          this.$router.push('/')
        }else{
          this.$router.push(path)
        }
      },
      direcionarHome(path){
        this.$router.push(path)
      },
      enviarparanotificacao(){
        if(localStorage.getItem('tipo_usuario') == 'HOSPEDE'){
          this.$router.push('/Hospede/Notificacoes')
        }else{
          this.$router.push('/Adm/Notificacoes')
        }
      },
      abrirBusca(){
        this.modalbusca = true
        this.$http('/pdv/buscarInformacaoPedido', {params: {busca: this.busca}})
        .then(res => {
          this.resultadobusca = [...res.data]
        })
        .catch(e => {
          console.log(e)
        })
      },
      fecharBusca(){
        this.modalbusca = false
        this.busca = null
        this.resultadobusca = []
      },
      async requistarSetorDaBusca(resultado){
        if(resultado.idstatus == 1){
          // idstatus = registrada

        }else if(resultado.idstatus == 2){
          // idstatus = caixa
          this.$router.push(`/Adm/Caixa?busca=${this.busca}&numpedido=${resultado.numpedido}`)
          this.fecharBusca()
        }else if(resultado.idstatus == 3){
          // idstatus = producao
          await this.$http('/pdv/buscarInformacaoPedidoSetor', {params: {busca: resultado.numpedido}})
          .then(res => {
            if(res.data[0] == 'cozinha'){
              this.$router.push(`/Adm/Cozinha?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
              return
            }
            if(res.data[0] == 'bar'){
              this.$router.push(`/Adm/Bar?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
              return
            }
          })
          .catch(e => {
            console.log(e)
          })


        }else if(resultado.idstatus == 4){
          // idstatus = pronto cozinha
          // se so existir um tipo de item nesse pedido, enviar para garcom
          await this.$http('/pdv/buscarInformacaoPedidoSetor', {params: {busca: resultado.numpedido}})
          .then(resp => {
            if(resp.data[0] == 'cozinha' && resp.data[1] == 'apenas'){
              this.$router.push(`/Adm/Garcom?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }else if(resp.data[0] == 'bar' && resp.data[1] == 'apenas'){
              this.$router.push(`/Adm/Garcom?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }else{
              this.$router.push(`/Adm/Bar?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }

          })

        }else if(resultado.idstatus == 5){
          // idstatus = entregue
          this.$router.push(`/Adm/Baixas?busca=${this.busca}&numpedido=${resultado.numpedido}`)
          this.fecharBusca()


        }else if(resultado.idstatus == 6){
          // idstatus = cancelado

        }else if(resultado.idstatus == 7){
          // idstatus = baixado

        }else if(resultado.idstatus == 8){
          // idstatus = pronto bar
          // se so existir um tipo de item nesse pedido, enviar para garcom
          await this.$http('/pdv/buscarInformacaoPedidoSetor', {params: {busca: resultado.numpedido}})
          .then(resp => {

            if(resp.data[0] == 'cozinha' && resp.data[1] == 'apenas'){
              this.$router.push(`/Adm/Garcom?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }else if(resp.data[0] == 'bar' && resp.data[1] == 'apenas'){
              this.$router.push(`/Adm/Garcom?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }else{
              this.$router.push(`/Adm/Cozinha?busca=${this.busca}&numpedido=${resultado.numpedido}`)
              this.fecharBusca()
            }

          })

        }else if(resultado.idstatus == 9){
          // idstatus = pronto
          this.$router.push(`/Adm/Garcom?busca=${this.busca}&numpedido=${resultado.numpedido}`)
          this.fecharBusca()

        }
      },
      async requistarSetorDaBuscaRefatorado({idstatus, numpedido}){

        const enumStatusComanda = {
          1: 'registrada',
          2: 'caixa',
          3: 'producao',
          4: 'prontoCozinha',
          5: 'pedidoEntregue',
          6: 'pedidoCancelado',
          // 7: 'pedidoBaixado',
          8: 'prontoBar',
          9: 'pronto',
          10: 'producaoCozinha',
          11: 'producaoBar'
        }

        const statusComanda = enumStatusComanda[idstatus]

        const redirecionamento = {
          registrado: `/Adm/Caixa?busca=${this.busca}&numpedido=${numpedido}`,
          caixa: `/Adm/Caixa?busca=${this.busca}&numpedido=${numpedido}`,
          producaoCozinha: `/Adm/Cozinha?busca=${this.busca}&numpedido=${numpedido}`,
          pedidoEntregue: `/Adm/Baixas?busca=${this.busca}&numpedido=${numpedido}`,
          pedidoCancelado: `/Adm/Baixas?busca=${this.busca}&numpedido=${numpedido}`,
          // pedidoBaixado: `/Adm/Baixas?busca=${this.busca}&numpedido=${numpedido}`,
          pronto: `/Adm/Garcom?busca=${this.busca}&numpedido=${numpedido}`,
          producaoBar: `/Adm/Bar?busca=${this.busca}&numpedido=${numpedido}`
        }

        if(statusComanda === undefined) return

        if(
          statusComanda === 'producao' ||
          statusComanda === 'prontoCozinha' ||
          statusComanda === 'prontoBar'
        ) {
          await this.$http('/pdv/buscarInformacaoPedidoSetor', {params: {busca: numpedido}})
            .then(({data}) => {
              const primeiro = data[0]
              const segundo = data[1]
              if(primeiro == 'cozinha' && segundo == 'apenas') {
                this.$router.push(redirecionamento['producaoCozinha'])
              } else if (primeiro == 'bar' && segundo == 'apenas') {
                this.$router.push(redirecionamento['producaoBar'])
              } else {
                this.$router.push(redirecionamento['producaoCozinha'])
              }
              this.fecharBusca()
            })
        } else {
          this.$router.push(redirecionamento[statusComanda])
          this.fecharBusca()
        }
      },
      atribuiFuncao(ids){
        const users = {
            master: [
                {icon: 'mdi-briefcase', title: 'ETAPAS', subLinks: [
                  {icon:'mdi-slot-machine',title:"CAIXA", path:'/Adm/Caixa'},
                  {icon:'mdi-silverware',title:"COZINHA", path:'/Adm/Cozinha'},
                  {icon:'mdi-account-tie',title:"GARÇOM", path:'/Adm/Garcom'},
                  {icon:'mdi-glass-cocktail',title:"BAR", path:'/Adm/Bar'},
                  {icon:'mdi-download-circle',title:"BAIXAS", path:'/Adm/Baixas'},
                  {icon:'mdi-exclamation',title:"PENDENTES", path:'/Adm/Pendentes'},
                ]},
                {icon: 'mdi-office-building-cog-outline', title: 'CONFIGURAÇÕES', subLinks: [
                  {icon:'mdi-lan',title:"LOCAL x PDV", path:'/Adm/LocalPDV'},
                  {icon:'mdi-cog',title:"GERAIS", path:'/Adm/Configuracoes'},
                  {icon:'mdi-account-cash',title:"COMISSIONAMENTO", path:'/Adm/ComissaoRegras'},
                  {icon:'mdi-clipboard-list',title:"CARDAPIO", path:'/Adm/Cardapio'},
                  {icon:'mdi-account-group',title:"CADASTRO USUÁRIOS", path:'/Adm/CadastroUsuario'},
                ]},

                {icon:'mdi-table-chair', title:"MESAS", path:'/Adm/Mesas'},
                {icon:'mdi-account-search',title:"HOSPEDES", path:'/Adm/Hospedes'},
                {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
                {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
                {icon:'mdi-logout',title:"SAIR", path:'/logout'},
            ],
            caixa: [
                {icon:'mdi-slot-machine',title:"CAIXA", path:'/Adm/Caixa'},
                {icon:'mdi-download-circle',title:"BAIXAS", path:'/Adm/Baixas'},
                {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
                {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
                {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
                {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
                {icon:'mdi-logout',title:"SAIR", path:'/logout'},

            ],
            cozinha: [
                {icon:'mdi-silverware',title:"COZINHA", path:'/Adm/Cozinha'},
                {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
                {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
                {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
                {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
                {icon:'mdi-logout',title:"SAIR", path:'/logout'},
            ],
            bar: [
                {icon:'mdi-glass-cocktail',title:"BAR", path:'/Adm/Bar'},
                {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
                {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
                {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
                {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
                {icon:'mdi-logout',title:"SAIR", path:'/logout'},
            ],
            garcom: [
                {icon:'mdi-account-tie',title:"GARÇOM", path:'/Adm/Garcom'},
                {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
                {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
                {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
                {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
                {icon:'mdi-logout',title:"SAIR", path:'/logout'},
            ]
          }


          const tuorCardapio = JSON.parse(localStorage.getItem("tuorCardapio")) || {}
          if(!tuorCardapio?.hospedes) {
            for(const [key, value] of Object.entries(users)) {
                const pathHospedes = value.findIndex(x => x.path == "/Adm/Hospedes");
                users[key][pathHospedes].path = "/Adm/HospedesExemplo";
            }
          }

          if(!tuorCardapio?.configuracoes?.cardapio) {
            users.master[1].subLinks[3].path = "/Adm/CardapioTuor";
          }

          const exibirTelaBaixas = !JSON.parse(localStorage.getItem("exibir_tela_baixas"))
          if(localStorage.getItem("tipo_integracao") != 'ROBO') {
            if(exibirTelaBaixas) {
              for(const prop in users) {
                users[prop] = users[prop].filter(obj => obj.title !== "BAIXAS");
                if(prop === "master") {
                  for (const subLink of users[prop]) {
                    if (subLink.subLinks) {
                      subLink.subLinks = subLink.subLinks.filter(link => link.path !== '/Adm/Baixas');
                    }
                  }
                }
              }
            }
          }


        const enumUsers = {
          1: "master",
          2: "caixa",
          3: "cozinha",
          4: "bar",
          5: "garcom",
          6: "bar"
        }

        const atribuicoes = ids.map(id => enumUsers[id])
                                .includes("master") ? users.master : ids.map(id => enumUsers[id])
                                .map(perfil => users[perfil])

        const unificaFuncoes = atribuicoes.reduce((acc, cur) => acc.concat(cur), []).filter(perfil => perfil !== undefined);

        function removeDuplicados(array) {
          const chavesUnicas = {};
          return array.filter((obj) => {
            const chave = obj.title + obj.path;
            if (!chavesUnicas[chave]) {
              chavesUnicas[chave] = true;
              return true;
            }
            return false;
          });
        }

        const tiraDuplicacoes = removeDuplicados(unificaFuncoes)

        function ordenaMenuItens(itens) {
          const firstTitles = ["CAIXA", "COZINHA", "GARÇOM", "BAR", "BAIXAS", "PENDENTES"];

          itens.sort((a, b) => {
            const prioridade = a.title;
            const resto = b.title;
            if (firstTitles.includes(prioridade) && !firstTitles.includes(resto)) {
              return -1;
            } else if (!firstTitles.includes(prioridade) && firstTitles.includes(resto)) {
              return 1;
            } else {
              return 0;
            }
          });

          return itens;
        }

        const itemsOrdenados = ordenaMenuItens(tiraDuplicacoes)

        return itemsOrdenados
      },
      async getComadasNaoIntegradas() {
        await this.$http('pdv/listarComandasNaoIntegradas').then(res => {
          this.comandasNaoIntegradas = res.data || []
        })
      }
    },
    async mounted(){

      const tipoIntegracao = localStorage.getItem("tipo_integracao")

      this.tipoIntegracao = tipoIntegracao

      if(this.tipousuario != 'HOSPEDE') {
        this.getComadasNaoIntegradas()
  
        this.sockets.subscribe('notificar_comandas_nao_integradas', async () => {
           this.getComadasNaoIntegradas()
          this.dialogComandasNaoIntegradas = true
        })
      }

      const nomeHotelLogado = localStorage.getItem("nomehotel")

      if(nomeHotelLogado && nomeHotelLogado != 'undefined'){
        this.nomeHotel = nomeHotelLogado
      }

      if(this.$store.getters.get_idioma){

            if(this.$store.getters.get_idioma  == 'pt-BR'){
                this.$i18n.locale = 'pt-BR'
                this.bandeira = 'br'
            }

            if(this.$store.getters.get_idioma == 'us'){
                this.$i18n.locale = 'en'
                this.bandeira = 'us'
            }

            if(this.$store.getters.get_idioma == 'es'){
                this.$i18n.locale = 'es'
                this.bandeira = 'es'
            }
      }
      this.coduh = localStorage.getItem('coduh')
      this.numeroreserva = localStorage.getItem('numreserva')
      this.nomeusuario = localStorage.getItem('usuario')
      this.tipousuario = localStorage.getItem('tipo_usuario')

      if(localStorage.getItem("pessoaLogada") && localStorage.getItem("pessoaLogada")  === 'false') {
        this.items =
          [
            {icon:'mdi-silverware',title:this.menucardapio, path:'/Hospede/Menu'},
            {icon:'mdi-cart',title:this.menucarrinho, path:'/Hospede/Carrinho'},
            {icon:'mdi-login',title: 'FAZER LOGIN', path:'/'},
          ]

      } else {
        if(this.tipousuario == 'HOSPEDE'){
          let pagamentoliberado = false
          let emailliberado = null

            await  this.$http.post('pdv/buscarparametros',{chave:'PAGAMENTOS'})
              .then(resp =>{

                  pagamentoliberado = resp.data[0].valor === 'true' ? true:false
                  emailliberado = resp.data[0].valor2
              }).catch(error =>{
                  alert(error)
              })






          if(this.$store.getters.get_idioma == 'us'){

                this.menucardapio  = 'MENU'
                this.menucarrinho      = 'CART'
                this.menupedidos       = 'ORDERS'
                this.menunotificacoes  = 'NOTIFICATIONS'
                this.menupagar         = 'PAY'
                this.menucomprovantes  = 'VOUCHERS'
                this.menusair          = 'LOG OFF'
          }

          if(this.$store.getters.get_idioma == 'es'){

                this.menucardapio  = 'MENÚ'
                this.menucarrinho      = 'CARRO'
                this.menupedidos       = 'PETICIONES'
                this.menunotificacoes  = 'NOTIFICACIONES'
                this.menupagar         = 'PAGAR'
                this.menucomprovantes  = 'BONOS'
                this.menusair          = 'SALIR'
          }




          if(pagamentoliberado){
                if(!emailliberado){
                  this.items = [
                    {icon:'mdi-silverware',title:this.this.menucardapio, path:'/Hospede/Menu'},
                    {icon:'mdi-cart',title:this.menucarrinho, path:'/Hospede/Carrinho'},
                    {icon:'mdi-clipboard-edit-outline',title:this.menupedidos, path:'/Hospede/Pedidos'},
                    {icon:'mdi-bell-badge',title:this.menunotificacoes, path:'/Hospede/Notificacoes'},
                    {icon:'mdi-cash-multiple',title:this.menupagar, path:'/Hospede/Pagar'},
                    {icon:'mdi-cash-multiple',title:this.menucomprovantes, path:'/Hospede/Comprovantes'},
                    {icon:'mdi-logout',title:this.menusair, path:'/logout'},
                  ]
                }else{
                      if(emailliberado && emailliberado == localStorage.getItem('email')){
                            this.items = [
                              {icon:'mdi-silverware',title:this.menucardapio, path:'/Hospede/Menu'},
                              {icon:'mdi-cart',title:this.menucarrinho, path:'/Hospede/Carrinho'},
                              {icon:'mdi-clipboard-edit-outline',title:this.menupedidos, path:'/Hospede/Pedidos'},
                              {icon:'mdi-bell-badge',title:this.menunotificacoes, path:'/Hospede/Notificacoes'},
                              {icon:'mdi-cash-multiple',title:this.menupagar, path:'/Hospede/Pagar'},
                              {icon:'mdi-cash-multiple',title:this.menucomprovantes, path:'/Hospede/Comprovantes'},
                              {icon:'mdi-logout',title:this.menusair, path:'/logout'},
                            ]
                      }else{
                            this.items = [
                                  {icon:'mdi-silverware',title:this.menucardapio, path:'/Hospede/Menu'},
                                  {icon:'mdi-cart',title:this.menucarrinho, path:'/Hospede/Carrinho'},
                                  {icon:'mdi-clipboard-edit-outline',title:this.menupedidos, path:'/Hospede/Pedidos'},
                                  {icon:'mdi-bell-badge',title:this.menunotificacoes, path:'/Hospede/Notificacoes'},
                                  {icon:'mdi-logout',title:this.menusair, path:'/logout'},
                                ]
                      }
                }
          }else{
            const itemsVerificar = [
              {icon:'mdi-silverware',title:this.menucardapio, path:'/Hospede/Menu'},
              {icon:'mdi-cart',title:this.menucarrinho, path:'/Hospede/Carrinho'},
              {icon:'mdi-clipboard-edit-outline',title:this.menupedidos, path:'/Hospede/Pedidos'},
              {icon:'mdi-bell-badge',title:this.menunotificacoes, path:'/Hospede/Notificacoes'},
              {icon:'mdi-logout',title:this.menusair, path:'/logout'},
            ]
            this.items = this.verificaPathCardapioHospede(itemsVerificar)
          }
        }else{

          this.items = this.atribuiFuncao(this.perfil)

          //usuario master
          // if(this.perfil.includes(1)){
          //   // if(this.perfil.includes() == 1){
          //     this.items = [
          //       {icon: 'mdi-briefcase', title: 'ETAPAS', subLinks: [
          //         {icon:'mdi-slot-machine',title:"CAIXA", path:'/Adm/Caixa'},
          //         {icon:'mdi-silverware',title:"COZINHA", path:'/Adm/Cozinha'},
          //         {icon:'mdi-account-tie',title:"GARÇOM", path:'/Adm/Garcom'},
          //         {icon:'mdi-glass-cocktail',title:"BAR", path:'/Adm/Bar'},
          //         {icon:'mdi-download-circle',title:"BAIXAS", path:'/Adm/Baixas'},
          //         {icon:'mdi-exclamation',title:"PENDENTES", path:'/Adm/Pendentes'},
          //       ]},
          //       {icon: 'mdi-office-building-cog-outline', title: 'CONFIGURAÇÕES', subLinks: [
          //         {icon:'mdi-lan',title:"LOCAL x PDV", path:'/Adm/LocalPDV'},
          //         {icon:'mdi-cog',title:"GERAIS", path:'/Adm/Configuracoes'},
          //         {icon:'mdi-account-cash',title:"COMISSÃO", path:'/Adm/ComissaoConfig'},
          //         {icon:'mdi-clipboard-list',title:"CARDAPIO", path:'/Adm/Cardapio'},
          //         {icon:'mdi-account-group',title:"CADASTRO USUÁRIOS", path:'/Adm/CadastroUsuario'},
          //       ]},

          //       {icon:'mdi-table-chair', title:"MESAS", path:'/Adm/Mesas'},
          //       {icon:'mdi-account-search',title:"HOSPEDES", path:'/Adm/Hospedes'},

          //       {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       // {icon:'bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},

          //       {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       // {icon:'mdi-credit-card-outline',title:"PAGAMENTOS", path:'/Adm/Pagamentos'},
          //       {icon:'mdi-logout',title:"SAIR", path:'/logout'},
          //     ]
          // }

          // //usuario CAIXA
          // if(this.perfil.includes(2)){
          //   // if(this.perfil.includes() == 2){
          //     this.items = [
          //       {icon:'mdi-slot-machine',title:"CAIXA", path:'/Adm/Caixa'},
          //       {icon:'mdi-download-circle',title:"BAIXAS", path:'/Adm/Baixas'},
          //       {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
          //       {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
          //       {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       // {icon:'mdi-credit-card-outline',title:"PAGAMENTOS", path:'/Adm/Pagamentos'},
          //       {icon:'mdi-logout',title:"SAIR", path:'/logout'},

          //     ]
          // }

          // //usuario COZINHA
          // if(this.perfil.includes(3)){
          //   // if(this.perfil.includes() == 3){
          //     this.items = [
          //       {icon:'mdi-silverware',title:"COZINHA", path:'/Adm/Cozinha'},
          //       {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
          //       {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
          //       {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       {icon:'mdi-logout',title:"SAIR", path:'/logout'},
          //     ]
          // }

          // //usuario BAR
          // if(this.perfil.includes(4 || 6)){
          //   // if(this.perfil.includes() == 4 || this.perfil.includes() == 6){
          //     this.items = [
          //       {icon:'mdi-glass-cocktail',title:"BAR", path:'/Adm/Bar'},
          //       {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
          //       {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
          //       {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       {icon:'mdi-logout',title:"SAIR", path:'/logout'},
          //     ]
          // }

          // //usuario GARCOM
          // if(this.perfil.includes(5)){
          //   // if(this.perfil.includes() == 5){
          //     this.items = [
          //       {icon:'mdi-account-tie',title:"GARÇOM", path:'/Adm/Garcom'},
          //       {icon:'mdi-account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
          //       {icon:'mdi-file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       {icon:'mdi-bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
          //       {icon:'mdi-school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       {icon:'mdi-logout',title:"SAIR", path:'/logout'},
          //     ]
          // }

          // //usuario BAIXA
          // if(this.perfil == 6){
          //     this.items = [
          //       {icon:'download-circle',title:"BAIXAS", path:'/Adm/Baixas'},
          //       {icon:'account-multiple',title:"HOSPEDES", path:'/Adm/Hospedes'},
          //       {icon:'file-chart',title:"RELATORIOS", path:'/Adm/Relatorio'},
          //       {icon:'bell-badge',title:"NOTIFICAÇÕES", path:'/Adm/Notificacoes'},
          //       {icon:'school',title:"TUTORIAIS", path:'/Adm/Tutoriais'},
          //       {icon:'logout',title:"SAIR", path:'logout'},

          //     ]
          // }

        }

      }

      const isHits = tipoIntegracao.toLocaleLowerCase() === "hits"

      if(isHits && this.tipousuario != 'HOSPEDE') {
        await this.getComadasNaoIntegradas()

        this.sockets.subscribe('notificar_comandas_nao_integradas', async () => {
          await this.getComadasNaoIntegradas()
          this.dialogComandasNaoIntegradas = true
        })
      }

      this.setPathHome();

    }
}
</script>

<style lang="scss" scoped>
  .busca-container{
    position: absolute;
    inset: 0;
    backdrop-filter: blur(100px);
    z-index: 200;
    padding: 1em;
  }
  .busca-content{
    margin-top: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 1em;
  }
  .resultado{
    background: #f9f9f2;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
    column-count: 2;
    padding: 1em;

    div{
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%;

      p:nth-child(1){
        font-weight: bold;
      }
      p:nth-child(2){
        word-break: break-word;
      }
    }

    &.sem-resultado{
      font-weight: bold;
      display: grid;
      place-content: center;
      text-transform: capitalize;
    }
  }

//css indesejado
.v-application p {
  margin-bottom: unset !important;
}
</style>
