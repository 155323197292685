<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logodiv" style="background-colo: red">
        <v-img src="/imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
      </div>
      <div>
               
          <div>
            <div style="color:white;font-weight:bold;">Email(Opicional)</div>
            <v-text-field
              v-model="email"
              data-cy="email"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white" style="height:50px;"
            ></v-text-field>
          </div>

          <div v-if="camposliberados">
                <div style="">
                  <div style="color:white;font-weight:bold;">Nome</div>
                  <v-text-field
                    v-model="nome"
                    data-cy="nome"
                    dense
                    outlined
                    autocomplete="off"
                    clearable
                    class="inputtext" style="height:50px;"
                    background-color="white"
                  ></v-text-field>
                </div> 

                <div>
                  <div style="color:white;font-weight:bold;">Cpf</div>
                  <v-text-field
                    v-model="cpf"
                    data-cy="cpf"
                    dense
                    outlined
                    autocomplete="off"
                    clearable
                    class="inputtext"
                    background-color="white" style="height:50px;"
                  ></v-text-field>
                </div>          

                <div v-if="jaCadastradoAntes" style="margin-bottom:10px;">
                  <h1 style="color:white;font-weight:bold;">Senha: {{coduh}}</h1>
                </div>  

              <!-- <div>
                <div style="color:white;font-weight:bold;">Senha</div>
                <v-text-field
                  v-model="senha"
                  data-cy="senha"
                  dense
                  autocomplete="off"
                  type="password"
                  outlined
                  clearable
                  class="inputtext"
                  background-color="white" style="height:50px;"
                  maxlength="4"
                ></v-text-field>
              </div>
              <div>
                <div style="color:white;font-weight:bold;">Confirmar Senha</div>
                <v-text-field
                  v-model="senha1"
                  data-cy="senha1"
                  dense
                  autocomplete="off"
                  type="password"
                  outlined
                  clearable
                  class="inputtext"
                  background-color="white" style="height:60px;"
                  maxlength="4"
                ></v-text-field>
              </div> -->

              <v-btn v-if="!jaCadastradoAntes"
                large
                block
                color="info"
                class="white--text font-weight-bold"
                data-cy="btn-criarHospedeFake"
                v-on:click="criarHospedeFake()"
              >
                Cadastrar
                <v-icon right dark>mdi-login</v-icon>
              </v-btn>

          </div>

          <div v-else>
              <v-btn
                large
                block
                color="info"
                class="white--text font-weight-bold"
                data-cy="btn-criarHospedeFake"
                v-on:click="verificarEmailPassante()"
              >
                Consultar Cadastro
                <v-icon right dark>mdi-login</v-icon>
              </v-btn>
          </div>

          <v-row justify="center" class="ma-0">
            <div class="mt-6 mb-4">
              <a href="/" data-cy="btn-login" class="white--text">
              Faça login
              </a>
            </div>
          </v-row>

          <v-row justify="center" class="mt-2">
            <div>
              <v-img src="/imgs/logoes.png" width="130" height="33"> </v-img>
            </div>
          </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      nome: null,
      email: null,
      cpf: null,
      senha: null,
      senha1: null,
      coduh:null,
      camposliberados:false,
      jaCadastradoAntes:false,
    };
  },

  components: {},

  methods: {
        verificarEmailPassante(){

                this.$http.post(`hospedes/verificarEmailPassante`,{email:this.email}).then((resp) =>{
                    
                    let dados = resp.data[0]
                    this.nome = dados.nome
                    this.cpf = dados.cpf
                    this.coduh = dados.coduh
                    this.camposliberados = true
                    this.jaCadastradoAntes = true

                    this.$alertar("success","Email Já Cadastrado, sua SENHA é: "+dados.coduh+" !","=D")

                }).catch(() =>{
                  this.$alertar("warning","Email não encontrado","Cadastre-se")
                    // this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                    this.camposliberados = true
                })

        },
        validarDados(obj){


            if(!obj.nome){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }

            // if(!obj.email){
            //     this.$alertar("warning","Email não Informado!","=(")
            //     return false
            // }
            
            if(!obj.cpf){
                this.$alertar("warning","Cpf não Informado!","=(")
                //validar numero cpf
                return false
            }

            // if(!obj.senha){
            //     this.$alertar("warning","Senha não Informada!","=(")
            //     return false
            // }
            // if(!obj.senha1){
            //     this.$alertar("warning","Senha de confirmação não Informada!","=(")
            //     return false
            // }
            // if(obj.senha !== obj.senha1){
            //     this.$alertar("warning","As senhas informadas devem ser iguais.","=(")
            //     return false
            // }

            return true
        },
        criarHospedeFake(){
            
            let obj = {
                nome:this.nome,
                email:this.email,
                cpf: this.cpf,
                senha: this.senha,
                senha1: this.senha1
            }

            if(this.validarDados(obj)){
                delete obj.senha1
                this.$http.post(`hospedes/criarhospedefake`,obj).then((resp) =>{
                  
                    this.$alertar("success","Registro Criado Com sucesso, sua SENHA é "+resp.data.coduh+"!","=)")
                    this.$emit('atualizarLista')

                }).catch((error) =>{
                    this.$alertar("warning",JSON.stringify(error.response.data.message),"=((")
                    // console.log("error")
                    // console.log(JSON.stringify(error.response.data.message))
                })
            }
        },
  },
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
}
</style>