<template>
    <div class="bodygenerico">
      <div class="bodyFlex">
          <ColunaListaPedidos :ojbPedidos="ojbPedidos" @setarPedido="(pedido) => setarPedido(pedido)" :buscainicial="query"
          urlVideo="https://drive.google.com/file/d/1LsWPYUc6bwA05UbPOSxpXTzpE0DWwjfE/preview"/>
          <ColunaPedido v-if="pedido" :pedido="pedido"  @imprimirComanda="(pedido) => imprimirComanda(pedido)"
            :exibirnao="false"  @nao="(pedido) =>cancelarPedido(pedido)" @sim="(pedido) => baixarPedido(pedido)"
            @listarComandas="()=>listarPedidosIniciais(idstatuspadraotela)"
            :tela="'BAIXA'"
            textonao="Cancelar" textosim="Baixar Pedido3"
            urlVideo="https://drive.google.com/file/d/1LsWPYUc6bwA05UbPOSxpXTzpE0DWwjfE/preview"
              />



      </div>

      <ModalImpressaoPedido :showModal="showModal" :pedidoSelecionado="pedidoSelecionado" />
      <!-- MODAL IMPRESSAO -->


    </div>
</template>

<script>

import ColunaListaPedidos from '../../components/pedidos/ColunaListaPedidos.vue'
import ColunaPedido from '../../components/pedidos/ColunaPedido.vue'
import ModalImpressaoPedido from '../../components/layout/ModalImpressaoPedido.vue'

const dayjs = require('dayjs')

export default {
  name: 'ViewCaixa',
  components:{
    ColunaListaPedidos,
    ColunaPedido,
    ModalImpressaoPedido
  },
  data: () => ({
    exibirTaxa:false,
    idstatuspadraotela:5, //status entregue
    ojbPedidos:null,
    pedido:null,
    showModal:true,
    pedidoSelecionado:{
            nomehotel:localStorage.getItem('nomehotel'),
            idcomanda:0,
            nomehospede:'',
            numerolocal:'',
            datapedido:dayjs().format(),
            quantidade:0,
            precototal:0,
            itens:[{
                    nomeproduto:'',
                    valorunitario:0.00,
                    qtde:0
                }]
        },
    query: '',
    garcomPodeFecharPassante:true
  }),
  watch: {
    //quando eu faco uma pesquisa e sou redirecionada para a mesma pagina que estou
    '$route': function(to, from){
      if(to.path == from.path){
        if(to.query.numpedido !== from.query.numpedido){
          this.query = to.query.numpedido
        }
      }
    },
  },
  methods:{
      continuar(){

          this.$router.push('/Hospede/Menu')
      },
      async getComandasReservaPorStatus(idreservasfront,idstatus){
            let objetoRetorno = {}


            await this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOS'})
            .then(resp =>{
                let fechamentoAutomatico = JSON.parse(resp.data[0].valor6);
                if (fechamentoAutomatico) {
                    this.garcomPodeFecharPassante = fechamentoAutomatico.permitirFechamentoPassanteGarcom
                }
            }).catch(error =>{
                alert(error)
            })


            let url = 'pdv/getComandasReservaPorStatus'
            if(this.garcomPodeFecharPassante === false){
              url = 'pdv/getComandasReservaPorStatusBaixaPassante'
            }


            await this.$http(url+'/'+idreservasfront+'/'+idstatus+'/t').then((resp) =>{
              console.log(resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.listadepedidos = resp.data
              for(var i = 0, len = objetoRetorno.listadepedidos.length; i < len; i++){
                  objetoRetorno.valorTotal += parseFloat(objetoRetorno.listadepedidos[i].valor)
                  objetoRetorno.statuscomanda = objetoRetorno.listadepedidos[i].statuscomanda
                  this.exibirTaxa = objetoRetorno.listadepedidos[i].exibirtaxa == 'true' ? true:false
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
      },
      async getProdutosdoPedido(numpedido){
            let objetoRetorno = {}
            const url = `pdv/getProdutosdoPedido/${numpedido}/t/${this.idstatuspadraotela}`
            await this.$http(url).then((resp) =>{
              console.log(resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.valorTotalSemTaxa = 0
              objetoRetorno.numpedido = resp.data[0].idcomanda
              objetoRetorno.datafeito = resp.data[0].datahoraabertura
              objetoRetorno.datarecebido = resp.data[0].datahoraabertura
              objetoRetorno.imgassinatura = resp.data[0].imgassinatura
              objetoRetorno.idtipolocal = resp.data[0].idtipolocal
              objetoRetorno.numerolocal = resp.data[0].numerolocal
              objetoRetorno.numreserva = resp.data[0].numreserva
              objetoRetorno.coduh = resp.data[0].coduh
              objetoRetorno.nome = resp.data[0].nome
              objetoRetorno.sobrenome = resp.data[0].sobrenome
              objetoRetorno.idgarcominicial = resp.data[0].idgarcominicial
              objetoRetorno.nomegarcominicial = resp.data[0].nomegarcominicial
              objetoRetorno.idgarcomatual = resp.data[0].idgarcomatual
              objetoRetorno.idstatuscomanda = resp.data[0].idstatuscomanda
              objetoRetorno.nomegarcom = resp.data[0].nomegarcom
              objetoRetorno.nomepdv = resp.data[0].nomepdv
              objetoRetorno.passante = resp.data[0].passante
              objetoRetorno.id_tipo_hospede_hits = resp.data[0].id_tipo_hospede_hits
              objetoRetorno.listadepedidos = resp.data
              for(var i = 0, len = resp.data.length; i < len; i++){
                  // if(this.exibirTaxa){
                  //   objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
                  // }else{
                    // objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
                    objetoRetorno.valorTotal += (parseFloat(resp.data[i].valortotal))
                    objetoRetorno.valorTotalSemTaxa += (parseFloat(objetoRetorno.listadepedidos[i].valorsemtaxa))
                  // }
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
      },
      async setarPedido(objpedido){
        this.pedido = null
        this.pedido = await this.getProdutosdoPedido(objpedido.numpedido)
        this.pedido.tempocategoria = objpedido.tempocategoria
        this.pedido.temposubcategoria = objpedido.temposubcategoria

        let imagemAssinatura = null

        await this.$http('pdv/getassinaturacomanda/'+objpedido.numpedido).then(resp =>{
                imagemAssinatura= resp.data[0].imgassinatura
            }).catch(error =>{
                console.log(error)
        })

        this.pedidoSelecionado = {
                nomehotel:localStorage.getItem('nomehotel'),
                idcomanda:this.pedido.numpedido,
                nomehospede:this.pedido.nome,
                numerolocal:this.pedido.numerolocal,
                datapedido:dayjs(this.pedido.datafeito).format('DD/MM/YYYY HH:mm'),
                quantidade:this.pedido.listadepedidos.length,
                precototal:this.pedido.valorTotal,
                tempoCategoria:objpedido.tempoCategoria,
                tempoSubCategoria:objpedido.tempoSubCategoria,
                itens:this.pedido.listadepedidos,
                imgassinaturasrc:imagemAssinatura
            }


      },
      imprimirComanda() {
            this.showModal = true
            setTimeout(() => this.$htmlToPaper('modalImpressao'), 1000);
            // this.$htmlToPaper('modalImpressao')
      },
      async listarPedidosIniciais(idstatus){
        this.ojbPedidos = await this.getComandasReservaPorStatus(0,idstatus)

         if( this.ojbPedidos.listadepedidos &&  this.ojbPedidos.listadepedidos.length > 0){

              this.ojbPedidos.listadepedidos[0].atrasado= true
              this.exibirTaxa = this.ojbPedidos.listadepedidos[0].exibirtaxa == 'true' ? true:false
              this.pedido = await this.getProdutosdoPedido(this.ojbPedidos.listadepedidos[0].numpedido)

              let imagemAssinatura= null

              await this.$http('pdv/getassinaturacomanda/'+this.ojbPedidos.listadepedidos[0].numpedido).then(resp =>{
                      imagemAssinatura = resp.data[0].imgassinatura
                  }).catch(error =>{
                      console.log(error)
              })

              this.pedidoSelecionado = {
                        nomehotel:localStorage.getItem('nomehotel'),
                        idcomanda:this.pedido.numpedido,
                        nomehospede:this.pedido.nome,
                        numerolocal:this.pedido.numerolocal,
                        datapedido:dayjs(this.pedido.datafeito).format('DD/MM/YYYY HH:mm'),
                        quantidade:this.pedido.listadepedidos.length,
                        precototal:this.pedido.valorTotal,
                        tempoCategoria:this.pedido.tempoCategoria,
                        tempoSubCategoria:this.pedido.tempoSubCategoria,
                        itens:this.pedido.listadepedidos,
                        imgassinaturasrc:imagemAssinatura
                    }

         }else{
           this.pedido = null
         }


      },
      async baixarPedido(pedido){
        // console.log("PEDD")
        // console.log(pedido)






        let url = `pdv/alterarStatusPedidoEProdutos/${pedido.numpedido}/7`

        const dados = {
          id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario")
        }

        await this.$http.post(url, dados).then(()=>{
            this.$alertar("success","Pedido baixado!","=)")
            this.listarPedidosIniciais(this.idstatuspadraotela)
            this.pedido = null
          }).catch(()=>{
            this.$alertar("warning","Não foi possível Fazzer a Baixa do Pedido!","=(")
          })


        // let obj = {
        //               numpedido:pedido.numpedido,
        //               tipopagamento:pedido.tipoPagamentoSelecionado,
        //               valorpagamento:parseFloat( pedido.cobrartaxas.id == 1 ? pedido.valorTotal: pedido.valorTotalSemTaxa ),
        //               cobrartaxa:pedido.cobrartaxas.id,
        //               nsu:pedido.nsu
        //             }


        // let url = `pdv/alterarStatusPedidoEProdutos/${pedido.numpedido}/7`

        // if(this.garcomPodeFecharPassante === false){

        //               url = `pdv/alterarStatusPedidoEProdutos/${pedido.numpedido}/7/f`

        //               this.$http.post(`/pdv/enviarPagtoPassante`,obj).then(async resp =>{
        //                 if(resp.status == 200){
        //                   await this.$http.post(url).then(()=>{
        //                                 this.$alertar("success","Pedido baixado!","=)")
        //                                 this.listarPedidosIniciais(this.idstatuspadraotela)
        //                                 this.pedido = null
        //                               }).catch(()=>{
        //                                 this.$alertar("warning","Não foi possível Fazzer a Baixa do Pedido!","=(")
        //                               })

        //                 }
        //             }).catch(error =>console.log('ERRO RETNORO ',error))

        // }else{
        //         await this.$http.post(url).then(()=>{
        //             this.$alertar("success","Pedido baixado!","=)")
        //             this.listarPedidosIniciais(this.idstatuspadraotela)
        //             this.pedido = null
        //           }).catch(()=>{
        //             this.$alertar("warning","Não foi possível Fazzer a Baixa do Pedido!","=(")
        //           })
        // }







      },
      async cancelarPedido(pedido){
        // console.log("PEDD")
        // console.log(pedido)

        const dados = {
          id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario")
        }

        await this.$http.post(`pdv/alterarStatusPedidoEProdutos/${pedido.numpedido}/6`, dados).then(()=>{
           this.$alertar("success","Pedido Cancelado!","=)")
           this.listarPedidosIniciais(this.idstatuspadraotela)
        }).catch(()=>{
          this.$alertar("warning","Não foi possível Cancelar o Pedido!","=(")
        })



      },

  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Realizar Baixas")
      await this.listarPedidosIniciais(this.idstatuspadraotela)

      const { numpedido } = this.$route.query
      if(numpedido){
        this.query = numpedido
        let pedido = this.ojbPedidos.listadepedidos.filter(pedido => pedido.numpedido == numpedido)[0]
        this.setarPedido(pedido)
      }

  }
};
</script>


<style scoped>

</style>
