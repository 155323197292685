<template>
  <div class="" >
    <div class="bodyFlex" >
      <v-row justify="center">
        <v-card width="95%" >
          <!-- <BotaoTutorial style="max-width: 5%;width: 5%; display: flex;" categoria="14" legenda="" urlVideo="https://www.youtube.com/embed/6UkdXYkjwJU" /> -->
         

          <v-card-text>

              <v-row style="display: flex-line" class="pa-2">

                  <v-text-field append-icon="mdi-account" v-model="nome" data-cy="nome" label="Nome" class="mr-1 ml-1" style="width: 150px"
                    >
                  </v-text-field>

                  <v-text-field append-icon="mdi-account" v-model="email" data-cy="email" label="email" class="mr-1 ml-1" style="width: 150px"
                    >
                  </v-text-field>           

                  <v-text-field v-model="coduh" data-cy="coduh" append-icon="mdi-door" label="UH" class="mr-1 ml-1" style="width: 100px"
                    >
                  </v-text-field>
                  
                  <v-text-field v-model="datacheckin" data-cy="datacheckin" type="date" append-icon="mdi-clock"
                    label="Check-IN" placeholder="dd/mm/aaaa" class="mr-1 ml-1"
                    >
                  </v-text-field>

                  <v-text-field v-model="numreserva" data-cy="numreserva" append-icon="mdi-numeric" placeholder="Loc Reserva" label="Reserva" class="mr-1 ml-1"
                    style="width: 100px" >
                  </v-text-field>

                  <v-select v-model="nacasa" :items="itemsnacasa" label="Na Casa" item-text="nome" item-value="id" return-object class="mr-1 ml-1" ></v-select>

                  <v-select data-cy="tipo-hospede" v-model="tipohospede" :items="tiposhospede" label="Tipo" item-text="nome" item-value="id" return-object class="mr-1 ml-1" ></v-select> 
                  

              </v-row>

              <v-row style="display: flex-line;margin-bottom:30px;" class="mr-1 ml-1 mt-1 mb-2">
                  <v-btn 
                    class="mr-2" 
                    v-if="(($store.getters.get_idperfil == 5 && garcomcadastrapassante == true) || ($store.getters.get_idperfil != 5)) "  
                    v-show="tipointegracao !== 'ADMH'"
                    @click="modalCheckinFake = true" 
                    data-cy="btn-criarhospedefake" 
                    title="Buscar" 
                    color="info" 
                  >
                    Criar Hospede Passante
                  </v-btn>

                  <v-btn 
                    class="mr-2" 
                    v-if="(($store.getters.get_idperfil == 5 && garcomcadastrapassante == true) || ($store.getters.get_idperfil != 5)) "  
                    v-show="tipointegracao === 'ADMH'"
                    @click="modalContaAvulsaADMH = true" 
                    data-cy="btn-criarhospedefake" 
                    title="Buscar" 
                    color="info" 
                  >
                    Criar Conta Avulsa
                  </v-btn>
                  <v-spacer></v-spacer>              
                  <v-btn
                      class="mr-2"
                      style="width:200px;margin-right:20px;"
                      @click="pesquisar()"
                      data-cy="btn-buscar"
                      title="Buscar"
                      color="info"
                      :disabled="botaoBuscandoHospede"
                  >
                      {{ botaoBuscandoHospede ? "Buscando..." : "Buscar" }}
                  </v-btn >
                  <v-btn
                      class="mr-2"
                      style="width:200px"
                      @click="limpar()"
                      data-cy="btn-limpar"
                      title="Limpar"
                      color="info"
                      :disabled="botaoBuscandoHospede"
                  >
                    Limpar
                  </v-btn >

                  <!-- <v-btn class="mr-2" style="width:200px" @click="importarCheckins()" data-cy="btn-limpar" title="Limpar" color="info" >Importar</v-btn > -->

              </v-row> 

              <v-row style="display: flex-line;justify-content:flex-end;padding-right:8px;" class="mr-1 ml-1 mt-1 mb-2">

                  <v-btn @click="voltar()"  elevation="" color="default" style="margin-right:10px;width:200px;" :disabled="botaoBuscandoHospede">Voltar</v-btn>
                  <v-btn @click="avancar()"  elevation="" color="default" style="width:200px" :disabled="botaoBuscandoHospede">Avançar</v-btn>

              </v-row> 

              <div style="min-height: 400px;">

                      <v-expansion-panels inset>

                            <v-expansion-panel v-for="(hospede, index) in hospedes" :key="hospede.idcheckin"  >

                                    <v-expansion-panel-header v-show="tipointegracao !== 'ADMH'" data-cy="hospede-painel" @click="senhavisivel = null">
                                          <span v-if="tipointegracao!='HITS'" data-cy="hospede-nome">{{ hospede.nome + `(${hospede.passante?'Passante':'Hospede'})` }}</span>
                                          <span v-else data-cy="hospede-nome">{{ hospede.nome +` (${hospede.passante?'Passante':(hospede.id_tipo_hospede_hits == 2? 'Day Use': (hospede.id_tipo_hospede_hits == 3?'Eventos':'Hospede') )}) ` }}</span>
                                          , UH: {{ hospede.coduh }} | Res Nº:{{ hospede.numreserva }}
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-header v-show="tipointegracao === 'ADMH'" data-cy="hospede-painel" @click="senhavisivel = null">
                                      <span v-if="hospede.passante">
                                        {{ hospede.nome + `(Mesa)` }}
                                      </span>
                                      <span v-else>
                                        {{ hospede.nome + `(${hospede.id_tipo_hospede_hits == 2 ? 'Avulso' : 'Hospede'})` }}
                                      </span>
                                      , UH: {{ hospede.coduh }} | Res Nº:{{ hospede.numreserva }}
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>

                                            <v-row justify="start">
                                                Reserva: {{ hospede.numreserva }}
                                            </v-row>

                                            <v-row justify="start">
                                              IN: {{ hospede.datacheckin |formatarData }}
                                            </v-row>

                                            <v-row justify="start">
                                              OUT: {{ hospede.datacheckoutprev |formatarData}}
                                            </v-row>

                                            <v-row justify="start">
                                                Email: {{hospede.email}}
                                            </v-row>

                                            <v-row justify="start">
                                                <span v-if="senhavisivel === hospede.senha+hospede.numreserva">
                                                  <button type="button" @click="senhaVisivel(hospede.senha+hospede.numreserva)">
                                                    <v-icon>mdi-eye-off-outline</v-icon>
                                                  </button>
                                                  Senha: 
                                                  <input style="width: fit-content" type="text"  disabled :value="hospede.senha">
                                                </span>
                                                <span v-else>
                                                  <button type="button" @click="senhaVisivel(hospede.senha+hospede.numreserva)">
                                                    <v-icon>mdi-eye-outline</v-icon>
                                                  </button>
                                                  Senha: 
                                                  <input style="width: fit-content" type="password" v-if="senhavisivel !== hospede.senha+hospede.numreserva" disabled :value="hospede.senha">
                                                  <br/>
                                                  <a data-cy="btn-gerarsenha" @click="gerarSenha(hospede.idcheckin, index)">Gerar senha automaticamente</a>
                                                </span>
                                            </v-row>

                                            <v-row justify="start" class="pb-1 pt-1" style="white-space: nowrap;">
                                          
                                                  <v-btn small class="mr-2 ml-2" title="Editar endereço de email do hóspede" 
                                                    @click="[idcheckin = hospede.idcheckin, email = hospede.email, exibeModalEditarEmail = true]" data-cy="btn-editar" >
                                                      Editar
                                                    <v-icon small dark class="ma-1">mdi-pencil </v-icon>
                                                  </v-btn>
                                          
                                                  <v-btn small class="mr-2 ml-2" title="Enviar email com dados de acesso, senha e etc." @click="enviarEmail(hospede)" data-cy="btn-enviaremail">
                                                    Enviar
                                                    <v-icon small dark class="ma-1">mdi-email-send </v-icon>
                                                  </v-btn>

                                                  <v-btn small class="mr-2 ml-2" @click="fazerPedido(hospede)" data-cy="btn-fazerpedido" >
                                                    Fazer Pedido
                                                    <v-icon small dark class="ma-1">mdi-order-bool-ascending-variant</v-icon>
                                                  </v-btn>

                                            </v-row>
                                        
                                    </v-expansion-panel-content>

                            </v-expansion-panel>

                      </v-expansion-panels>

              </div>

          </v-card-text>

          <!-- <v-card-actions> 
          </v-card-actions> -->

        </v-card>
      </v-row>
    </div>

    <ModalCheckinFake 
      :exibirModalCheckinFake="modalCheckinFake" 
      @fechar="() => modalCheckinFake = false" 
      @atualizar="listarHospedesNaCasa()"/>

    <modalContaAvulsaADMH 
      :exibirModalContaAvulsaADMH="modalContaAvulsaADMH" 
      @fechar="() => modalContaAvulsaADMH = false" 
      @atualizar="() => {
        this.botaoBuscandoHospede = true;
        listarHospedesNaCasaADMH()
      }"
    />


    <ModalEditarEmailHospede 
     v-model="exibeModalEditarEmail" 
     :dialog="exibeModalEditarEmail"
     :idcheckin="idcheckin"
     :email="email"
     @fechar="exibeModalEditarEmail = false"
     @atualizar="listarHospedesNaCasa()"/>
  </div>
</template>

<script>
import ModalEditarEmailHospede from '@/components/modals/ModalEditarEmailHospede.vue'
import ModalCheckinFake from '../../components/layout/Modais/ModalCheckinFake.vue';
import ModalContaAvulsaADMH from '../../components/layout/Modais/ModalContaAvulsaADMH.vue'
// import BotaoTutorial from '../../components/layout/BotaoTutorial.vue';
export default {
  name: "ViewHospedes",
  components: { 
    ModalEditarEmailHospede, 
    ModalCheckinFake, 
    ModalContaAvulsaADMH
  },
  data: () => ({
    garcomcadastrapassante:false,
    idcheckin : 0,
    email : '',
    coduh: "",
    nome: "",
    datacheckin: "",
    numreserva: "",
    hospedes: [],
    campo: "",
    exibeModalEditarEmail : false,
    senhavisivel: null,
    modalCheckinFake: false,
    tipointegracao:null,
    pagina:1,
    nacasa:{ id:2, nome: 'SIM' },
    itemsnacasa:[
       { id:1, nome: 'TODOS' },
       { id:2, nome: 'SIM' },
       { id:3, nome: 'NÃO' },
    ],
    tipohospede:{ id:-2, nome: 'TODOS' },
    tiposhospede:[
      { id:-2, nome: 'TODOS' },
      { id:-1, nome: 'PASSANTE' },
    ],
    botaoBuscandoHospede: false,
    modalContaAvulsaADMH: false,
    numeroImportacao: 0
  }),
  methods: {
    importarCheckins() {
      this.$http.post('/pdv/importarcheckinsADMH', {})
        .then(res => {
          if (res.status === 200) {
            this.$alertar('success', '=)', 'Checkins importados com sucesso!')
            this.listarHospedesNaCasa()
          }
        }).catch(e => {
          this.$alertar('warning', '=(', 'Falha ao importar checkins!')
          console.log(e)
        })
    },
    async pesquisar(){
      this.pagina = 0
      this.botaoBuscandoHospede = true
      if (this.tipointegracao === 'ADMH') {
        await this.listarHospedesNaCasaADMH()
      } else {
        await this.listarHospedesNaCasa()
        this.botaoBuscandoHospede = false
      }
    },
    avancar(){
        if(this.hospedes.length >= 10){
          this.pagina++
          this.listarHospedesNaCasa()
        }
    },
    voltar(){
        if(this.pagina>1){
          this.pagina--
          this.listarHospedesNaCasa()
        }
    },
    gerarSenha(idcheckin, indexhospede){
        let senha = Math.floor(1000 + Math.random() * 9000);
        let body = {
          idcheckin,
          senha
        }
        this.$http.post("/hospedes/alterarsenha", body) 
          .then((response) => {
            if (response.status == 200) {
              this.hospedes[indexhospede].senha = senha
              this.$alertar('success', 'Senha gerada com sucesso', '=)')
            } else {
              this.$alertar('warning', 'Erro ao gerar senha automaticamento', '=(')
            }
          })
          .catch(e => {
            console.log('error na requisicao de alterar senha', e)
            this.$alertar('warning', 'error na requisicao de alterar senha', '=(')
          })
      
    }, 
    enviarEmail(hospede){
      this.$http.post('hospedes/enviaremail', {hospede}).then(res => {
        if(res.status === 200){
          this.$alertar('success', '=)','Email Enviado!')
        }
      }).catch(e => {
          this.$alertar('warning', '=)','Falha no envio do email!')
          console.log(e)
      })
    },
    fazerPedido(hospede){
      console.log(hospede)
      if(this.$route.query.mesa > 0){
        this.$router.push(`/Adm/FazerPedido/${hospede.idreservasfront}/${hospede.idhospede}/${hospede.idcheckin}?mesa=${this.$route.query.mesa}`)
      }else{
        this.$router.push(`/Adm/FazerPedido/${hospede.idreservasfront}/${hospede.idhospede}/${hospede.idcheckin}`)
      }
    },   
    senhaVisivel(arg){
      if(this.senhavisivel === arg){
        this.senhavisivel = null
      }else{
        this.senhavisivel = arg
      }      
    },  
    exemplodealerta() {
    },
    async listarHospedesNaCasa() {
      let body = {}

      if (this.nome.length > 0) {
        body.nome = this.nome
      }

      if(this.email.length > 0){
        body.email = this.email
      }

      if (this.coduh.length > 0) {
        body.coduh = this.coduh
      }

      if(this.datacheckin.length > 0){
        body.datacheckin = this.datacheckin
      }

      if (this.numreserva.length > 0) {
        body.numreserva = this.numreserva
      }

      

      if(this.nacasa.id == 2){
        body.nacasa = true
      }else{
        if(this.nacasa.id == 3){
          body.nacasa = false
        }else{
          body.nacasa = null
        }
      }

      if(this.tipohospede.id >= -1){
        
          if(this.tipohospede.id === -1){
            body.passante = true
          }else{
            delete body.passante
            if(this.tipointegracao == 'HITS'){
              if(this.tipohospede.id === 0){
                body.id_tipo_hospede = '0,1'
              }else{
                body.id_tipo_hospede = this.tipohospede.id
              }
            }else{
              body.id_tipo_hospede = this.tipohospede.id
            }
          }

      }else{
        delete body.passante
        delete body.id_tipo_hospede
      }

      body.pagina = this.pagina
  
      await this.$http.post("/hospedes/listar", body).then(async (response) => {

        if (response.status == 200) {

          this.hospedes = response.data;
          if(this.hospedes.length == 0){
            if (this.tipointegracao !== 'ADMH') {
              await this.$http.post("/hospedes/listar", body).then((response) => {
                  if (response.status == 200) {
                    this.hospedes = response.data;
                    this.botaoBuscandoHospede = false;
                  }
              })
            }
          } else {
            this.botaoBuscandoHospede = false;
          }
        } else {
          this.hospedes = [];
        }
      });

      this.limparCampos()
    },
    async listarHospedesNaCasaADMH() {
      let body = {}

      if (this.nome.length > 0) {
        body.nome = this.nome
      }

      if(this.email.length > 0){
        body.email = this.email
      }

      if (this.coduh.length > 0) {
        body.coduh = this.coduh
      }

      if(this.datacheckin.length > 0){
        body.datacheckin = this.datacheckin
      }

      if (this.numreserva.length > 0) {
        body.numreserva = this.numreserva
      }

      

      if(this.nacasa.id == 2){
        body.nacasa = true
      }else{
        if(this.nacasa.id == 3){
          body.nacasa = false
        }else{
          body.nacasa = null
        }
      }

      if(this.tipohospede.id >= -1){
        
          if(this.tipohospede.id === -1){
            body.passante = true
          }else{
            delete body.passante
            if(this.tipointegracao == 'HITS'){
              if(this.tipohospede.id === 0){
                body.id_tipo_hospede = '0,1'
              }else{
                body.id_tipo_hospede = this.tipohospede.id
              }
            }else{
              body.id_tipo_hospede = this.tipohospede.id
            }
          }

      }else{
        delete body.passante
        delete body.id_tipo_hospede
      }

      body.pagina = this.pagina
  
      if (this.tipointegracao === 'ADMH') {
        this.botaoBuscandoHospede = true;
      }

      await this.$http.post("/hospedes/listar", body).then(async (response) => {

        if (response.status == 200) {

          this.hospedes = response.data;
          if(this.hospedes.length == 0){
            console.log('aguardando resposta do socket...')
          } else {
            this.limparCampos()
            this.botaoBuscandoHospede = false;
          }
        } else {
          this.limparCampos()
          this.hospedes = [];
        }
      });

    },
    limpar() {
      this.coduh = ""
      this.nome = ""
      this.email = ""
      this.datacheckin = ""
      this.numreserva = ""
      this.listarHospedesNaCasa();
    },
    limparCampos() {
      this.coduh = ""
      this.nome = ""
      this.email = ""
      this.datacheckin = ""
      this.numreserva = ""
    },
  },
  async mounted() {
    this.$store.dispatch("set_titulo", "Hospedes");
    this.tipointegracao = localStorage.getItem('tipo_integracao')

    if(this.tipointegracao == 'HITS'){
      this.tiposhospede.push({ id:0, nome: 'HOSPEDE' })
      this.tiposhospede.push({ id:2, nome: 'DAY USE' })
      this.tiposhospede.push( { id:3, nome: 'EVENTOS' })
      this.tipohospede = {id:0, nome: 'HOSPEDE'  }
    }

    if(this.tipointegracao === 'ADMH') {
      const tiposHospedes = [
        {id: -2, nome: "TODOS"},
        {id: 0, nome: "HOSPEDE"},
        {id: 2, nome: "AVULSAS"},
        {id: -1, nome: "MESAS"}
      ]
      this.tiposhospede = tiposHospedes;
      this.botaoBuscandoHospede = true;
      this.sockets.subscribe('listar_hospedes', async () => {
        await this.listarHospedesNaCasa();
        this.botaoBuscandoHospede = false;
      })
              
    }

    this.$http.post('pdv/buscarparametros',{chave:'CRIARPASSANTEGARCOM'})
      .then(resp =>{
        this.garcomcadastrapassante = resp.data[0].valor === 'true' ? true : false
      }).catch(error =>{
        alert(error)
      })

    this.listarHospedesNaCasa();
  },
};
</script>


<style scoped>
</style>
