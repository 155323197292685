<template>
  <div>
<!--    <BotaoVoltar :url="'/Hospede/Categorias/'+$route.params.idpdv" />-->
    <div>
      <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
        <v-btn depressed color="error" @click="voltar()">{{$t('voltar.texto')}}</v-btn>
      </div>
    </div>
    <Subtitulo :subtitulo="subtitulo" />
    <div class="categoryList" >
      <div class="d-flex align-center categoryItem" @click="direcionar(subcategoria.idsubcategoriaprodcd)" data-cy="subcategoria" v-for="subcategoria in listadesubcategorias" :key="subcategoria.idsubcategoriaprodcd">
        <div class="colA">
          <v-img class="colAimg" :src="subcategoria.imgcategoriaprodcd ? subcategoria.imgcategoriaprodcd : null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
          <p class="colBTitle">{{subcategoria.nomesubcategoriaprodcd}}</p>
          <p class="colBOutHour" v-show="subcategoria.dentro_do_horario == 'false'" style="color:red"> Fora do horário</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'View SubCategoria',
    data: () => ({
      subtitulo:"",
      listadesubcategorias: [],
    }),
    components: {
      Subtitulo,
    },
    async mounted(){
       await this.getNomeCategoria();
       await this.getSubCategorias();
    },
    methods: {
      voltar() {
        this.$router.go(-1);
      },  
      async getNomeCategoria() {
        await this.$http('pdv/getNomeCategoria/'+this.$route.params.idcategoriaprodcd).then((resp) =>{
          this.subtitulo = resp.data.nomecategoriaprodcd
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi pegar o nome da Categoria","=(")
        })
        this.$store.dispatch("set_titulo",this.$t('titulocardapio.texto'))
      },
      async getSubCategorias(){
        await this.$http('pdv/getSubCategorias/'+this.$route.params.idcategoriaprodcd+'/0/hospede').then((resp) =>{
          if(resp.data.length > 0){
            console.log(resp.data)
            this.listadesubcategorias = resp.data
          }else{
            this.$router.push('/Hospede/ProdutosMesa/'+this.$route.params.idpdv+'/'+this.$route.params.idcategoriaprodcd+'/0')
          }
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
        })
      },
      direcionar(idsubcategoria){
        const url = `/Hospede/ProdutosMesa/${this.$route.params.idpdv}/${this.$route.params.idcategoriaprodcd}/${idsubcategoria}`;
        this.$router.push(url);
      }
    }
  }
</script>

<style scoped>
.categoryItem{
  font-size:1.2em;
  color:rgb(70, 70, 70);
  /* background-color: pink; */
  height: 70px;
  width:330px;
  max-width:100%;
  margin-bottom: 20px;
  margin-right:2%;
  border: 1px solid rgb(156, 156, 156);
  border-radius: 10px;
  padding:5px;
}
.categoryItem .colA{
  /* background-color:red; */
  width: 60px;
  padding-left:2%;
  padding-right: 2%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryItem .colA .colAimg{
  border-radius: 100%;
  height:50px;
  max-height:50px;
  width:50px;
  max-width:50px;
}

.categoryItem .colB{
  /* background-color:Blue; */
  width: 270px;
  padding-left:2%;
  display: flex;
  flex-direction: column;
  padding-right:2%;
  word-break: break-all;
}

.colBTitle {
  font-size: 18px;
  margin: 0;
}
.colBOutHour {
  font-size: 14px;
  margin: 0;
}
.categoryList{
  /* background-color:green; */
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
  padding:0px!important;
}
</style>
