<template>
  <div class="ddpms">
    <span style="font-weight: bold; color: #036097">Localidades</span>
    <v-card
      elevation="2"
      outlined
      style="padding-bottom: 0px !important; height: 19%; max-height: 110px;margin-bottom:20px;padding:2px;"
    >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
      <BotaoTutorial categoria="14" legenda="" urlVideo="https://drive.google.com/file/d/1prtcACzCnihtvvI-MbDEM_kSdDetm31p/preview" />
    </div>

      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="" style="margin-top: 0px; margin-bottom: 0px">
        <div style="height: 70px; max-height: 70px; display: flex">
          <div style="width: 48%; margin-left: 0%">
            <label for="">Localidades</label>
            <select
              v-model="idlocal"
              class="select"
              data-cy="getPDVSPMS"
              @change="getPDVSPMS()"
              data-v-step-localxpdv="1"
            >
            <option
              v-for="localidade in localidades"
              :key="localidade.id"
              :value="localidade.id_localidade"
            >
              {{ localidade.descricao }}
            </option>
            </select>
          </div>
          <!-- <div style="width: 48%; margin-left: 2%">
            <label for="">Categorias</label>
            <select
              v-model="idcategoriapms"
              class="select"
              @change="getProdutosPMS()"
            >
              <option
                v-for="categoria in listaCategoriasPMS"
                :key="categoria.idcategoriaprod"
                :value="categoria.idcategoriaprod"
              >
                {{ categoria.desccategoriaprod }}
              </option>
            </select>
          </div> -->
        </div>
      </v-card-text>
    </v-card>


    <span style="font-weight: bold; color: #036097;">PDV DISPONÍVEIS PARA SELEÇÃO</span>
    <v-card
      elevation="2"
      outlined
      class="mt-2"
      height="80.5%"
      style="overflow: auto; max-height: 65vh"
    >
      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="" style="display:flex;flex-wrap:wrap;" data-cy="pdvs-wrapper">

        <v-card
          v-for="pdv in listaPDVsPMS"
          :key="pdv.idpdv"
          :style="pdv.marcadolocal?'background-color:#d1deeb;':''"
          elevation="2"
          outlined
          class="mt-2"
          width="18%"
          min-width="18%"
          style="margin-right:10px;min-height:50px;"
          data-cy="setPdvLocal"
          @click="setPdvLocal(pdv)"
          data-v-step-localxpdv="2"
        >
          <v-card-text>
            <div style="display: flex">
              <div style="width: 90%; color: #5a5858; font-weight: bold">
                {{ pdv.descpdv }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="exibeListaPDVAssoc" max-width="600px">
      <v-card width="600px">
        <v-toolbar color="primary"><v-tooblar-title style="color: white;">PDVs Associados</v-tooblar-title>
        <v-spacer></v-spacer><v-icon color="red" @click="exibeListaPDVAssoc = false">mdi-close-circle</v-icon>
        </v-toolbar>

        <v-card-text>
          <v-row no-gutters class="ma-1">
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> PDV </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Categoria </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Sub Categoria </v-card>
            </v-col>
          </v-row>
          <div class="ma-1">

          <v-row no-gutters v-for="pdv in listaPdvAssoc" :key="pdv.id" >
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomepdv && pdv.nomepdv.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nome && pdv.nome.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomesub && pdv.nomesub.toUpperCase() }}
              </v-card>
            </v-col>
          </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!--
       <div class="ddpms">
       <select v-model="statuscomanda" class="select">
            <option value=2 >Pedido Solicitado</option>
            <option value=3 >Pedido em Produção</option>
            <option value=4 >Pedido Pronto</option>
            <option value=5 >Pedido Entregue</option>
            <option value=6 >Pedido Cancelado</option>
        </select>
     <div >
          tes

    </div>

  </div> -->
</template>

<script>
import BotaoTutorial from '../layout/BotaoTutorial.vue';
export default {
    name: "Coluna PMS",
    props: ["url", "atualizar"],
    data: () => ({
        idlocal: 1,
        listaPDVsPMS: [],
        idpdvpms: null,
        listaCategoriasPMS: [],
        idcategoriapms: null,
        listaProdutosPMS: [],
        listaProdutosPMSFiltrados: [],
        idprodutopms: null,
        idprodutoclicado: null,
        listaPdvAssoc: [],
        exibeListaPDVAssoc: false,
        textoProdPesquisar: null,
        localidades: []
    }),
    components: {
        BotaoTutorial
    },
    watch: {
        atualizar(valor) {
            console.log(valor);
            this.getProdutosPMS();
        },
        textoProdPesquisar(valor) {
            if (valor.length > 0) {
                this.listaProdutosPMSFiltrados = this.listaProdutosPMS.filter(x => {
                    if (x.descproduto.trim().toUpperCase().includes(valor.trim().toUpperCase())) {
                        return x;
                    }
                });
            }
            else {
                this.listaProdutosPMSFiltrados = this.listaProdutosPMS;
            }
        },
    },
    async mounted() {
        await this.getPDVSPMS();
        await this.getLocalidades();
    },
    methods: {
        setPdvLocal(pdv) {
            pdv.idlocal = this.idlocal;
            pdv.ativo = !pdv.marcadolocal;
            // alert(JSON.stringify(pdv,null,2))
            this.$http.post("pdv/setPdvLocal", pdv).then(async () => {
                if(!pdv.marcadolocal){
                  this.$alertar("success","PDV marcado com sucesso!", "=)")
                } else {
                  this.$alertar("success","PDV desmarcado com sucesso!", "=)")
                }
                await this.getPDVSPMS();
            }).catch(() => {
                this.$alertar("warning", "Não foi Possível Adicionar o Produto ao Cardápio Digital!", "=(");
            });
        },
        direcionar() {
            this.$router.push(this.url);
        },
        exibirPDVsAssociados() {
            this.exibeListaPDVAssoc = true;
        },
        adicionarProduto(produto) {
            this.idprodutoclicado = produto.idproduto;
            let obj = {
                idcategoriaprodcd: localStorage.getItem("idcatcd"),
                idsubcategoriaprodcd: localStorage.getItem("idsubcatcd"),
                idproduto: this.idprodutoclicado,
            };
            this.$http
                .post("pdv/addProdutoAoCD", obj)
                .then(() => {
                console.log("te");
                // this.getProdutosPMS()
                this.$emit("atualizardados");
            })
                .catch(() => {
                this.$alertar("warning", "Não foi Possível Adicionar o Produto ao Cardápio Digital!", "=(");
            });
        },
        getPDVSPMS() {
            this.$http("pdv/getPDVSCDLOCAL/" + this.idlocal).then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaPDVsPMS = resp.data;
                    this.idpdvcd = this.listaPDVsPMS[0].idpdv;
                    console.log("this.listaPDVsPMS");
                    console.log(this.listaPDVsPMS);
                    // await this.getCategoriasCD()
                }
            }).catch((error) => {
                // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
                this.$alertar("warning", error, "=(");
            });
            // this.$http("pdv/getPDVSPMS/0")
            //   .then(async (resp) => {
            //     if (resp.data && resp.data.length > 0) {
            //       this.listaPDVsPMS = resp.data;
            //       console.log("this.listaPDVsPMS")
            //       console.log(this.listaPDVsPMS)
            //       this.idpdvpms = this.listaPDVsPMS[0].idpdv;
            //       await this.getCategoriasPMS();
            //     }
            //   })
            //   .catch((error) => {
            //     // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
            //     this.$alertar("warning", error, "=(");
            //   });
        },
        getLocalidades() {
            this.$http("pdv/localidades/").then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.localidades = resp.data;
                    console.log("this.localidades");
                    console.log(this.localidades);
                }
            }).catch((error) => {
                this.$alertar("warning", error, "=(");
            });
        },
        getCategoriasPMS() {
            this.listaCategoriasPMS = [];
            this.$http(`pdv/getCategoriasPMS/${this.idpdvpms}/0`)
                .then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaCategoriasPMS = resp.data;
                    this.idcategoriapms = this.listaCategoriasPMS[0].idcategoriaprod;
                    await this.getProdutosPMS();
                }
            })
                .catch(() => {
                this.$alertar("warning", "Não foi possivel listar as Categorias do PMS", "=(");
            });
        },
        getProdutosPMS() {
            this.listaProdutosPMS = [];
            this.$http(`pdv/getProdutosPMS/${this.idpdvpms}/${this.idcategoriapms}/0`)
                .then((resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.listaProdutosPMS = resp.data;
                    this.listaProdutosPMSFiltrados = this.listaProdutosPMS;
                    console.log(this.listaProdutosPMS);
                }
            })
                .catch(() => {
                this.$alertar("warning", "Não foi possivel listar os Produtos do PMS", "=(");
            });
        },
    },
};
</script>

<style scoped>
.ddpms {
  /* background-color: white; */
  width: 100%;
  height: 100% !important;
}

.ddpmsprod {
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid black;
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}
</style>
