<template>
    <v-dialog v-model="exibirModalEdicaoPDV" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:10%;color:white;font-weight:bold;" >
                        Editar PDV
                    </span>
                    <div  style="width:85%;display:flex;justify-content:flex-end;padding-right:20px;">
                        <v-btn small color="default" @click="exibirModalDuplicarCardapioPDV()" title="Fechar">
                            Duplicar cardapio de outro PDV
                        </v-btn>
                        <!-- <div style="background-color:white; padding: 0px 20px;border-radius:5px;">
                            Duplicar cardapio de outro PDV
                        </div> -->
                    </div>
                    <span style="width:5%;">
                        <v-btn data-cy="fechar-editar-pdv" x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <v-flex xs12>
                            <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="uploadCompressor" />
                            <v-avatar slot="offset" class="mx-auto d-block" size="130">
                            <v-icon v-if="!imgSrc" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                            <!-- <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="imgUpload" /> -->
                            <img v-if="imgSrc" :src="imgSrc" class="imgUpload" />
                            </v-avatar>
                            <div style="display:flex;justify-content:center;margin-top:20px;">
                                <label for="file-upload" >
                                    <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                    Foto
                                    </div>
                                </label>
                            </div>
                        </v-flex>

                        <div style="padding-bottom:10px;">
                            <h3>Nome <span style="color:red;">(Máx. 50 Cararcteres)</span></h3>
                            <input maxlength="50" type="text" data-cy="input-nome" v-model="pdv.descpdv" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div>
                             <v-container class="">
                                <v-row>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="pdv.ativo" data-cy="switch-ativo"> </v-switch>
                                                <label>{{pdv.ativo ? 'Ativado': 'Pausado'}}</label>
                                            </div>
                                    </v-col>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="pdv.allinclusive" data-cy="switch-allinclusive"> </v-switch>
                                                <label>Disponível para All Inclusive</label>
                                            </div>
                                    </v-col>

                                    <v-col v-if="fluxoImpressao==2">
                                            <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;">
                                                <label>Impressora</label>
                                                <v-select  v-model="impressora" :items="listadeimpressoras"
                                                    item-text="nome" item-value="ip" return-object dense outlined
                                                    style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                                </v-select>
                                            </div>
                                    </v-col>



                                </v-row>


                               <v-row>

                                 <v-col v-if="true">
                                   <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;">
                                     <v-autocomplete
                                         style="border-radius: 10px;"
                                         v-model="exibirPara"
                                         :items="exibirParaItens"
                                         outlined
                                         dense
                                         chips
                                         small-chips
                                         label="Exibir para"
                                         multiple
                                         data-cy="input-exibir-para"
                                     ></v-autocomplete>
                                   </div>
                                 </v-col>

                               </v-row>


                              </v-container>
                        </div>

                        <div style="padding-bottom:10px;">

                            <h3>Disponibilidade</h3>
                            <v-btn small elevation="" color="primary" @click="adicionarDisponibilidade()">Adicionar Disponibilidade</v-btn>

                            <div>
                                 <v-container class="">
                                    <v-row>
                                      <v-col><v-btn @click="setDia('seg')" small elevation="" :color="seg?'success':'default'">Seg</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('ter')" small elevation="" :color="ter?'success':'default'">Ter</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('qua')" small elevation="" :color="qua?'success':'default'">Qua</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('qui')" small elevation="" :color="qui?'success':'default'">Qui</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('sex')" small elevation="" :color="sex?'success':'default'">Sex</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('sab')" small elevation="" :color="sab?'success':'default'">Sab</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('dom')" small elevation="" :color="dom?'success':'default'">Dom</v-btn></v-col>
                                    </v-row>
                                  </v-container>
                            </div>
                            <div style="display:flex;flex-wrap:wrap;">

                                <div v-for="horario in listaHorarios" :key="horario.id" style="display:flex;align-items:flex-end;width:25%;margin-top:10px;">
                                    <div style="border:1px solid #bdbaba;display:flex;flex-direction:column;align-items:flex-end;padding:5px;border-radius:10px;">
                                        <div style="display:flex;align-items:flex-end;padding:5px;border-radius:10px;">
                                            <datetime class="inputsHorario" data-cy="input-horainicio" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}" style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horainicial" >
                                            </datetime>
                                            <span style="margin-right:10px;" >até</span>
                                            <datetime class="inputsHorario" data-cy="input-horafim" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}"  style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horafinal" >
                                            </datetime>
                                        </div>
                                        <div>
                                            <a @click="removerDisponibilidade(horario)">excluir</a>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>






                    </div>
                    <div style="margin-top:10px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" @click="atualizarPdv()" data-cy="atualizarPdv">
                            SALVAR
                        </v-btn>
                    </div>

                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="error" @click="setExclusaoModal()" data-cy="setExclusaoModal">
                            EXCLUIR PDV
                        </v-btn>
                    </div>

                </v-card>



            </v-card-text>


        </v-card>


         <ModalConfirmacao :showModal="exibirModalExclusao" :titulo="'Deseja Realmente Excluir o PDV?'"
                textosim="Sim" textonao="Não" @sim="()=>excluirPDV()" @nao="()=>exibirModalExclusao=false"  />

        <ModalDuplicarCardapioPDV v-if="pdv.idpdv" :showModalDuplicarCardapioPDV="showModalDuplicarCardapioPDV" :pdv="pdv"
                @fechar="()=>fecharModalDuplicarCardapioPDV()" @salvar="()=>exibirModalExclusao=false" @atualizarAposDuplicar="() => this.$emit('atualizarAposDuplicar')" />

    </v-dialog>
</template>

<script>

import ModalConfirmacao from         '../../layout/ModalConfirmacao.vue'
import ModalDuplicarCardapioPDV from '../../layout/Modais/ModalDuplicarCardapioPDV.vue'
import Compressor from 'compressorjs'
// import dayjs from 'dayjs'
import {serializarImagem} from '../../../services/serializar'

  export default {
    name: 'Modal Editar PDV',
    props:["exibirModalEdicaoPDV","idpdvcd"],
    components: {
      ModalConfirmacao,
      ModalDuplicarCardapioPDV,
    },
    data: () => ({
        exibirModalExclusao:false,
        nome:null,
        tempo:null,
        horarioinicial:false,
        horariofinal:false,
        pdv:{
            descpdv:null,
            ativo:null
        },
        imgSrc:null,
        showModalDuplicarCardapioPDV:false,
        listaHorarios:[],
        seg:false,
        ter:false,
        qua:false,
        qui:false,
        sex:false,
        sab:false,
        dom:false,
        listadeimpressoras:[],
        impressora:null,
        fluxoImpressao:null,

        exibirPara: [],
        exibirParaItens: [{ text: "Garcom", value: "garcom" }, { text: "Hóspede", value: "hospede" }],
    }),
    async mounted(){

        await this.getFluxoImpressao()

        this.$http.post('pdv/getImpressoras',{ativo:true}).then(resp =>{
                        if(resp.data && resp.data.length > 0){
                            this.listadeimpressoras = resp.data
                            this.impressora = {nome:resp.data[0].nome, ip:resp.data[0].ip, id:resp.data[0].id}
                        }
                    }).catch(error =>{
                        alert(error)
                    })

         this.getPdv()


    },
    methods:{

        async uploadCompressor(e) {
            e.preventDefault();

            this.imgSrc = URL.createObjectURL(e.target.files[0]);

            const file = e.target.files[0];
            const _self = this;

            new Compressor(file, {
                quality: 0.6,
                mimeType: "image/jpeg",
                async success(result) {
                    const imagem = new File([result], "name");

                    const infoPDV = {
                        idpdv: _self.pdv.idpdv,
                        link: _self.$dados.BUILD_API_URL
                    };

                    const formData = new FormData();
                    formData.append('imagem', imagem, `${infoPDV.idpdv}.jpg`);
                    formData.append('infos', JSON.stringify(infoPDV));

                    const headers = {
                        'Content-Type': 'multipart/form-data'
                    }

                    await _self.$http.post("pdv/uploadImagemPDV", formData, headers).then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
                },
                error(err) {
                    console.log("Erro no comprimir")
                    console.log(err.message);
                },
            });
        },

        async getFluxoImpressao(){

            await this.$http.post('/pdv/buscarparametros', { chave:'FLUXOIMPRESSAO' }).then(async resp=>{



                            if(resp.data && resp.data.length>0){
                                this.fluxoImpressao = resp.data[0].valor
                                // alert( 'asa '+this.fluxoImpressao )
                            }

                        }).catch(() =>{

                            return {}

                        })

        },

        setDia(dia){
            if(dia === 'seg'){ this.seg = !this.seg}
            if(dia === 'ter'){ this.ter = !this.ter}
            if(dia === 'qua'){ this.qua = !this.qua}
            if(dia === 'qui'){ this.qui = !this.qui}
            if(dia === 'sex'){ this.sex = !this.sex}
            if(dia === 'sab'){ this.sab = !this.sab}
            if(dia === 'dom'){ this.dom = !this.dom}
        },
        removerDisponibilidade(horario){
            let indice = this.listaHorarios.indexOf(horario)
            if(indice > -1){
                this.listaHorarios.splice(indice,1)
            }
        },
        adicionarDisponibilidade(){
            this.listaHorarios.push({tipo:1,horainicial:'00:00:00',horafinal:'23:50:00'})
        },
        exibirModalDuplicarCardapioPDV(){

            this.showModalDuplicarCardapioPDV = true
        },
        fecharModalDuplicarCardapioPDV(){

            this.showModalDuplicarCardapioPDV = false
        },
        setExclusaoModal(){
            this.exibirModalExclusao = !this.exibirModalExclusao
        },
        async excluirPDV(){

            try {
                let dados ={idPDVCD:this.pdv.idpdv}
                await this.$http.post("pdv/excluirPDVCD",dados,{timeout:60000 })
                    .then(() => {

                        this.setExclusaoModal()
                        this.$alertar("success","PDV Excluído Com sucesso!","=)")
                        this.$emit('atualizarLista')
                        this.fechar()
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.$emit('atualizarLista')
                    });
            } catch (error) {
                alert(error)
            }


        },
        async salvaimg(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }
            let dados = {
                "tipo" : tipoImg,
                "nome" : "imgPDV",
                "dados" : this.imgSrc,
                "tabela": "pdvcd",
                "campo": "img",
                "chave": "idpdv",
                "valor": this.pdv.idpdv
            }
            console.log('dados> ',dados)
            await this.$http.post("pdv/imagemupdate",dados,{timeout:60000 })
                .then((response) => {
                if(response.status == 200){

                    this.imgSrc = response.data.result.imagem[0]
                }else{
                    console.log(JSON.stringify(response))
                }
                })
                .catch((error) => {
                console.log(error.response);
            });
        },
        onFileChange(e) {
            // this.setImage(e)
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaimg(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error"
                this.snackText = error.message
            })
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        getPdv(){
            this.$http('pdv/getPDVSCD/'+this.idpdvcd).then(async resp =>{

                if(resp.data && resp.data.length > 0){
                    this.pdv.idpdv = resp.data[0].idpdv
                    this.pdv.descpdv = resp.data[0].descpdv
                    this.pdv.ativo = resp.data[0].ativo
                    this.seg =  resp.data[0].seg
                    this.ter =  resp.data[0].ter
                    this.qua =  resp.data[0].qua
                    this.qui =  resp.data[0].qui
                    this.sex =  resp.data[0].sex
                    this.sab =  resp.data[0].sab
                    this.dom =  resp.data[0].dom
                    this.impressora = { id:resp.data[0].id, nome:resp.data[0].nome,ip:resp.data[0].ip }
                    this.imgSrc = resp.data[0].img

                    this.exibirPara = JSON.parse(resp.data[0].exibir_para)

                    this.pdv.allinclusive = resp.data[0].allinclusive

                    this.$http.post('pdv/getDisponibilidade',{tipo:1,iditem:this.pdv.idpdv}).then(resp =>{

                        this.listaHorarios = resp.data
                        let objlistaHorarios = resp.data
                        this.listaHorarios = []
                        for(let a=0,lena= objlistaHorarios.length; a < lena; a++){
                            let horariod = objlistaHorarios[a]
                            this.listaHorarios.push({tipo:2,horainicial:'2022-07-06T'+horariod.horainicial,horafinal:'2022-07-06T'+horariod.horafinal})
                        }

                    }).catch(error =>{

                        console.log(error)
                    })

                }
            }).catch((error)=>{

                // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
                this.$alertar('warning',error,'=(')
            })
        },
        validarDados(obj){
            if(!obj.idpdv){
                this.$alertar("warning","pdv não Selecionado","=(")
                return false
            }

            if(!obj.descpdv){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }



            return true
        },
        atualizarPdv(){
            let obj = {
                idpdv:this.pdv.idpdv,
                descpdv:this.pdv.descpdv,
                ativo:this.pdv.ativo,
                allinclusive:this.pdv.allinclusive,
                listaHorarios:this.listaHorarios,
                seg:this.seg,
                ter:this.ter,
                qua:this.qua,
                qui:this.qui,
                sex:this.sex,
                sab:this.sab,
                dom:this.dom,
                exibir_para: JSON.stringify(this.exibirPara),
            }

            if(this.impressora && this.impressora.id){
                obj.idimpressora = this.impressora.id
            }

            if(this.validarDados(obj)){
                this.$http.put(`pdv/pdvcd`,obj).then(() =>{
                    this.$alertar("success","pdv Atualizada Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning","Não foi possivel atualizar a pdv","=(")
                    console.log(error)
                })
            }
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

