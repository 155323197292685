<template>
    <div style="height:100%;display:flex;">

        <div class="ddpms">
    <span style="font-weight: bold; color: #036097">PDVS</span>
    <v-card
      elevation="2"
      outlined
      style="padding-bottom: 0px !important; height: 19%; max-height: 110px;margin-bottom:20px;padding:2px;"
    >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
      <BotaoTutorial categoria="" legenda="" urlVideo="https://drive.google.com/file/d/1FiYGt5OBDYKLtkTxEmu9wA9kloEaioXZ/preview" />
    </div>
    
      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="" style="margin-top: 0px; margin-bottom: 0px">
        <div style="height: 70px; max-height: 70px; display: flex">
          <div style="width: 48%; margin-left: 0%">
            <label for="">Selecione o PDV</label>
            <select data-cy="idpdvcd"
              v-model="idpdvcd"
              class="select"
              @change="getCategoriasCD()"
            >
              <option v-for="pdv in listaPDVsPMS" :key="pdv.idpdv" :value="pdv.idpdv" :style="!pdv.ativo ? 'color:red': '' " >{{pdv.descpdv +(!pdv.ativo ? ' [PAUSADO]':'' )}}</option>
              
            </select>
          </div>
          <!-- <div style="width: 48%; margin-left: 2%">
            <label for="">Categorias</label>
            <select
              v-model="idcategoriapms"
              class="select"
              @change="getProdutosPMS()"
            >
              <option
                v-for="categoria in listaCategoriasPMS"
                :key="categoria.idcategoriaprod"
                :value="categoria.idcategoriaprod"
              >
                {{ categoria.desccategoriaprod }}
              </option>
            </select>
          </div> -->
        </div>
      </v-card-text>
    </v-card>


    <span style="font-weight: bold; color: #036097;">Categorias Para Comissão</span>
    <v-card
      elevation="2"
      outlined
      class="mt-2"
      height="80.5%"
      style="overflow: auto; max-height: 65vh"
    >
      <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
      <v-card-text class="" style="display:flex;flex-wrap:wrap;">
           
        <v-card
          v-for="cat in listaCategoriasCD"
          :key="cat.idcategoriaprodcd"
          elevation="2"
          outlined
          class="mt-2"
          width="100%"
          min-width="18%"
          style="margin-right:10px;min-height:50px;display:flex;"
         
        >
          
      
              <div style="width: 90%;padding:20px; color: #5a5858; font-weight: bold;display:flex;">
                <div style="width:25%;height:50px;margin-right:10px;" ><span data-cy="nomecategoriaprodcd">{{cat.nomecategoriaprodcd }}</span>{{(!cat.ativo ? ' [PAUSADO]':'' ) }}</div>
                <div style="width:15%;height:50px;margin-right:10px;">
                    <label for="mesainicial" style="font-size:14px;font-weight:bold;">Percentual</label>
                    <input id="mesainicial" v-model="cat.percentual" data-cy="cat.percentual" maxlength="50" type="number" step="0.1" placeholder="% Comissao" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>
                <div style="width:15%;height:50px;margin-right:10px;">
                    <label for="mesainicial" style="font-size:14px;font-weight:bold;">Data Início</label>
                    <input id="mesainicial" v-model="cat.datainicio" data-cy="cat.datainicio" maxlength="50" type="date" step="0.1" placeholder="Data Início" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>

                <div style="width:15%;height:50px;margin-right:10px;display:flex;flex-direction:column;justify-content:flex-end;">
                    <v-btn small elevation="" color="info"   style="width:100%;"  @click="adicionarRegistroComissao(cat)" data-cy="adicionarRegistroComissao">Adicionar</v-btn>
                </div>

                <div style="width:15%;height:50px;margin-right:10px;display:flex;flex-direction:column;justify-content:flex-end;">
                    <v-btn small elevation="" color="success"   style="width:5%;"  @click="verLog(cat)" data-cy="verLog">LOG</v-btn>
                </div>
              </div>
           

        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog v-model="exibeListaPDVAssoc" max-width="600px">
      <v-card width="600px">
        <v-toolbar color="primary"><v-tooblar-title style="color: white;">PDVs Associados</v-tooblar-title>
        <v-spacer></v-spacer><v-icon color="red" @click="exibeListaPDVAssoc = false">mdi-close-circle</v-icon>
        </v-toolbar>
        
        <v-card-text>
          <v-row no-gutters class="ma-1">
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> PDV </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Categoria </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile> Sub Categoria </v-card>
            </v-col>
          </v-row>
          <div class="ma-1">

          <v-row no-gutters v-for="pdv in listaPdvAssoc" :key="pdv.id" >
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomepdv && pdv.nomepdv.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nome && pdv.nome.toUpperCase() }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="pa-2" outlined tile>
                {{ pdv.nomesub && pdv.nomesub.toUpperCase() }}
              </v-card>
            </v-col>
          </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

    <v-dialog v-model="exibirModalLog" width="1000" max-width="80vw">

        <div style="background-color:white;min-height:60vh;max-height:90vh;">
            <div style="display:flex;padding:10px;">
                <div style="width:10%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">ID</div>
                <div style="width:20%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">Categoria</div>
                <div style="width:10%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">Percentual</div>
                <div style="width:20%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">Data Inicio</div>
                <div style="width:20%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">Data Criacao</div>
                <div style="width:20%;border:1px solid #b1b1b1;padding-left:5px;font-weight:bold;">criado Por</div>
            </div>
            <div style="background-color:white;max-height:80vh;overflow:auto">
                <div style="display:flex;padding:10px;" v-for="log in listaLog" :key="log.id" data-cy="linhas-log">
                    <div style="width:10%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.id}}</div>
                    <div style="width:20%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.nomecategoria}}</div>
                    <div style="width:10%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.percentual}}</div>
                    <div style="width:20%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.datainicio}}</div>
                    <div style="width:20%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.datacriacao}}</div>
                    <div style="width:20%;border-bottom:1px solid #b1b1b1;padding-left:10px;margin-bottom:5px;">{{log.nomeusuario}}</div>
                </div>
            </div>
        </div>

    </v-dialog>

       
         <!-- <CardapioCD   :atualizar="atualizarCD"  @atualizardados="() => atualizarDados()" /> -->
          <!-- ADICIONAR CONTEUDO AQUI -->

         <!-- <ModalLogComissaoConfig  :exibirModalLog="exibirModalLog"
            @fechar="()=>alert('tes')" 
            /> -->
    </div>
</template>

<script>


// import CardapioCD from '../../components/cardapio/CardapioCD.vue'
import dayjs from 'dayjs'
import BotaoTutorial from '../../components/layout/BotaoTutorial.vue';
// import ModalLogComissaoConfig from '../../components/layout/Modais/ModalEdicaoPDV.vue'
export default { 
  name: 'ComissaoConfig',
  components:{
    BotaoTutorial
  },                
  data: () => ({   
    exibirModalLog:false,
    atualizarPMS:false,
    atualizarCD:false,
    idlocal:1,
    listaPDVsPMS: [],
    idpdvpms: null,
    idpdvcd:1,
    listaCategoriasCD: [],
    idcategoriacd: null,
    listaProdutosPMS: [],
    listaProdutosPMSFiltrados: [],
    idprodutopms: null,
    idprodutoclicado: null,
    listaPdvAssoc: [],
    listaLog:[],
    exibeListaPDVAssoc: false,
    textoProdPesquisar:null,
  }),
  methods:{
      verLog(categoria){
          
          if(!this.exibirModalLog){
                this.$http.get('pdv/getLogComissaoConfig/'+categoria.idcategoriaprodcd).then(async (resp) =>{
                            resp.data.map(x =>{
                                        x.datainicio = dayjs(x.datainicio).format('DD/MM/YYYY')
                                        x.datacriacao = dayjs(x.datacriacao).format('DD/MM/YYYY')
                                    })
                            this.listaLog = resp.data
                    }).catch((error)=>{
                        this.$alertar('warning',error,'=(')
                    })  
          }
          this.exibirModalLog = !this.exibirModalLog
      },
      adicionarRegistroComissao(categoria){
          let objeto = {
              idcategoriacd:categoria.idcategoriaprodcd,
              percentual:categoria.percentual,
              datainicio:categoria.datainicio,
              datacriacao:dayjs().format()
          }
          this.$http.post('pdv/addComissaoConfig/',objeto).then(async () =>{
                    this.$alertar('success',"Percentual de Comissão Adicionada",'=D')
                    this.getCategoriasCD()
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })
      },
      exemplodealerta(){
          //   this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
      },
      atualizarDados(){
          this.atualizarPMS = !this.atualizarPMS
          this.atualizarCD  = !this.atualizarCD
      },
        getPDVSPMS() {
            this.$http('pdv/getPDVSCDLOCAL/'+this.idlocal).then(async resp =>{
                        if(resp.data && resp.data.length > 0){
                            this.listaPDVsPMS = resp.data 
                            this.idpdvcd = this.listaPDVsPMS[0].idpdv
                            this.getCategoriasCD()

                        }
                    }).catch((error)=>{

                        // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
                        this.$alertar('warning',error,'=(')
                    })
        },
        getCategoriasCD() {
            this.listaCategoriasCD = []
            this.$http('pdv/getCategoriasComissao/'+this.idpdvcd+'/0').then((resp) =>{
                if(resp.data && resp.data.length > 0){
                    resp.data.map(x =>{
                                return x.datainicio = dayjs(x.datainicio).format('YYYY-MM-DD')
                            })

                    this.listaCategoriasCD = resp.data
                    this.idcategoriacd = this.listaCategoriasCD[0].idcategoriaprodcd
                    // this.getSubCategoriasCD()
                }
            }).catch(error =>{
                console.log(error)
                // this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
            })
        },

  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Comissões Config")
      this.getPDVSPMS()
  }
};
</script>


<style scoped>
    .ddpms {
  /* background-color: white; */
  width: 100%;
  height: 100% !important;
}

.ddpmsprod {
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid black;
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}
</style>
