<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <div class="categoryList" data-cy="lista-itens-carrinho">
      <ProdutoCarrinho v-for="produto in listadeprodutos" :key="produto.idcategoriaprod" 
        :produto="produto" :idcategoriaprodcd="idcategoriaprodcd" :idsubcategoriaprodcd="idsubcategoriaprodcd"
        :idpdv="idpdv"
        @retirarProdutoCarrinho="(produto)=>retirarProdutoCarrinho(produto)"
        @retirarDoCarrinhoLocal="(produto)=>retirarDoCarrinhoLocal(produto)"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import ProdutoCarrinho from './ProdutoCarrinho.vue'
  export default {
    name: 'ProdutoListaCarrinho',
    props:['idcategoriaprodcd','idsubcategoriaprodcd','idpdv','listadeprodutos'],
    components:{
      // TitleH1,
      ProdutoCarrinho
    },
    data: () => ({
      // listadeprodutos:[]
    }),

    methods:{

      retirarProdutoCarrinho(produto){
        this.$emit('retirarProdutoCarrinho',produto)
      },
      retirarDoCarrinhoLocal(produto){
        this.$emit('retirarProdutoCarrinhoLocal',produto)
      }

    },

    mounted(){
      this.getProdutos()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      /* align-items: flex-start; */
      width:100%;
      max-height:65vh;
      overflow:auto;
      padding:0px!important;
    }
</style>
