<template>
  <div class="centralizadohorizontal">
    <div class="estiloColuna">
      <v-card
        elevation="2"
        outlined
        style="
          padding-bottom: 5px !important;
          height: 95%;
          max-height: 95%;
          margin-top: 1%;
          padding: 10px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
          "
        >
          <BotaoTutorial
            categoria="14"
            legenda="Dúvidas Relatórios"
            urlVideo="https://drive.google.com/file/d/1xe7LK1yXq7I72oPb1KIkOFFONzXbKoPK/preview"
          />
        </div>
        <v-card-text>
          <div class="topoform">
            <div class="divinput">
              <label for="">Relatório </label>
              <select v-model="idrelatorio" class="select" @change="setnome()">
                <option
                  v-for="relatorio in listarelatorios"
                  :key="relatorio.id"
                  :value="relatorio.id"
                >
                  {{ relatorio.nome }}
                </option>
              </select>
            </div>

            <template v-if="idrelatorio === 3">
              <div class="divinput">
                <label for="">Competência</label>
                <select v-model="competencia" class="select">
                  <option
                    v-for="c in competenciaList"
                    :key="c.valor"
                    :value="c.valor"
                  >
                    {{ c.nome }}
                  </option>
                </select>
              </div>
              <div class="divinput">
                <label for="">Garçon</label>
                <select v-model="vendedorComissao" class="select">
                  <option
                    v-for="v in vendedorComissaoList"
                    :key="v.valor"
                    :value="v.valor"
                  >
                    {{ v.nome }}
                  </option>
                </select>
              </div>
              <div class="divinput">
                <label for="">Regra</label>
                <select v-model="regraComissao" class="select">
                  <option
                    v-for="r in regraComissaoList"
                    :key="r.valor"
                    :value="r.valor"
                  >
                    {{ r.nome }}
                  </option>
                </select>
              </div>
              <div class="divinput">
                <label for="">PDV</label>
                <select v-model="pdvComissao" class="select">
                  <option
                    v-for="p in pdvComissaoList"
                    :key="p.valor"
                    :value="p.valor"
                  >
                    {{ p.nome }}
                  </option>
                </select>
              </div>
              <div class="divinput">
                <label for="">Categoria</label>
                <select v-model="categoriaComissao" class="select">
                  <option
                    v-for="c in categoriaComissaoList"
                    :key="c.valor"
                    :value="c.valor"
                  >
                    {{ c.nome }}
                  </option>
                </select>
              </div>
            </template>

            <div class="divinput" v-if="idrelatorio != 3">
              <label for="">Data Inicial</label>
              <input
                type="date"
                class="input"
                placeholder="Enter email"
                v-model="datainicial"
              />
            </div>

            <div class="divinput" v-if="idrelatorio != 3">
              <label for="">Data Final</label>
              <input
                type="date"
                class="input"
                placeholder="Enter email"
                v-model="datafinal"
              />
            </div>

            <div class="divinput" v-if="idrelatorio != 3 && idrelatorio != 9">
              <label for="">Status</label>
              <select v-model="idstatus" class="select" @change="setnome()">
                <option
                  v-for="status in listastatus"
                  :key="status.id"
                  :value="status.id"
                >
                  {{ status.nome }}
                </option>
              </select>
            </div>

            <div
              class="divinput"
              v-if="
                idrelatorio == 2 ||
                  idrelatorio == 4 ||
                  idrelatorio == 9 ||
                  idrelatorio == 10
              "
            >
              <label for="">Nome</label>
              <input
                type="text"
                class="input"
                placeholder="Nome"
                v-model="pesquisanome"
              />
            </div>

            <div
              class="divinput"
              v-if="idrelatorio == 2 || idrelatorio == 4 || idrelatorio == 10"
            >
              <label for="">Reserva</label>
              <input
                type="text"
                class="input"
                placeholder="Reserva"
                v-model="pesquisareserva"
              />
            </div>

            <div
              class="divinput"
              v-if="idrelatorio == 2 || idrelatorio == 4 || idrelatorio == 10"
            >
              <label for="">UH</label>
              <input
                type="text"
                class="input"
                placeholder="UH"
                v-model="pesquisauh"
              />
            </div>

            <div class="divinput" v-if="idrelatorio == 10">
              <label for="">ID Comanda</label>
              <input
                type="text"
                class="input"
                placeholder="ID Comanda"
                v-model="idComanda"
              />
            </div>

            <div class="divinput" v-if="idrelatorio == 2 || idrelatorio == 4">
              <label for="">Data Checkin</label>
              <input
                type="text"
                class="input"
                placeholder="Data Checkin"
                v-model="pesquisadtcheckin"
              />
            </div>

            <div class="divinput" v-if="idrelatorio == 12">
                <label for="horaInicial">Horário Inicial</label>
                <v-menu
                  ref="menuInicio"
                  v-model="menuInicio"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <input
                      type="text"
                      class="input"
                      placeholder="Selecione o horário inicial"
                      v-bind="attrs"
                      v-on="on"
                      v-model="horaInicial"
                      readonly
                    />
                  </template>
                  <v-time-picker
                    v-model="horaInicial"
                    full-width
                    @click:minute="$refs.menuInicio.save(horaInicial)"
                  ></v-time-picker>
                </v-menu>
              </div>

              <div class="divinput" v-if="idrelatorio == 12">
                <label for="horaFinal">Horário Final</label>
                <v-menu
                  ref="menuFinal"
                  v-model="menuFinal"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <input
                      type="text"
                      class="input"
                      placeholder="Selecione o horário final"
                      v-bind="attrs"
                      v-on="on"
                      v-model="horaFinal"
                      readonly
                    />
                  </template>
                  <v-time-picker
                    v-model="horaFinal"
                    full-width
                    @click:minute="$refs.menuFinal.save(horaFinal)"
                  ></v-time-picker>
                </v-menu>
              </div>


            <div
              style="
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                margin-right: 40px;
                width: 100%;
              "
            >
              <v-btn
                :disabled="txtBtnGerar === 'Gerando...'"
                large
                class="mx-2"
                color="primary"
                style="min-width: 250px"
                @click="gerarRelatorio()"
                >{{ txtBtnGerar }}</v-btn
              >
              <template v-if="idrelatorio === 3">
                <v-btn
                  large
                  color="success"
                  @click="gerarExcel()"
                  :disabled="gerandoRelatorio"
                >
                  <v-icon>mdi-download</v-icon>
                  {{ txtBtnBaixarPlanilha }}
                </v-btn>
              </template>
            </div>
            <iframe :id="'iframe_downloader'" style="display:none;"></iframe>
          </div>

          <div
            style="
              width: 100%;
              height: 8vh;
              position: absulute;
              margin-bottom: 0px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
          </div>
        </v-card-text>
        <div
          v-if="idrelatorio !== 3"
          style="display: flex; justify-content: flex-end; padding-right: 60px"
        >
          <JsonCSV :data="items" :name="nomerelatorioaux">
            <div
              style="
                background-color: #2b81d6;
                width: 250px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: white;
                border-radius: 5px;
              "
            >
              Baixar Planilha .CSV
            </div>
          </JsonCSV>

          <v-btn
            style="
                background-color: #2b81d6;margin-left:20px;
                width: 250px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: white;
                border-radius: 5px;
              "
            @click="baixar()"
            >Baixar Planilha Excel</v-btn
          >
        </div>
      </v-card>
    </div>

    <div class="estiloColuna">
      <v-card
        elevation="2"
        outlined
        style="
          padding-bottom: 5px !important;
          height: 95%;
          max-height: 95%;
          margin-top: 1%;
        "
      >
        <v-card-title primary-title>
          <div style="padding-left: 10px; padding-right: 10px">
            <div style="display: flex; flex-direction: column">
              <div>Nome: {{ nomeRelatorio }}</div>
              <div v-if="idrelatorio !== 3">Status: {{ nomeStatus }}</div>
            </div>
          </div>
        </v-card-title>

        <div style="padding-left: 20px; padding-right: 20px">
          <v-data-table :headers="headers" :items="items" :search="search">
            <template v-slot:[`item.datahoraabertura`]="{ item }">
              {{ item.datahoraabertura | formatarDataHora }}
            </template>

            <template v-slot:[`item.datacheckin`]="{ item }">
              {{ item.datacheckin | formatarData }}
            </template>

            <template v-slot:[`item.datacheckoutprev`]="{ item }">
              {{ item.datacheckoutprev | formatarData }}
            </template>

            <template v-slot:[`item.dataentregue`]="{ item }">
              {{ item.dataentregue | formatarDataHora }}
            </template>

            <template v-slot:[`item.dataassinatura`]="{ item }">
              {{ item.dataassinatura | formatarDataHora }}
            </template>

            <template v-slot:[`item.imgassinatura`]="{ item }">
              <a @click="carregarModalAssinatura(item)"> Assinatura </a>
            </template>

            <template v-slot:[`item.status_item`]="{ item }">
              <a
                @click="setModalTrocarStatusDoItem(item)"
                v-if="item.id_status_item != 5 && item.id_status_item != 7"
              >
                {{ item.status_item }}
              </a>
              <span v-else> {{ item.status_item }} </span>
            </template>

            <template v-slot:[`item.confirmacao_integracao`]="{ item }">
              <a
                v-if="item.confirmacao_integracao"
                @click="carregarModalConfirmacao(item.confirmacao_integracao)"
              >
                Ver Confirmacao
              </a>
            </template>

            <template v-slot:[`item.idcomanda`]="{ item }">
              <a
                @click="
                  idrelatorio != 10
                    ? carregarModalItemsComanda(item)
                    : carregarModalItemsComandaCompleto(item)
                "
              >
                {{ item.idcomanda }}
              </a>
            </template>

            <template v-slot:[`item.hora_entregue`]="{ item }">
              <span v-if="item.hora_entregue">
                {{ item.hora_entregue | formatarDataHora }}
              </span>
              <span v-else>
                {{ item.hora_entregue }}
              </span>
            </template>

            <template v-slot:[`item.data_fechamento_comanda`]="{ item }">
              <span v-if="item.data_fechamento_comanda">
                {{ item.data_fechamento_comanda | formatarDataHora }}
              </span>
              <span v-else>
                {{ item.data_fechamento_comanda }}
              </span>
            </template>

            <template v-slot:[`item.abertura_comanda`]="{ item }">
              <span v-if="item.abertura_comanda">
                {{ item.abertura_comanda | formatarDataHora }}
              </span>
              <span v-else>
                {{ item.abertura_comanda }}
              </span>
            </template>

            <template v-slot:[`item.integracao_pms`]="{ item }">
              <span v-if="item.integracao_pms != 'Não Integrou'">
                {{ item.integracao_pms | formatarDataHora }}
              </span>
              <span v-else>
                {{ item.integracao_pms }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>

    <ModalAssinatura
      v-if="exibirModalAssinatura"
      :exibirModalAssinatura="exibirModalAssinatura"
      :dados="itemparamodalassinatura"
      :pedido="itemparamodalassinatura"
      @fechar="() => setexibirModalAssinatura()"
    />

    <ModalConfirmacaoIntegracao
      v-if="exibirModalConfirmacao"
      :exibirModalConfirmacao="exibirModalConfirmacao"
      :dados="dadosConfirmacao"
      @fechar="() => setexibirModalConfirmacao()"
    />

    <ModalItensComanda
      v-if="exibirModalItensComanda"
      :exibirModalItensComanda="exibirModalItensComanda"
      :pedido="itemparamodalItensComanda"
      @imprimir="(pedido) => imprimir(pedido)"
      @fechar="() => setexibirModalItensComanda()"
    />

    <ModalItensComandaCompleto
      v-if="exibirModalItensComandaCompleto"
      :exibirModalItensComanda="exibirModalItensComandaCompleto"
      :pedido="itemComandaCompleta"
      @imprimir="(pedido) => imprimir(pedido)"
      @fechar="() => setexibirModalItensComandaCompleto()"
    />

    <ModalTrocarStatusDoItem
      v-if="exibirModalTrocarStatusDoItem"
      :exibirModalTrocarStatusDoItem="exibirModalTrocarStatusDoItem"
      :item="itemparamodalTrocarStatusDoItem"
      @fechar="(value) => setexibirModalTrocarStatusDoItem(value)"
      @gerarRelatorio="gerarRelatorio"
    />

    <v-dialog
      v-model="exibirModalImpressaoPedidoRelatorio"
      width="1000"
      max-width="80vw"
    >
      <div
        v-if="pedidoImprimir"
        id="modalImpressao"
        style="
          background-color: white;
          width: 100vw;
          min-height: 100vh;
          position: absolute;
          top: 0;
        "
      >
        <table
          class="printer-ticket"
          style="max-width: 60mm; background-color: #fcffb4 !important"
        >
          <thead style="max-width: 60mm">
            <tr>
              <th class="title" colspan="3">Menu Digital</th>
            </tr>
            <tr>
              <th colspan="3" style="font-size: 10px">
                COD: {{ pedidoImprimir.idcomanda }}
              </th>
            </tr>
            <tr>
              <th colspan="3" style="font-size: 10px">
                ------ UH/MESA - NOME ------
              </th>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 10px">
                {{ pedidoImprimir.numerolocal }} - {{ pedidoImprimir.nome }}
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td colspan="3" style="font-size: 10px">
                UH: {{ pedidoImprimir.coduh }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style="font-size:10px;"
                v-show="parametrosImpressao.mostrarGarcom"
              >
                GARCOM: {{ pedidoImprimir.nomegarcom }}
              </td>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 10px">
                DATA: {{ pedidoImprimir.datahoraabertura | formatarDataHora }}
              </td>
            </tr>
            <tr>
              <td colspan="3" style="font-size: 10px">
                QTDE: {{ pedidoImprimir.itens.length }} PRODUTO(S)
              </td>
              <!-- eslint-disable-next-line vue/no-parsing-error -->
            </tr>
            <tr>
              <th colspan="3" style="font-size: 10px">
                <b>-------------- ITENS --------------</b>
              </th>
            </tr>
          </thead>
          <tbody style="max-width: 60mm">
            <div
              :key="item.idcomandaproduto"
              v-for="item in pedidoImprimir.itens"
            >
              <tr class="top">
                <td style="font-size: 10px">{{ item.nomecd }}</td>
              </tr>
              <tr>
                <td style="font-size: 10px">
                  {{ item.precounitario | formatarMoeda }}
                </td>
                <td style="font-size: 10px">{{ item.quantidade }}</td>
                <td style="font-size: 10px">
                  {{ (item.precounitario * item.quantidade) | formatarMoeda }}
                </td>
              </tr>
              <tr v-if="item.valortaxa > 0">
                <td style="font-size: 10px">taxa</td>
                <td style="font-size: 10px"></td>
                <td style="font-size: 10px">
                  {{
                    (item.precounitario *
                      (item.valortaxa / 100) *
                      item.quantidade)
                      | formatarMoeda
                  }}
                </td>
              </tr>

              <tr
                class="top"
                v-if="
                  item.opcional_escolhido && item.opcional_escolhido.length > 0
                "
              >
                <td style="font-size: 10px">Opcionais</td>
              </tr>
              <tr
                class="top"
                v-for="opcional in item.opcional_escolhido"
                :key="opcional.descricao"
              >
                <td style="font-size: 10px;padding-left:10px;">
                  {{ opcional.descricao }}
                </td>
              </tr>

              <tr class="top">
                <td style="font-size: 10px">Obs:{{ item.observacao }}</td>
              </tr>
              <tr class="top">
                <td>&nbsp;</td>
              </tr>
            </div>

            <tr class="top">
              <td colspan="3">
                Obs Geral: {{ pedidoImprimir.observacaogerais }}
              </td>
            </tr>

            <tr class="top">
              <th colspan="3"><b>-------------------------------------</b></th>
            </tr>

            <tr style="height: 60px">
              <td style="font-size: 10px"><b>Total</b></td>
              <td></td>
              <td style="font-size: 10px">
                {{ pedidoImprimir.totalComanda | formatarMoeda }}
              </td>
            </tr>

            <tr
              style="height: 20px;"
              v-show="parametrosImpressao.mostrarTotalTaxaProduto"
            >
              <td style="font-size:10px;"><b>Total taxas de produto</b></td>
              <td></td>
              <td style="font-size:10px;">
                {{ pedidoImprimir.totalTaxasPorProduto | formatarMoeda }}
              </td>
            </tr>
          </tbody>
          <br />
          <br />
          <tfoot style="max-width: 30px">
            <tr class="sup ttu p--0">
              <td colspan="3" style="font-size: 10px">
                <img
                  style="width: 80%"
                  v-if="imgassinaturasrc"
                  v-bind:src="'data:image/jpeg;base64,' + imgassinaturasrc"
                />
                <br />
                <b>__________________________</b>
              </td>
            </tr>
            <tr class="sup ttu p--0">
              <td colspan="3" style="font-size: 12px">
                <b>{{ pedidoImprimir.nome }}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ModalAssinatura from "../../components/layout/Modais/ModalAssinatura.vue";
import ModalItensComanda from "../../components/layout/Modais/ModalItensComanda.vue";
import ModalItensComandaCompleto from "../../components/layout/Modais/ModalItensComandaCompleto.vue";
import ModalTrocarStatusDoItem from "../../components/layout/Modais/ModalTrocarStatusDoItem.vue";
import ModalConfirmacaoIntegracao from "../../components/layout/Modais/ModalConfirmacaoIntegracao.vue";
//import VueHtml2pdf from "vue-html2pdf";
import JsonCSV from "vue-json-csv";
import BotaoTutorial from "../../components/layout/BotaoTutorial.vue";

const dayjs = require("dayjs");

export default {
  name: "Relatorio",
  components: {
    ModalAssinatura,
    ModalItensComanda,
    ModalTrocarStatusDoItem,
    ModalConfirmacaoIntegracao,
    ModalItensComandaCompleto,
    //VueHtml2pdf,
    JsonCSV,
    BotaoTutorial,
  },
  data: () => ({
    horaInicial: null,
    horaFinal: null,
    dadosConfirmacao: null,
    nomeHotel: localStorage.getItem("nomehotel"),
    telaatual: "fluxo",
    listarelatorios: [],
    idrelatorio: 4,
    listastatus: [],
    idstatus: 0,
    datainicial: null,
    datafinal: null,
    nomeRelatorio: "Relatorio de Pedidos Analítico",
    nomerelatorioaux: "",
    nomeStatus: "Todos",
    headers: [],
    items: [],
    search: "",
    exibirModalAssinatura: false,
    itemparamodalassinatura: null,
    exibirModalItensComanda: false,
    itemparamodalItensComanda: null,
    exibirModalImpressaoPedidoRelatorio: false,
    dadosComissao: [],
    dadosComissaoExcel: [],
    dadosPedidosAnalitico: [],
    planilhaPedidosAnaliticos: [],
    pesquisanome: null,
    pesquisareserva: null,
    pesquisauh: null,
    pesquisadtcheckin: null,
    showModal: false,
    pedidoImprimir: null,
    txtBtnGerar: "GERAR",
    imgassinaturasrc: null,
    varTotalComissaoGeral: 0,
    varTotalVendaGeral: 0,
    datainicialformatada: null,
    datafinalformatada: null,
    exibirModalConfirmacao: false,
    competenciaList: [],
    competencia: null,
    vendedorComissaoList: [],
    vendedorComissao: null,
    regraComissaoList: [],
    regraComissao: null,
    pdvComissaoList: [],
    pdvComissao: null,
    categoriaComissaoList: [],
    categoriaComissao: null,
    txtBtnBaixarPlanilha: "Baixar Planilha",
    gerandoRelatorio: false,
    ehMaster: false,
    ehGarcon: false,
    idComanda: null,

    exibirModalItensComandaCompleto: false,
    itemComandaCompleta: null,

    exibirModalTrocarStatusDoItem: false,
    itemparamodalTrocarStatusDoItem: null,
  }),
  methods: {
    baixar() {
      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        idstatus: this.idstatus,
        nome: this.pesquisanome,
        numreserva: this.pesquisareserva,
        uh: this.pesquisauh,
        datacheckin: this.pesquisadtcheckin,
      };

      this.$http
        .post("relatorio/relatorioexcel", param)
        .then((resp) => {
          console.log(resp);

          let url = `${this.$dados.BUILD_API_URL}${
            resp.data.link
          }?${Date.now()}`;
          console.log(url);
          document.getElementById("iframe_downloader").src = url;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gerarExcel() {
      this.gerandoRelatorio = true;
      this.txtBtnBaixarPlanilha = "Gerando planilha...";
      let competenciaSelecionada = this.competenciaList.find(
        (x) => x.valor === this.competencia
      );
      let param = {
        idrelatorio: this.idrelatorio,
        competencia: competenciaSelecionada.nome,
        ano: competenciaSelecionada.ano,
        mes: competenciaSelecionada.mes,
        idvendedor: this.vendedorComissao,
        idregra: this.regraComissao,
        idpdv: this.pdvComissao,
        idcategoria: this.categoriaComissao,
      };
      this.$http
        .post("relatorio/comissao/excel", param)
        .then((response) => {
          this.gerandoRelatorio = false;
          this.txtBtnBaixarPlanilha = "Baixar Planilha";
          this.$alertar("success", "Excel gerado!", "=)");
          // setTimeout comentado pois foi resolvido o retorno prematuro do backend
          //setTimeout(()=>{
          let url = `${this.$dados.BUILD_API_URL}${
            response.data.link
          }?${Date.now()}`;
          document.getElementById("iframe_downloader").src = url;
          //},3000);
        })
        .catch(() => {
          this.gerandoRelatorio = false;
          this.txtBtnBaixarPlanilha = "Baixar Planilha";
          this.$alertar("warning", "Não foi possível gerar o relatório!", "=(");
        });
    },
    setImpressaoModal() {
      this.exibirModalImpressaoPedidoRelatorio = !this
        .exibirModalImpressaoPedidoRelatorio;
    },
    async imprimir(pedido) {
      this.pedidoImprimir = pedido;
      this.parametrosImpressao = await this.$http
        .post("/pdv/buscarparametros", {
          chave: "CONFIGS_COMANDAS_RELATORIO_IMPRESSAO",
        })
        .then((res) => JSON.parse(res.data[0].valor))
        .catch(() => false);

      this.exibirModalItensComanda = false;
      this.exibirModalImpressaoPedidoRelatorio = true;
      this.pedidoImprimir.totalComanda = 0;
      const totalTaxasPorProduto = this.pedidoImprimir.itens.reduce(
        (acc, item) => {
          return (
            acc +
            (item.valortaxa / 100) *
              parseFloat(item.quantidade) *
              parseFloat(item.precounitario)
          );
        },
        0
      );

      this.pedidoImprimir = {
        ...this.pedidoImprimir,
        totalTaxasPorProduto,
      };

      console.log(this.pedidoImprimir.totalTaxasPorProduto);
      for (let i = 0, len = this.pedidoImprimir.itens.length; i < len; i++) {
        if (this.pedidoImprimir.itens[i].valortaxa > 0) {
          let valorComTaxa =
            parseFloat(this.pedidoImprimir.itens[i].precounitario) *
            parseFloat(this.pedidoImprimir.itens[i].quantidade);
          valorComTaxa =
            valorComTaxa +
            valorComTaxa * (this.pedidoImprimir.itens[i].valortaxa / 100);
          this.pedidoImprimir.totalComanda += valorComTaxa;
        } else {
          this.pedidoImprimir.totalComanda +=
            parseFloat(this.pedidoImprimir.itens[i].precounitario) *
            parseFloat(this.pedidoImprimir.itens[i].quantidade);
        }
      }

      await this.$http("pdv/getassinaturacomanda/" + pedido.numpedido)
        .then((resp) => {
          this.imgassinaturasrc = resp.data[0].imgassinatura;
        })
        .catch((error) => {
          console.log(error);
        });

      var self = this;
      setTimeout(() => {
        this.$htmlToPaper("modalImpressao");
        self.exibirModalImpressaoPedidoRelatorio = false;
      }, 1000);
    },
    async gerarPDF() {
      await this.$refs.html2Pdf.generatePdf();
      let self = this;
      setTimeout(() => {
        self.window.location.reload();
      }, 10000);
    },
    continuar() {
      this.$router.push("/Hospede/Menu");
    },
    async setarTela(tela) {
      this.telaatual = tela;
    },
    salvar(parametros) {
      if (parametros.tela == "fluxo") {
        this.$http
          .put("pdv/updateparametros", parametros.parametro)
          .then(() => {
            this.$alertar("success", "Parametro Atualizado com Sucesso!", "=)");
          })
          .catch(() => {
            this.$alertar(
              "success",
              "Não foi Possível atualizar com Sucesso!",
              "=("
            );
          });
      }
    },
    setnome() {
      let nomerel = this.listarelatorios.find((x) => {
        return x.id === this.idrelatorio;
      });
      this.nomeRelatorio = nomerel.nome;

      let nomest = this.listastatus.find((x) => {
        return x.id === this.idstatus;
      });
      this.nomeStatus = nomest.nome;
      this.dadosComissaoExcel = [];
      this.dadosPedidosAnalitico = [];
      this.items = [];
    },
    gerarRelatorio() {
      this.dadosComissaoExcel = [];
      this.txtBtnGerar = "Gerando...";
      if (this.idrelatorio === 1) {
        this.relatorioPerformance();
      }
      if (this.idrelatorio === 2) {
        this.relatorioPedidos();
      }
      if (this.idrelatorio === 3) {
        this.relatorioComissoes();
      }
      if (this.idrelatorio === 4) {
        this.relatorioPedidosAnalitico();
      }
      if (this.idrelatorio === 5) {
        this.relatorioComandasAbertas();
      }
      if (this.idrelatorio === 7) {
        this.relatorioItensRemovidoComanda();
      }
      if (this.idrelatorio === 8) {
        this.relatorioFiltro();
      }
      if (this.idrelatorio === 9) {
        this.relatorioPagtoPassantes();
      }
      if (this.idrelatorio === 10) {
        this.relatorioComandasCompleto();
      }
      if (this.idrelatorio === 11) {
        this.relatorioOrdemPagamentosGateway();
      }
      if (this.idrelatorio === 12) {
        this.relatorioDePedidosPorFaixaHorario();
      }
    },
    async relatorioDePedidosPorFaixaHorario() {
      this.nomerelatorioaux =
        "relatorio_pedidos_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      if (this.horaInicial && !this.horaFinal) {
        this.$alertar("warning", "Horário final obrigatório!", "=/");
        this.txtBtnGerar = "GERAR";
        return;
      }

      if (!this.horaInicial && this.horaFinal) {
        this.$alertar("warning", "Horário inicial obrigatório!", "=/");
        this.txtBtnGerar = "GERAR";
        return;
      }

      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        horaInicial: this.horaInicial ?? null,
        horaFinal: this.horaFinal ?? null,
        idstatus: this.idstatus,
      };
      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          if (resp.data.length > 0) {
            this.headers = [
              { text: "Horário", value: "hora", width: "120px" },
              { text: "Qtde Comandas", value: "qtde_comandas", width: "120px" },
              { text: "Qtde Itens", value: "qtde_itens", width: "120px" },
            ];
            this.items = resp.data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    async relatorioComissoes() {
      try {
        let competenciaSelecionada = this.competenciaList.find(
          (x) => x.valor === this.competencia
        );
        let param = {
          idrelatorio: this.idrelatorio,
          ano: competenciaSelecionada.ano,
          mes: competenciaSelecionada.mes,
          idvendedor: this.vendedorComissao,
          idregra: this.regraComissao,
          idpdv: this.pdvComissao,
          idcategoria: this.categoriaComissao,
        };

        //console.error(`param: ${JSON.stringify(param)}`)

        await this.$http
          .post("relatorio/get", param)
          .then((resp) => {
            this.items = resp.data;
            this.headers = [
              { text: "Vendedor", value: "vendedor" },
              { text: "Regra", value: "regra" },
              { text: "PDV", value: "pdv" },
              { text: "Categoria", value: "categoria" },
              { text: "Produto", value: "produto" },
              { text: "Total", value: "total", align: "end" },
              {
                text: "Comissão (%)",
                value: "comissao_percentual",
                align: "end",
              },
              { text: "Comissão (R$)", value: "comissao_valor", align: "end" },
            ];
            this.txtBtnGerar = "GERAR";
          })
          .catch((error) => {
            this.$alertar(
              "warning",
              "Não foi possível gerar o relatorio",
              "=("
            );
            console.log("ERROR > > relatorio de comissões ", error);
            this.txtBtnGerar = "GERAR";
          });
      } catch (error) {
        console.log("error >> 22 ", error);
        this.txtBtnGerar = "GERAR";
      }
    },
    relatorioPerformance() {
      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        idstatus: this.idstatus,
      };
      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          this.nomerelatorioaux =
            "relatorio_perfomance_" +
            dayjs(this.datainicial).format("DD-MM-YYYY") +
            "_a_" +
            dayjs(this.datafinal).format("DD-MM-YYYY") +
            ".csv";

          if (resp.data.length > 0) {
            this.headers = [
              { text: "Nome", value: "nome" },
              { text: "Tempo no Caixa", value: "nocaixa" },
              { text: "Tempo na Cozinha", value: "nacozinha" },
              { text: "Tempo no Garçom", value: "nogarcom" },
              { text: "Tempo no Bar", value: "nobar" },
            ];
            this.items = resp.data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    relatorioPedidos() {
      this.nomerelatorioaux =
        "relatorio_pedidos_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        horaInicial: this.horaInicial ?? null,
        horaFinal: this.horaFinal ?? null,
        idstatus: this.idstatus,
        nome: this.pesquisanome,
        numreserva: this.pesquisareserva,
        uh: this.pesquisauh,
        datacheckin: this.pesquisadtcheckin,
      };
      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          if (resp.data.length > 0) {
            this.headers = [
              { text: "ID Comanda", value: "idcomanda", width: "120px" },
              { text: "Garçom", value: "nomegarcom", width: "120px" },
              { text: "Status", value: "nomestatus", width: "120px" },
              { text: "Aberta?", value: "comandaaberta", width: "120px" },
              { text: "Nome", value: "nome", width: "120px" },
              { text: "Sobrenome", value: "sobrenome", width: "120px" },
              { text: "Assinatura", value: "imgassinatura", width: "120px" },
              { text: "UH", value: "coduh", width: "100px" },
              { text: "Reserva", value: "numreserva", width: "120px" },
              { text: "DT Checkin", value: "datacheckin", width: "120px" },
              { text: "Cartão", value: "cartaoconsumo", width: "120px" },
              {
                text: "Solicitado Em",
                value: "datahoraabertura",
                width: "150px",
              },
              { text: "Entregue Em", value: "dataentregue", width: "150px" },
              { text: "Local", value: "localentrega", width: "120px" },
              { text: "Número", value: "numerolocal", width: "120px" },
            ];
            this.items = resp.data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    relatorioComandasAbertas() {
      this.nomerelatorioaux =
        "relatorio_comandas_abertas_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
      };

      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          if (resp.data.length > 0) {
            this.headers = [
              { text: "ID Comanda", value: "idcomanda", width: "120px" },
              { text: "Garçom", value: "nomegarcom", width: "120px" },
              { text: "Aberta?", value: "comandaaberta", width: "120px" },
              { text: "Status", value: "nomestatus", width: "120px" },
              { text: "Nome", value: "nome", width: "120px" },
              { text: "Sobrenome", value: "sobrenome", width: "120px" },
              { text: "UH", value: "coduh", width: "100px" },
              { text: "Reserva", value: "numreserva", width: "120px" },
              { text: "Cartão", value: "cartaoconsumo", width: "120px" },
              {
                text: "Solicitado Em",
                value: "datahoraabertura",
                width: "150px",
              },
              { text: "Local", value: "localentrega", width: "120px" },
              { text: "Número", value: "numerolocal", width: "120px" },
            ];
            this.items = resp.data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          alert(4);
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    relatorioOrdemPagamentosGateway() {
      this.nomerelatorioaux =
        "relatorio_ordem_pagtos_gateway_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
      };

      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          if (resp.data.length > 0) {
            this.headers = [
              { text: "ID Pagto", value: "id_pagamento", width: "120px" },
              { text: "ID Comanda", value: "idcomanda", width: "120px" },
              { text: "Id Hospede", value: "idhospede", width: "120px" },
              { text: "Nome", value: "nome", width: "120px" },
              { text: "Sobrenome", value: "sobrenome", width: "120px" },
              {
                text: "Id Pagto Gateway",
                value: "id_pedido_pagarme",
                width: "120px",
              },
              { text: "Status", value: "status", width: "120px" },
              {
                text: "Hora Recebido API",
                value: "hora_recebido_api",
                width: "120px",
              },
            ];
            this.items = resp.data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          alert(4);
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    relatorioPedidosAnalitico() {
      this.dadosPedidosAnalitico = [];

      this.nomerelatorioaux =
        "relatorio_pedidos_analitico_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".xls";

      let param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        horaInicial: this.horaInicial ?? null,
        horaFinal: this.horaFinal ?? null,
        idstatus: this.idstatus,
        nome: this.pesquisanome,
        numreserva: this.pesquisareserva,
        uh: this.pesquisauh,
        datacheckin: this.pesquisadtcheckin,
      };

      this.$http
        .post("relatorio/get", param)
        .then((resp) => {
          function calculaTotalPorProduto(data) {
            return data.map((item) => {
              // let total =(Number(item.precounitario) * Number(item.quantidade)).toFixed(2);
              let total = (
                Number(item.precounitariofinal) * Number(item.quantidade)
              ).toFixed(2);
              item.precototal = total.toString().replace(".", ",");
              return item;
            });
          }

          function replaceDotByCommaUnitPrice(data) {
            return data.map((item) => {
              item.precounitario = item.precounitario
                .toString()
                .replace(".", ",");
            });
          }

          if (resp.data.length > 0) {
            this.headers = [
              { text: "ID Comanda", value: "idcomanda", width: "120px" },
              { text: "Garçom", value: "nomegarcom", width: "100px" },
              { text: "Aberta?", value: "comandaaberta", width: "120px" },
              { text: "Integrado ao PMS", value: "integrado", width: "100px" },
              {
                text: "Dados Confirmação",
                value: "confirmacao_integracao",
                width: "100px",
              },
              { text: "Status Comanda", value: "nomestatus", width: "120px" },
              { text: "Status Item", value: "status_item", width: "120px" },
              { text: "Nome", value: "nome", width: "120px" },
              { text: "Sobrenome", value: "sobrenome", width: "120px" },
              { text: "Assinatura", value: "imgassinatura", width: "120px" },
              { text: "UH", value: "coduh", width: "100px" },
              { text: "Reserva", value: "numreserva", width: "120px" },
              { text: "DT Checkin", value: "datacheckin", width: "120px" },
              {
                text: "DT Checkout",
                value: "datacheckoutprev",
                width: "120px",
              },

              { text: "Cartão", value: "cartaoconsumo", width: "120px" },
              {
                text: "Solicitado Em",
                value: "datahoraabertura",
                width: "150px",
              },
              { text: "Entregue Em", value: "dataentregue", width: "150px" },
              { text: "Assinado Em", value: "dataassinatura", width: "150px" },
              { text: "Local", value: "localentrega", width: "120px" },
              { text: "Número", value: "numerolocal", width: "120px" },
              { text: "ID Item", value: "iditem", width: "120px" },
              { text: "Qtde", value: "quantidade", width: "120px" },
              { text: "Preço Unit.", value: "precounitario", width: "120px" },
              {
                text: "Desconto. Cat.",
                value: "desconto_categoria",
                width: "120px",
              },
              {
                text: "Preço Final.",
                value: "precounitariofinal",
                width: "120px",
              },
              { text: "Preço Total", value: "precototal", width: "120px" },
              { text: "Descrição", value: "descproduto", width: "120px" },
              {
                text: "Cod. Grupo PMS",
                value: "codgrupoprodpms",
                width: "120px",
              },
              {
                text: "Cod. Prod. PMS",
                value: "codprodutopms",
                width: "120px",
              },
              { text: "Nome CD", value: "nomecd", width: "120px" },
            ];

            resp.data = calculaTotalPorProduto(resp.data);

            this.items = resp.data;

            resp.data = replaceDotByCommaUnitPrice(resp.data);

            this.dadosPedidosAnalitico = this.items;
            let planilhaPedidosAnaliticos = [];
            for (
              let i = 0, len = this.dadosPedidosAnalitico.length;
              i < len;
              i++
            ) {
              let obj = this.dadosPedidosAnalitico[i];
              delete obj.imgassinatura;
              planilhaPedidosAnaliticos.push(obj);
            }
            this.planilhaPedidosAnaliticos = planilhaPedidosAnaliticos;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
          this.txtBtnGerar = "GERAR";
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
          this.txtBtnGerar = "GERAR";
        });
    },
    relatorioItensRemovidoComanda() {
      this.nomerelatorioaux =
        "relatorio_items_removidos_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      this.itemsRemovidosComanda = [];

      const params = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        idstatus: this.idstatus,
      };

      this.$http.post("relatorio/get", params).then((res) => {
        this.headers = [
          { text: "ID", value: "id", width: "10px" },
          { text: "Comanda", value: "idcomanda", width: "15px" },
          { text: "Nome Produto", value: "nomecd", width: "20px" },
          { text: "Garçom", value: "idusuariogarcom", width: "10px" },
          { text: "Preço Unitário", value: "precounitario", width: "88px" },
          { text: "Data da Remoção", value: "dataremocao", width: "88px" },
          { text: "Qtd", value: "quantidade", width: "20px" },
          { text: "Observaçao", value: "observacao", width: "160px" },
          { text: "Motivo Remoção", value: "motivoremocao", width: "160px" },
          // { text: 'Id Produto', value: 'idproduto',width: '120px' },
        ];
        delete res.data.idproduto;
        delete res.data.quantidade;
        res.data = res.data.map((item) => {
          return {
            ...item,
            dataremocao: dayjs(item.dataremocao).format("DD/MM/YYYY HH:mm:ss"),
          };
        });
        this.items = res.data;
      });
      this.txtBtnGerar = "GERAR";
    },
    relatorioPagtoPassantes() {
      this.nomerelatorioaux =
        "relatorio_pagto_passantes_" +
        dayjs(this.datainicial).format("DD-MM-YYYY") +
        "_a_" +
        dayjs(this.datafinal).format("DD-MM-YYYY") +
        ".csv";

      this.itemsRemovidosComanda = [];

      const params = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        idstatus: this.idstatus,
        nome: this.pesquisanome,
      };

      this.$http.post("relatorio/get", params).then((res) => {
        this.headers = [
          { text: "ID", value: "idcomanda", width: "10px" },
          { text: "Data Hora", value: "datahoracriacao", width: "15px" },
          { text: "Passante", value: "hospede", width: "15px" },
          { text: "Tipo Pagamento", value: "tipopagamento", width: "20px" },
          { text: "Valor", value: "valor", width: "10px" },
          { text: "Usuario", value: "nomeusuario", width: "88px" },
        ];
        delete res.data.idproduto;
        delete res.data.quantidade;
        res.data = res.data.map((item) => {
          return {
            ...item,
            dataremocao: dayjs(item.dataremocao).format("DD/MM/YYYY HH:mm:ss"),
          };
        });
        this.items = res.data;
      });
      this.txtBtnGerar = "GERAR";
    },
    relatorioComandasCompleto() {
      const param = {
        idrelatorio: this.idrelatorio,
        datainicial: this.datainicial,
        datafinal: this.datafinal,
        idstatus: this.idstatus,
        nome: this.pesquisanome,
        numreserva: this.pesquisareserva,
        uh: this.pesquisauh,
        idcomanda: this.idComanda,
      };

      this.$http
        .post("relatorio/get", param)
        .then(({ data }) => {
          if (data) {
            this.setHeadersComandasCompleto();
            this.items = data;
          } else {
            this.headers = [];
            this.items = [];
            this.$alertar("warning", "Relatorio sem Dados!", "=/");
          }
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possível gerar o relatorio", "=(");
        });

      this.txtBtnGerar = "GERAR";
    },
    setHeadersComandasCompleto() {
      this.headers = [
        { text: "ID", value: "idcomanda", width: "30px" },
        { text: "Hospede", value: "hospede", width: "120px" },
        { text: "UH", value: "uh", width: "30px" },
        { text: "Local", value: "local", width: "120px" },
        { text: "Abertura Comanda", value: "abertura_comanda", width: "160px" },
        { text: "Status", value: "status_comanda", width: "120px" },
        { text: "Aberta?", value: "comanda_fechada", width: "120px" },
        { text: "Integração", value: "integracao_pms", width: "120px" },
        { text: "Garçom", value: "garcom_comanda", width: "120px" },
        {
          text: "Ultima Atualização",
          value: "ultimo_usuario_atualizacao",
          width: "120px",
        },
        { text: "Hora Entregue", value: "hora_entregue", width: "120px" },
        {
          text: "Fechamento Comanda",
          value: "data_fechamento_comanda",
          width: "120px",
        },
      ];
    },
    carregarModalAssinatura(item) {
      this.itemparamodalassinatura = item;
      this.itemparamodalassinatura.numpedido = this.itemparamodalassinatura.idcomanda;

      this.setexibirModalAssinatura();
    },
    carregarModalConfirmacao(item) {
      this.dadosConfirmacao = item;
      this.setexibirModalConfirmacao();
    },
    setexibirModalConfirmacao() {
      this.exibirModalConfirmacao = !this.exibirModalConfirmacao;
    },
    setexibirModalAssinatura() {
      this.exibirModalAssinatura = !this.exibirModalAssinatura;
    },
    carregarModalItemsComanda(item) {
      if ([2,3,10,11].includes(item.idstatus)) {
        return;
      }
      console.log("carregarModalItemsComanda ", item);
      this.itemparamodalItensComanda = item;
      console.log(
        "this.itemparamodalItensComanda",
        this.itemparamodalItensComanda
      );
      this.itemparamodalItensComanda.numpedido = this.itemparamodalItensComanda.idcomanda;
      this.setexibirModalItensComanda();
    },
    carregarModalItemsComandaCompleto(item) {
      if ([2,3,10,11].includes(item.idstatus)) {
        return;
      }
      this.itemComandaCompleta = item;
      this.setexibirModalItensComandaCompleto();
    },
    setexibirModalItensComanda() {
      //this.$alertar('warning','tes')
      this.exibirModalItensComanda = !this.exibirModalItensComanda;
    },
    setexibirModalItensComandaCompleto() {
      this.exibirModalItensComandaCompleto = !this
        .exibirModalItensComandaCompleto;
    },
    carregarFiltrosComissao() {
      this.$http.post("relatorio/comissao/filtros", {}).then((res) => {
        //console.error(JSON.stringify(res.data))
        this.ehMaster = JSON.parse(localStorage.getItem("perfis"))?.includes(1);
        this.ehGarcon = JSON.parse(localStorage.getItem("perfis"))?.includes(5);

        this.competenciaList = res.data.competencialist;
        this.competencia = this.competenciaList.slice(0)[0].valor;

        if (this.ehMaster) {
          this.vendedorComissaoList = res.data.vendedorlist;
          this.vendedorComissaoList.unshift({ nome: "Todos", valor: -1 });
          this.vendedorComissao = this.vendedorComissaoList.slice(0)[0].valor;
        } else {
          if (this.ehGarcon) {
            this.vendedorComissaoList = [
              {
                nome: localStorage.getItem("nome"),
                valor: parseInt(localStorage.getItem("idusuario")),
              },
            ];
            this.vendedorComissao = parseInt(localStorage.getItem("idusuario"));
          }
        }

        this.regraComissaoList = res.data.regralist;
        this.regraComissaoList.unshift({ nome: "Todas", valor: -1 });
        this.regraComissao = this.regraComissaoList.slice(0)[0].valor;

        this.pdvComissaoList = res.data.pdvlist;
        this.pdvComissaoList.unshift({ nome: "Todos", valor: -1 });
        this.pdvComissao = this.pdvComissaoList.slice(0)[0].valor;

        this.categoriaComissaoList = res.data.categorialist;
        this.categoriaComissaoList.unshift({ nome: "Todas", valor: -1 });
        this.categoriaComissao = this.categoriaComissaoList.slice(0)[0].valor;
      });
    },
    setModalTrocarStatusDoItem(item) {
      this.itemparamodalTrocarStatusDoItem = item;
      this.setexibirModalTrocarStatusDoItem();
    },
    setexibirModalTrocarStatusDoItem(value) {
      if (value != null || value !== undefined) {
        this.exibirModalTrocarStatusDoItem = value;
      } else {
        this.exibirModalTrocarStatusDoItem = !this
          .exibirModalTrocarStatusDoItem;
      }
    },
  },
  async mounted() {
    this.$store.dispatch("set_titulo", "Relatorios");

    if (
      this.$store.getters.get_idperfil.includes(5) &&
      !this.$store.getters.get_idperfil.includes(1)
    ) {
      this.idrelatorio = 2;
      this.listarelatorios = [
        { id: 2, nome: "Relatorio de Pedidos" },
        { id: 4, nome: "Relatorio de Pedidos Analítico" },
        { id: 5, nome: "Relatorio de Comandas Abertas" },
        { id: 7, nome: "Relatorio de Remoçao de Itens Comanda" },
      ];
    } else {
      this.listarelatorios = [
        { id: 1, nome: "Relatorio de Perfomance" },
        { id: 2, nome: "Relatorio de Pedidos" },
        { id: 3, nome: "Relatorio de Comissões" },
        { id: 4, nome: "Relatorio de Pedidos Analítico" },
        { id: 5, nome: "Relatorio de Comandas Abertas" },
        { id: 7, nome: "Relatorio de Remoçao de Itens Comanda" },
        { id: 8, nome: "Relatorio de Filtro" },
        { id: 9, nome: "Relatorio de Pagamentos Passantes" },
        { id: 11, nome: "Relatorio de Ordem de Pagamentos" },
        { id: 12, nome: "Relatorio de Pedidos Por Faixa de Horário" },
      ];
    }

    if (this.idrelatorio !== 3) {
      this.$http("pdv/statuscomandas")
        .then((resp) => {
          this.listastatus[0] = {
            id: 0,
            nome: "Todos",
          };
          this.listastatus = this.listastatus.concat(resp.data);
          this.listastatus = this.listastatus.filter((x) => {
            return x.nome !== "Registrada";
          });
        })
        .catch(() => {
          this.$alertar("warning", "Não foi possivel listar os status", "=(");
        });

      this.datainicial = dayjs().format("YYYY-MM-DD");
      this.datafinal = dayjs().format("YYYY-MM-DD");

      this.carregarFiltrosComissao();
    }
  },
};
</script>

<style scoped>
.centralizadohorizontal {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: pink; */
  justify-content: center;
}

.topoform {
  /* background-color: blue; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.estiloColuna {
  /* background-color: white; */
  /* padding-top:10px; */
  width: 80% !important;
  /* margin-right:2%!important; */
  /* min-height: 100%;
        max-height: 100; */
  /* border:1px solid rgb(156, 156, 156);
        border-radius:5px; */
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid rgb(156, 156, 156);
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}

.divinput {
  width: 30%;
  margin-left: 2%;
  margin-bottom: 10px;
}

.input {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  border: 1px solid rgb(156, 156, 156);
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  /* padding-right: 20%; */
  border-radius: 10px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal {
  width: 100%;
  height: 50px;
  display: block;
  margin-bottom: 30px;
  border: 0px;
  font-weight: bold;
  color: white;
}

.botaoModalLocalAmarelo {
  background-color: rgb(239, 175, 62);
}
.botaoModalLocalVerde {
  background-color: rgb(0, 165, 51);
}
.botaoModalLocalAzul {
  background-color: rgb(63, 81, 181);
}
.botaoModalLocalVermelho {
  background-color: rgb(216, 37, 37);
}
.headerTitulo {
  font-weight: bold;
}
.numeroForm {
  height: 50px !important;
}
</style>
