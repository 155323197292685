<template>
    <transition  name="modal" style="width:100vw;"  @close="showModal = false">
            <div style="background-color:red;display:none" >
                <div id="modalImpressao" style="background-color:green;width:100vw;min-height:100vh;position:absolute;top:0;" >
                   <table class="printer-ticket" style="max-width:60mm;background-color:#fcffb4 !important;">
                        <thead  style="max-width:60mm;">
                            
                            <tr><th class="title" colspan="3">{{pedidoSelecionado.nomehotel}}</th></tr>
                            <tr><th class="title" colspan="3">Menu Digital</th></tr>
                            <tr><th colspan="3">COD: {{ pedidoSelecionado.idcomanda }}</th></tr>
                            <tr><th colspan="3">------ UH/MESA - NOME ------</th></tr>
                            <tr><td colspan="3">{{pedidoSelecionado.numerolocal}} - {{pedidoSelecionado.nomehospede}}</td></tr>
                            <tr><td colspan="3">DATA: {{pedidoSelecionado.datapedido}}</td></tr>
                            <tr><td colspan="3">QTDE:  {{pedidoSelecionado.quantidade}} PRODUTO(S)</td></tr> 
                            <tr><th colspan="3"><b>--------------  ITENS  --------------</b></th></tr> 
                            
                        </thead>
                        <tbody style="max-width:60mm;">
                            <div :key="item.idlancamentospdv" v-for="item in pedidoSelecionado.itens">
                                <tr class="top">
                                    <td colspan="3">{{item.descproduto}} {{ item.ativo == false ? '[CANCELADO]' : '' }}</td>
                                </tr>
                                <tr>
                                    <td>{{item.precounitario  | formatarMoeda}}</td>
                                    <td>{{item.quantidade }}</td>
                                    <td>{{(item.precounitario * item.quantidade ) | formatarMoeda}}</td>
                                </tr>
                                <tr v-if="item.valortaxa > 0">
                                    <td>Taxa</td>
                                    <td>{{item.valortaxa}}%</td>
                                    <td>{{((item.precounitario*(item.valortaxa/100)) * item.quantidade ) | formatarMoeda}}</td>
                                </tr>
                                <tr class="top">
                                    <td colspan="3" v-if="item.observacao">Obs:{{item.observacao}}</td>
                                </tr>

                                <tr class="top" v-for="opcional in item.opcional_escolhido" :key="opcional.descricao" >
                                    <td colspan="3">opc:{{opcional.descricao }}</td>
                                </tr>

                                

                                <tr class="top">
                                    <td colspan="3">&nbsp;</td>
                                </tr>
                            </div>

                            <tr class="top" >
                                <td colspan="3">
                                  Obs. Geral: {{pedidoSelecionado.observacaogerais }}</td>
                            </tr> 
                            
                            <tr class="top">
                                <th colspan="3" ><b>-------------------------------------</b></th> 
                            </tr>

                            <tr 
                              v-show="
                                pedidoSelecionado.desconto && 
                                pedidoSelecionado.desconto.valor > 0
                              " 
                              style="height: 20px; margin-bottom: 50px">
                                <td><b>Desconto</b></td>
                                <td></td>
                                <td
                                  v-if="pedidoSelecionado.desconto && pedidoSelecionado.desconto.tipo == 'valor'"
                                >
                                  {{pedidoSelecionado.desconto.valor && pedidoSelecionado.desconto.valor | formatarMoeda}}
                                </td> 
                                <td
                                  v-if="pedidoSelecionado.desconto && pedidoSelecionado.desconto.tipo == 'percentual'"
                                >
                                  {{pedidoSelecionado.desconto.valor}}%
                                </td>
                            </tr>

                            
                            <tr style="height: 20px;">
                                <td><b>Total</b></td>
                                <td></td>
                                <td>{{pedidoSelecionado.precototal | formatarMoeda}}</td>
                            </tr>

                            
                            
                        </tbody>
                        <br>
                        <br>
                        <tfoot style="max-width:30px;">
                            
                            <tr class="sup ttu p--0">
                                <td colspan="3">
                                    <img style="width:80%" v-if="pedidoSelecionado.imgassinaturasrc" v-bind:src="'data:image/jpeg;base64,'+pedidoSelecionado.imgassinaturasrc" />
                                    <br>
                                    <b>__________________________</b>
                                </td>
                            </tr>
                            <tr class="sup ttu p--0">
                                <td colspan="3">
                                    <b>{{pedidoSelecionado.nomehospede}}</b>
                                </td>
                            </tr>
                            
                        </tfoot>
                    </table> 
                    
                </div>

            </div>
          
      </transition>
</template>

<script>

  export default {
    name: 'ModalImpressaoPedido',
    props:["showModal","pedidoSelecionado"],
    data() {
      return {
      
      };
    },
    async mounted(){
      
       
    },
    methods:{
        direcionar(){
            this.$router.push(this.url)
        },
        nao(){
          this.$emit('nao')
        },
        sim(){
          this.$emit('sim')
        },
        
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}
</style>

