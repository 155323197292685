<template>
    <v-dialog v-model="exibirModalTutorialResposta" width="90%" height="100vh" max-width="40vw">
        <v-card style="max-width: 100%;height: 90vh;">
            <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;">
                        FAQ
                    </span>
                    <span style="width:5%">
                        <v-btn x-small color="red" @click="$emit('setModalTutorialResposta',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <v-text style="max-height: 50px;">
                <div style="width:100%;max-height:80%;overflow:auto;">
                    <div style="padding:30px;color:#494949;font-weight: 300; text-align: justify;">
                        <VueEditor v-model="resposta" disabled="true" :editorOptions="editorOptions"></VueEditor>
                        <!-- {{ resposta }} -->
                    </div>
                    <div style="width:100%;display:flex;justify-content: flex-end;align-items:flex-end;padding-right: 20px;">
                        <p style="color:#494949;font-weight: 100; font-size: medium; margin-bottom: 4px;">
                            Essa resposta te ajudou?
                        </p>
                        <v-btn icon style="margin-right: 0" :color="curtidasfaq?'green':'gray'" @click="setCurtirDescurtir(true)">
                            <v-icon color="gray">
                                mdi-thumb-up
                            </v-icon>
                        </v-btn>
                        <v-btn icon style="margin-right: 0" :color="curtidasfaq===false?'red':'gray'" @click="setCurtirDescurtir(false)">
                            <v-icon color="gray">
                                mdi-thumb-down
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-text>
        </v-card>
    </v-dialog>
</template>
<script>

import { VueEditor } from "vue2-editor"

export default {
    name: "ModalTutorialResposta",
    data: () => ( {
        faq: [],
        editorOptions: {
            modules: {
                toolbar: false,
            },
        }
    }),
    props:["exibirModalTutorialResposta", "resposta", "curtidasfaq", "idfaq"],
    methods: {
        setCurtirDescurtir(fl_curtirDescurtir) {
        
            this.curtidasfaq = fl_curtirDescurtir

            let baseURL = this.$dados.BUILD_APIES_URL

            this.$http.post(baseURL+"/faq/inserirAtualizarCurtida", 
            {id_faq: this.idfaq,idusuario: localStorage.getItem('idusuario'),fl_curtida: this.curtidasfaq,idcliente: this.$dados.BUILD_ID_CLIENTE},
            {headers:{'x-access-token':this.$dados.BUILD_TOKEN_APIES}})
                .then((response) => {
                    this.faq = response.data
                })
                .catch((error) => {
                    alert(error)
                    console.log(error);
                });
        },
    },
    mounted() {
        
    },
    components: {
        VueEditor
    }
}
</script>
