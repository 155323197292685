<template>
<div class="estiloColuna">

    <FluxoPedido v-if="telaatual === 'fluxo'" @salvar="(parametros) => salvar(parametros)" />
    <RoomService v-if="telaatual === 'roomservice'" @salvar="(parametros) => salvar(parametros)" />
    <Assinatura v-if="telaatual === 'assinatura'" @salvar="(parametros) => salvar(parametros)" />
    <Impressoras v-if="telaatual === 'impressoras'" @salvar="(parametros) => salvar(parametros)" />
    <Maquininhas v-if="telaatual === 'maquininhas'" @salvar="(parametros) => salvar(parametros)" />
    <Publicidade v-if="telaatual === 'publicidade'" @salvar="(parametros) => salvar(parametros)" />
    <Importacao v-if="telaatual === 'importacao'" @salvar="(parametros) => salvar(parametros)" />
    <Mesa v-if="telaatual === 'mesa'" @salvar="(parametros) => salvar(parametros)" />
    <GargonsXMesa v-if="telaatual === 'garconsxmesas'" @salvar="(parametros) => salvar(parametros)" />
    <Cumim v-if="telaatual === 'cumim'" @salvar="(parametros) => salvar(parametros)" />
    <AllInclusive v-if="telaatual === 'allinclusive'" @salvar="(parametros) => salvar(parametros)" />
    <PracaXPDV v-if="telaatual === 'pracaxpdv'" @salvar="(parametros) => salvar(parametros)" />
    <PracaXPerfil v-if="telaatual === 'pracaxperfil'" @salvar="(parametros) => salvar(parametros)"/>
    <VerificarEmailHospedeFake v-if="telaatual === 'emailhospedefake'" @salvar="(parametros) => salvar(parametros)"/>
    <ConfiguracaoHits v-if="telaatual === 'configuracaohits'" @salvar="(parametros) => salvar(parametros)"/>
    <PagamentosConf v-if="telaatual === 'pagamentosconf'" @salvar="(parametros) => salvar(parametros)"/>
    <Gateways v-if="telaatual === 'gateways'" @salvar="(parametros) => salvar(parametros)"/>
    <TipoHospedeXPDV v-if="telaatual === 'tipohospedexpdv'" @salvar="(parametros) => salvar(parametros)"/>
    <Email v-if="telaatual === 'email'" @salvar="(parametros) => salvar(parametros)"/>
    <Comanda v-if="telaatual === 'configrobo'" @salvar="(parametros) => salvar(parametros)"/>
    <GeradorQRCode v-if="telaatual === 'gerardorqrcode'" @salvar="(parametros) => salvar(parametros)"/>
    <HospedeVizualizacao v-if="telaatual === 'vizualizacaohospede'" @salvar="(parametros) => salvar(parametros)"/>
    <Locais v-if="telaatual === 'Locais'" @salvar="(parametros) => salvar(parametros)"/>
      
    <!-- <FluxoPedido v-if="telaatual == 'fluxo'" @salvar="(parametros) => salvar(parametros)" />
    <FluxoPedido v-if="telaatual == 'fluxo'" @salvar="(parametros) => salvar(parametros)" /> -->

</div>
</template>

<script>

  import FluxoPedido from '../configuracoes/FluxoPedido.vue'
  import RoomService from '../configuracoes/RoomService.vue'
  import Assinatura from '../configuracoes/Assinatura.vue'
  import Impressoras from '../configuracoes/Impressoras.vue'
  import Maquininhas from '../configuracoes/Maquininhas.vue'
  import Publicidade from '../configuracoes/Publicidade.vue'
  import Importacao from '../configuracoes/Importacao.vue'
  import Mesa from '../configuracoes/Mesa.vue'
  import GargonsXMesa from '../configuracoes/GargonsXMesa.vue'
  import Cumim from '../configuracoes/Cumim.vue'
  import AllInclusive from '../configuracoes/AllInclusive.vue'
  import PracaXPDV from '../configuracoes/PracaXPDV.vue'
  import PracaXPerfil from '../configuracoes/PracaXPerfil.vue'
  import VerificarEmailHospedeFake from '../configuracoes/VerificarEmailHospedeFake.vue'
  import ConfiguracaoHits from '../configuracoes/ConfiguracaoHits.vue'
  import PagamentosConf from '../configuracoes/PagamentosConf.vue'
  import Gateways from '../configuracoes/Gateways.vue'
  import TipoHospedeXPDV from '../configuracoes/TipoHospedeXPDV.vue'
  import Email from '../configuracoes/Email.vue'
  import Comanda from '../configuracoes/Comanda.vue'
  import GeradorQRCode from "../configuracoes/GeradorQRCode.vue";
  import HospedeVizualizacao from '../configuracoes/HospedeVizualizacao/HospedeVizualizacao.vue';
  import Locais from '../configuracoes/Locais.vue'
  export default {
    name: 'ColunaDireita',
    components: {
      FluxoPedido,
      RoomService,
      Assinatura,
      Impressoras,
      Maquininhas,
      Publicidade,
      Importacao,
      Mesa,
      GargonsXMesa,
      Cumim,
      AllInclusive,
      PracaXPDV,
      PracaXPerfil,
      VerificarEmailHospedeFake,
      ConfiguracaoHits,
      PagamentosConf,
      Gateways,
      TipoHospedeXPDV,
      Email,
      Comanda,
      GeradorQRCode,
      HospedeVizualizacao,
      Locais
    },
    props:["telaatual"],
    async mounted(){

    },
    watch:{

    },
    data: () => ({

    }),
    methods:{
        salvar(parametros){

            this.$emit('salvar',parametros)

        }
    }
  }
</script>

<style scoped>
    .estiloColuna{
        /* background-color: white; */
        /* padding-top:10px; */
        width: 70%!important;
        margin-right:2%!important;
        min-height: 100%;
        max-height: 100;
        /* border:1px solid rgb(156, 156, 156);
        border-radius:5px; */

    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      /* border-top-right-radius: 5px;
      border-top-left-radius: 5px; */
      /* background-color:#1976D2; */
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-left:2px;
      padding-right:20px;
    }
</style>
