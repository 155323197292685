<template>
  <div class="" >
    <div class="bodyFlex" >
      <v-row justify="center">
        <v-card width="95%" >
          <!-- <BotaoTutorial style="max-width: 5%;width: 5%; display: flex;" categoria="14" legenda="" urlVideo="https://www.youtube.com/embed/6UkdXYkjwJU" /> -->
          <v-card-text>

              <v-row style="display: flex-line" class="pa-2" data-v-step-hospedes="1">

                  <v-text-field append-icon="mdi-account" v-model="nome" data-cy="nome" label="Nome" class="mr-1 ml-1" style="width: 150px"
                    >
                  </v-text-field>

                  <v-text-field append-icon="mdi-account" v-model="email" data-cy="email" label="email" class="mr-1 ml-1" style="width: 150px"
                    >
                  </v-text-field>           

                  <v-text-field v-model="coduh" data-cy="coduh" append-icon="mdi-door" label="UH" class="mr-1 ml-1" style="width: 100px"
                    >
                  </v-text-field>
                  
                  <v-text-field v-model="datacheckin" data-cy="datacheckin" type="date" append-icon="mdi-clock"
                    label="Check-IN" placeholder="dd/mm/aaaa" class="mr-1 ml-1"
                    >
                  </v-text-field>

                  <v-text-field v-model="numreserva" data-cy="numreserva" append-icon="mdi-numeric" placeholder="Loc Reserva" label="Reserva" class="mr-1 ml-1"
                    style="width: 100px" >
                  </v-text-field>

                  <v-select v-model="nacasa" :items="itemsnacasa" label="Na Casa" item-text="nome" item-value="id" return-object class="mr-1 ml-1" ></v-select>

                  <v-select v-model="tipohospede" :items="tiposhospede" label="Tipo" item-text="nome" item-value="id" return-object class="mr-1 ml-1" ></v-select>
                  

              </v-row>

              <v-row style="display: flex-line;margin-bottom:30px;" class="mr-1 ml-1 mt-1 mb-2">

                  <v-btn data-v-step-hospedes="2" class="mr-2" v-if="(($store.getters.get_idperfil == 5 && garcomcadastrapassante == true) || ($store.getters.get_idperfil != 5)) "  @click="modalCheckinFake = true" data-cy="btn-criarhospedefake" title="Buscar" color="info" >Criar Hospede Passsante</v-btn >
                  <v-spacer></v-spacer>              
                  <v-btn
                      class="mr-2"
                      style="width:200px;margin-right:20px;"
                      data-cy="btn-buscar"
                      title="Buscar"
                      color="info"
                      :disabled="botaoBuscandoHospede"
                  >
                      {{ botaoBuscandoHospede ? "Buscando..." : "Buscar" }}
                  </v-btn >
                  <v-btn
                      class="mr-2"
                      style="width:200px"
                      data-cy="btn-limpar"
                      title="Limpar"
                      color="info"
                      :disabled="botaoBuscandoHospede"
                  >
                    Limpar
                  </v-btn >

              </v-row> 

              <v-row style="display: flex-line;justify-content:flex-end;padding-right:8px;" class="mr-1 ml-1 mt-1 mb-2">

                  <v-btn elevation="" color="default" style="margin-right:10px;width:200px;" :disabled="botaoBuscandoHospede">Voltar</v-btn>
                  <v-btn data-v-step-hospedes="4" elevation="" color="default" style="width:200px" :disabled="botaoBuscandoHospede">Avançar</v-btn>

              </v-row> 

              <div style="min-height: 400px;">

                      <v-expansion-panels inset v-model="hospedesSelecionados">

                            <v-expansion-panel v-for="(hospede, index) in hospedes" :key="hospede.idcheckin"  :id="'hospede-selecao'+index" >

                                    <v-expansion-panel-header data-cy="hospede-painel" @click="senhavisivel = null" >
                                          <span v-if="tipointegracao!='HITS'" data-cy="hospede-nome">{{ hospede.nome + `(${hospede.passante?'Passante':'Hospede'})` }}</span>
                                          <span v-else data-cy="hospede-nome">{{ hospede.nome +` (${hospede.passante?'Passante':(hospede.id_tipo_hospede_hits == 2? 'Day Use': (hospede.id_tipo_hospede_hits == 3?'Eventos':'Hospede') )}) ` }}</span>
                                          , UH: {{ hospede.coduh }} | Res Nº:{{ hospede.numreserva }}
                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content :data-v-step-hospedes="'2'+index">

                                            <v-row justify="start">
                                                Reserva: {{ hospede.numreserva }}
                                            </v-row>

                                            <v-row justify="start">
                                              IN: {{ hospede.datacheckin | formatarData }}
                                            </v-row>

                                            <v-row justify="start">
                                              OUT: {{ hospede.datacheckoutprev | formatarData }}
                                            </v-row>

                                            <v-row justify="start">
                                                Email: {{hospede.email}}
                                            </v-row>

                                            <v-row justify="start">
                                                <span v-if="senhavisivel === hospede.senha+hospede.numreserva">
                                                  <button type="button" @click="senhaVisivel(hospede.senha+hospede.numreserva)">
                                                    <v-icon>mdi-eye-off-outline</v-icon>
                                                  </button>
                                                  Senha: 
                                                  <input style="width: fit-content" type="text"  disabled :value="hospede.senha">
                                                </span>
                                                <span v-else>
                                                  <button type="button" @click="senhaVisivel(hospede.senha+hospede.numreserva)">
                                                    <v-icon>mdi-eye-outline</v-icon>
                                                  </button>
                                                  Senha: 
                                                  <input style="width: fit-content" type="password" v-if="senhavisivel !== hospede.senha+hospede.numreserva" disabled :value="hospede.senha">
                                                  <br/>
                                                  <a data-cy="btn-gerarsenha" @click="gerarSenha(hospede.idcheckin, index)">Gerar senha automaticamente</a>
                                                </span>
                                            </v-row>

                                            <v-row justify="start" class="pb-1 pt-1" style="white-space: nowrap;">
                                          
                                                  <v-btn small class="mr-2 ml-2" title="Editar endereço de email do hóspede" 
                                                     data-cy="btn-editar" >
                                                      Editar
                                                    <v-icon small dark class="ma-1">mdi-pencil </v-icon>
                                                  </v-btn>
                                          
                                                  <v-btn small class="mr-2 ml-2" title="Enviar email com dados de acesso, senha e etc." data-cy="btn-enviaremail">
                                                    Enviar
                                                    <v-icon small dark class="ma-1">mdi-email-send </v-icon>
                                                  </v-btn>

                                                  <v-btn small class="mr-2 ml-2" data-cy="btn-fazerpedido" data-v-step-hospedes="3">
                                                    Fazer Pedido
                                                    <v-icon small dark class="ma-1">mdi-order-bool-ascending-variant</v-icon>
                                                  </v-btn>

                                            </v-row>

                                    </v-expansion-panel-content>

                            </v-expansion-panel>

                      </v-expansion-panels>

              </div>

          </v-card-text>
        </v-card>
      </v-row>
    </div>

    <v-tour name="hospedes" :steps="steps" :callbacks="callbacksTuor"></v-tour>
  </div>
</template>

<script>
export default {
  name: "ViewHospedes",
  data: () => ({
    garcomcadastrapassante:false,
    idcheckin : 0,
    email : '',
    coduh: "",
    nome: "",
    datacheckin: "",
    numreserva: "",
    hospedes: [],
    campo: "",
    exibeModalEditarEmail : false,
    senhavisivel: null,
    modalCheckinFake: false,
    tipointegracao:null,
    pagina:1,
    nacasa:{ id:2, nome: 'SIM' },
    itemsnacasa:[
       { id:1, nome: 'TODOS' },
       { id:2, nome: 'SIM' },
       { id:3, nome: 'NÃO' },
    ],
    tipohospede:{ id:-1, nome: 'TODOS' },
    tiposhospede:[
      { id:-2, nome: 'TODOS' },
      { id:-1, nome: 'PASSANTE' },
    ],
    botaoBuscandoHospede: false,
    hospedesSelecionados: [],

    steps: [
      {
        target: '[data-v-step-hospedes="1"]',
        content: 'Aqui estão alguns filtros para buscar o hóspede rapidamente',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-hospedes="2"]',
        content: 'Nesta opção é possível criar passantes dentro do cardápio digital <br /> <br />  OBS: O PMS não possui conhecimento desse passante até o momento da integracação',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-hospedes="3"]',
        content: 'Clicando aqui é possivel realizar um pedido para um hóspede',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-hospedes="4"]',
        content: 'Nesta tela aparecem apenas 10 hóspedes por vez, então é preciso paginar para ver os outros, se não optar buscar o hóspede pelo nome/uh/reserva/email',
        params: {
          placement: 'bottom'
        }
      },
    ],
    callbacksTuor: {
      onFinish: () => {
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, hospedes: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))
        location.replace('/Adm/Hospedes');
      },
      onStop: () => {
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, hospedes: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))
        location.replace('/Adm/Hospedes');
      },
      onSkip: () => {
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, hospedes: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))
        location.replace('/Adm/Hospedes');
      },
    }
  }),
  methods: {
    gerarSenha(idcheckin, indexhospede){
        this.hospedes[indexhospede].senha = Math.floor(1000 + Math.random() * 9000);
        this.$alertar('success', 'Senha gerada com sucesso', '=)')
    },
    senhaVisivel(arg){
      if(this.senhavisivel === arg){
        this.senhavisivel = null
      }else{
        this.senhavisivel = arg
      }      
    },  
    async listarHospedesNaCasa() {
     this.hospedes = [
        {
          "idcheckin": 513,
          "idhotel": 1,
          "numreserva": 2587,
          "idreservasfront": 2587,
          "coduh": "504",
          "nome": "Hospede Teste",
          "email": "hospede@email.com",
          "datacheckin": "2023-12-12T03:00:00.000Z",
          "datacheckoutprev": "2023-12-15T03:00:00.000Z",
          "telefone": null,
          "sobrenome": "",
          "senha": "69052",
          "idioma": "pt-BR",
          "idpessoa": 1044,
          "avatar": null,
          "idhospede": 7234,
          "globalcodehits": "2587-69052",
          "cartaoconsumo": "123456789",
          "tipopensao": null,
          "linkenviado": false,
          "flnacasa": true,
          "passante": false,
          "id_tipo_hospede_hits": 0,
          "ativo_temp": true
        },
        {
          "idcheckin": 504,
          "idhotel": 1,
          "numreserva": 2585,
          "idreservasfront": 2585,
          "coduh": "603",
          "nome": "Hospede 2 Teste",
          "email": "",
          "datacheckin": "2023-12-06T03:00:00.000Z",
          "datacheckoutprev": "2023-12-20T03:00:00.000Z",
          "telefone": null,
          "sobrenome": "",
          "senha": "78108",
          "idioma": "pt-BR",
          "idpessoa": 1035,
          "avatar": null,
          "idhospede": 7233,
          "globalcodehits": "2585-78108",
          "cartaoconsumo": null,
          "tipopensao": null,
          "linkenviado": false,
          "flnacasa": true,
          "passante": false,
          "id_tipo_hospede_hits": 0,
          "ativo_temp": true
        },
      ]
    },
    limpar() {
      this.coduh = ""
      this.nome = ""
      this.email = ""
      this.datacheckin = ""
      this.numreserva = ""
      this.listarHospedesNaCasa();
    },
    limparCampos() {
      this.coduh = ""
      this.nome = ""
      this.email = ""
      this.datacheckin = ""
      this.numreserva = ""
    },
  },
  async mounted() {
    this.$store.dispatch("set_titulo", "Hospedes Exemplo");

    this.tipointegracao = localStorage.getItem('tipo_integracao')

    if(this.tipointegracao == 'HITS'){
        this.tiposhospede.push({ id:0, nome: 'HOSPEDE' })
        this.tiposhospede.push({ id:2, nome: 'DAY USE' })
        this.tiposhospede.push( { id:3, nome: 'EVENTOS' })
        this.tipohospede = {id:0, nome: 'HOSPEDE'  }
    }

    if(this.tipointegracao === 'ADMH') {
      const tiposHospedes = [
        {id: -2, nome: "TODOS"},
        {id: 0, nome: "HOSPEDE"},
        {id: 2, nome: "AVULSAS"},
        {id: -1, nome: "MESAS"}
      ]
      this.tiposhospede = tiposHospedes
    }

    this.garcomcadastrapassante = true

    await this.listarHospedesNaCasa();

    const element = document.getElementById('hospede-selecao0')
    element.children[0].click()

    this.$tours['hospedes'].start()
  },
};
</script>


<style scoped>
</style>
