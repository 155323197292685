<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <div class="categoryList">
      <Produto v-for="produto in listadeprodutos" :key="produto.idcategoriaprod+produto.idproduto" 
        :produto="produto" :idcategoriaprodcd="idcategoriaprodcd" :idsubcategoriaprodcd="idsubcategoriaprodcd"
        :idpdv="idpdv"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import Produto from './Produto.vue'
  export default {
    name: 'ProdutoLista',
    props:['idcategoriaprodcd','idsubcategoriaprodcd','idpdv','listadeprodutos'],
    components:{
      // TitleH1,
      Produto
    },
    data: () => ({
      // listadeprodutos:[]
    }),

    methods:{

      // getProdutos(){
      //     this.$http('pdv/getProdutos/'+this.idcategoriaprodcd+'/'+this.idsubcategoriaprodcd).then((resp) =>{
      //       this.listadeprodutos = resp.data
      //     }).catch(error =>{
      //       console.log(error)
      //       this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
      //     })
      // },

    },

    mounted(){
      // this.getProdutos()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
    }
</style>
