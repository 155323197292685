<template>
  <div>
    <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" v-if="$route.params.idsubcategoriaprodcd > 0" />
    <BotaoVoltar :url="'/Hospede/Categorias/'+$route.params.idpdv" v-else />
    <Subtitulo :subtitulo="subtitulo" />
    <ProdutoLista 
      :listadeprodutos="listadeprodutos"
      :idcategoriaprodcd="$route.params.idcategoriaprodcd" 
      :idsubcategoriaprodcd="$route.params.idsubcategoriaprodcd"
      :idpdv="$route.params.idpdv" />
  </div>
</template>

<script>
  import ProdutoLista from '../../components/produtos/ProdutoLista.vue'
  import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'ViewProdutos',
    data: () => ({
      subtitulo:"",
      listadeprodutos:[]
    }),
    watch:{
      listadeprodutos(val){
        this.listadeprodutos = val
      }
    },
    components: {
      ProdutoLista,
      BotaoVoltar,
      Subtitulo,
    },
    methods:{
      getProdutos(){
            this.$http('pdv/getProdutos/'+this.$route.params.idcategoriaprodcd+'/'+this.$route.params.idsubcategoriaprodcd+'?tipousuario=hospede').then((resp) =>{
              this.listadeprodutos = resp.data
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
            })
        },
    },
    mounted(){
        this.$http('pdv/getNomeSubCategoria/'+this.$route.params.idsubcategoriaprodcd).then((resp) =>{
          this.subtitulo = resp.data.nomecategoriaprodcd
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi pegar o nome da SubCategoria","=(")
        })
        this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
        this.getProdutos()
    }
  }
</script>
