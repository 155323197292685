<template>
    <div class="bodygenerico">
      <div class="bodyFlex">
          <ColunaListaPedidos
            :ojbPedidos="ojbPedidos"
            :buscainicial="query"
            @setarPedido="(pedido) => setarPedido(pedido)"
            urlVideo="https://drive.google.com/file/d/157huWtvjdUkcQsuWsY-WfJaSoCbhmkY1/preview"
            :tela="'GARCOM'"
          />
          <ColunaPedido v-if="pedido" :pedido="pedido"  @imprimirComanda="(pedido) => imprimirComanda(pedido)"
            :exibirnao="true"  @nao="(pedido) =>cancelarPedido(pedido)" @sim="(pedido) => marcarComoPronto(pedido)"
            @getProdutosdoAposLiberacaoPorItem="(numPedido) =>getProdutosdoAposLiberacaoPorItem(numPedido)"
            @marcarItemComoEntregue="(objeto) =>marcarItemComoEntregue(objeto)"
            :tela="'COZINHA'"
            textonao="Cancelar" textosim="Marcar Como Pronto" perfil="COZINHA" categoria="14" legenda="Dúvidas Cozinha"  urlVideo="https://drive.google.com/file/d/157huWtvjdUkcQsuWsY-WfJaSoCbhmkY1/preview"
          />
      </div>
      <v-tour name="etapas" :steps="steps" :callbacks="callbacksTuor"></v-tour>
    </div>
</template>

<script>
import ColunaListaPedidos from '../../components/pedidos/ColunaListaPedidos.vue'
import ColunaPedido from '../../components/pedidos/ColunaPedido.vue'


const dayjs = require('dayjs')

export default {
  name: 'ExemploADM',
  components:{
    ColunaListaPedidos,
    ColunaPedido,
  },
  data: () => ({
    exibirTaxa:false,
    idstatuspadraotela:"10",
    ojbPedidos:null,
    pedido:null,
    showModal:true,
    pedidoSelecionado:{
            nomehotel:localStorage.getItem('nomehotel'),
            idcomanda:0,
            nomehospede:'',
            numerolocal:'',
            datapedido:dayjs().format(),
            quantidade:0,
            precototal:0,
            itens:[{
                    nomeproduto:'',
                    valorunitario:0.00,
                    qtde:0
                }]
        },
    query: '',


    steps: [
      {
        target: '[data-v-step-etapas="1"]',
        content: 'Aqui você consegue buscar comandas, nome do hóspede, número do quarto ou número da comanda.',
        params: {
          placement: 'top'
        }
      },
      {
        target: '[data-v-step-etapas="2"]',
        content: 'Basta clicar no pedido, para obter mais informações sobre a comanda.',
        params: {
          placement: 'right'
        }
      },
      {
        target: '[data-v-step-etapas="3"]',
        content: 'Aqui você consegue conferir alguas informações importantes sobre o pedido.',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-etapas="4"]',
        content: 'Também é possível fazer a transferência de titularidade da comanda.',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-etapas="5"]',
        content: 'Clicando aqui você consegue imprimir a comanda.',
        params: {
          placement: 'bottom'
        }
      },
      {
        target: '[data-v-step-etapas="6"]',
        content: 'Este é um contador de tempo, vizualizado apenas nessa página, que mostra um tempo médio de preparo desse pedido',
        params: {
          placement: 'top'
        }
      },
      {
        target: '[data-v-step-etapas="7"]',
        content: 'Aqui é possivel vizualizar algumas informacoes do pedido, tais como opcionais escolhidos, observações e etc.',
        params: {
          placement: 'left'
        }
      },
      {
        target: '[data-v-step-etapas="8"]',
        content: 'Clicando aqui é possivel fazer a transferência do item para outra comanda',
        params: {
          placement: 'right'
        }
      },
      {
        target: '[data-v-step-etapas="9"]',
        content: 'É possível também remover um item da comanda, lembre-se que é obrigatório inserir um motivo',
        params: {
          placement: 'right'
        }
      },
      {
        target: '[data-v-step-etapas="10"]',
        content: 'Se clicar aqui, é possivel fazer a liberação apenas do item selecionado',
        params: {
          placement: 'right'
        }
      },
      {
        target: '[data-v-step-etapas="11"]',
        content: 'Nesta opção todos os itens são liberado de uma vez só.',
        params: {
          placement: 'top'
        }
      }
    ],
    callbacksTuor: {
      onFinish: () => {
        const perfisLogado = JSON.parse(localStorage.getItem("perfis"));
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, etapas: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))

        //usario caixa
        if(perfisLogado.includes(1) || perfisLogado.includes(2)){
          location.replace('/Adm/Caixa');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Caixa");
        }

        //usuario COZINHA
        if(perfisLogado.includes(3)){
          location.replace('/Adm/Cozinha');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Cozinha");
        }

        //usuario BAR1
        if(perfisLogado.includes(4)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario BAR2
        if(perfisLogado.includes(6)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario GARCOM
        if(perfisLogado.includes(5)){
          location.replace('/Adm/Garcom');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Garcom");
        }
      },
      onStop: () => {
        const perfisLogado = JSON.parse(localStorage.getItem("perfis"));
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, etapas: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))

        //usario caixa
        if(perfisLogado.includes(1) || perfisLogado.includes(2)){
          location.replace('/Adm/Caixa');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Caixa");
        }

        //usuario COZINHA
        if(perfisLogado.includes(3)){
          location.replace('/Adm/Cozinha');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Cozinha");
        }

        //usuario BAR1
        if(perfisLogado.includes(4)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario BAR2
        if(perfisLogado.includes(6)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario GARCOM
        if(perfisLogado.includes(5)){
          location.replace('/Adm/Garcom');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Garcom");
        }
      },
      onSkip: () => {
        const perfisLogado = JSON.parse(localStorage.getItem("perfis"));
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = { ...tuorLocalStorage, etapas: true }
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))

        //usario caixa
        if(perfisLogado.includes(1) || perfisLogado.includes(2)){
          location.replace('/Adm/Caixa');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Caixa");
        }

        //usuario COZINHA
        if(perfisLogado.includes(3)){
          location.replace('/Adm/Cozinha');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Cozinha");
        }

        //usuario BAR1
        if(perfisLogado.includes(4)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario BAR2
        if(perfisLogado.includes(6)){
          location.replace('/Adm/Bar');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Bar");
        }

        //usuario GARCOM
        if(perfisLogado.includes(5)){
          location.replace('/Adm/Garcom');
          // location.href = 'https://tupi.dev';
          // this.$router.push("/Adm/Garcom");
        }
      },
    }
  }),
  methods:{
    listarPedidosIniciais() {
      this.ojbPedidos = this.getComandasReservaPorStatus()

      this.ojbPedidos.listadepedidos[0].atrasado = true
      this.exibirTaxa = true

      this.pedido = this.getProdutosdoPedido()

      this.pedido.tempocategoria = this.ojbPedidos.listadepedidos[0].tempocategoria
      this.pedido.temposubcategoria = this.ojbPedidos.listadepedidos[0].temposubcategoria

      this.pedidoSelecionado = {
        nomehotel: localStorage.getItem('nomehotel'),
        idcomanda: this.pedido.numpedido,
        nomehospede: this.pedido.nome,
        numerolocal: this.pedido.numerolocal,
        datapedido: dayjs(this.pedido.datafeito).format('DD/MM/YYYY HH: mm'),
        quantidade: this.pedido.listadepedidos.length,
        precototal: this.pedido.valorTotal,
        itens: this.pedido.listadepedidos,
        imgassinaturasrc: null
      }
    },
    getComandasReservaPorStatus() {
      const mockReturnAPI = [
        {
          "numpedido": 3,
          "coduh": "504",
          "numerolocal": "12",
          "datahoraabertura": "2023-12-14T14:14:41.000Z",
          "exibirtaxa": "true",
          "valorsemtaxa": "52.50",
          "valor": 57.75,
          "idstatuscomanda": 9,
          "statuscomanda": "Pronto",
          "temposubcategoria": null,
          "tempocategoria": null,
          "hospede": [
            {
              "idcheckin": 513,
              "idhotel": 1,
              "numreserva": 2587,
              "idreservasfront": 2587,
              "coduh": "504",
              "nome": "Hospede Teste",
              "email": "herlessi@email.com",
              "datacheckin": "2023-12-12T00:00:00",
              "datacheckoutprev": "2023-12-15T00:00:00",
              "telefone": null,
              "sobrenome": "",
              "senha": "69052",
              "idioma": "pt-BR",
              "idpessoa": 1044,
              "avatar": null,
              "idhospede": 7234,
              "globalcodehits": "2587-69052",
              "cartaoconsumo": "1234566",
              "tipopensao": null,
              "linkenviado": false,
              "flnacasa": true,
              "passante": false,
              "id_tipo_hospede_hits": 0,
              "ativo_temp": true
            }
          ],
          "nomepdv": "Restaurante",
          "passante": false,
          "id_tipo_hospede_hits": 0,
          "atrasado": true
        },
        {
          "numpedido": 5,
          "coduh": "PS1",
          "numerolocal": "12",
          "datahoraabertura": "2023-12-14T14:14:41.000Z",
          "exibirtaxa": "true",
          "valorsemtaxa": "52.50",
          "valor": 57.75,
          "idstatuscomanda": 9,
          "statuscomanda": "Pronto",
          "temposubcategoria": null,
          "tempocategoria": null,
          "hospede": [
            {
              "idcheckin": 513,
              "idhotel": 1,
              "numreserva": 2587,
              "idreservasfront": 2587,
              "coduh": "504",
              "nome": "Passante Teste",
              "email": "herlessi@email.com",
              "datacheckin": "2023-12-12T00:00:00",
              "datacheckoutprev": "2023-12-15T00:00:00",
              "telefone": null,
              "sobrenome": "",
              "senha": "69052",
              "idioma": "pt-BR",
              "idpessoa": 1044,
              "avatar": null,
              "idhospede": 7234,
              "globalcodehits": "2587-69052",
              "cartaoconsumo": "1234566",
              "tipopensao": null,
              "linkenviado": false,
              "flnacasa": true,
              "passante": true,
              "id_tipo_hospede_hits": 0,
              "ativo_temp": true
            }
          ],
          "nomepdv": "Restaurante",
          "passante": true,
          "id_tipo_hospede_hits": 0,
          "atrasado": true
        }
      ]
      const objetoRetorno = {
        valorTotal: 57.75 * mockReturnAPI.length,
        listadepedidos: mockReturnAPI,
      }
      this.exibirTaxa = true;

      return objetoRetorno
    },
    getProdutosdoPedido(){
      const mockReturnAPI = [
        {
          "idcomanda": 385,
          "idfoliohits": null,
          "idfoliotohits": null,
          "idreservasfront": 2587,
          "idstatuscomanda": 9,
          "numreserva": 2587,
          "coduh": "504",
          "nome": "Hospede Teste",
          "sobrenome": "",
          "opcional_escolhido": [
            {
              "descricao": "Maionese"
            }
          ],
          "idtipolocal": 4,
          "numerolocal": "12",
          "numero_cadeira": null,
          "marcado_como_entregue": false,
          "meioprato": false,
          "dataassinatura": null,
          "datacancelamento": null,
          "datahoraabertura": new Date(),
          "fl_comanda_fechada": false,
          "datarecebidocozinha": new Date(),
          "dataenviocozinha": new Date(),
          "datarecebidogarcom": new Date(),
          "dataenviogarcom": null,
          "datarecebidobar": null,
          "dataenviobar": null,
          "dataentregue": null,
          "imgassinatura": null,
          "descstatuscomanda": "Pronto",
          "idcomandaproduto": 824,
          "observacao": null,
          "precounitario": "0",
          "quantidade": 1,
          "valortaxa": 0,
          "exibirtaxa": "true",
          "valorsemtaxa": "0",
          "valortotal": 0,
          "idstatuscomandaproduto": 9,
          "flbebidaantes": null,
          "idproduto": 24,
          "descproduto": "Filet Com Fritas",
          "nomecd": "Filet Com Fritas",
          "codgrupoprodpms": 1,
          "codprodutopms": "58",
          "idcategoriaprod": 3,
          "idtipocategoriaprod": null,
          "precoimportado": 40,
          "descricao": "Fritas bla bla bla bla bla bla bla bla bla bla bla bla bla bla",
          "idgarcominicial": 489,
          "nomegarcominicial": "Marcio Ueda",
          "idgarcomatual": 489,
          "nomegarcom": "Marcio Ueda",
          "flbebida": false,
          "nomepdv": "Restaurante",
          "passante": false,
          "id_tipo_hospede_hits": 0,
          "nomehotel": "Equipotel",
          "ipimpressora": "tcp://10.125.121.105.5",
          "nomedepartamento": "Restaurante",
          "ativo": true,
          "idcheckin": 513,
          "idhospede": 7234
        },
        {
          "idcomanda": 4,
          "idfoliohits": null,
          "idfoliotohits": null,
          "idreservasfront": 2587,
          "idstatuscomanda": 9,
          "numreserva": 2587,
          "coduh": "504",
          "nome": "Herlessi Teste",
          "sobrenome": "",
          "opcional_escolhido": null,
          "idtipolocal": 2,
          "numerolocal": "12",
          "numero_cadeira": null,
          "marcado_como_entregue": false,
          "meioprato": false,
          "dataassinatura": null,
          "datacancelamento": null,
          "datahoraabertura": new Date(),
          "fl_comanda_fechada": false,
          "datarecebidocozinha": new Date(),
          "dataenviocozinha": new Date(),
          "datarecebidogarcom": new Date(),
          "dataenviogarcom": null,
          "datarecebidobar": null,
          "dataenviobar": null,
          "dataentregue": null,
          "imgassinatura": null,
          "descstatuscomanda": "Pronto",
          "idcomandaproduto": 823,
          "observacao": null,
          "precounitario": "12.50",
          "quantidade": 1,
          "valortaxa": 10,
          "exibirtaxa": "true",
          "valorsemtaxa": "12.50",
          "valortotal": 13.75,
          "idstatuscomandaproduto": 9,
          "flbebidaantes": null,
          "idproduto": 5,
          "descproduto": "ARROZ COM BANANA",
          "nomecd": "ARROZ COM BANANA",
          "codgrupoprodpms": 1,
          "codprodutopms": "108",
          "idcategoriaprod": 3,
          "idtipocategoriaprod": null,
          "precoimportado": 12.5,
          "descricao": null,
          "idgarcominicial": 489,
          "nomegarcominicial": "Marcio Ueda",
          "idgarcomatual": 489,
          "nomegarcom": "Marcio Ueda",
          "flbebida": false,
          "nomepdv": "Restaurante",
          "passante": false,
          "id_tipo_hospede_hits": 0,
          "nomehotel": "Equipotel",
          "ipimpressora": "tcp://10.125.121.105.5",
          "nomedepartamento": "Restaurante",
          "ativo": true,
          "idcheckin": 513,
          "idhospede": 7234
        }
      ]
      const objetoRetorno = {
        valorTotal: 0,
        valorTotalSemTaxa: 0,
      }

      objetoRetorno.numpedido = mockReturnAPI[0].idcomanda
      objetoRetorno.marcado_como_entregue = mockReturnAPI[0].marcado_como_entregue
      objetoRetorno.datafeito = mockReturnAPI[0].datahoraabertura
      objetoRetorno.datarecebido = mockReturnAPI[0].datarecebidogarcom
      objetoRetorno.imgassinatura = mockReturnAPI[0].imgassinatura
      objetoRetorno.idtipolocal = mockReturnAPI[0].idtipolocal
      objetoRetorno.numerolocal = mockReturnAPI[0].numerolocal
      objetoRetorno.numreserva = mockReturnAPI[0].numreserva
      objetoRetorno.coduh = mockReturnAPI[0].coduh
      objetoRetorno.nome = mockReturnAPI[0].nome
      objetoRetorno.sobrenome = mockReturnAPI[0].sobrenome
      objetoRetorno.idgarcominicial = mockReturnAPI[0].idgarcominicial
      objetoRetorno.nomegarcominicial = mockReturnAPI[0].nomegarcominicial
      objetoRetorno.idgarcomatual = mockReturnAPI[0].idgarcomatual
      objetoRetorno.idstatuscomanda = mockReturnAPI[0].idstatuscomanda
      objetoRetorno.nomegarcom = mockReturnAPI[0].nomegarcom
      objetoRetorno.nomepdv = mockReturnAPI[0].nomepdv
      objetoRetorno.passante = mockReturnAPI[0].passante
      objetoRetorno.id_tipo_hospede_hits = mockReturnAPI[0].id_tipo_hospede_hits

      objetoRetorno.listadepedidos = mockReturnAPI
      for(var i = 0, len = mockReturnAPI.length; i < len; i++){
        if(mockReturnAPI[i].valortotal > 0){
          objetoRetorno.valorTotal += (parseFloat(mockReturnAPI[i].valortotal))
          objetoRetorno.valorTotalSemTaxa += (parseFloat(objetoRetorno.listadepedidos[i].valorsemtaxa))
        }
      }

      return objetoRetorno
    },
    finalizaTuor(){
      const perfil = JSON.parse(localStorage.getItem("perfis"));
      console.log("perfil>>", perfil)
    },
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Exemplo ADM")
      this.listarPedidosIniciais()
      this.$tours['etapas'].start()
  },

};
</script>


<style scoped>

</style>
