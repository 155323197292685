<template>
    <div style="height:100%;display:flex;">

         <Locais  :atualizar="atualizarPMS" @atualizardados="() => atualizarDados()" />
         <!-- <CardapioCD   :atualizar="atualizarCD"  @atualizardados="() => atualizarDados()" /> -->
          <!-- ADICIONAR CONTEUDO AQUI -->
        <v-tour name="localxpdv" :steps="steps" :callbacks="callbacksTuor"></v-tour>
    </div>
</template>

<script>

import Locais from '../../components/cardapio/Locais.vue'
// import CardapioCD from '../../components/cardapio/CardapioCD.vue'

export default {
  name: 'ViewHospedes',
  components:{      
      Locais,
    //   CardapioCD,
  },                
  data: function() {
    return {
      atualizarPMS:false,
      atualizarCD:false,

      steps: [
        {
          target: '[data-v-step-localxpdv="1"]',
          content: 'Quando clicado aqui, é possivel escolher o local que aparecerá em exibição para o hóspede.',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-localxpdv="2"]',
          content: 'Clicando no pdv, e ficando essa cor de fundo, significa que este pdv estará em exibição para o hóspede.',
          params: {
            placement: 'top'
          }
        },
      ],
      callbacksTuor: {
        onFinish: this.finalizedTuor,
        onStop: this.finalizedTuor,
        onSkip: this.finalizedTuor,
      }
    }
  },
  methods:{
      exemplodealerta(){
          //   this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
      },
      atualizarDados(){
          this.atualizarPMS = !this.atualizarPMS
          this.atualizarCD  = !this.atualizarCD
      },
      finalizedTuor(){
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = {...tuorLocalStorage, configuracoes: {localXpdv: true}}
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))
      }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Local x PDV")

    const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}

    if(!tuorLocalStorage?.configuracoes?.localXpdv) {
      this.$tours['localxpdv'].start()
    }

  }
};
</script>


<style scoped>
    
</style>
