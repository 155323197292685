<template>
    <v-dialog v-model="exibirModalEdicaoSubCategoria" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Sub Categoria
                    </span>
                    <span data-cy="fechar-criar-subcategoria" style="width:5%;">
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">

                        <v-flex xs12>
                            <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="uploadCompressor" />
                            <v-avatar slot="offset" class="mx-auto d-block" size="130">
                            <v-icon v-if="!imgSrc" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                            <img v-if="imgSrc" :src="imgSrc" class="imgUpload" />
                            </v-avatar>
                            <div style="display:flex;justify-content:center;margin-top:20px;">
                                <label for="file-upload" >
                                    <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                    Foto
                                    </div>
                                </label>
                            </div>
                        </v-flex>


                        <div>
                             <v-container class="">
                                <v-row>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Nome <span style="color:red;">(Máx. 50 Cararcteres)</span></h3>
                                            <input maxlength="50" type="text" data-cy="input-nome" v-model="subcategoria.nomesubcategoriaprodcd" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                        </div>
                                  </v-col>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Tempo de Preparo em minutos</h3>
                                            <input type="number" v-model="subcategoria.tempopreparo" data-cy="input-preparo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                        </div>
                                  </v-col>
                                </v-row>

                               <v-row>

                                 <v-col v-if="true">
                                   <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;">
                                     <v-autocomplete
                                         style="border-radius: 10px;"
                                         v-model="exibirPara"
                                         :items="exibirParaItens"
                                         outlined
                                         dense
                                         chips
                                         small-chips
                                         label="Exibir para"
                                         multiple
                                     ></v-autocomplete>
                                   </div>
                                 </v-col>

                                 <v-col style="display:flex; align-items: center;">
                                    <div
                                        style="display: flex; align-items: center; margin-top: -5px; width: 100%"
                                    >
                                        <div style="display: flex; flex-direction: row; justify-items: center; align-items: center;  width: 100%">
                                            <v-switch v-model="integrarComPMS" data-cy="switch-integrar-pms"></v-switch>
                                            <label>Integrar com o PMS?</label>
                                        </div>

                                        <!-- campo 'Dia máximo de integração' comentado para não exibir esse campo até entendimento real -->
                                        <div v-if="integrarComPMS && 1==2">
                                            <label for="">Dia máximo de integração</label>
                                            <input
                                                type="date" v-model="dia_maximo_integracao" style="margin-left: 10px; border-radius: 10px; padding: 5px; border: 1px solid #bdbaba; width: 150px; height: 40px;">
                                            </div>
                                    </div>
                                 </v-col>

                               </v-row>

                              </v-container>
                        </div>


                        <div style="padding-bottom:10px;display:flex;align-items:center;">
                            <v-switch v-model="subcategoria.ativo" data-cy="switch-ativo"> </v-switch>
                            <label>{{subcategoria.ativo ? 'Ativado': 'Pausado'}}</label>
                        </div>


                        <div style="padding-bottom:10px;">

                            <h3>Disponibilidade</h3>
                            <v-btn data-cy="adicionar-horario-disponibilidade" small elevation="" color="primary" @click="adicionarDisponibilidade()">Adicionar Disponibilidade</v-btn>


                            <div>
                                <v-container class="">
                                    <v-row>
                                    <v-col><v-btn @click="setDia('seg')" small elevation="" :color="seg?'success':'default'">Seg</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('ter')" small elevation="" :color="ter?'success':'default'">Ter</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('qua')" small elevation="" :color="qua?'success':'default'">Qua</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('qui')" small elevation="" :color="qui?'success':'default'">Qui</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('sex')" small elevation="" :color="sex?'success':'default'">Sex</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('sab')" small elevation="" :color="sab?'success':'default'">Sab</v-btn></v-col>
                                    <v-col><v-btn @click="setDia('dom')" small elevation="" :color="dom?'success':'default'">Dom</v-btn></v-col>
                                    </v-row>
                                </v-container>
                            </div>

                            <div style="display:flex;flex-wrap:wrap;">

                                <div v-for="horario in listaHorarios" :key="horario.id" style="display:flex;align-items:flex-end;width:25%;margin-top:10px;">
                                    <div style="border:1px solid #bdbaba;display:flex;flex-direction:column;align-items:flex-end;padding:5px;border-radius:10px;">
                                        <div style="display:flex;align-items:flex-end;padding:5px;border-radius:10px;">
                                            <datetime class="inputsHorario" data-cy="input-horainicio" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}" style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horainicial" >
                                            </datetime>
                                            <span style="margin-right:10px;" >até</span>
                                            <datetime class="inputsHorario" data-cy="input-horafim" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}"  style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horafinal" >
                                            </datetime>
                                        </div>
                                        <div>
                                            <a @click="removerDisponibilidade(horario)">excluir</a>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>


                        <!-- <div style="padding-bottom:10px;">

                            <h3>Disponibilidade</h3>

                            <div style="display:flex;align-items:flex-end;">
                                <datetime class="inputsHorario" value-zone="UTC-3" data-cy="input-horainicio" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="subcategoria.horainicio" >
                                </datetime>
                                <span style="margin-right:10px;" >até</span>
                                <datetime class="inputsHorario" value-zone="UTC-3" data-cy="input-horafim" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="subcategoria.horafinal" >
                                </datetime>
                            </div>

                        </div> -->


                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" @click="atualizarSubCategoria()" data-cy="atualizarSubCategoria">
                            SALVAR
                        </v-btn>
                    </div>

                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="error" @click="setExclusaoModal()" data-cy="setExclusaoModal">
                            EXCLUIR SUB CATEGORIA
                        </v-btn>
                    </div>

                </v-card>



            </v-card-text>


        </v-card>

        <ModalConfirmacao :showModal="exibirModalExclusao" :titulo="'Deseja Realmente Excluir a Sub Categoria?'"
        textosim="Sim" textonao="Não" @sim="()=>excluirSubCategoria('QUARTO')" @nao="()=>exibirModalExclusao=false"  />

    </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import ModalConfirmacao from '../../layout/ModalConfirmacao.vue'
import Compressor from 'compressorjs'
import {serializarImagem} from '../../../services/serializar'

  export default {
    name: 'BotaoVoltar',
    props:["exibirModalEdicaoSubCategoria","idcategoriacd","idsubcategoriacd","idpdvcd"],
    components: {
      ModalConfirmacao
    },
    data: () => ({
        exibirModalExclusao:false,
        nome:null,
        tempo:null,
        horarioinicial:false,
        horariofinal:false,
        subcategoria:null,
        imgSrc:null,
        listaHorarios:[],
        seg:false,
        ter:false,
        qua:false,
        qui:false,
        sex:false,
        sab:false,
        dom:false,

        exibirPara: [],
        exibirParaItens: [{ text: "Garcom", value: "garcom" }, { text: "Hóspede", value: "hospede" }],

        integrarComPMS: null,
        // dia_maximo_integracao: dayjs().format("YYYY-MM-DD"),
        dia_maximo_integracao: null,
    }),
    mounted(){
        this.getSubCategoriasCD()
    },
    methods:{

        async uploadCompressor(e) {
            e.preventDefault();

            this.imgSrc = URL.createObjectURL(e.target.files[0]);

            const file = e.target.files[0];
            const _self = this;

            new Compressor(file, {
                quality: 0.6,
                mimeType: "image/jpeg",
                async success(result) {
                    const imagem = new File([result], "name");

                    const infoCategoria = {
                        idsubcategoria: _self.idsubcategoriacd,
                        link: _self.$dados.BUILD_API_URL
                    };

                    const formData = new FormData();
                    formData.append('imagem', imagem, `${infoCategoria.idsubcategoria}.jpg`);
                    formData.append('infos', JSON.stringify(infoCategoria));

                    const headers = {
                        'Content-Type': 'multipart/form-data'
                    }

                    await _self.$http.post("pdv/uploadImagemSubcategoria", formData, headers).then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
                },
                error(err) {
                    console.log("Erro no comprimir")
                    console.log(err.message);
                },
            });
        },

        setDia(dia){
            if(dia === 'seg'){ this.seg = !this.seg}
            if(dia === 'ter'){ this.ter = !this.ter}
            if(dia === 'qua'){ this.qua = !this.qua}
            if(dia === 'qui'){ this.qui = !this.qui}
            if(dia === 'sex'){ this.sex = !this.sex}
            if(dia === 'sab'){ this.sab = !this.sab}
            if(dia === 'dom'){ this.dom = !this.dom}
        },
        removerDisponibilidade(horario){
            let indice = this.listaHorarios.indexOf(horario)
            if(indice > -1){
                this.listaHorarios.splice(indice,1)
            }
        },
        adicionarDisponibilidade(){
            this.listaHorarios.push({tipo:1,horainicial:'00:00:00',horafinal:'23:50:00'})
        },
        setExclusaoModal(){
            this.exibirModalExclusao = !this.exibirModalExclusao
        },
        async excluirSubCategoria(){

            let dados ={idsubcategoriacd:this.subcategoria.idsubcategoriaprodcd}
             await this.$http.post("pdv/excluirSubCategoriaCD",dados,{timeout:60000 })
                .then(() => {
                    this.setExclusaoModal()
                    this.$alertar("success","Sub Categoria Excluída Com sucesso!","=)")
                    this.$emit('atualizarLista')
                    this.fechar()
                })
                .catch((error) => {
                    console.log(error.response);
                    this.$emit('atualizarLista')
                 });

        },
        async salvaimg(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }
            let dados = {
                "tipo" : tipoImg,
                "nome" : "imgCategoria",
                "dados" : this.imgSrc,
                "tabela": "subcategoriaprodcd",
                "campo": "img",
                "chave": "id",
                "valor": this.idsubcategoriacd
            }
            await this.$http.post("pdv/imagemupdate",dados,{timeout:60000 })
                .then((response) => {
                if(response.status == 200){

                    this.imgSrc = response.data.result.imagem[0]
                }else{
                    console.log(JSON.stringify(response))
                }
                })
                .catch((error) => {
                console.log(error.response);
            });
        },
        onFileChange(e) {
            // this.setImage(e)
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaimg(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error"
                this.snackText = error.message
            })
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        getSubCategoriasCD(){
            this.$http(`pdv/getSubCategorias/${this.idcategoriacd}/${this.idsubcategoriacd}`).then((resp) =>{
                this.subcategoria = resp.data[0]

                this.seg =  resp.data[0].seg
                this.ter =  resp.data[0].ter
                this.qua =  resp.data[0].qua
                this.qui =  resp.data[0].qui
                this.sex =  resp.data[0].sex
                this.sab =  resp.data[0].sab
                this.dom =  resp.data[0].dom
                this.imgSrc = resp.data[0].img

                this.imgSrc = this.subcategoria.imgcategoriaprodcd

                // let dataMaximoIntegracao = resp.data[0].dia_maximo_integracao ? dayjs(resp.data[0].dia_maximo_integracao).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');

                // if(dayjs(dataMaximoIntegracao) < dayjs().subtract(4, 'day')) {
                //     dataMaximoIntegracao = dayjs().subtract(3, 'day').format('YYYY-MM-DD');
                // }

                // this.dia_maximo_integracao = dataMaximoIntegracao
                this.dia_maximo_integracao = null

                this.integrarComPMS = resp.data[0].integravel

                this.exibirPara = JSON.parse(resp.data[0].exibir_para)

                this.$http.post('pdv/getDisponibilidade',{tipo:3,iditem:this.subcategoria.idsubcategoriaprodcd}).then(resp =>{

                        this.listaHorarios = resp.data
                        let objlistaHorarios = resp.data
                        this.listaHorarios = []
                        for(let a=0,lena= objlistaHorarios.length; a < lena; a++){
                            let horariod = objlistaHorarios[a]
                            this.listaHorarios.push({tipo:2,horainicial:'2022-07-06T'+horariod.horainicial,horafinal:'2022-07-06T'+horariod.horafinal})
                        }

                    }).catch(error =>{

                        console.log(error)
                    })


            }).catch(error =>{
                console.log(error)
                this.$alertar("warning","Não foi possivel exibir as Sub Categorias","=(")
            })
        },
        validarDados(obj){

            if(!obj.id){
                this.$alertar("warning","Categoria não Selecionado","=(")
                return false
            }

            if(!obj.nome){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }

            if(!obj.tempopreparo){
                this.$alertar("warning","Tempo de Preparo não Informado!","=(")
                return false
            }

            if(!obj.horainicio){
                this.$alertar("warning","Horário Inicial não Informado!","=(")
                return false
            }

            if(!obj.horafinal){
                this.$alertar("warning","Horário Final não Informado!","=(")
                return   false
            }

            // if(dayjs(obj.dia_maximo_integracao) < dayjs().subtract(4, 'day')) {
            //     this.$alertar("warning","Dia máximo de integração não pode ser menor que 3 dias atrás!","=(")
            //     return false
            // }

            return true
        },
        atualizarSubCategoria(){
            let obj = {
                id:this.subcategoria.idsubcategoriaprodcd,
                nome:this.subcategoria.nomesubcategoriaprodcd,
                tempopreparo:this.subcategoria.tempopreparo,
                horainicio:dayjs(this.subcategoria.horainicio).format('HH:mm:ss'),
                horafinal:dayjs(this.subcategoria.horafinal).format('HH:mm:ss'),
                ativo:this.subcategoria.ativo,
                listaHorarios:this.listaHorarios,
                seg:this.seg,
                ter:this.ter,
                qua:this.qua,
                qui:this.qui,
                sex:this.sex,
                sab:this.sab,
                dom:this.dom,
                exibir_para: JSON.stringify(this.exibirPara),
                integravel: this.integrarComPMS,
                // dia_maximo_integracao: this.dia_maximo_integracao,
                dia_maximo_integracao: null,
            }

            if(this.validarDados(obj)){
                this.$http.put(`pdv/subCategorias`,obj).then(() =>{
                    this.$alertar("success","Sub Categoria Atualizada Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning","Não foi possivel atualizar a sub categoria","=(")
                    console.log(error)
                })
            }
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

