<template>
    <div>

    <v-dialog v-model="exibirModalItensComanda " width="1300" max-width="90vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Itens

                    </span>
                    <!-- <v-btn small elevation="" style="margin-right:20px;" color="warning" @click="imprimirComanda()">Fechar Comanda</v-btn> -->
                    <!-- <v-btn small elevation="" style="margin-right:20px;" color="" @click="() => logs.exibir = true">Logs</v-btn> -->
                    <v-btn small elevation="" style="margin-right:20px;" color="" @click="imprimirComanda()">Imprimir</v-btn>
                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <!-- {{listaItens}} -->
            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">

                        <v-data-table v-if="listaItens" :headers="headers" :items="listaItens">

                          <template v-slot:[`item.idcomanda`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.idcomanda }}
                            </div>
                          </template>

                          <template v-slot:[`item.id`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.id }}
                            </div>
                          </template>

                          <template v-slot:[`item.nomecd`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.nomecd }}
                            </div>
                          </template>

                          <template v-slot:[`item.quantidade`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.quantidade }}
                            </div>
                          </template>

                          <template v-slot:[`item.precounitario`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.precounitario | formatarMoeda }}
                            </div>
                          </template>

                          <template v-slot:[`item.total_item`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.total_item | formatarMoeda }}
                            </div>
                          </template>

                          <template v-slot:[`item.status_item`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.status_item }}
                            </div>
                          </template>

                          <template v-slot:[`item.motivoremocao`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.motivoremocao }}
                            </div>
                          </template>

                          <template v-slot:[`item.dataremocao`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.dataremocao | formatarDataHora }}
                            </div>
                          </template>

                          <template v-slot:[`item.nome_garcom`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.nome_garcom }}
                            </div>
                          </template>

                          <template v-slot:[`item.integracao_pms`]="{ item }">
                            <div v-if="item.integracao_pms === 'Não Integrou'" :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.integracao_pms }}
                            </div>
                            <div v-else :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.integracao_pms | formatarDataHora }}
                            </div>
                          </template>

                          <template v-slot:[`item.impressao`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.impressao }}
                            </div>
                          </template>

                          <template v-slot:[`item.hora_solicitado`]="{ item }">
                            <div :class="{ 'fundo-vermelho': !item.ativo }">
                              {{ item.hora_solicitado | formatarDataHora }}
                            </div>
                          </template>
                        </v-data-table>
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <b>TOTAL</b>: {{totalComanda | formatarMoeda }}
                    </div>


                    <!-- UHS DE PASSANTES CADASTRADOS NO SISTEMAS, AVULSOS, SÃO INICIADAS COM 'PS' -->
                    <!-- {{pedido}} -->
                    <div v-if="pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PS' && pedido.comandaaberta != 'Fechada'">
                        <v-btn  small elevation="" color="primary"   style="width:40%;height:7vh"
                                                 data-cy="btn-sim" @click="setexibirModalAddPagtoBaixar()">
                                                Adicionar Pagamento e Baixar
                                                </v-btn>
                    </div>
                    <div v-if="pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PSs' && pedido.comandaaberta != 'Fechada'">


                          <div style="border:1px solid #cbcbcb;margin-top:10px;display:flex;" >
                            <!-- {{this.pedido.idstatus}} -->
                            <div style="width:30%;padding:20px;">
                                <v-select return-object color v-model="cobrartaxas" :items="listacobrartaxas" @change="setValorFinalFechar()"  item-text="nome" item-value="id" label="Cobrar Taxas"></v-select>
                            </div>
                            <div style="width:30%;padding:20px;">
                                <v-select color v-model="tipoPagamentoSelecionado" :items="listaTiposPagamentos" item-text="label" item-value="value" label="Tipo Pagamento"></v-select>
                            </div>

                            <div style="width:40%;padding:20px;">
                                <v-text-field type="number" name="name" label="NSU" id="nsu" v-model="nsu" ></v-text-field>
                            </div>

                          </div>

                          <div style="border:1px solid #cbcbcb;margin-top:10px;display:flex;">
                              <div style="width:30%;padding:20px;">
                                  <b>Valor Total:</b>{{totalComanda | formatarMoeda}}
                              </div>
                              <div style="width:30%;padding:20px;">
                                  <b>Valor Taxas:</b>{{(totalComanda - totalComandaSemTaxa) | formatarMoeda}}
                              </div>
                              <div style="width:40%;padding:20px;background-color:#2196F3;color:white;font-weight:bold;font-size:20px;">
                                  Valor Final:{{valorFecharComanda | formatarMoeda}}
                              </div>
                          </div>

                          <div v-if="pedido.idstatus == 5" style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                              <v-btn color="error" @click="enviarPagamento()" :disabled="tipoPagamentoSelecionado  && tipoPagamentoSelecionado > 0?false:true">
                                  FECHAR COMANDA
                              </v-btn>
                          </div>
                    </div>

                </v-card>



            </v-card-text>


        </v-card>




    </v-dialog>

    <ModalLogs
        :logs="logs.data"
        :exibirModalLogs="logs.exibir"
        @fechar="() => logs.exibir = false"
    />

    <ModalConfirmacao :showModal="exibirModalConfirmacaoRemocao" :titulo="'Confirma Remoção do Item?'"
        :textonao="'Cancelar'"   @nao="()=>setModalRemocaoItem(null)"
        :textosim="'Confirmar'"  @sim="()=>removerItemDaComanda()"
        />

    <ModalAddPagtoPassante v-if="exibirModalAddPagtoBaixar" :exibirModalAddPagtoBaixar="exibirModalAddPagtoBaixar"
            :pedido="pedido"
            @fechar="()=>setexibirModalAddPagtoBaixar()" />

    </div>
</template>

<script>

  import ModalConfirmacao from '../ModalConfirmacao.vue'
  import ModalAddPagtoPassante from './ModalAddPagtoPassante.vue'
  import ModalLogs from './ModalLogs.vue'

  export default {
    name: 'ModalItensComandaCompleto',
    props:["exibirModalItensComanda","pedido"],
    components:{
        ModalConfirmacao,
        ModalAddPagtoPassante,
        ModalLogs,
    },
    data: () => ({
        valorFecharComanda:null,
        exibirModalConfirmacaoRemocao:false,
        listaItens:null,
        
        headers:[
          { text: 'ID Comanda', value: 'idcomanda' },
          { text: 'ID CP', value: 'id' },
          { text: 'Produto', value: 'nomecd' },
          { text: 'Quantidade', value: 'quantidade' },
          { text: 'Preço Unitário', value: 'precounitario' },
          { text: 'Preco Total', value: 'total_item' },
          { text: 'Status', value: 'status_item' },
          { text: 'Remoção', value: 'motivoremocao' },
          { text: 'Data Remoção', value: 'dataremocao' },
          { text: 'Garçom', value: 'nome_garcom' },
          { text: 'Integracao', value: 'integracao_pms' },
          { text: "Impresso?", value: "impressao" },
          { text: "Hora Solicitado", value: "hora_solicitado" },
        ],
        pedidoSelecionado:null,
        totalComanda:0,
        totalComandaSemTaxa:0,
        logs: {
          exibir: false,
          data: []
        }
    }),
    async mounted(){
      await this.getProdutosdoPedido(this.pedido.idcomanda)
    },
    watch:{
      async pedido(){
        await this.getProdutosdoPedido(this.pedido.idcomanda)
      }
    },
    methods:{
      fecharComanda(){
        const dados = {
          id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
          web: true,
        }

        this.$http.post(`/pdv/alterarStatusPedidoEProdutos/${this.pedido.numpedido}/5/f`, dados).then(resp =>{
            this.listaTiposPagamentos = resp.data.tipospagamentos
            this.fechar()
        }).catch(error =>console.log('ERRO RETNORO ',error))
      },
      imprimirComanda() {
        let objPedido = this.pedido
        objPedido.itens = this.listaItens
        this.$emit('imprimir',objPedido)
      },
      fechar(){
        this.$emit('fechar')
      },
      async getProdutosdoPedido(idcomanda) {
        const url = `/pdv/infosCompletasComandas/${idcomanda}`

        await this.$http(url)
          .then(({data}) => {
            this.listaItens = data;

            for(const item of this.listaItens) {
              if(item.ativo && item.integracao_pms != 'Não Integrou') {
                this.totalComanda += parseFloat(item.total_item)
              }
            }

          }).catch((err) => {
            console.log(err)
            this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
          })
      },
      async getLogsComandaHITS(idcomanda) {
        const url = `pdv/logHITSComandas/${idcomanda}`;
        const response = await this.$http(url)
          .then((res) => res.data )
          .catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
          });

        return response;
      },
    }
  }
</script>


<style scoped>
.fundo-vermelho {
  font-style: italic;
  color: red;
  text-decoration: line-through;
}
</style>

