<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="ojbPedidos.statuscomanda" :corTexto="corTexto" />
    <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="'Total: R$ '+ojbPedidos.valorTotal.toFixed(2)" :corTexto="corTexto" />

    <div class="categoryList" style="margin-top:10px;margin-bottom:20px;" data-cy="lista-pedidos" v-if="ojbPedidos && ojbPedidos.listadepedidos">
      <PedidoAdm data-cy="pedidoAdm" v-for="(pedido, index) in ojbPedidos.listadepedidos" :key="pedido.numpedido" 
        :pedido="pedido"  :corTexto="corTexto" :index="index"
        @setarPedido="(pedido)=>setarPedido(pedido)"
       data-v-step-etapas="2"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  import PedidoAdm from './PedidoAdm.vue'
  export default {
    name: 'PedidosLista',
    props:['ojbPedidos','statuscomanda','corTexto'],
    components:{
      // TitleH1,
      PedidoAdm,
      Subtitulo,
    },
    data: () => ({
      // listadeprodutos:[]
      indexultimoselecionado : null
    }),

    methods:{

      setarPedido(pedido){
          
          this.$emit('setarPedido', pedido)
      }

    },

    mounted(){
      // this.getProdutos()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
      overflow:auto;
      max-height:65vh;
    }
</style>
