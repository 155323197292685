<template>
    <v-dialog v-model="exibirModalConfirmacao" width="1000" max-width="80vw">
        <v-card>
            <v-toolbar color="#1976d2" class="mb-8">
                <v-flex style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Dados de Confirmação
                    </span>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                
                <pre style="font-weight:bold;">{{ dados | pretty }}</pre>        
            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['dados', 'exibirModalConfirmacao'],
    data(){
        return {
            headers: [
                { text: 'Cód. Produto', align: 'start', value: 'codprod' },
                { text: 'Descrição', align: 'start', value: 'descricao' },
                { text: 'Data', align: 'start', value: 'data' },
                { text: 'Preço Unit.', align: 'start', value: 'preco' },
                { text: 'Quantidade.', align: 'start', value: 'quant' },
                { text: 'Valor Total', align: 'start', value: 'valortotal' },
            ],
        }
    },
    methods: {
        fechar(){
            this.$emit('fechar')
        }
    },
}
</script>

<style lang="scss" scoped>

::v-deep tbody tr {
  cursor: pointer;
}

::v-deep tbody tr:nth-child(2n+1) {
  background: rgba(0, 0, 0, 0.05);
}

//css indesejado
.v-application p {
  margin-bottom: unset !important;
}

</style>