<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logodiv" style="background-colo: red">
        <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
      </div>
      <div>
        <v-btn
          large
          block
          color="info"
          class="white--text font-weight-bold"
          data-cy="btn-verificar"
          v-on:click="verificar()"
        >
          Verificar
          <v-icon right dark>mdi-login</v-icon>
        </v-btn>
        
        <v-row justify="center" class="mt-2">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerificarEmailHospedeFake",
  data() {
    return {
      token: null,
    };
  },

  components: {},

  methods: {
    async verificar(){
      //bater na rota auth/verificartoken e essa rota verificar envia o email para o hospedeverificartoken
      await this.$http.post('/auth/verificartoken', {token:this.token}).then(res => {
        if(res.status === 200){
          this.$alertar('success', '=)', 'Verificação Concluida. Olhe a sua caixa de email.')
        }
      }).catch(e => {this.$alertar('warning', '=/', e);console.log(e)})
    }

  },
  async mounted(){
    //verificar se o token existe uma rota parar checar se o token passado na requisicao existe e nao esta verificado
    const { token } = this.$route.params
    await this.$http.post('/auth/validartoken', {token}).then(res => {
      if(res.status === 200 && res.data.valor){
        this.token = token
        return
      }
      if(res.status === 200 && !res.data.valor){
        this.$router.push('/')    
        return  
      }
    }).catch(e => {alert('erro na validacao do token. olhe o console');console.log(e)})
  }
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
}
</style>