<template>
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            v-bind="attrs"
            v-on="on"
            style="margin-right: 15px; color: #2196f3; cursor: pointer;height: fit-content;"
            data-v-step-etapas="8"
        >
          mdi-swap-horizontal
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Transferência de itens da comanda</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-autocomplete
                  :items="tiposTransferencia"
                  label="Qual o tipo de transferência?"
                  item-text="text"
                  v-model="tipoTransferencia"
              ></v-autocomplete>
            </v-row>

            <v-row v-if="tipoTransferencia === 'fechadas'">
              <v-autocomplete
                  :items="['Hóspede', 'Day Use', 'Passante']"
                  label="Qual o tipo de hóspede?"
                  item-text="nome"
                  item-value="idcheckin"
                  required
                  v-model="tipoHospede"
              ></v-autocomplete>
            </v-row>
            <v-row v-show="tipoHospede != null">
              <v-autocomplete
                  :items="hospedeFiltrados"
                  label="Pra quem deseja transferir?"
                  item-text="nome"
                  return-object
                  required
                  v-model="hospedeSelecionado"
              ></v-autocomplete>
            </v-row>

            <v-row v-if="tipoTransferencia === 'abertas'">
              <v-autocomplete
                  :items="comandasAbertas"
                  label="Para qual comanda deseja transferir?"
                  item-text="text"
                  return-object
                  required
                  v-model="hospedeSelecionado"
              ></v-autocomplete>
            </v-row>


            <v-row>
              <v-textarea
                  label="Motivo da transferência"
                  required
                  v-model="motivoTransferencia"
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="warning"
              @click="dialog = false"
              style="margin: 0 !important;"
          >
            Fechar
          </v-btn>
          <ModalOutrosItensTransferencia
            :pedido="pedido"
            @transferirComanda="() => transferirComanda()"
            @atualizaItensTransferidos="(itens) => atualizaItensTransferidos(itens)"
            :itensAdicionado="itensTransferidos"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup>
import ModalOutrosItensTransferencia from "@/components/layout/Modais/ModalOutrosItensTransferencia.vue";

export default {
  props: ["pedido", "pedidoitem"],
  components: {
    ModalOutrosItensTransferencia,
  },
  async mounted() {
    await this.listarComandas();
    this.itensTransferidos.push(this.pedidoitem.idcomandaproduto)
  },
  data: () => ({
    dialog: false,

    tipoHospede: null,
    hospedeFiltrados: [],
    hospedes: [],
    hospedeSelecionado: null,
    motivoTransferencia: null,

    comandasAbertas: [],

    tipoTransferencia: null,
    tiposTransferencia: [{text: 'Comandas abertas', value: 'abertas'}, {text: 'Abrir nova comanda', value: 'fechadas'}],

    itensTransferidos: [],
  }),
  watch: {
    tipoHospede: function (val) {
      this.hospedeSelecionado = null

      if(val === "Passante") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.passante)
      }

      if (val === "Hóspede") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.id_tipo_hospede_hits === 0 || hospede.id_tipo_hospede_hits === 1)
      }

      if(val === "Day Use") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.id_tipo_hospede_hits === 2)
      }
    },
    tipoTransferencia: function() {
      this.limparCampos()
    }
  },
  methods: {
    fecharModal() {
      this.dialog = false
    },

    async listarComandas() {
      await this.$http.get("pdv/buscarComandasTransferenciaItem")
          .then(({data}) => {
            this.hospedes = data.comandasASeremAbertas
            this.comandasAbertas = data.comandasAbertas.map(comanda => {

              function exibicaoUH(dados) {
                let text;

                if(dados.passante) {
                  text = `- Passante`
                }

                if(dados.id_tipo_hospede_hits === 0 || dados.id_tipo_hospede_hits === 1) {
                  text = `- UH: ${dados.coduh}`
                }

                if(dados.id_tipo_hospede_hits === 2) {
                  text = `- Dayuse`
                }

                return text;
              }

              return {
                ...comanda,
                text: `ID: ${comanda.idcomanda} - ${comanda.nome} ${exibicaoUH(comanda)}`
              }
            })
          })
          .catch(err => {
            console.log(err)
          })
    },

    async transferirComanda() {

      if(this.validacoesCampoPreenchidos(this.tipoTransferencia) === false){
        return;
      }

      const data = {
        idcomandaantiga: this.pedido.numpedido,
        idusuarioresponsavel: localStorage.getItem("idusuario"),
        motivotransferencia: this.motivoTransferencia,
        tipotransferencia: this.tipoTransferencia,
        itensTransferidos: this.itensTransferidos,

        dadosTransferenciaComandaAberta: {},
        dadosTransferenciaComandaFechada: {}
      }


      if(this.tipoTransferencia === 'abertas') {
        data.dadosTransferenciaComandaAberta.idcomanda = this.hospedeSelecionado.idcomanda
      }

      if(this.tipoTransferencia === 'fechadas') {
        data.dadosTransferenciaComandaFechada.idcheckin = this.hospedeSelecionado.idcheckin
      }

      await this.$http.post("pdv/transferenciaItensComanda", data)
          .then(() => {
            // console.log(res.data)
            this.limparCampos();
            this.dialog = false;
            this.$emit("listarComanda")
          })
          .catch(err => {
            console.log(err.response.data)
          })
    },

    validacoesCampoPreenchidos(tipoTransferencia) {

      if(tipoTransferencia !== 'abertas' && tipoTransferencia !== 'fechadas') {
        this.$alertar("info", "Selecione o tipo de transferência", "=(")
        return false
      }

      if(tipoTransferencia === 'fechadas') {
        if(this.tipoHospede === null) {
          this.$alertar("info", "Selecione o tipo de hóspede", "=(")
          return false
        }

        if(this.hospedeSelecionado === null) {
          this.$alertar("info", "Selecione um hóspede para transferir os itens", "=(")
          return false
        }
      }

      if(tipoTransferencia === 'abertas') {
        if(this.hospedeSelecionado === null) {
          this.$alertar("info", "Selecione uma comanda para transferir os itens", "=(")
          return false
        }
      }

      // Validacoes universais
      if(
          this.motivoTransferencia === null ||
          this.motivoTransferencia.trim() === "" ||
          this.motivoTransferencia.trim().length < 3
      ) {
        this.$alertar("info", "Preencha o motivo da transferência", "=(")
        return false
      }

      if(this.itensTransferidos.length === 0) {
        this.$alertar("info", "Selecione pelo menos um item para transferir", "=(")
        return false
      }
    },

    atualizaItensTransferidos(itens) {
      this.itensTransferidos = itens
    },

    limparCampos() {
      this.hospedeSelecionado = null
      this.motivoTransferencia = null
      this.tipoHospede = null
    }
  }
}

</script>

<style scoped>

</style>
