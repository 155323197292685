<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1p2eJ5W5l9ggDcGoh8QYzzhyFPtYJPRpR/preview" />
    </div>
        <v-card-text style="min-height:10%;max-height:10%;margin-bottom:50px;">
            <div style="witdh:100%;display:flex;">
                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <label for="pesquisanome" style="font-size:14px;font-weight:bold;">Cumim</label>
                    <input id="pesquisanome" v-model="pesquisanome" data-cy="pesquisanome" maxlength="50" type="text"  placeholder="Digite o nome do Cumim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>

                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <label for="pesquisaemail" style="font-size:14px;font-weight:bold;">Email</label>
                    <input id="pesquisaemail" v-model="pesquisaemail" data-cy="pesquisaemail" maxlength="50" type="text"  placeholder="Digite o email do Cumim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>

                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <v-btn small elevation="" color="primary"   style="width:60%;margin-top:25px;"                      
                    @click="buscarGarcons()" data-cy="buscarGarcons">Pesquisar</v-btn>
                </div>

            </div>
        </v-card-text>
        <v-card-text style="min-height:80%;max-height:300px;overflow:auto;">

                <div style="margin-top:10px;display:flex;flex-direction:column;border-bottom:1px solid #b5b5b5;" v-for="garcon in listadegarcons" :key="garcon.id">
                    <div  style="margin-top:10px;display:flex;">
                        <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                            <label for="mesainicial" style="font-size:14px;font-weight:bold;">Cumim</label>
                            <div style="padding-top:5px;" data-cy="garcon.nome">{{garcon.nome}}</div>
                        </div>
                        <!-- <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:row;align-items:center;width:25%;padding-right:20px;margin-bottom:20px;">
                            <v-switch v-model="garcon.md_receber_room_service" data-cy="garcon.md_receber_room_service"> </v-switch>
                            <label style="font-size:18px;">RoomService?</label>
                        </div> -->
                        <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:row;align-items:center;width:25%;padding-right:20px;margin-bottom:20px;">
                            <v-switch v-model="garcon.garcomonline" data-cy="garcon.garcomonline"> </v-switch>
                            <label style="font-size:18px;">Ativo?</label>
                        </div>

                        <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:15%;padding-right:20px;margin-bottom:20px;">
                            <div style="margin-left:10px;margin-top:5px;width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                                    <v-btn small elevation="" color="primary"   style="width:60%;height:7vh"   data-cy="atualizarUsuario"                   @click="atualizarUsuario(garcon)">SALVAR</v-btn>
                            </div>
                        </div>
                    </div>
                    <!-- <div  style="margin-top:1px;display:flex;border-bottom:1px solid #b5b5b5;padding-bottom:10px;" data-cy="wrapper-mesas">

                        <div data-cy="mesa-item" v-for="mesa in garcon.mesas" :key="mesa.id" style="border:1px solid #b5b5b5;display:flex;flex-direction:column;width:20%;padding-left:2px;border-radius:5px;margin-right:10px;">
                            <label for="mesainicial" style="font-size:14px;font-weight:bold;">Intervalo de Mesas</label>
                            <div style="display:flex;width:100%">
                                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                                    <input id="mesainicial" v-model="mesa.mesainicial" data-cy="mesa.mesainicial" maxlength="50" type="text"  placeholder="Início" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                </div>
                                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                                    <input id="mesainicial" v-model="mesa.mesafinal" data-cy="mesa.mesafinal"  maxlength="50" type="text"  placeholder="Fim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                </div>
                            </div>
                            <a @click="excluirIntervalo(mesa)" data-cy="excluirIntervalo">Excluir</a>
                        </div>

                        <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:15%;padding-right:20px;margin-bottom:20px;">
                            <div style="margin-left:10px;margin-top:5px;width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                                    <v-btn small elevation="" color="success"   style="width:60%;height:7vh"         data-cy="adicionarMesaGarcom"             @click="adicionarMesaGarcom(garcon)">ADD</v-btn>
                            </div>
                        </div>
                        
                        
                    </div> -->
                </div>

                
               

        
                
                  
        
        </v-card-text>

        <ModalConfirmacao :showModal="exibirModalConfirmar" :titulo="'Confima a exclusão?'" 
                    textosim="Confirmar" textonao="Cancelar" @sim="()=>confirmarExclusao()" @nao="()=>{exibirModalConfirmar = false}"   />

    </v-card>
</template>

<script>
import ModalConfirmacao from '../layout/ModalConfirmacao.vue'
import BotaoTutorial from '../layout/BotaoTutorial.vue'

  export default {
    name: 'Mesa Config',
    components: {
    ModalConfirmacao,
    BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarGarcons()
    },
    watch:{
      
    },
    data: () => ({
        pesquisanome:null,
        pesquisaemail:null,
        mesainicial:1,
        mesafinal:100,
        nome:null,
        email:null,
        listadegarcons:[],
        exibirModalConfirmar:false,
        mesaSelecionada:null
    }),
    methods:{
        confirmarExclusao(){
            
            this.$http.post(`pdv/excluirIntervaloMesa`,{idintervalo:this.mesaSelecionada.id}).then(() =>{
                    this.$alertar("success","Usuario Atualizado Com sucesso!","=)")
                    this.$emit('atualizarLista')
                    this.exibirModalConfirmar = !this.exibirModalConfirmar
                    this.buscarGarcons()
                }).catch((error) =>{
                    this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                    this.exibirModalConfirmar = !this.exibirModalConfirmar
                })
            
        },
        excluirIntervalo(mesa){
            this.mesaSelecionada = mesa
            this.exibirModalConfirmar = !this.exibirModalConfirmar
        },
        adicionarMesaGarcom(garcom){
            if(garcom.mesas){
                garcom.mesas.push({mesainicial:null,mesafinal:null})
            }else{
                garcom.mesas = []
                garcom.mesas.push({mesainicial:null,mesafinal:null})
            }
        },
        buscarGarcons(){

                let obj = {
                    nome:this.pesquisanome,
                    email:this.pesquisaemail,
                    idperfil:7,
                    filtro: "Todos"
                }

                this.$http.post('pdv/buscarusuarios',obj)
                .then((resp) =>{
                    // this.$alertar("success",JSON.stringify(resp.data), "=)")
                    this.listadegarcons = resp.data
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível buscar os usuarios!", "=(")
                })
        },
        atualizarUsuario(garcom){
           
            // garcom.mesas = garcom.mesas.filter(x =>{
            //     return (x.mesainicial && x.mesafinal)
            // })
            let obj = {
                idusuariosistema:garcom.idusuario,
                nome:garcom.nome,
                email:garcom.email,
                nomeusuario:garcom.nomeusuario,
                // idperfil:5,
                usuariosxperfil: 7,
                // md_mesa_inicial:garcom.md_mesa_inicial,
                // md_mesa_final:garcom.md_mesa_final,
                // md_receber_room_service:garcom.md_receber_room_service,
                garcomonline:garcom.garcomonline,
                // mesas:garcom.mesas
            }

           

            // if(this.validarDados(obj)){

                this.$http.post(`pdv/atualizarusuario`,obj).then(() =>{
                    this.$alertar("success","Usuario Atualizado Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                    // console.log("error")
                    // console.log(JSON.stringify(error.response.data.message))
                })
            // }
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'PRACA'})
            .then(resp =>{
                this.mesainicial = resp.data[0].valor
                this.mesafinal   = resp.data[0].valor2
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'PRACACOMPARTILHADA'})
            .then(resp =>{
                this.pracacompartilhada = resp.data[0].valor === 'true' ? true:false
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>