<template>
  <v-card v-if="referencia">
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          v-for="(chave, indice) in Object.keys(referencia)"
          :key="indice"
          dense
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-text-field :disabled="somenteLeitura"
            :label="`${chave} (R$)`"
            type="number"
            step="0.1"
            class="right-input"
            v-model="referencia[chave]"
            dense
            return-masked-value
            mask="###.###.###,##"
            :class="{ 'strong': mesCompetencia === indice }"
            :color="`${mesCompetencia === indice ? 'teal' : 'primary'}`"
            @input="fatAlterado=true"
          />
        </v-col>
      </v-row>
      <v-row justify="center" style="text-align:center;">
        <v-col cols="3" align-self="center">
          <v-btn
            :disabled="!fatAlterado"
            small
            elevation=""
            color="primary"
            @click="salvarReferencia()"
          >
            Salvar
          </v-btn>
        </v-col>        
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ComissaoReferencia",
  props: {
    referencia: {
      type: Object,
      required: false,
    },
    mesCompetencia: {
      type: Number,
      required: true
    },
    id_comissao_regra: {
      type: Number,
      required: true
    },
    somenteLeitura: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data: () => ({
    fatAlterado: false
  }),
  watch: {
    mesCompetencia(val) {
      if (val) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    converterPropriedadesParaFloat(obj) {
      let resultado = Object.assign({},obj)
      let chaves = Object.keys(resultado);
      for (var i = 0; i < chaves.length; i++) {
        let chave = chaves[i]
        var value = resultado[chave];
        var floatValue = parseFloat(value);
        resultado[chave] = isNaN(floatValue) ? 0.0 : floatValue;
        //console.error(`resultado[key]: ${resultado[chave]}`)
      }
      return resultado;
    },

    salvarReferencia() {
      //
      let objModificado = this.converterPropriedadesParaFloat(this.referencia)
      //console.error(JSON.stringify(objModificado))
      this.$http
        .post("comissao/referencia/salvar", {
          id_comissao_regra: this.id_comissao_regra,
          referencia: objModificado
        })
        .then(async () => {
          this.$emit('mudou');
          this.$alertar("success", "Referências de faturamento salvas!", "=)");
          this.fatAlterado = false;
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    }
  },
  mounted() {
    
  },
};
</script>

<style>
.container {
  padding: 10px;
}
.toolbar {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
}
.toolbar-title {
  width: 80%;
}
.tutorial-button {
  width: 5%;
  margin-right: 30px;
}
.filter-container {
  max-width: 100%;
  padding-bottom: 10px;
}
.filter-input {
  width: 100% !important;
  padding-bottom: 0px;
}
.filter-buttons {
  display: flex;
  justify-content: flex-end;
}
.filter-buttons-col {
  padding-bottom: 0px;
  padding-top: 10px;
  margin-right: 20px;
}
.right-input input {
  text-align: right;
}
.strong input {
  font-weight: bold;
  color: teal!important;
}
</style>