<template>
  <div>
<!--    <BotaoVoltar :url="'/Hospede/Menu'" />-->
    <div>
      <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
          <v-btn depressed color="error" @click="voltar()">{{$t('voltar.texto')}}</v-btn>
      </div>
    </div>
    <Subtitulo :subtitulo="subtitulo" />
    <div class="categoryList">
      <div class="d-flex align-center categoryItem" @click="direcionar(categoria.idcategoriaprodcd)" data-cy="categoria" v-for="categoria in listadecategorias" :key="categoria.idcategoriaprodcd">
        <div class="colA">
          <v-img class="colAimg" :src="categoria.imgcategoriaprodcd ? categoria.imgcategoriaprodcd : null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
          <p class="colBTitle">{{categoria.nomecategoriaprodcd}}</p>
          <p class="colBOutHour" v-show="categoria.dentro_do_horario == 'false'" style="color:red"> Fora do horário</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CategoriaLista from '../../components/categories/CategoriaLista.vue'
// import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
import Subtitulo from '../../components/layout/Subtitulo.vue'
// import Categoria from "@/components/categories/Categoria.vue";

export default {
  name: 'CategoriaMesa',
  data: () => ({
    subtitulo: "",

    listadecategorias: [],
  }),
  components: {
    // Categoria,
    // CategoriaLista,
    // BotaoVoltar,
    Subtitulo,
  },
  
  async mounted(){
    await this.getNomePDV();
    await this.getCategorias();
  },
  methods: {
    voltar() {
      this.$router.go(-1);
    },  
    async getCategorias(){
      this.$http(`pdv/getCategorias/${this.$route.params.idpdv}/0/hospede`).then((resp) =>{
        this.listadecategorias = resp.data
      }).catch(error =>{
        console.log(error)
        this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
      })
    },
    async getNomePDV() {
      this.$http('pdv/getNomePdv/'+this.$route.params.idpdv).then((resp) =>{
        this.subtitulo = resp.data.descpdv
        localStorage.setItem('pdvSelecionado', this.$route.params.idpdv)
      }).catch(error =>{
        console.log(error)
        this.$alertar("warning","Não foi pegar o nome do PDV","=(")
      })
      this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
    },
    direcionar(idsubcategoria) {
      const url = `/Hospede/SubCategoriasMesa/${this.$route.params.idpdv}/${idsubcategoria}`
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
  .categoryList{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    padding:0px!important;
  }

  .categoryItem{
    font-size:1.2em;
    color:rgb(70, 70, 70);
    /* background-color: pink; */
    height: 70px;
    width:330px;
    max-width:100%;
    margin-bottom: 20px;
    margin-right:2%;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 10px;
    padding:5px;
  }
  .categoryItem .colA{
    /* background-color:red; */
    width: 60px;
    padding-left:2%;
    padding-right: 2%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .categoryItem .colA .colAimg{
    border-radius: 100%;
    height:50px;
    max-height:50px;
    width:50px;
    max-width:50px;
  }

  .categoryItem .colB{
    /* background-color:Blue; */
    width: 270px;
    padding-left:2%;
    display: flex;
    flex-direction: column;
    padding-right:2%;
    word-break: break-all;
  }

  .colBTitle {
    font-size: 18px;
    margin: 0;
  }
  .colBOutHour {
    font-size: 14px;
    margin: 0;
  }
</style>
