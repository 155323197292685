<template>
  <v-card-text class="content-cores">
    <h1>Cores</h1>
    <div class="container-cores">
      <div class="edit">
        <h2>Editar</h2>
        <div class="inputs-colors">
            <div class="color-input">
              <label for="corPrincipal">Cor Principal</label>
              <input
                  v-model="configLayout.corPrincipal"
                  type="color"
                  id="corPrincipal"
                  class="input-color"
              />
            </div>

            <div class="color-input">
              <label for="textoPrimario">Texto Primário</label>
              <input
                  v-model="configLayout.textoPrimario"
                  type="color"
                  id="textoPrimario"
                  class="input-color"
              />
            </div>

            <div class="color-input">
              <label for="textoSecundario">Texto Secundário</label>
              <input
                  v-model="configLayout.textoSecundario"
                  type="color"
                  id="textoSecundario"
                  class="input-color"
              />
            </div>
        </div>

      </div>

      <div class="preview">
        <h2>Pre-vizualizar</h2>

        <div class="container-preview">
          <div class="content-preview">
            <img src="/imgs/exemploProdutoCores.jpg" />
            <div class="infos-preview">
              <v-chip class="badge" :style="'background-color: ' + configLayout.corPrincipal">
                Categoria
              </v-chip>
              <div class="infos-top">
                <p class="nome" :style="'color: ' + configLayout.textoPrimario">Filé com Fritas</p>
                <p class="descricao" :style="'color: ' + configLayout.corPrincipal">R$ 49,00</p>
              </div>
              <v-divider class="divider"></v-divider>
              <span class="description">
                <span :style="'color: ' + configLayout.textoSecundario">Filé Grelhado, Banana E Queijo A Milanesa, Fritas, Frutas E Arroz.</span>
                <v-divider class="divider"></v-divider>
              </span>
             
              <div class="observations">
                <span :style="'color: ' + configLayout.textoSecundario">Observações: </span>
                <Textarea disabled/>
              </div>

              <div class="interacts-bottom">
                <div class="quantity-buttons">
                  <button>-</button>
                  <span>5</span>
                  <button>+</button>
                </div>

                <v-btn class="add" :style="'background-color: ' + configLayout.corPrincipal ">
                  Adicionar
                </v-btn>

              </div>

            </div>
          </div>
  </div>

      </div>
    </div>
    <v-btn class="save-button" color="primary" @click="salvarCores" data-cy="salvar">
      SALVAR
    </v-btn>
  </v-card-text>
</template>

<script>
  export default {
    name: 'CoresConfiguracoes',
    components: {},
    props:[],
    data: () => ({
      configLayout: {
        corPrincipal: '#ff0000',
        textoPrimario: '#000000',
        textoSecundario: '#ffffff',
      },
    }),
    async mounted(){
      await this.buscarDados()
    },
    methods:{
        salvarCores(){
            const valor = JSON.stringify({
                corPrincipal: this.configLayout.corPrincipal,
                textoPrimario: this.configLayout.textoPrimario,
                textoSecundario: this.configLayout.textoSecundario,
            }, null, 2)

            const parametro = {
                chave: 'CONFIGS_CORES_HOSPEDE',
                valor,
            }

            this.$http.put('pdv/updateparametros', parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
        },
        async buscarDados(){
            const url = `pdv/coresHospedeFront`

            await this.$http(url).then((res) => {
                this.configLayout.corPrincipal = res.data.corPrincipal
                this.configLayout.textoPrimario = res.data.textoPrimario
                this.configLayout.textoSecundario = res.data.textoSecundario
            })
            .catch(err => {
                console.log(err)
            })

        }
    }
  }
</script>

<style src="./cores.css" scoped></style>