<template>
    <v-dialog v-model="exibirModalCheckinFake" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span v-show="tipoIntegracao === 'hits'" style="width:90%;color:white;font-weight:bold;display: flex;" >
                        Criar Hospede Passante
                    </span>
                    <span v-show="tipoIntegracao === 'ADMH'" style="width:90%;color:white;font-weight:bold;display: flex;" >
                        Criar Conta Avulsa
                    </span>
                    <span style="width:5%;display: flex;">
                        <!-- <BotaoTutorial categoria="" legenda="" urlVideo="" /> -->
                    </span>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <div style="padding-bottom:10px;">
                            <h3>Nome <span style="color:red;"></span></h3>
                            <input maxlength="50" type="text" v-model="nome" data-cy="modalfake-nome" placeholder="Digite o nome completo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Email (Opcional)<span style="color:red;"></span></h3>
                            <input maxlength="50" id="campo_email" type="text" v-model="email" data-cy="modalfake-email" placeholder="Digite o email" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Tipo Documento<span style="color:red;"></span></h3>
                            <v-select color v-model="tipoDocumentoselecionado" :items="listaDocumentos"  return-object
                            item-value="id" item-text="nome" @change="setClearDoc()" ></v-select>
                            
                        </div>

                        <div style="padding-bottom:10px;" v-if="tipoDocumentoselecionado.id == 1">
                            <h3>CPF (Opcional)<span style="color:red;"></span></h3>
                            <input maxlength="50" id="campo_cpf" type="text" v-model="cpf" data-cy="modalfake-cpf" placeholder="Digite o cpf sem espaços e pontos" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;" v-if="tipoDocumentoselecionado.id == 2">
                            <h3>CNPJ (Opcional)<span style="color:red;"></span></h3>
                            <input maxlength="50" id="campo_cpf" type="text" v-model="cnpj" data-cy="modalfake-cpf" placeholder="Digite o CNPJ sem espaços e pontos" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <!-- <div style="padding-bottom:10px;">
                            <h3>Senha <span style="color:red;">(sem espaços)</span></h3>
                            <input maxlength="50" type="password" v-model="senha" data-cy="modalfake-senha" placeholder="Digite o nome de senha sem espaços" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            <a @click="gerarSenha">Gerar senha automaticamente</a>
                        </div>


                        <div style="padding-bottom:10px;">
                            <h3>Confirmar Senha <span style="color:red;">(sem espaços)</span></h3>
                            <input maxlength="50" type="password" v-model="senha1" data-cy="modalfake-senha1" placeholder="Digite a senha sem espaços" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div> -->
                       
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn 
                            v-if="tipoIntegracao == 'HITS'"
                            color="primary" 
                            @click="criarHospedeFake()" 
                            data-cy="modalfake-criarHospedeFake">
                            CRIAR HOSPEDE PASSANTE
                        </v-btn>
                        <v-btn 
                            v-if="tipoIntegracao == 'ADMH'"
                            color="primary" 
                            @click="criarHospedeFake()" 
                            data-cy="modalfake-criarHospedeFake">
                            CRIAR CONTA AVULSA
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>
    </v-dialog>
</template> 

<script>
// import dayjs from 'dayjs'

// import BotaoTutorial from '../BotaoTutorial.vue';

  export default {
    name: "ModalCheckinFake",
    props: ["exibirModalCheckinFake"],
    data: () => ({
        nome: "",
        email: "",
        cpf: "",
        senha: "",
        senha1: "",
        tipoIntegracao: "",
        tipoDocumentoselecionado:{id:1,nome:'CPF'},
        listaDocumentos:[
            {id:1,nome:'CPF'},
            {id:2,nome:'CNPJ'},
        ],
    }),
    components: {
        // BotaoTutorial
    },
    mounted() {
        this.tipoIntegracao = localStorage.getItem("tipo_integracao");
    },
    methods: {
        setClearDoc(){
            if(this.tipoDocumentoselecionado.id == 1){this.cnpj = null}
            if(this.tipoDocumentoselecionado.id == 2){this.cpf = null}
        },
        gerarSenha() {
            this.senha = Math.floor(1000 + Math.random() * 9000);
            this.senha1 = this.senha;
        },
        direcionar() {
            this.$router.push(this.url);
        },
        fechar() {
            this.$emit("fechar");
        },
        validarDados(obj) {
            if (!obj.nome) {
                this.$alertar("warning", "Nome não Informado!", "=(");
                return false;
            }
            // if (!obj.email) {
            //     this.$alertar("warning", "Email não Informado!", "=(");
            //     return false;
            // }
            // if (!obj.cpf) {
            //     this.$alertar("warning", "Cpf não Informado!", "=(");
            //     //validar numero cpf
            //     return false;
            // }
            // if(isNaN(Number(obj.cpf))){
            //     this.$alertar("warning", "Cpf deve conter apenas números!", "=(");
            //     return false;
            // }
            // if(obj.cpf.length !== 11){
            //     this.$alertar("warning", "Cpf deve conter 11 números!", "=(");
            //     return false;
            // }
            // if (!obj.senha) {
            //     this.$alertar("warning", "Senha não Informada!", "=(");
            //     return false;
            // }
            // if (!obj.senha1) {
            //     this.$alertar("warning", "Senha de confirmação não Informada!", "=(");
            //     return false;
            // }
            // if (obj.senha !== obj.senha1) {
            //     this.$alertar("warning", "As senhas informadas devem ser iguais.", "=(");
            //     return false;
            // }
            return true;
        },
        criarHospedeFake() {

            const senha = this.gerarSenhaAleatoria();

            const obj = {
                nome: this.nome,
                senha: senha,
            };

            if(this.email) {
                obj.email = this.email;
            }

            if(this.cpf) {
                obj.cpf = this.cpf;
            }

            if(this.cnpj) {
                obj.cnpj = this.cnpj;
            }

            if (this.validarDados(obj)) {
                this.$http.post(`hospedes/criarhospedefake`, obj).then(() => {
                    this.$alertar("success", "Hospede Criado Com sucesso!", "=)");
                    this.$emit("atualizarLista");
                }).catch((error) => {
                    this.$alertar("warning", JSON.stringify(error.response.data.message), "=(");
                });
            }
        },
        gerarSenhaAleatoria() {
             let senha = Math.random().toString(36).slice(-8);
            return senha;
        },
    },
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }

  .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid rgb(156, 156, 156);
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }

    .listaBotaoPerfil{
        height:100px;
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .botaoPerfilNaoSelecionado{
        background-color: #8a8a8a;
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .botaoPerfilSelecionado{
        background-color: rgb(7, 156, 7);
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

