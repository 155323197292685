<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="Dúvidas Assinaturas" urlVideo="https://drive.google.com/file/d/19-WMmNsHUc7VXeYse0oQk9xJXeiaB3P2/preview"/>
    </div>
        
        <v-card-text>
                      
                <div style="height:42vh;max-height:10vh;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="config.assinaturaobrigatoria" data-cy="config.assinaturaobrigatoria"> </v-switch>
                        <label style="font-size:18px;">É obrigatória a assinatura do <span style="font-weight: bolder;">Hóspede</span> ao fechar comanda?</label>
                    </div>
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="config.assinaturaobrigatoriapassante" data-cy="config.assinaturaobrigatoria"> </v-switch>
                        <label style="font-size:18px;">É obrigatória a assinatura do <span style="font-weight: bolder;">Hóspede PASSANTE</span> ao fechar a comanda?</label>
                    </div>
                </div>

               
                <div style="height:42vh;max-height:10vh;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <!-- <v-switch v-model="config.assinaturaQrCode" data-cy="config.assinaturaobrigatoria"> </v-switch>
                        <label style="font-size:18px;">Assinatura automatica via QR Code?</label> -->
                    </div>
                </div>

                <div style="height:50px;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <div style="width:30%;display:flex;align-items:center;">
                            <label style="font-size:18px;">Modelo de Assinatura</label>
                        </div>
                        <div style="width:70%;">
                            <v-select  v-model="modeloAssinaturaSelecionado" :items="listaDeModelosAssinatura" 
                                item-text="nome" item-value="id" return-object dense outlined 
                                style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                            </v-select>
                           
                        </div>
                    </div>
                </div>
                

               
                
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>
        
        </v-card-text>

    </v-card>
</template>

<script>
import BotaoTutorial from '../layout/BotaoTutorial.vue'
  
  export default {
    name: 'Assinatura Config',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        config:{
            assinaturaobrigatoria:true,
            assinaturaQrCode:false,
            assinaturaobrigatoriapassante: false,
        },
        modeloAssinaturaSelecionado:{id:1,nome:"Assinatura Digital"},
        listaDeModelosAssinatura:[
            {id:1,nome:"Assinatura Digital"},
            {id:2,nome:"QR CODE"},
            {id:3,nome:"RFID"},
        ]
    }),
    methods:{
        salvar(){

            // let obj = {
            //     tela:'fluxo',
            //     parametro:{
            //         chave:'FLUXOPEDIDOS',
            //         valor:this.config.assinaturaobrigatoria ? 'true' : 'false'
            //     }
            // }

            let parametro = {
                    chave:'OBRIGATORIOASSINATURA',
                    valor:this.config.assinaturaobrigatoria ? 'true' : 'false',
                    valor2:this.modeloAssinaturaSelecionado.id,
                }

            this.$http.put('pdv/updateparametros',parametro)
            .then(() =>{
                
                this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
               
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })

            let parametroPassante = {
                    chave:'OBRIGATORIOASSINATURAPASSANTE',
                    valor:this.config.assinaturaobrigatoriapassante ? 'true' : 'false',
                }

            
            this.$http.put('pdv/updateparametros',parametroPassante)
            .then(() =>{
                
                // this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
               
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })
            
            // this.$emit('salvar',obj)
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'OBRIGATORIOASSINATURA'})
            .then(resp =>{
                this.config.assinaturaobrigatoria = resp.data[0].valor === 'true'? true : false
                this.config.assinaturaQrCode = resp.data[0].valor2 === 'true'? true : false
                if(resp.data[0].valor2 == 1){
                    this.modeloAssinaturaSelecionado = {id:1,nome:"Assinatura Digital"}
                }
                if(resp.data[0].valor2 == 2){
                    this.modeloAssinaturaSelecionado =  {id:2,nome:"QR CODE"}
                }
                if(resp.data[0].valor2 == 3){
                    this.modeloAssinaturaSelecionado =  {id:3,nome:"RFID"}
                }

            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'OBRIGATORIOASSINATURAPASSANTE'})
            .then(resp =>{
                this.config.assinaturaobrigatoriapassante = resp.data[0].valor === 'true'? true : false
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>