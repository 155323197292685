<template>

    <div class="ddpms">
        <span style="font-weight:bold;color:#036097;">ITENS EXIBIDOS NO CARDÁPIO DIGITAL</span>
        <v-card  elevation="2" outlined  style="padding-bottom:0px!important;height:19%;max-height:110px;padding:1px;" >
            <!-- <BotaoTutorial style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;" categoria="14" legenda="" urlVideo="" /> -->
            <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
            <v-card-text class="" style="margin-top:0px;margin-bottom:0px;padding: 2px;">
                <div style="height:70px;display:flex;">

                    <div style="width:27%;margin-left:3%;">
                        
                        <label for="">PDV</label>
                        <select v-model="idpdvcd" data-cy="idpdvcd" class="select" @change="getCategoriasCD()">
                            <option :style="!pdv.ativo ? 'color:red': '' " v-for="pdv in listaPDVsCD"  :key="pdv.idpdv" :value="pdv.idpdv" >
                                {{pdv.descpdv +(!pdv.ativo ? ' [PAUSADO]':'' )}}
                            </option>
                            
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                            <a style="text-decoration: underline;" @click="setModalEdicaoPDV()" data-cy="setModalEdicaoPDV" >Editar</a>
                            <a style="text-decoration: underline;" @click="setModalCriarPDV()" data-cy="setModalCriarPDV" >Criar</a>
                        </div>

                    </div>

                    <div style="width:27%;margin-left:3.5%;">
                        
                        <label for="">Categoria</label>
                        <select v-model="idcategoriacd" data-cy="idcategoriacd" class="select" @change="getSubCategoriasCD()">
                            <option :style="!categoria.ativo ? 'color:red': '' " v-for="categoria in listaCategoriasCD"  :key="categoria.idcategoriaprodcd" :value="categoria.idcategoriaprodcd" >
                                {{categoria.nomecategoriaprodcd +(!categoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                            <a style="text-decoration: underline;" @click="setModalEdicaoCategoria()" data-cy="setModalEdicaoCategoria" >Editar</a>
                            <a style="text-decoration: underline;" @click="setModalCriarCategoria()" data-cy="setModalCriarCategoria" >Criar</a>
                        </div>
                        
                    </div>

                    <div style="width:27%;margin-left:3%;">
                        
                        <label for="">Sub Categoria</label>
                        <select v-model="idsubcategoriacd" data-cy="idsubcategoriacd" class="select" @change="getProdutosCD()">
                            <option  value="0" >
                               -
                            </option>
                            <option :style="!subcategoria.ativo ? 'color:red': '' " v-for="subcategoria in listaSubCategoriasCD"  :key="subcategoria.idsubcategoriaprodcd" :value="subcategoria.idsubcategoriaprodcd" >
                                {{subcategoria.nomesubcategoriaprodcd +(!subcategoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div>
                            <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                                <a style="text-decoration: underline;" @click="setModalEdicaoSubCategoria()" data-cy="setModalEdicaoSubCategoria" >Editar</a>
                                <a style="text-decoration: underline;" @click="setModalCriarSubCategoria()" data-cy="setModalCriarSubCategoria" >Criar</a>
                            </div>
                        </div>

                    </div>

                    <div style="width:2%;margin-left:1%;align-self: center">
                        
                        <v-btn @click="() => logs.exibir = true"><v-icon style="color:black;">mdi-eye</v-icon></v-btn>

                    </div>

                    
                <!-- <label for="">Categorias</label>
                <select v-model="statuscomanda" class="select">
                    <option value=2 >Pedido Solicitado</option>
                    <option value=3 >Pedido em Produção</option>
                    <option value=4 >Pedido Pronto</option>
                    <option value=5 >Pedido Entregue</option>
                    <option value=6 >Pedido Cancelado</option>
                </select> -->
                </div>
            </v-card-text>
            
        </v-card>

        <v-card  elevation="2" outlined  class="mt-2" height="80.5%" style="max-height:65vh;">
            <!-- <v-card-text style="pb-0 mb-0">
                Categorias
            </v-card-text> -->
            <v-card-text class="">
                <div>
                 <a @click="edicaoemmassa=true" v-if="!edicaoemmassa">Edição Rapida de PDV x Preço</a>
                 <a @click="edicaoemmassa=false" style="margin-right:50px;" v-else>cancelar</a>
        
                 <v-btn small elevation="" color="info" v-if="edicaoemmassa" @click="vincular()">Vincular Marcados ao Preços da ESQUERDA</v-btn>
                 </div>
                 <!-- {{listaItensProdutoPorPDVPMS}} -->
                    
                <div style="width:100%;display:flex; flex-wrap:wrap;height:55vh;max-height:55vh;overflow:auto;align-items:flex-start;justity-content:flex-start;align-content:flex-start;padding-bottom:10px;" data-cy="produtos-adicionados">
                       
                    <v-card data-cy="produto-adicionado" v-for="prod in listaProdutosCD" :key="prod.idproduto" elevation="2" outlined  class="mt-2 ml-6" height="150px" width="28%"
                    style="padding:0px;display:flex;align-items:flex-start;padding-top:10px;padding-left:10px;word-break: break-all;">
                        <v-card-text style="color:#5a5858;font-size:12px;padding:0px;padding-left:5px;padding-right:5px;font-weight:bold;">
                            
                            <v-card-text style="text-align:end;font-size:12px;padding:0px;padding-left:0px;padding-right:5px;">
                                <div style="display:flex;justify-content:space-between;align-items:flex-end;">
                                    
                                    <div ><a @click="setModalEdicaoProduto(prod)" data-cy="setModalEdicaoProduto">Editar</a></div>
                                    <div v-if="edicaoemmassa">
                                        <v-checkbox v-if="prod.match" label="" v-model="prod.atualizarPreco" style="height:30px;margin-top:0px;" ></v-checkbox>
                                    </div>
                                    <div v-else>
                                        <v-icon color="error" data-cy="removerProduto" @click="removerProduto(prod)">
                                            mdi-close-box
                                        </v-icon>
                                    </div>
                                </div>
                                
                            </v-card-text>
                            <div v-if="prod.nomedepartamento">
                                ({{prod.nomedepartamento}})
                            </div>
                            <div>
                                {{prod.nomecd}}<v-spacer></v-spacer>
                                {{prod.precoimportado | formatarMoeda}}
                            </div>

                            <div style="display:flex;align-items:flex-end;justify-content:flex-end;">
                                Ordem Visual:
                                <input type="text" @change="atualizarProduto(prod)" v-model="prod.ordem_visualizacao" style="width:35%;margin-left:10px;height:20px;background-color:white;border:1px solid #bdbaba;border-radius:10px;text-align:center;" />
                            </div>
                             
                        </v-card-text>

                       
                        
                    </v-card>
                    
                </div>
            </v-card-text>
            
        </v-card>

        <ModalEdicaoPDV v-if="exibirModalEdicaoPDV" :exibirModalEdicaoPDV="exibirModalEdicaoPDV"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            @atualizarLista="()=>{ getPDVSCD() }"
            @fechar="()=>setModalEdicaoPDV()" 
            @atualizarAposDuplicar="() => getCategoriasCD()"
            />

        <ModalCriarPDV v-if="exibirModalCriarPDV" :exibirModalCriarPDV="exibirModalCriarPDV"
            :idpdvcd="idpdvcd"
            @atualizarLista="getPDVSCD"
            @fechar="()=>setModalCriarPDV()" 
            @selecionaUltimoPdvCriado="selecionaUltimoPdvCriado" />



        <ModalEdicaoCategoria v-if="exibirModalEdicaoCategoria" :exibirModalEdicaoCategoria="exibirModalEdicaoCategoria"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            @atualizarLista="()=>{ getCategoriasCD() }"
            @fechar="()=>setModalEdicaoCategoria()" />

        <ModalCriarCategoria v-if="exibirModalCriarCategoria" :exibirModalCriarCategoria="exibirModalCriarCategoria"
            :idpdvcd="idpdvcd"
            @atualizarLista="()=>{ getCategoriasCD() }"
            @fechar="()=>setModalCriarCategoria()" />


        <ModalEdicaoSubCategoria v-if="exibirModalEdicaoSubCategoria" :exibirModalEdicaoSubCategoria="exibirModalEdicaoSubCategoria"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            :idsubcategoriacd="idsubcategoriacd"
            @atualizarLista="()=>{ getCategoriasCD() }"
            @fechar="()=>setModalEdicaoSubCategoria()" />

        <ModalCriarSubCategoria v-if="exibirModalCriarSubCategoria" :exibirModalCriarSubCategoria="exibirModalCriarSubCategoria"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            @atualizarLista="()=>{ getCategoriasCD() }"
            @fechar="()=>setModalCriarSubCategoria()" />

        <ModalEditarProduto v-if="exibirModalEdicaoProduto" :exibirModalEdicaoProduto="exibirModalEdicaoProduto"
            :produto="produtoclicadoedicao"
             @atualizarLista="()=>{ getCategoriasCD() }"
             @fechar="()=>setModalEdicaoProduto()"
        />

        <ModalLogsMovimentacao 
            :logs="logs.data"
            :exibirModalLogs="logs.exibir"
            @fechar="() => logs.exibir = false"
        />

    </div>
  <!--
       <div class="ddpms">
       <select v-model="statuscomanda" class="select">
            <option value=2 >Pedido Solicitado</option>
            <option value=3 >Pedido em Produção</option>
            <option value=4 >Pedido Pronto</option>
            <option value=5 >Pedido Entregue</option>
            <option value=6 >Pedido Cancelado</option>
        </select>
     <div >
          tes
       
    </div>
    
  </div> -->
</template>

<script>

import ModalEdicaoPDV from '../layout/Modais/ModalEdicaoPDV.vue'
import ModalCriarPDV from '../layout/Modais/ModalCriarPDV.vue'
import ModalEdicaoCategoria from '../layout/Modais/ModalEdicaoCategoria.vue'
import ModalCriarCategoria from '../layout/Modais/ModalCriarCategoria.vue'
import ModalEdicaoSubCategoria from '../layout/Modais/ModalEdicaoSubCategoria.vue'
import ModalCriarSubCategoria from '../layout/Modais/ModalCriarSubCategoria.vue'
import ModalEditarProduto from '../layout/Modais/ModalEditarProduto.vue'
import ModalLogsMovimentacao from '../layout/Modais/ModalLogMovimentacao.vue'
// import BotaoTutorial from '../layout/BotaoTutorial.vue'

export default {
    name: 'Coluna CD',
    props:["url","atualizar","listaItensProdutoPorPDVPMS"],
    components:{
    ModalEdicaoPDV,
    ModalCriarPDV,
    ModalEdicaoCategoria,
    ModalCriarCategoria,
    ModalEdicaoSubCategoria,
    ModalCriarSubCategoria,
    ModalEditarProduto,
    ModalLogsMovimentacao
    // BotaoTutorial
},
    data: () => ({
        edicaoemmassa:false,
        listaPDVsCD:[],
        idpdvcd:null,
        listaCategoriasCD:[],
        idcategoriacd:null,
        listaSubCategoriasCD:[],
        idsubcategoriacd:null,
        listaProdutosCD:[],
        idprodutocd:null,
        exibirModalEdicaoPDV:false,
        exibirModalCriarPDV:false,
        exibirModalEdicaoCategoria:false,
        exibirModalCriarCategoria:false,
        exibirModalEdicaoSubCategoria:false,
        exibirModalCriarSubCategoria:false,
        exibirModalEdicaoProduto:false,
        idprodutoclicado:null,
        produtoclicadoedicao:null,
        logs: {
            exibir: false,
            data: []
        },
    }),
    async mounted(){
        await this.getPDVSCD()
        
    },
    watch:{
        atualizar(valor){
            console.log(valor)
            this.getProdutosCD()
        },
        listaItensProdutoPorPDVPMS(listaPMS){
                this.match(listaPMS)
        }
    },
    methods:{
        atualizarProduto(produto){
            let obj = {
                idcategoriaprodcdprodutos:produto.idcategoriaprodcdprodutos,
                ordem_visualizacao:produto.ordem_visualizacao
            }
       
            this.$http.post(`pdv/altercategoriaprodcdprodutos`,obj).then(() =>{
                this.getProdutosCD()
            }).catch((error) =>{
                this.$alertar("warning","Não foi possivel atualizar o Produto","=(")
                console.log(error)
            })

        },
        async vincular(){
            for(let i=0,len=this.listaProdutosCD.length;i <len; i++){
                let prod = this.listaProdutosCD[i]
                if(prod.atualizarPreco && prod.match){
                  

                    //removeproduto
                    let objremover = {
                        idcategoriaprodcd: prod.idcategoriaprodcd,
                        idsubcategoriaprodcd: prod.idsubcategoriaprodcd?prod.idsubcategoriaprodcd:0,
                        idproduto: prod.idproduto
                    }
                    
                    await this.$http.post('pdv/removerProdutoAoCD', objremover ).then(() =>{
                        console.log("ok");
                    }).catch(() =>{
                        this.$alertar('warning',"Não foi Possível Adicionar o Produto ao Cardápio Digital!",'=(')
                    })

                    //adicionar
                    let objinserir = {
                        idcategoriaprodcd: prod.idcategoriaprodcd,
                        idsubcategoriaprodcd: prod.idsubcategoriaprodcd?prod.idsubcategoriaprodcd:0,
                        idproduto: prod.idproduto,
                        idpdvproduto:prod.idpdvproduto
                    };
                    

                    await this.$http.post("pdv/addProdutoAoCD", objinserir)
                        .then(() => { console.log("ok");  })
                        .catch(() => { console.log("ERRO") });

                    
                }
            }
            this.$emit('atualizardados') 
        },
        match(listaPMS){
            let indice
                for(let i=0,len = this.listaProdutosCD.length; i < len; i++){
                    this.listaProdutosCD[i].match = false
                    this.listaProdutosCD[i].atualizarPreco = false
                }
              
                try {
                    
                
                    for(let i=0,len = listaPMS.length; i < len; i++){
                        let produtoPMS = listaPMS[i]
                        indice = this.listaProdutosCD.findIndex(x=>{
                            return x.idproduto == produtoPMS.idproduto
                        })
                        
                        if(indice > 0){
                            this.listaProdutosCD[indice].match = true
                            this.listaProdutosCD[indice].idpdvproduto = produtoPMS.idpdvproduto
                        }
                    }
               
                // if(this.edicaoemmassa){
                    // this.listaProdutosCD = this.listaProdutosCD.sort((a,b)=>{
                    //     return (b.match?1:0) -(a.match?1:0)
                    // })
                 
                // }
                } catch (error) {
                    console.log(error)
                    
                }
        },
        removerProduto(produto){

            this.idprodutoclicado = produto.idproduto

            let obj = {
                idcategoriaprodcd: this.idcategoriacd,
                idsubcategoriaprodcd: this.idsubcategoriacd,
                idproduto: this.idprodutoclicado
            }

            this.$http.post('pdv/removerProdutoAoCD', obj ).then(() =>{
                console.log("te")
                // this.getProdutosPMS()
                this.$emit('atualizardados') 
            }).catch(() =>{
                this.$alertar('warning',"Não foi Possível Adicionar o Produto ao Cardápio Digital!",'=(')
            })
        },
        setModalEdicaoPDV(){
            this.exibirModalEdicaoPDV = !this.exibirModalEdicaoPDV
            // this.getCategoriasCD()
        },
        setModalCriarPDV(){
            this.exibirModalCriarPDV = !this.exibirModalCriarPDV
            // this.getCategoriasCD()
        },
        setModalEdicaoCategoria(){
            this.exibirModalEdicaoCategoria = !this.exibirModalEdicaoCategoria
            // this.getCategoriasCD()
        },
        setModalCriarCategoria(){
            this.exibirModalCriarCategoria = !this.exibirModalCriarCategoria
            // this.getCategoriasCD()
        },
        setModalEdicaoSubCategoria(){
            this.exibirModalEdicaoSubCategoria = !this.exibirModalEdicaoSubCategoria
            // this.getCategoriasCD()
        },
        setModalCriarSubCategoria(){
            this.exibirModalCriarSubCategoria = !this.exibirModalCriarSubCategoria
            // this.getCategoriasCD()
        },
        setModalEdicaoProduto(produto){
            this.produtoclicadoedicao = produto
            this.exibirModalEdicaoProduto = !this.exibirModalEdicaoProduto
            // this.getCategoriasCD()
        },
        direcionar(){
            this.$router.push(this.url)
        },
        getPDVSCD(ultimoPdvCriado){
            this.$http('pdv/getPDVSCD/0').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listaPDVsCD = resp.data 
                    if(ultimoPdvCriado) {
                        this.listaPDVsCD.find(item => {
                            if(item.descpdv == ultimoPdvCriado) {
                                this.idpdvcd = item.idpdv
                            }
                        })
                    } else {
                        this.idpdvcd = this.listaPDVsCD[0].idpdv
                    }
                    await this.getCategoriasCD()
                }
            }).catch((error)=>{

                // this.$alertar('warning','Não foi possivel listar os PDVs do PMS','=(')
                this.$alertar('warning',error,'=(')
            })
        },
        getCategoriasCD(){
            this.listaCategoriasCD = []
            this.$http('pdv/getCategorias/'+this.idpdvcd+'/0').then((resp) =>{
                if(resp.data && resp.data.length > 0){
                    this.listaCategoriasCD = resp.data
                    this.idcategoriacd = this.listaCategoriasCD[0].idcategoriaprodcd
                    this.getSubCategoriasCD()
                }
            }).catch(error =>{
                console.log(error)
                // this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
            })

            // this.$http(`pdv/getCategoriasCD/${this.idpdvcd}/0`).then(async resp =>{
            //     if(resp.data && resp.data.length > 0){
            //         this.listaCategoriasCD = resp.data
            //         this.idcategoriacd = this.listaCategoriasCD[0].idcategoriaprod
            //         await this.getProdutosCD()
            //     }
            // }).catch(()=>{
            //     this.$alertar('warning','Não foi possivel listar as Categorias do PMS','=(')
            // })

        },
        getSubCategoriasCD(){
            localStorage.removeItem('idsubcatcd')
            this.idsubcategoriacd = 0
            this.listaSubCategoriasCD = []
            this.listaProdutosCD = []
            this.$http('pdv/getSubCategorias/'+this.idcategoriacd+'/0').then((resp) =>{
                this.listaSubCategoriasCD = resp.data
                if(this.listaSubCategoriasCD.length > 0){
                    this.idsubcategoriacd = this.listaSubCategoriasCD[0].idsubcategoriaprodcd
                }
                this.getProdutosCD()
            }).catch(error =>{
                console.log(error)
                // this.$alertar("warning","Não foi possivel exibir as Sub Categorias","=(")
            })
        },
        getProdutosCD(){
            this.listaProdutosCD = []
            
            localStorage.setItem('idcatcd', this.idcategoriacd)
            localStorage.setItem('idsubcatcd', this.idsubcategoriacd)

            this.$http('pdv/getProdutos/'+this.idcategoriacd+'/'+this.idsubcategoriacd).then((resp) =>{
              this.listaProdutosCD = resp.data
              this.match(this.listaItensProdutoPorPDVPMS)
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os Produtos","=(")
            })
        },
        editarProduto(prod){
            alert(JSON.stringify(prod))
        },
        selecionaUltimoPdvCriado(pdv) {
            this.listaPDVsCD.find((item) => {
                console.log(item.descpdv)
                console.log(pdv)
                console.log(item.descpdv == pdv)
                if (item.descpdv == pdv) {
                    this.idpdvcd = item.idpdv
                }
            })

            // this.listaPDVsCD = resp.data 
            // this.idpdvcd = this.listaPDVsCD[0].idpdv
            // this.idpdvcd = pdv
        
        }
    }
  }
</script>

<style scoped>
    .ddpms{
        /* background-color: white; */
        width:63%;
        margin-left:2%;
        height:100%!important;
    }

    .ddpmsprod{
        
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }

</style>
