<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="Dúvidas Publicidade" urlVideo="https://drive.google.com/file/d/1e6VKNSgNWgNUmUPMZ-gU6fw2YJEv2r-i/preview" />
    </div>
        <v-card-text>
                      
                <div style="height:42vh;max-height:10vh;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="exibirpublicidade"> </v-switch>
                        <label style="font-size:18px;">Exibir Publicidade?</label>
                    </div>
                </div>

                <div style="height:30vh;max-height:30vh;margin-top:20px;margin-bottom:30px;display:flex;">

                    <v-flex xs12>
                        <input id="file-upload1" data-cy="file-upload1" type="file" style="display:none;" accept="image/*"  @change="onFileChange1" />
                        <v-avatar slot="offset" class="mx-auto d-block" size="130">
                        <v-icon v-if="!imgSrc1" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                        <img v-if="imgSrc1" :src="'data:' + imgTipo + ';base64,' + imgSrc1" class="imgUpload" />
                        </v-avatar>
                        <div style="display:flex;justify-content:center;margin-top:20px;">
                            <label for="file-upload1" >
                                <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                Foto 1
                                </div>
                            </label>
                        </div>
                    </v-flex>
                    
                    <v-flex xs12>
                        <input id="file-upload2" data-cy="file-upload2" type="file" style="display:none;" accept="image/*"  @change="onFileChange2" />
                        <v-avatar slot="offset" class="mx-auto d-block" size="130">
                        <v-icon v-if="!imgSrc2" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                        <img v-if="imgSrc2" :src="'data:' + imgTipo + ';base64,' + imgSrc2" class="imgUpload" />
                        </v-avatar>
                        <div style="display:flex;justify-content:center;margin-top:20px;">
                            <label for="file-upload2" >
                                <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                Foto 2
                                </div>
                            </label>
                        </div>
                    </v-flex>

                    <v-flex xs12>
                        <input id="file-upload3" data-cy="file-upload3" type="file" style="display:none;" accept="image/*"  @change="onFileChange3" />
                        <v-avatar slot="offset" class="mx-auto d-block" size="130">
                        <v-icon v-if="!imgSrc3" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                        <img v-if="imgSrc3" :src="'data:' + imgTipo + ';base64,' + imgSrc3" class="imgUpload" />
                        </v-avatar>
                        <div style="display:flex;justify-content:center;margin-top:20px;">
                            <label for="file-upload3" >
                                <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                Foto 3
                                </div>
                            </label>
                        </div>
                    </v-flex>

                </div>

               
                
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>
        
        </v-card-text>

    </v-card>
</template>

<script>
  
  import {serializarImagem} from '../../services/serializar'
  import BotaoTutorial from '../layout/BotaoTutorial.vue';

  export default {
    name: 'Publicidade Config',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        config:{
            assinaturaobrigatoria:true,
        },
        imgSrc1:null,
        imgSrc2:null,
        imgSrc3:null,
        exibirpublicidade:true,
    }),
    methods:{
        async salvaimg(tipo,tipoImg) {

            if (tipo == 1 && !this.imgSrc1.length > 0) { console.log("invalido"); return; }
            if (tipo == 2 && !this.imgSrc2.length > 0) { console.log("invalido"); return; }
            if (tipo == 3 && !this.imgSrc3.length > 0) { console.log("invalido"); return; }

            let dados = {
                "tipo" : tipoImg,
                "nome" : "imgPublicidade",
                "dados" : tipo == 1 ? this.imgSrc1 : (tipo  == 2 ? this.imgSrc2 : this.imgSrc3),
                "tabela": "parametros",
                "campo": tipo == 1 ? 'valor' : (tipo  == 2 ? 'valor2' : 'valor3'),
                "chave": "chave",
                "valor": 'IMAGENSPUBLICIDADE'
            }
            await this.$http.post("pdv/imagemupdate",dados,{timeout:60000 })
                .then((response) => {
                if(response.status == 200){
                    
                    if(tipo == 1) { this.imgSrc1 = response.data.result.imagem[0] }
                    if(tipo == 2) { this.imgSrc2 = response.data.result.imagem[0] }
                    if(tipo == 3) { this.imgSrc3 = response.data.result.imagem[0] }
                    
                }else{
                    console.log(JSON.stringify(response))
                }
                })
                .catch((error) => {
                console.log(error.response);
            });
        },
        onFileChange1(e) {
            // this.setImage(e)
           
            serializarImagem(e, 0.8).then(resp =>{
                
                this.imgSrc1 = resp.dados
                // this.salvaimg(1,resp.tipo);
                let parametro = {
                    chave:'IMAGENSPUBLICIDADE',
                    valor:this.imgSrc1
                }

                this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })

            }).catch(error =>{
              
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
        },
        onFileChange2(e) {
            // this.setImage(e)
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc2 = resp.dados
                // this.salvaimg(2,resp.tipo);
                let parametro = {
                    chave:'IMAGENSPUBLICIDADE',
                    valor2:this.imgSrc2
                }

                this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
        },
        onFileChange3(e) {
            // this.setImage(e)
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc3 = resp.dados
                // this.salvaimg(3,resp.tipo);
                let parametro = {
                    chave:'IMAGENSPUBLICIDADE',
                    valor3:this.imgSrc3
                }

                this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
        },
        salvar(){

            // let obj = {
            //     tela:'fluxo',
            //     parametro:{
            //         chave:'FLUXOPEDIDOS',
            //         valor:this.config.assinaturaobrigatoria ? 'true' : 'false'
            //     }
            // }

            let parametro = {
                    chave:'EXIBIRPUBLICIDADE',
                    valor:this.exibirpublicidade ? 'true' : 'false'
                }

            this.$http.put('pdv/updateparametros',parametro)
            .then(() =>{
                this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })
            

        },
        async buscarDados(){
           
            this.$http.post('pdv/buscarparametros',{chave:'IMAGENSPUBLICIDADE'})
            .then(resp =>{
                this.imgSrc1 = resp.data[0].valor 
                this.imgSrc2 = resp.data[0].valor2
                this.imgSrc3 = resp.data[0].valor3
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'EXIBIRPUBLICIDADE'})
            .then(resp =>{
                this.exibirpublicidade = resp.data[0].valor === 'true'? true : false
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>