<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="ojbPedidos.statuscomanda === 'Pedido Baixado'?'Pedido Entregue':ojbPedidos.statuscomanda" :corTexto="corTexto" />
    <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="'Total: R$ '+ojbPedidos.valorTotal.toFixed(2)" :corTexto="corTexto" />
    <div class="categoryList" style="margin-top:10px;margin-bottom:20px;">
      <Pedido v-for="pedido in ojbPedidos.listadepedidos" :key="pedido.numpedido" 
        :pedido="pedido"  :corTexto="corTexto"
        @retirarPedido="(produto)=>retirarPedido(produto)"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  import Pedido from './Pedido.vue'
  export default {
    name: 'PedidosLista',
    props:['ojbPedidos','statuscomanda','corTexto'],
    components:{
      // TitleH1,
      Pedido,
      Subtitulo,
    },
    data: () => ({
      // listadeprodutos:[]
    }),

    methods:{

      retirarPedido(produto){
        this.$emit('retirarPedido',produto)
      }

    },

    mounted(){
      // this.getProdutos()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
    }
</style>
