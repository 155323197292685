<template>

    <div class="d-flex align-center description">
        <p>{{text}}</p>
    </div>

</template>

<script>
export default {
    props:["text"]
}
</script>

<style scoped>
    p{
        font-size:1em;
        color:rgb(114, 114, 114);
    }

    .description{
        font-size:1.3em;
        color:rgb(70, 70, 70);
        margin-bottom: 2%;
    }
</style>