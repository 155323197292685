<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;" >
        <v-card-text style="min-height:10%;max-height:10%;margin-bottom:50px;">
            <div style="witdh:100%;display:flex;">
                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <label for="pesquisanome" style="font-size:14px;font-weight:bold;">Garçom</label>
                    <input id="pesquisanome" v-model="pesquisanome" data-cy="pesquisanome" maxlength="50" type="text"  placeholder="Digite o nome do garçom" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>

                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <label for="pesquisaemail" style="font-size:14px;font-weight:bold;">Email</label>
                    <input id="pesquisaemail" v-model="pesquisaemail" data-cy="pesquisaemail" maxlength="50" type="text"  placeholder="Digite o email do garçom" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                </div>

                <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:30%;padding-right:20px;margin-bottom:20px;">
                    <v-btn small elevation="" color="primary"   style="width:60%;margin-top:25px;"                      
                    @click="buscarGarcons()" data-cy="buscarGarcons">Pesquisar</v-btn>
                </div>

            </div>
        </v-card-text>
        <v-card-text style="min-height:80%;max-height:300px;overflow:auto;">

                <div style="margin-top:15px;display:flex;flex-direction:column;border:1px solid #b5b5b5;border-radius:10px;padding:5px;" v-for="garcon in listadegarcons" :key="garcon.id">
                    <div  style="margin-top:5px;display:flex;margin-bottom:10px;">
                        <div style="display:flex;width:30%;">
                            <div><label for="mesainicial" style="font-size:14px;font-weight:bold;">Garçom</label></div>
                            <div style="padding-top:1px;padding-left:10px;" data-cy="garcon.nome">{{garcon.nome}}</div>
                        </div>
                        <div style="width:70%;display:flex;justify-content:flex-end;padding-right:10px;">
                            <div style="display:flex;">
                                <v-switch v-model="garcon.md_receber_room_service" data-cy="garcon.md_receber_room_service" style="height:10px;padding:0px;margin:0px;"> </v-switch>
                                <label style="font-size:18px;">RoomService?</label>
                            </div>
                            <div style="display:flex;margin-left:20px;">
                                <v-switch v-model="garcon.garcomonline" data-cy="garcon.garcomonline" style="height:10px;padding:0px;margin:0px;"> </v-switch>
                                <label style="font-size:18px;">Ativo?</label>
                            </div>
                            <div style="display:flex;margin-left:20px;">
                                <v-switch v-model="garcon.flevento" data-cy="garcon.garcomonline" style="height:10px;padding:0px;margin:0px;"> </v-switch>
                                <label style="font-size:18px;">Em Evento?</label>
                            </div>
                        </div>

                        <!-- <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:15%;padding-right:20px;margin-bottom:20px;">
                            <div style="margin-left:10px;margin-top:5px;width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                                    <v-btn small elevation="" color="primary"   style="width:60%;height:7vh"   data-cy="atualizarUsuario"                   @click="atualizarUsuario(garcon)">SALVAR</v-btn>
                            </div>
                        </div> -->
                    </div>

                    <div style="width:100%;padding-bottom:5px;display:flex;height:40px;">
                        <div style="width:20%;padding:2px;">
                            <v-btn small elevation="" color="success" style="width:100%;height:100%;" data-cy="adicionarMesaGarcom" @click="adicionarMesaGarcom(garcon)">
                                Add Mesas
                            </v-btn>
                        </div>
                        <div style="width:80%;padding:2px;display:flex;justify-content:flex-end;padding-right:10px;">

                            <div style="width:50%;display:flex;justify-content:flex-end;align-items:flex-end;padding-right:10px;font-weight:bold;" v-if="garcon.flevento">
                               Alocado no Evento:
                            </div>
                            <div style="width:50%;" v-if="garcon.flevento">
                                    <v-select style="margin:0px;padding:0px;width:100%;"
                                        :items="listaPdvsLocal"
                                        item-text="descpdv"
                                        item-value="idpdv"
                                        v-model="garcon.idpdvevento"
                                        width="50%">
                                    </v-select>
                            </div>
                            
                        </div>
                            
                    </div>

                    <div  style="margin-top:1px;display:flex;padding:5px;flex-wrap:wrap;" data-cy="wrapper-mesas">

                        

                        <div data-cy="mesa-item" v-for="mesa in garcon.mesas" :key="mesa.id" style="border:1px solid #b5b5b5;display:flex;flex-direction:column;width:20%;border-radius:5px;margin-right:10px;padding:10px;margin-top:10px;">
                            <label for="mesainicial" style="font-size:14px;font-weight:bold;">Intervalo de Mesas</label>
                            <div style="display:flex;width:100%;justify-content:space-between;margin-bottom:10px;">
                                <div style="width:40%">
                                    <input id="mesainicial" v-model="mesa.mesainicial" data-cy="mesa.mesainicial" maxlength="50" type="text"  placeholder="Início" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                </div>
                                <div style="width:20%;text-align:center;">/</div>
                                <div style="width:40%">
                                    <input id="mesainicial" v-model="mesa.mesafinal" data-cy="mesa.mesafinal"  maxlength="50" type="text"  placeholder="Fim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                </div>
                            </div>
                            <a @click="excluirIntervalo(mesa)" data-cy="excluirIntervalo">Excluir</a>
                        </div>

                        
                        
                        
                    </div>

                    <div style="display:flex;justify-content:flex-end;padding-right:10px;">
                        <v-btn small elevation="" color="primary" style="width:30%;height:7vh" data-cy="atualizarUsuario" @click="atualizarUsuario(garcon)">Salvar Configuração</v-btn>
                    </div>
                </div>

                
               

        
                
                  
        
        </v-card-text>

        <ModalConfirmacao :showModal="exibirModalConfirmar" :titulo="'Confima a exclusão?'" 
                    textosim="Confirmar" textonao="Cancelar" @sim="()=>confirmarExclusao()" @nao="()=>{exibirModalConfirmar = false}"   />

    </v-card>
</template>

<script>

    import ModalConfirmacao from '../layout/ModalConfirmacao.vue'

    export default {

        name: 'Mesa Config',
        components: {
            ModalConfirmacao,
        },
        props:[],
        async mounted(){
            this.getPdvsLocal(3)
            this.buscarGarcons()
        },
        watch:{
        
        },
        data: () => ({
            pesquisanome:null,
            pesquisaemail:null,
            mesainicial:1,
            mesafinal:100,
            nome:null,
            email:null,
            listadegarcons:[],
            exibirModalConfirmar:false,
            mesaSelecionada:null,
            listaPdvsLocal:[],
        }),
        methods:{
            getPdvsLocal(local) {
                
                    this.$http('pdv/getPDVSCDLOCAL/'+local).then(async resp =>{

                                if(resp.data && resp.data.length > 0){

                                    let listaRetornado = resp.data 
                                    let listaFiltrada = listaRetornado.filter(x =>{
                                            return x.marcadolocal
                                    })
                                    
                                    this.listaPdvsLocal = listaFiltrada
                                }
                            }).catch((error)=>{
                                this.$alertar('warning',error,'=(')
                            })

            },
            confirmarExclusao(){
                
                this.$http.post(`pdv/excluirIntervaloMesa`,{idintervalo:this.mesaSelecionada.id}).then(() =>{
                        this.$alertar("success","Usuario Atualizado Com sucesso!","=)")
                        this.$emit('atualizarLista')
                        this.exibirModalConfirmar = !this.exibirModalConfirmar
                        this.buscarGarcons()
                    }).catch((error) =>{
                        this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                        this.exibirModalConfirmar = !this.exibirModalConfirmar
                    })
                
            },
            excluirIntervalo(mesa){
                this.mesaSelecionada = mesa
                this.exibirModalConfirmar = !this.exibirModalConfirmar
            },
            adicionarMesaGarcom(garcom){
                if(garcom.mesas){
                    garcom.mesas.push({mesainicial:null,mesafinal:null})
                }else{
                    garcom.mesas = []
                    garcom.mesas.push({mesainicial:null,mesafinal:null})
                }
            },
            buscarGarcons(){
                    let obj = {
                        nome:this.pesquisanome,
                        email:this.pesquisaemail,
                        idperfil:5,
                        filtro: "Todos"
                    }

                    this.$http.post('pdv/buscarusuarios',obj)
                    .then((resp) =>{
                        // this.$alertar("success",JSON.stringify(resp.data), "=)")
                        this.listadegarcons = resp.data
                    }).catch(() =>{
                        this.$alertar("success","Não foi Possível buscar os usuarios!", "=(")
                    })
            },
            atualizarUsuario(garcom){
  
                if((garcom.garcomonline && garcom.mesas) || (!garcom.garcomonline) ){

                    if(garcom.garcomonline){
                        garcom.mesas = garcom.mesas.filter(x =>{
                            return (x.mesainicial && x.mesafinal)
                        })
                    }

                    if(!garcom.flevento){
                        garcom.idpdvevento = null
                    }
                    let obj = {
                        idusuariosistema:garcom.idusuario,
                        nome:garcom.nome,
                        email:garcom.email,
                        nomeusuario:garcom.nomeusuario,
                        usuariosxperfil: 5,
                        md_mesa_inicial:garcom.md_mesa_inicial,
                        md_mesa_final:garcom.md_mesa_final,
                        md_receber_room_service:garcom.md_receber_room_service,
                        garcomonline:garcom.garcomonline,
                        flevento:garcom.flevento,
                        idpdvevento:garcom.idpdvevento,
                        mesas:garcom.mesas
                    }


                    this.$http.post(`pdv/atualizarusuario`,obj).then(() =>{
                        this.$alertar("success","Usuario Atualizado Com sucesso!","=)")
                        this.$emit('atualizarLista')
                    }).catch((error) =>{
                        this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                    })

                }else{
                    this.$alertar("warning", "Não é possivel ativar um registro sem Adicionar uma praça" ,"=(")
                }

            },
            async buscarDados(){
                this.$http.post('pdv/buscarparametros',{chave:'PRACA'})
                .then(resp =>{
                    this.mesainicial = resp.data[0].valor
                    this.mesafinal   = resp.data[0].valor2
                }).catch(error =>{
                    alert(error)
                })

                this.$http.post('pdv/buscarparametros',{chave:'PRACACOMPARTILHADA'})
                .then(resp =>{
                    this.pracacompartilhada = resp.data[0].valor === 'true' ? true:false
                }).catch(error =>{
                    alert(error)
                })

            

            }
        }
    }
</script>

<style scoped>
    
</style>