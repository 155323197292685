<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logodiv" style="background-colo: red">
        <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
      </div>
      <p>
        <span style="color: white">
          Enviaremos um email para recuperação de sua senha. <br />
          We will send you an email to recover your password.
        </span>
      </p>
      <div>
        <v-text-field
          type="email"
          v-model="email"
          :rules="emailRules"
          label="Email"
          dense
          outlined
          clearable
          class="inputtext"
          background-color="white"
          placeholder="Insira seu email"
        ></v-text-field>
        <v-btn
          large
          block
          color="info"
          class="white--text font-weight-bold"
          style="width: 140px"
          @click="alterar()"
        >
          OK
          <v-icon right dark>mdi-pencil</v-icon>
        </v-btn>
        <div style="display: flex; align-items: center">
          <v-switch v-model="usuarioSistema" color="orange"> </v-switch>
          <label style="color: white">Sou Funcionário do Hotel</label>
        </div>

        <v-row justify="center" class="mt-4">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      dominio : window.origin,
      usuarioSistema: false,
      email: "",
      emailRules: [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "Email inválido",
      ],
    };
  },

  components: {},
  methods: {
    async alterar() {
      if(!/.+@.+\..+/.test(this.email) || !this.email.length > 0){
        return
      }
      let body = {
        email: this.email,
        usuariosistema: this.usuarioSistema,
      };
      await this.$http
        .post("/auth/validaremail", body)
        .then(async (response) => {
          console.log(response);
          if (response.status != 200) {
            alert("Email nao encontrado");
            return;
          } else {
            if (this.usuarioSistema) {
              this.alterarPassUsuario();
            } else {
              this.alterarPassCliente();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async alterarPassCliente() {
      let body = {
        email : this.email,
        usuariosistema : this.usuarioSistema
      }
      await this.$http.post('/auth/solicitaalteracaosenha', body).then(response =>{
        if(response.status == 200){
          //Enviar Email aqui
          let bodyMail ={
            url : window.origin+'/formpassrecovery?token='+response.data.token,
            destinatario : this.email,
            assunto : 'Alteracao de Senha / Update Password',
            origin : window.origin
          }
          console.log(bodyMail)
          this.$http.post('/auth/enviaremailrecuperacao', bodyMail).then(response =>{
            if(response.status == 200){
              alert("Solicitada alteracao de senha")
            }
          })
        }
      }).catch(error =>{
        console.log(error)
      })
    },
    async alterarPassUsuario() {
      let body = {
        email : this.email,
        usuariosistema : this.usuarioSistema
      }
      await this.$http.post('/auth/solicitaalteracaosenha', body).then(response =>{
        if(response.status == 200){
          //Enviar Email aqui
          let bodyMail ={
            url : window.origin+'/formpassrecovery?token='+response.data.token+'&us=true',
            destinatario : this.email,
            assunto : 'Alteracao de Senha / Update Password',
            origin : window.origin
          }
          console.log(bodyMail)
          this.$http.post('/auth/enviaremailrecuperacao', bodyMail).then(response =>{
            if(response.status == 200){
              alert("Solicitada alteracao de senha")
            }
          })
        }
      }).catch(error =>{
        console.log(error)
      })
    },
  },
  mounted(){
    console.log(this.dominio)
  }
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
}
</style>