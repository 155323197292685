<template>
  <div>

    <!--        <v-card style="max-width: 100%">-->
    <!--             <v-toolbar color="blue" class="mb-8">-->
    <!--                <v-flex  style="display: flex" class="pa-2">-->
    <!--                    <span style="width:95%;color:white;font-weight:bold;" >-->
    <!--                        Itens-->
    <!--                    </span>-->
    <!--&lt;!&ndash;                    <v-btn small elevation="" style="margin-right:20px;">este top Imprimir</v-btn>&ndash;&gt;-->
    <!--                    <span style="width:5%;">-->
    <!--                        <v-btn x-small color="red" @click="fechar()" title="Fechar">-->
    <!--                            <v-icon x-small style="color:white;">mdi-close</v-icon>-->
    <!--                        </v-btn>-->
    <!--                    </span>-->
    <!--                </v-flex>-->
    <!--            </v-toolbar>-->
    <!--            <v-card-text>-->
    <!--                <v-card style="padding:10px;margin-bottom:10px;">-->
    <!--                  <div>-->
    <!--                    <div>-->
    <!--                      Comanda: {{item.idcomanda}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Local Entrega: {{item.localentrega}} - {{item.numerolocal}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Garcom Responsavel: {{item.nomegarcom}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Preço Unitário: {{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.precounitario)}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Quantidade: {{item.quantidade}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Integrado: {{item.integrado}}-->
    <!--                    </div>-->

    <!--                    <div>-->
    <!--                      Item: {{item.coduh}}-->
    <!--                    </div>-->

    <!--                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">-->
    <!--                         <v-btn color="primary" @click="salvar()">-->
    <!--                           Salvar-->
    <!--                         </v-btn>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </v-card>-->
    <!--            </v-card-text>-->
    <!--        </v-card>-->

    <v-dialog v-model="exibirModalTrocarStatusDoItem" width="1000" max-width="80vw">
      <v-card class="custom-card" outlined>
        <v-toolbar color="primary">
          <div class="content-toolbar-top" style="">
            <v-toolbar-title class="white--text">
              Detalhes do Item
            </v-toolbar-title>
            <v-btn x-small @click="fechar" class="mr-4" style="background: red">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <v-card-text>
          <div class="info-container">
            <v-row>
              <v-col v-for="(value, label) in itemInfo" :key="label" cols="12" md="6">
                <div class="info-item">
                  <strong>{{ label }}:</strong> {{ value }}
                </div>
              </v-col>
              <v-col>
                Status:
                <v-select
                  :items="statusParaTroca"
                  label="Status"
                  v-model="statusPedido"
                  return return-object
                  item-text="label"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="actions-container">
          <v-btn class="custom-button" color="primary" @click="salvar">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'BotaoVoltar',
  props: ["exibirModalTrocarStatusDoItem", "item"],
  data: () => ({
    statusPedido: '',

    statusParaTroca: [],
  }),
  async mounted() {
    await this.listarStatusParaTroca();
    this.statusPedido = this.item.id_status_item;
  },
  computed: {
    itemInfo() {
      return {
        'Comanda': this.item.idcomanda,
        'Local Entrega': `${this.item.localentrega} - ${this.item.numerolocal}`,
        'Garcom Responsável': this.item.nomegarcom,
        'Preço Unitário': this.formatCurrency(this.item.precounitario),
        'Quantidade': this.item.quantidade,
        'Integrado': this.item.integrado,
      };
    }
  },
  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    async salvar() {
      const data = {
        ...this.item,
        idstatusnovo: this.statusPedido.value,
        descstatuscomanda: this.item.status_item,
        id_ultimo_usuario_atualizacao: localStorage.getItem('idusuario'),
        idcomandaproduto: this.item.iditem,
      }

      await this.$http.post('pdv/alterarStatusItem', data)
          .then(() => {
            this.$alertar('success', 'Sucesso', 'Status do item alterado com sucesso');
            this.$emit('gerarRelatorio');
          })
          .catch(err => {
            this.$alertar('warning', 'Atenção', err?.response?.data?.message || 'Erro ao alterar status do item');
          })

      this.$emit('fechar', false);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    async listarStatusParaTroca() {
      this.$http('/pdv/listarStatusParaTroca')
          .then(({data}) => {

            /*
            * 1 - Carrinho
            * 5 - Entregue
            * 7 - Baixado | Fechado
            * 6 - Cancelado
             */
            const notIncludes = [1, 5, 6, 7];

            data = data.filter(status => !notIncludes.includes(status.id));

            this.statusParaTroca = data.map(status => ({
              value: status.id,
              label: status.nome,
            }));
          })
          .catch(err => {
            this.$alertar('warning', 'Atenção', err?.response?.data?.message || 'Erro ao listar status para troca');
            this.$emit('fechar', false);
          })
    },
  }
};
</script>

<style scoped>
.content-toolbar-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center
}

.custom-card {
  margin: auto;
  border-radius: 10px;
}

.custom-button {
  color: #fff;
}

.info-container {
  padding: 20px;
}

.info-item {
  margin-bottom: 10px;
}

.actions-container {
  justify-content: flex-end;
  padding: 20px;
}
</style>
