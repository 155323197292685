<template>
  <div>
    <!-- <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" /> -->
    <BotaoVoltar :url="'/Hospede/Pedidos'" />
    <ListadeProdutosdoPedido
      :listadeprodutos="listadeprodutos"
      :idcategoriaprodcd="$route.params.idcategoriaprodcd"
      :idsubcategoriaprodcd="$route.params.idsubcategoriaprodcd"
      :idpdv="$route.params.idsubcategoriaprodcd"
      @retirarProdutoCarrinho="(produto)=>retirarProdutoCarrinho(produto)"  />



    <div class="shoppingCarAddLine" v-if="statuscomanda == 2">
    <!-- <div class="shoppingCarAddLine" > -->

            <div class="shoppingCarAddLineInner">

                <div class="shoppingCarAddLineAdd">
                    <div class="shoppingCarAddLineAddBtn" @click="cancelarPedido()" data-cy="cancelarPedido">
                        <div>Cancelar</div>
                    </div>
                </div>
            </div>

    </div>

    <ModalConfirmacao :showModal="exibirModalConfirmacaoCancel" :titulo="`Confirmar Cancelamento?`"
            textosim="SIM" textonao="NÃO" @sim="()=>confirmarCancelamento()" @nao="()=>fecharModal()"  />




  </div>
</template>

<script>
  import ListadeProdutosdoPedido from '../../components/pedidos/ListadeProdutosdoPedido.vue'
  import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
  import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
//   import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'

  export default {
    name: 'Produtos do Pedido',
    components: {
      ListadeProdutosdoPedido,
      BotaoVoltar,
      ModalConfirmacao,
    //   ModalConfirmacaoInput,
    },
    data: () => ({
        valor:14.90,
        listadeprodutos:[],
        valorTotal:0.00,
        exibirModalLocal:false,
        exibirModalNumeroLocal:false,
        exibirModalConfirmacao:false,
        exibirModalCartaoConsumo:false,
        tipoLocal:1,
        numeroLocal:localStorage.getItem('coduh'),
        numeroCartaoConsumo:localStorage.getItem('cartaoconsumo'),
        valorinicialmodal:null,
        idcomanda:null,
        statuscomanda:0,
        exibirModalConfirmacaoCancel:false,
    }),
    methods:{
        fecharModal(){
            this.exibirModalConfirmacaoCancel = false
        },
        confirmarCancelamento(){
           this.exibirModalConfirmacaoCancel = false

          const data = {
            id_ultimo_usuario_atualizacao: -1,
          }

           this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.$route.params.idPedido}/6`, data).then(()=>{

            // this.$alertar("success",JSON.stringify(resp.data),"=)")
            this.$alertar("success","Pedido Cancelado!","=)")
            this.$router.push('/Hospede/Pedidos')

          }).catch(()=>{
            this.$alertar("warning","Não foi possível Cancelar o Pedido!","=(")
          })
        },
        cancelarPedido(){

          this.exibirModalConfirmacaoCancel = !this.exibirModalConfirmacaoCancel



        },
        getProdutosdoPedido(){
            this.$http('pdv/getProdutosdoPedido/'+this.$route.params.idPedido+'/t').then((resp) =>{
              this.listadeprodutos = resp.data
              this.valorTotal = 0
              for(var i = 0, len = this.listadeprodutos.length; i < len; i++){
                  this.statuscomanda = this.listadeprodutos[i].idstatuscomanda
                  this.valorTotal += this.listadeprodutos[i].precounitario *this.listadeprodutos[i].quantidade
                  this.idcomanda = this.listadeprodutos[i].idcomanda
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os produtos","=(")
            })
        },
        retirarProdutoCarrinho(produto){

            let objetoproduto = {
                idprodutocomanda:produto.id,
                numpedido:produto.idcomanda,
                id_ultimo_usuario_atualizacao: -1,
            }
            this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then(() =>{
              this.getProdutosdoPedido()
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
            })
        },
        direcionar(){
            this.$router.push('/Hospede/Menu')
        },
        finalizarPedido(){
            this.exibirModalLocal = true
        }
    },
    mounted(){
        this.$store.dispatch("set_titulo", `Pedido Num: ${this.$route.params.idPedido}`)
        this.getProdutosdoPedido()
    }
  }
</script>

<style scoped>
    .shoppingCarAddLine{
        background-color: white;
        /* background-color: red; */
        margin-left:auto;
        margin-right:auto;
        display: flex;
         flex-direction: column;
        align-items: center;
       position: fixed;
        bottom: 0px;
        left:0px;
        right:0px;
        width: 90%;
        height:12vh;
        border-top: 1px solid rgb(156, 156, 156);
    }





    .shoppingCarAddLineInner{
        /* background-color: blue; */
        display: flex;
        align-items: center;
        /* position: fixed;
        bottom: 0px; */
        width: 100%;
        height: 100%;
        /* border-top: 1px solid rgb(156, 156, 156); */
    }

    .shoppingCarAddLineTotal{
        width:30%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

    }

    .shoppingCarAddLineAdd{
        width:100%;
        /* background-color: yellow; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoppingCarAddLineAddBtn{
        /* background-color: #4CAF50; */
        background-color: red;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0px 7%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .shoppingCarAddLineTotalBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 10%;
        align-items: center;
        justify-content: space-between;
    }

    .shoppingCarAddLineTotalBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }
</style>
