<template>
    


        <div style="height:100%;display:flex;">

            <CardapioPMS  :atualizar="atualizarPMS" @atualizardados="() => atualizarDados()" @atualizarListaItensPdv="(lista)=>atualizarlistaItensPdv(lista)" />
            <CardapioCD   :atualizar="atualizarCD"  @atualizardados="() => atualizarDados()" @atualizarListaItensPdv="(lista)=>atualizarlistaItensPdv(lista)" 
                :listaItensProdutoPorPDVPMS="listaItensProdutoPorPDVPMS"
                />
            <!-- ADICIONAR CONTEUDO AQUI -->
                    

        </div>

</template>

<script>

import CardapioPMS from '../../components/cardapio/CardapioPMS.vue'
import CardapioCD from '../../components/cardapio/CardapioCD.vue'

export default {
  name: 'ViewHospedes',
  components:{      
      CardapioPMS,
      CardapioCD,
  },                
  data: () => ({    
    atualizarPMS:false,
    atualizarCD:false,
    listaItensProdutoPorPDVPMS:null
  }),
  methods:{
      exemplodealerta(){
          //   this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
      },
      atualizarDados(){
          this.atualizarPMS = !this.atualizarPMS
          this.atualizarCD  = !this.atualizarCD
      },
      atualizarlistaItensPdv(lista){
        
          console.log(lista)
          this.listaItensProdutoPorPDVPMS = lista
      }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Editar Cardápio")
  }
};
</script>


<style scoped>
    
</style>
