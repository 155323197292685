<template>
    <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding: 10px;">
      <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1O5rqmT5P54BkT0qp8QNlfRxmGc0d0F0o/preview" />
      </div>
      <v-card-text style="">
          <div style="height:7vh;max-height:7vh;overflow:auto;margin-top:20px;">
              <div style="padding-bottom:2px;display:flex;align-items:center;">
                  <label style="font-size:18px;">Gerencie vinculos entre intervalos de mesas e perfis de usuarios</label>
                  <v-btn color="#1976D2" rounded style="margin-left: 1em;" data-cy="btn-inserirvinculo" @click="inserirVinculo"><v-icon color="#f9f9f2">mdi-plus-circle-outline</v-icon></v-btn>
              </div>
          </div>
          <div class="">
              <div  v-for="vinculo in vinculos" :key="vinculo.id" data-cy="vinculo" style="margin-top:1px;display:flex;flex-direction: column;border-bottom:1px solid #b5b5b5;padding-bottom:10px;margin-bottom: 2em;">
                  <div style="display:flex;width:100%;align-items: flex-start">
                      <label for="mesainicial" style="font-size:14px;font-weight:bold; line-height: 1;">Intervalo de Mesas</label>
                      <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                          <input id="mesainicial" autocomplete="off" v-model="vinculo.mesainicial" data-cy="mesainicial" maxlength="50" type="text"  placeholder="Início" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                      </div>
                      <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                          <input id="mesainicial" autocomplete="off" v-model="vinculo.mesafinal" data-cy="mesafinal"  maxlength="50" type="text"  placeholder="Fim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                      </div>
                      <a data-cy="btn-removerVinculo" @click="() => removerVinculo(vinculo)">excluir</a>
                  </div>
                  <div style="display:flex;width:100%;align-items: center" v-if="perfis.length > 0">
                      <label for="mesainicial" style="font-size:14px;font-weight:bold; line-height: 1;">PDV'S</label>
                      <div style="display: flex; justify-content: space-around; flex: 1;">
                          <div  
                              v-for="perfil in perfis"  
                              :class="perfilSelecionado(perfil, vinculo)" 
                              :key="perfil.id"
                              @click="inserirRemoverPerfil(perfil, vinculo)"
                              :data-cy="`perfil_${perfil.nome}`"
                          >
                              {{perfil.nome}}
                          </div>
                      </div>
                  <v-btn color="#1976D2" rounded style="margin-left: 1em;" data-cy="btn-salvarVinculo" @click="()=>salvarVinculo(vinculo)"><v-icon color="#f9f9f2">mdi-content-save</v-icon></v-btn>
                  </div>
              </div>
          </div>
          <!-- {{JSON.stringify(vinculos, null, '\t')}}<br/> -->
          <!-- {{JSON.stringify(perfis, null, '\t')}} -->
      </v-card-text>
    </v-card>
    
  </template>
  
  <script>
  import BotaoTutorial from '../layout/BotaoTutorial.vue'
  export default {
      data() {
          return {
              perfis: [],
              vinculos: []
          };
      },
      components: {
        BotaoTutorial
      },
      methods: {
          //metodos para interecao com servidor
          salvarVinculo(vinculo) {
              let pracas;
              if (vinculo.id) {
                  pracas = {
                      id: parseInt(vinculo.id),
                      mesainicial: parseInt(vinculo.mesainicial),
                      mesafinal: parseInt(vinculo.mesafinal),
                      perfis: vinculo.perfis
                  };
              }
              else {
                  pracas = {
                      mesainicial: parseInt(vinculo.mesainicial),
                      mesafinal: parseInt(vinculo.mesafinal),
                      perfis: vinculo.perfis
                  };
              }
              this.$http.post("/pdv/addUpPracasXPerfil", { pracas })
                  .then(async (res) => {
                  if (res.status === 200) {
                      // await this.requisitiarVinculos()
                      this.$alertar("success", "=)", "Vinculo inserido com sucesso!");
                  }
              })
                  .catch(e => {
                  console.log("erro em vincular praca de mesas ao perfil", e);
                  this.$alertar("warning", "=/", "Falha no vinculo!");
              });
          },
          async requisitiarVinculos() {
              this.$http("pdv/getPracasXPerfil").then(async (resp) => {
                  if (resp.data && resp.data.length > 0) {
                      this.vinculos = resp.data;
                      console.log(this.vinculos)
                  }
              }).catch((error) => {
                  this.$alertar("warning", error, "=(");
              });
          },
          requisitarPerfis() {
              if (this.$store.getters.get_idperfil.includes(1)) {
                  this.perfis = [
                      { id: 1, nome: "Master" },
                      { id: 2, nome: "Caixa" },
                      { id: 3, nome: "Cozinha" },
                      { id: 4, nome: "Bar 1" },
                      { id: 6, nome: "Bar 2" },
                      { id: 5, nome: "Garçom" }
                  ];
              }
              else {
                  this.perfis = [
                      { id: 2, nome: "Caixa" },
                      { id: 3, nome: "Cozinha" },
                      { id: 4, nome: "Bar 1" },
                      { id: 6, nome: "Bar 2" },
                      { id: 5, nome: "Garçom" }
                  ];
              }
          },
          //metodos para manipulacao dos dados no front e da dom
          perfilSelecionado(perfil, vinculo) {
              let index = this.vinculos.indexOf(vinculo);
              let idxperfil = this.vinculos[index].perfis ? this.vinculos[index].perfis.indexOf(perfil.id) : -1;
              if (idxperfil != -1) {
                  return "perfil selecionado";
              }
              else {
                  return "perfil";
              }
          },
          inserirRemoverPerfil(pdv, vinculo) {
              let index = this.vinculos.indexOf(vinculo);
              if(this.vinculos[index].perfis == null){
                  this.vinculos[index].perfis = []
              }
              if (this.vinculos[index].perfis.length > 0) {
                  let idxpdv = this.vinculos[index].perfis.indexOf(pdv.id);
                  if (idxpdv >= 0) {
                      this.vinculos[index].perfis.splice(idxpdv, 1);
                  }
                  else {
                      this.vinculos[index].perfis.push(pdv.id);
                  }
              }
              else {
                  this.vinculos[index].perfis.push(pdv.id);
              }
          },
          inserirVinculo() {
              const pracas = {
                  mesainicial: "1",
                  mesafinal: "1000",
                  perfis: []
              };
              this.$http.post("/pdv/addUpPracasXPerfil", { pracas })
                  .then(async (res) => {
                  if (res.status === 200) {
                      // await this.requisitiarVinculos()
                      this.$alertar("success", "=)", "Vinculo inserido com sucesso!");
                      this.requisitiarVinculos();
                  }
              })
  
              // this.vinculos.push({ mesainicial: "", mesafinal: "", perfis: [] });
          },
          removerVinculo(vinculo) {
              if (vinculo.id) {
                  this.$http.post("/pdv/removerPracasXPerfil", { idpraca: vinculo.id })
                      .then(res => {
                      if (res.status === 200) {
                          this.$alertar("success", "=)", "Vinculo excluido com sucesso!");
                      }
                  })
                      .catch(e => {
                      console.log("erro em vincular praca de mesas ao pdv", e);
                      this.$alertar("warning", "=/", "Falha no exclusão vinculo!");
                  });
              }
              let index = this.vinculos.indexOf(vinculo);
              this.vinculos.splice(index, 1);
          },
      },
      async mounted() {
          await this.requisitiarVinculos();
          await this.requisitarPerfis();
      },
  }
  </script>
  
  <style lang="scss" scoped>
  
  .vinculos-wrapper{
    overflow-y: scroll;
    max-height: 100%;
  }
  
  .perfil{
    padding: .5em 1em;
    border-radius: 1em;
    background: #aaa;
    color: #666;
    user-select: none;
  
    &:hover{
      cursor: pointer;
    }
  
    &.selecionado{
      background: green;
      color: #f9f9f2;
    }
  }
  
  </style>