<template>
    
    <div class="centralizadohorizontal">
        <div class="estiloColuna">
            <v-card  v-for="item in notificacoes" :key="item.idcomandanotificacao"
                elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:3%;" >
                <v-card-text style="display:flex;flex-direction:column;">
                    <div style="width:100%;font-weight:500;">{{item.msg}}</div>
                    <div style="width:100%;text-align:end;">{{item.datacriacao | formatarDataHora}}</div>
                    
                </v-card-text>
            </v-card>


            
        </div>

       
      
    </div> 
</template>

<script>



export default {
  name: 'View Notificacoes',
  components:{      

  },                
  data: () => ({    
    notificacoes:[],
  }),
  methods:{
      consultarNotificacoes(){
        this.$http("/pdv/notificacoes/1").then((resp) =>{
                console.log(resp)
                
                this.notificacoes = resp.data

                    this.$http.put("/pdv/atualizarNotificacoes/1")
                    .then(() =>{
                        this.$store.dispatch("set_qtdenotificacao", 0)
                    })

            })
            .catch((error) =>{
                alert(error)
            })
            
    }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", this.$t('titulonotificacoes.texto'))
      this.consultarNotificacoes(2)   
  }
};
</script>


<style scoped>
    .centralizadohorizontal{
        display:flex;
        align-items: center;
        flex-direction: column;
        /* background-color: pink; */
        justify-content: center;
    }  

    .topoform{
        /* background-color: blue; */
        width:100%;
        display:flex;
        flex-wrap:wrap;
        align-content: flex-start;
    }  

    .estiloColuna{
        /* background-color: red; */
        max-height: 85vh;
        overflow:auto;
        padding: 10px 10px;
        /* padding-top:10px; */
        width: 95%!important;
        /* margin-right:2%!important; */
        /* min-height: 100%;
        max-height: 100; */
        /* border:1px solid rgb(156, 156, 156);
        border-radius:5px; */
        
    }

    .topoColuna{
      /* width:100%;
      height:10%;
      border:none;
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-left:2px;
      padding-right:20px; */
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid rgb(156, 156, 156);
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }


    .divinput{
        width:30%;
        margin-left:2%;
        margin-bottom: 10px;
    }

    .input{

        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   

        border:1px solid rgb(156, 156, 156);
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        /* padding-right: 20%; */
        border-radius: 10px;
    }
</style>