<template>
  <div>
    <!-- <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" /> -->
    <ProdutoListaCarrinho v-if="listadeprodutos.length > 0"
      :listadeprodutos="listadeprodutos"
      :idcategoriaprodcd="$route.params.idcategoriaprodcd"
      :idsubcategoriaprodcd="$route.params.idsubcategoriaprodcd"
      :idpdv="$route.params.idsubcategoriaprodcd"
      @retirarProdutoCarrinho="(produto)=>retirarProdutoCarrinho(produto)"
      @retirarProdutoCarrinhoLocal="(produto)=>retirarProdutoCarrinhoLocal(produto)"
       />

    <div v-else style="height:50vh;display:flex;align-items:center;justify-content:center;padding-left:20px;padding-right:20px;text-align:center;">
        <div>{{$t('semprodutosnocarrinho.texto')}}</div>
    </div>

    <div class="shoppingCarAddLine">

            <div class="shoppingCarAddLineAddText" style="color: blue;" data-cy="addMaisItens" @click="direcionar()"> <v-icon color="primary">mdi-plus</v-icon> {{$t('produto.adicionaritens')}}</div>
            <div class="shoppingCarAddLineInner">
               <div class="shoppingCarAddLineTotal">
                    {{valorTotal | formatarMoeda}}
                </div>
                <div class="shoppingCarAddLineAdd">
                    <div v-if="!pessoalLogada"  class="shoppingCarAddLineAddBtn" data-cy="finalizarPedido" @click="() => modalConfirmarLogin.exibir = true">
                        <div>{{$t('pessoasemlogin.login')}}</div>
                    </div>
                    <div v-else class="shoppingCarAddLineAddBtn" data-cy="finalizarPedido" @click="finalizarPedido()">
                        <div>{{$t('produto.finalizar')}}</div>
                    </div>
                </div> <!--  -->
            </div>

    </div>

    <!-- <ModalConfirmacao v-if="!passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textosim="$t('modallocal.btn1')" :textonao="$t('modallocal.btn2')" :textocancelar="$t('modallocal.btn3')" @sim="()=>setarLocal('QUARTO')" @nao="()=>setarLocal('RESTAURANTE')"
                                                @cancelar="() =>{exibirModalLocal = false}"  />
    <ModalConfirmacao v-if="passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textosim="'-'" :textonao="$t('modallocal.btn2')" :textocancelar="$t('modallocal.btn3')" @sim="()=>setarLocal('QUARTO')" @nao="()=>setarLocal('RESTAURANTE')"
                                                @cancelar="() =>{exibirModalLocal = false}"  /> -->

    <ModalConfirmacaoLocal v-if="!passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textolocal1="$t('modallocal.btn1')" @funcaoLocal="($event)=>setarLocal($event)"
        :textolocal2="$t('modallocal.btn2')" @funcaoLocal2="()=>setarLocal('RESTAURANTE')"
        :textolocal3="$t('modallocal.btn3')" @funcaoLocal3="()=>setarLocal('EVENTOS')"
        :textolocal4="$t('modallocal.btn4')" @funcaoLocal4="()=>setarLocal('OUTROS')"
        :textocancelar="$t('modallocal.btnvoltar')" @cancelar="() =>{exibirModalLocal = false}"  />

    <ModalConfirmacaoLocal v-if="passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textolocal1="'-'"                   @funcaoLocal="($event)=>setarLocal($event)"
        :textolocal2="$t('modallocal.btn2')" @funcaoLocal2="()=>setarLocal('RESTAURANTE')"
        :textolocal3="'-'"                   @funcaoLocal3="()=>setarLocal('EVENTOS')"
        :textolocal4="$t('modallocal.btn4')" @funcaoLocal4="()=>setarLocal('OUTROS')"
        :textocancelar="$t('modallocal.btn3')" @cancelar="() =>{exibirModalLocal = false}"  />

    <ModalConfirmacaoInput
        :showModal="exibirModalNumeroLocal"
        :titulo="titleConfirmacaoInputLugar()"
        :textosim="$t('confirmar.texto')"
        :textonao="$t('cancelar.texto')"
        @sim="(numero)=>setarNumeroLocal(numero)"
        @nao="()=>fecharModais()"
        :tipoLocal="tipoLocal"
        :valorinicial="valorinicialmodal" :inputhabilitado="tipoLocal != 1 ? 'SIM' : 'NAO'" />

    <ModalConfirmacaoInput :showModal="exibirModalCartaoConsumo" :titulo="$t('modalcartaoconsumo.texto')"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="(numero)=>setarNumeroCartaoConsumo(numero)" @nao="()=>fecharModais()"
        :valorinicial="valorinicialmodalcartao" :inputhabilitado="'NAO'"  />

    <ModalConfirmacao :showModal="exibirModalConfirmacao" :titulo="$t('avisocancelamento.texto')"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="()=>confirmarPedidoConsumos()" @nao="()=>fecharModais()" />
        <ModalConfirmacao :showModal="exibirModalConfirmacaoAlertaConsumo" :titulo="message"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="()=>confirmarPedido()" @nao="()=>fecharModais()" />

    <ModalConfirmacao :showModal="modalConfirmarLogin.exibir" :titulo="$t('pessoasemlogin.finalizarpedido')"
        :textosim="$t('pessoasemlogin.login')"
        :textonao="$t('pessoasemlogin.continuarnavegando')"
        @sim="() => redirecionarLogin()"
        @nao="() => continuarNavegando()"
    />

  </div>
</template>

<script>
  import ProdutoListaCarrinho from '../../components/produtos/ProdutoListaCarrinho.vue'
  import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
  import ModalConfirmacaoLocal from '../../components/layout/ModalConfirmacaoLocal.vue'
  import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'

  export default {
    name: 'Carrinho',
    components: {
      ProdutoListaCarrinho,
      ModalConfirmacao,
      ModalConfirmacaoLocal,
      ModalConfirmacaoInput,
    },
    data: () => ({
        pessoalLogada: false,
        valor:14.90,
        listadeprodutos:[],
        valorTotal:0.00,
        exibirModalLocal:false,
        exibirModalNumeroLocal:false,
        exibirModalConfirmacao:false,
        exibirModalCartaoConsumo:false,
        exibirModalConfirmacaoAlertaConsumo: false,
        tipoLocal:1,
        numeroLocal:localStorage.getItem('coduh'),
        numeroCartaoConsumo:localStorage.getItem('cartaoconsumo'),
        valorinicialmodal:null,
        valorinicialmodalcartao:null,
        idcomanda:null,
        message:'',
        passante:false,
        modalConfirmarLogin: {
            exibir: false,
        }
    }),
    methods:{
      titleConfirmacaoInputLugar() {
        // :titulo="$t('modalconfirmacao.qualonumero')+`${ tipoLocal == 1? $t('modalconfirmacao.doseuquarto'):$t('modalconfirmacao.dasuamesa') }`"
        let titulo = `${this.$t('modalconfirmacao.qualonumero')} ${this.tipoLocal == 1? this.$t('modalconfirmacao.doseuquarto'):this.$t('modalconfirmacao.dasuamesa')}`

        if(this.tipoLocal == -1) {
          titulo = `${this.$t('modalconfirmacao.qualoutro')}⠀⠀⠀⠀`
        }

        return titulo
      },
      redirecionarLogin() {
        this.$router.push('/')
      },
      continuarNavegando() {
        this.modalConfirmarLogin.exibir = false;
      },
      async confirmarPedidoConsumos() {
        if(this.tipoLocal == 2 || this.tipoLocal == 3) {
            const numeroPraca = this.numeroLocal;
            await this.$http(`pdv/buscarComandaMesa/${numeroPraca}`).then(() => {
                this.confirmarPedido()
            }).catch(error => {
                console.log(error);
                this.$alertar("warning", "Não foi possivel buscar as comandas da mesa", "=(");
            });
        } else {
            this.confirmarPedido();
        }
      },
        async confirmarPedido(){

            let dados = {
                tipolocal:this.tipoLocal,
                numerolocal:this.numeroLocal,
                cartaoconsumo:this.numeroCartaoConsumo,
                id_ultimo_usuario_atualizacao: -1,
            }


            this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/2`,dados).then(()=>{
                this.fecharModais()
                this.getProdutosNoCarrinho()
                this.abrirMesa()
                this.$alertar("success","Pedido Enviado para Produção","=)")
                // this.$router.push('/Hospede/Bemvindo')

                const logadoPelaMesa = JSON.parse(localStorage.getItem("login_mesa"));

                let pathRedirect;

                if(logadoPelaMesa) {
                    const params = JSON.parse(localStorage.getItem("params_login_mesa"));
                    pathRedirect = '/Hospede/PDVMesa'

                    let pdvs = params.pdvs.split(",");

                    for(const pdv of pdvs) {
                        if(pathRedirect.includes("?")) {
                            pathRedirect += `&pdvs=${pdv}`;
                        } else {
                            pathRedirect += `?pdvs=${pdv}`;
                        }
                    }

                    if(params.mesa) {
                    pathRedirect += `&mesa=${params.mesa}`;
                    }
                } else {
                    pathRedirect = '/Hospede/Bemvindo'
                }

                this.$router.push(pathRedirect)

            })
            .catch((error)=>{
                console.log(error)
                this.fecharModais()

                if( error.response && error.response.data && error.response.data.messageComandaAberta){
                    this.$alertar("warning",error.response.data.messageComandaAberta,"Solicita que um Garçom Feche a Comanda!")
                }else{
                    this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
                }
            })
            // await this.getProdutosNoCarrinho()
            // this.$router.push('/Hospede/Pedidos')
        },

        async abrirMesa() {
         const data = {
          numeroMesa: this.numeroLocal,
          status: true,
         }

         this.$http.post(`mesaStatus/cadastrarMesa/`, data).then(()=>{
          console.log('mesa aberta')
            })
            .catch((error)=>{
             console.log(error)
            })
        },

        setarNumeroLocal(numero){

            this.numeroLocal = numero
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalConfirmacao = false
            this.valorinicialmodalcartao = localStorage.getItem('cartaoconsumo')

            if(this.obrigatoriocartaoconsumo){

                this.exibirModalCartaoConsumo = true
            }else{

                this.exibirModalLocal = false
                this.exibirModalNumeroLocal = false
                this.exibirModalCartaoConsumo = false
                this.exibirModalCartaoConsumo = false
                this.exibirModalConfirmacao = true
            }

        },
        setarNumeroCartaoConsumo(numero){
            if(numero){
                this.numeroCartaoConsumo = numero
                this.exibirModalLocal = false
                this.exibirModalNumeroLocal = false
                this.exibirModalCartaoConsumo = false
                this.exibirModalCartaoConsumo = false
                this.exibirModalConfirmacao = true
            }else{
                this.$alertar("warning","Informe o Cartão de Consumo!","=(")
            }
        },
        setarLocal(local){
            this.tipoLocal = local.id_localidade;
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = true
            this.valorinicialmodal = localStorage.getItem('coduh') || null;

        },
        fecharModais(){
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalConfirmacao = false
            this.exibirModalConfirmacaoAlertaConsumo = false
        },
        getProdutosNoCarrinho(){
            this.$http('pdv/getProdutosNoCarrinho').then((resp) =>{
              this.listadeprodutos = resp.data.itensPedido
              this.valorTotal = 0
              for(var i = 0, len = this.listadeprodutos.length; i < len; i++){
                  this.valorTotal += this.listadeprodutos[i].precounitario *this.listadeprodutos[i].quantidade
                  this.idcomanda = this.listadeprodutos[i].idcomanda
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
            })
        },
        retirarProdutoCarrinho(produto){

            let objetoproduto = {
                idprodutocomanda:produto.id,
                numpedido:produto.idcomanda,
                id_ultimo_usuario_atualizacao: -1,
            }
            this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then(() =>{
              this.getProdutosNoCarrinho()
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
            })
        },
        retirarProdutoCarrinhoLocal(produto){
            console.log(produto)
            const produtos = JSON.parse(localStorage.getItem('produtosCarrinho'))
            const index = produtos.findIndex(item => item.id === produto.id)
            produtos.splice(index, 1)
            localStorage.setItem('produtosCarrinho', JSON.stringify(produtos))
            this.listadeprodutos = produtos
        },
        direcionar(){
            const logadoPelaMesa = JSON.parse(localStorage.getItem("login_mesa"));

            if(logadoPelaMesa) {
                const params = JSON.parse(localStorage.getItem("params_login_mesa"));
                params.pdvs = params.pdvs.split(",");

                this.$router.push({
                    query: params,
                    path: "/Hospede/PDVMesa"
                });
            } else {
                this.$router.push('/Hospede/Categorias/'+localStorage.getItem('pdvSelecionado'));
            }
        },
        finalizarPedido(){
            if(this.listadeprodutos.length > 0){
                const pessoaLogadaMesa = JSON.parse(localStorage.getItem("login_mesa"))
                if(pessoaLogadaMesa) {
                    this.setarLocal({
                        id_localidade: 2
                    });

                    const params = JSON.parse(localStorage.getItem("params_login_mesa"));
                    if(params.mesa) {
                        this.valorinicialmodal = params.mesa;    
                    }

                } else {
                    this.exibirModalLocal = true
                }

            }else{
                this.$alertar("warning","Você não possui itens no Carrinho","=(")
            }
        },
        async salvarNoCarrinhoProdutosLocalStorage(produtos){

            for(let produto of produtos){
                let produtoAdicionado = null
                produto.quantidade = parseInt(produto.quantidade)

                let dadosComanda = await this.$http(`/pdv/getComandaAberta`)
                dadosComanda = dadosComanda ? dadosComanda.data:null
                produto.numpedido = dadosComanda.pedido.numpedido

                delete produto.img
                delete produto.precoimportado
                delete produto.nomecd
                delete produto.descproduto
                delete produto.valortaxa


                if(dadosComanda && dadosComanda.pedido && dadosComanda.pedido.numpedido > 0){

                    delete produto.idreservasfront
                    delete produto.idhospede


                    produto.idreservasfront = localStorage.getItem('idreservasfront')
                    produto.id_ultimo_usuario_atualizacao = -1;

                    produtoAdicionado = await this.$http.post(`pdv/adicionarProdutoNaComanda`, produto)
                    if(produtoAdicionado){
                        this.$alertar("sucess","Adicionado ao carrinho","=(")
                    }else{
                        this.$alertar("warning","Não foi possível adicionar o produto a comanda!","=(")
                    }
                }else{

                    const data = {
                      id_ultimo_usuario_atualizacao: -1,
                      abertura_responsavel: 'hospede_antigo'
                    }
                    

                    dadosComanda = await this.$http.post(`pdv/iniciarComanda`, data)
                    dadosComanda = dadosComanda && dadosComanda.data
                    if(dadosComanda && dadosComanda.pedido > 0){

                        produto.idhospede = localStorage.getItem('idhospede')
                        produto.idreservasfront = localStorage.getItem('idreservasfront')
                        produto.id_ultimo_usuario_atualizacao = -1;

                        produtoAdicionado = await this.$http.post(`pdv/adicionarProdutoNaComanda`,produto)
                        if(produtoAdicionado){
                            this.$alertar("sucess","Adicionado ao carrinho","=(")
                        }else{
                            this.$alertar("warning","Não foi possível adicionar o produto a comanda!","=(")
                        }
                    }else{
                        this.$alertar("warning","Não foi possível iniciar uma nova comanda!","=(")
                    }
                }
            }

            localStorage.removeItem("produtosCarrinho")
        },
    },
    async mounted(){

        if(localStorage.getItem('passante')){
            this.passante = localStorage.getItem('passante').toUpperCase() == 'TRUE'? true: false
        }


        if(localStorage.getItem("pessoaLogada") && localStorage.getItem("pessoaLogada") === 'true') {
            this.pessoalLogada = true
            if(localStorage.getItem('produtosCarrinho') && JSON.parse(localStorage.getItem('produtosCarrinho')).length > 0 ){
                await this.salvarNoCarrinhoProdutosLocalStorage([...JSON.parse(localStorage.getItem('produtosCarrinho'))])
            }
            this.$store.dispatch("set_titulo",this.$t('titulocarrinho.texto'))
            await this.$http.post('pdv/buscarparametros',{chave:'OBRIGATORIOCARTAOCONSUMO'})
                .then(resp =>{
                    this.obrigatoriocartaoconsumo = resp.data[0].valor === 'true'? true : false
                }).catch(error =>{
                    alert(error)
                })
        }



        if(localStorage.getItem("pessoaLogada") && localStorage.getItem("pessoaLogada") === 'false'){
            const produtos = JSON.parse(localStorage.getItem('produtosCarrinho'))
            this.listadeprodutos = produtos ? produtos : []
            this.valorTotal = 0
            for(var i = 0, len = this.listadeprodutos.length; i < len; i++){
                this.valorTotal += this.listadeprodutos[i].precounitario *this.listadeprodutos[i].quantidade
                this.idcomanda = this.listadeprodutos[i].idcomanda
            }

        } else {
            this.getProdutosNoCarrinho()
        }

    }
  }
</script>

<style scoped>
    .shoppingCarAddLine{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height:20vh;
        border-top: 1px solid rgb(156, 156, 156);
    }

    .shoppingCarAddLineAddText{
        /* background-color: yellow; */
        width: 100%;
        height: 30%;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        color:#4CAF50;
        font-weight: bold;
    }



    .shoppingCarAddLineInner{
        /* background-color: blue; */
        display: flex;
        align-items: center;
        /* position: fixed;
        bottom: 0px; */
        width: 100%;
        height: 70%;
        /* border-top: 1px solid rgb(156, 156, 156); */
    }

    .shoppingCarAddLineTotal{
        width:30%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

    }

    .shoppingCarAddLineAdd{
        width:70%;
        /* background-color: red; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoppingCarAddLineAddBtn{
        background-color: #4CAF50;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0px 7%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .shoppingCarAddLineTotalBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 10%;
        align-items: center;
        justify-content: space-between;
    }

    .shoppingCarAddLineTotalBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }
</style>
