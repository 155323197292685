<template>
  <transition name="modal" v-if="showModal" @close="showModal = false">
      <div class="modal-mask" >
          <div class="modal-wrapper">
              <div class="modal-container">

              <div class="modal-header">
                  <slot name="header">

                  </slot>
              </div>

              <div class="modal-body">
                  <slot name="body">
                  {{titulo}} <span style="color:red;" >*Obrigatório {{inputhabilitado}}</span>
                  <div class="form-group">
                      <input type="text" v-model="numero" class="form-control numeroForm" data-cy="modal-confirmacao-input" id="exampleInputPassword1" :placeholder="placeHolder()" :disabled="inputhabilitado == 'NAO' ? true : false">
                  </div>
                  </slot>
              </div>

              <div class="modal-footer">
                  <slot name="footer">
                    <button class="botaoModalLocal botaoModalLocalVerde" data-cy="modal-confirmacaoInput-sim" @click="sim()" >
                        {{textosim}}
                    </button>
                    <button class="botaoModalLocal botaoModalLocalVermelho" data-cy="modal-confirmacaoInput-nao" @click="nao()" >
                        {{textonao}}
                    </button>
                  </slot>
              </div>
              </div>
          </div>
      </div>
  </transition>
</template>

<script>

export default {
  name: 'BotaoVoltar',
  props:["showModal","titulo","textosim","textonao","valorinicial","inputhabilitado", "tipoLocal"],
  mounted(){
      this.numero = this.valorinicial
      if(this.$route.query.mesa > 0){
        this.numero = this.$route.query.mesa 
      }
  },
  data: () => ({
    numero:null,
  }),
  watch:{
     valorinicial(val){
        this.numero = val
     }
  },
  methods:{
      direcionar(){
          this.$router.push(this.url)
      },
      nao(){
        this.$emit('nao')
      },
      sim(){
        if(this.numero){
          this.$emit('sim',this.numero)
        }
      },
      placeHolder() {

        let placeholder = "Número"


        if(this.tipoLocal == -1) {
          placeholder = "insira o lugar"
        }

        return placeholder

      }
  }
}
</script>


<style scoped>
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: table;
transition: opacity 0.3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
width: 300px;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
transition: all 0.3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.botaoModalLocal{
  width:100%;
  height: 50px;
  display: block;
  margin-bottom:30px;
  border:0px;
  font-weight: bold;
  color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;width:100%!important;border:1px solid black; border-radius:5px;margin-top:10px;padding:10px;}
</style>

