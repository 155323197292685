<template>
  <div>
    <!-- <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" /> -->
    <!-- <div v-if="ojbPedidosEnviados.listadepedidos.length > 0"> -->
    <div v-if="this.ojbPedidosCount > 0">

        <PedidosLista  :ojbPedidos="ojbPedidosEnviados"   corTexto="#55ad94"    />
        <hr/>
        <PedidosLista  :ojbPedidos="ojbPedidosEmProducao" corTexto="#555cdd"     />
        <hr/>
        <PedidosLista  :ojbPedidos="ojbPedidosProntos"    corTexto="#d98e00"     />
        <hr/>
        <PedidosLista  :ojbPedidos="ojbPedidosEntregues"  corTexto="green"     />
        <hr/>
        <PedidosLista  :ojbPedidos="ojbPedidosCancelados" corTexto="red"     />

    </div>

    <div v-else style="display:flex;min-height:70vh;justify-content:center;align-items:center;">
        <span style="font-size:20px;">Você ainda não fez um Pedido!</span>
    </div>

   
<!-- 
    <ModalConfirmacao :showModal="exibirModalLocal" :titulo="'Onde você está?'"
        textosim="Quarto" textonao="Restaurante" @sim="()=>setarLocal('QUARTO')" @nao="()=>setarLocal('RESTAURANTE')"  />

    <ModalConfirmacaoInput :showModal="exibirModalNumeroLocal" :titulo="`Qual o numero ${ tipoLocal == 1? ' do seu quarto?':' da sua mesa?' }`"
        textosim="Confirmar" textonao="Cancelar" @sim="(numero)=>setarNumeroLocal(numero)" @nao="()=>fecharModais()"
        :valorinicial="valorinicialmodal"  />

    <ModalConfirmacaoInput :showModal="exibirModalCartaoConsumo" :titulo="`Qual o numero do seu Cartao de Consumo?`" 
        textosim="Confirmar" textonao="Cancelar" @sim="(numero)=>setarNumeroCartaoConsumo(numero)" @nao="()=>fecharModais()"  
             />

    <ModalConfirmacao :showModal="exibirModalConfirmacao" :titulo="'Ao enviar o pedido ele não poderá ser cancelado!'"
        textosim="Enviar" textonao="Cancelar" @sim="()=>confirmarPedido()" @nao="()=>fecharModais()" />
                 -->

  </div>
</template>

<script>
  import PedidosLista from '../../components/pedidos/PedidosLista.vue'
//   import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
//   import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'

  export default {
    name: 'Pedidos',
    components: {
      PedidosLista,
    //   ModalConfirmacao,
    //   ModalConfirmacaoInput,
    },
    data: () => ({
        // valor:14.90,
        listadepedidos:[],
        ojbPedidosCount:0,
        ojbPedidosEnviados:null,
        ojbPedidosEmProducao:null,
        ojbPedidosProntos:null,
        ojbPedidosEntregues:null,
        ojbPedidosCancelados:null
        // valorTotal:0.00,
        // exibirModalLocal:false,
        // exibirModalNumeroLocal:false,
        // exibirModalConfirmacao:false,
        // exibirModalCartaoConsumo:false,
        // tipoLocal:1,
        // numeroLocal:localStorage.getItem('coduh'),
        // numeroCartaoConsumo:localStorage.getItem('cartaoconsumo'),
        // valorinicialmodal:null,
        // idcomanda:null
    }),
    methods:{
        // async confirmarPedido(){
        //     this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/2`).then(()=>{
        //         this.fecharModais()
        //         this.getComandasReservaPorStatus()
        //         this.$alertar("warning","Pedido Enviado para Cozinha","=)")
        //     })
        //     .catch((error)=>{
        //         console.log(error)
        //         this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
        //     })
        //     // await this.getComandasReservaPorStatus()
        //     // this.$router.push('/Hospede/Pedidos')
        // },
        // setarNumeroLocal(numero){
        //     this.numeroLocal = numero
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = false
        //     this.exibirModalCartaoConsumo = true
        // },
        // setarNumeroCartaoConsumo(numero){
        //     this.numeroCartaoConsumo = numero
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = true
        // },
        // setarLocal(local){
        //     if(local === 'QUARTO'){
        //         this.tipoLocal = 1
        //         this.exibirModalLocal = false
        //         this.exibirModalNumeroLocal = true
        //         this.valorinicialmodal = this.numeroLocal
        //     }
        //     if(local === 'RESTAURANTE'){
        //         this.tipoLocal = 2
        //         this.exibirModalLocal = false
        //         this.exibirModalNumeroLocal = true
        //         this.valorinicialmodal = this.numeroCartaoConsumo
        //     }
            
        // },
        // fecharModais(){
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = false
        // },
        async getPedidosPorStatus(){
            let idreservasfront = localStorage.getItem('idreservasfront')
            this.ojbPedidosEnviados = await this.getComandasReservaPorStatus(idreservasfront,2)
            this.ojbPedidosEmProducao = await this.getComandasReservaPorStatus(idreservasfront,3)
            this.ojbPedidosProntos = await this.getComandasReservaPorStatus(idreservasfront,4)
            this.ojbPedidosEntregues = await this.getComandasReservaPorStatus(idreservasfront,'5,7')
            this.ojbPedidosCancelados = await this.getComandasReservaPorStatus(idreservasfront,6)
        },
        async getComandasReservaPorStatus(idreservasfront,idstatus){
            let objetoRetorno = {}
            await this.$http('pdv/getComandasReservaPorStatus/'+idreservasfront+'/'+idstatus+'/t').then((resp) =>{
              console.log(resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.listadepedidos = resp.data

              this.ojbPedidosCount = objetoRetorno.listadepedidos.length

              for(var i = 0, len = objetoRetorno.listadepedidos.length; i < len; i++){
                  objetoRetorno.valorTotal += objetoRetorno.listadepedidos[i].valor
                  objetoRetorno.statuscomanda = objetoRetorno.listadepedidos[i].statuscomanda
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
        },
        // retirarProdutoCarrinho(produto){
            
        //     let objetoproduto = {
        //         idprodutocomanda:produto.id,
        //         numpedido:produto.idcomanda
        //     }
        //     this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then(() =>{
        //       this.getComandasReservaPorStatus()
        //     }).catch(error =>{
        //       console.log(error)
        //       this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
        //     })
        // },
        // direcionar(){
        //     this.$router.push('/Hospede/Menu')
        // },
        // finalizarPedido(){
        //     this.exibirModalLocal = true
        // }
    },
    mounted(){
        this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
        // this.getComandasReservaPorStatus()
        this.getPedidosPorStatus()
    }
  }
</script>

<style scoped>
    .shoppingCarAddLine{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height:20vh;
        border-top: 1px solid rgb(156, 156, 156);
    }

    .shoppingCarAddLineAddText{
        /* background-color: yellow; */
        width: 100%;
        height: 30%;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        color:#4CAF50;
        font-weight: bold;
    }

    

    .shoppingCarAddLineInner{
        /* background-color: blue; */
        display: flex;
        align-items: center;
        /* position: fixed;
        bottom: 0px; */
        width: 100%;
        height: 70%;
        /* border-top: 1px solid rgb(156, 156, 156); */
    }

    .shoppingCarAddLineTotal{
        width:30%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

    }

    .shoppingCarAddLineAdd{
        width:70%;
        /* background-color: red; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoppingCarAddLineAddBtn{
        background-color: #4CAF50;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0px 7%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .shoppingCarAddLineTotalBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 10%;
        align-items: center;
        justify-content: space-between;
    }

    .shoppingCarAddLineTotalBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }
</style>