<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="text-center">Regras de Comissionamento</h2>
      </v-col>
      <v-col>
        <v-row class="mb-2">
          <v-col align-self="center">
            <v-text-field class="centered-input" :disabled="!ehMaster"
              @input="diaAlterado = true"
              type="number"
              dense
              min="1"
              max="28"
              v-model="diaFechamento"
              label="Data de Fechamento Mensal"
              outlined
              style="min-width: 180px"
            >
            </v-text-field>
            <label v-if="diaAlterado" style="color:red!important;">Atenção! A mudança na data de fechamento pode acarretar discrepâncias no relatório de comissões!</label>
          </v-col>
          <v-col>
            <v-btn
              :disabled="!diaAlterado"
              small
              elevation=""
              color="primary"
              @click="salvarDataFechamento()"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <div class="es-tabs">
        <div class="es-tab active" @click="changeTab(0)">Regras Vigentes</div>
        <div class="es-tab" @click="changeTab(1)">Regras Inativas</div>
      </div>
    </v-card>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:[`item.regra`]="{ item }">
        <v-text-field
          :id="`regra_${item.id_comissao_regra}`"
          :ref="`regra_${item.id_comissao_regra}`"
          v-model="editedItem.regra"
          :hide-details="true"
          dense
          single-line
          v-if="item.id_comissao_regra === editedItem.id_comissao_regra"
        ></v-text-field>
        <span v-else>{{ item.regra }}</span>
      </template>
      <template v-slot:[`item.padrao`]="{ item }">
        <v-select
          v-if="item.id_comissao_regra === editedItem.id_comissao_regra"
          v-model="editedItem.padrao"
          :items="padraoSimNao"
          item-text="nome"
          item-value="valor"
        >
        </v-select>
        <span :class="{ 'es-green': item.padrao }" v-else>{{
          item.padrao ? "Sim" : "Não"
        }}</span>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-select
          v-if="item.id_comissao_regra === editedItem.id_comissao_regra"
          v-model="editedItem.ativo"
          :items="statusList"
          item-text="nome"
          item-value="valor"
        >
        </v-select>
        <span :class="{ 'es-red': !item.ativo }" v-else>{{
          item.ativo ? "Ativa" : "Inativa"
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: flex; align-items: center">
          <div v-if="item.id_comissao_regra === editedItem.id_comissao_regra">
            <v-icon color="red" class="mr-3" @click="closeEdit()">
              mdi-window-close
            </v-icon>
            <v-icon color="green" @click="salvarComissao()">
              mdi-content-save
            </v-icon>
          </div>
          <div v-else>
            <v-icon v-if="ehMaster" class="light-green" @click="editItem(item)" title="Editar">
              mdi-pencil
            </v-icon>
            <v-icon
              class="light-blue"
              @click="definirMetas(item)"
              title="Definir Metas"
            >
              mdi-trending-up
            </v-icon>
            <v-icon
              class="light-red"
              @click="definirProdutos(item)"
              title="Definir Produtos"
            >
              mdi-tag-outline
            </v-icon>
          </div>
        </div>
      </template>
      <template v-if="ehMaster" v-slot:[`footer.page-text`]>
        <v-btn v-if="editedIndex === -1" small elevation="" color="success" @click="novaRegra()">
          + NOVA REGRA
        </v-btn>
      </template>      
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "ComissaoRegras",
  data: () => ({
    tab: null,
    headers: [
      { text: "Regra", value: "regra" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "ativo" },
      { text: "Ações", value: "actions" },
    ],
    items: [],
    editedItem: {},
    editedIndex: -1,
    defaultItem: {
      id_comissao_regra: 0,
      regra: "",
      padrao: false,
      ativo: true,
      colaboradores: null
    },
    statusList: [
      { nome: "Ativa", valor: true },
      { nome: "Inativa", valor: false },
    ],
    padraoSimNao: [
      { nome: "Sim", valor: true },
      { nome: "Não", valor: false },
    ],
    vigentes: true,
    diaFechamento: "24",
    diaAlterado: false,
    ehMaster: false,
  }),
  methods: {
    changeTab(tabIndex) {
      var tabs = document.getElementsByClassName("es-tab");
      // Remover a classe 'active' de todas as abas
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove("active");
      }
      // Adicionar a classe 'active' à aba selecionada
      tabs[tabIndex].classList.add("active");
      this.vigentes = tabIndex === 0;
      this.$forceUpdate();
      this.buscarComissoes();
    },
    novaRegra() {
      this.items.push(this.defaultItem);
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$nextTick(() => this.$refs.regra_0.focus());
    },
    async salvarComissao() {
      if (!this.editedItem.regra.trim()) {
        this.$alertar("warning", `A regra precisa ter um nome!`, "=(");
        return;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      }
      this.$http
        .post("comissao/salvar", this.editedItem)
        .then(async () => {
          this.$alertar("success", "Regra salva!", "=)");
          this.buscarComissoes();
          this.closeEdit();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
          this.closeEdit();
        });
    },
    closeEdit() {
      setTimeout(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        if (this.editedItem.id_comissao_regra === 0) {
          this.items.pop();
        }
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$nextTick(() =>
        this.$refs[`regra_${item.id_comissao_regra}`].focus()
      );
    },
    definirProdutos(item) {
      localStorage.setItem(`id_comissao_regra`, item.id_comissao_regra);
      this.$router.push(`/Adm/ComissaoProdutos`);
    },
    definirMetas(item) {
      localStorage.setItem(`id_comissao_regra`, item.id_comissao_regra);
      localStorage.setItem(`dia_fechamento`, this.diaFechamento);
      this.$router.push(`/Adm/ComissaoMetas`);
    },
    buscarComissoes() {
      this.editedIndex = -1;
      this.editedItem = {};
      this.$http
        .post("comissao/buscar", { vigentes: this.vigentes })
        .then(async (res) => {
          this.items = res.data;
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    buscarParametro() {
      this.$http
        .post("pdv/buscarparametros", { chave: "DIA_FECHAMENTO_COMISSAO" })
        .then(async (res) => {
          if (res.data && res.data.length > 0) {
            this.diaFechamento = parseInt(res.data[0].valor);
          }
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    salvarDataFechamento() {
      this.$http
        .post("comissao/fechamento/salvar", {
          idhotel: localStorage.getItem("hotel"),
          chave: "DIA_FECHAMENTO_COMISSAO",
          valor: this.diaFechamento,
        })
        .then(async () => {
          this.$alertar("success", "Data de fechamento salva!", "=)");
          this.diaAlterado = false;
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
  },
  mounted() {
    this.$store.dispatch("set_titulo", "Comissões");
    this.buscarComissoes();
    this.buscarParametro();
    this.ehMaster = (JSON.parse(localStorage.getItem('perfis')))?.includes(1);
    // this.ehGarcon = (JSON.parse(localStorage.getItem('perfis')))?.includes(5);
  },
};
</script>
<style scoped>
.light-blue {
  color: blue !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-blue:hover {
  background-color: lightblue !important;
}
.light-green {
  color: green !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-green:hover {
  background-color: lightgreen !important;
}
.light-yellow {
  color: rgb(255, 196, 0) !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-yellow:hover {
  background-color: rgb(235, 230, 172) !important;
}

.light-red {
  color: red !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-red:hover {
  background-color: lightcoral !important;
}
/* .es-tabs {
  display: flex;
  flex-direction: row;
}
.es-tab {
  color: rgb(135, 135, 139);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.es-active {
  color: rgb(69, 69, 252);
} */

/* Estilo básico das abas */
.es-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 5px;
  margin: 0;
}

.es-tab {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: lightblue;
  color: white;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.es-tab:hover {
  cursor: pointer;
  background-color: cornflowerblue;
}

/* Estilo para a aba ativa */
.es-tab.active {
  background-color: dodgerblue;
}

.es-tab.active:hover {
  background-color: deepskyblue;
}

.es-green {
  color: rgb(253, 253, 253);
  background-color: rgb(119, 206, 119);
  padding: 0.2rem;
  border-radius: 50%;
}

.es-red {
  color: rgb(228, 48, 48);
  font-weight: bold;
}
  .centered-input >>> input {
    text-align: center;
  }

</style>