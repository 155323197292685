<template>
    <div class="bodygenerico">
        <div class="bodyFlex">
            <!-- <ColunaEsquerdaTutoriais @setarTela="(tela) => setarTela(tela)" />  -->
            <!-- <div class="estiloColuna">
                <div class="topoColuna">
                    <Subtitulo :subtitulo="'Tutoriais'" :corTexto="'#fff'" />
                </div>
                <div v-if="tutoriais" class="categoryList" style="margin-top:10px;margin-bottom:20px;padding-left:10px;padding-right:10px;">
                    <v-btn v-for="tuto in tutoriais" :key="tuto.id"
                        small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" 
                        @click="setarTutorial(tuto)">
                        {{tuto.titulo}}
                    </v-btn>
                </div>
            </div> -->

            <div class="estiloColuna">
                <div class="topoColuna">
                    <Subtitulo :subtitulo="'Tutoriais'" :corTexto="'#fff'" />
                </div>
                <div v-if="tutoriais" class="categoryList" style="margin-top:10px;margin-bottom:20px;padding-left:10px;padding-right:10px;">
                    <!-- <v-btn v-for="tuto in tutoriais" :key="tuto.id"
                        small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" 
                        @click="setarTutorial(tuto)">
                        {{tuto.titulo}}
                    </v-btn> -->
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                    >
                        <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquise pelo tutorial"
                            single-line
                            hide-details
                            ></v-text-field>
                        </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                        <v-btn
                            small
                            color="info"
                            @click="setarTutorial(item)"
                        >
                            <v-icon color="">mdi-play-circle</v-icon>
                        </v-btn>
                        </template>
                    </v-data-table>
                </div>
            </div>

               
            
            <div class="estiloColunaDireita">
    
                <v-card flat style="display:flex;align-items:center;justify-content:center;width:100%;height:100%;">
                    <iframe class="iframe-video" style="width:90%;height:90%;"
                    :src="tutorialselecionado.video">
                    </iframe>
                </v-card>
                
            </div>

        </div>
    </div>
</template>

<script>
import Subtitulo from '../../components/layout/Subtitulo.vue'
// import ColunaEsquerdaTutoriais from '../../components/layout/ColunaEsquerdaTutoriais.vue'
// import ColunaDireitaTutoriais  from '../../components/layout/ColunaDireitaTutoriais.vue'
// import ColunaPedido from '../../components/pedidos/ColunaPedido.vue'
// import ModalImpressaoPedido from '../../components/layout/ModalImpressaoPedido.vue'

// const dayjs = require('dayjs')


export default {
  name: 'ViewTutoriais',
  components:{ 
    // ColunaEsquerdaTutoriais,
    Subtitulo,
    // ColunaDireitaTutoriais,
    // ColunaPedido,
    // ModalImpressaoPedido
  },
  data: () => ({
    tutoriais:null,
    tutorialselecionado:null,
    search: '',
    headers: [
        {
            text: 'Tutorial',
            width: '75%',
            value: 'titulo',
        },
        { 
            text: 'Abrir tutorial', 
            width: '25%',
            value: 'actions' 

        },
    ],
    desserts: [
        {}
    ],
  }),
  methods:{
      setarTutorial(tuto){
          this.tutorialselecionado = tuto
      }

  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Tutoriais")

      let baseURL = this.$dados.BUILD_APIES_URL

      this.$http.post(baseURL+"/tutorial/select", {idioma: 'pt-BR', sistema:2,'uid':localStorage.getItem('userid')},
      {headers:{'x-access-token':this.$dados.BUILD_TOKEN_APIES}})
      .then((response) => {
        if (response.status == 200) {
            this.tutoriais = response.data
            this.desserts = response.data
            if(response.data.length > 0) {
                this.setarTutorial(response.data[0])
            }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>


<style scoped>
    .estiloColuna{
        background-color: white;
        /* padding-top:10px; */
        width: 28%!important;
        margin-right:2%!important;
        min-height: 76vh;
        max-height: 76vh;
        border:1px solid rgb(156, 156, 156);
        border-radius:5px;
    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color:#1976D2;
      display:flex;
      align-items: center;
    }

    .estiloColunaDireita{
        background-color: white;
        padding:10px;
        width: 70%!important;
        margin-right:2%!important;
        min-height: 100%;
        max-height: 100;
        border:1px solid rgb(156, 156, 156);
        border-radius:5px; /**/
        
    }

</style>
