<template>
  <div id="container">
    <div id="info">
      <h2>Personalização de Comandas Impressas</h2>
      <p>
        Bem-vindo à tela de personalização de comandas impressas. Aqui você pode criar template personalizado para suas comandas, usando tags de substituição.
      </p>
      <p>
        Basta envolver as tags de substituição com "{{}}" e elas serão automaticamente substituídas pelos valores correspondentes.
      </p>
      <!-- <div class="tags">
        <p><strong>Tags de Substituição:</strong> valorTotal, numpedido, datafeito, idtipolocal, numerolocal, numreserva, coduh, nome, nomegarcom, nomepdv, descproduto
          precounitario, valortotalproduto, quantidade, observacao</p>
      </div> -->

      <div class="tags">
        <p><strong>Tags de Substituição Cabeçalho e Rodapé:</strong> {{ tagsSubsCabecalhoRodape.join(', ') }}</p>
      </div>

      
      <div class="tags">
        <p><strong>Tags de Substituição Items:</strong> {{ tagsSubsItems.join(', ') }}</p>
      </div>

      <div class="tags">
        <p><strong>OBS: não esqueça de salvar, clicando no botão abaixo "salvar"</strong></p>
      </div>
    </div>

    <div id="container-editor">
      <div id="editors" class="el-tiptap-editor__wrapper">
        <el-tiptap
            class="editor"
            :extensions="extensionEditor"
            content="Cabeçalho"
            v-model="template.cabecalho"
        />

        <el-tiptap
            class="editor"
            :extensions="extensionEditor"
            content="Itens"
            v-model="template.items"
        />

        <el-tiptap
            class="editor"
            :extensions="extensionEditor"
            content="Rodapé"
            v-model="template.rodape"
        />
      </div>

      <div id="preview">
        <el-tiptap
        
            class="editor preview-editor"
            :extensions="extensionEditor"
            content="Visualização"
            v-model="preview"
            :readonly="true"
        >
          <template #menubar="{}">
            <div class="menubar"></div>
          </template>
        </el-tiptap>
      </div>
    </div>

    <div style="margin-top: 3rem">
      <v-btn color="primary" @click="save">Salvar</v-btn>
    </div>
  </div>
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  Bold,
  // Heading,
  TextAlign,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  // Print,
} from 'element-tiptap';

// import codemirror from 'codemirror';
import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js'; // autoCloseTags

export default {
  watch: {
    'template.cabecalho': {
      handler: function (val) {
        const htmlFormatado = this.insertVariablesInHtml(val, this.valoresExemploCabecalhoRodape);
        this.cabecalho = htmlFormatado;
        this.template.cabecalho = val

        this.preview = this.cabecalho + this.items + this.rodape;
      },
    },
    'template.items': {
      handler: function (val) {
        const htmlFormatado = this.insertVariablesInHtml(val, this.valoresItemsExemplo);
        this.items = htmlFormatado;
        this.template.items = val

        this.preview = this.cabecalho + this.items + this.rodape;
      },
    },
    'template.rodape': {
      handler: function (val) {
        const htmlFormatado = this.insertVariablesInHtml(val, this.valoresExemploCabecalhoRodape);
        this.rodape = htmlFormatado;
        this.template.rodape = val

        this.preview = this.cabecalho + this.items + this.rodape;
      },
    },
  },
  data() {
    return {
      extensionEditor: [
        new Doc(),
        new Text(),
        // new Heading({ level: 1}),
        new Paragraph(),
        new Bold({ bubble: true }),
        new TextAlign(),
        new Table({ resizable: false }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        // new Print(),
      ],
      extensionsPreview: [
        new Doc(),
        new Text(),
        new Paragraph(),
      ],

      cabecalho: "",
      items: "",
      rodape: "",
      preview: "",

      template: {
        cabecalho: "",
        items: "",
        rodape: "",
      },

      valoresExemploCabecalhoRodape: {
        idcomanda: 474,
        idtipolocal: 1,
        numerolocal: 503,
        numreserva: 2607,
        coduh: 503,
        hospede: "UH:503 - Roberto Carlos",
        nome: "Roberto",
        sobrenome: "Carlos",
        datafeito: "2024-01-25",
        nomegarcom: "Marcio Ueda",
        nomepdv: "Restaurante",
        cpf: 11122233344,
        local_entrega: "Quarto: 503",
        observacaogerais: "observacao geral",
        taxasTotaisBruto: 3.75,
        subtotalSemTaxas: 37.50,
        subtotalComTaxas: 41.25,
        valorTotalSemTaxas: 27.63,
      },

      valoresItemsExemplo: {
        idcomanda: 474,
        id: 993,
        descproduto: "ARROZ COM BANANA",
        precounitario: 12.50,
        quantidade: 1,
        valortaxa: 10,
        observacao: "Sem sal",
        valortotal: 13.75,
        opcional_escolhido: '1-Bem Passado, 2-Limão'
      },

      tagsSubsCabecalhoRodape: [],
      tagsSubsItems: [],


    //   valoresExemplos: {
    //     // imgassinatura: "assinatura.jpg",
    //     numpedido: 732,
    //     datafeito: "2023-09-11",
    //     datarecebido: "2023-09-12",
    //     idtipolocal: 1,
    //     numerolocal: "12",
    //     numreserva: "1684",
    //     uh: "UH5678",
    //     nome: "Roberto Guimarães",
    //     nomegarcom: "Alisson Silva",
    //     nomepdv: "Restaurante",
    //     id_tipo_hospede_hits: 2,

    //     descproduto: 'Arroz',
    //     precounitario: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(12.5),
    //     valortotalproduto: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(10),
    //     valordescontoproduto: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(2.5),
    //     quantidade: 1,
    //     observacao: "Sem sal",
    //     valortotalprodutosemtaxa: '19.13',

    //     subtotal: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(1000),
    //     valorTotal: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(900),
    //     descontototal: "9.88",
    //     totalTaxasValor: "2.76",
    //     descontovalortotal: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(100),
    //     valorTotalSemTaxas: '27.63',
    //     subtotalComTaxas: '41.25',
    //     subtotalSemTaxas: '37.50',
    //     taxasTotaisBruto: '3.75'

    // },

    //   itemsExemplo: {
    //     produto: "Água",
    //     descricao: "dsds",
    //     quantidade: "x5"
    //   },

    //   cabecalhoExemplo: {
    //     comanda: "399",
    //     data: "10/10/2020",
    //     hora: "10:00",
    //     garcom: "João Henrique",
    //     mesa: "10",
    //     cliente: "João Tupinambá",
    //   },

      // rodapeExemplo: {
      //   total: "R$ 100,00",
      //   discontoTotal: "R$ 10,00",
      //   taxaServico: "R$ 10,00",
      //   taxaServicoPorcentagem: "10%",
      // }
    };

  },
  async mounted(){
    this.carregaTagsSubs();
    await this.carregaTemplate();
  },
  methods: {
    carregaTagsSubs() {
      for(const [key] of Object.entries(this.valoresExemploCabecalhoRodape)) {
        this.tagsSubsCabecalhoRodape.push(key)
      }

      for(const [key] of Object.entries(this.valoresItemsExemplo)) {
        this.tagsSubsItems.push(key)
      }
    },
    insertVariablesInHtml(html, variables) {
      let newHtml = html;

      Object.keys(variables).forEach((key) => {
        newHtml = newHtml.replace(new RegExp(`{{${key}}}`, "g"), variables[key]);
      });

      return newHtml;
    },
    async save() {

      const data = {
        cabecalho: this.template.cabecalho,
        items: this.template.items,
        rodape: this.template.rodape,
      }

      await this.$http.post('/pdv/salvarLayoutComanda', data)
          .then(() => {
            this.$alertar('success', 'Sucesso', 'Layout de comanda salvo com sucesso!')
          })
          .catch(() => {
            this.$alertar('warning', 'Erro!', 'Não foi possível salvar o layout da comanda!')
          })
    },
    async carregaTemplate() {
      await this.$http.get('/pdv/carregaLayoutComanda')
          .then((res) => {

            const cabecalho = res.data[0].valor
            const items = res.data[0].valor2
            const rodape = res.data[0].valor3

            this.cabecalho = cabecalho;
            this.items = items;
            this.rodape = rodape;

            // this.preview = this.insertVariablesInHtml(this.cabecalho, this.valoresExemplos) + this.insertVariablesInHtml(this.items, this.valoresExemplos) + this.insertVariablesInHtml(this.rodape, this.valoresExemplos);

            this.template.cabecalho = cabecalho;
            this.template.items = items;
            this.template.rodape = rodape;
          })
          .catch(() => {
            this.cabecalho = "";
            this.items = "";
            this.rodape = "";

            this.template.cabecalho = "";
            this.template.items = "";
            this.template.rodape = "";

            this.$alertar("warning", "Atenção", "Ainda não foi salvo nenhum layout da comanda!")
          })
    },

  }
};
</script>

<style scoped>
#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin-bottom: 100px
}

#container-editor{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 15.5px;
}

#info {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: left;
}

.tags {
  margin-top: 10px;
}

.tags ul {
  list-style-type: square;
  padding-left: 20px;
}

.editor {
  max-width: 574px;
  width: 100%;
  height: 350px;
}

th, tr, table {
  background: red;
  border: none;
  margin: 0;
}

.preview {
  width: 700px;
}

.preview-editor {
  height: 700px;
}

.tableWrapper {
  margin: 0;
  padding: 0;
  background: red !important;
}
</style>

