<template style="padding-top: 0px">
  <div style="margin-top: -10px; padding-top: -20px">
    <v-container class="">
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-text>
              <v-container class="">
                <v-row>
                  <v-col cols="12" md="3">
                    <div style="display: flex">
                      <div>
                        <v-select
                          v-model="faixaHorario"
                          :items="listaDeFaixaDeHorario"
                          @change="buscarGarconsNoCronograma()"
                          item-text="nome"
                          item-value="id"
                          return-object
                          dense
                          outlined
                          hide-details
                          aria-hidden
                          label="Faixa Horario"
                          style="
                            width: 100%;
                            height: 20px;
                            background-color: white;
                            padding: 10px;
                            border-radius: 10px;
                            margin-top: -10px;
                          "
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-btn
                          small
                          elevation=""
                          color="success"
                          data-cy="criar-ou-editar-faixa-horario"
                          @click="exibirModalCadastroDeFaixaDeHorario = true"
                        >
                          <v-icon color="">mdi-plus</v-icon>/
                          <v-icon color="">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div style="display: flex">
                      <div>
                        <v-select
                          v-model="praca"
                          :items="listaDePracas"
                          @change="buscarGarconsNoCronograma()"
                          item-text="nome"
                          item-value="id"
                          return-object
                          dense
                          outlined
                          hide-details
                          aria-hidden
                          label="Praça"
                          style="
                            width: 100%;
                            height: 20px;
                            background-color: white;
                            padding: 10px;
                            border-radius: 10px;
                            margin-top: -10px;
                          "
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-btn
                          small
                          elevation=""
                          color="success"
                          @click="exibirModalCadastroDePraca = true"
                          data-cy="criar-ou-editar-pracas"
                        >
                          <v-icon color="">mdi-plus</v-icon>/
                          <v-icon color="">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div style="display: flex; justify-content: flex-end">
                      <v-btn
                        small
                        elevation=""
                        :color="segSelecionado ? 'success' : 'default'"
                        data-cy="dia-seg"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'SEG',
                            selecionado: !segSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Seg</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-ter"
                        :color="terSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'TER',
                            selecionado: !terSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Ter</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-qua"
                        :color="quaSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'QUA',
                            selecionado: !quaSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Quar</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-qui"
                        :color="quiSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'QUI',
                            selecionado: !quiSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Qui</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-sex"
                        :color="sexSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'SEX',
                            selecionado: !sexSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Sex</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-sab"
                        :color="sabSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'SAB',
                            selecionado: !sabSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Sab</v-btn
                      >
                      <v-btn
                        small
                        elevation=""
                        data-cy="dia-dom"
                        :color="domSelecionado ? 'success' : 'default'"
                        @click="
                          setDiaSemanaSelecionado({
                            dia: 'DOM',
                            selecionado: !domSelecionado,
                          })
                        "
                        style="height: 40px; margin-right: 10px"
                        >Dom</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="">
      <v-row>
        <v-col cols="12" md="4" style="">
          <!-- <v-card style="height:70vh;">
                        <v-card-title primary-title style="display: flex;justify-content:space-between">
                            <span>Garçons</span> <span>Total de Garçom: <span id="quantidade-garcom">{{listadegarconsdisponiveis.length}}</span></span>
                        </v-card-title>
                        <v-card-text>
                            <div style="display:flex;align-items:center;height:50px;border-bottom:1px solid #c5c5c5;margin-bottom:5px;max-width:90%;" v-for="garcom in listadegarconsdisponiveis" :key="garcom.id">
                                <div style="width:90%">{{garcom.nome}}</div>
                                <div style="width:10%">
                                    <v-btn small elevation="" color="info" @click="atribuirGarcomAoCronograma(garcom)">
                                            <v-icon color="">mdi-arrow-right-bold-circle</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card> -->

          <v-data-table
            :headers="cabecalhoGarcom"
            :items="garcomDisponiveis"
            :search="pesquisaGarcom"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="pesquisaGarcom"
                  append-icon="mdi-magnify"
                  label="Pesquise por nome de garçom"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                color="info"
                data-cy="adicionar-garcom"
                @click="atribuirGarcomAoCronograma(item)"
              >
                <v-icon color="">mdi-arrow-right-bold-circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="8" background-color="primary">
          <v-card style="background-color: #f1f1f1">
            <div
              style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                padding-top: 10px;
              "
            >
              <v-container class="">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      type="text"
                      label="Nome Garçom"
                      v-model="textoFiltroGarcons"
                      outlined
                      return-object
                      hide-details
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                      small
                      elevation=""
                      color="info"
                      style="height: 40px"
                      @click="buscarGarconsNoCronograma()"
                      >Filtrar</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="4">
                    <a
                      style="text-decoration: underline"
                      @click="exibirRelatorioFolga()"
                      >Relatorio de Folgas</a
                    >
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <v-card-text data-cy="garcom-atribuido" style="max-height: 60vh; overflow: auto">
              <v-card
                style="margin-bottom: 20px; padding: 0px !important"
                elevation="3"
                v-for="garcom in listaDeGarconsAtribuidos"
                :key="garcom.id"
              >
                <v-card-text style="">
                  <v-container class="" style="padding: 10px !important">
                    <v-row style="padding: 0px">
                      <!-- {{garcom}} -->
                      <v-col style="padding: 0px; color: black" cols="6" md="4"
                        ><strong>{{ garcom.nome }}</strong></v-col
                      >
                      <v-col style="padding: 0px" cols="6" md="2">
                        <div style="display: flex">
                          Ativo?<v-switch
                            v-model="garcom.ativo"
                            data-cy="garcon.garcomativo"
                            style="
                              height: 10px;
                              padding: 0px;
                              margin: 0px;
                              margin-left: 10px;
                            "
                            @change="
                              alterarAtribuicaoGarcomGarcomAoCronograma(garcom)
                            "
                          >
                          </v-switch>
                        </div>
                      </v-col>

                      <v-col style="padding: 0px" cols="6" md="4"
                        ><a
                          style="text-decoration: underline; font-weight: bold"
                          @click="setModaFolga(garcom)"
                          >Prox. Folga: {{ garcom.proxfolga | formatarData }}</a
                        ></v-col
                      >
                      <!-- <v-col style="padding:0px;" cols="6" md="2"><a>Ver Folgas</a></v-col> -->
                      <v-col style="padding: 0px" cols="6" md="2">
                        <v-btn
                          small
                          data-cy="remover-garcom-cronograma"
                          elevation=""
                          color="error"
                          style="margin-right: 10px"
                          @click="desatribuirGarcomDoCronograma(garcom)"
                        >
                          <v-icon color="">mdi-arrow-left-bold-circle</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: 30px">
                      <!-- <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.seg = !garcom.seg; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.seg?'success':'default'" style="margin-right:10px;">seg</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.ter = !garcom.ter; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.ter?'success':'default'" style="margin-right:10px;">Ter</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.qua = !garcom.qua; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.qua?'success':'default'" style="margin-right:10px;">Qua</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.qui = !garcom.qui; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.qui?'success':'default'" style="margin-right:10px;">Qui</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.sex = !garcom.sex; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.sex?'success':'default'" style="margin-right:10px;">Sex</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.sab = !garcom.sab; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.sab?'success':'default'" style="margin-right:10px;">Sab</v-btn></v-col>
                                      <v-col style="padding:0px;" cols=""><v-btn small elevation="" @click="(garcom)=>{ garcom.dom = !garcom.dom; alterarAtribuicaoGarcomGarcomAoCronograma(garcom)}" :color="garcom.dom?'success':'default'" style="margin-right:10px;">Dom</v-btn></v-col> -->
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-seg"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'seg'
                            )
                          "
                          :color="garcom.seg ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >seg</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-ter"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'ter'
                            )
                          "
                          :color="garcom.ter ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Ter</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-qua"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'qua'
                            )
                          "
                          :color="garcom.qua ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Qua</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-qui"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'qui'
                            )
                          "
                          :color="garcom.qui ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Qui</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-sex"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'sex'
                            )
                          "
                          :color="garcom.sex ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Sex</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-sab"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'sab'
                            )
                          "
                          :color="garcom.sab ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Sab</v-btn
                        ></v-col
                      >
                      <v-col style="padding: 0px" cols=""
                        ><v-btn
                          small
                          data-cy="dia-garcom-dom"
                          elevation=""
                          @click="
                            alterarAtribuicaoGarcomGarcomAoCronograma(
                              garcom,
                              'dom'
                            )
                          "
                          :color="garcom.dom ? 'success' : 'default'"
                          style="margin-right: 10px"
                          >Dom</v-btn
                        ></v-col
                      >
                    </v-row>

                    <v-row style="margin-top: 30px; height: 40px">
                      <v-col style="padding: 0px; color: black" cols="6" md="6">
                        <div style="display: flex">
                          Room Service?<v-switch
                            v-model="garcom.roomservice"
                            data-cy="garcon.garcomonline"
                            style="
                              height: 10px;
                              padding: 0px;
                              margin: 0px;
                              margin-left: 10px;
                            "
                            @change="
                              alterarAtribuicaoGarcomGarcomAoCronograma(garcom)
                            "
                          >
                          </v-switch>
                        </div>
                      </v-col>
                      <v-col style="padding: 0px; color: black" cols="6" md="6">
                        <div style="display: flex">
                          Em Evento?
                          <v-switch
                            v-model="garcom.flevento"
                            data-cy="garcon.garcomonline"
                            style="
                              height: 10px;
                              padding: 0px;
                              margin: 0px;
                              margin-left: 10px;
                            "
                            @change="
                              alterarAtribuicaoGarcomGarcomAoCronograma(garcom)
                            "
                          >
                          </v-switch>
                          <v-select
                            v-if="garcom.flevento"
                            v-model="garcom.idpdvevento"
                            :items="listaDeEventos"
                            @change="
                              alterarAtribuicaoGarcomGarcomAoCronograma(garcom)
                            "
                            item-text="descpdv"
                            item-value="idpdv"
                            dense
                            outlined
                            hide-details
                            aria-hidden
                            label="Eventos"
                            style="
                              width: 6%;
                              height: 20px;
                              background-color: white;
                              padding: 10px;
                              border-radius: 10px;
                              margin-top: -10px;
                            "
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="exibirModalFolga" width="80vw" max-width="600px">
      <div
        style="
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
        "
      >
        <div>
          <v-container class="pa-4" style="background-color: #f1f1f1">
            <v-row>
              <v-col cols="12" md="12" v-if="garcomSelecionado">
                <h4>Folgas de {{ garcomSelecionado.nome }}</h4>
              </v-col>

              <v-col cols="12" md="12">
                <v-container class="">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="date"
                        label="Data Próxima Folga"
                        v-model="dataFolga"
                        outlined
                        return-object
                        hide-details
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="text"
                        label="Descrição"
                        v-model="descFolga"
                        outlined
                        return-object
                        hide-details
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-btn
                        small
                        elevation=""
                        color="info"
                        block
                        @click="adicionarFolga()"
                        >Adicionar Folga</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-data-table
                      :headers="headersfolgas"
                      :items="listadefolgasgarcom"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.dia`]="{ item }">
                        {{ item.dia | formatarData }}
                      </template>
                      <template v-slot:[`item.id`]="{ item }">
                        <v-btn
                          small
                          elevation=""
                          color="error"
                          @click="() => removerFolga(item.id)"
                        >
                          <v-icon color="">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" md="12">
                <!-- <v-textarea label="Descrição"  v-model="atividadeCriar.descricao" outlined return-object hide-details dense >
                            </v-textarea>  -->
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col>
                    <!-- <v-btn elevation="" @click="() => exibirModalCriarAtividade=false" color="error" block> Cancelar</v-btn>  -->
                  </v-col>

                  <v-col>
                    <!-- <v-btn elevation="" @click="() => registrarAtividade()" color="info" block>  Registrar</v-btn>  -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="exibirModalCadastroDeFaixaDeHorario"
      width="80vw"
      max-width="600px"
    >
      <div
        style="
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
        "
      >
        <div>
          <v-container class="pa-4" style="background-color: #f1f1f1">
            <v-row>
              <v-col cols="12" md="12">
                <h4>Cadastrar Nova Faixa de Horarios</h4>
              </v-col>

              <v-col cols="12" md="12">
                <div style="display: flex">
                  <div>
                    <label style="font-size: 18px; margin-right: 1em"
                      >Horario Inicial?</label
                    >
                    <v-text-field
                      type="time"
                      outlined
                      hide-details
                      dense
                      v-model="horaInicialNovaFaixa"
                      data-cy="horaInicialNovaPraca"
                    >
                    </v-text-field>
                  </div>
                  <div style="margin-left: 20px">
                    <label style="font-size: 18px; margin-right: 1em"
                      >Horario Final?</label
                    >
                    <v-text-field
                      type="time"
                      outlined
                      hide-details
                      dense
                      v-model="horaFinalNovaFaixa"
                      data-cy="horaFinalNovaPraca"
                    >
                    </v-text-field>
                  </div>
                  <div style="margin-left: 20px; padding-top: 25px">
                    <v-btn
                      small
                      elevation=""
                      color="success"
                      large
                      data-cy="adicionar-faixa-horario"
                      @click="adicionarFaixaDeHorario()"
                      >Adicionar</v-btn
                    >
                  </div>
                </div>
              </v-col>

              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headersHorarios"
                  :items="listaDeFaixaDeHorario"
                  class="elevation-1"
                  data-cy="tabela-de-horarios"
                >
                  <template v-slot:[`item.id`]="{ item }">
                    <v-btn
                      small
                      elevation=""
                      color="error"
                      @click="confirmarRemocaoHorario(item)"
                    >
                      <v-icon color="">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="exibirModalCadastroDePraca"
      width="80vw"
      max-width="600px"
    >
      <div
        style="
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
        "
      >
        <div>
          <v-container class="pa-4" style="background-color: #f1f1f1">
            <v-row>
              <v-col cols="12" md="12">
                <h4>Cadastrar Nova Praça</h4>
              </v-col>

              <v-col cols="12" md="12">
                <div style="display: flex">
                  <div>
                    <label style="font-size: 18px; margin-right: 1em"
                      >Mesa Inicial?</label
                    >
                    <v-text-field
                      type="number"
                      outlined
                      hide-details
                      dense
                      v-model="mesaInicialNovaPraca"
                      data-cy="MesaInicialNovaFaixa"
                    >
                    </v-text-field>
                  </div>
                  <div style="margin-left: 20px">
                    <label style="font-size: 18px; margin-right: 1em"
                      >Mesa Final?</label
                    >
                    <v-text-field
                      type="number"
                      outlined
                      hide-details
                      dense
                      v-model="mesaFinalNovaPraca"
                      data-cy="MesaFinalNovaFaixa"
                    >
                    </v-text-field>
                  </div>
                  <div style="margin-left: 20px; padding-top: 25px">
                    <v-btn
                      small
                      elevation=""
                      color="success"
                      large
                      data-cy="adicionar-faixa-pracas"
                      @click="adicionarFaixaDePraca()"
                      >Adicionar</v-btn
                    >
                  </div>
                </div>
              </v-col>

              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headersPracas"
                  :items="listaDePracas"
                  class="elevation-1"
                  data-cy="tabela-de-pracas"
                >
                  <template v-slot:[`item.id`]="{ item }">
                    <v-btn
                      small
                      elevation=""
                      color="error"
                      @click="confirmarRemocaoPraca(item)"
                    >
                      <v-icon color="">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="exibirConfirmarRemocaoPraca"
      width="80vw"
      max-width="600px"
    >
      <v-card>
        <v-card-text class="pa-10">
          <h2>Confirma a exclusão da Praça {{ praca.nome }}?</h2>
          <div
            style="
              margin-top: 50px;
              display: flex;
              justify-content: space-between;
            "
          >
            <v-btn
              small
              elevation=""
              color="error"
              @click="exibirConfirmarRemocaoPraca = false"
              >Cancelar</v-btn
            >
            <v-btn small elevation="" color="info" @click="removerPraca()"
              >Confirmar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="exibirConfirmarRemocaoHorario"
      width="80vw"
      max-width="600px"
    >
      <v-card>
        <v-card-text class="pa-10">
          <h2>
            Confirma a exclusão da Faixa de Horario {{ faixaHorario.nome }}?
          </h2>
          <div
            style="
              margin-top: 50px;
              display: flex;
              justify-content: space-between;
            "
          >
            <v-btn
              small
              elevation=""
              color="error"
              @click="exibirConfirmarRemocaoHorario = false"
              >Cancelar</v-btn
            >
            <v-btn small elevation="" color="info" @click="removerHorario()"
              >Confirmar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="exibirModalRelatorioFolgas"
      width="80vw"
      max-width="600px"
    >
      <v-card>
        <v-card-text class="pa-10">
          <v-container class="">
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <label style="font-size: 18px; margin-right: 1em"
                    >Data Inicial</label
                  >
                  <v-text-field
                    type="date"
                    outlined
                    hide-details
                    dense
                    v-model="relatorioFolgas_dtInicial"
                    data-cy="horaInicialNovaFaixa"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div style="margin-left: 20px">
                  <label style="font-size: 18px; margin-right: 1em"
                    >Data Final</label
                  >
                  <v-text-field
                    type="date"
                    outlined
                    hide-details
                    dense
                    v-model="relatorioFolgas_dtFinal"
                    data-cy="horaFinalNovaFaixa"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <div style="">
                  <v-btn
                    small
                    elevation=""
                    color="info"
                    block
                    @click="gerarRelatorioDeFolgas()"
                  >
                    Gerar
                  </v-btn>
                  <JsonCSV
                    :data="dadosRelatorioDeFolga"
                    v-if="dadosRelatorioDeFolga.length > 0"
                    :name="nomeRelatorioDeFolgas"
                  >
                    <!-- <div style="background-color:#2b81d6;width:450px;height:40px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;"> -->
                    <v-btn
                      small
                      elevation=""
                      style="margin-top: 20px"
                      color="success"
                      block
                      @click="gerarRelatorioDeFolgas()"
                    >
                      Download Planilha de Folgas
                    </v-btn>
                    <!-- </div> -->
                  </JsonCSV>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import JsonCSV from "vue-json-csv";

// const dayjs = require('dayjs')

export default {
  name: "ViewCaixa",
  components: {
    JsonCSV,
  },
  data: () => ({
    cabecalhoGarcom: [
      {
        text: "Nome garcom",
        value: "nome",
        width: "90%",
      },
      {
        text: "Adicionar",
        value: "actions",
        sortable: false,
        width: "10%",
      },
    ],
    garcomDisponiveis: [],
    pesquisaGarcom: "",
    textoFiltroGarcons: null,
    dadosRelatorioDeFolga: [],
    nomeRelatorioDeFolgas: "",
    relatorioFolgas_dtInicial: null,
    relatorioFolgas_dtFinal: null,
    exibirModalRelatorioFolgas: false,
    exibirConfirmarRemocaoPraca: false,
    exibirConfirmarRemocaoHorario: false,
    segSelecionado: false,
    terSelecionado: false,
    quaSelecionado: false,
    quiSelecionado: false,
    sexSelecionado: false,
    sabSelecionado: false,
    domSelecionado: false,
    horaInicialNovaFaixa: null,
    horaFinalNovaFaixa: null,
    mesaInicialNovaPraca: null,
    mesaFinalNovaPraca: null,
    exibirModalCadastroDeFaixaDeHorario: false,
    exibirModalCadastroDePraca: false,
    headersfolgas: [
      { text: "Dia", value: "dia", width: "80%" },
      { text: "Ação", value: "id", width: "20%" },
    ],
    listadefolgasgarcom: [
      { id: 1, dia: "2022-05-06" },
      { id: 2, dia: "2022-05-06" },
      { id: 3, dia: "2022-05-06" },
    ],
    dataFolga: null,
    descFolga: null,
    exibirModalFolga: false,
    garcomSelecionado: null,
    telaatual: "fluxo",
    listadegarconsdisponiveis: [
      { id: 1, nome: "Herlessi Nogueira" },
      { id: 2, nome: "Mauricio Savio" },
      { id: 3, nome: "Edilson Vieira" },
      { id: 4, nome: "Marcell Vaz" },
    ],
    faixaHorario: {},
    listaDeFaixaDeHorario: [],
    headersHorarios: [
      { text: "Faixa de Horários", value: "nome", width: "50%" },
      { text: "Ação", value: "id", width: "50%" },
    ],
    praca: {},
    listaDePracas: [],
    headersPracas: [
      { text: "Faixa de Mesas", value: "nome", width: "50%" },
      { text: "Ação", value: "id", width: "50%" },
    ],
    evento: {},
    listaDeEventos: [],
    listaDeGarconsAtribuidos: [],
  }),
  methods: {
    exibirRelatorioFolga() {
      this.dadosRelatorioDeFolga = [];
      this.exibirModalRelatorioFolgas = true;
    },
    gerarRelatorioDeFolgas() {
      this.nomeRelatorioDeFolgas =
        "Relatorio_de_Folgas_" +
        this.relatorioFolgas_dtInicial +
        "_a_" +
        this.relatorioFolgas_dtFinal +
        ".csv";

      let param = {
        idrelatorio: 6,
        datainicial: this.relatorioFolgas_dtInicial,
        datafinal: this.relatorioFolgas_dtFinal,
        idstatus: 0,
      };

      this.$http
        .post("pdv/relatorios", param)
        .then((resp) => {
          this.dadosRelatorioDeFolga = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    confirmarRemocaoPraca(item) {
      this.praca = item;
      this.exibirConfirmarRemocaoPraca = true;
    },
    removerPraca() {
      this.$http
        .post("pdv/buscarGarconsNaFaixaDePraca", { idpraca: this.praca.id })
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {
            alert(
              "Não é possível remover uma praça que contenha garçons atribuídos!"
            );
          } else {
            this.$http
              .post("pdv/removerFaixaDePraca", { idpraca: this.praca.id })
              .then(() => {
                this.buscarCronogramaFaixaPraca();
                this.exibirConfirmarRemocaoPraca = false;
              })
              .catch(() => {
                alert("Não foi possível");
              });
          }
        })
        .catch(() => {
          alert(
            "Não é possível remover uma praça que contenha garçons atribuídos!"
          );
        });
    },
    confirmarRemocaoHorario(item) {
      this.faixaHorario = item;
      this.exibirConfirmarRemocaoHorario = true;
    },
    removerHorario() {
      this.$http
        .post("pdv/buscarGarconsNaFaixaDeHorario", {
          idhorario: this.faixaHorario.id,
        })
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {
            alert(
              "Não é possível remover uma faixa de horário que contenha garçons atribuídos!"
            );
          } else {
            this.$http
              .post("pdv/removerFaixaDeHorario", {
                idhorario: this.faixaHorario.id,
              })
              .then(() => {
                this.buscarCronogramaFaixaHorario();
                this.exibirConfirmarRemocaoHorario = false;
              })
              .catch(() => {
                alert("Não foi possível");
              });
          }
        })
        .catch(() => {
          alert(
            "Não é possível remover uma faixa de horário que contenha garçons atribuídos!"
          );
        });
    },
    adicionarFolga() {
      if (this.dataFolga && this.descFolga) {
        let objeto = {
          idusuariogarcom: this.garcomSelecionado.idusuario,
          dia: this.dataFolga,
          descricao: this.descFolga,
        };

        this.$http
          .post("pdv/adicionarFolga", objeto)
          .then(() => {
            this.listadefolgasgarcom = [];
            this.buscarFolgasDoGarcom();
            this.buscarGarconsNoCronograma();
            this.$alertar("success", "Parametro Atualizado com Sucesso!", "=)");
          })
          .catch(() => {
            this.$alertar(
              "success",
              "Não foi Possível atualizar com Sucesso!",
              "=("
            );
          });
      } else {
        alert("Selecione uma data e uma descrição para a Folga!");
      }
    },
    removerFolga(id) {

      const url = `pdv/removerFolga/${id}`

      this.$http.delete(url)
      .then(() => {
        this.listadefolgasgarcom = [];
        this.buscarFolgasDoGarcom();
        this.buscarGarconsNoCronograma();
        this.$alertar("success", "Parametro removido com sucesso", "=)");
      })
      .catch((err) => {
        const message = err.response.data.message
        this.$alertar("warning", message, "=(");
      })
    },
    setDiaSemanaSelecionado(dados) {
      if (dados.dia == "SEG" && dados.selecionado === true) {
        this.segSelecionado = true;
      } else {
        if (dados.dia == "SEG" && dados.selecionado === false) {
          this.segSelecionado = false;
        }
      }

      if (dados.dia == "TER" && dados.selecionado === true) {
        this.terSelecionado = true;
      } else {
        if (dados.dia == "TER" && dados.selecionado === false) {
          this.terSelecionado = false;
        }
      }

      if (dados.dia == "QUA" && dados.selecionado === true) {
        this.quaSelecionado = true;
      } else {
        if (dados.dia == "QUA" && dados.selecionado === false) {
          this.quaSelecionado = false;
        }
      }

      if (dados.dia == "QUI" && dados.selecionado === true) {
        this.quiSelecionado = true;
      } else {
        if (dados.dia == "QUI" && dados.selecionado === false) {
          this.quiSelecionado = false;
        }
      }

      if (dados.dia == "SEX" && dados.selecionado === true) {
        this.sexSelecionado = true;
      } else {
        if (dados.dia == "SEX" && dados.selecionado === false) {
          this.sexSelecionado = false;
        }
      }

      if (dados.dia == "SAB" && dados.selecionado === true) {
        this.sabSelecionado = true;
      } else {
        if (dados.dia == "SAB" && dados.selecionado === false) {
          this.sabSelecionado = false;
        }
      }

      if (dados.dia == "DOM" && dados.selecionado === true) {
        this.domSelecionado = true;
      } else {
        if (dados.dia == "DOM" && dados.selecionado === false) {
          this.domSelecionado = false;
        }
      }

      this.buscarGarconsNoCronograma();
    },
    async setModaFolga(garcom) {
      this.listadefolgasgarcom = [];
      this.garcomSelecionado = garcom;
      await this.buscarFolgasDoGarcom();
      this.exibirModalFolga = true;
    },
    continuar() {
      this.$router.push("/Hospede/Menu");
    },
    async setarTela(tela) {
      this.telaatual = tela;
    },
    salvar(parametros) {
      if (parametros.tela == "fluxo") {
        this.$http
          .put("pdv/updateparametros", parametros.parametro)
          .then(() => {
            this.$alertar("success", "Parametro Atualizado com Sucesso!", "=)");
          })
          .catch(() => {
            this.$alertar(
              "success",
              "Não foi Possível atualizar com Sucesso!",
              "=("
            );
          });
      }
    },
    validarAtribuicaoDeGarcomAoCronograma() {
      let error = false;

      if (this.praca.id && this.faixaHorario.id) {
        if (
          this.segSelecionado ||
          this.terSelecionado ||
          this.quaSelecionado ||
          this.quiSelecionado ||
          this.sexSelecionado ||
          this.sabSelecionado ||
          this.domSelecionado
        ) {
          error = true;
        }
      }

      return error;
    },
    atribuirGarcomAoCronograma(garcom) {
      let validado = this.validarAtribuicaoDeGarcomAoCronograma();
      if (validado) {
        let obj = {
          idusuariogarcom: garcom.idusuario,
          idhorario: this.faixaHorario.id,
          idpraca: this.praca.id,
          seg: this.segSelecionado,
          ter: this.terSelecionado,
          qua: this.quaSelecionado,
          qui: this.quiSelecionado,
          sex: this.sexSelecionado,
          sab: this.sabSelecionado,
          dom: this.domSelecionado,
          ativo: garcom.ativo,
          roomservice: garcom.roomservice,
        };
        // alert(JSON.stringify(obj,null,2))
        this.$http
          .post("pdv/atribuirGarcomAoCronograma", obj)
          .then(() => {
            this.buscarGarcons();
          })
          .catch(() => {
            this.$alertar(
              "success",
              "Não foi Possível Atribuir ao Garçom!",
              "=("
            );
          });
      } else {
        alert(
          "Selecione os campos de Faixa de Horario, praça e dia antes de atribuir um garçom ao cronograma"
        );
      }
    },
    alterarAtribuicaoGarcomGarcomAoCronograma(garcom, dia) {
      if (dia == "seg") {
        garcom.seg = !garcom.seg;
      }
      if (dia == "ter") {
        garcom.ter = !garcom.ter;
      }
      if (dia == "qua") {
        garcom.qua = !garcom.qua;
      }
      if (dia == "qui") {
        garcom.qui = !garcom.qui;
      }
      if (dia == "sex") {
        garcom.sex = !garcom.sex;
      }
      if (dia == "sab") {
        garcom.sab = !garcom.sab;
      }
      if (dia == "dom") {
        garcom.dom = !garcom.dom;
      }

      // alert(garcom.seg+'-'+garcom.ter+'-'+garcom.qua+'-'+garcom.qui+'-'+garcom.sex+'-'+garcom.sab+'-'+garcom.dom)
      if (garcom.seg == true) {
        this.segSelecionado = true;
      } else {
        this.segSelecionado = false;
      }
      if (garcom.ter == true) {
        this.terSelecionado = true;
      } else {
        this.terSelecionado = false;
      }
      if (garcom.qua == true) {
        this.quaSelecionado = true;
      } else {
        this.quaSelecionado = false;
      }
      if (garcom.qui == true) {
        this.quiSelecionado = true;
      } else {
        this.quiSelecionado = false;
      }
      if (garcom.sex == true) {
        this.sexSelecionado = true;
      } else {
        this.sexSelecionado = false;
      }
      if (garcom.sab == true) {
        this.sabSelecionado = true;
      } else {
        this.sabSelecionado = false;
      }
      if (garcom.dom == true) {
        this.domSelecionado = true;
      } else {
        this.domSelecionado = false;
      }

      let validado = this.validarAtribuicaoDeGarcomAoCronograma();
      if (validado) {
        let obj = {
          idusuariogarcom: garcom.idusuario,
          idhorario: garcom.idhorario,
          idpraca: garcom.idpraca,
          seg: garcom.seg,
          ter: garcom.ter,
          qua: garcom.qua,
          qui: garcom.qui,
          sex: garcom.sex,
          sab: garcom.sab,
          dom: garcom.dom,
          ativo: garcom.ativo,
          roomservice: garcom.roomservice,
          flevento: garcom.flevento,
          idpdvevento: garcom.idpdvevento,
        };

        this.$http
          .post("pdv/atribuirGarcomAoCronograma", obj)
          .then(() => {
            this.buscarGarcons();
          })
          .catch(() => {
            this.$alertar(
              "success",
              "Não foi Possível Atribuir o Garçom ao Cronograma!",
              "=("
            );
          });
      } else {
        alert(
          "Selecione os campos de Faixa de Horario, praça e dia antes de atribuir um garçom ao cronograma"
        );
      }
    },

    desatribuirGarcomDoCronograma(garcom) {
      let obj = { idusuariogarcom: garcom.idusuario };
      this.$http
        .post("pdv/desatribuirGarcomDoCronograma", obj)
        .then(() => {
          this.buscarGarcons();
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível retirar ao Garçom do Cronograma!",
            "=("
          );
        });
    },
    buscarGarcons() {
      this.buscarGarconsDisponiveis();
      this.buscarGarconsNoCronograma();
    },
    buscarGarconsDisponiveis() {
      // let obj = { nome:this.pesquisanome, email:this.pesquisaemail, idperfil:5 }
      let obj = { idperfil: 5, ativo: true, filtro: "Todos" };

      this.$http
        .post("pdv/buscarusuarios", obj)
        .then((resp) => {
          this.listadegarconsdisponiveis = resp.data;
          this.garcomDisponiveis = this.listadegarconsdisponiveis.map(
            ({ nome, ativo, idusuario }) => ({ nome, ativo, idusuario })
          );
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível buscar os usuarios!",
            "=("
          );
        });
    },
    buscarGarconsNoCronograma() {
      // let obj = { nome:this.pesquisanome, email:this.pesquisaemail, idperfil:5 }
      let obj = {
        idperfil: 5,
        atribuidoAoCronograma: true,
        idhorario: this.faixaHorario.id,
        idpraca: this.praca.id,
        seg: this.segSelecionado,
        ter: this.terSelecionado,
        qua: this.quaSelecionado,
        qui: this.quiSelecionado,
        sex: this.sexSelecionado,
        sab: this.sabSelecionado,
        dom: this.domSelecionado,
      };

      if (this.textoFiltroGarcons) {
        obj.nome = this.textoFiltroGarcons;
      }

      this.$http
        .post("pdv/buscarGarconsNoCronograma", obj)
        .then((resp) => {
          this.listaDeGarconsAtribuidos = resp.data;
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível buscar os usuarios!",
            "=("
          );
        });
      console.log(this.listaDeGarconsAtribuidos);
    },
    adicionarFaixaDeHorario() {
      let obj = {
        horainicial: this.horaInicialNovaFaixa,
        horafinal: this.horaFinalNovaFaixa,
      };
      this.$http
        .post("pdv/adicionarFaixaDeHorario", obj)
        .then(() => {
          this.buscarCronogramaFaixaHorario();
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível adicionar faixa de horario!",
            "=("
          );
        });
    },
    buscarCronogramaFaixaHorario() {
      this.$http
        .post("pdv/getCronogramaFaixaHorario")
        .then((resp) => {
          if (resp.data && resp.data.length > 0) {
            this.listaDeFaixaDeHorario = resp.data;
            this.faixaHorario = this.listaDeFaixaDeHorario[0];
          }
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível buscar as faixas de horários!",
            "=("
          );
        });
    },
    adicionarFaixaDePraca() {
      let obj = {
        pracainicial: this.mesaInicialNovaPraca,
        pracafinal: this.mesaFinalNovaPraca,
      };
      this.$http
        .post("pdv/adicionarFaixaDePraca", obj)
        .then(() => {
          this.buscarCronogramaFaixaPraca();
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível adicionar Faixa de Praça!",
            "=("
          );
        });
    },
    buscarCronogramaFaixaPraca() {
      this.$http
        .post("pdv/getCronogramaFaixaPraca")
        .then((resp) => {
          if (resp.data && resp.data.length > 0) {
            this.listaDePracas = resp.data;
            this.praca = this.listaDePracas[0];
            console.log(resp.data);
          }
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível buscar as faixas de Praças!",
            "=("
          );
        });
    },
    buscarFolgasDoGarcom() {
      this.listadefolgasgarcom = [];
      let objeto = {
        idusuariogarcom: this.garcomSelecionado.idusuario,
      };
      this.$http
        .post("pdv/buscarFolgasDoGarcom", objeto)
        .then((resp) => {
          if (resp && resp.data && resp.data.length > 0) {
            this.listadefolgasgarcom = resp.data;
          }

          // alert(JSON.stringify(this.listadefolgasgarcom,null,2))
        })
        .catch(() => {
          this.$alertar(
            "success",
            "Não foi Possível buscar as folgas do garçons!",
            "=("
          );
        });
    },
    getEventosCadastrados(idTipo) {
      this.$http("pdv/getPDVSCDLOCAL/" + idTipo)
        .then(async (resp) => {
          if (resp.data && resp.data.length > 0) {
            let listaRetornado = resp.data;
            let listaFiltrada = listaRetornado.filter((x) => {
              return x.marcadolocal;
            });
            this.listaDeEventos = listaFiltrada;
          }
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
  },
  async mounted() {
    this.$store.dispatch("set_titulo", "Configurações");
    console.log(dayjs().format("YYYY-MM-DD"));
    this.buscarGarcons();
    this.buscarCronogramaFaixaHorario();
    this.buscarCronogramaFaixaPraca();
    this.getEventosCadastrados(3);
    this.relatorioFolgas_dtInicial = dayjs().format("YYYY-MM-DD");
    this.relatorioFolgas_dtFinal = dayjs().format("YYYY-MM-DD");
  },
};
</script>

<style scoped></style>
