<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="info"
            small
            elevation=""
            style="margin-right:20px; align-self: center; justify-self: center;"
            v-bind="attrs"
            v-on="on"
        >
          Trans. Comanda
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Transferência de Comanda</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-autocomplete
                  :items="['Hóspede', 'Day Use', 'Passante']"
                  label="Qual o tipo de hóspede?"
                  item-text="nome"
                  item-value="idcheckin"
                  required
                  v-model="tipoHospede"
              ></v-autocomplete>
            </v-row>
            <v-row v-show="tipoHospede != null">
                <v-autocomplete
                    :items="hospedeFiltrados"
                    label="Pra quem deseja transferir?"
                    item-text="nome"
                    item-value="idcheckin"
                    required
                    v-model="hospedeSelecionado"
                ></v-autocomplete>
            </v-row>
            <v-row>
                <v-textarea
                    label="Motivo da transferência"
                    required
                    v-model="motivoTransferencia"
                ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
              color="success"
              @click="() => transferirComanda()"
          >
            Transferir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup>
export default {
  data: () => ({
    dialog: false,

    tipoHospede: null,
    hospedeFiltrados: [],
    hospedes: [],
    hospedeSelecionado: null,
    motivoTransferencia: null,
  }),
  props: ["pedido"],
  async mounted() {
    await this.listarHospedes();
  },
  watch: {
    tipoHospede: function (val) {
      this.hospedeSelecionado = null

      if(val === "Passante") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.passante)
      }

      if (val === "Hóspede") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.id_tipo_hospede_hits === 0 || hospede.id_tipo_hospede_hits === 1)
      }

      if(val === "Day Use") {
        this.hospedeFiltrados = this.hospedes.filter(hospede => hospede.id_tipo_hospede_hits === 2)
      }
    },
  },
  methods: {
    fecharModal() {
      this.dialog = false
    },

    async listarHospedes() {
      await this.$http.get("pdv/listarHospedesParaTransferencia")
          .then(res => {
            this.hospedes = res.data
          })
          .catch(err => {
            console.log(err)
          })
    },

    async transferirComanda() {

      if(!this.motivoTransferencia) {
        this.$alertar("info", "Atenção", "Informe o motivo da transferência")
      }

      if(!this.hospedeSelecionado) {
        this.$alertar("info", "Atenção", "Selecione o hospede para transferir a comanda")
      }

      if(this.motivoTransferencia.trim().length < 3) {
        this.$alertar("info", "Atenção", "Informe um motivo válido")
      }

      const data = {
        novoDono: {
          idcheckin: this.hospedeSelecionado,
          nome: this.hospedes.find(hospede => hospede.idcheckin === this.hospedeSelecionado).nome,
        },
        antigoDono: {
          idcheckin: this.pedido.idCheckin,
          nome: this.pedido.nome,
        },
        idcomanda: this.pedido.numpedido,
        idusuarioresponsavel: localStorage.getItem("idusuario"),
        motivo: this.motivoTransferencia
      }

      await this.$http.post("pdv/transferirDonoDeComanda", data)
          .then(() => {
            window.location.reload();
            this.dialog = false
          })
          .catch(err => {
            this.$alertar("info", "Erro", err.response.data?.message || err.response.data)
          })
    },

    limparCampos() {
      this.hospedeSelecionado = null
      this.motivoTransferencia = null
    }
  }
}

</script>

<style scoped>

</style>
