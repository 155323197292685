<template>
    <v-stepper v-if="configuracoes.novoAutoatendimento" id="container" v-model="e1" v-show="!carregando">
        <v-stepper-header>
            <v-stepper-step editable step="1">
              Boas Vindas
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
              Cores
            </v-stepper-step>

            <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <BoasVindaConfiguracoes />
            </v-stepper-content>

          <v-stepper-content step="2">
            <CoresConfiguracoes />
          </v-stepper-content>


        </v-stepper-items>
    </v-stepper>
     <v-card v-else>
        <v-card-text>
            <v-alert type="info" dismissible>
                <span class="font-weight-bold">Atenção!</span> Para acessar as configurações do novo autoatendimento, ative a opção "Novo Autoatendimento" no menu de configurações.
            </v-alert>

            <br/>
            <v-switch
                v-model="configuracoes.novoAutoatendimentoConfigurado"
                label="Novo Autoatendimento"
                color="primary"
                data-cy="novo-autoatendimento"
            ></v-switch>

            <v-btn class="save-button" color="primary" @click="salvarConfiguracoesNovoAutoAtendimento" data-cy="salvar">
                Salvar
            </v-btn>
        </v-card-text>
     </v-card>
</template>

<script>
  import BoasVindaConfiguracoes from "@/components/configuracoes/HospedeVizualizacao/BoasVindas.vue";
  import CoresConfiguracoes from "@/components/configuracoes/HospedeVizualizacao/Cores.vue";

  export default {
    name: 'Comandas Configuracoes',
    data: function () {
      return {
        configuracoes: {
          novoAutoatendimento: false,
          novoAutoatendimentoConfigurado: false,
        },
      }
    },
    mounted() {
      this.buscarDadosIntegracaoNovoAutoatendimento();
    },
    components: {
      CoresConfiguracoes,
      BoasVindaConfiguracoes
    },
    methods: {
      async buscarDadosIntegracaoNovoAutoatendimento() {
        const { data } = await this.$http.get('/pdv/buscarIntegracaoNovoCardapioHospedes')

        if(!data) {
          this.configuracoes.novoAutoatendimento = false;
          this.configuracoes.novoAutoatendimentoConfigurado = false;

          return;
        }

        this.configuracoes.novoAutoatendimento = data.ativo
        this.configuracoes.novoAutoatendimentoConfigurado = data.ativo
      },
      async salvarConfiguracoesNovoAutoAtendimento() {
        const body = {
          ativo: this.configuracoes.novoAutoatendimentoConfigurado
        }
        try {
          const { data } = await this.$http.put('/pdv/ativarOuInativarNovoCardapioAutoatendimento', body)

          this.configuracoes.novoAutoatendimento = data.ativo
        } catch (e) {
          this.$alertar(
              "warning",
              "Não foi possível salvar as configurações do novo autoatendimento",
              "=("
          )
        }
      },
    },
  }
</script>

