<template>

    <div class="ddpms">
        <span style="font-weight:bold;color:#036097;">ITENS EXIBIDOS NO CARDÁPIO DIGITAL</span>
        <v-card  elevation="2" outlined  style="padding-bottom:0px!important;height:19%;max-height:110px;padding:1px;" >
            <v-card-text class="" style="margin-top:0px;margin-bottom:0px;padding: 2px;">
                <div style="height:70px;display:flex;">
                    <div style="width:27%;margin-left:3%;">
                        <label for="">PDV</label>
                        <select v-model="idpdvcd" data-cy="idpdvcd" class="select" @change="getCategoriasCD()">
                            <option :style="!pdv.ativo ? 'color:red': '' " v-for="pdv in listaPDVsCD"  :key="pdv.idpdv" :value="pdv.idpdv" >
                                {{pdv.descpdv +(!pdv.ativo ? ' [PAUSADO]':'' )}}
                            </option>
                            
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                            <a style="text-decoration: underline;" data-v-step-cardapio="5" data-cy="setModalEdicaoPDV" >Editar</a>
                            <a style="text-decoration: underline;" data-v-step-cardapio="13" data-cy="setModalCriarPDV" >Criar</a>
                        </div>

                    </div>

                    <div style="width:27%;margin-left:3.5%;">
                        
                        <label for="">Categoria</label>
                        <select v-model="idcategoriacd" data-cy="idcategoriacd" class="select" @change="getSubCategoriasCD()">
                            <option :style="!categoria.ativo ? 'color:red': '' " v-for="categoria in listaCategoriasCD"  :key="categoria.idcategoriaprodcd" :value="categoria.idcategoriaprodcd" >
                                {{categoria.nomecategoriaprodcd +(!categoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                            <a style="text-decoration: underline;" data-v-step-cardapio="14" data-cy="setModalEdicaoCategoria" >Editar</a>
                            <a style="text-decoration: underline;" data-cy="setModalCriarCategoria" >Criar</a>
                        </div>
                        
                    </div>

                    <div style="width:27%;margin-left:3%;">
                        
                        <label for="">Sub Categoria</label>
                        <select v-model="idsubcategoriacd" data-cy="idsubcategoriacd" class="select" @change="getProdutosCD()">
                            <option  value="0" >
                               -
                            </option>
                            <option :style="!subcategoria.ativo ? 'color:red': '' " v-for="subcategoria in listaSubCategoriasCD"  :key="subcategoria.idsubcategoriaprodcd" :value="subcategoria.idsubcategoriaprodcd" >
                                {{subcategoria.nomesubcategoriaprodcd +(!subcategoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div>
                            <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                                <a style="text-decoration: underline;" data-cy="setModalEdicaoSubCategoria" >Editar</a>
                                <a style="text-decoration: underline;" data-cy="setModalCriarSubCategoria" >Criar</a>
                            </div>
                        </div>

                    </div>

                    <div style="width:2%;margin-left:1%;align-self: center">
                        
                        <v-btn><v-icon style="color:black;">mdi-eye</v-icon></v-btn>

                    </div>
                </div>
            </v-card-text>
            
        </v-card>

        <v-card  elevation="2" outlined  class="mt-2" height="80.5%" style="max-height:65vh;">
            <v-card-text class="">
                <div>
                 <a @click="edicaoemmassa=true" v-if="!edicaoemmassa">Edição Rapida de PDV x Preço</a>
                 <a @click="edicaoemmassa=false" style="margin-right:50px;" v-else>cancelar</a>
        
                 <v-btn small elevation="" color="info" v-if="edicaoemmassa">Vincular Marcados ao Preços da ESQUERDA</v-btn>
                 </div>
                <div style="width:100%;display:flex; flex-wrap:wrap;height:55vh;max-height:55vh;overflow:auto;align-items:flex-start;justity-content:flex-start;align-content:flex-start;padding-bottom:10px;">
                       
                    <v-card v-for="prod in listaProdutosCD" :key="prod.idproduto" elevation="2" outlined  class="mt-2 ml-6" height="150px" width="28%"
                    style="padding:0px;display:flex;align-items:flex-start;padding-top:10px;padding-left:10px;word-break: break-all;">
                        <v-card-text style="color:#5a5858;font-size:12px;padding:0px;padding-left:5px;padding-right:5px;font-weight:bold;">
                            
                            <v-card-text style="text-align:end;font-size:12px;padding:0px;padding-left:0px;padding-right:5px;">
                                <div style="display:flex;justify-content:space-between;align-items:flex-end;">
                                    
                                    <div><a data-cy="setModalEdicaoProduto" data-v-step-cardapio="18">Editar</a></div>
                                    <div v-if="edicaoemmassa">
                                        <v-checkbox v-if="prod.match" label="" v-model="prod.atualizarPreco" style="height:30px;margin-top:0px;" ></v-checkbox>
                                    </div>
                                    <div v-else>
                                        <v-icon color="error" data-cy="removerProduto">
                                            mdi-close-box
                                        </v-icon>
                                    </div>
                                </div>
                                
                            </v-card-text>
                            <div v-if="prod.nomedepartamento">
                                ({{prod.nomedepartamento}})
                            </div>
                            <div>
                                {{prod.nomecd}}<v-spacer></v-spacer>
                                {{prod.precoimportado | formatarMoeda}}
                            </div>

                            <div style="display:flex;align-items:flex-end;justify-content:flex-end;">
                                Ordem Visual:
                                <input type="text" @change="atualizarProduto(prod)" v-model="prod.ordem_visualizacao" style="width:35%;margin-left:10px;height:20px;background-color:white;border:1px solid #bdbaba;border-radius:10px;text-align:center;" />
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            
        </v-card>

        <ModalEdicaoPDVTuor v-if="exibirModalEdicaoPDV" :exibirModalEdicaoPDV="exibirModalEdicaoPDV"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            @atualizarLista="()=>{ getPDVSCD() }"
            @fechar="()=>setModalEdicaoPDV()" 
            @atualizarAposDuplicar="() => getCategoriasCD()"
        />


        <ModalEdicaoCategoriaTuor v-if="exibirModalEdicaoCategoria" :exibirModalEdicaoCategoria="exibirModalEdicaoCategoria"
            :idpdvcd="idpdvcd"
            :idcategoriacd="idcategoriacd"
            @atualizarLista="()=>{ getCategoriasCD() }"
            @fechar="()=>setModalEdicaoCategoria()" />


        <ModalEditarProdutoTuor v-if="exibirModalEdicaoProduto" :exibirModalEdicaoProduto="exibirModalEdicaoProduto"
            :produto="produtoclicadoedicao"
             @atualizarLista="()=>{ getCategoriasCD() }"
             @fechar="()=>setModalEdicaoProduto()"
        />

        <ModalLogsMovimentacao 
            :logs="logs.data"
            :exibirModalLogs="logs.exibir"
            @fechar="() => logs.exibir = false"
        />

    </div>
</template>

<script>
import ModalEdicaoPDVTuor from '../layout/Modais/ModalEdicaoPDVTuor.vue'
import ModalEdicaoCategoriaTuor from '../layout/Modais/ModalEdicaoCategoriaTuor.vue'
import ModalEditarProdutoTuor from '../layout/Modais/ModalEditarProdutoTuor.vue'
import ModalLogsMovimentacao from '../layout/Modais/ModalLogMovimentacao.vue'

export default {
    name: 'ColunaCD',
    props: [
        "url",
        "atualizar",
        "listaItensProdutoPorPDVPMS",
        "abrirModalEdicaoPDV",
        "abrirModalEdicaoCategoria",
        "abrirModalEdicaoProduto"
    ],
    components: {
      ModalEdicaoPDVTuor,
      ModalEdicaoCategoriaTuor,
      ModalEditarProdutoTuor,
      ModalLogsMovimentacao
    },
    data: () => ({
        edicaoemmassa:false,
        listaPDVsCD:[],
        idpdvcd:null,
        listaCategoriasCD:[],
        idcategoriacd:null,
        listaSubCategoriasCD:[],
        idsubcategoriacd:null,
        listaProdutosCD:[],
        idprodutocd:null,
        exibirModalEdicaoPDV:false,
        exibirModalCriarPDV:false,
        exibirModalEdicaoCategoria:false,
        exibirModalCriarCategoria:false,
        exibirModalEdicaoSubCategoria:false,
        exibirModalCriarSubCategoria:false,
        exibirModalEdicaoProduto:false,
        idprodutoclicado:null,
        produtoclicadoedicao:null,
        logs: {
            exibir: false,
            data: []
        },
    }),
    mounted(){
      this.getPDVSCD()
    },
    watch:{
        atualizar(valor){
            console.log(valor)
            this.getProdutosCD()
        },
        listaItensProdutoPorPDVPMS(listaPMS){
                this.match(listaPMS)
        },
        abrirModalEdicaoPDV(valor){
          this.setModalEdicaoPDV(valor)
        },
        abrirModalEdicaoCategoria(valor){
          this.setModalEdicaoCategoria(valor)
        },
        abrirModalEdicaoProduto(valor){
          this.setModalEdicaoProduto(valor)
        }
    },
    methods:{
        atualizarProduto(produto){
            let obj = {
                idcategoriaprodcdprodutos:produto.idcategoriaprodcdprodutos,
                ordem_visualizacao:produto.ordem_visualizacao
            }
       
            this.$http.post(`pdv/altercategoriaprodcdprodutos`,obj).then(() =>{
                this.getProdutosCD()
            }).catch((error) =>{
                this.$alertar("warning","Não foi possivel atualizar o Produto","=(")
                console.log(error)
            })

        },
        match(listaPMS){
            let indice
                for(let i=0,len = this.listaProdutosCD.length; i < len; i++){
                    this.listaProdutosCD[i].match = false
                    this.listaProdutosCD[i].atualizarPreco = false
                }
              
                try {
                    
                
                    for(let i=0,len = listaPMS.length; i < len; i++){
                        let produtoPMS = listaPMS[i]
                        indice = this.listaProdutosCD.findIndex(x=>{
                            return x.idproduto == produtoPMS.idproduto
                        })
                        
                        if(indice > 0){
                            this.listaProdutosCD[indice].match = true
                            this.listaProdutosCD[indice].idpdvproduto = produtoPMS.idpdvproduto
                        }
                    }
                } catch (error) {
                    console.log(error)
                    
                }
        },
        setModalEdicaoPDV(value){
            this.exibirModalEdicaoPDV = value
        },
        setModalEdicaoCategoria(value){
            this.exibirModalEdicaoCategoria = value
        },
        setModalEdicaoProduto(){
          this.produtoclicadoedicao = {
            "nomedepartamentocd": "Bar",
            "idproduto": 10,
            "descproduto": "Produto Bebida",
            "descricao": null,
            "nomecd": "Produto Bebida",
            "opcoes": [
              {
                "id": 10,
                "ativo": true,
                "descricao": "Opcional 1",
                "idproduto": 10,
                "motivoremocao": null,
                "id_ultimo_usuario_atualizacao": 1
              },
              {
                "id": 11,
                "ativo": true,
                "descricao": "Opcional 2",
                "idproduto": 10,
                "motivoremocao": null,
                "id_ultimo_usuario_atualizacao": 1
              }
            ],
            "configuracao_multiselecao": {
              "id": 3,
              "idproduto": 10,
              "multiselecao": true,
              "id_ultimo_usuario_atualizacao": 1
            },
            "img": null,
            "idcategoriaprod": 2,
            "idcategoriaprodcd": 1,
            "nomecategoriaprodcd": "A",
            "idsubcategoriaprodcd": null,
            "nomesubcategoriaprodcd": null,
            "ativo": true,
            "precoimportado": 7.9,
            "coddepartamentos": "6",
            "nomedepartamento": "Frigobar",
            "idcategoriaprodcdprodutos": 9,
            "ordem_visualizacao": 1,
            "match": false,
            "atualizarPreco": false
          }
          this.exibirModalEdicaoProduto = !this.exibirModalEdicaoProduto
        },
        getPDVSCD(){
              const mockResponsePDV = [
                {
                  "idpdv": 4,
                  "descpdv": "PDV Criado Cardapio Digital",
                  "ativo": true,
                  "allinclusive": false,
                  "seg": true,
                  "ter": true,
                  "qua": true,
                  "qui": true,
                  "sex": true,
                  "sab": true,
                  "dom": true,
                  "idimpressora": null,
                  "nome": null,
                  "ip": null,
                  "img": null,
                  "exibir_para": "[\"hospede\",\"garcom\"]"
                },
                {
                  "idpdv": 5,
                  "descpdv": "Outro PDV Criado Cardapio Digital",
                  "ativo": true,
                  "allinclusive": false,
                  "seg": true,
                  "ter": true,
                  "qua": true,
                  "qui": true,
                  "sex": true,
                  "sab": true,
                  "dom": false,
                  "idimpressora": null,
                  "nome": null,
                  "ip": null,
                  "img": null,
                  "exibir_para": "[\"garcom\",\"hospede\"]"
                },
              ]
              this.listaPDVsCD = mockResponsePDV
              this.idpdvcd = mockResponsePDV[0].idpdv

              this.getCategoriasCD()
        },
        getCategoriasCD(){
          const categoriasMockReponseAPI = [
            {
              "idcategoriaprodcd": 5,
              "nomecategoriaprodcd": "Categoria criada dentro do cardapio digital",
              "imgcategoriaprodcd": null,
              "dia_maximo_integracao": "2023-10-24T03:00:00.000Z",
              "horainicio": null,
              "horafinal": null,
              "tempopreparo": 40,
              "ativo": true,
              "flbebida": false,
              "flentreguecumim": false,
              "integravel": true,
              "seg": true,
              "ter": true,
              "qua": true,
              "qui": true,
              "sex": true,
              "sab": true,
              "dom": true,
              "idimpressora": 1,
              "nome": "Esmeralda",
              "ip": "tcp://10.125.121.105.5",
              "exibir_para": "[\"garcom\",\"hospede\"]",
              "liberarautomatico": false
            },
            {
              "idcategoriaprodcd": 6,
              "nomecategoriaprodcd": "Outra Categoria criada dentro do cardapio digital",
              "imgcategoriaprodcd": null,
              "dia_maximo_integracao": "2023-10-24T03:00:00.000Z",
              "horainicio": null,
              "horafinal": null,
              "tempopreparo": 40,
              "ativo": true,
              "flbebida": false,
              "flentreguecumim": false,
              "integravel": true,
              "seg": true,
              "ter": true,
              "qua": true,
              "qui": true,
              "sex": true,
              "sab": true,
              "dom": true,
              "idimpressora": 1,
              "nome": "Esmeralda",
              "ip": "tcp://10.125.121.105.5",
              "exibir_para": "[\"garcom\",\"hospede\"]",
              "liberarautomatico": false
            }
          ]
          this.listaCategoriasCD = categoriasMockReponseAPI
          this.idcategoriacd = categoriasMockReponseAPI[0].idcategoriaprodcd

          this.getSubCategoriasCD()
        },
        getSubCategoriasCD(){

          const subcategoriasMockResponseAPI = [
            {
              "idsubcategoriaprodcd": 1,
              "nomesubcategoriaprodcd": "Subcategoria criada dentro do cardapio digital",
              "imgcategoriaprodcd": null,
              "dia_maximo_integracao": "2023-10-24T03:00:00.000Z",
              "horainicio": null,
              "exibir_para": "[\"hospede\"]",
              "integravel": false,
              "horafinal": null,
              "tempopreparo": 40,
              "ativo": true,
              "seg": true,
              "ter": true,
              "qua": true,
              "qui": true,
              "sex": true,
              "sab": true,
              "dom": true
            },
            {
              "idsubcategoriaprodcd": 2,
              "nomesubcategoriaprodcd": "Outra Subcategoria criada dentro do cardapio digital",
              "imgcategoriaprodcd": null,
              "dia_maximo_integracao": "2023-11-13T03:00:00.000Z",
              "horainicio": null,
              "exibir_para": "[\"garcom\",\"hospede\"]",
              "integravel": true,
              "horafinal": null,
              "tempopreparo": 40,
              "ativo": true,
              "seg": true,
              "ter": true,
              "qua": true,
              "qui": true,
              "sex": true,
              "sab": true,
              "dom": false
            }
          ]

          this.listaSubCategoriasCD = subcategoriasMockResponseAPI
          this.idsubcategoriacd = this.listaSubCategoriasCD[0].idsubcategoriaprodcd

          this.getProdutosCD()
        },
        getProdutosCD(){
          const produtosMockResponseAPI = [
            {
              "nomedepartamentocd": "Bar",
              "idproduto": 10,
              "descproduto": "Produto Bebida",
              "descricao": null,
              "nomecd": "Produto Bebida",
              "opcoes": [
                {
                  "id": 10,
                  "ativo": true,
                  "descricao": "Opcional 1",
                  "idproduto": 10,
                  "motivoremocao": null,
                  "id_ultimo_usuario_atualizacao": 1
                },
                {
                  "id": 11,
                  "ativo": true,
                  "descricao": "Opcional 2",
                  "idproduto": 10,
                  "motivoremocao": null,
                  "id_ultimo_usuario_atualizacao": 1
                },

              ],
              "configuracao_multiselecao": {
                "id": 3,
                "idproduto": 10,
                "multiselecao": true,
                "id_ultimo_usuario_atualizacao": 1
              },
              "img": null,
              "idcategoriaprod": 2,
              "idcategoriaprodcd": 1,
              "nomecategoriaprodcd": "A",
              "idsubcategoriaprodcd": null,
              "nomesubcategoriaprodcd": null,
              "ativo": true,
              "precoimportado": 7.9,
              "coddepartamentos": "6",
              "nomedepartamento": "Frigobar",
              "idcategoriaprodcdprodutos": 9,
              "ordem_visualizacao": 1,
              "match": false,
              "atualizarPreco": false
            },
            {
              "nomedepartamentocd": "Bar",
              "idproduto": 38,
              "descproduto": "Produto Comida",
              "descricao": null,
              "nomecd": "Produto Comida",
              "opcoes": null,
              "configuracao_multiselecao": null,
              "img": null,
              "idcategoriaprod": 38,
              "idcategoriaprodcd": 1,
              "nomecategoriaprodcd": "A",
              "idsubcategoriaprodcd": null,
              "nomesubcategoriaprodcd": null,
              "ativo": true,
              "precoimportado": 100,
              "coddepartamentos": "44",
              "nomedepartamento": "Café da Manha",
              "idcategoriaprodcdprodutos": 16,
              "ordem_visualizacao": 1,
              "match": true,
              "atualizarPreco": false,
              "idpdvproduto": 38
            }
          ]
          localStorage.setItem('idcatcd', this.idcategoriacd)
          localStorage.setItem('idsubcatcd', this.idsubcategoriacd)
          this.listaProdutosCD = produtosMockResponseAPI
          this.match(this.listaItensProdutoPorPDVPMS)
        },
        selecionaUltimoPdvCriado(pdv) {
            this.listaPDVsCD.find((item) => {
                console.log(item.descpdv)
                console.log(pdv)
                console.log(item.descpdv == pdv)
                if (item.descpdv == pdv) {
                    this.idpdvcd = item.idpdv
                }
            })
        }
    }
  }
</script>

<style scoped>
    .ddpms{
        /* background-color: white; */
        width:63%;
        margin-left:2%;
        height:100%!important;
    }

    .ddpmsprod{
        
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }

</style>
