<template>

    <div class="d-flex align-center item" @click="direcionar()" data-cy="pdv">
        <div class="colA">
            <!-- <v-img class="colAimg" :src="pdv.img ? 'data:' + imgTipo + ';base64,'+pdv.img:null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img> -->
            <v-img class="colAimg" :src="pdv.img || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
            <div class="colBItemTitle">{{pdv.descpdv}}</div>
            <div v-show="pdv.dentro_do_horario == 'false'" class="colBItemOutHour" style="color:red">Fora do horário</div>
        </div>
    </div>

</template>

<script>
export default {
    props:["pdv", "pdvsSelecionados"],

     methods:{
        direcionar(){
            this.$router.push('/Hospede/Categorias/'+this.pdv.idpdv)
        }
     }
}
</script>

<style scoped>
    .item{
        font-size:1.2em;
        color:rgb(70, 70, 70);
        /* background-color: pink; */
        height: 70px;
        width:330px;
        max-width:100%;
        margin-bottom: 20px;
        margin-right:2%;
        border: 1px solid rgb(156, 156, 156);
        border-radius: 10px;
        padding:5px;
    }
    .item .colA{
        /* background-color:red; */
        width: 60px;
        padding-left:2%; 
        padding-right: 2%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item .colA .colAimg{
        border-radius: 100%;
        height:50px;
        max-height:50px;
        width:50px;
        max-width:50px;
    }

    .item .colB{
        /* background-color:Blue; */
        width: 270px;
        padding-left:2%; 
        display: flex;
        flex-direction: column;
        padding-right:2%; 
        word-break: break-all;
    }

    .item .colB .colBItemTitle{
        /* background-color:red; */
        font-size:18px;
        line-height: 1.1;
        height: 70%;
    }

    .item .colB .colBItemOutHour{
        /* background-color:red; */
        font-size:14px;
        line-height: 1.1;
        height: 70%;
    }

    .item .colB .colBItemPrice{
        /* background-color:Blue; */
        display: flex;
        font-size:18px;
        line-height: 1.1;
        height: 30%;
        padding-bottom:10%;
        padding-right: 10px;
        justify-content: flex-end;
        font-weight: bold;
    }



</style>