<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logodiv" style=";width:100%;padding:20%;">
        <v-img src="/imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
      </div>
      
        <div style="color: white;font-size:18px;">
          Download do Aplicativo Android para Garçons 
        </div>

        <div style="color: white;margin-top:30px;">
          <strong style="font-size:26px;">Passo 1</strong>
          <span style="font-size:18px;">: Clique no botão a baixo para fazer o Download, ao Fazer o Download ele irá pedir confirmação de segurança, <strong style="font-size:22px;color:#ffe73c;">pode confirmar e continuar sem problemas</strong>.</span>
        </div>

        <div style="color: white;margin-top:30px;">
            <v-btn large block color="info" class="white--text font-weight-bold" style="width: 140px;margin-top:20px;" @click="download()" >
                DOWNLOAD
                <v-icon right dark>mdi-download</v-icon>
            </v-btn>
        </div>

        <div style="color: white;margin-top:30px;">
          <strong style="font-size:26px;">Passo 2</strong>
          <span style="font-size:18px;">: Clique no Arquivo <strong style="font-size:22px;color:#ffe73c;">aplicativo_garcom.apk</strong> que você acabou de baixar.</span>
        </div>

        <div style="color: white;margin-top:30px;">
          <strong style="font-size:26px;">Passo 3</strong>
          <span style="font-size:18px;">: Quando estiver instalando o Android vai perguntar se você autoriza a instalação de Aplicativo de fonte externa, basta informar que 
            <strong style="font-size:22px;color:#ffe73c;">sim</strong>.</span>
        </div>
      
      <div>
        
        
        

        <v-row justify="center" class="mt-8">
          <div>
            <v-img src="/imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      dominio : window.origin,
      usuarioSistema: false,
      email: "",
      emailRules: [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "Email inválido",
      ],
    };
  },

  components: {},
  methods: {
    async download() {
      window.location.href='/aplicativo/aplicativo_garcom.apk'+`?${Date.now()}`
    },
    async alterarPassCliente() {
      let body = {
        email : this.email,
        usuariosistema : this.usuarioSistema
      }
      await this.$http.post('/auth/solicitaalteracaosenha', body).then(response =>{
        if(response.status == 200){
          //Enviar Email aqui
          let bodyMail ={
            url : window.origin+'/formpassrecovery?token='+response.data.token,
            destinatario : this.email,
            assunto : 'Alteracao de Senha / Update Password',
            origin : window.origin
          }
          console.log(bodyMail)
          this.$http.post('/auth/enviaremailrecuperacao', bodyMail).then(response =>{
            if(response.status == 200){
              alert("Solicitada alteracao de senha")
            }
          })
        }
      }).catch(error =>{
        console.log(error)
      })
    },
    async alterarPassUsuario() {
      let body = {
        email : this.email,
        usuariosistema : this.usuarioSistema
      }
      await this.$http.post('/auth/solicitaalteracaosenha', body).then(response =>{
        if(response.status == 200){
          //Enviar Email aqui
          let bodyMail ={
            url : window.origin+'/formpassrecovery?token='+response.data.token+'&us=true',
            destinatario : this.email,
            assunto : 'Alteracao de Senha / Update Password',
            origin : window.origin
          }
          console.log(bodyMail)
          this.$http.post('/auth/enviaremailrecuperacao', bodyMail).then(response =>{
            if(response.status == 200){
              alert("Solicitada alteracao de senha")
            }
          })
        }
      }).catch(error =>{
        console.log(error)
      })
    },
  },
  mounted(){
    console.log(this.dominio)
  }
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  /* margin-top:-150px; */
  width: 80vw;
  max-width: 350px;
}

.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
}
</style>