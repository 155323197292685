<template>
  <div>
    <BotaoVoltar :url="'/Hospede/Menu'" />
    <Subtitulo :subtitulo="subtitulo" />
    <CategoriaLista :idpdv="$route.params.idpdv" />
    
  </div>
</template>

<script>
  import CategoriaLista from '../../components/categories/CategoriaLista.vue'
  import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'View Categoria',
    data: () => ({
      subtitulo:"",
    }),
    components: {
      CategoriaLista,
      BotaoVoltar,
      Subtitulo,
    },
    mounted(){
        this.$http('pdv/getNomePdv/'+this.$route.params.idpdv).then((resp) =>{
          this.subtitulo = resp.data.descpdv
          localStorage.setItem('pdvSelecionado', this.$route.params.idpdv)
          //console.log(localStorage.getItem('pdvSelecionado'))
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi pegar o nome do PDV","=(")
        })
        this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
    }
  }
</script>
