<template>
      <v-dialog
        v-model="virtualDiolog"
        scrollable
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="warning"
            dark
            v-bind="attrs"
            v-on="on"
            data-v-step-cardapio="21"
          >
            Opcionais por produto
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="card-header">
            <div class="card-header-items">
              <span>
                Opcionais por produto
              </span>

              <v-btn
                color="primary"
                  @click="() => adicionarOpcao()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>

            <div class="card-header-items">
              <span>
                Permitir multiseleção?
              </span>

              <v-checkbox
                v-model="multiselecao"
              ></v-checkbox>
            </div>

          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px; margin-top: 1em">
            <div v-for="(opcao, index) in opcoes" :key="opcao.index">

              <v-row>
                <v-col cols="9">
                  <v-text-field
                      v-model="opcao.descricao"
                      label="Descrição"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-dialog
                      v-model="virtualDiologMotivoRemocao"
                      persistent
                      max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => removerOpcao(index)"
                      >
                        <v-icon color="white">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Por favor insira o motivo de exclusão</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                  v-model="motivoRemocao"
                                  label="Motivo"
                                  required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="() => diologMotivoRemocao = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                            color="error"
                            @click="() => confirmarRemocao(index)"
                        >
                          Confirmar e remover
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </div>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions style="display: flex; flex-direction: row;justify-content: flex-end; align-items: center">
            <v-btn
              color="primary"
              @click="dialog = false"
            >
              Fechar
            </v-btn>
            <v-btn
              color="success"
              @click="salvar"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </template>

<script>
export default {
  data () {
    return {
        diologMotivoRemocao: false,
        opcoes: [],
        motivoRemocao: "",
        dialog: false,
        multiselecao: false,

        virtualDiologMotivoRemocao: false,
        virtualDiolog: false,
    }
  },
  props: [
    "idproduto"
  ],
  mounted() {
    this.carregaOpcoes();
  },
  watch: {
    diologMotivoRemocao: function(val) {
      this.virtualDiologMotivoRemocao = val;
    },
    diolog: function(val) {
      this.virtualDiolog = val;
    }
  },
  methods: {
    async carregaOpcoes() {

      const mockResponseOpcoes = {
        "configuracao": {
          "id": 3,
          "idproduto": 10,
          "multiselecao": true,
          "id_ultimo_usuario_atualizacao": 1
        },
        "opcoes": [
          {
            "id": 1,
            "ativo": true,
            "descricao": "Limão com Gelo",
            "idproduto": 10,
            "motivoremocao": null,
            "id_ultimo_usuario_atualizacao": 1
          },
          {
            "id": 2,
            "ativo": true,
            "descricao": "Ao ponto",
            "idproduto": 10,
            "motivoremocao": null,
            "id_ultimo_usuario_atualizacao": 1
          },
        ]
      }
      this.opcoes = mockResponseOpcoes.opcoes || [];
      this.multiselecao = mockResponseOpcoes.configuracao?.multiselecao;
    },
    async salvar() {
      this.$alertar("success", "Sucesso", "Opcionais por produto salvo com sucesso!")
    },
    async removerOpcao(index) {
      this.opcoes.splice(index, 1);
    },
    adicionarOpcao() {
      this.opcoes.push(
          {
            id: null,
            idproduto: this.idproduto,
            descricao: "",
            ativo: true,
            id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario")
          }
      );
    },
  }

}
</script>


<style>

  .card-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .card-header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

</style>
