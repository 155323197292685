<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;margin-bottom: 90px;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="´Dúvidas Room Service" urlVideo="https://drive.google.com/file/d/1gzpUrGhdQdY6Li7o8OcDHa0hN2DB0TOj/preview"/>
    </div>
        
        <v-card-text style="padding-bottom:30px">

                <div style="display:flex;">

                    <div style="height:5vh;margin-top:5px;width:50%;padding-right:20px;">
                        <div style="padding-bottom:2px;display:flex;align-items:center">
                            <v-switch v-model="flDistribuirPedidosEntreGarcons" data-cy="config.aprovacaocaixa"> </v-switch>
                            <label style="font-size:18px;">Distribuir Pedidos entre os Garçons?</label>
                        </div>
                    </div>

                  
                    
                </div>
                      
                
                <div style="width:100%;height:8vh;position:absulute;margin-top:50px;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>
        
        </v-card-text>

    </v-card>
</template>

<script>
import BotaoTutorial from '../layout/BotaoTutorial.vue'
  
  export default {
    name: 'Fluxo Pedido',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        flDistribuirPedidosEntreGarcons:false,
    }),
    methods:{
        salvar(){

                let parametro = { chave:'ROOMSERVICE', valor:this.flDistribuirPedidosEntreGarcons ? 'true' : 'false' }
                
                this.$http.put('pdv/updateparametros',parametro)
                    .then(() =>{
                        this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                    }).catch(() =>{
                        this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                    })
            
        },
        async buscarDados(){

            this.$http.post('pdv/buscarparametros',{chave:'ROOMSERVICE'})
            .then(resp =>{
                this.flDistribuirPedidosEntreGarcons = resp.data[0].valor === 'true'? true : false
            }).catch(error =>{
                alert(error)
            })

        },
    }
  }
</script>

<style scoped>
    
</style>