<template> 
  <div style="min-width:100%;min-height:100%;" >
    
    <Bar />
    <div style="min-width:100%;height:93.5vh!important;padding:1em;background-color:rgba(235, 235, 245,.5)!important;">
        <router-view />
    </div>

   </div>
</template>

<script>

import Bar from '../../components/layout/Bar'
export default {
  name: 'App',
  components:{
    Bar
  },
  data: () => ({
    titulo:'Card'
  }),
  methods:{

    getNotificacoesCount(){

          this.$http("/pdv/notificacoesCount/1")
              .then((resp) =>{
                 
                  this.$store.dispatch("set_qtdenotificacao", resp.data.length)
              })
              .catch((error) =>{
                  alert(error)
              })
              
    }

  },
  mounted(){

    if(!localStorage.getItem('pessoaLogada') && !localStorage.getItem('pessoaLogada') === 'false'){
      this.getNotificacoesCount()
    }

    if(localStorage.getItem('pessoaLogada') && localStorage.getItem('pessoaLogada') === 'false'){
      this.$http
        .post(this.$dados.BUILD_API_URL + "/hospedes/acessarCardapioSemLogin").then(res => {
          this.$http.defaults.headers.common["x-access-token"] = res.data.token;
        })
    }


    this.$socket.io.opts.query.tipo = 'CARDAPIO'
		this.$socket.io.opts.query.tipo_usuario = 'HOSPEDE'
    this.$socket.io.opts.query.usuario = localStorage.getItem('usuario')
		this.$socket.io.opts.query.idhotel = localStorage.getItem('idhotel')
    this.$socket.connect();

    const self = this
    this.sockets.subscribe('notificacao_hospede',(msg)=>{
        if(msg === "Pedido Cancelado!"){
          self.$alertar("warning",msg,"")
        }else{
          self.$alertar("success",msg,"")
        }
      }
    )

  },
  beforeDestroy(){
      this.sockets.unsubscribe("notificacao_hospede")
  }

};
</script>

<style scoped>
    .bodygenerico{
        width: 100%;
        min-height: 90vh!important;
        padding:0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
