<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1fqqQ3zmN-EVYQUW0Nvgl_GV1CE59z00p/preview" />
    </div>
        <v-card-text>

                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="config.verificaremailhospedefake" data-cy="config.verificaremailhospedefake"> </v-switch>
                        <label style="font-size:18px;">É obrigatório a verificação do email do hospede fake?</label>
                    </div>
                </div>

                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="config.mudarTipoLogin" data-cy="config.verificaremailhospedefake"> </v-switch>
                        <label style="font-size:18px;">Hospede faz login com CPF?</label>
                    </div>
                </div>


                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                  <div style="padding-bottom:2px;display:flex;align-items:center;">
                    <v-switch v-model="config.buscaAutomaticaQuarto" data-cy="config.verificaremailhospede0fake"> </v-switch>
                    <label style="font-size:18px;">Quando login for CPF, buscar quarto automaticamente?</label>
                  </div>
                </div>

                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                  <div style="padding-bottom:2px;display:flex;align-items:center;">
                    <v-switch v-model="config.novoAutoatendimento" data-cy="config.verificaremailhospede0fake"> </v-switch>
                    <label style="font-size:18px;">Ativar novo autoatendimento</label>
                  </div>
                </div>

                <div style="height:42vh;max-height:10vh;overflow:auto;margin-top:20px;">
                  <div style="padding-bottom:2px;display:flex;align-items:center;">
                    <v-switch v-model="config.exibirBotaoLoginFuncionario" data-cy="config.exibirBotaoLoginFuncionario"> </v-switch>
                    <label style="font-size:18px;">Exibir botão "ENTRAR COMO FUNCIONÁRIO" ?</label>
                  </div>
                </div>


                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>

        </v-card-text>

    </v-card>
</template>

<script>
import BotaoTutorial from '../layout/BotaoTutorial.vue'

  export default {
    name: 'ValidarEmailHospedeFake',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    data: () => ({
        config:{
            verificaremailhospedefake: true,
            mudarTipoLogin: false,
            buscaAutomaticaQuarto: false,

            novoAutoatendimento: false,
            novoAutoatendimentoConfigurado: false,
            exibirBotaoLoginFuncionario: false
        }
    }),
    methods:{
        salvar(){
            const parametros = [
                {
                    chave: 'VERIFICAREMAILHOSPEDEFAKE',
                    valor: this.config.verificaremailhospedefake ? 'true' : 'false'
                },
                {
                    chave: 'TIPOLOGINHOSPEDE',
                    valor: this.config.mudarTipoLogin ? 'cpf' : 'email'
                },
                {
                  chave: 'BUSCA_AUTOMATICA_QUARTO',
                  valor: this.config.buscaAutomaticaQuarto ? 'true' : 'false'
                },
                {
                  chave: 'EXIBIR_BOTAO_LOGIN_FUNCIONARIO',
                  valor: this.config.exibirBotaoLoginFuncionario ? 'true' : 'false'
                }
            ]

            Promise.all(parametros.map(parametro => this.$http.put('pdv/updateparametros', parametro))).then(() => {
                this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })

            this.salvarConfiguracoesNovoAutoAtendimento();
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'VERIFICAREMAILHOSPEDEFAKE'})
            .then(resp =>{
                this.config.verificaremailhospedefake = resp.data[0].valor === 'true'? true : false
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'TIPOLOGINHOSPEDE'})
            .then(resp =>{
                this.config.mudarTipoLogin = resp.data[0].valor === 'cpf' ? true : false
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'BUSCA_AUTOMATICA_QUARTO'})
                .then(resp =>{
                  this.config.buscaAutomaticaQuarto = resp.data[0].valor === 'true' ? true : false
                }).catch(error =>{
                  alert(error)
                })

          this.$http.post('pdv/buscarparametros',{chave:'EXIBIR_BOTAO_LOGIN_FUNCIONARIO'})
              .then(resp =>{
                this.config.exibirBotaoLoginFuncionario = resp.data[0]?.valor === 'true' ? true : false
              }).catch(error =>{
            alert(error)
          })

            this.buscarDadosIntegracaoNovoAutoatendimento();
        },



      async buscarDadosIntegracaoNovoAutoatendimento() {
        const { data } = await this.$http.get('/pdv/buscarIntegracaoNovoCardapioHospedes')

        if(!data) {
          this.config.novoAutoatendimento = false;
          this.config.novoAutoatendimentoConfigurado = false;

          return;
        }

        this.config.novoAutoatendimento = data.ativo
        this.config.novoAutoatendimentoConfigurado = data.ativo
      },
      async salvarConfiguracoesNovoAutoAtendimento() {
        const body = {
          ativo: this.config.novoAutoatendimento
        }
        try {
          const { data } = await this.$http.put('/pdv/ativarOuInativarNovoCardapioAutoatendimento', body)

          this.config.novoAutoatendimento = data.ativo
        } catch (e) {
          this.$alertar(
              "warning",
              "Não foi possível salvar as configurações do novo autoatendimento",
              "=("
          )
        }
      },
    }
  }
</script>

<style scoped>

</style>
