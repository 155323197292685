<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logowrapper">
        <div class="logodiv">
          <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
        </div>
        <div>
          <div style="color:white;font-weight:bold;">{{captalize(tipoLogin)}}: </div>
          <v-text-field
              v-model="nome_usuario"
              data-cy="nome_usuario"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white"
          ></v-text-field>
        </div>
        <div>
          <div style="color:white;font-weight:bold;">UH: </div>
          <v-text-field
              v-model="senha"
              data-cy="senha"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white"
          ></v-text-field>
        </div>
        <v-btn
            large
            block
            color="info"
            class="white--text font-weight-bold"
            data-cy="btn-logar"
            v-on:click="login()"
        >
          Entrar
          <v-icon right dark>mdi-login</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginQRCODEPDVMESA",
  data() {
    return {
      tipoLogin: '',
      nome_usuario: "",
      senha: "",
    };
  },
  components: {},
  methods: {
    async salvaInformacoes(checkin) {
      localStorage.setItem("pessoaLogada", "true");
      localStorage.setItem("token", checkin.token);
      localStorage.setItem("nome", checkin.nome);
      localStorage.setItem("email", checkin.email);
      localStorage.setItem("nomehotel", checkin.nomehotel);
      localStorage.setItem("idhotel", checkin.idhotel);
      localStorage.setItem("numreserva", checkin.numreserva);
      localStorage.setItem("coduh", checkin.coduh);
      localStorage.setItem("datacheckin", checkin.datacheckin);
      localStorage.setItem("datacheckoutprev", checkin.datacheckoutprev);
      localStorage.setItem("idreservasfront", checkin.idreservasfront);
      localStorage.setItem("idhospede", checkin.idhospede);
      localStorage.setItem("idcheckin", checkin.idcheckin);
      localStorage.setItem("cartaoconsumo", checkin.cartaoconsumo);
      localStorage.setItem("tipo_usuario", "HOSPEDE");
      localStorage.setItem("passante", checkin.passante);
      localStorage.setItem("login_mesa", "true");
      localStorage.setItem("params_login_mesa", JSON.stringify(this.$route.query));

      this.$store.dispatch("set_titulo", "Cardápio");
      this.$http.defaults.headers.common["x-access-token"] = checkin.token;

      this.redirecionaCardapio();
    },
    redirecionaCardapio(){
      const params = {
        pdvs: this.pdv,
      };

      if(this.mesa) {
        params.mesa = this.mesa;
      }

      this.$router.push({ query: params, path: "/Hospede/PDVMesa" });
    },
    async retrieveInfos() {
      this.mesa = this.$route.query.mesa ? decodeURIComponent(this.$route.query.mesa) : "";
      this.pdv = decodeURIComponent(this.$route.query.pdvs).split(',') || [];

      if(!this.pdv) {
        this.$alertar("warning", "Erro", "Não foi encontrado nenhum PDV para esta mesa.");
        this.$router.push("/");
      }

      await this.verificarIntegracaoNovoCardapioHospede();
    },
    validarDadosInseridos() {
      if(!this.nome_usuario) {
        this.$alertar("warning", "Erro", `Por favor, insira o seu ${this.tipoLogin}.`);
        return false;
      }

      if(!this.senha) {
        this.$alertar("warning", "Erro", "Por favor, insira a sua senha.");
        return false;
      }

      return true;
    },
    async login() {

      if(!this.validarDadosInseridos()) return;

      const baseURL = `${this.$dados.BUILD_API_URL}/hospedes`;

      let url = '';
      const data = {
        senha: this.senha.trim(),
      };

      if(this.tipoLogin === 'cpf') {
        url = `${baseURL}/loginCPF`;
        data.cpf = this.nome_usuario.trim().toLowerCase();
      } else if(this.tipoLogin === 'email') {
        url = `${baseURL}/login`;
        data.email = this.nome_usuario.trim().toLowerCase();
      }

      this.$http.post(url, data)
          .then(res => {
              this.salvaInformacoes(res.data);
          })
          .catch(() =>  {
            this.$alertar("warning", "Erro", "Não foi encontrado nenhum checkin para este quarto.", "=(")
          })
    },
    verificarTipoLogin() {
      const url = `${this.$dados.BUILD_API_URL}/hospedes/verificarTipoLoginHospede`;
      this.$http.get(url)
          .then(res => {
            this.tipoLogin = res.data;
          })
          .catch(() =>  {
            this.$alertar("warning", "Erro", "Erro ao procurar o tipo login")
            this.$router.push("/");
          })
    },
    captalize(text) {
      if(typeof text !== 'string') return '';

      if(text === 'cpf') return 'CPF';

      text = text.trim().toLowerCase();
      text = text.split(' ');
      text = text.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      text = text.join(' ');

      return text;
    },
    async verificarIntegracaoNovoCardapioHospede() {
      this.$http(this.$dados.BUILD_API_URL +'/pdv/buscarIntegracaoNovoCardapioHospedes').then((res) => {
        if(!res.data) {
          return
        }

        if(res.data.ativo) {
          const baseURL = `https://cardapiodigital.cd.economysoftware.com.br/${res.data.nome_cliente}`
          const url = `${baseURL}/login?pdvs=${this.pdv.join(',')}&mesa=${this.mesa}`

          console.log("urll>>", url)
          window.location.replace(url)
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  async mounted() {
    await this.retrieveInfos();
    await this.verificarTipoLogin();
  },
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  width: 80vw;
  max-width: 350px;
}

.logowrapper{
  display: grid;
  place-content: center;
}
.logodiv {
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;
}

.card {
  margin-bottom: 16px;
}

.card:hover {
  transform: scale(1.05);
  transition: 0.2s;

}

</style>
