<template>
  <div class="estiloColuna">
      <div class="topoColuna">
        <Subtitulo style="max-width: 90%; width: 90%;display: flex;" :subtitulo="'Lista de Pedidos'" :corTexto="'#fff'" />

      <div v-show="telasComNotificacao">
        <div 
          v-show="showPopUp"
          style="margin-right:10px;border-radius:100px;padding:1px;width:35px;height:35px;display:flex;align-items:center;justify-content:center;cursor:pointer"
          @click="() => switchOnOffPopUp()"
        >
          <v-icon style="color:white">mdi-information-outline</v-icon>
        </div>

        <div 
          v-show="!showPopUp"
          style="margin-right:10px;border-radius:100px;padding:1px;width:35px;height:35px;display:flex;align-items:center;justify-content:center;cursor:pointer"
          @click="() => switchOnOffPopUp()"
        >
          <v-icon style="color:white">mdi-information-off-outline</v-icon>
        </div>

      </div>

      <BotaoTutorial style="max-width: 10%;width: 10%;margin-right: 5%;align-items:flex-end;display:flex;justify-content: flex-end; z-index: 1" categoria="14" legenda="" :urlVideo="urlVideo"/>
          
        

      </div>
      
            
      <v-text-field
        data-v-step-etapas="1"
        style="margin: 1em"
        outlined 
        hide-details
        placeholder="Pesquise aqui pelo Pedido ou UH" 
        id="input-busca-listapedidos"
        v-model="busca" 
        @input="e => filtarObjPedidos(e)"/>
      <PedidosListaAdm :ojbPedidos="ojbPedidos" corTexto="#55ad94" @setarPedido="(valor) => setarPedido(valor)" />
  </div>
</template>

<script>
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  import PedidosListaAdm from '../../components/pedidos/PedidosListaAdm.vue'
  import BotaoTutorial from '../layout/BotaoTutorial.vue'
  export default {
    name: 'ColunaListaPedidos',
    components: {
      PedidosListaAdm,
      Subtitulo,
      BotaoTutorial,
  },
    props:["urlVideo", "ojbPedidos", 'buscainicial', 'tela'],
    async mounted(){
      
      const titulo = this.tela?.toUpperCase()

      if(this.locaisPermitidos.includes(titulo)){
        this.telasComNotificacao = true
      }

    },
    beforeMount(){
      const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []

      const titulo = this.tela?.toUpperCase()

      const locaisPermitidos = ['CAIXA', 'BAR', 'COZINHA'];

      if(locaisPermitidos.includes(titulo)){

        const exibirNotificacao = locaisNotificacao.includes(titulo)

        this.showPopUp = exibirNotificacao
      }


    },
    data: () => ({
      busca: null,
      ojbPedidosdb: {},
      showPopUp: true,
      telasComNotificacao: false,
      locaisPermitidos: ['CAIXA', 'BAR', 'COZINHA'],
    }),
    watch: {
      //guardar estado de objetoPedidos para usar no filtro
      ojbPedidos: function(){
        this.ojbPedidosdb = JSON.parse(JSON.stringify(this.ojbPedidos))
      },
      //filtrar os pedidos caso acha algum valor no atributo this.buscainicial quadno os pedido forem carregados
      ojbPedidosdb: function(){
        if(this.ojbPedidosdb != {} && this.buscainicial.length > 0 && this.buscainicial === this.busca){
          this.busca = this.buscainicial
          this.filtarObjPedidos(this.busca)
        }
      },
      //filtrar os pedidos quando a busca mudar (ex: quando a pesquisa redireciona para a mesma pagina que esta)
      buscainicial: async function(){
        this.busca = this.buscainicial
        try{
          await this.filtarObjPedidos(this.busca)
          let pedido = this.ojbPedidos.listadepedidos.filter(pedido => pedido.numpedido == this.busca)[0]
          this.$emit('setarPedido', pedido)
        }catch(e){
          console.log('error', e)
        }

      },
      
    },
    methods:{
        direcionar(){
            this.$router.push(this.url)
        },
        setarPedido(pedido){
          this.$emit('setarPedido', pedido)
        },
        filtarObjPedidos(e){
          let query = e
          if(query == ''){
            this.ojbPedidos = JSON.parse(JSON.stringify(this.ojbPedidosdb))
          }
          const pedidossemfiltro = JSON.parse(JSON.stringify(this.ojbPedidosdb))
          const pedidoscomfiltro = pedidossemfiltro.listadepedidos.filter(pedido => {           
            if(parseInt(query)){
              // return String(pedido.numpedido).toLowerCase().includes(query.toLowerCase())
              return  (String(pedido.numpedido).toLowerCase().includes(query.toLowerCase())  || String(pedido.coduh).toLowerCase().includes(query.toLowerCase()) )
            }    
            let contem = pedido.hospede.map(hospede => Object.values(hospede).some((attr) => String(attr).toLowerCase().includes(query.toLowerCase())))
            // filtrar apenas pelos hospedes 
            return contem.some(el => el === true)
          })
          this.ojbPedidos.listadepedidos = pedidoscomfiltro
        },
      switchOnOffPopUp(){
        const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []
        const titulo = this.tela?.toUpperCase()

        const locaisPermitidos = this.locaisPermitidos

        if(!locaisPermitidos.includes(titulo)){
          return
        }

        const index = locaisNotificacao.indexOf(titulo)


        if(index > -1){
          locaisNotificacao.splice(index, 1)
        }else{
          locaisNotificacao.push(titulo)
        }

        this.showPopUp = !this.showPopUp;
        localStorage.setItem("locaisNotificacao", JSON.stringify(locaisNotificacao))

        window.location.reload()
      },
      atribuirValorPopUp(){
        const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []

        const titulo = this.tela?.toUpperCase()

        const locaisPermitidos = this.locaisNotificacao

        if(locaisPermitidos.includes(titulo)){
          const exibirNotificacao = locaisNotificacao.includes(titulo)
          this.showPopUp = exibirNotificacao
        }
      },  
    },

  }
</script>

<style scoped>
    .estiloColuna{
        background-color: white;
        /* padding-top:10px; */
        width: 28%!important;
        margin-right:2%!important;
        min-height: 90vh;
        max-height: 90vh;
        border:1px solid rgb(156, 156, 156);
        border-radius:5px;
        
    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color:#1976D2;
      display:flex;
      align-items: center;
    }
</style>