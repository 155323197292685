<template>
    <div class="cc-principal">
        <!-- Cartão -->
        <div class="cc-cartao">
          <div></div>
          <div class="cc-cartao_corpo">
              <p>{{nometitular}}</p>
              <p>{{numerocartao}}</p>
          
          </div>
          <div class="cc-cartao_rodape">
            <p style="margin-right: 1em;">{{mmaa}}</p>
            <p>{{cvccvv}}</p>
            <div style="flex:1"/>
            
            
            <!-- svg logo -->
            <BandeiraVisa v-if="bandeira == 'VISA'" />
            <BandeiraMaster v-if="bandeira == 'MASTER'" />
            <BandeiraAmex v-if="bandeira == 'AMEX'" />
            <BandeiraDinners v-if="bandeira == 'DINERS'"  />
            <BandeiraDiscover v-if="bandeira == 'DISCOVER'"  />
            <BandeiraJcb v-if="bandeira == 'JCB'"  />
            <BandeiraElo v-if="bandeira == 'ELO'"  />
            <BandeiraHipercard v-if="bandeira == 'HIPERCARD'"  />

          </div>
            <!-- 
            <img v-if="bandeira == 'OUTRO'" src="@/assets/img/outro.png" width="auto" height="130" alt="Imagem Cartão" crossorigin /> -->
        </div>
        <!-- Dados -->

        <!-- <div class="cc-center">Cartão de {{tipoPagto == 1?'Crédito':'Débito'}}</div> -->
        <div class="cc-form">
          <form autocomplete="off">
            <div class="cc-row" >
                <div class="cc-col-12">
                    <label class="cc-label" for="i-nometitular">Nome do titular:</label>
                    <v-text-field id="i-nometitular" filled rounded dense
                        v-model="nometitular"
                        :rules="[validarNome]"
                        @keyup="corrigirNome"
                        class="cc-input"
                        backgroundColor="#80808020"
                        height="2.75em"
                    ></v-text-field>
                </div>
            </div>
            <div class="cc-row" >
                <div class="cc-col-12">
                    <label class="cc-label" for="i-numerocartao">Número do cartão:</label>
                    <v-text-field id="i-numerocartao" filled rounded dense
                        v-model="numerocartao"
                        :rules="[validarNumeroCartao]"
                        @keyup="event => corrigirNumeroCartao(event)"
                        @blur="formatarNumeroCartao"
                        class="cc-input"
                        backgroundColor="#80808020"
                        height="2.75em"
                    ></v-text-field>
                </div>
            </div>
            <div class="cc-row">
                <div class="cc-col-4" style="width:30%;" >
                    <label class="cc-label" for="i-mmaa">MM/AA:</label>
                    <v-text-field id="i-mmaa" filled rounded dense
                        v-model="mmaa"
                        :rules="[validarValidade]"
                        @keyup="event => corrigirValidade(event)"
                        class="cc-input"
                        backgroundColor="#80808020"
                        height="2.75em"


                    ></v-text-field>
                </div>
                <div class="cc-col-4" style="width:30%;">
                    <label class="cc-label" for="i-cvccvv">CVC/CVV:</label>
                    <v-text-field id="i-cvccvv" filled rounded dense
                        v-model="cvccvv" type="number"
                        :rules="[validarCVCCVV]"
                        @keyup="event => corrigirCVCCVV(event)"
                        class="cc-input"
                        backgroundColor="#80808020"
                        height="2.75em"


                    ></v-text-field>
                </div>
                <div class="cc-col-4" style="width:30%;" v-if="$store.getters.get_tipopagto == 1">
                    <label class="cc-label" for="i-cvccvv">Parcelas:</label>
                    <v-text-field id="i-cvccvv" filled rounded dense
                        class="cc-input"
                        backgroundColor="#80808020"
                        height="2.75em"
                        v-model="parcelas" type="number"
                    ></v-text-field>
                </div>
            </div>
          </form>
        </div>
        <!-- Botão Pagar -->
        <div style="">
            <button class="cc-pagar-btn" @click="confirmar" :disabled="!validInput">
                <p class="cc-pagar-btn_texto"> Pagar</p>

                <div style="flex: 1;"/>
                <v-icon :color="!validInput ? `#BABAB5` : '#f9f9f2' ">mdi-arrow-right</v-icon>
            </button>
        </div>
        <!-- Modal de confirmação -->
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600" v-model="confirmMessage"
        >
            <template v-slot:default="confirmMessage">
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;padding:10px;">
                            <img src="@/assets/img/confirmarcartao.svg" 
                                width="150" height="auto"
                                alt="Confirmando..." crossorigin />
                                <br>
                            <div class="contest-message">Confirma os dados do seu cartão?
                                <br>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-spacer />
                        <!-- Confirmar (Sim) -->
                        <button class="sim-btn"
                            v-on:click="registrarPagamento"
                        >
                            <img src="@/assets/img/sim.svg" 
                                width="25" height="auto"
                                alt="Confirmar..." crossorigin />
                            <span class="px-2">Sim</span>
                        </button>
                        <v-spacer />
                        <!-- Cancelar (Não) -->
                        <button class="nao-btn"
                            v-on:click="confirmMessage.value = false"
                        >
                            <img src="@/assets/img/nao.svg" 
                                width="25" height="auto"
                                alt="Cancelar..." crossorigin />
                            <span class="px-2">Não</span>
                        </button>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>               
    </div>
</template>

<script>

import BandeiraAmex from "../../components/layout/BandeirasCartao/BandeiraAmex.vue"
import BandeiraDinners from "../../components/layout/BandeirasCartao/BandeiraDinners.vue"
import BandeiraDiscover from "../../components/layout/BandeirasCartao/BandeiraDiscover.vue"
import BandeiraElo from "../../components/layout/BandeirasCartao/BandeiraElo.vue"
import BandeiraHipercard from "../../components/layout/BandeirasCartao/BandeiraHipercard.vue"
import BandeiraJcb from "../../components/layout/BandeirasCartao/BandeiraJcb.vue"
import BandeiraMaster from "../../components/layout/BandeirasCartao/BandeiraMaster.vue"
import BandeiraVisa from "../../components/layout/BandeirasCartao/BandeiraVisa.vue"

export default {
    name: 'DADOS CARTAO',
    props:["valorapagar"],
    data: () =>{
        return{
            nometitular: '',
            numerocartao: '',
            mmaa: '',
            cvccvv: '',
            bandeira: 'VISA',
            confirmMessage: false,
            // tipoPagto:localStorage.getItem('tipoPgto'),
            tipoPagto:null,
            parcelas:1,
            parcelasMaxima:1,
        }
    },
    components: {
        BandeiraAmex,
        BandeiraDinners,
        BandeiraDiscover,
        BandeiraElo,
        BandeiraHipercard,
        BandeiraJcb,
        BandeiraMaster,
        BandeiraVisa
	},
    computed: {
        validInput() {
            return (
                typeof(this.validarNome()) == 'boolean'
                && typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        }
    },
    methods: {
        confirmar() {
            if(localStorage.getItem('tipoPgto') == 1){
                if(this.parcelas>0 && this.parcelasMaxima >= this.parcelas){
                    this.confirmMessage = true
                }else{ 
                    alert("A Quantidade de Parcela deve está entre 1 e "+this.parcelasMaxima+"!");
                }
            }else{
                this.confirmMessage = true
            }

            
        },
        getBandeiraCartao(cardNumber){
            var regexVisa = /^4[0-9]{12}(?:[0-9]{3})?/;
            var regexMaster = /^5[1-5][0-9]{1,14}/;
            var regexAmex = /^3[47][0-9]{1,13}/;
            var regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{1,11}/;
            var regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{1,12}/;
            var regexJCB = /^(?:2131|1800|35\d{3})\d{1,11}/;
            var regexElo = /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/
            var regexHipercard = /^606282|^637095|^637599|^637568/

            if(regexVisa.test(cardNumber)){
                return 'VISA';
            }
            if(regexMaster.test(cardNumber)){
                return 'MASTER';
            }
            if(regexAmex.test(cardNumber)){
                return 'AMEX';
            }
            if(regexDiners.test(cardNumber)){
                return 'DINERS';
            }
            if(regexDiscover.test(cardNumber)){
                return 'DISCOVER';
            }
            if(regexJCB.test(cardNumber)){
                return 'JCB';
            }
            if(regexElo.test(cardNumber)){
                return 'ELO';
            }
            if(regexHipercard.test(cardNumber)){
                return 'HIPERCARD';
            }

            return 'VISA';
        },
        validarNome() {
            return !!this.nometitular  || 'Este campo é obrigatório.'
        },
        corrigirNome() {
            this.nometitular = this.nometitular.replace(/[^a-zA-Z ]/g, '');
            this.nometitular = this.nometitular.replace(/ {2}/g, ' ');
            this.nometitular = this.nometitular.toUpperCase()
        },
        validarNumeroCartao() {
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            var limiteInf = 13
            var limiteSup = 19
            if (this.bandeira == 'AMEX') {
                limiteInf = 15
                limiteSup = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    limiteInf = 14
                    limiteSup = 14
                } else {
                    if (this.bandeira == 'MASTER' 
                    || this.bandeira == 'DISCOVER'
                    || this.bandeira == 'JCB'
                    || this.bandeira == 'ELO'
                    || this.bandeira == 'HIPERCARD'
                    || this.bandeira == 'VISA') {
                        limiteInf = 16
                        limiteSup = 16
                    }
                }
            }
            return (!!numeroSemBarra && numeroSemBarra.length>=limiteInf && numeroSemBarra.length<=limiteSup || 'Este campo é obrigatório, verifique os números do cartão.')
        },
        corrigirNumeroCartao(event) {
            var code = (event.which) ? event.which : event.keyCode;
            // console.error(code)
            // Retirar tudo que não é digito ou traço...
            this.numerocartao = this.numerocartao.replace(/[^0-9-]/g, '');
            // Identificar a bandeira do cartão (somente dígitos)
            this.bandeira = this.getBandeiraCartao(this.numerocartao.replace(/[^0-9]/g, ''))
            // Se estiver editando não mexer...
            if (code == 8) { return }
            // Se digitar o traço, retirar...
            if (code == 109) {
                this.numerocartao = this.numerocartao.slice(0, -1)
                return
            }
            // Inserir os traços automaticamente (se for AMEX a formatação é diferente)...
            var posSegundaBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 8
            var posTerceiraBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 12
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if ((numeroSemBarra.length == 4)
                || (numeroSemBarra.length == posSegundaBarra)
                || (numeroSemBarra.length == posTerceiraBarra)) {
                this.numerocartao +='-'
            }
            // Limitar a 16 números (existem cartões de 13 a 19 dígitos)
            var tamanhoLimite = 19
            if (this.bandeira == 'AMEX') {
                tamanhoLimite = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    tamanhoLimite = 14
                } else {
                    if (this.bandeira == 'MASTER' 
                    || this.bandeira == 'DISCOVER'
                    || this.bandeira == 'JCB'
                    || this.bandeira == 'ELO'
                    || this.bandeira == 'HIPERCARD'
                    || this.bandeira == 'VISA') {
                        tamanhoLimite = 16
                    }
                }
            }
            if (numeroSemBarra.length > tamanhoLimite) {
                this.numerocartao = this.numerocartao.slice(0, -1)
            }
            // Retirar eventuais duplicidades do traço...
            this.numerocartao = this.numerocartao.replace('--', '-')
        },
        formatarNumeroCartao() {
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if (numeroSemBarra) {
                if (this.bandeira=='AMEX'||this.bandeira == 'DINERS') {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,10)
                        + '-' + numeroSemBarra.substring(10)
                } else {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,8)
                        + '-' + numeroSemBarra.substring(8,12)
                        + '-' + numeroSemBarra.substring(12)
                }
            }
        },
        validarValidade() {
            var validDateYear = true
            if (this.mmaa) {
                var parts = this.mmaa.split('/')
                if (parts.length == 2) {
                    try {
                        var month = parseInt(parts[0])
                        var year = parseInt(parts[1])
                        validDateYear = month >= 1 && month <= 12
                        validDateYear = validDateYear && year > 20 && year < 99
                    } catch (e) {
                        // console.error(e)
                        validDateYear = false
                    }
                } else {
                    validDateYear = false
                }
            }
            return (!!this.mmaa && validDateYear) || 'Digite mês e ano de validade.'
        },
        corrigirValidade(event) {
            var code = (event.which) ? event.which : event.keyCode;
            // console.error(code)
            // Retirar tudo que não é digito ou '/'...
            this.mmaa = this.mmaa.replace(/[^0-9/]/g, '');
            // Se estiver editando não mexer...
            if (code == 8) { return }
            // Se digitar a barra, retirar...
            if (code == 111) {
                this.mmaa = this.mmaa.slice(0, -1)
                return
            }
            // Inserir a barra automaticamente...
            var mmaaSemBarra = this.mmaa.replace(/[/]/g, '')
            if (mmaaSemBarra.length == 2) {
                this.mmaa +='/'
            }
            // Limitar a 4 números
            if (mmaaSemBarra.length > 4) {
                this.mmaa = this.mmaa.slice(0, -1)
            }
            // Retirar eventuais duplicidades da barra...
            this.mmaa = this.mmaa.replace('//', '/')
        },
        validarCVCCVV() {
            var re
            if (this.bandeira == 'AMEX') { 
                re = /\b[0-9]{4}\b/g 
            } else {
                re = /\b[0-9]{3}\b/g 
            }
            return !!(this.cvccvv && this.cvccvv.match(re)) || 'Digite os dígitos de controle.'
        },
        corrigirCVCCVV() {
            // Limitar a 3 ou 4 números
            var limit = (this.bandeira == 'AMEX') ? 4 : 3
            if (this.cvccvv.length > limit) {
                this.cvccvv = this.cvccvv.slice(0, -1)
            }
        },
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        registrarPagamento() {
            // var mmaaParts = this.mmaa.split('/')
            // if (mmaaParts.length == 2) {
            //     var ano = parseInt(mmaaParts[1]) + 2000
            //     var validade = mmaaParts[0] + '/' + ano
            //     this.$emit('registrarPagamento', {
            //         nome: this.nometitular,
            //         numerocartao: this.numerocartao.replace(/[-]/g, ''),
            //         validade: validade,
            //         cvccvv: this.cvccvv,
            //         valorapagar: this.valorapagar,
            //         bandeira: this.bandeira=='OUTRO'?'VISA':this.bandeira,
            //         parcelas:this.parcelas
            //     })
            // }
            // this.confirmMessage = false
            // this.$router.push('/Hospede/pagamento-aprovado')
            
            this.$store.dispatch("set_nomecartao"       , this.nometitular);
            this.$store.dispatch("set_numerocartao"     , this.numerocartao);
            this.$store.dispatch("set_venctocartao"     , this.mmaa);
            this.$store.dispatch("set_codcartao"        , this.cvccvv);
            this.$store.dispatch("set_parcelascartao"   , this.parcelas);
            this.$store.dispatch("set_bandeiracartao"   , this.bandeira);
            this.$router.push('/Hospede/FotoDocPagamento')
            

        }
    },
    mounted(){

        this.nometitular = this.$store.getters.get_nomecartao
        this.numerocartao = this.$store.getters.get_numerocartao
        this.mmaa = this.$store.getters.get_venctocartao
        this.cvccvv = this.$store.getters.get_codcartao
        this.parcelas = this.$store.getters.get_parcelascartao
        this.bandeira = this.$store.getters.get_bandeiracartao
        this.bandeira = this.getBandeiraCartao(this.numerocartao.replace(/[^0-9]/g, ''))

        // this.$http.post("configuracao/buscarParametro", {
        //     idhotel: localStorage.getItem("hotel"),
        //     chave: "DEBCREDCONFIG",
        // }).then((resp) => {
        //     if (resp.status == 200) {
        //         this.parcelasMaxima = resp.data[0].valor2
        //     } else {
        
        //     }
        // })
        // .catch((error) => {
        //   console.log(error);
        // });      
    }
}
</script>

<style lang="scss" scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}

.cc-label{
  font-size: 14px;
  text-transform: capitalize;
}

.credit-card-brand{
  width: 4em;
  height: auto;
}

.cc-cartao {
  width: 100%;
  margin: auto;
  padding: 1em;
  display: grid;
  grid-template-rows: .2fr 1fr .2fr;
  grid-template-columns: 1fr;
  border-radius: 1em;
  height: 12em;
  color: #666;
  margin-bottom: 1em;

  background: rgb(25,118,210);
  background: -moz-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: -webkit-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1976d2",endColorstr="#00d4ff",GradientType=1);

  color: #f9f9f2;
  font-weight: bold;

  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
//   backdrop-filter: blur(2px);

  .cc-cartao_corpo{
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
  .cc-cartao_rodape{
    z-index: 3;
    display: flex;
    justify-content: flex-end;
  }
}
.cc-pagar-btn {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: #1976d2!important;
  color: white;
  padding: 1.5vh;
  line-height: 1.5;
  border-radius: 1em;
  width: 100%;
  height: 3em;
  display: flex;
}
.cc-pagar-btn:disabled {
  background-color: #80808020 !important;
  .cc-pagar-btn_texto{
    color: #BABAB5 !important;
  }
}



.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}



//css vuetify

.v-text-field--rounded {
  border-radius: 1em !important;
}


.v-application p {
  margin-bottom: unset !important;
}

.v-text-field__details {
    margin-bottom: 3px !important;
}
</style>