import { render, staticRenderFns } from "./ModalItensComandaCompleto.vue?vue&type=template&id=2bdd9930&scoped=true&"
import script from "./ModalItensComandaCompleto.vue?vue&type=script&lang=js&"
export * from "./ModalItensComandaCompleto.vue?vue&type=script&lang=js&"
import style0 from "./ModalItensComandaCompleto.vue?vue&type=style&index=0&id=2bdd9930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bdd9930",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VDataTable,VDialog,VFlex,VIcon,VSelect,VTextField,VToolbar})
