<template>
    <v-dialog v-model="exibirModalContaAvulsaADMH" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:90%;color:white;font-weight:bold;display: flex;" >
                        Criar Conta Avulsa
                    </span>
                    <span style="width:5%;display: flex;">
                        <!-- <BotaoTutorial categoria="" legenda="" urlVideo="" /> -->
                    </span>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <div style="padding-bottom:10px;">
                            <h3>Nome <span style="color:red;"></span></h3>
                            <input maxlength="50" type="text" v-model="nome" data-cy="modalfake-nome" placeholder="Digite o nome completo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Número<span style="color:red;"></span></h3>
                            <input maxlength="50" id="campo_email" type="number" v-model="numero" data-cy="modalfake-email" placeholder="Digite o referente ao hospede" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>OBS (Opcional)<span style="color:red;"></span></h3>
                            <input maxlength="50" id="campo_cpf" type="text" v-model="obs" data-cy="modalfake-cpf" placeholder="Digite uma observacao" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>
                       
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                        <v-btn 
                            color="primary" 
                            @click="criarContaAvulsa()" 
                            data-cy="modalfake-criarHospedeFake">
                            CRIAR CONTA AVULSA
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>
    </v-dialog>
</template> 

<script>

  export default {
    name: "ModalContaAvulsaADMH",
    props: ["exibirModalContaAvulsaADMH"],
    data: () => ({
        nome: "",
        numero: "",
        obs: ""
    }),
    watch: {
        nome: function (val) {
            this.nome = val.toUpperCase();
        },
    },
    components: {},
    mounted() {
        this.tipoIntegracao = localStorage.getItem("tipo_integracao");

        this.sockets.subscribe('conta_avulsa_criada', () => {
          this.$alertar("success", "Sucesso", "Conta avulsa criada com sucesso.")
          this.$emit("atualizar")
        })

        this.sockets.subscribe('conta_avulsa_erro_criar', (message) => {
          this.$alertar("warning", "Erro", message)
        })

    },
    methods: {
        async criarContaAvulsa() {
            const data = {
                nome: this.nome,
                numero: Number(this.numero),
                obs: this.obs
            }
            console.log('chegou aqui')
            this.$http.post("pdv/abrirContaAvulsaADMH", data)
            this.$alertar("info", "Aguarde...", "Estamos criando a conta avulsa.")
        },
        fechar() {
            this.$emit("fechar");
        },
    },
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }

  .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid rgb(156, 156, 156);
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }

    .listaBotaoPerfil{
        height:100px;
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .botaoPerfilNaoSelecionado{
        background-color: #8a8a8a;
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .botaoPerfilSelecionado{
        background-color: rgb(7, 156, 7);
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

