var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Opcionais por produto ")])]}}]),model:{value:(_vm.virtualDiolog),callback:function ($$v) {_vm.virtualDiolog=$$v},expression:"virtualDiolog"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-items"},[_c('span',[_vm._v(" Opcionais por produto ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.adicionarOpcao(); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('div',{staticClass:"card-header-items"},[_c('span',[_vm._v(" Permitir multiseleção? ")]),_c('v-checkbox',{model:{value:(_vm.multiselecao),callback:function ($$v) {_vm.multiselecao=$$v},expression:"multiselecao"}})],1),_c('div',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v("*Estes Itens não serão integrados, servem somente como informativo*")])]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px","margin-top":"1em"}},_vm._l((_vm.opcoes),function(opcao,index){return _c('div',{key:opcao.id},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Descrição","outlined":"","dense":""},model:{value:(opcao.descricao),callback:function ($$v) {_vm.$set(opcao, "descricao", $$v)},expression:"opcao.descricao"}})],1),_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function () { return _vm.removerOpcao(index); }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.virtualDiologMotivoRemocao),callback:function ($$v) {_vm.virtualDiologMotivoRemocao=$$v},expression:"virtualDiologMotivoRemocao"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Por favor insira o motivo de exclusão")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Motivo","required":""},model:{value:(_vm.motivoRemocao),callback:function ($$v) {_vm.motivoRemocao=$$v},expression:"motivoRemocao"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.diologMotivoRemocao = false; }}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function () { return _vm.confirmarRemocao(index); }}},[_vm._v(" Confirmar e remover ")])],1)],1)],1)],1)],1)],1)}),0),_c('v-divider'),_c('v-card-actions',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"flex-end","align-items":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { this$1.virtualDiolog = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.salvar}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }