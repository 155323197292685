import { render, staticRenderFns } from "./LocalPDV.vue?vue&type=template&id=52b8d163&scoped=true&"
import script from "./LocalPDV.vue?vue&type=script&lang=js&"
export * from "./LocalPDV.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b8d163",
  null
  
)

export default component.exports