<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <!-- <BotaoTutorial categoria="13" legenda="" urlVideo="" /> -->
    </div>
        
        <v-card-text>
                      
                <div style="margin-top:20px;display:flex;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                        <label for="mesainicial" style="font-size:20px;font-weight:bold;">Menor Mesa</label>
                        <input id="mesainicial" v-model="mesainicial" data-cy="mesainicial" maxlength="50" type="text"  placeholder="Digite o nome da Categoria" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                    </div>
                    <div style="padding-bottom:2px;display:flex;align-items:center;flex-direction:column;align-items:flex-start;width:50%;padding-right:20px;margin-bottom:20px;">
                        <label for="mesainicial" style="font-size:20px;font-weight:bold;">Maior Mesa</label>
                        <input id="mesainicial" v-model="mesafinal" data-cy="mesafinal" maxlength="50" type="text"  placeholder="Digite o nome da Categoria" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                    </div>
                </div>

                <div style="margin-top:20px;display:flex;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="pracacompartilhada" data-cy="pracacompartilhada"> </v-switch>
                        <label style="font-size:18px;">Permitir que dois ou mais garçons trabalhem dentro da mesma faixa de mesas ( praças )?</label>
                    </div>
                </div>

               
                
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="iniciarSave()" data-cy="iniciarSave">SALVAR</v-btn>
                </div>
        
        </v-card-text>

            <ModalConfirmacao :showModal="exibirModalLocal" :titulo="'Todos os garçons com mesas compartilhadas terão suas configurações de mesa zeradas!'" 
                    textosim="Confirmar" textonao="Cancelar" @sim="()=>confirmarAlteracao()" @nao="()=>{exibirModalLocal = false}"   />

    </v-card>
</template>

<script>
  
  import ModalConfirmacao from '../layout/ModalConfirmacao.vue'
//   import BotaoTutorial from '../layout/BotaoTutorial.vue'

  export default {
    name: 'Mesa Config',
    components: {
        ModalConfirmacao,
        // BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        exibirModalLocal:false,
        pracacompartilhada:false,
        mesainicial:1,
        mesafinal:100,
    }),
    methods:{
        confirmarAlteracao(){
            this.exibirModalLocal = false
            this.salvar()
        },
        iniciarSave(){
            if(this.pracacompartilhada){
                this.salvar()
            }else{
                this.exibirModalLocal = true
            }
        },
        salvar(){

            let parametro = {
                    chave:'PRACA',
                    valor:this.mesainicial,
                    valor2:this.mesafinal,
                }

            this.$http.put('pdv/updateparametros',parametro)
            .then(() =>{

                parametro = {
                    chave:'PRACACOMPARTILHADA',
                    valor:this.pracacompartilhada? 'true' : 'false'
                }
                
                this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                    if(!this.pracacompartilhada){

                            this.$http.post('pdv/zerarGarconConflitoMesa')
                            .then(() =>{
                                    this.$alertar("success","Parametros Atualizados!<br><span style='color:red;'>Caso tenha alterado a permissão de praça compartilhada, verifique as Mesas de todos os seus garçons para evitar problemas!</span>", "=)")
                            }).catch((error) =>{
                                console.log(error)
                                this.$alertar("warning","Não foi Possível atualizar com Sucesso!", "=(")
                            })

                    }
                }).catch(() =>{
                    this.$alertar("warning","Não foi Possível atualizar com Sucesso!", "=(")
                })
               
            }).catch(() =>{
                this.$alertar("warning","Não foi Possível atualizar com Sucesso!", "=(")
            })
            
            // this.$emit('salvar',obj)
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'PRACA'})
            .then(resp =>{
                this.mesainicial = resp.data[0].valor
                this.mesafinal   = resp.data[0].valor2
            }).catch(error =>{
                alert(error)
            })

            this.$http.post('pdv/buscarparametros',{chave:'PRACACOMPARTILHADA'})
            .then(resp =>{
                this.pracacompartilhada = resp.data[0].valor === 'true' ? true:false
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>