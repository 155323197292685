<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            style="margin-left: 8px;"
        >
          Transferir
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Deseja adicionar algum outro item nessa transferencia?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
              >
                  <v-checkbox
                      v-for="produto in pedido.listadepedidos" :key="produto.idcomandaprodutos"
                      :label="`${produto.descproduto} - ${produto.quantidade}X`"
                      :value="produto.idcomandaproduto"
                      v-model="itensTransferidos"
                      @change="() => atualizaItensTransferidos()"
                  ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="() => transferirComanda()"
          >
            Transferir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: ["pedido", "itensAdicionado"],
  mounted() {
    this.itensTransferidos = this.itensAdicionado
  },
  data: () => ({
    dialog: false,
    itensTransferidos: [],
  }),
  methods: {
    transferirComanda() {
      this.$emit("transferirComanda");
    },
    atualizaItensTransferidos() {
      this.$emit("atualizaItensTransferidos", this.itensTransferidos);
    },
  },
}
</script>
