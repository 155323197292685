<template>
  <div class="container">
    <div class="container">
      <h2 class="text-center">
        {{
          comissao.colaboradores
            ? "Total de colaboradores para rateio da meta"
            : "Turnos"
        }}
      </h2>
    </div>
    <template v-if="!comissao.colaboradores">
      <div class="container">
        <comissao-turno :comissao="comissao" />
        <div v-if="ehMaster" class="text-center my-3">
          <v-btn
            color="primary"
            @click="continuarSemTurnos()"
          >
            Calcular Comissionamento sem turnos
            <v-icon small> mdi-timer-off </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    <div v-else class="container">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="3">
              <v-text-field :disabled="!ehMaster"
                type="number"
                step="1"
                class="centered-input"
                v-model="comissao.colaboradores"
                label="Colaboradores"
                @input="mudarColaboradores()"
              />
            </v-col>
            <v-col cols="3" align-self="center">
              <v-btn
                :disabled="!modificouColaboradores"
                small
                elevation=""
                color="primary"
                @click="salvarColaboradores()"
              >
                Salvar
              </v-btn>
            </v-col>
            <v-col v-if="ehMaster" cols="3" align-self="center">
              <v-btn color="success" @click="definirTurnos()">
                Definir Turnos
                <v-icon small> mdi-clock </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="container">
      <h2 class="text-center">Referência de Faturamento</h2>
    </div>
    <div class="container" v-if="comissao.id_comissao_regra">
      <comissao-referencia
        :referencia="comissao.referencia"
        :mesCompetencia="mesCompetencia"
        :id_comissao_regra="comissao.id_comissao_regra"
        @mudou="calcularMetaZero()"
        :somenteLeitura="!ehMaster"
      ></comissao-referencia>
    </div>
    <div class="container">
      <h2 class="text-center">Metas</h2>
    </div>
    <div class="container">
      <v-row justify="center">
        <v-col cols="3">
          <v-select
            chips
            prepend-icon="mdi-calendar"
            v-model="mesCompetencia"
            :items="meses"
            item-text="mes"
            item-value="id_mes"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-if="!comissao.colaboradores"
            chips
            prepend-icon="mdi-clock"
            v-model="turnoSelecionado"
            :items="comissao.turnos"
            return-object
            item-text="turno"
            item-value="id_turno"
          >
          </v-select>
          <v-text-field
            v-else
            readonly
            type="number"
            step="1"
            class="centered-input mt-4"
            v-model="comissao.colaboradores"
            label="Colaboradores"
          />
        </v-col>
        <v-col cols="3" align-self="center">
          <label class="text-center">Meta Zero</label>
          <h3>
            {{ metaZero | formatarMoeda }}
          </h3>
        </v-col>
      </v-row>
    </div>
    <!-- Metas -->
    <div class="container mb-5">
      <v-data-table :headers="computedHeaders" :items="items">
        <template v-slot:[`item.meta`]="{ item }">
          <v-text-field
            :ref="`meta_${item.id_comissao_meta}`"
            v-model="editedItem.meta"
            :hide-details="true"
            dense
            single-line
            v-if="item.id_comissao_meta === editedItem.id_comissao_meta"
          ></v-text-field>
          <span v-else>{{ item.meta }}</span>
        </template>
        <template v-slot:[`item.percentual_limite`]="{ item }">
          <v-text-field
            v-model="editedItem.percentual_limite"
            :hide-details="true"
            dense
            single-line
            v-if="item.id_comissao_meta === editedItem.id_comissao_meta"
          ></v-text-field>
          <span v-else>
            <template v-if="item.venda_maxima > 0">
              {{ item.percentual_limite | formatarPercentual }}
            </template>
            <template v-else>
              {{ ` acima de ${limiteAnterior(item)}%` }}
            </template>
          </span>
        </template>
        <template v-slot:[`item.venda_minima`]="{ item }">
          <template>
            <p>{{ item.venda_minima | formatarMoeda }}</p>
          </template>
        </template>
        <template v-slot:[`item.venda_maxima`]="{ item }">
          <template v-if="item.venda_maxima > 0">
            <p>{{ item.venda_maxima | formatarMoeda }}</p>
          </template>
          <template v-else>
            <p>{{ ` - - - - - - - - - - ` }}</p>
          </template>
        </template>
        <template v-slot:[`item.percentual_comissao`]="{ item }">
          <v-text-field
            :id="`meta_${item.id_comissao_meta}`"
            v-model="editedItem.percentual_comissao"
            :hide-details="true"
            step="0.1"
            dense
            type="number"
            single-line
            v-if="item.id_comissao_meta === editedItem.id_comissao_meta"
          ></v-text-field>
          <span v-else>{{
            item.percentual_comissao | formatarPercentual
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div style="display: flex; align-items: center">
            <div v-if="item.id_comissao_meta === editedItem.id_comissao_meta">
              <v-icon color="red" class="mr-3" @click="closeEdit()">
                mdi-window-close
              </v-icon>
              <v-icon color="green" @click="salvarMeta()">
                mdi-content-save
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                class="light-green"
                @click="editItem(item)"
                title="Editar"
              >
                mdi-pencil
              </v-icon>
            </div>
          </div>
        </template>
        <template v-if="ehMaster" v-slot:[`footer.page-text`]>
          <v-btn
            v-if="editedIndex === -1"
            small
            elevation=""
            color="success"
            @click="novaMeta()"
          >
            + NOVA META
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ComissaoReferencia from "./ComissaoReferencia.vue";
import ComissaoTurno from "./ComissaoTurno.vue";
import dayjs from "dayjs";
export default {
  name: "ComissaoMetas",
  components: { ComissaoReferencia, ComissaoTurno },
  data: () => ({
    tab: null,
    headers: [
      { text: "Meta", value: "meta", sortable: false },
      {
        text: "% acima da Meta Zero",
        value: "percentual_limite",
        align: "right",
        sortable: false,
      },
      {
        text: "Venda Mínima",
        value: "venda_minima",
        align: "right",
        sortable: false,
      },
      {
        text: "Venda Máxima",
        value: "venda_maxima",
        align: "right",
        sortable: false,
      },
      { text: "Comissão", value: "percentual_comissao", sortable: false },
    ],
    comissao: {},
    items: [],
    editedItem: {},
    editedIndex: -1,
    defaultItem: {
      id_comissao_meta: 0,
      id_comissao_regra: 0,
      meta: "Meta 0",
      percentual_limite: 10,
      percentual_comissao: 0.5,
    },
    turnoSelecionado: 0,
    metaZero: 0,
    mesCompetencia: dayjs().month(),
    meses: [
      { id_mes: 0, mes: "Janeiro" },
      { id_mes: 1, mes: "Fevereiro" },
      { id_mes: 2, mes: "Março" },
      { id_mes: 3, mes: "Abril" },
      { id_mes: 4, mes: "Maio" },
      { id_mes: 5, mes: "Junho" },
      { id_mes: 6, mes: "Julho" },
      { id_mes: 7, mes: "Agosto" },
      { id_mes: 8, mes: "Setembro" },
      { id_mes: 9, mes: "Outubro" },
      { id_mes: 10, mes: "Novembro" },
      { id_mes: 11, mes: "Dezembro" },
    ],
    modificouColaboradores: false,
    colaboradoresAntes: 0,
    ehMaster: false,
  }),
  watch: {
    turnoSelecionado(val) {
      if (val) {
        this.calcularMetaZero();
      }
    },
    mesCompetencia(val) {
      if (val >= 0) {
        this.calcularMetaZero();
      }
    },
  },
  computed: {
    computedHeaders() {
      if (this.editedIndex === -1) {
        return this.headers;
      } else {
        return this.headers.filter(
          (x) => x.value !== "venda_minima" && x.value !== "venda_maxima"
        );
      }
    },
  },
  methods: {
    mudarColaboradores() {
      this.modificouColaboradores = true;
      this.calcularMetaZero();
    },
    continuarSemTurnos() {
      if (!this.comissao.colaboradores || this.comissao.colaboradores == 0) {
        this.comissao.colaboradores = this.colaboradoresAntes > 0 ? this.colaboradoresAntes : 10;
      }  
      this.$http
        .post("comissao/salvar", {
          id_comissao_regra: this.comissao.id_comissao_regra,
          colaboradores: this.comissao.colaboradores,
        })
        .then(async () => {
          this.calcularMetaZero();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    async definirTurnos() {
      this.colaboradoresAntes = this.comissao.colaboradores;
      await this.$http
        .post("comissao/salvar", {
          id_comissao_regra: this.comissao.id_comissao_regra,
          colaboradores: null,
        })
        .then(async (res) => {
          this.comissao.colaboradores = null;
          this.comissao.turnos = res.data.turnos;
          if (this.comissao.turnos.length > 0) {
            this.turnoSelecionado = this.comissao.turnos[0];
            this.calcularMetaZero();
          }
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    salvarColaboradores() {
      this.$http
        .post("comissao/salvar", {
          id_comissao_regra: this.comissao.id_comissao_regra,
          colaboradores: this.comissao.colaboradores,
        })
        .then(async () => {
          this.$alertar(
            "success",
            "Total de colaboradores na regra salvo!",
            "=)"
          );
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    limiteAnterior(item) {
      let indice = this.items.indexOf(item);
      if (this.items[indice - 1]) {
        return this.items[indice - 1].percentual_limite;
      } else {
        return this.items[this.items.length - 1].percentual_limite;
      }
    },
    calcutarMetas() {
      if (this.comissao.metas && this.comissao.metas.length > 0) {
        let anterior = parseFloat(this.metaZero);
        for (var i = 0; i < this.comissao.metas.length; i++) {
          let percentual_limite = this.comissao.metas[i].percentual_limite
            ? parseFloat(this.comissao.metas[i].percentual_limite) / 100
            : -1.0;
          let venda_minima = parseFloat(anterior);
          let venda_maxima = -1.0;
          // A última meta não tem limite superior...
          if (percentual_limite >= 0) {
            venda_maxima = parseFloat(
              parseFloat(this.metaZero) +
                percentual_limite * parseFloat(this.metaZero)
            );
          }
          this.comissao.metas[i].venda_minima = venda_minima;
          this.comissao.metas[i].venda_maxima = venda_maxima;
          anterior = venda_maxima + 0.01;
        }
      }
    },
    calcularMetaZero() {
      if (this.mesCompetencia >= 0 && this.comissao && this.comissao.referencia) {
        let resultado = 0;
        const mesFat = parseFloat(
          this.comissao.referencia[
            Object.keys(this.comissao.referencia)[this.mesCompetencia]
          ]
        );
        let percentual = 1.0;
        let totalColaboradores = this.comissao.colaboradores;
        if (!this.comissao.colaboradores) {
          percentual =
            parseFloat(this.turnoSelecionado.percentual_faturamento) / 100;
          totalColaboradores = this.turnoSelecionado.colaboradores;
        }
        resultado = ((mesFat * percentual) / totalColaboradores).toFixed(2);
        this.metaZero = resultado;
        this.calcutarMetas();
      }
    },
    novoTurno() {
      this.comissao.turnos.push({
        id_comissao_regra: this.comissao.id_comissao_regra,
        id_comissao_turno: 0,
        turno: `Novo Turno`,
        colaboradores: 0,
        percentual_faturamento: 0,
      });
    },
    voltar() {
      window.history.back();
    },
    novaMeta() {
      this.items.push(this.defaultItem);
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$nextTick(() => this.$refs.meta_0.focus());
    },
    faixasValidas() {
      const faixa = this.items[this.editedIndex];
      // Obter a venda mínima e máxima da linha editada
      const minimaEditada = faixa.percentual_minimo;
      const maximaEditada = faixa.percentual_maximo;
      // Verificar se há sobreposição com outras faixas de vendas
      for (let i = 0; i < this.items.length; i++) {
        if (i === this.editedIndex) {
          continue; // Ignorar a linha editada
        }
        const faixaAtual = this.items[i];
        const minimaAtual = faixaAtual.percentual_minimo;
        const maximaAtual = faixaAtual.percentual_maximo;
        // Verificar se há sobreposição entre as faixas
        if (
          (minimaEditada >= minimaAtual && minimaEditada <= maximaAtual) ||
          (maximaEditada >= minimaAtual && maximaEditada <= maximaAtual) ||
          (minimaEditada <= minimaAtual && maximaEditada >= maximaAtual)
        ) {
          return false; // Sobreposição detectada
        }
      }
      return true; // Não há sobreposição
    },
    async salvarMeta() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      }
      if (!this.faixasValidas()) {
        this.$alertar(
          "warning",
          `Faixa inválida. As faixas de vendas não devem se sobrepor!`,
          "=("
        );
        return;
      }
      this.editedItem.id_comissao_regra = this.comissao.id_comissao_regra;
      this.$http
        .post("comissao/meta/salvar", this.editedItem)
        .then(async () => {
          this.$alertar("success", "Meta salva!", "=)");
          this.buscarMetas();
          this.closeEdit();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
          this.closeEdit();
        });
    },
    closeEdit() {
      setTimeout(() => {
        if (this.editedItem.id_comissao_meta === 0) {
          this.items.pop();
        }
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$nextTick(() => this.$refs[`meta_${item.id_comissao_meta}`].focus());
    },
    buscarMetas() {
      this.editedIndex = -1;
      this.editedItem = {};
      this.$http
        .post("comissao/meta/buscar", {
          id_comissao_regra: localStorage.getItem("id_comissao_regra"),
        })
        .then(async (res) => {
          if (res.data.length > 0) {
            this.comissao = res.data[0];
            this.$store.dispatch("set_titulo", this.comissao.regra);
            this.items = this.comissao.metas;
            if (!this.items) this.items = [];
            if (!this.comissao.colaboradores && this.comissao.turnos.length == 0) {
              await this.definirTurnos();
            } else {
              this.turnoSelecionado = this.comissao.turnos[0];
              this.calcularMetaZero();
            }
          }
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
  },
  mounted() {
    this.buscarMetas();
    this.ehMaster = (JSON.parse(localStorage.getItem('perfis')))?.includes(1);
    if (this.ehMaster) {
      this.headers.push({ text: "Ações", value: "actions", sortable: false });
    }
  },
};
</script>
<style scoped>
.light-green {
  color: green !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-green:hover {
  background-color: lightgreen !important;
}
.light-red {
  color: red !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-red:hover {
  background-color: lightcoral !important;
}
.centered-input >>> input {
  text-align: center;
}
.right-input input {
  text-align: right;
}
</style>