<template>
  <div class="cc-principal">
    <div style="display:flex;justify-content:center;margin-bottom:20px;">
        <select data-cy="filtroPedidos" v-model="statuspagamento" class="select">
            <option value=3 >Pagamento Realizado</option>
            <option value=5 >Pagamento Estornado</option>
        </select>
    </div>

    <!-- <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" /> -->
    <div v-if="listadepagamentos.length > 0">

        <div v-for="pagto in listadepagamentos" :key="pagto.id" style="border:1px solid black;border-radius:10px;padding:10px;">
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Id Pagamento:</div><div>{{pagto.id}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Data Pagamento:</div><div>{{pagto.datacriacao | formatarDataHora}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Tipo:</div><div>{{pagto.tipotransacao}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Status:</div><div>{{pagto.desstatustransacao}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Valor:</div><div>{{pagto.valor | formatarMoeda}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Parcelas:</div><div>{{pagto.qtdeparcelas}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Gateway:</div><div>{{pagto.nomegateway}}</div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >Cod Transação:</div>{{pagto.idpagamentogateway}}<div></div></div>
            <div style="display:flex;"><div style="width:35%;font-weight:bold;" >obs:</div><div>{{pagto.obs}}</div></div>
        </div>

    </div>

    <div v-else style="display:flex;min-height:70vh;justify-content:center;align-items:center;">
        <span style="font-size:20px;text-align:center">{{this.carregando ? this.carregando : 'Você ainda não possui Pagamentos neste Status!'}}</span>
    </div>

   
<!-- 
    <ModalConfirmacao :showModal="exibirModalLocal" :titulo="'Onde você está?'"
        textosim="Quarto" textonao="Restaurante" @sim="()=>setarLocal('QUARTO')" @nao="()=>setarLocal('RESTAURANTE')"  />

    <ModalConfirmacaoInput :showModal="exibirModalNumeroLocal" :titulo="`Qual o numero ${ tipoLocal == 1? ' do seu quarto?':' da sua mesa?' }`"
        textosim="Confirmar" textonao="Cancelar" @sim="(numero)=>setarNumeroLocal(numero)" @nao="()=>fecharModais()"
        :valorinicial="valorinicialmodal"  />

    <ModalConfirmacaoInput :showModal="exibirModalCartaoConsumo" :titulo="`Qual o numero do seu Cartao de Consumo?`" 
        textosim="Confirmar" textonao="Cancelar" @sim="(numero)=>setarNumeroCartaoConsumo(numero)" @nao="()=>fecharModais()"  
             />

    <ModalConfirmacao :showModal="exibirModalConfirmacao" :titulo="'Ao enviar o pedido ele não poderá ser cancelado!'"
        textosim="Enviar" textonao="Cancelar" @sim="()=>confirmarPedido()" @nao="()=>fecharModais()" />
                 -->

  </div>
</template>

<script>
//   import PedidosLista from '../../components/pedidos/PedidosLista.vue'
//   import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
//   import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'



  export default {
    name: 'Pedidos',
    components: {
    //   PedidosLista,
    //   ModalConfirmacao,
    //   ModalConfirmacaoInput,
    },
    watch:{
        async statuspagamento(valor){
           
            this.ojbPagamentos = null
            let idreservasfront = localStorage.getItem('idreservasfront')
            this.carregando = "Carregando itens"
            this.corTexto = this.listaCores[valor].cor
            let idstatus = valor 
            if(valor == 5){
                idstatus = '5,7'
            }
            this.ojbPagamentos = await this.getComandasReservaPorStatus(idreservasfront,idstatus)
            this.carregando = null
        },
    },
    data: () => ({
        listadepagamentos:[],
        ojbPagamentos:null,
        statuspagamento:3,
        listaCores:[
            {cor:"#55ad94"},
            {cor:"#55ad94"},
            {cor:"#55ad94"},
            {cor:"#555cdd"},
            {cor:"#d98e00"},
            {cor:"green"},
            {cor:"red"},
            {cor:"#d98e00"},
            {cor:"#d98e00"},
            {cor:"#d98e00"},
        ],
        corTexto:'',
        carregando:null,

    }),
    methods:{
        async listarPagamentosPorHospede(){
            let idcheckin = localStorage.getItem('idcheckin')
            this.$http.post(`pagamento/listarPagamentosPorHospede/${idcheckin}/${this.statuspagamento}`).then((resp)=>{
                this.listadepagamentos = resp.data
            })
            .catch((error)=>{
                alert(error)
                console.log(error)
                this.$alertar("warnign","Não Po","=(")
            })
        }
        // async confirmarPedido(){
        //     this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/2`).then(()=>{
        //         this.fecharModais()
        //         this.getComandasReservaPorStatus()
        //         this.$alertar("success","Pedido Enviado para Produção","=)")
        //     })
        //     .catch((error)=>{
        //         console.log(error)
        //         this.$alertar("success","Não foi possivel exibir os produtos no carrinho","=(")
        //     })
        //     // await this.getComandasReservaPorStatus()
        //     // this.$router.push('/Hospede/Pedidos')
        // },
        // setarNumeroLocal(numero){
        //     this.numeroLocal = numero
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = false
        //     this.exibirModalCartaoConsumo = true
        // },
        // setarNumeroCartaoConsumo(numero){
        //     this.numeroCartaoConsumo = numero
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = true
        // },
        // setarLocal(local){
        //     if(local === 'QUARTO'){
        //         this.tipoLocal = 1
        //         this.exibirModalLocal = false
        //         this.exibirModalNumeroLocal = true
        //         this.valorinicialmodal = this.numeroLocal
        //     }
        //     if(local === 'RESTAURANTE'){
        //         this.tipoLocal = 2
        //         this.exibirModalLocal = false
        //         this.exibirModalNumeroLocal = true
        //         this.valorinicialmodal = this.numeroCartaoConsumo
        //     }
            
        // },
        // fecharModais(){
        //     this.exibirModalLocal = false
        //     this.exibirModalNumeroLocal = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalCartaoConsumo = false
        //     this.exibirModalConfirmacao = false
        // },
        // async getPedidosPorStatus(){
        //     let idreservasfront = localStorage.getItem('idreservasfront')
        //     this.ojbPagamentos = await this.getComandasReservaPorStatus(idreservasfront,this.statuspagamento)
        //     this.corTexto = this.listaCores[this.statuspagamento].cor
        // },
        // async getComandasReservaPorStatus(idreservasfront,idstatus){
        //     let objetoRetorno = {}
        //     await this.$http('pdv/getComandasReservaPorStatus/'+idreservasfront+'/'+idstatus+'/t').then((resp) =>{
        //       console.log(resp.data)
        //       objetoRetorno.valorTotal = 0
        //       objetoRetorno.listadepagamentos = resp.data
        //       console.log("objetoRetorno.listadepagamentos")
        //       console.log(objetoRetorno.listadepagamentos)
        //       for(var i = 0, len = objetoRetorno.listadepagamentos.length; i < len; i++){
        //           objetoRetorno.valorTotal += parseFloat(objetoRetorno.listadepagamentos[i].valor)
        //           objetoRetorno.statuspagamento = objetoRetorno.listadepagamentos[i].statuspagamento
        //       }
        //       console.log("objetoRetorno")
        //       console.log(objetoRetorno)

        //     }).catch(error =>{
        //       console.log(error)
        //       this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
        //     })

        //     return objetoRetorno
        // },
        // retirarProdutoCarrinho(produto){
            
        //     let objetoproduto = {
        //         idprodutocomanda:produto.id,
        //         numpedido:produto.idcomanda
        //     }
        //     this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then(() =>{
        //       this.getComandasReservaPorStatus()
        //     }).catch(error =>{
        //       console.log(error)
        //       this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
        //     })
        // },
        // direcionar(){
        //     this.$router.push('/Hospede/Menu')
        // },
        // finalizarPedido(){
        //     this.exibirModalLocal = true
        // }
    },
    mounted(){
        this.$store.dispatch("set_titulo", "Comprovantes")
        // this.getComandasReservaPorStatus()
        this.listarPagamentosPorHospede()
    }
  }
</script>

<style scoped>

.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}


    .shoppingCarAddLine{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height:20vh;
        border-top: 1px solid rgb(156, 156, 156);
    }

    .shoppingCarAddLineAddText{
        /* background-color: yellow; */
        width: 100%;
        height: 30%;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        color:#4CAF50;
        font-weight: bold;
    }

    

    .shoppingCarAddLineInner{
        /* background-color: blue; */
        display: flex;
        align-items: center;
        /* position: fixed;
        bottom: 0px; */
        width: 100%;
        height: 70%;
        /* border-top: 1px solid rgb(156, 156, 156); */
    }

    .shoppingCarAddLineTotal{
        width:30%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

    }

    .shoppingCarAddLineAdd{
        width:70%;
        /* background-color: red; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoppingCarAddLineAddBtn{
        background-color: #4CAF50;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0px 7%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .shoppingCarAddLineTotalBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 10%;
        align-items: center;
        justify-content: space-between;
    }

    .shoppingCarAddLineTotalBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 50px;
        padding:10px;
        border-radius: 10px;
  
    }
</style>