<template>
  <div>
    <div>
      <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
          <v-btn depressed color="error" @click="voltar()">{{$t('voltar.texto')}}</v-btn>
      </div>
    </div>
    <div class="itemList">
      
      <div class="d-flex align-center item" @click="direcionar(pdv.idpdv)" data-cy="pdv" v-for="pdv in pdvs" :key="pdv.idpdv">
        <div class="colA">
          <v-img class="colAimg" :src="pdv.img ? pdv.img: null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
          <div class="colBItemTitle">{{pdv.descpdv}}</div>
          <div v-show="pdv.dentro_do_horario == 'false'" class="colBItemOutHour" style="color:red">Fora do horário</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PDVMesas",
  data: () => ({
    pdvs: [],
  }),
  async mounted() {

    try {
      this.retrieveInfos()
      await this.getPDVS()  
    } catch (error) {
      console.log(error)
    }
    
  },
  methods: {
    voltar() {
      this.$router.go(-1);
    },  
    async getPDVS(){
      await this.$http('pdv/getPDVSCD/0').then(async res => {
        this.filterPDVS(res.data);
      }).catch((error)=>{
        this.$alertar('warning',error,'=(')
      })
    },
    retrieveInfos() {
      this.pdvs = this.$route.query.pdvs || [];
      if(typeof this.pdvs != 'object'){
        this.pdvs = [this.pdvs] 
      }
      this.mesa = this.$route.query.mesa ? decodeURIComponent(this.$route.query.mesa) : "";

      if(!this.pdvs) {
        this.$alertar("warning", "Erro", "Não foi encontrado nenhum PDV para esta mesa.");
        this.$router.push("/");
      } else {
        this.pdvs = this.pdvs.map(pdv => Number(pdv));
      }
    },
    filterPDVS(pdvs){
      this.pdvs = pdvs.filter(pdv => this.pdvs.includes(pdv.idpdv));
    },
    direcionar(idpdv) {
      this.$router.push('/Hospede/CategoriasMesa/'+idpdv);
    }
  }

}
</script>



<style scoped>
  .itemList{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
  }

  .item{
    font-size:1.2em;
    color:rgb(70, 70, 70);
    /* background-color: pink; */
    height: 70px;
    width:330px;
    max-width:100%;
    margin-bottom: 20px;
    margin-right:2%;
    border: 1px solid rgb(156, 156, 156);
    border-radius: 10px;
    padding:5px;
  }
  .item .colA{
    /* background-color:red; */
    width: 60px;
    padding-left:2%;
    padding-right: 2%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item .colA .colAimg{
    border-radius: 100%;
    height:50px;
    max-height:50px;
    width:50px;
    max-width:50px;
  }

  .item .colB{
    /* background-color:Blue; */
    width: 270px;
    padding-left:2%;
    display: flex;
    flex-direction: column;
    padding-right:2%;
    word-break: break-all;
  }

  .item .colB .colBItemTitle{
    /* background-color:red; */
    font-size:18px;
    line-height: 1.1;
    height: 70%;
  }

  .item .colB .colBItemOutHour{
    /* background-color:red; */
    font-size:14px;
    line-height: 1.1;
    height: 70%;
  }

  .item .colB .colBItemPrice{
    /* background-color:Blue; */
    display: flex;
    font-size:18px;
    line-height: 1.1;
    height: 30%;
    padding-bottom:10%;
    padding-right: 10px;
    justify-content: flex-end;
    font-weight: bold;
  }
</style>
