<template>
    <div>
        <div>
            <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
                <v-btn depressed color="error" @click="voltar()">{{$t('voltar.texto')}}</v-btn>
            </div>
        </div>
        <div>
            <v-img class="productimg" :src="dadosProduto.img ? dadosProduto.img : null || '/imgs/food.png'" aspect-ratio="2.0" ></v-img>
            <v-container class="productContainer">
                <TitleH1 :text="dadosProduto.nomecd || dadosProduto.descproduto" />
                <Description :text="dadosProduto.descricao" />
                <div class="price">Preço Produto: {{dadosProduto.precoimportado | formatarMoeda}}</div>
                <div v-if="exibirTaxasAosClientes" class="pricetax">{{dadosProduto.nometaxa}}: {{dadosProduto.valortaxa}}%</div>

                <div v-if="multiselecaoOpcao">
                  <v-checkbox
                      style="padding: 0; margin: 0;"
                      v-for="opcao in opcoes"
                      :key="opcao.id"
                      :label="opcao.descricao"
                      :value="opcao.id"
                      v-model="opcionalProduto"
                  ></v-checkbox>
                </div>
                <div v-else>
                  <v-radio-group
                      v-model="opcionalProduto"
                      mandatory
                  >
                    <v-radio
                        v-for="opcao in opcoes"
                        :key="opcao.id"
                        :label="opcao.descricao"
                        :value="opcao.id"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>

                <v-textarea solo v-model="observacao" name="input-7-4" :label="$t('produto.observacao')" class="productObservation" ></v-textarea>


            </v-container>

            <div class="productAddLine">
                <div class="productAddLineCount">
                    <div class="productAddLineCountBox">
                        <div class="productAddLineCountBoxA" @click="subtrair()">-</div>
                        <div class="productAddLineCountBoxB">{{quantidadeselecionada}}</div>
                        <div class="productAddLineCountBoxA" @click="adicionar()">+</div>
                    </div>
                </div>
                <div class="productAddLineAdd">
                    <div class="productAddLineAddBtn" data-cy="addProdutoCarrinho" @click="addProdutoCarrinho()">
                        <div>{{$t('produto.adicionar')}}</div>
                        <div>{{ valorCalculado | formatarMoeda}}</div>
                    </div>
                </div>
            </div>

            <ModalConfirmacao :showModal="exibirModalBebida" :titulo="`A bebida deve vir antes ou junto com o Prato?`"
                textonao="Vir com o Prato" textosim="Vir Antes" @nao="()=>setEntregaBebida(1)" @sim="()=>setEntregaBebida(2)" @cancelar="exibirModalBebida = false" :exibeBtnCancelar="true" />

        </div>
    </div>
</template>

<script>

  import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
  import TitleH1 from '../../components/TitleH1.vue'
  import Description from '../../components/Description.vue'
  import swal from "sweetalert2";

  export default {
    name: 'Produto',

    components: {
        TitleH1,
        Description,
        ModalConfirmacao,
    },
    data: () => ({
        idpdv:null,
        idcategoriaprodcd:null,
        idsubcategoriaprodcd:null,
        idproduto:null,
        dadosProduto:null,
        quantidadeselecionada:1,
        valorCalculado:0,
        observacao:null,
        dadosComanda:null,
        dadoscategoria:null,
        exibirModalBebida:false,
        flbebidaantes:false,
        exibirTaxasAosClientes:false,
        adicionando:false,
        opcionalProduto: [],
        opcoes: [],
        multiselecaoOpcao: false,
    }),
    watch:{
        quantidadeselecionada(val){
            // this.valorCalculado = (this.dadosProduto.precoimportado) * val
            if(this.dadosProduto.valortaxa > 0 && this.exibirTaxasAosClientes){
                let taxa = this.dadosProduto.valortaxa/100
                this.valorCalculado = (this.dadosProduto.precoimportado+(this.dadosProduto.precoimportado*taxa)) * val
            }else{
                this.valorCalculado = (this.dadosProduto.precoimportado) * val
            }
        }
    },
    methods:{
        voltar() {
            this.$router.go(-1);
        },
        subtrair(){
            if(this.quantidadeselecionada > 1){
                this.quantidadeselecionada--
            }
        },
        adicionar(){
            if(this.quantidadeselecionada < 10){
                this.quantidadeselecionada++
            }
        },
        getProdutoDesc(){
            // this.$http(`pdv/getProdutoDesc/${this.idproduto}`).then(resp=>{
            this.$http(`pdv/getProdutoDescCat/${this.idcategoriaprodcd}/${this.idsubcategoriaprodcd}/${this.idproduto}`).then(resp=>{
                this.dadosProduto = resp.data
                if(this.dadosProduto.precoimportado > 0){

                    // this.valorCalculado = this.dadosProduto.precoimportado * this.quantidadeselecionada
                    if(this.dadosProduto.valortaxa > 0  && this.exibirTaxasAosClientes){
                        let taxa = this.dadosProduto.valortaxa/100
                        this.valorCalculado = (this.dadosProduto.precoimportado+ (this.dadosProduto.precoimportado*taxa)) * this.quantidadeselecionada
                    }else{
                        this.valorCalculado = this.dadosProduto.precoimportado * this.quantidadeselecionada
                    }
                }
            })
            .catch(error =>{
                this.$alertar("warning","Não foi possível buscar os dados do Produto","=(")
                console.log(error)
            })
        },
        direcionar(){
            this.$router.push('/Hospede/Carrinho')
        },
        setEntregaBebida(tipo){
            // tipo = 1 (vir com o prato)
            // tipo = 2 (vir antes)
            if(tipo == 2){
                this.flbebidaantes = true
            }
            this.salvarNoCarrinho()
        },
        async salvarNoCarrinho(){

            let produtoAdicionado = null
            this.dadosComanda = await this.$http(`/pdv/getComandaAberta`)
            this.dadosComanda = this.dadosComanda ? this.dadosComanda.data:null
            console.log("this.dadosComanda")
            console.log(this.dadosComanda)
            console.log("this.dadosComanda.pedido")
            console.log(this.dadosComanda.pedido)
            if(this.dadosComanda && this.dadosComanda.pedido && this.dadosComanda.pedido.numpedido > 0){
              const idsprodutoOpcoes = Array.isArray(this.opcionalProduto) ? this.opcionalProduto : [this.opcionalProduto]

                let objetoProduto = {
                    numpedido: this.dadosComanda.pedido.numpedido,
                    quantidade: this.quantidadeselecionada,
                    precounitario: this.dadosProduto.precoimportado,
                    codgrupoproduto: this.dadosProduto.codgrupoprodpms,
                    codproduto: this.dadosProduto.codprodutopms,
                    idproduto: this.idproduto,
                    idpdv: this.idpdv,
                    observacao:this.observacao,
                    flbebidaantes:this.flbebidaantes,
                    idreservasfront:localStorage.getItem('idreservasfront'),
                    idcategoriaprodcd:this.idcategoriaprodcd,
                    idsubcategoriaprodcd:this.idsubcategoriaprodcd,
                    idsprodutoopcoes: idsprodutoOpcoes,
                }


                produtoAdicionado = await this.$http.post(`pdv/adicionarProdutoNaComanda`, objetoProduto)
                if(produtoAdicionado){
                    this.direcionar()
                }else{
                    this.$alertar("warning","Não foi possível adicionar o produto a comanda!","=(")
                }
            }else{

                // this.$alertar("blocked-success", "Estamos abrindo uma nova comanda, por favor aguarde!" ,"=)");

                const body = {
                    autoatendimentoImportarHITS: true,
                    id_ultimo_usuario_atualizacao: -1
                }

                console.log("ue")

                await this.$http.post(`pdv/iniciarComanda`, body)
                    .then(res => this.dadosComanda = res)
                    .catch(err => this.$alertar("warning", err.response.data.message ,"=("))

              console.log("iniciou comanda")

                this.dadosComanda = this.dadosComanda && this.dadosComanda.data
                if(this.dadosComanda && this.dadosComanda.pedido > 0){

                  const idsprodutoOpcoes = Array.isArray(this.opcionalProduto) ? this.opcionalProduto : [this.opcionalProduto]

                    let objetoProduto ={
                        numpedido: this.dadosComanda.pedido,
                        quantidade: this.quantidadeselecionada,
                        precounitario: this.dadosProduto.precoimportado,
                        codgrupoproduto: this.dadosProduto.codgrupoprodpms,
                        codproduto: this.dadosProduto.codprodutopms,
                        idproduto: this.idproduto,
                        idpdv: this.idpdv,
                        observacao:this.observacao,
                        flbebidaantes:this.flbebidaantes,
                        idreservasfront:localStorage.getItem('idreservasfront'),
                        idhospede:localStorage.getItem('idhospede'),
                        idcategoriaprodcd:this.idcategoriaprodcd,
                        idsubcategoriaprodcd:this.idsubcategoriaprodcd,
                        idsprodutoopcoes: idsprodutoOpcoes,
                    }
                  console.log(objetoProduto)

                    console.log("adicionando produto na comanda depois de ter iniciado ela")

                    swal.close()

                    produtoAdicionado = await this.$http.post(`pdv/adicionarProdutoNaComanda`,objetoProduto)
                    if(produtoAdicionado){

                      console.log("produto adicionado")

                        this.direcionar()
                    }else{
                        this.$alertar("warning","Não foi possível adicionar o produto a comanda!","=(")
                    }
                }
            }

        },
        async addProdutoCarrinho(){

          console.log("aqui")

            if(!this.adicionando){

                if(localStorage.getItem("pessoaLogada") && localStorage.getItem("pessoaLogada") == "false") {
                    const produtosAnterior = localStorage.getItem("produtosCarrinho")
                    let produtos = produtosAnterior ? JSON.parse(produtosAnterior) : []

                    const idsprodutoOpcoes = Array.isArray(this.opcionalProduto) ? this.opcionalProduto : [this.opcionalProduto]

                    let produto = {
                        ...this.dadosProduto,
                        precounitario: this.dadosProduto.precoimportado,
                        codgrupoproduto: this.dadosProduto.codgrupoprodpms,
                        codproduto: this.dadosProduto.codprodutopms,
                        idproduto: this.idproduto,
                        idpdv: this.idpdv,
                        observacao:this.observacao,
                        flbebidaantes:this.flbebidaantes,
                        idreservasfront:localStorage.getItem('idreservasfront'),
                        idhospede:localStorage.getItem('idhospede'),
                        idcategoriaprodcd:this.idcategoriaprodcd,
                        idsubcategoriaprodcd:this.idsubcategoriaprodcd,
                        quantidade: this.quantidadeselecionada.toFixed(2),
                        idsprodutoopcoes: idsprodutoOpcoes
                    }

                    const produtoJaNoCarrinho = produtos.find(p => p.idproduto == this.idproduto)

                    if(!produtoJaNoCarrinho){
                        produtos.push(produto)
                    } else {
                        const index = produtos.findIndex(p => p.idproduto == this.idproduto)
                        produtos[index] = produto
                    }

                    localStorage.setItem("produtosCarrinho", JSON.stringify(produtos))

                    this.direcionar()


                } else {

                    this.adicionando = true
                    //buscar dados dos pedidos dos dados da comanda e verificar se existe um produto do tipo comida
                    let dadoscomanda = await this.$http(`/pdv/getComandaAberta`).then(res => res.data)
                    let dadospedido = await this.$http('pdv/getProdutosdoPedido/'+dadoscomanda.pedido.numpedido+'/c').then(res => res.data)

                    //caso nao tenha pedidos do tipo comida no carrinho
                    //adicionar o pedido no carrinho sem perguntar se é para vir antes ou junto com o prato
                    if(dadospedido.length === 0){
                      return this.salvarNoCarrinho()
                    }

                    if(this.dadoscategoria.flbebida){
                        this.exibirModalBebida = true
                    }else{
                        this.salvarNoCarrinho()
                    }

                }
            }
        },
        async carregaOpcoes() {
          const idproduto = this.$route.params.idproduto

          this.$http.get(`pdv/opcoesProduto/${idproduto}/true`)
              .then((res) => {
                this.opcoes = res.data?.opcoes;
                this.multiselecaoOpcao = res.data?.configuracao?.multiselecao;
              })
              .catch(() => {
                this.$alertar("warning", "Erro", "Erro ao carregar opcionais por produto!")
              });
        },
    },
    async mounted(){

      await this.carregaOpcoes();

        this.adicionando = false
        this.idpdv = this.$route.params.idpdv
        this.idcategoriaprodcd = this.$route.params.idcategoriaprodcd
        this.idsubcategoriaprodcd = this.$route.params.idsubcategoriaprodcd
        this.idproduto = this.$route.params.idproduto

        await this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOS'})
        .then(resp =>{
            this.exibirTaxasAosClientes =  resp.data[0].valor4 === 'true'? true : false
        }).catch(error =>{
            alert(error)
        })

        this.getProdutoDesc()

        this.dadoscategoria = await this.$http(`pdv/getCategorias/${this.idpdv}/${this.idcategoriaprodcd}`)
        this.dadoscategoria = this.dadoscategoria.data[0]


        this.$http.post('pdv/buscarparametros',{chave:'FLUXOPEDIDOS'})
        .then(resp =>{
            this.exibirTaxasAosClientes =  resp.data[0].valor4 === 'true'? true : false
        }).catch(error =>{
            alert(error)
        })

    }
  }
</script>

<style scoped>
    .productimg{
        margin-bottom: 1%;
    }

    .price{
        font-size: 1.2em;
        color:green;
        margin-bottom: 10px;
    }

    .pricetax{
        font-size: 1.2em;
        color:red;
        margin-bottom: 10px;
    }

    .productContainer{
        margin-bottom: 80px;
    }

    .productObservation{
        margin-bottom: 100px;
    }

    .productAddLine{
        background-color: white;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height:14vh;
        border-top: 1px solid rgb(156, 156, 156);
    }

    .productAddLineCount{
        width:40%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .productAddLineAdd{
        width:60%;
        /* background-color: red; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .productAddLineAddBtn{
        background-color: #4CAF50;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0px 7%;
        margin-right: 5%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .productAddLineCountBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 5%;
        align-items: center;
        justify-content: space-between;
    }

    .productAddLineCountBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }

</style>
