<template>
    <v-dialog v-model="exibirModalAddPagtoBaixar"  width="1000" max-width="80vw" >
        <v-card style="max-width: 100%;padding:0px!important;" >
             <v-toolbar color="blue" class="mb-2">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Pagamento e Baixar - Comanda {{idcomanda}} - Cliente {{pedido.nome}}
                    </span>
                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>


            <v-card-text style="margin:0px!important;" >
                <v-card style="padding:10px;margin:0px!important;">
                    <div style="width:100%;">
                        <div>
                             <v-container class="">
                                <v-row>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Cobrar Taxas?</h3>
                                            <v-select return-object color v-model="cobrartaxas" :items="listacobrartaxas" @change="setValorFinalFechar()"  item-text="nome" item-value="id" label="Cobrar Taxas"></v-select>
                                        </div>
                                  </v-col>

                                    <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Valor</h3>
                                            <div style="margin-top:20px;font-size:20px;">{{pedido.valorTotalSemTaxa | formatarMoeda}}</div>
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Valor Taxa:</h3>
                                            <div style="margin-top:20px;font-size:20px;">{{valorFecharComanda | formatarMoeda}}</div>
                                        </div>
                                    </v-col>
                                    <v-col style="background-color:#f0f6fb;">
                                        <div style="padding-bottom:10px;">
                                            <h3>Valor Pagar:</h3>
                                            <div style="margin-top:20px;font-size:20px;" data-cy="valor-total-pagamento">
                                                {{
                                                    (cobrartaxas.id == 1?
                                                pedido.valorTotal:pedido.valorTotalSemTaxa) | formatarMoeda}}</div>
                                        </div>
                                    </v-col>


                                </v-row>
                              </v-container>
                        </div>

                        <div>
                             <v-container class="">
                                <v-row>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Valor?</h3>
                                            <input maxlength="50" data-cy="input-valorpagto" type="number" v-model="valorpagtonovo" placeholder="Digite o nome da Categoria" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                        </div>
                                  </v-col>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Meio Pagamento:</h3>
                                            <v-select data-cy="meios-pagamentos" color v-model="tipoPagamentoSelecionado" :items="listaTiposPagamentos" item-text="label" item-value="value" label="Tipo Pagamento" return-object></v-select>
                                        </div>
                                  </v-col>

                                  <v-col v-if="tipoIntegracao === 'ADMH' ">
                                        <div style="padding-bottom:10px;">
                                            <h3>Detalhe: (até 20 caracteres)</h3>
                                            <v-text-field type="text" name="name" label="Pix, Crédito 2x" id="nsu" v-model="nsunovo" ></v-text-field>
                                        </div>
                                  </v-col>

                                  <v-col v-else>
                                        <div style="padding-bottom:10px;">
                                            <h3>NSU:</h3>
                                            <v-text-field type="number" name="name" label="NSU" id="nsu" v-model="nsunovo" ></v-text-field>
                                        </div>
                                  </v-col>
                                  <v-col>
                                        <div style="padding-top:30px;">
                                            <v-btn color="primary" @click="adicionarPagtoArray()" data-cy="adicionar-pagamento" :disabled="this.comparaValorPagamentoBotaoAdd()">
                                                Add
                                            </v-btn>
                                        </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                        </div>

                        <div>
                             <v-container class="">
                                <v-row>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Valor Total de {{ this.totalPagamentosAdicionados | formatarMoeda }}</h3>
                                                <v-data-table
                                                    :headers="headerspagamentos"
                                                    :items="listaPagamentos"
                                                    class="elevation-1"
                                                >
                                                    <template v-slot:[`item.action`]="{ item }">
                                                         <v-btn small elevation="" color="error" @click="removerPagamento(item)" >Remover</v-btn>
                                                    </template>

                                                </v-data-table>
                                        </div>
                                  </v-col>

                                </v-row>
                              </v-container>
                        </div>



                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <!-- <v-btn color="primary" @click="adicionarPagamentoFecharComanda()" data-cy="criarCategoria" :disabled="valorFecharComanda.toFixed(2) == somarPagtosAdicionados()? false:true || bloquearBotaoPagamento">
                            Adicionar Pagamentos e Fechar Comanda.

                        </v-btn> -->
                        <v-btn
                            color="primary"
                            @click="adicionarPagamentoFecharComanda()"
                            data-cy="fechar-comanda-passante"
                            :disabled="this.comparaValorPagamento()"
                        >
                            Adicionar Pagamentos e Fechar Comanda.
                        </v-btn>
                    </div>
                    <br>
                    <br>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from 'dayjs'

  export default {
    name: 'ModalddPagtoPassante',
    props:["exibirModalAddPagtoBaixar","idpdvcd","pedido"],
    data: () => ({
        idcomanda:null,
        cobrartaxas:{nome:'SIM',id:1},
        listacobrartaxas:[{nome:'SIM',id:1},{nome:'NAO',id:2}],
        tipoPagamentoSelecionado:null,
        listaTiposPagamentos:[],
        valorpagtonovo:null,
        listaPagamentos:[],
        nsunovo:null,
        valorFecharComanda:0,
        headerspagamentos: [
                { text: "Valor", value: "valorpagto" },
                { text: "Tipo", value: "nomepagamento" },
                { text: "NSU", value: "nsu" },
                { text: "Remover", value: "action" },
            ],
        totalPagamentosAdicionados:0,
        categoria:{
            id:null,
            nome:null,
            tempopreparo:40,
            horainicio:'00:00:00',
            horafinal:'23:59:00',
            ativo:true,
            flbebida:false,
            liberarautomatico:false,

        },
        listaHorarios:[],
        seg:false,
        ter:false,
        qua:false,
        qui:false,
        sex:false,
        sab:false,
        dom:false,
        listadeimpressoras:[],
        impressora:null,
        fluxoImpressao:null,
        pedidoAux:null,
        bloquearBotaoPagamento: false,
        tipoIntegracao:  "",
    }),
    async mounted(){

        this.tipoIntegracao = localStorage.getItem('tipo_integracao')


        this.idcomanda = this.pedido.listadepedidos && this.pedido.listadepedidos.length > 0 && this.pedido.listadepedidos[0].idcomanda?
                                                this.pedido.listadepedidos[0].idcomanda:this.pedido.numpedido

        this.pedido = await this.getProdutosdoPedido(this.idcomanda)

        await this.listarTiposPagamentos()
        this.setValorFinalFechar()

        await this.getFluxoImpressao()

        this.$http.post('pdv/getImpressoras',{ativo:true}).then(resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listadeimpressoras = resp.data
                    this.impressora = {nome:resp.data[0].nome, ip:resp.data[0].ip, id:resp.data[0].id}
                }
            }).catch(error =>{
                alert(error)
            })

        await this.getPagamentosRegistrados();
    },
    methods:{
        comparaValorPagamento() {
            const valor = this.valorFecharComanda.toFixed(2) == this.somarPagtosAdicionados()
            return valor ? this.bloquearBotaoPagamento || false : true
        },
        comparaValorPagamentoBotaoAdd() {
            if (this.valorFecharComanda.toFixed(2) === '0.00' || this.valorFecharComanda.toFixed(2) === !this.valor) return false;
            console.log(this.somarPagtosAdicionados())
            const valor = this.valorFecharComanda.toFixed(2) == this.somarPagtosAdicionados()
            return valor ? true : false
        },
        async getProdutosdoPedido(numpedido){
            let objetoRetorno = {}
            await this.$http('pdv/getProdutosdoPedido/'+numpedido+'/t').then((resp) =>{
            objetoRetorno.valorTotal = 0
            objetoRetorno.valorTotalSemTaxa = 0
            objetoRetorno.numpedido = resp.data[0].idcomanda
            objetoRetorno.datafeito = resp.data[0].datahoraabertura
            objetoRetorno.datarecebido = resp.data[0].datahoraabertura
            objetoRetorno.imgassinatura = resp.data[0].imgassinatura
            objetoRetorno.idtipolocal = resp.data[0].idtipolocal
            objetoRetorno.numerolocal = resp.data[0].numerolocal
            objetoRetorno.numreserva = resp.data[0].numreserva
            objetoRetorno.coduh = resp.data[0].coduh
            objetoRetorno.nome = resp.data[0].nome
            objetoRetorno.sobrenome = resp.data[0].sobrenome
            objetoRetorno.fl_comanda_fechada = resp.data[0].fl_comanda_fechada
            objetoRetorno.idgarcominicial = resp.data[0].idgarcominicial
            objetoRetorno.nomegarcominicial = resp.data[0].nomegarcominicial
            objetoRetorno.idgarcomatual = resp.data[0].idgarcomatual
            objetoRetorno.idstatuscomanda = resp.data[0].idstatuscomanda
            objetoRetorno.nomegarcom = resp.data[0].nomegarcom
            objetoRetorno.nomepdv = resp.data[0].nomepdv
            objetoRetorno.passante = resp.data[0].passante
            objetoRetorno.id_tipo_hospede_hits = resp.data[0].id_tipo_hospede_hits
            objetoRetorno.listadepedidos = resp.data
            for(var i = 0, len = resp.data.length; i < len; i++){
                // if(this.exibirTaxa){
                //   objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
                // }else{
                    // objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
                    objetoRetorno.valorTotal += (parseFloat(resp.data[i].valortotal))
                    objetoRetorno.valorTotalSemTaxa += (parseFloat(objetoRetorno.listadepedidos[i].valorsemtaxa))
                // }
            }
            }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            // await this.$http(`pdv/listarDescontos/${numpedido}`)
            // .then(res => {
            //   if(!res.data[0]) {

            //     const desconto = {
            //       valor: 0,
            //       tipo: 'valor',
            //       valorComDesconto: objetoRetorno.valorTotal,
            //       valorComDescontoSemTaxa: objetoRetorno.valorTotalSemTaxa,
            //     }

            //     return objetoRetorno.desconto = desconto
            //   }

            //   const valorComDesconto = this.calculaDesconto(res.data[0].valor, res.data[0].tipodesconto, objetoRetorno)

            //   objetoRetorno.valorTotal = valorComDesconto.comTaxa
            //   objetoRetorno.valorTotalSemTaxa = valorComDesconto.semTaxa

            // })

            return objetoRetorno
        },
        calculaDesconto(desconto, tipo, obj) {
        let comTaxa = 0
        let semTaxa = 0

            if(tipo === 'valor') {
                comTaxa = obj.valorTotal - desconto
                semTaxa = obj.valorTotalSemTaxa - desconto

            }

            if(tipo === 'percentual') {
                const valorPercentual = (obj.valorTotal * desconto) / 100
                comTaxa = obj.valorTotal - valorPercentual
                semTaxa = obj.valorTotalSemTaxa - valorPercentual
            }

            return {comTaxa, semTaxa}
        },
        async adicionarPagamentoFecharComanda(){
            this.bloquearBotaoPagamento = true
            console.log('this.somarPagtosAdicionados()', this.somarPagtosAdicionados())

            if(this.valorFecharComanda.toFixed(2) == this.somarPagtosAdicionados()){
                console.log('pedido', this.pedi)

                await this.$http.patch('pdv/atualizaCobrarTaxaComanda', {idcomanda: this.idcomanda, cobrartaxa: this.cobrartaxas.id})
                .then(async () => {
                    const url = `pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/5/f`
                    await this.$http.post(url,{pagamentopassante:true, web: true, passante: this.pedido.passante})
                    .then(()=>{
                        this.$alertar("success","Pagamento(s) registrado(s) e comanda baixada com sucesso!","=(")
                        this.$emit('listarComandas')
                        this.$emit('fechar')
                    })
                })
                .catch(() => {
                    this.$alertar("warning","Não foi possivel registrar o pagamento!","=(")
                })

            }else{
                this.$alertar("warning","Valor enviado está diferente do valor total!","=(")
            }

            this.bloquearBotaoPagamento = false
        },
        async removerPagamento(pagamento) {
            const idusuario = localStorage.getItem("idusuario");

            const url = `/pdv/pagamentosPassante/${pagamento.id}/${this.idcomanda}/${idusuario}`;

            await this.$http.delete(url)
            .then(res => {
                this.setPagamentosRetornoAPI(res.data)
            })
            .catch(err => {
                console.log(err.response.data)
            })
        },
        setValorFinalFechar(){

            if(this.cobrartaxas.id == 1){
              this.valorFecharComanda = this.pedido.valorTotal
            }else{
              this.valorFecharComanda = this.pedido.valorTotalSemTaxa
            }

        },
        somarPagtosAdicionados(){
            let retorno = 0
            for(let i = 0, len = this.listaPagamentos.length; i <len; i++){
                retorno += parseFloat(this.listaPagamentos[i].valorpagto)
            }

            if(parseFloat(retorno.toFixed(2)) > parseFloat(this.valorFecharComanda.toFixed(2))){
                this.$alertar("warning","Valor ficou maior do que o valor a pagar!","=(")
            }
            return retorno.toFixed(2)
        },
        adicionarPagtoArray(){
            const novoPagamento = {
                valorpagto:this.valorpagtonovo, 
                idtipo:this.tipoPagamentoSelecionado.value,
                nomepagamento:this.tipoPagamentoSelecionado.label,
                nsu:this.nsunovo,
            }

            const url = `/pdv/registrarPagamentosPassante`;

            const idusuario = localStorage.getItem("idusuario");

            const pagamentosDivididos = {
                idcomanda: this.idcomanda,
                listadepagamentos: [novoPagamento],
                idusuario,
                cobrartaxa: this.cobrartaxas.id,
                id_ultimo_usuario_atualizacao: idusuario,
            };

            this.$http.post(url, pagamentosDivididos)
            .then(res => {
                this.setPagamentosRetornoAPI(res.data.tipospagamentos)
            })
            .catch(err => {
                console.log(err)
            });
        },
        listarTiposPagamentos(){

            this.$http.post('/pdv/getTiposPagamentos').then(resp =>{
                this.listaTiposPagamentos = resp.data.tipospagamentos
            }).catch(error =>console.log('ERRO RETNORO ',error))
        },
        async getFluxoImpressao(){

            await this.$http.post('/pdv/buscarparametros', { chave:'FLUXOIMPRESSAO' }).then(async resp=>{



                            if(resp.data && resp.data.length>0){
                                this.fluxoImpressao = resp.data[0].valor
                                // alert( 'asa '+this.fluxoImpressao )
                            }

                        }).catch(() =>{

                            return {}

                        })

        },

        setDia(dia){
            if(dia === 'seg'){ this.seg = !this.seg}
            if(dia === 'ter'){ this.ter = !this.ter}
            if(dia === 'qua'){ this.qua = !this.qua}
            if(dia === 'qui'){ this.qui = !this.qui}
            if(dia === 'sex'){ this.sex = !this.sex}
            if(dia === 'sab'){ this.sab = !this.sab}
            if(dia === 'dom'){ this.dom = !this.dom}
        },
        removerDisponibilidade(horario){
            let indice = this.listaHorarios.indexOf(horario)
            if(indice > -1){
                this.listaHorarios.splice(indice,1)
            }
        },
        adicionarDisponibilidade(){
            this.listaHorarios.push({tipo:2,horainicial:'00:00:00',horafinal:'23:50:00'})
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        validarDados(obj){
            if(!obj.idpdv){
                this.$alertar("warning","PDV não Selecionado","=(")
                return false
            }

            if(!obj.nome){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }

            if(!obj.tempopreparo){
                this.$alertar("warning","Tempo de Preparo não Informado!","=(")
                return false
            }

            if(!obj.horainicio){
                this.$alertar("warning","Horário Inicial não Informado!","=(")
                return false
            }

            if(!obj.horafinal){
                this.$alertar("warning","Horário Final não Informado!","=(")
                return false
            }

            return true
        },
        criarCategoria(){

            let obj = {
                idpdv:this.idpdvcd,
                nome:this.categoria.nomecategoriaprodcd,
                tempopreparo:this.categoria.tempopreparo,
                horainicio:dayjs(this.categoria.horainicio).format('HH:mm:ss'),
                horafinal:dayjs(this.categoria.horafinal).format('HH:mm:ss'),
                flbebida:this.categoria.flbebida,
                liberarautomatico:this.categoria.liberarautomatico,
                listaHorarios:this.listaHorarios,
                seg:this.seg,
                ter:this.ter,
                qua:this.qua,
                qui:this.qui,
                sex:this.sex,
                sab:this.sab,
                dom:this.dom
            }

            if(this.impressora && this.impressora.id){
                obj.idimpressora = this.impressora.id
            }

            if(this.validarDados(obj)){
                this.$http.post(`pdv/categorias`,obj).then(() =>{
                    this.$alertar("success","Categoria Criada Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning","Não foi possivel atualizar a categoria","=(")
                    console.log(error)
                })
            }
        },
        async getPagamentosRegistrados () {

            this.$http.get(`/pdv/pagamentosPassante/${this.idcomanda}`)
            .then(async (res) => {
                setTimeout(() => {
                this.setPagamentosRetornoAPI(res.data)
                }, 300)
            })
            .catch(error => {
                console.log(error)
            });
        },
        setPagamentosRetornoAPI(pagamentos) {
            const pagamentosNOVO = pagamentos.map(pagamento => {
                const pagamentoNome = this.listaTiposPagamentos.find(tipo => tipo.value == pagamento.idtipopagamento).label;
                return {
                    valorpagto: pagamento.valor,
                    nomepagamento: pagamentoNome,
                    nsu: pagamento.nsu,
                    idtipo: pagamento.idtipopagamento,
                    id: pagamento.id,
                    action: 1
                }
            });

            this.limpaCamposInformacoesPagamentos();
            this.listaPagamentos = pagamentosNOVO;

            this.totalPagamentosAdicionados = this.somarPagtosAdicionados();
        },
        limpaCamposInformacoesPagamentos() {
            this.valorpagtonovo = null
            this.tipoPagamentoSelecionado = null
            this.nsunovo = null
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

