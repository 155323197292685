<template>
  <div>
    <v-dialog
      v-model="exibirModalLogs"
      width="1000"
      max-width="80vw"
      v-if="trocarLog"
    >
      <v-card style="max-width: 100%">
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width:95%;color:white;font-weight:bold;">
              Escolha o Log
            </span>
            <span style="width:5%;">
              <v-btn
                x-small
                color="red"
                @click="() => fecharModal()"
                title="Fechar"
              >
                <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>

        <v-card-text>
          <div
            style="width:100%;display:flex; justify-content: center;align-items: center"
          >
            <v-btn
              elevation=""
              style="margin-right:20px;color: white"
              color="blue"
              @click="() => trocarLogs(1)"
              >Logs Antes de Lançamento</v-btn
            >

            <v-btn
              elevation=""
              style="margin-right:20px;color: white"
              color="blue"
              @click="() => trocarLogs(2)"
              >Logs de Sucesso HITS</v-btn
            >

            <v-btn
              elevation=""
              style="margin-right:20px;color: white"
              color="blue"
              @click="() => trocarLogs(3)"
              >Logs de Erro HITS</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exibirModalLogs" width="1000" max-width="80vw" v-else>
      <v-card style="max-width: 100%">
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width:95%;color:white;font-weight:bold;">
              Logs
            </span>
            <v-btn
              small
              elevation=""
              style="margin-right:20px;"
              color=""
              @click="() => (trocarLog = true)"
              >Trocar Logs</v-btn
            >
            <span style="width:5%;">
              <v-btn
                x-small
                color="red"
                @click="() => fecharModal()"
                title="Fechar"
              >
                <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>

        <v-card-text>
          <v-card style="padding:10px;margin-bottom:10px;">
            <div style="width:100%;">
              <v-data-table v-if="items" :headers="headers" :items="items">
              </v-data-table>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalLogs",
  props: ["logs", "exibirModalLogs"],
  components: {},
  data: () => ({
    trocarLog: true,
    headers: [],
    items: [],
    antesLancamento: {
      headers: [
        { text: "Tipo", value: "nome" },
        { text: "Data", value: "date" },
        { text: "Objeto", value: "dados" },
        { text: "Headers", value: "headers" },
      ],
      items: [],
    },
    sucessoHITS: {
      headers: [
        { text: "Tipo", value: "nome" },
        { text: "Data", value: "date" },
        { text: "ID Folio", value: "idFolio" },
        { text: "ID Folio To", value: "idFolioTo" },
        { text: "Itens Folio", value: "folioItens" },
        { text: "Global Code", value: "globalCode" },
        { text: "Global Code To", value: "globalCodeTo" },
        { text: "Alerta", value: "alert" },
      ],
      items: [],
    },
    erroHITS: {
      headers: [
        { text: "Tipo", value: "nome" },
        { text: "Data", value: "date" },
        { text: "Resposta", value: "resposta" },
      ],
      items: [],
    },
  }),
  watch: {},
  methods: {
    fecharModal() {
      this.$emit("fechar");
    },
    trocarLogs(log) {
      this.items = [];
      this.headers = [];
      this.distribuiLogs();
      if (log === 1) {
        this.items = this.antesLancamento.items;
        this.headers = this.antesLancamento.headers;
      } else if (log === 2) {
        this.items = this.sucessoHITS.items;
        this.headers = this.sucessoHITS.headers;
      } else if (log === 3) {
        this.items = this.erroHITS.items;
        this.headers = this.erroHITS.headers;
      }
      this.trocarLog = false;
    },
    distribuiLogs() {
      this.antesLancamento.items = [];
      this.logs.antesLancamento.forEach((log) => {
        this.antesLancamento.items.push({
          ...log,
          dados: JSON.stringify(log.dados),
          headers: JSON.stringify(log.headers),
        });
      });

      this.sucessoHITS.items = [];
      this.logs.sucessoHITS.forEach(({ nome, resposta }) => {
        const retorno = {
          nome: nome,
          idFolio: resposta.idFolio,
          idFolioTo: resposta.idFolioTo,
          folioItens: JSON.stringify(resposta.folioItens),
          globalCode: resposta.globalCode,
          globalCodeTo: resposta.globalCodeTo,
          alert: resposta.AlertMessage,
        };
        this.sucessoHITS.items.push(retorno);
      });

      this.erroHITS.items = [];
      this.logs.erroHITS.forEach((log) => {
        this.erroHITS.items.push({
          ...log,
          resposta: JSON.stringify(log.resposta),
        });
      });
    },
  },
  mounted() {
    // this.distribuiLogs();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal {
  width: 100%;
  height: 50px;
  display: block;
  margin-bottom: 30px;
  border: 0px;
  font-weight: bold;
  color: white;
}

.botaoModalLocalAmarelo {
  background-color: rgb(239, 175, 62);
}
.botaoModalLocalVerde {
  background-color: rgb(0, 165, 51);
}
.botaoModalLocalAzul {
  background-color: rgb(63, 81, 181);
}
.botaoModalLocalVermelho {
  background-color: rgb(216, 37, 37);
}
.headerTitulo {
  font-weight: bold;
}
.numeroForm {
  height: 50px !important;
}

.inputsHorario {
  background-color: white;
  text-align: center;
  border-radius: 15px;
  min-height: 8vh;
  font-size: 25px;
  padding: 10px;
  border: 1px solid #819ab4;
  margin-right: 10px;
  width: 100px;
}
</style>
