<template>
  <div class="pagination">
    <button
      v-for="(page) in visiblePages"
      :key="page"
      :class="{ 'itemselecionado': pagina === page, 'item': pagina !== page }"
      @click="changePage(page - 1)"
      style="z-index: 1"
    >
      {{ page }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pagina: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    maxVisibleButtons: {
      type: Number,
      default: 5, // Define o número máximo de botões visíveis de cada vez
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    // Calcula os índices das páginas visíveis
    visiblePages() {
      const totalPages = this.pages;
      const currentPage = this.pagina;
      const maxButtons = this.maxVisibleButtons;

      let startPage = currentPage - Math.floor(maxButtons / 2);
      startPage = Math.max(1, startPage);
      const endPage = Math.min(startPage + maxButtons - 1, totalPages);

      const visiblePages = [];
      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }
      return visiblePages;
    },
  },
  methods: {
    changePage(index) {
      this.$emit('change-page', index);
    },
  },
};
</script>

<style lang="scss" scoped>
$light-grey: #cccccc;
$dark-grey: #333333;
$orange: rgb(71, 98, 255);
$dark-orange: rgb(21, 0, 138);

.itemselecionado{
    padding: 0.5rem 0.75rem;
    border: 1px solid $dark-grey;
    cursor: pointer;
    background-color: rgb(40, 47, 98);
    color:white;
}

.pagination {
  display: flex;
  justify-content: center;

  .item {
    padding: 0.5rem 0.75rem;
    border: 1px solid $light-grey;
    cursor: pointer;
    background-color: white;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background-color: $light-grey;
      border-color: lighten($dark-grey, 50%);
      z-index: 3;
    }

    &.current {
      cursor: default;
      color: white;
      background-color: $orange;
      border-color: $dark-orange;
      z-index: 2;
    }

    + .item {
      margin-left: -1px;
      margin-right: 0;
    }
  }
}
</style>