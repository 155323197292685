<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <div class="categoryList">
      <Categoria v-for="categoria in listadecategorias" :key="categoria.idcategoriaprod" 
        :categoria="categoria" :idpdv="idpdv"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import Categoria from './Categoria.vue'
  export default {
    name: 'CategoriaLista',
    props:['idpdv'],
    components:{
      // TitleH1,
      Categoria
    },
    data: () => ({
      listadecategorias:[]
    }),

    methods:{

      getCategorias(){
          this.$http(`pdv/getCategorias/${this.idpdv}/0/hospede`).then((resp) =>{
            this.listadecategorias = resp.data
          }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
          })
      },

    },

    mounted(){
      this.getCategorias()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
    }
</style>
