<template>
    <div class="cc-principal">
        <!-- Cartão -->
        <div class="">
            <div style="display:flex;justify-content:center;text-align:center;margin-top:20px;margin-bottom:10px;">
                <div style="width:70%;">
                    Tira uma foto de um documento com foto para ser usado no Reconhecimento Facial da Próxima Etapa.
                </div>
            </div>

            <div class="cardInputs" style="display:flex;justify-content:center;align-items:center;margin-bottom:10px">
                <img v-if="imgSrc" :src="'data:image.png;base64,' + imgSrc" class="imgUpload" />
            </div>

            <input id="file-upload" type="file" data-cy="btn-uploadfrente" style="display: none" accept="image/*" @change="onFileChange"/>
            <div style="display:flex;justify-content:center;">
                <label for="file-upload" style="background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                    <span data-cy="span-cliqueaqui" style="margin-right:10px;font-size:18px;font-weight:bold;color:white;">Capturar Imagem Doc</span>
                    <i style="color:white!important;" class="fa fa-search"></i>
                </label>
            </div>


            <div style="display:flex;justify-content:center;margin-top:20px;" @click="avancar()" v-if="imgSrc">
                <label for="file-upload" style="background-color:#15a6e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                    <span data-cy="span-cliqueaqui" style="margin-right:10px;font-size:18px;font-weight:bold;color:white;">Avançar</span>
                    <i style="color:white!important;" class="fa fa-search"></i>
                </label>
            </div>




        </div>
        <!-- Dados -->

        <!-- <div class="cc-center">Cartão de {{tipoPagto == 1?'Crédito':'Débito'}}</div> -->
        <div class="cc-form">

        </div>
        <!-- Botão Pagar -->

        <!-- Modal de confirmação -->
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600" v-model="confirmMessage"
        >
            <template v-slot:default="confirmMessage">
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;padding:10px;">
                            <img src="@/assets/img/confirmarcartao.svg"
                                width="150" height="auto"
                                alt="Confirmando..." crossorigin />
                                <br>
                            <div class="contest-message">Confirma os dados do seu cartão?
                                <br>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-spacer />
                        <!-- Confirmar (Sim) -->
                        <button class="sim-btn"
                            v-on:click="registrarPagamento"
                        >
                            <img src="@/assets/img/sim.svg"
                                width="25" height="auto"
                                alt="Confirmar..." crossorigin />
                            <span class="px-2">Sim</span>
                        </button>
                        <v-spacer />
                        <!-- Cancelar (Não) -->
                        <button class="nao-btn"
                            v-on:click="confirmMessage.value = false"
                        >
                            <img src="@/assets/img/nao.svg"
                                width="25" height="auto"
                                alt="Cancelar..." crossorigin />
                            <span class="px-2">Não</span>
                        </button>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>


import {serializarImagem} from '../../services/serializar'

export default {
    name: 'FOTO DOC',
    props:["valorapagar"],
    data: () =>{
        return{
            nometitular: '',
            numerocartao: '',
            mmaa: '',
            cvccvv: '',
            bandeira: 'VISA',
            confirmMessage: false,
            tipoPagto:localStorage.getItem('tipoPgto'),
            parcelas:1,
            parcelasMaxima:1,
            imgSrc:null,
            loading:false,
        }
    },
    components: {

	},
    computed: {
        validInput() {
            return (
                typeof(this.validarNome()) == 'boolean'
                && typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        }
    },
    methods: {
        avancar(){
            this.$router.push('/Hospede/Selfie')
        },
        async onFileChange(e) {

            console.log(e)
            this.loading =  true
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.$store.dispatch("set_imgdocfrente" , this.imgSrc);
                this.$store.dispatch("set_tipodocfrente", resp.tipo);
                // this.salvaFotoFrente(resp.tipo);

                let self = this
                setTimeout(() => {
                    self.$router.push('/Hospede/Selfie')
                }, 1000);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error"
                this.snackText = error.message
            })

        },
        async salvaFotoFrente(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }

            let dados = {
                "tipo" : tipoImg,
                "nome" : "DocumentoFoto",
                "dados" : this.imgSrc,
                "tabela": "wout_pagamento",
                "fk": "docfrente",
                "chave": "id",
                "valor": this.dados.idhospede
            };
            await this.$http.post("/imagem/upinsert", dados, { timeout: 60000 })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("Foto do Usuario Salvo com Sucesso!");
                    } else {
                        console.log("Não foi possivel salvar a Foto do Usuario!");
                    }
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error.response);
                });

        },
        confirmar() {
            if(localStorage.getItem('tipoPgto') == 1){
                if(this.parcelas>0 && this.parcelasMaxima >= this.parcelas){
                    this.confirmMessage = true
                }else{
                    alert("A Quantidade de Parcela deve está entre 1 e "+this.parcelasMaxima+"!");
                }
            }else{
                this.confirmMessage = true
            }


        },

        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
    },
    mounted(){
        this.imgSrc = this.$store.getters.get_imgdocfrente
    }
}
</script>

<style lang="scss" scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;
  }
}

.cc-label{
  font-size: 14px;
  text-transform: capitalize;
}

.credit-card-brand{
  width: 4em;
  height: auto;
}

.cc-cartao {
  width: 100%;
  margin: auto;
  padding: 1em;
  display: grid;
  grid-template-rows: .2fr 1fr .2fr;
  grid-template-columns: 1fr;
  border-radius: 1em;
  height: 12em;
  color: #666;
  margin-bottom: 1em;

  background: rgb(25,118,210);
  background: -moz-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: -webkit-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1976d2",endColorstr="#00d4ff",GradientType=1);

  color: #f9f9f2;
  font-weight: bold;

  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
//   backdrop-filter: blur(2px);

  .cc-cartao_corpo{
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
  .cc-cartao_rodape{
    z-index: 3;
    display: flex;
    justify-content: flex-end;
  }
}
.cc-pagar-btn {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: #1976d2!important;
  color: white;
  padding: 1.5vh;
  line-height: 1.5;
  border-radius: 1em;
  width: 100%;
  height: 3em;
  display: flex;
}
.cc-pagar-btn:disabled {
  background-color: #80808020 !important;
  .cc-pagar-btn_texto{
    color: #BABAB5 !important;
  }
}



.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}



//css vuetify

.v-text-field--rounded {
  border-radius: 1em !important;
}


.v-application p {
  margin-bottom: unset !important;
}

.v-text-field__details {
    margin-bottom: 3px !important;
}

.imgUpload{
      width:400px;
      max-width:90vw;
      margin-top:20px;
}
</style>
