<template>
    <div>
        <div style="margin-bottom: 1em;">
            <v-card  elevation="2" outlined>
                
                <v-card-text>

                    <v-row class="d-flex align-center">
                        <v-col cols="12" lg="2" md="4" sm="6">
    
                            <v-text-field v-model="filtroNome"
                                label="Nome Hospede"
                                append-icon="mdi-account"
                                hide-details
                                dense
                            >
                            </v-text-field>

                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6">
                            <!-- <v-text-field
                                label="Número Reserva"
                                append-icon="mdi-account-details"
                                hide-details
                                dense
                            ></v-text-field> -->

                            <v-text-field 
                                v-model="filtroReserva"
                                label="Número Reserva"
                                append-icon="mdi-account-details"
                                hide-details
                                dense
                                ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6">
                            <!-- <v-select
                            :items="[]"
                            append-outer-icon="mdi-list-status"
                            menu-props="auto"
                            hide-details
                            label="Status Reserva"
                            single-line
                            dense
                            ></v-select> -->
                            <v-select
                                v-model="filtroStatusPagto"
                                :items="listadestatus"
                                item-value="id"
                                item-text="nome"
                                append-outer-icon="mdi-list-status"
                                menu-props="auto"
                                hide-details
                                label="Status Reserva"
                                single-line
                                dense
                                
                            >
                            </v-select>
                        </v-col>


                        <v-col cols="12" lg="2" md="4" sm="6">
                            <!-- <v-text-field
                                label="Data Check-in"
                                append-icon="mdi-sort-calendar-ascending"
                                hide-details
                                dense
                            ></v-text-field> -->
                            <v-text-field 
                                type="date"
                                v-model="filtroDataInicial"
                                label="Data Check-in"
                                hide-details
                                dense
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" lg="2" md="4" sm="6">
                            <v-text-field 
                                type="date"
                                v-model="filtroDataFinal"
                                label="Data Check-in"
                                hide-details
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="2" md="4" sm="6" class="d-flex justify-space-between flex-wrap">
                          <v-btn color="#1976d2" dark @click="loadPagamentos()">
                                Buscar
                                <v-icon right>
                                    mdi-magnify
                                </v-icon>
                          </v-btn>
                          <v-btn color="#1976d2" text @click="limparFiltro()" > 
                                Limpar
                                <v-icon right>
                                    mdi-autorenew
                                </v-icon>
                          </v-btn>
                        </v-col>

                    
                    </v-row>

                            
                </v-card-text>

            </v-card>
        </div>
        <div>
            <v-card  elevation="2" outlined>
                
                <v-card-text>
                    <!-- <v-data-table
                        :headers="headers"
                        :items="pagamentos"
                        :items-per-page="10"
                        class="elevation-1"
                    > 
                        <template v-slot:[`item.nomehospede`]="{ item }">
                            <p style="font-weight: bold">{{item.nomehospede}}</p>                    
                        </template>

                        <template v-slot:[`item.numreserva`]="{ item }">
                            <p style="font-weight: bold">{{item.numreserva}}</p>                    
                        </template>     

                        <template v-slot:[`item.datacheckinprev`]="{ item }">
                            <p style="font-weight: bold">{{item.datacheckinprev}}</p>                    
                        </template>

                        <template v-slot:[`item.datacheckoutprev`]="{ item }">
                            <p style="font-weight: bold">{{item.datacheckoutprev}}</p>                    
                        </template>

                        <template v-slot:[`item.valor`]="{ item }">
                            <p style="font-weight: bold">{{item.valor}}</p>                    
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                                <v-btn 
                                    style="margin: .5em;"
                                    elevation="2"
                                    raised
                                    small
                                    tile
                                    @click="verItens(item)"
                                >
                                    <v-icon left color="purple" >
                                        mdi-format-list-bulleted
                                    </v-icon>
                                    <p style="color: purple"> itens</p>
                                </v-btn>
                                <v-btn 
                                    style="margin: .5em;"
                                    elevation="2"
                                    raised
                                    small
                                    tile
                                >
                                    <v-icon left color="green">
                                        mdi-check
                                    </v-icon>
                                    <p style="color: green">baixar</p>
                                </v-btn>
                                <v-btn 
                                    style="margin: .5em;"
                                    elevation="2"
                                    raised
                                    small
                                    tile
                                >
                                    <v-icon left color="#1e90ff">
                                        mdi-playlist-check
                                    </v-icon>
                                    <p style="color: #1e90ff">historico</p>
                                </v-btn>
                                <v-btn 
                                    style="margin: .5em;"
                                    elevation="2"
                                    raised
                                    small
                                    tile
                                >
                                    <v-icon left color="red">
                                        mdi-close
                                    </v-icon>
                                    <p style="color: red">estornar</p>
                                </v-btn>                            
                        </template>
                    
                    </v-data-table>   -->


                    <v-data-table
                            :headers="headers"
                            :items="pagamentos"
                            :footer-props="footerProps"
                            :search="search"
                            class="elevation-1"
                        >
                            <template v-slot:[`footer.page-text`]="items"> 
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                            </template>
                            <template v-slot:[`item.datacheckinprevisto`]="{ item }">
                            {{item.datacheckinprevisto | formatarData}}
                            </template>
                            <template v-slot:[`item.datacheckoutprevisto`]="{ item }">
                            {{item.datacheckoutprevisto | formatarData}}
                            </template>
                            <template v-slot:[`item.datacriacao`]="{ item }">
                                {{item.datacriacao | formatarData}}
                            </template>
                            <template v-slot:[`item.valor`]="{ item }">
                            <div class="item-value">{{item.valor | formatarMoeda}}</div>
                            </template>
                            <template v-slot:[`item.valoraberto`]="{ item }">
                            <div class="item-value">{{item.valoraberto | formatarMoeda}}</div>
                            </template>
                            <template v-slot:[`item.valorpago`]="{ item }">
                            <div class="item-value">{{item.valorpago | formatarMoeda}}</div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                            <div class="item-group">
                                <div class="item-action" title="Itens de consumo."
                                @click="carregarItens(item)">
                                <v-icon small color="purple" class="mx-1 ponteiro">
                                    mdi-format-list-bulleted</v-icon>Itens
                                </div>
                                <div class="item-action" title="Confirmar pagamento."
                                @click="confirmarBaixa(item)">
                                <v-icon small color="green" class="mx-1">
                                    mdi-check</v-icon>Baixar
                                </div>
                                <!-- <div class="item-action" title="Histórico de pagamentos."
                                @click="carregarHistorico(item)">
                                <v-icon small color="blue" class="mx-1">
                                    mdi-playlist-check</v-icon>Histórico
                                </div> -->
                                <div class="item-action" title="Estornar pagamento."
                                @click="confirmarEstorno(item)">
                                <v-icon small color="red" class="mx-1">
                                    mdi-close</v-icon>Estornar
                                </div>
                            </div>
                            </template>
                        </v-data-table>
                        
                </v-card-text>

            </v-card>
        </div>    

        <ModalItensPagamento 
            :modalaberto="veritens" 
            :itens="itens" 
            @fechar="() => {this.itens = []; this.veritens=false}"/>

<!-- Ítens do Pagamento -->
          <pagamentos-itens :items="itens" :show="mostrarItens" @closeitems="fecharItens" />
          <!-- Histórico do Pagamento -->
          <pagamentos-historico :items="historico" :show="mostrarHistorico" @closeitems="fecharHistorico" />
          <!-- Confirmar Baixa de Pagamento -->
          <pagamentos-confirmar :show="mostrarBaixa" 
            :titulo="'Confirmar baixa do pagamento'"
            :texto="txtBaixaPagamento"
            @confirmar="baixarPagamento" @cancelar="mostrarBaixa=false" />
          <!-- Confirmar Estorno de Pagamento -->
          <pagamentos-confirmar :show="mostrarEstorno" 
            :titulo="'Confirmar estorno do pagamento'"
            :texto="'Clique em confirmar para estornar este pagamento.'"
            @confirmar="estornarPagamento" @cancelar="mostrarEstorno=false" />
        <!-- <ModalHistoricoPagamento 
            :modalaberto="verhistorico" 
            :historico="historico" 
            @fechar="() => {this.historico = []; this.verhistorico=false}"/> -->

    </div>    
</template>

<script>
import ModalItensPagamento from '@/components/layout/Modais/ModalItensPagamento'
import dayjs from 'dayjs';
import PagamentosItens from './PagamentosItens.vue';
import PagamentosHistorico from './PagamentosHistorico.vue';
import PagamentosConfirmar from './PagamentosConfirmar.vue';
// import { BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS } from '../../constantes.js'
export default {
//pode haver duas alternativas, o objeto recebido ao executar a funcao this.requisitarPagamentos já trazer os itens do pagamento e o seu historico ou 
//a cada clique nas acoes itens e historio um requisicao será feita
    components: {ModalItensPagamento,
     PagamentosItens, PagamentosHistorico, PagamentosConfirmar},
    data(){
        return {
            listadestatus:[
                {id:1,nome:'Iniciado'},
                {id:2,nome:'Não Concluido'},
                {id:3,nome:'Finalizado'},
                {id:4,nome:'Baixado'},
                {id:5,nome:'Estornado'}
            ],
            txtBaixaPagamento:'Clique em confirmar para baixar este pagamento.',
            filtroStatusPagto:3,
            filtroReserva: null,
            filtroNome: null,
            filtroSobrenome: null,
            filtroDataInicial: null,
            filtroDataFinal: null,
            snack: false,
            snackColor: "",
            snackText: "",
            pagamentos: [],
            footerProps: {
            'items-per-page-options': [15,20,25,{ text: "Todas", value: -1 }],
            'items-per-page-text': 'Linhas por página',
            },
            dialog: false,
            search: "",
            headers: [
            { text: "Nome", value: "nome" },
            // { text: "Sobrenome", value: "sobrenome" },
            { text: "Reserva", value: "numeroreserva" },
            { text: "Checkin", value: "datacheckinprevisto" },
            { text: "Checkout", value: "datacheckoutprevisto" },
            { text: "ID Pagto", value: "id" },
            { text: "Dia Pagto", value: "datacriacao" },
            { text: "Valor", value: "valor", align: 'end' },
            { text: "Tipo", value: "tipotransacao", align: 'end' },
            { text: "Parcelas", value: "qtdeparcelas", align: 'end' },
            { text: "Gateway", value: "nomegateway", align: 'end' },
            // { text: "Valor em Aberto", value: "valoraberto", align: 'end' },
            // { text: "Pagamento", value: "valorpago", align: 'end' },      
            { text: "Ações", value: "actions", sortable: false, align: 'center' ,width: '320px'}
            ],
            editedIndex: -1,
            editedItem: {},
            textoBtnPequisar: 'Pesquisar',
            itens: [],
            mostrarItens: false,
            historico: [],
            mostrarHistorico: false,
            mostrarBaixa: false,
            itemABaixar: {},
            mostrarEstorno: false,
            itemAEstornar: {}
        }
    },
    methods: {
    //   requisitarPagamentos(){
    //     this.pagamentos = [
    //         {
    //             nomehospede: 'Italo',
    //             numreserva: '22233',
    //             datacheckinprev: '21/02/2022',
    //             datacheckoutprev: '22/02/2022',
    //             valor: '303,28',
    //             itens: [
    //                 {
    //                     codprod: '123',
    //                     descricao: 'Sopa de Galinha',
    //                     data: '21/02/2022',
    //                     preco: 7.50,
    //                     quant: 2,
    //                 }
    //             ]
    //         }, 
    //         {
    //             nomehospede: 'Herlessi',
    //             numreserva: '33226',
    //             datacheckinprev: '23/02/2022',
    //             datacheckoutprev: '25/02/2022',
    //             valor: '208,83',
    //             itens: [
    //                 {
    //                     codprod: '123',
    //                     descricao: 'Sopa de Galinha',
    //                     data: '21/02/2022',
    //                     preco: 7.50,
    //                     quant: 2,
    //                 }
    //             ]
    //         },
    //         {
    //             nomehospede: 'Karynne',
    //             numreserva: '77886',
    //             datacheckinprev: '21/02/2022',
    //             datacheckoutprev: '28/02/2022',
    //             valor: '407,35',
    //             itens: [
    //                 {
    //                     codprod: '123',
    //                     descricao: 'Sopa de Galinha',
    //                     data: '21/02/2022',
    //                     preco: 7.50,
    //                     quant: 2,
    //                 }
    //             ]
    //         }              
    //     ]
    //   },
    //   verItens(pagamento){
    //     this.veritens = true
    //     this.itens = pagamento.itens
    //   },
    //   verHistorico(pagamento){
    //     this.verhistorico = true
    //     this.historico = pagamento.historico
    //   }      
    
        carregarItens(item) {
            var params = {idpagamento: item.id}
            this.$http.post("/pagamento/getItensPagamentos", params)
            .then((response) => {
                if (response.status == 200) {
                    
                    this.itens = response.data
                    this.mostrarItens = true
                } else {
                   
                    console.error(response)
                }
            })
            .catch((error) => {         
                console.log(error)
            });
        },
        fecharItens() {
            this.itens = []
            this.mostrarItens = false
        },
        carregarHistorico(item) {
            var params = {idpagamento: item.id}
            this.$http.post("/webcheckout/getDetalhePagamentos", params)
            .then((response) => {
                if (response.status == 200) {
                this.historico = response.data
                this.mostrarHistorico = true
                } else {
                console.error(response)
                }
            })
            .catch((error) => {          
                console.log(error)
            });
        },    
        fecharHistorico() {
            this.historico = []
            this.mostrarHistorico = false
        },
        confirmarBaixa(item) {
            this.itemABaixar = item
            this.mostrarBaixa = true
        },
        robo_enviarPagtoPms(){
        
                this.$socket.disconnect();
                this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
                this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
                this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('useremail')
                this.$socket.connect();

                console.log("this.itemABaixar")
                console.log(this.itemABaixar)
                this.$socket.emit("webckout_back_robo_enviarpagtoaopms", {
                    idhotel:localStorage.getItem('hotel'),
                    numeroreserva:this.itemABaixar.numeroreserva,
                    usuario:localStorage.getItem('useremail'),
                    idreservahospede:this.itemABaixar.idreservahospedepagante,
                    idpagamentogateway:this.itemABaixar.idpagamentogateway,
                    valorpago:this.itemABaixar.valorpago,
                    bandeira:this.itemABaixar.bandeira,
                });
                this.sockets.subscribe("fecharModalBaixando", (dados) => {
                    this.mostrarBaixa = false
                    this.sockets.unsubscribe("fecharModalBaixando")
                    console.log(dados.msg)
                    //   this.$emit('direcionar', {to:'inicio', from:'inicio'})
                    // this.direcionar({to:'inicio', from:'inicio'})
                });
        },
        baixarPagamento() {
            
            this.txtBaixaPagamento = 'Realizando Baixa...'
            var params = {
                "idpagamento": this.itemABaixar.id
            }
            
            this.$http.post("/pagamento/baixarPagamento", params)
            .then((response) => {

                if (response.status == 200) {
    
                    if(this.$dados.BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS == "S"){
                        
                        this.txtBaixaPagamento = 'Enviando Pagamento para o PMS...'
                        this.robo_enviarPagtoPms()
                        
                        // this.snackText = 'Pagamento baixado.'
                        // this.snackColor = 'green'
                        // this.snack = true
                        // this.loadPagamentos()
                    }else{
     
                        this.snackText = 'Pagamento baixado.'
                        this.snackColor = 'green'
                        this.snack = true
                        this.loadPagamentos()
                        this.mostrarBaixa = false
                    }
                } else {
     
                    console.error(response)
                }
                // this.mostrarBaixa = false
            })
            .catch((error) => {          
                console.log(error)
                this.mostrarBaixa = false
            });
        },
        confirmarEstorno(item) {
            this.itemAEstornar = item
            this.mostrarEstorno = true
        },    
        estornarPagamento() {
            var params = {
                idhotel: this.itemAEstornar.idhotel,
                idpagamento: this.itemAEstornar.id
            }

            this.$http.post("/pagamento/estornarPagamento", params)
            .then((response) => {
                
                if (response.status == 200) {
                    this.snackText = 'Pagamento estornado.'
                    this.snackColor = 'green'
                    this.snack = true
                    this.loadPagamentos()
                } else {
                    console.error(response)
                }
                this.mostrarEstorno = false
            })
            .catch((error) => {          
                alert(error)
                this.mostrarEstorno = false
            });
        },    
        limparFiltro() {
            this.filtroReserva = null
            this.filtroNome = null
            this.filtroSobrenome = null
            this.filtroDataInicial = null
            this.filtroDataFinal = null
        },
        loadPagamentos() {
            
            this.textoBtnPequisar = "Pesquisando..."

            if(!this.filtroDataInicial && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
                this.filtroDataInicial = dayjs().format('YYYY-MM-DD')
            }
            if(!this.filtroDataFinal && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
                this.filtroDataFinal = dayjs().format('YYYY-MM-DD')
            }
            
            
            var params = {
                datacheckoutinicial: this.filtroDataInicial,
                datacheckoutfinal: this.filtroDataFinal,
                statuspagamento: this.filtroStatusPagto,
                nome: this.filtroNome,
                // sobrenome: this.filtroSobrenome,
                numeroreserva: this.filtroReserva
            }
            

            try {

                this.$http.post("/pagamento/getPagamentos",params)
                .then((response) => {
                    if (response.status == 200) {
                        // TODO: substituir o array por response.data...
                        this.pagamentos = response.data
                    }
                })
                .catch((error) => {          
                    alert(error)
                    console.log(error)
                });
            } finally {
                this.textoBtnPequisar = "Pesquisar"
                // this.pagamentos = [
                //   {
                //     nome: 'Fulano de tal',
                //     reserva: '32145654',
                //     datacheckinprevisto: '2021-10-01 10:00',
                //     datacheckoutprevisto: '2021-10-07 16:00',
                //     valortotal: '120.50',
                //     valoremaberto: '11227.1',
                //     pagamento: '.59'
                //   },
                //   {
                //     nome: 'Ciclano de tal',
                //     reserva: '34446754',
                //     datacheckinprevisto: '2021-10-02 12:00',
                //     datacheckoutprevisto: '2021-10-09 16:00',
                //     valortotal: '1212420',
                //     valoremaberto: '0',
                //     pagamento: '2020'
                //   },
                //   {
                //     nome: 'Beltrano de tal',
                //     reserva: '34111759',
                //     datacheckinprevisto: '2021-10-06 12:00',
                //     datacheckoutprevisto: '2021-10-12 16:00',
                //     valortotal: '3224.20',
                //     valoremaberto: '1004.29',
                //     pagamento: '2024'
                //   },
                // ];
            }
        },
        focus(id) {
            var idEl = document.getElementById(id)
            if (idEl) idEl.focus()
        },
        deleteItem(item) {
            if (confirm("Are you sure you want to delete this item?")) {
                this.editedItem = Object.assign({}, item);
            }
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        fecharAtualizacao() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        saveInline() {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Registro salvo com Sucesso!";
        },
        cancelInline() {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Operação Cancelada";
        },
        reset() {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data reset to default";
        },
        openInline() {
            this.snack = true;
            this.snackColor = "info";
            this.snackText = "Dialog opened";
        },
        errorSave() {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Erro ao salvar usuário";
        },
        closeInline() {
            console.log("Dialog closed");
        },
    
    },
    mounted(){
        // this.$store.dispatch("set_titulo", "Pagamentos")
        // this.requisitarPagamentos()
        this.loadPagamentos();
    }
}
</script>


<style lang="scss" scoped>


.item-group{
  display: flex;
  flex-direction: row;
}
.item-action{
  cursor: pointer;
  font-size: 0.8rem!important;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.item-value{
  font-size: 0.8rem!important;
}

</style>