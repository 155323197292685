<template>
  <div class="ml-3 subitulo" :style="'color:'+(corTexto?corTexto:'black')">{{subtitulo}}</div>
</template>

<script>

  export default {
    name: 'SubTitulo',
    props:["subtitulo","corTexto"],
    mounted(){
      //todo
    },
    methods:{
       //todo
    }
  }
</script>

<style scoped>
    .subitulo{
        max-width: 80%;
        word-break: break-all;
        font-weight: 900!important;
    }
</style>
