<template>
    <div class="bodygenerico">
        <CarrosselImgs :items="items" v-if="exibirpublicidade" />
        <div class="tituloBemVindo">
            {{ $t('bemvindo.ola') }} {{nome}},  {{ $t('bemvindo.texto') }} <b>{{nomehotel}}</b>!
            <!-- {{ $t('bemvindo.texto.tipodocumento') }} -->
        </div>

        <div class="linha">
            <div class="linhaa" ><b> {{ $t('bemvindo.reserva') }}:</b></div>
            <div>{{numreserva}}</div>
        </div>
        <div class="linha">
            <div class="linhaa" ><b> {{ $t('bemvindo.apartamento') }}:</b></div>
            <div>{{coduh}}</div>
        </div>

        <div class="linha">
            <div class="linhaa" ><b>  {{ $t('bemvindo.periodo') }}:</b></div>
            <div>{{datacheckin | formatarDataCompacta}} a {{datacheckoutprev | formatarDataCompacta}}</div>
        </div>


        <v-btn large color="info" class="white--text font-weight-bold botaocontinuar" v-on:click="continuar()" >
            {{ $t('bemvindo.continuar') }}
            <v-icon right dark >mdi-login</v-icon>
        </v-btn>
     
    </div>
</template>

<script>

import CarrosselImgs from '../../components/layout/CarrosselImgs.vue'

export default {
  name: 'App',
  components:{ 
    CarrosselImgs
  },
  data: () => ({
    nome:localStorage.getItem('nome'),
    nomehotel:localStorage.getItem('nomehotel'),
    numreserva:localStorage.getItem('numreserva'),
    datacheckin:localStorage.getItem('datacheckin'),
    datacheckoutprev:localStorage.getItem('datacheckoutprev'),
    coduh:localStorage.getItem('coduh'),
    items:[],
    exibirpublicidade:true,
  }),
  methods:{
      continuar(){
          
          this.$store.dispatch("set_titulo",this.$t('titulocardapio.texto'))
          this.$router.push('/Hospede/Menu')
      }
  },
  mounted(){

    this.$http.post('pdv/buscarparametros',{chave:'EXIBIRPUBLICIDADE'})
    .then(resp =>{
      
        this.exibirpublicidade = resp.data[0].valor === 'true'? true : false

    }).catch(error =>{
        alert(error)
    })


    this.$http.post('pdv/buscarparametros',{chave:'IMAGENSPUBLICIDADE'})
    .then(resp =>{

        this.items= [
                      {src:'data:image/jpeg;base64,'+resp.data[0].valor,  },
                      {src:'data:image/jpeg;base64,'+resp.data[0].valor2,  },
                      {src:'data:image/jpeg;base64,'+resp.data[0].valor3,  },
                    
                    ]

    }).catch(error =>{
        alert(error)
    })

 
  }
};
</script>


<style scoped>
    .tituloBemVindo{
        margin-top: 50px;
        font-size: 20px;
        width: 80%;
        text-align: center;
        margin-bottom: 20px;
    }

    .linha{
        display: flex;
        width: 80%;
        max-width: 400px;
    }

    .linhaa{
        width: 40%;
    }

    .botaocontinuar{
        margin-top:30px;
    }
</style>
