<template>
      <v-card-text id="content" class="content-boas-vindas">
          <div class="content-container-boas-vindas">
              <div class="infos">
                  <h1>Informacoes</h1>

                  <div class="info-container">
                      <v-flex xs12>
                          <input id="file-upload2" data-cy="file-upload2" type="file" style="display:none;" accept="image/*" @change="onFileChange" />
                          <v-avatar slot="offset" class="mx-auto d-block" size="130">
                              <v-icon v-if="!this.configsBoasVindas.img" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                              <img v-if="this.configsBoasVindas.img" :src="this.configsBoasVindas.img" class="imgUpload" />
                          </v-avatar>
                          <div class="upload-button-container">
                              <label for="file-upload2">
                                  <div class="upload-button">
                                      Alterar
                                  </div>
                              </label>
                          </div>
                      </v-flex>
                      <div class="text-fields">
                          <p>Titulo</p>
                          <v-text-field v-model="configsBoasVindas.titulo" outlined dense placeholder="Titulo"></v-text-field>

                          <p>Descricao</p>
                          <v-textarea  v-model="configsBoasVindas.descricao" outlined dense placeholder="Descricao"></v-textarea>
                      </div>
                  </div>
              </div>
              <div class="preview">
                  <h1>Pre Vizualização</h1>
                  <div class="preview-container">
                      <img :src="configsBoasVindas.img" class="img-preview" alt="Imagem de Boas Vindas" />
                      <h2 class="title">{{configsBoasVindas.titulo}}</h2>
                      <p class="description">{{configsBoasVindas.descricao}}</p>
                  </div>
              </div>
          </div>


          <div class="save-button-container">
              <v-btn class="save-button" color="primary" @click="salvarBoasVindas" data-cy="salvar">
                  Salvar
              </v-btn>
          </div>
      </v-card-text>
</template>

<script>
  import {serializarImagem} from '../../../services/serializar'

  export default {
    name: 'BoasVindaConfiguracoes',
    components: {},
    props:[],
    data: () => ({
        configsBoasVindas: {
            titulo: "",
            descricao: "",
            img: "",

            imgTrocada: false,
        }
    }),
    async mounted(){
      await this.buscarDados()
    },
    methods:{
        salvarBoasVindas(){
            const valor = JSON.stringify({
                titulo: this.configsBoasVindas.titulo,
                descricao: this.configsBoasVindas.descricao,
            }, null, 2)

            const valor2 = this.configsBoasVindas.img;

            const parametro = {
                chave: 'CONFIGS_BOAS_VINDAS_HOSPEDES',
                valor,
            }

            if(this.configsBoasVindas.imgTrocada) {
                parametro.valor2 = valor2
            }

            this.$http.put('pdv/updateparametros', parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
        },
        onFileChange(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.configsBoasVindas.imgTrocada = true
                this.configsBoasVindas.img = `data:image/jpeg;base64, ${resp.dados}`
            }).catch(() => {
            })
        },
        async buscarDados(){
            await this.$http('hospedes/informacoesBoasVindas/true').then(res => {
                this.configsBoasVindas.titulo = res.data.titulo
                this.configsBoasVindas.descricao = res.data.descricao
                this.configsBoasVindas.img = res.data.img
            })
            .catch(err => {
                console.log(err)
            })

        }
    }
  }
</script>

<style src="./boas-vindas.css" scoped></style>