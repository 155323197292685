<template>
    <div style="height:100%;display:flex;">
      <CardapioPedidoTuor @add="adicionarProdutoCarrinho"/>
      <CardapioCarrinhoTuor
        @remove="remove"
        @getProdutosNoCarrinho="getProdutosNoCarrinho"
        :produtos="carrinho"
        :hospede="hospede"
        :tipointegracao="tipointegracao"
        :feitoPorFecharComanda="feitoPorFecharComanda"
      />

      <v-tour name="fazerPedido" :steps="steps" :callbacks="callbacksTuor"></v-tour>

<!--      <ModalOpcionaisEscolha-->
<!--        :exibirModal="exibirModalOpcionais"-->
<!--        :produto="produtoSelecionado"-->

<!--        @fecharModal="fecharModal"-->
<!--        @setOpcoesSelecionadas="setOpcoesSelecionadas"-->
<!--        @add="add"-->
<!--      />-->
    </div>
</template>

<script>

import CardapioPedidoTuor from '../../components/cardapio/CardapioPedidoTuor.vue'
import CardapioCarrinhoTuor from '../../components/cardapio/CardapioCarrinhoTuor.vue'
import router from "@/router";
// import ModalOpcionaisEscolha from '../../components/layout/Modais/ModalOpcionaisEscolha.vue'

export default {
  name: 'ViewHospedes',
  components:{
    CardapioPedidoTuor,
    CardapioCarrinhoTuor,
    // ModalOpcionaisEscolha
},
  data: function() {

    return  {
      carrinho: [],
      valorTotal: 0,
      idcomanda: null,
      dadosComanda: null,
      idreservasfront: null,
      idcheckin:null,
      hospede:null,
      tipointegracao:null,
      feitoPorFecharComanda: false,

      exibirModalOpcionais: false,
      produtoSelecionado: null,


      steps: [
        {
          target: '[data-v-step-fazer-pedido="1"]',
          content: 'Você consegue filtrar todos por pdv, categoria, subcategoria e nome do produto',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="2"]',
          content: 'Aqui estarão todos produtos disponíveis para adicionar no carrinho',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="3"]',
          content: 'Clicando no produto você consegue adicionar no carrinho',
          params: {
            placement: 'right'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="4"]',
          content: 'É possivel adicionar uma observação ao item',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="5"]',
          content: 'É possível também adicionar um desconto fixo ou percentual por produto',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="6"]',
          content: 'Aqui você vizualiza todos os adicionais escolhidos pelo cliente',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="7"]',
          content: 'Clicando aqui você consegue remover o item do carrinho',
          params: {
            placement: 'left'
          }
        },
        {
          target: '[data-v-step-fazer-pedido="8"]',
          content: 'Clicando aqui você consegue finalizar o pedido',
          params: {
            placement: 'top'
          }
        },
      ],
      callbacksTuor: {
        onFinish: this.finalizedTuor,
        onStop: this.finalizedTuor,
        onSkip: this.finalizedTuor,
      },
    }
  },
  watch: {
    exibirModalOpcionais(value) {
      if(!value) {
        this.produtoSelecionado = null
      }
    }
  },
  methods:{
    finalizedTuor(){
      const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
      const tuorVizualiado = {...tuorLocalStorage, fazerPedido: true}
      localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))

      const params = router.currentRoute.params

      router.push(`/Adm/FazerPedido/${params.idreservasfront}/${params.idhospede}/${params.idcheckin}`)
    },
    fecharModal() {
      this.exibirModalOpcionais = false;
    },
    async adicionarProdutoCarrinho(produto) {
      this.produtoSelecionado = null;
      this.setOpcoesSelecionadas(null);
      this.limpaOpcoes();
      this.exibirModalOpcionais = false;

      if(produto.opcoes) {
        this.produtoSelecionado = produto;
        this.exibirModalOpcionais = true;
      } else {
        await this.add(produto)
      }
    },
    setOpcoesSelecionadas(value) {
      this.opcoesSelecionada = value;
    },
    async add(){},
    exibicaoDasOpcoesEscolhidas(ids, opcoes) { 
      if(ids) {
        const opcoesEscolhidas = opcoes.filter(opcao => ids.includes(opcao.id))
        console.log(opcoesEscolhidas)

        const retorno = opcoesEscolhidas.map(item => ({
          id: item.id,
          descricao: item.descricao,
        }));

        return retorno;
      }

      return [];
    },
    async addOpcionais(produto) {
      await this.add(produto)
    },
    remove(){},
    getProdutosNoCarrinho(){
        const mockResponse = [
          {
            "idreservasfront": 2601,
            "id": 837,
            "idcomanda": 391,
            "quantidade": 1,
            "precounitario": "4.50",
            "observacao": null,
            "idusuario": null,
            "codprodutopms": "11",
            "codgrupoprodutopms": "1",
            "idproduto": 15,
            "idpdv": null,
            "idpdvintegrado": null,
            "flbebidaantes": null,
            "datahoralancto": null,
            "idstatus": 1,
            "idcategoriaprodcd": 2,
            "idsubcategoriaprodcd": null,
            "idfoliohits": null,
            "idfoliotohits": null,
            "flimpresso": false,
            "idusuariogarcom": null,
            "ativo": true,
            "idusuarioremocao": null,
            "numeropedido": null,
            "data_criacao": "2023-12-27T20:11:05.000Z",
            "data_integrou_hits": null,
            "fl_removido_hits": false,
            "descontopercentual": null,
            "descontovalor": null,
            "tipodesconto": null,
            "numero_cadeira": null,
            "motivoremocao": null,
            "dataremocao": null,
            "marcado_como_entregue": false,
            "meioprato": false,
            "ultimo_horario_enviado": null,
            "statusintegracaohits": 0,
            "id_ultimo_usuario_atualizacao": 1,
            "flimpressocaixa": false,
            "enviado_aplicativo_nao_impresso": null,
            "descproduto": "Produto B",
            "nomecd": "Produto B",
            "precoimportado": 4.95,
            "precoimportadosemtaxa": 4.5,
            "precoproduto": 4.5,
            "vltaxa": 0.1,
            "opcoes_por_produto": [
              {
                "id": 12,
                "descricao": "Gelo"
              },
              {
                "id": 13,
                "descricao": "Limão"
              }
            ],
            "opcoes_selecionadas": [
              {
                "id": 13,
                "descricao": "Limão"
              },
              {
                "id": 12,
                "descricao": "Gelo"
              },
            ]
          },
          {
              "idreservasfront": 2601,
              "id": 836,
              "idcomanda": 391,
              "quantidade": 1,
              "precounitario": "12.50",
              "observacao": null,
              "idusuario": null,
              "codprodutopms": "108",
              "codgrupoprodutopms": "1",
              "idproduto": 5,
              "idpdv": null,
              "idpdvintegrado": null,
              "flbebidaantes": null,
              "datahoralancto": null,
              "idstatus": 1,
              "idcategoriaprodcd": 3,
              "idsubcategoriaprodcd": null,
              "idfoliohits": null,
              "idfoliotohits": null,
              "flimpresso": false,
              "idusuariogarcom": null,
              "ativo": true,
              "idusuarioremocao": null,
              "numeropedido": null,
              "data_criacao": "2023-12-27T20:11:02.000Z",
              "data_integrou_hits": null,
              "fl_removido_hits": false,
              "descontopercentual": null,
              "descontovalor": null,
              "tipodesconto": null,
              "numero_cadeira": null,
              "motivoremocao": null,
              "dataremocao": null,
              "marcado_como_entregue": false,
              "meioprato": false,
              "ultimo_horario_enviado": null,
              "statusintegracaohits": 0,
              "id_ultimo_usuario_atualizacao": 1,
              "flimpressocaixa": false,
              "enviado_aplicativo_nao_impresso": null,
              "descproduto": "Produto A",
              "nomecd": "Produto A",
              "precoimportado": 13.75,
              "precoimportadosemtaxa": 12.5,
              "precoproduto": 12.5,
              "vltaxa": 0.1,
              "opcoes_por_produto": null,
              "opcoes_selecionadas": null
            },
        ]

        this.carrinho = mockResponse
        this.valorTotal = 0
        for(var i = 0, len = this.carrinho.length; i < len; i++){
            this.valorTotal += this.carrinho[i].precounitario *this.carrinho[i].quantidade
            this.idcomanda = this.carrinho[i].idcomanda
        }

        this.getDadosHospede()
    },
    getDadosHospede(){
          const mockResponse = {
            "idcheckin": 527,
            "idhotel": 1,
            "numreserva": 2601,
            "idreservasfront": 2601,
            "coduh": "101",
            "nome": "Hospede Exemplo",
            "email": "teste_01@economysoftware.com.br",
            "datacheckin": "2023-12-26T03:00:00.000Z",
            "datacheckoutprev": "2023-12-27T03:00:00.000Z",
            "telefone": null,
            "sobrenome": "",
            "senha": "02888",
            "idioma": "pt-BR",
            "idpessoa": 1079,
            "avatar": null,
            "idhospede": 6985,
            "globalcodehits": "2601-02888",
            "cartaoconsumo": "26010",
            "tipopensao": null,
            "linkenviado": false,
            "flnacasa": true,
            "passante": false,
            "id_tipo_hospede_hits": 0,
            "ativo_temp": true
          }

          this.hospede = mockResponse
    },
    limpaOpcoes() {
      this.opcoesSelecionada = [];
      this.multiselecao = false;
    }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Tuor - Fazer Pedido")
      await this.getProdutosNoCarrinho()
      this.feitoPorFecharComanda = this.$route.query.feitoPorFecharComanda ? true : false


    setTimeout(() => {
      this.$tours['fazerPedido'].start()
    }, 200)
  },
  async beforeMount(){
    this.tipointegracao = this.$dados.BUILD_TIPO_INTEGRACAO
  }
};
</script>


<style scoped>

</style>
