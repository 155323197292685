<template>
  <div class="ddpms">
    <span style="font-weight: bold; color: #036097">Carrinho</span>

    <v-card
      elevation="2"
      outlined
      height="auto"
      class="ml-2"
      style="overflow: auto; min-height: 10vh;padding:10px;margin-bottom:8px;max-height:170px;height:40%"
    >
        <div style="display:flex;flex-direction:row;">
            <div style="width:50%;background-color:white;width:50%!important;"  >
              <strong>Nome:</strong>{{hospede.nome}}
            </div>
            <div style="width:25%;">
              <strong>UH</strong>: {{hospede.coduh}}
            </div>
            <div style="width:25%;">
              <strong>Reserva</strong>: {{hospede.numreserva}}
            </div>
        </div>

        <div style="display:flex;flex-direction:row;">
            <div style="width:20%;background-color:white;width:50%!important;display:flex;flex-direction:row;"  >
              <strong>Tipo</strong>:
              <span v-if="tipointegracao != 'HITS'" style=""><b>{{`${hospede.passante?'Passante':'Hospede'}`}}</b></span>
              <span v-else style="">{{`${hospede.passante?'Passante':(hospede.id_tipo_hospede_hits == 2? 'Day Use': (hospede.id_tipo_hospede_hits == 3?'Eventos':'Hospede') )} `}}</span>
            </div>
             <div style="width:80%;">
              GARCOM:
              <v-select color v-model="garcomselecionado" item-text="nomegarcom" item-value="idusuario" :items="listaDeGarconsDisponiveis" return-object filled  label=""></v-select>
            </div>
            <!--<div style="width:25%;">
              Reserva: 25465
            </div> -->
        </div>

    </v-card>

    <v-card
      elevation="2"
      outlined
      height="auto"
      class="ml-2"
      style="overflow: auto; min-height: 57vh; height: 61vh;"
    >


      <v-card-text class="lista-produtos" style="">
        <!-- <v-card-text style="pb-0 mb-0">
          <div style="position:fixed;z-index:1000;background-color:white;width:50%!important;"  >
            HERLESSI
          </div>
        </v-card-text> -->
        <!-- <v-text-field
          outlined
          clearable label="Buscar itens"
          v-model="textoProdPesquisar"
          data-cy="textoProdPesquisar"
          @input="e => filtarCarrinho(e)"/>

           @click="$emit('remove',prod)"
          -->

        <div style="max-height: 57vh; ">
          <v-card
          v-for="prod in produtoscarrinho"
          :key="prod.idproduto"
          :style="prod.cardapios ? 'background-color:#d1deeb;' : ''"
          elevation="2"
          outlined
          class="mt-2"

        >
          <v-card-text>
            <div style="display: flex">
              <div style="width: 100%; color: #5a5858; font-weight: bold;display:flex;flex-direction:column;" data-cy="prod.descproduto">
                  <div>{{ prod.descproduto }}</div>
                  <div>{{ prod.precounitario | formatarMoeda }}</div>

                  <div style="margin-top:10px;display:flex;flex-direction:row;">
                      <v-text-field @change="salvarAlteracaoProduto(prod)" style="width:20%;margin-right:5%;" type="number" placeholder="Valor"  v-model="prod.quantidade" label="Qtde" outlined color></v-text-field>
                      <v-text-field data-v-step-fazer-pedido="4" @change="salvarAlteracaoProduto(prod)" style="width:75%" type="text" placeholder="Valor"  v-model="prod.observacao" label="Observacao" outlined color></v-text-field>
                  </div>
                  <div style="margin-top:10px;display:flex;flex-direction:row;" v-if="prod.opcoes_selecionadas">
                    <v-select
                      data-v-step-fazer-pedido="6"
                      :items="prod.opcoes_selecionadas"
                      :label="prod.opcoes_selecionadas.length > 1 ? 'Opções selecionados' : 'Opção selecionado'"
                      item-text="descricao"
                      v-model="prod.opcoes_selecionadas"
                      multiple
                      disabled
                      return-object
                    ></v-select>
                    <!-- disabled -->
                  </div>
                  <div
                  v-show="!descontoFinalComanda"
                  v-if="$store.getters.get_idperfil != 5 || ($store.getters.get_idperfil == 5 && permitidodescontogarcom == true)" style="margin-top:10px;display:flex;flex-direction:row;">
                      <v-select data-v-step-fazer-pedido="5" @change="salvarAlteracaoProduto(prod)" style="width:20%;margin-right:5%;" v-model="prod.tipodesconto" :items="tiposdesconto" label="Desconto" item-text="nome" item-value="id" return-object></v-select>
                      <v-text-field v-if="(prod.tipodesconto && prod.tipodesconto.id === 1) || prod.tipodesconto === 1" @change="salvarAlteracaoProduto(prod)" type="number" placeholder="Valor do Desconto" label="Percentual" v-model="prod.descontopercentual" outlined color></v-text-field>
                      <v-text-field v-if="(prod.tipodesconto && prod.tipodesconto.id === 2) || prod.tipodesconto === 2" @change="salvarAlteracaoProduto(prod)" type="number" placeholder="Valor do Desconto" label="Valor do Desconto" v-model="prod.descontovalor" outlined color></v-text-field>
                  </div>

                  <div style="margin-top:10px;display:flex;flex-direction:row;justify-content:flex-end;">
                      <a data-v-step-fazer-pedido="7" @click="$emit('remove',prod)" style="color:red;text-decoration:underline;">Remover</a>
                  </div>

              </div>
              <!-- <div>
                <v-btn @click.stop="alterarQuantidade(prod, 'incrementar')">+</v-btn>
                {{prod.quantidade}}
                <v-btn @click.stop="alterarQuantidade(prod, 'decrementar')">-</v-btn>
              </div> -->
            </div>
          </v-card-text>
        </v-card>
        </div>

      </v-card-text>
    </v-card>

    <v-btn width="100%" data-v-step-fazer-pedido="8" style="margin-left:5px" color="#1976d2" dark @click="finalizarPedido">{{$t('produto.finalizar')}}</v-btn>

    <ModalConfirmacao :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textosim="$t('modallocal.btn1')" :textonao="$t('modallocal.btn2')" :textocancelar="$t('modallocal.btn3')" @sim="()=>setarLocal('QUARTO')" @nao="()=>setarLocal('RESTAURANTE')"
                                                @cancelar="() =>{exibirModalLocal = false}"  />

    <ModalConfirmacaoInput :showModal="exibirModalNumeroLocal" :titulo="$t('modalconfirmacao.qualonumero')+`${ tipoLocal == 1? $t('modalconfirmacao.doseuquarto'):$t('modalconfirmacao.dasuamesa') }`"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="(numero)=>setarNumeroLocal(numero)" @nao="()=>fecharModais()"
        :valorinicial="valorinicialmodal" :inputhabilitado="tipoLocal != 1 ? 'SIM' : 'NAO'" />

    <ModalConfirmacaoInput :showModal="exibirModalCartaoConsumo" :titulo="$t('modalcartaoconsumo.texto')"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="(numero)=>setarNumeroCartaoConsumo(numero)" @nao="()=>fecharModais()"
        :valorinicial="valorinicialmodalcartao" :inputhabilitado="'NAO'"  />

    <ModalConfirmacao :showModal="exibirModalConfirmacao" :titulo="$t('avisocancelamento.texto')"
        :textosim="$t('confirmar.texto')" :textonao="$t('cancelar.texto')" @sim="()=>confirmarPedido()" @nao="()=>fecharModais()" />

  </div>
</template>

<script>
import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'

export default {
  name: "Coluna PMS",
  props: ["produtos", "idcomanda", "idreservasfront","idcheckin","hospede","tipointegracao", "feitoPorFecharComanda"],
  components: {
    ModalConfirmacao,
    ModalConfirmacaoInput,
  },
  data: () => ({
    garcomselecionado:null,
    listaDeGarconsDisponiveis:[],
    produtoscarrinho: [],
    produtosdb: [],
    exibirModalLocal: false,
    exibirModalNumeroLocal:false,
    exibirModalConfirmacao:false,
    exibirModalCartaoConsumo:false,
    tipoLocal:1,
    numeroLocal:localStorage.getItem('coduh'),
    numeroCartaoConsumo:localStorage.getItem('cartaoconsumo') || 1234,
    valorinicialmodal:null,
    valorinicialmodalcartao:null,
    permitidodescontogarcom:false,
    tiposdesconto:[
      { id:0, nome: 'SEM DESCONTO' },
      { id:1, nome: 'PERCENTUAL' },
      { id:2, nome: 'VALOR' },
    ],
    descontoFinalComanda: false,
    opcoesProduto: [],
  }),
  watch: {
    produtos(){
      this.produtoscarrinho = JSON.parse(JSON.stringify(this.produtos))
      this.produtosdb = JSON.parse(JSON.stringify(this.produtos))
    }
  },
  methods: {
    async verificarDescontoFinalComanda() {
      this.$http.post('pdv/buscarparametros',{chave:'DESCONTO_FINAL_COMANDA'})
      .then(resp =>{
        if(resp.data && resp.data.length > 0){
          this.descontoFinalComanda = resp.data[0].valor === 'true' ? true : false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    salvarAlteracaoProduto(produto){

      let objetoEnviar = {...produto}

      if(produto.tipodesconto){
          objetoEnviar.tipodesconto= produto.tipodesconto.id
      }

      this.$http.post(`pdv/alterarProdutoNaComanda`, objetoEnviar).then(()=>{
            console.log('alterado')
        })
        .catch((error)=>{
            console.log(error)
        })

    },
    direcionar() {
      this.$router.push(this.url);
    },
    filtarCarrinho(e){
      let query = e
      if(query == ''){
        this.produtoscarrinho = JSON.parse(JSON.stringify(this.produtosdb))
        return
      }
      const produtos = this.produtosdb.filter(produto => {
        // filtrar apenas pelos hospedes
        return Object.values(produto).some((attr) => String(attr).toLowerCase().includes(query.toLowerCase()))
      })
      this.produtoscarrinho = produtos
    },
    finalizarPedido(){
        if(this.produtoscarrinho.length > 0){
          this.exibirModalLocal = true
        }else{
          this.$alertar("warning","Você não possui itens no Carrinho","=(")
        }
    },
    async carregaOpcoes() {
      this.$http.get(`pdv/opcoesProduto/${this.idproduto}/true`)
          .then((res) => {
            this.opcoesProduto = res.data?.opcoes;
            this.multiselecaoOpcao = res.data?.configuracao?.multiselecao;
          })
          .catch(() => {
            this.$alertar("warning", "Erro", "Erro ao carregar opcionais por produto!")
          });
    },
    async confirmarPedido(){
      let dados = {
        tipolocal:this.tipoLocal,
        numerolocal:this.numeroLocal,
        cartaoconsumo:this.numeroCartaoConsumo,
        flgarcom:true,
        // idusuario:this.garcomselecionado.idusuario
      }


      const comandaUnicaPorMesa = await this.$http.post('pdv/buscarparametros', {chave: "COMANDA_UNICA_MESA"})
      .then((res) => res.data[0].valor == 'true')
      .catch((error)=>{
        console.log(error)
        this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
      })

      const comandasAbertasNaMesa = await this.$http.get(`/pdv/buscarComandaMesa/${this.numeroLocal}`)
      .then((res) => {
        const filter = res.data.filter((comanda) => comanda.idcomanda != this.idcomanda)
        return filter.length
      })

      if(comandaUnicaPorMesa && comandasAbertasNaMesa > 0){
        this.fecharModais()
        this.$alertar("warning","Já existe uma comanda aberta nessa mesa","=(")
        return
      }

      dados.id_ultimo_usuario_atualizacao = -1;

      if(this.feitoPorFecharComanda) {
        dados.entregarDireto = true;

        this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/5`,dados).then(()=>{
          this.fecharModais()
          this.$emit('getProdutosNoCarrinho', this.idreservasfront)
          this.$alertar("success","Pedido Enviado para Produção","=)")
        })
        .catch((error)=>{
          console.log(error)
          this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
        })
      } else {
        this.$http.post(`pdv/alterarStatusPedidoEProdutos/${this.idcomanda}/2`,dados).then(()=>{
          this.fecharModais()
          this.$emit('getProdutosNoCarrinho', this.idreservasfront)
          this.$alertar("success","Pedido Enviado para Produção","=)")
        })
        .catch((error)=>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
        })
      }
      // await this.getProdutosNoCarrinho()
      // this.$router.push('/Hospede/Pedidos')
    },
    setarNumeroLocal(numero){
        this.numeroLocal = numero
        this.exibirModalLocal = false
        this.exibirModalNumeroLocal = false
        this.exibirModalCartaoConsumo = false
        this.exibirModalConfirmacao = false
        this.valorinicialmodalcartao = localStorage.getItem('cartaoconsumo')

        if(this.obrigatoriocartaoconsumo){
            this.exibirModalCartaoConsumo = true
        }else{
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalConfirmacao = true
        }
    },
    setarNumeroCartaoConsumo(numero){
        if(numero){
            this.numeroCartaoConsumo = numero
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalCartaoConsumo = false
            this.exibirModalConfirmacao = true
        }else{
            this.$alertar("warning","Informe o Cartão de Consumo!","=(")
        }
    },
    setarLocal(local){
        if(local === 'QUARTO'){
            this.tipoLocal = 1
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = true
            this.valorinicialmodal = localStorage.getItem('coduh')
        }
        if(local === 'RESTAURANTE'){
            this.tipoLocal = 2
            this.exibirModalLocal = false
            this.exibirModalNumeroLocal = true
            this.valorinicialmodal = null
        }

        return;

    },
    fecharModais(){
        this.exibirModalLocal = false
        this.exibirModalNumeroLocal = false
        this.exibirModalCartaoConsumo = false
        this.exibirModalCartaoConsumo = false
        this.exibirModalConfirmacao = false
    },
    async alterarQuantidade(produto, tipo){
      let index = this.produtoscarrinho.indexOf(produto)
      let quantidadeantiga = this.produtoscarrinho[index].quantidade
      let quantidadenova
      if(tipo == 'incrementar'){

        if(quantidadeantiga < 10){

          quantidadenova = quantidadeantiga += 1
          await this.$http.post('pdv/alterarQuantidadeProduto', {idproduto: produto.idproduto, idcomanda: this.idcomanda, quantidade: quantidadenova}).then(() => {
            this.produtoscarrinho[index].quantidade = quantidadenova
          })

        }
        let quantidadenova
      }else{

        if(quantidadeantiga > 1){

            quantidadenova = quantidadeantiga -= 1
            await this.$http.post('pdv/alterarQuantidadeProduto', {idproduto: produto.idproduto, idcomanda: this.idcomanda, quantidade: quantidadenova}).then(() => {
              this.produtoscarrinho[index].quantidade = quantidadenova
            })

        }

      }

    }
  },
  async mounted(){
          this.$http.post('pdv/buscarparametros',{chave:'PERMITIDO_DESCONTO_POR_GARCOM'})
          .then(resp =>{
              this.permitidodescontogarcom = resp.data[0].valor === 'true' ? true:false
          }).catch(error =>{
              alert(error)
          })

          this.listaDeGarconsDisponiveis = await this.$http.post('pdv/listarGarconsDisponiveisAgora').then((resp) =>{ return resp.data}).catch(error => console.log(error))


  await this.verificarDescontoFinalComanda()
  }
};
</script>

<style scoped lang="scss">
.ddpms {
  /* background-color: white; */
  width: 35%;
  height: 100% !important;
}

.ddpmsprod {
}

.select {
  -webkit-appearance: none; /* Remove estilo padrão do Chrome */
  -moz-appearance: none; /* Remove estilo padrão do FireFox */
  appearance: none; /* Remove estilo padrão do FireFox*/

  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif)
    no-repeat #fff; /* Imagem de fundo (Seta) */
  background-position: 98% center; /*Posição da imagem do background*/

  border: 1px solid black;
  width: 100%;
  max-width: 1000px;
  height: 40px;
  padding: 10px;
  padding-right: 20%;
  border-radius: 10px;
}

.lista-produtos{
  height: 100% !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr 1fr .1fr;
}

</style>
