<template>
    <v-dialog v-model="showModalDuplicarCardapioPDV" max-width="70vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Duplicar Cardapio de PDV
                    </span>
                    
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        
                        {{JSON.stringify(this.pdf,null,2)}}
                        <v-select color v-model="pdvantigo" 
                            :items="listadepdvs" filled  label="Copiar cardápio do PDV:"
                            item-text="descpdv"
                            item-value="idpdv"
                            >
                        </v-select>
                        
                    </div>

                    <div v-if="!processando">
                        <div v-if="!confirmado" style="margin-top:10px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                            <v-btn color="primary" @click="setConfirmar()" data-cy="atualizarPdv">
                                Copiar dados do PDV
                            </v-btn>
                        </div>

                        <div v-else style="margin-top:10px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                            <v-btn color="error"  @click="setConfirmar()" data-cy="atualizarPdv" style="margin-right:30px;">
                                Cancelar Copia de Dados
                            </v-btn>
                            <v-btn color="primary" @click="duplicarCardapioPdv()" data-cy="atualizarPdv">
                                Confirmar Copia de Dados
                            </v-btn>
                        </div>
                    </div>
                    <div v-else style="font-size:20px;display:flex;justify-content:center;">
                        Processando...
                    </div>

                   

                </v-card>

                

            </v-card-text>
            
            
        </v-card>


       
    </v-dialog>
</template> 

<script>


  export default {
    name: 'Modal Duplicar PDV',
    props:["showModalDuplicarCardapioPDV","pdv"],
    components: {
      
    },
    data: () => ({
       listadepdvs:null,
       pdvantigo:null,
       pdvnovo:null,
       confirmado:false,
       processando:false,
    }),
    mounted(){
        this.pdvnovo = this.pdv
        this.getPDVSCD()
    },
    methods:{
        setConfirmar(){
            this.confirmado = !this.confirmado
        },
        fechar(){
            this.$emit('fechar')
        },
        getPDVSCD(){
            this.$http('pdv/getPDVSCD/0').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listadepdvs = resp.data 
                    this.pdvantigo = resp.data[0].idpdv
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })
        },
        duplicarCardapioPdv(){
            
            this.processando = true
            this.$http.post('pdv/duplicarCardapioPDV',{pdvnovo:this.pdvnovo.idpdv,pdvantigo:this.pdvantigo}).then(() =>{
                this.processando = false
                this.$emit('atualizarAposDuplicar')
                this.$alertar('success',"Processo Concluído",'=D')
            }).catch((error)=>{
                this.processando = false
                this.$alertar('warning',error,'=(')
            })
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

