<template>
  <transition name="modal" v-if="showModal" @close="showModal = false">
      <div class="modal-mask" >
          <div class="modal-wrapper">
              <div class="modal-container">

              <div class="modal-header">
                  <slot name="header">
                  <!-- <div style="width:100%;background-color:green;display:flex;justify-content:flex-end;margin-top:10px;" @click="showModal = false" >
                      <v-badge  color="red accent-4" icon="mdi-close"  ></v-badge>
                  </div> -->
                  </slot>
              </div>

              <div class="modal-body">
                  <slot name="body">
                  {{titulo}}
                  </slot>
              </div>

              <div class="modal-footer" >
                  <slot name="footer">
                    <v-textarea v-if="tipoTela == 'REMOCAOITEM'"
                      v-model="motivoremocaoState"
                      @input="motivoremocaoHandleUpdate"
                      label="Insira o motivo da remoção"
                    ></v-textarea>

                    <v-btn  :disabled="motivoremocao && motivoremocao.trim().length > 0 ? false : true" v-if="textosim != '-' && tipoTela == 'REMOCAOITEM'" color="green" style="color: white;" width="240px" data-cy="modal-confirmacao-sim" @click="sim()" class="mb-2 mt-2">
                      {{textosim}}
                    </v-btn>

                    <v-btn v-if="textosim != '-' && tipoTela != 'REMOCAOITEM'" color="green" style="color: white;" width="240px" data-cy="modal-confirmacao-sim" @click="sim()" class="mb-2 mt-2" :disabled="destivarBotao">
                      {{textosim}}
                    </v-btn>

                    <v-btn v-if="textonao != '-'" color="orange" style="color: white;" width="240px" data-cy="modal-confirmacao-nao" @click="nao()" class="mb-2 mt-2" :disabled="destivarBotao">
                        {{textonao}}
                    </v-btn>
                    <v-btn v-if="exibeBtnCancelar" color="red" style="color: white;" data-cy="modal-confirmacao-cancelar"  width="240px" @click="cancelar()" class="mb-2 mt-2" :disabled="destivarBotao">
                        <!-- <v-icon>mdi-alert</v-icon> --> {{textocancelar?textocancelar:'Voltar'}}
                    </v-btn>
                  </slot>
              </div>
              </div>
          </div>
      </div>
  </transition>
</template>

<script>

export default {
  name: 'BotaoVoltar',
  props:["showModal","titulo","textosim","textonao","textocancelar", "exibeBtnCancelar", "motivoremocao", "tipoTela"],
  data(){
      return{
          motivoremocaoState: this.motivoremocao,
          destivarBotao: false
      }
  },
  mounted(){
    this.destivarBotao = false
  },
  methods:{
      direcionar(){
          this.$router.push(this.url)
      },
      nao(){
        this.destivarBotao = true
        this.$emit('nao')
        this.destivarBotao = false
      },
      sim(){
        this.destivarBotao = true
        this.$emit('sim')
        this.destivarBotao = false
      },
      cancelar(){
        this.destivarBotao = true
        this.$emit('cancelar')
        this.destivarBotao = false
      },
      motivoremocaoHandleUpdate(){
        this.$emit('motivoremocaoHandleUpdate', this.motivoremocaoState)
      }
  }
}
</script>


<style scoped>
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: table;
transition: opacity 0.3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
width: 300px;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
transition: all 0.3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.botaoModalLocal{
  width:100%;
  height: 50px;
  display: block;
  margin-bottom:30px;
  border:0px;
  font-weight: bold;
  color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.botaoModalLocalLaranja{background-color: rgb(245, 149, 40);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}
</style>

