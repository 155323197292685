<template>
    <v-dialog v-model="exibirModalEditarUsuario" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Usuário
                    </span>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <div style="display:flex;flex-direction:row;">
                            <div style="padding-bottom:10px;width:70%;">
                                <h3>Nome <span style="color:red;"></span></h3>
                                <input maxlength="50" type="text" data-cy="modaleditar-nome" v-model="usuarioedicao.nome" placeholder="Digite o nome completo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            </div>
                            <div style="padding-bottom:10px;width:30%;margin-left:5px;">
                                <h3>Turno para Comissionamento <span style="color:red;"></span></h3>
                                <!-- <input maxlength="50" type="text" data-cy="modaleditar-nome" v-model="usuarioedicao.id_turno" placeholder="Digite o nome completo" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" /> -->
                                <select v-model="usuarioedicao.id_turno" data-cy="id_turno" class="select-usuario">
                                    <option v-for="turno in listaTurnos" :key="turno.id_turno" 
                                        :value="turno.id_turno" >
                                        {{turno.turno}}
                                    </option>                                    
                                </select>
                            </div>
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Email <span style="color:red;"></span></h3>
                            <input maxlength="50" type="text" data-cy="modaleditar-email" v-model="usuarioedicao.email" placeholder="Digite o emal" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Login <span style="color:red;">(sem espaços)</span></h3>
                            <input maxlength="50" type="text" data-cy="modaleditar-login" v-model="usuarioedicao.nomeusuario" placeholder="Digite o nome de login sem espaços" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Nova Senha <span style="color:red;">(sem espaços)</span></h3>
                            <input maxlength="50" type="password" data-cy="modaleditar-senha1" v-model="senha1" placeholder="Digite o nome de login sem espaços" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                            <a @click="gerarSenha">Gerar senha automaticamente</a>
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Repita a Senha <span style="color:red;">(sem espaços)</span></h3>
                            <input maxlength="50" type="password" data-cy="modaleditar-senha2" v-model="senha2" placeholder="Digite o nome de login sem espaços" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div style="padding-bottom:10px;">
                            <h3>Perfil</h3>
                            <!-- <select v-model="usuarioedicao.idperfil" data-cy="modaleditar-idperfil" class="select" @change="setnome()">
                                <option v-for="perfil in listaperfis"  :key="perfil.id" :value="perfil.id" >
                                    {{perfil.nome}}
                                </option>
                            </select> -->
                            <div class="listaBotaoPerfil">
                                <div :class="getPerfilSelecionado(perfil.id) ?'botaoPerfilSelecionado': 'botaoPerfilNaoSelecionado'" 
                                    v-for="perfil in listaperfis"  :key="perfil.id" 
                                    @click="addRemovePerfilxUsuairo(perfil.id)">
                                    {{perfil.nome}}
                                </div>
                            </div>
                        </div>

<!-- 
                        <div style="padding-bottom:10px;padding-top:10px;" v-if="usuarioxperfil.includes(3) || usuarioxperfil.includes(4)">
                            <h3>PVD's</h3>
                            <div class="listaBotaoPerfil">
                                <div :class="getPvdSelecionado(pvd) ? 'botaoPerfilSelecionado': 'botaoPerfilNaoSelecionado'" 
                                    v-for="pvd in listapdvs"  :key="pvd.idpvd" 
                                    @click="inserirRemoverPdv(pvd)">
                                    {{pvd.descpdv}}
                                </div>
                            </div>
                        </div> -->

                        
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" @click="atualizarUsuario()" data-cy="modaleditar-atualizarUsuario">
                            SALVAR
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>
    </v-dialog>
</template> 

<script>
// import dayjs from 'dayjs'

  export default {
    name: 'Modal Editar Usuario',
    props:["exibirModalEditarUsuario","usuarioedicao"],
    data: () => ({
        listaperfis:[],
        idperfil:2,
        nome:'herlessi1',
        email:'herlessi@gmail.com1',
        login:'herlessi1',
        senha1: null,
        senha2: null,
        usuarioxperfil:[],
        listapdvs: [],
        pdvs: [],
        listaTurnos: []
    }),
    async mounted(){
        this.carregarTurnos()
        await this.requisitarPDVS()
        this.usuarioxperfil = this.usuarioedicao.perfis
        // this.pdvs = this.usuarioedicao.pdvs
        
        if(this.$store.getters.get_idperfil.includes(1)){
            this.listaperfis = [
                {id:1,nome:'Master'},
                {id:2,nome:'Caixa'},
                {id:3,nome:'Cozinha'},
                {id:4,nome:'Bar 1'},                
                {id:6,nome:'Bar 2'},
                {id:5,nome:'Garçom'},
                {id:8,nome:'Garçom Padrão'}
            ]
        }else{
                this.listaperfis = [
                {id:2,nome:'Caixa'},
                {id:3,nome:'Cozinha'},
                {id:4,nome:'Bar 1'},                
                {id:6,nome:'Bar 2'},
                {id:5,nome:'Garçom'},
                {id:8,nome:'Garçom Padrão'}
            ]
        }

        

    },
    methods:{
        carregarTurnos() {
            this.$http
                .post('comissao/turno/buscar',{}).then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listaTurnos = resp.data 
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })
        },
        inserirRemoverPdv(pvd){
          if(this.pdvs.indexOf(pvd.idpdv) < 0){
            this.pdvs.push(pvd.idpdv)
          }else{
            this.pdvs.splice(this.pdvs.indexOf(pvd.idpdv), 1)
          }
        },
        getPvdSelecionado(pvd){
          if(this.pdvs.indexOf(pvd.idpdv) < 0){
            return false
          }else{
            return true
          }
        
        },
        async requisitarPDVS(){
            this.$http('pdv/getPDVSCD/0').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listapdvs = resp.data 
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })
        },     
        getPerfilSelecionado(id){
            
            if(this.usuarioxperfil){
                let found = this.usuarioxperfil.find(x => x  == id);
                return found
            }else{
                return null
            }

        },
        addRemovePerfilxUsuairo(id){
            let found = null
            if(this.usuarioxperfil){
                found = this.usuarioxperfil.find(x => x  == id);
            }else{
                this.usuarioxperfil = []
            }
            if(found){
                this.removesuarioxperfil(id)
            }else{
                this.addusuarioxperfil(id)
            }
            
        },
        addusuarioxperfil(idperfil){
            this.usuarioxperfil.push(idperfil)
        },
        removesuarioxperfil(idperfil){
            this.usuarioxperfil = this.usuarioxperfil.filter(x=>{
                return x != idperfil
            })
        },
        gerarSenha(){
            function getRandomInt(min, max){
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }
            function get_password_word(n){
                var data1 = ['a','e','i','o','u'];
                var data2 = ['b','c','d','f','g','h','j','k','l','m','n','p','q','r','s','t','v','w','x','y','z'];
                var data3 = ['bl','br','cl','cr','dr','dw','fl','fr','gl','gr','gw','kn','kr','kw','mr','ph','pl','pn','pr','ps','sc','sh','sk','sl','sm','sn','sp','st','sv','sw','tr','ts','wh'];
                
                var str = '';
                var last = '';
                for(var i = 0; i < n; i++){
                    var type = getRandomInt(1, 10);
                    //avoiding some cases
                    if(last == 3)
                        type = 1;
                    if(last == 2)
                        type = 1;
                    if(last == 1 && getRandomInt(1, 2) == 1)
                        type = 2;
                    //generate
                    if(type < 4){ //40%
                        str += data1[getRandomInt(0, data1.length-1)];
                        last = 1;
                        }
                    else if(type < 9){  //40%
                        str += data2[getRandomInt(0, data2.length-1)];
                        last = 2;
                        }
                    else{ //20%
                        str += data3[getRandomInt(0, data3.length-1)];
                        last = 3;
                        }
                    }
                str = str.charAt(0).toUpperCase() + str.slice(1);
                return str;
            }
            this.senha1 = get_password_word(6)
            this.senha2 = this.senha1
          
        },    
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        validarDados(obj){


            if(!obj.nome){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }

            if(!obj.email){
                this.$alertar("warning","Email não Informado!","=(")
                return false
            }

             if(!obj.nomeusuario){
                this.$alertar("warning","Login não Informado!","=(")
                return false
            }

            if(this.usuarioxperfil.length == 0){
                this.$alertar("warning","Id Perfil não Informado!","=(")
                return false
            }

            if(obj.senha1 != obj.senha2){
                this.$alertar("warning","As senhas não estão iguais!","=(")
                return false
            }else{
                obj.senha = obj.senha1
            }






            return true
        },
        atualizarUsuario(){
            
            let obj = {
                idusuariosistema:this.usuarioedicao.idusuario,
                nome:this.usuarioedicao.nome,
                email:this.usuarioedicao.email,
                nomeusuario:this.usuarioedicao.nomeusuario,
                // idperfil:this.usuarioedicao.idperfil,
                usuariosxperfil:this.usuarioxperfil,
                senha1:this.senha1,
                senha2:this.senha2,
                id_turno: this.usuarioedicao.id_turno
            }
console.log(obj)
            if(this.validarDados(obj)){
                // obj['pdvs']= this.pdvs
            
                this.$http.post(`pdv/atualizarusuario`,obj).then(() =>{
                    this.$alertar("success","Usuario Atualizado Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning",JSON.stringify(error.response.data.message),"=(")
                    // console.log("error")
                    // console.log(JSON.stringify(error.response.data.message))
                })

            }
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }

  .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid rgb(156, 156, 156);
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }
    
    .listaBotaoPerfil{
        height:100px;
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .botaoPerfilNaoSelecionado{
        background-color: #8a8a8a;
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .botaoPerfilSelecionado{
        background-color: rgb(7, 156, 7);
        color:white;
        font-size: 16px;
        width: 150px;
        margin-top:10px;
        height:30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .select-usuario {
        width:100%;
        height:50px;
        background-color:white;
        padding:10px;
        border:1px solid #bdbaba;
        border-radius:10px;
    }
</style>

