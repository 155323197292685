<template>
  <div>
    <v-dialog v-model="exibirModalLogs" width="1000" max-width="80vw">
      <v-card style="max-width: 100%">
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width:95%;color:white;font-weight:bold;">
              Logs de Movimentação
            </span>
            <span style="width:5%;">
              <v-btn
                x-small
                color="red"
                @click="() => fecharModal()"
                title="Fechar"
              >
                <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>

        <template>
          <v-card>
            <v-card-title>
              <v-select
                :items="itemsTipo"
                label="Tipo"
                v-model="tipo"
              ></v-select>

              <v-spacer></v-spacer>

              <v-select
                :items="itemsIdusuario"
                label="Usuário"
                v-model="idusuario"
                item-value="idusuario"
                item-text="nome"
              ></v-select>

              <v-spacer></v-spacer>

              <v-select
                :items="itemsDescricao"
                label="Descrição"
                v-model="descricao"
              ></v-select>

              <v-spacer></v-spacer>

              <v-btn @click="buscarLogs">Buscar logs</v-btn>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
            ></v-data-table>
          </v-card>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "ModalLogsMovimentacao",
  props: ["exibirModalLogs"],
  components: {},
  data: () => ({
    search: "",
    headers: [
      { text: "Tipo", value: "tipo" },
      { text: "Usuário", value: "nome_usuario" },
      { text: "Descrição", value: "descricao" },
      { text: "Data", value: "dt_criacao" },
    ],
    items: [],
    tipo: "Todos",
    idusuario: "Todos",
    descricao: "Todos",
    itemsTipo: ["Todos", "PDV", "Categoria", "Subcategoria"],
    itemsIdusuario: ["Todos"],
    itemsDescricao: [
      "Todos",
      "Exclusão",
      "Inativação",
      "Atualização",
      "Adição de produto",
      "Remoção de produto",
      "Criação",
    ],
  }),
  watch: {},
  methods: {
    fecharModal() {
      this.$emit("fechar");
    },
    buscarLogs() {
      const url = "/pdv/logMovimentacaoCardapio";

      this.search = "";

      const enumTipo = {
        PDV: 1,
        Categoria: 2,
        Subcategoria: 3,
      };

      const enumTipoInvertido = {
        1: "PDV",
        2: "Categoria",
        3: "Subcategoria",
      };

      const data = {
        tipo: enumTipo[this.tipo] || undefined,
        usuario: this.idusuario === "Todos" ? undefined : this.idusuario,
        descricao: this.descricao == "Todos" ? undefined : this.descricao,
      };

      this.$http.post(url, data).then(({ data }) => {
        data.map((item) => {
          item.dt_criacao = dayjs(item.dt_criacao).format(
            "DD-MM-YYYY HH:mm:ss"
          );
          item.tipo = enumTipoInvertido[item.tipo];
        });
        this.items = data;
      });
    },
    buscarUsuarios() {
      this.$http
        .post("pdv/buscarusuarios", { filtro: "Todos" })
        .then(({ data }) => {
          this.itemsIdusuario = [
            { nome: "Todos", idusuario: undefined },
            ...data,
          ];
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.buscarLogs();
    this.buscarUsuarios();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal {
  width: 100%;
  height: 50px;
  display: block;
  margin-bottom: 30px;
  border: 0px;
  font-weight: bold;
  color: white;
}

.botaoModalLocalAmarelo {
  background-color: rgb(239, 175, 62);
}
.botaoModalLocalVerde {
  background-color: rgb(0, 165, 51);
}
.botaoModalLocalAzul {
  background-color: rgb(63, 81, 181);
}
.botaoModalLocalVermelho {
  background-color: rgb(216, 37, 37);
}
.headerTitulo {
  font-weight: bold;
}
.numeroForm {
  height: 50px !important;
}

.inputsHorario {
  background-color: white;
  text-align: center;
  border-radius: 15px;
  min-height: 8vh;
  font-size: 25px;
  padding: 10px;
  border: 1px solid #819ab4;
  margin-right: 10px;
  width: 100px;
}
</style>
