<template>
    <div class="centralizadohorizontal">
         <div class="estiloColuna">
             <div style="width:100%;height:8vh;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;padding-right:5.5%;">
                 <v-btn large elevation="" id="botaoSincronizar" color="primary" style="width:40%;height:7vh" data-cy="setexibirModalCriarUsuario" @click="sincronizarPMS()">
                    {{ butaoBuscarHits }}
                 </v-btn>
 
                 <v-btn large elevation="" color="success" style="width:40%;height:7vh" data-cy="setexibirModalCriarUsuario" @click="setexibirModalCriarUsuario()">
                    + NOVO USUÁRIO
                 </v-btn>
             </div>
 
             <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:3px;" >
                <BotaoTutorial style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;" categoria="14" legenda="" urlVideo="https://drive.google.com/file/d/1sBEI9kxncyx1OsMZ5jufuBEzI1xw3s5h/preview" />
                 <v-card-text>
 
                         
                             
                         <div class="topoform">
 
                             
                           
 
                             <div class="divinput">
                         
                                     <label for="">Nome:</label>
                                     <input type="text" class="input" data-cy="nome" placeholder="Insira o nome" v-model="nome">
 
                             </div>
 
                             <div class="divinput">
                         
                                     <label for="">Email:</label>
                                     <input type="text" class="input" data-cy="email" placeholder="Insira o  email" v-model="email">
 
                             </div>
 
                             <div class="divinput">
                         
                                     <label for="">Tipo</label>
                                     <select v-model="idperfil" class="select" data-cy="idperfil" @change="setnome()">
                                         <option v-for="perfil in listaperfis"  :key="perfil.id" :value="perfil.id" >
                                             {{perfil.nome}}
                                         </option>
                                     </select>
 
                             </div>
 
                             <div class="divinput">
                         
                                 <label for="">Filtro:</label>
                                 <select v-model="filtro" class="select" data-cy="integracao" @change="setIntegracao()">
                                     <option v-for="integracao in integracoes"  :key="integracao.nome" :value="integracao.nome" >
                                         {{integracao.nome}}
                                     </option>
                                 </select>
 
                             </div>
 
                              
 
                         </div>
 
                         
 
                         
                         
                         <div style="width:100%;height:8vh;margin-bottom:0px;display:flex;align-items:center;justify-content:flex-end;padding-right:4%;">
                                 <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                                 <!-- <div class="divinput" style="display:flex;align-items:flex-end;margin-top:30px;"> -->
                         
                                     <v-btn large elevation="" color="primary" style="width:40%;height:7vh" data-cy="buscar" @click="buscar()">
                                         BUSCAR USUÁRIO    
                                     </v-btn>
 
                                 <!-- </div>         -->
                         </div>
                 
                 </v-card-text>
 
             </v-card>
         </div>
 
         <div class="estiloColuna">
             <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;" >
                 <v-card-title primary-title >
                     <div style="padding-left:10px;padding-right:10px;">
                         <div style="display:flex;flex-direction:column;">
                             <!-- <div>Nome: {{nomeRelatorio}}</div> 
                             <div>Status: {{nomeStatus}}</div>  -->
                             <div id="textoUsuarioSincronizado" name="textoUsuarioSincronizado">{{textoUsuarioSincronizado}}</div> 
                         </div>
                     </div>
                     
                 </v-card-title>
 
                 <div style="padding-left:20px;padding-right:20px;">
                     <v-data-table 
                     :headers="headers"
                     :items="listadeusuarios"
                     :search="search"
                     >
                     <!--  -->
                     <template v-slot:[`item.nome`]="{ item }">
                         <a @click="setexibirModalEditarUsuario(item)" data-cy="setexibirModalEditarUsuario">{{item.nome}}</a>
                     </template>
 
                      <template v-slot:[`item.email`]="{ item }">
                         <span data-cy="item.email">{{item.email}}</span>
                     </template>
 
                     <!-- <template v-slot:[`item.dataentregue`]="{ item }">
                         {{item.dataentregue | formatarDataHora}}
                     </template>
 
                     <template v-slot:[`item.imgassinatura`]="{ item }">
                         <a @click="carregarModalAssinatura(item)"> Assinatura </a>
                     </template> -->
 
                      <template v-slot:[`item.action`]="{ item }">
                         <a data-cy="item.action" @click="alterarStatusUsuario(item)"> 
                           {{item.ativo ? 'inativar' : 'ativar'}}
                         </a>
                     </template> -->
 
                     
                     
                     </v-data-table>
 
                     
 
                 </div>
 
             </v-card>
         </div>
 
         <ModalCriarUsuario v-if="exibirModalCriarUsuario" :exibirModalCriarUsuario="exibirModalCriarUsuario"
             :pedido="itemparamodalassinatura"
             @fechar="()=>setexibirModalCriarUsuario()" />
 
         <ModalEditarUsuario v-if="exibirModalEditarUsuario" :exibirModalEditarUsuario="exibirModalEditarUsuario"
             :usuarioedicao="usuarioedicao"
             @fechar="()=>setexibirModalEditarUsuario()" />
       
     </div> 
 </template>
 
 <script>
 
 // import ColunaEsquerda from '../../components/layout/ColunaEsquerda.vue'
 // import ColunaDireita from '../../components/layout/ColunaDireita.vue'
 // import ColunaPedido from '../../components/pedidos/ColunaPedido.vue'
 // import ModalImpressaoPedido from '../../components/layout/ModalImpressaoPedido.vue'
 import ModalCriarUsuario from '../../components/layout/Modais/ModalCriarUsuario.vue'
 import ModalEditarUsuario from '../../components/layout/Modais/ModalEditarUsuario.vue'
 import BotaoTutorial from '../../components/layout/BotaoTutorial.vue';
 
 // const dayjs = require('dayjs')
 
 export default {
   name: 'ViewCadastroUsuario',
   components:{
     // ColunaEsquerda,
     // ColunaDireita,
     // ColunaPedido,
     // ModalImpressaoPedido,
     ModalCriarUsuario,
     ModalEditarUsuario,
     BotaoTutorial,
 },
   data: () => ({
     listaperfis:[],
     idperfil:0,
     nome:null,
     email:null,
     login:null,
     exibirModalCriarUsuario:false,
     exibirModalEditarUsuario:false,
     usuarioedicao:null,
     listadeusuarios:[],
     butaoBuscarHits: "Sincronizar usuários",
     filtro: "Todos",
     integracoes: [],
     textoUsuarioSincronizado:'',
   }),
     computed: {
         perfil(){
             return this.$store.getters.get_idperfil
         }
     },
   methods:{
       alterarStatusUsuario(item){
         let obj = {
           idUsuario: item.idusuario,
           statusUsuario: !item.ativo         
         }
         this.$http.put('pdv/usuarios/alterarStatusUsuario', obj).then((response) => {
           if (response.status == 200) {
             let user = this.listadeusuarios.filter(el => el.idusuario === item.idusuario)[0]
             let indexuser = this.listadeusuarios.indexOf(user)
             user.ativo = obj.statusUsuario
             this.listadeusuarios[indexuser] = user
             this.$alertar('success', 'Status alterado com sucesso', '=)')
           } else {
             this.$alertar('warning', 'Erro ao alterar o status do usuário', '=(')
           }
         });      
       },
       setexibirModalEditarUsuario(usuario){
        
         this.usuarioedicao = usuario
         this.exibirModalEditarUsuario = !this.exibirModalEditarUsuario
       },
       continuar(){
           this.$router.push('/Hospede/Menu')
       },
       async setarTela(tela){
         this.telaatual = tela
       },
       buscar(){
 
             // if(this.nome || this.email){
 
                 // filtro 0 = busca todos os usuarios
                 // filtro 1 = busca usuarios economy
                 // filtro 2 = busca usuarios hits
                 // filtro 3 = busca usuarios admh
 
                 const obj = {
                         nome:this.nome,
                         email:this.email,
                         idperfil:this.idperfil,
                         filtro: this.filtro
                     }
 
                     this.$http.post('pdv/buscarusuarios', obj)
                     .then((resp) =>{
                         this.listadeusuarios = resp.data
                         if(resp.data && resp.data.length > 0){
                             this.textoUsuarioSincronizado = 'Usuarios Sincronizados'
                         }else{
                             this.textoUsuarioSincronizado = ''
                         }
                         
                     }).catch(() =>{
                         this.textoUsuarioSincronizado = ''
                         this.$alertar("success","Não foi Possível buscar todos os usuarios!", "=(")
                     })
 
 
             // }else{
             //     this.$alertar("warning", "Nome ou Email são Obri" , "=)")
             // }
 
       },
       carregarModalAssinatura(item){
           this.itemparamodalassinatura = item
           this.itemparamodalassinatura.numpedido = this.itemparamodalassinatura.idcomanda
         
           this.setexibirModalCriarUsuario()
       },
       setexibirModalCriarUsuario(){
         //   this.$alertar('warning','tes')
             this.exibirModalCriarUsuario = !this.exibirModalCriarUsuario
         },
         esperar(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
         
    async sincronizarPMS() {
 
        this.butaoBuscarHits = "Sincronizando..."

        this.filtro = "Todos"

        const obj = {
            nome:this.nome,
            email:this.email,
            idperfil:this.idperfil,
            filtro: this.filtro
        }

        const tipoIntegracao = localStorage.getItem('tipo_integracao')

        if(tipoIntegracao.toUpperCase() === 'HITS') {
            this.$http.post('pdv/importarUsuariosHits');
        }

        if(tipoIntegracao.toUpperCase() === 'ADMH') {
            this.$http.post('pdv/importacaoUsuariosADMH');
            await this.esperar(5000);
        }

        this.$http.post('pdv/buscarusuarios', obj)
        .then((resp) =>{
            this.listadeusuarios = resp.data
            this.butaoBuscarHits = "Sincronizado"
        }).catch(() =>{
            this.$alertar("success","Não foi Possível buscar todos os usuarios!", "=(")
            this.butaoBuscarHits = "Sincronizar usuários"
        })
     },
   },
   async mounted(){
         this.$store.dispatch("set_titulo", "Cadastro de Usuarios")
         if(this.perfil == 1){
             this.listaperfis = [
                 {id:0,nome:'Todos'},
                 {id:1,nome:'Master'},
                 {id:2,nome:'Caixa'},
                 {id:3,nome:'Cozinha'},
                 {id:4,nome:'Bar'},
                 {id:6,nome:'Bar 2'},
                 {id:5,nome:'Garçom'},
                 {id:7,nome:'Cumim'}
             ]
         }else{
             this.listaperfis = [
                 {id:0,nome:'Todos'},
                 {id:2,nome:'Caixa'},
                 {id:3,nome:'Cozinha'},
                 {id:4,nome:'Bar'},
                 {id:6,nome:'Bar 2'},
                 {id:5,nome:'Garçom'},
                 {id:7,nome:'Cumim'}
             ]
         }
 
         this.headers = [
                         { text: 'ID', value: 'idusuario',width: '120px' },
                         { text: 'NOME', value: 'nome',width: '120px' },
                         { text: 'LOGIN', value: 'nomeusuario',width: '120px' },
                         { text: 'EMAIL', value: 'email',width: '120px' },
                         { text: 'PERFIL', value: 'nomeperfil',width: '120px' },
                         { text: 'TIPO', value: 'integracao', width: '120px' },
                         { text: 'ATIVAR/INATIVAR', value: 'action',width: '120px' },
 
                         // { text: 'MESA INI', value: 'md_mesa_inicial',width: '120px' },
                         // { text: 'MESA FIM', value: 'md_mesa_final',width: '120px' }
                     ]
 
        this.integracoes = [
            {nome: "Todos"},
            {nome: "Economy"},
            // {nome: "HITS"},
        ]

        const tipoIntegracao = localStorage.getItem('tipo_integracao')

        if(tipoIntegracao.toUpperCase() === 'ADMH') {
            const integracoes = this.integracoes
            integracoes.push({nome: "ADMH"})
            this.integracoes = integracoes
        }

        if(tipoIntegracao.toUpperCase() === 'HITS') {
            const integracoes = this.integracoes
            integracoes.push({nome: "HITS"})
            this.integracoes = integracoes
        }
   }
   
 };
 </script>
 
 
 <style scoped>
     .centralizadohorizontal{
         display:flex;
         align-items: center;
         flex-direction: column;
         /* background-color: pink; */
         justify-content: center;
     }  
 
     .topoform{
         /* background-color: blue; */
         width:100%;
         display:flex;
         flex-wrap:wrap;
         align-content: flex-start;
     }  
 
     .estiloColuna{
         /* background-color: white; */
         /* padding-top:10px; */
         width: 80%!important;
         /* margin-right:2%!important; */
         /* min-height: 100%;
         max-height: 100; */
         /* border:1px solid rgb(156, 156, 156);
         border-radius:5px; */
         
     }
 
     .topoColuna{
       /* width:100%;
       height:10%;
       border:none;
       display:flex;
       align-items: center;
       justify-content: space-between;
       padding-left:2px;
       padding-right:20px; */
     }
 
     .select{
       
         -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
         -moz-appearance: none;/* Remove estilo padrão do FireFox */  
         appearance: none; /* Remove estilo padrão do FireFox*/   
           
         background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
         background-position: 98% center;  /*Posição da imagem do background*/  
        
 
         border:1px solid rgb(156, 156, 156);
         width: 100%;
         max-width:1000px;
         height: 40px;
         padding:10px;
         padding-right: 20%;
         border-radius: 10px;
   
     }
 
 
     .divinput{
         width:30%;
         margin-left:2%;
         margin-bottom: 10px;
     }
 
     .input{
 
         -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
         -moz-appearance: none;/* Remove estilo padrão do FireFox */  
         appearance: none; /* Remove estilo padrão do FireFox*/   
 
         border:1px solid rgb(156, 156, 156);
         width: 100%;
         max-width:1000px;
         height: 40px;
         padding:10px;
         /* padding-right: 20%; */
         border-radius: 10px;
     }
 </style>
 