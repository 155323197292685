<template>
    <v-card class="container" elevation="2" outlined style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px">

      <div class="qrcode-generator" >
        <h2>Gerador de QR Code</h2>

        <div class="pdvs">
          <h3>Selecione os pdvs que ficarão visíveis</h3>
          <div>
            <v-checkbox
              class="pdvs-checkbox"
              v-for="pdv of pdvs" :key="pdv.idpdv"
              v-model="pdvsSelecionados"
              :label="pdv.descpdv"
              :value="pdv.idpdv"
              color="primary"
            ></v-checkbox>
          </div>
        </div>

        <div class="input-container">
          <label for="url">Mesa</label>
          <input v-model="mesa" id="url" type="text" />
        </div>

      <div class="input-container">
        <label for="url">Mensagem</label>
        <input v-model="mensagem" id="url" type="text" />
      </div>

        <div class="input-container">
          <label for="color">Cor:</label>
          <input v-model="color" id="color" type="color" />
        </div>

        <div class="input-container">
          <label for="size">Tamanho:</label>
          <input v-model="size" id="size" type="range" min="100" max="300" />
        </div>

        <button @click="downloadQRCode">Baixar QR Code</button>

        </div>

        <div ref="imgDownload" class="img-download">
          <h1 v-if="mesa" :style="'color: ' + color">Mesa: {{mesa}}</h1>

          <p :style="'color: ' + color">{{mensagem}}</p>

          <div class="qrcode">
            <qrcode-vue :value="url" :size="size" level="H" :foreground="color"></qrcode-vue>
          </div>
        </div>
    </v-card>
  </template>

  <script>
  import QrcodeVue from 'qrcode.vue'
  import html2canvas from 'html2canvas';

    export default {
      name: 'QRCodeRestaurante',
      components: {
        QrcodeVue
      },
      props:[
        'carregando'
      ],
      async mounted(){
        await this.getPDVS();
      },
      data: () => ({
        mesa: "",
        url: "",
        color: "#000000",
        mensagem: "",

        size: 300,

        pdvs: [],
        pdvsSelecionados: [],
      }),
      methods:{
        downloadQRCode() {
          const divToConvert = this.$refs.imgDownload;
          html2canvas(divToConvert, {
            backgroundColor: null,
          }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${this.mesa}-qrcode.png`;
            link.click();
          });
        },
        downloadQRCode2() {
          const qrCode = document.querySelector('canvas');
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          const text = this.mesa
          const size = this.size

          canvas.width = size
          canvas.height = size + 100

          ctx.fillStyle = '#fff'
          ctx.font = 'bold 50px sans-serif'
          ctx.textAlign = 'center'

          ctx.fillText(text, size / 2, size + 50)

          ctx.drawImage(qrCode, 0, 0, size, size)

          const link = document.createElement('a')
          link.download = `${this.mesa}-qrcode.png`
          link.href = canvas.toDataURL()
          link.click()
        },
        async getPDVS(){
          this.$emit('setCarregando', true);
          await this.$http('pdv/getPDVSCD/0').then(async res => {
            this.pdvs = res.data;
            this.pdvsSelecionados = [this.pdvs[0].idpdv];

            this.montarURL(this.pdvsSelecionados, this.mesa);
          }).catch((error)=>{
            this.$alertar('warning',error,'=(')
          })
          this.$emit('setCarregando', false);
        },
        montarURL(pdvs, mesa) {
          let url = `${window.location.origin}/loginpdvmesa?pdvs=${pdvs.join(',')}`;

          if(mesa) {
            const newMesa = encodeURIComponent(mesa);
            url = `${url}&mesa=${newMesa}`;
          }

          this.url = url;
          console.log(this.url);
        }
      },
      watch:{
        mesa: function(val) {
          if(isNaN(val)) {
            this.$alertar("warning","Por favor digite apenas caracteres numéricos","=(");
            val = "1";
            this.mesa = val;
          }

          this.montarURL(this.pdvsSelecionados, this.mesa);
        },
        pdvsSelecionados: function(val) {
          if(val.length < 1) {
            this.$alertar("warning","Por favor selecione ao menos um pdv","=(");
            this.pdvsSelecionados = [this.pdvs[0].idpdv];
            val = this.pdvsSelecionados;
          }

          this.montarURL(this.pdvsSelecionados, this.mesa);
        },
      },
    }
  </script>

  <style scoped>

  .img-download {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
  }

  .img-download h1 {
    font-size: 2rem;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }

  .qrcode-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    padding: 2rem;
    border-radius: 8px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .input-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  label {
    font-weight: bold;
  }

  input[type="text"],
  input[type="color"],
  input[type="range"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.25rem;
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #0056b3;
  }

  .pdvs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .pdvs > div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
  }

  .pdvs-checkbox {
    padding: 0;
    margin-right: 10px;
  }
  </style>
