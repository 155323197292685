<template>
  <div class="estiloColuna">
      <div class="topoColuna">
        <Subtitulo style="max-width: 90%; width: 90%;" :subtitulo="'Configurações'" :corTexto="'#fff'" />
        <!-- <BotaoTutorial style="max-width: 10%;width: 10%;margin-right: 5%;align-items:flex-end;display:flex;justify-content: flex-end;" categoria="Configurações" legenda="Dúvidas" urlVideo="https://www.youtube.com/embed/n5rvW4Wzc0M" /> -->
      </div>
      <ListaConfiguracoes  corTexto="#55ad94" @setarTela="(tela) => setarTela(tela)" />
  </div>
</template>

<script>
// import BotaoTutorial from './BotaoTutorial.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  import ListaConfiguracoes from '../../components/layout/ListaConfiguracoes.vue'
  export default {
    name: 'ColunaEsquerda',
    components: {
    ListaConfiguracoes,
    Subtitulo,
},
    props:["url","ojbPedidos"],
    async mounted(){
      
    },
    data: () => ({
      
    }),
    methods:{
        direcionar(){
            this.$router.push(this.url)
        },
        setarTela(tela){
          this.$emit('setarTela', tela)
        }
        
    }
  }
</script>

<style scoped>
    .estiloColuna{
        background-color: white;
        /* padding-top:10px; */
        width: 28%!important;
        margin-right:2%!important;
        min-height: 85vh;
        max-height: 85vh;
        border:1px solid rgb(156, 156, 156);
        border-radius:5px;
        overflow: hidden;
        
    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color:#1976D2;
      display:flex;
      align-items: center;
    }
</style>