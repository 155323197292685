<template>
    <v-dialog v-model="exibirModalCriarCategoria" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Criar Nova Categoria
                    </span>
                    <span style="width:5%;"> 
                        <v-btn data-cy="fechar-criar-categoria" x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <div>
                             <v-container class="">
                                <v-row>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Nome <span style="color:red;">(Máx. 50 Cararcteres)</span></h3>
                                            <input maxlength="50" data-cy="input-nome" type="text" v-model="categoria.nomecategoriaprodcd" placeholder="Digite o nome da Categoria" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                        </div>
                                  </v-col>
                                  <v-col>
                                        <div style="padding-bottom:10px;">
                                            <h3>Tempo de Preparo em minutos</h3>
                                            <input type="number" data-cy="input-preparo" v-model="categoria.tempopreparo" placeholder="Digite o Tempo de Preparo em minutos"  style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                                        </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                        </div>

                        <div>
                             <v-container class="">
                                <v-row>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="categoria.flbebida" data-cy="switch-bebida"> </v-switch>
                                                <label>É Bebida?</label>
                                            </div>
                                    </v-col>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="categoria.liberarautomatico" data-cy="switch-automatica"> </v-switch>
                                                <label>Liberação Automatica para o Garçom?</label>
                                            </div>
                                    </v-col>

                                  <v-col v-if="fluxoImpressao==2">
                                        <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;">
                                            <label>Impressora</label>
                                            <v-select  v-model="impressora" :items="listadeimpressoras" 
                                                item-text="nome" item-value="ip" return-object dense outlined 
                                                style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                            </v-select>
                                        </div>
                                  </v-col>

                                </v-row>
                              </v-container>
                        </div>

                        <div style="padding-bottom:10px;">
                            
                            <h3>Disponibilidade</h3>
                            <v-btn data-cy="adicionar-horario-disponibilidade" small elevation="" color="primary" @click="adicionarDisponibilidade()">Adicionar Disponibilidade</v-btn>


                            <div>
                                 <v-container class="">
                                    <v-row>
                                      <v-col><v-btn data-cy="disp-seg" @click="setDia('seg')" small elevation="" :color="seg?'success':'default'">Seg</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-ter" @click="setDia('ter')" small elevation="" :color="ter?'success':'default'">Ter</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-qua" @click="setDia('qua')" small elevation="" :color="qua?'success':'default'">Qua</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-qui" @click="setDia('qui')" small elevation="" :color="qui?'success':'default'">Qui</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-sex" @click="setDia('sex')" small elevation="" :color="sex?'success':'default'">Sex</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-sab" @click="setDia('sab')" small elevation="" :color="sab?'success':'default'">Sab</v-btn></v-col>
                                      <v-col><v-btn data-cy="disp-dom" @click="setDia('dom')" small elevation="" :color="dom?'success':'default'">Dom</v-btn></v-col>
                                    </v-row>
                                  </v-container>
                            </div>
                            
                            <div style="display:flex;flex-wrap:wrap;">

                                <div v-for="horario in listaHorarios" :key="horario.id" style="display:flex;align-items:flex-end;width:25%;margin-top:10px;">
                                    <div style="border:1px solid #bdbaba;display:flex;flex-direction:column;align-items:flex-end;padding:5px;border-radius:10px;">
                                        <div style="display:flex;align-items:flex-end;padding:5px;border-radius:10px;">
                                            <datetime class="inputsHorario" data-cy="input-horainicio" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}" style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" 
                                                v-model="horario.horainicial" >
                                            </datetime>
                                            <span style="margin-right:10px;" >até</span>
                                            <datetime class="inputsHorario" data-cy="input-horafim" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}"  style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" 
                                                v-model="horario.horafinal" >
                                            </datetime>
                                        </div>
                                        <div>
                                            <a @click="removerDisponibilidade(horario)">excluir</a>
                                        </div>
                                    </div>
                                    
                                </div>

                                

                            </div>

                        </div>
                        
                        <!-- <div style="padding-bottom:10px;">
                            <h3>Disponibilidade</h3>
                            <div style="display:flex;align-items:flex-end;">
                                <datetime class="inputsHorario" value-zone="UTC-3" data-cy="input-horainicio" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="categoria.horainicio" >
                                </datetime>
                                <span style="margin-right:10px;" >até</span>
                                <datetime class="inputsHorario" value-zone="UTC-3" data-cy="input-horafim" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="categoria.horafinal" >
                                </datetime>
                            </div>
                            
                        </div> -->

                        
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" @click="criarCategoria()" data-cy="criarCategoria">
                            SALVAR
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>
    </v-dialog>
</template> 

<script>
import dayjs from 'dayjs'

  export default {
    name: 'BotaoVoltar',
    props:["exibirModalCriarCategoria","idpdvcd"],
    data: () => ({
        categoria:{
            id:null,
            nome:null,
            tempopreparo:40,
            horainicio:'00:00:00',
            horafinal:'23:59:00',
            ativo:true,
            flbebida:false,
            liberarautomatico:false,
            
        },
        listaHorarios:[],
        seg:false,
        ter:false,
        qua:false,
        qui:false,
        sex:false,
        sab:false,
        dom:false,
        listadeimpressoras:[],
        impressora:null,
        fluxoImpressao:null,
    }),
    async mounted(){

        await this.getFluxoImpressao()

        this.$http.post('pdv/getImpressoras',{ativo:true}).then(resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listadeimpressoras = resp.data
                    this.impressora = {nome:resp.data[0].nome, ip:resp.data[0].ip, id:resp.data[0].id}
                }
            }).catch(error =>{
                alert(error)
            })

    },
    methods:{

        async getFluxoImpressao(){ 

            await this.$http.post('/pdv/buscarparametros', { chave:'FLUXOIMPRESSAO' }).then(async resp=>{ 
 
                            

                            if(resp.data && resp.data.length>0){
                                this.fluxoImpressao = resp.data[0].valor
                                // alert( 'asa '+this.fluxoImpressao )
                            }

                        }).catch(() =>{

                            return {}

                        })

        },

        setDia(dia){
            if(dia === 'seg'){ this.seg = !this.seg}
            if(dia === 'ter'){ this.ter = !this.ter}
            if(dia === 'qua'){ this.qua = !this.qua}
            if(dia === 'qui'){ this.qui = !this.qui}
            if(dia === 'sex'){ this.sex = !this.sex}
            if(dia === 'sab'){ this.sab = !this.sab}
            if(dia === 'dom'){ this.dom = !this.dom}
        },
        removerDisponibilidade(horario){
            let indice = this.listaHorarios.indexOf(horario)
            if(indice > -1){
                this.listaHorarios.splice(indice,1)
            }
        },
        adicionarDisponibilidade(){
            this.listaHorarios.push({tipo:2,horainicial:'00:00:00',horafinal:'23:50:00'})
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        validarDados(obj){
            if(!obj.idpdv){
                this.$alertar("warning","PDV não Selecionado","=(")
                return false
            }

            if(!obj.nome){
                this.$alertar("warning","Nome não Informado!","=(")
                return false
            }

            if(!obj.tempopreparo){
                this.$alertar("warning","Tempo de Preparo não Informado!","=(")
                return false
            }

            if(!obj.horainicio){
                this.$alertar("warning","Horário Inicial não Informado!","=(")
                return false
            }

            if(!obj.horafinal){
                this.$alertar("warning","Horário Final não Informado!","=(")
                return false
            }

            return true
        },
        criarCategoria(){
            
            let obj = {
                idpdv:this.idpdvcd,
                nome:this.categoria.nomecategoriaprodcd,
                tempopreparo:this.categoria.tempopreparo,
                horainicio:dayjs(this.categoria.horainicio).format('HH:mm:ss'),
                horafinal:dayjs(this.categoria.horafinal).format('HH:mm:ss'),
                flbebida:this.categoria.flbebida,
                liberarautomatico:this.categoria.liberarautomatico,
                listaHorarios:this.listaHorarios,
                seg:this.seg,
                ter:this.ter,
                qua:this.qua,
                qui:this.qui,
                sex:this.sex,
                sab:this.sab,
                dom:this.dom
            }

            if(this.impressora && this.impressora.id){
                obj.idimpressora = this.impressora.id
            }

            if(this.validarDados(obj)){
                this.$http.post(`pdv/categorias`,obj).then(() =>{
                    this.$alertar("success","Categoria Criada Com sucesso!","=)")
                    this.$emit('atualizarLista')
                }).catch((error) =>{
                    this.$alertar("warning","Não foi possivel atualizar a categoria","=(")
                    console.log(error)
                })
            }
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

