<template>
    <div>

    <v-dialog v-model="exibirModalItensComanda " width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Itens

                    </span>
                    <!-- <v-btn small elevation="" style="margin-right:20px;" color="warning" @click="imprimirComanda()">Fechar Comanda</v-btn> -->
                    <v-btn 
                        v-if="pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PS' && pedido.id_trx_pagto_maquininha && pedido.id_trx_pagto_maquininha.trim() != ''"
                        small elevation="" style="margin-right:20px;" color="" @click="() => exibirPagamentos(pedido)">
                        Ver Pagamento
                    </v-btn>
                    <v-btn small elevation="" style="margin-right:20px;" color="" @click="() => logs.exibir = true">Logs</v-btn>
                    <v-btn small elevation="" style="margin-right:20px;" color="" @click="imprimirComanda()">Imprimir</v-btn>
                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="fechar()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <!-- {{listaItens}} -->
            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                       <v-data-table v-if="listaItens"
                        :headers="headers"
                        :items="listaItens"
                        >

                                <template v-slot:[`item.precounitario`]="{ item }">
                                    {{item.precounitario | formatarMoeda}}
                                </template>

                                <template v-slot:[`item.valortotal`]="{ item }">
                                    {{item.valortotal | formatarMoeda}}
                                </template>

                                <template v-slot:[`item.idcomandaproduto`]="{ item }" >
                                      <v-btn v-if="$store.getters.get_idperfil != 5 && pedido.idstatus != 5" small elevation="" color="error" @click="setModalRemocaoItem(item)">
                                          <v-icon color="">mdi-delete</v-icon>
                                      </v-btn>
                                       <div v-else>
                                      </div>
                                </template>



                        </v-data-table>
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <b>TOTAL</b>: {{totalComanda | formatarMoeda}}
                    </div>


                    <!-- UHS DE PASSANTES CADASTRADOS NO SISTEMAS, AVULSOS, SÃO INICIADAS COM 'PS' -->
                    <!-- {{pedido}} -->
                    <div style="display:flex;flex-direction:row;align-items:space-between;justify-content:space-between;"
                     >
                        <v-btn v-if="pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PS' && pedido.comandaaberta != 'Fechada'"
                         small elevation="" color="primary"   style="width:40%;height:7vh"
                                                 data-cy="btn-sim" @click="setexibirModalAddPagtoBaixar()">
                                                Adicionar Pagamento e Baixar
                                                </v-btn>

                        <!-- && pedido.comandaaberta == 'Fechada' -->
                        <v-btn  
                          v-if="1==1 && pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PS' && pedido.id_trx_pagto_maquininha && pedido.id_trx_pagto_maquininha.trim() != '' "
                          small elevation="" color="error"   style="width:40%;height:7vh"
                                                 data-cy="btn-sim" @click="setexibirModalCancelarPagtoMaquininha()">
                                                Cancelar Pagto Maquininha
                                                </v-btn>

                    </div>
                    
                    
                    <div v-if="pedido && pedido.coduh && pedido.coduh.length > 2 && pedido.coduh.substring(0,2) == 'PSs' && pedido.comandaaberta != 'Fechada'">


                          <div style="border:1px solid #cbcbcb;margin-top:10px;display:flex;" >
                            <!-- {{this.pedido.idstatus}} -->
                            <div style="width:30%;padding:20px;">
                                <v-select return-object color v-model="cobrartaxas" :items="listacobrartaxas" @change="setValorFinalFechar()"  item-text="nome" item-value="id" label="Cobrar Taxas"></v-select>
                            </div>
                            <div style="width:30%;padding:20px;">
                                <v-select color v-model="tipoPagamentoSelecionado" :items="listaTiposPagamentos" item-text="label" item-value="value" label="Tipo Pagamento"></v-select>
                            </div>

                            <div style="width:40%;padding:20px;">
                                <v-text-field type="number" name="name" label="NSU" id="nsu" v-model="nsu" ></v-text-field>
                            </div>

                          </div>

                          <div style="border:1px solid #cbcbcb;margin-top:10px;display:flex;">
                              <div style="width:30%;padding:20px;">
                                  <b>Valor Total:</b>{{totalComanda | formatarMoeda}}
                              </div>
                              <div style="width:30%;padding:20px;">
                                  <b>Valor Taxas:</b>{{(totalComanda - totalComandaSemTaxa) | formatarMoeda}}
                              </div>
                              <div style="width:40%;padding:20px;background-color:#2196F3;color:white;font-weight:bold;font-size:20px;">
                                  Valor Final:{{valorFecharComanda | formatarMoeda}}
                              </div>
                          </div>

                          <div v-if="pedido.idstatus == 5" style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                              <v-btn color="error" @click="enviarPagamento()" :disabled="tipoPagamentoSelecionado  && tipoPagamentoSelecionado > 0?false:true">
                                  FECHAR COMANDA
                              </v-btn>
                          </div>
                    </div>

                </v-card>



            </v-card-text>


        </v-card>




    </v-dialog>



    <v-dialog v-model="exibirModalPagamentos" width="1000" max-width="80vw">

        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                       Pagamentos
                    </span>

                    <span style="width:5%;">
                        <v-btn x-small color="red" @click="fecharModalPagamentos()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">

                    <div style="width:100%;">
                       <v-data-table v-if="listaDePagamentos" :headers="headersPagamentos" :items="listaDePagamentos" >

                        <template v-slot:[`item.created_at`]="{ item }" >
                                {{item.created_at | formatarDataHora}}
                        </template>
                        
                        <template v-slot:[`item.amount`]="{ item }" >
                                {{(parseInt(item.amount)/100)}}
                        </template>
                        
                        <template v-slot:[`item.closed`]="{ item }" >
                                    <v-btn v-if="item.id" small elevation="" color="error" @click="setexibirModalCancelarPagtoMaquininha()" >
                                         Cancelar
                                    </v-btn>                                
                        </template>

                        </v-data-table>
                    </div>

                </v-card>

            </v-card-text>

        </v-card>

    </v-dialog>

    <ModalLogs
        :logs="logs.data"
        :exibirModalLogs="logs.exibir"
        @fechar="() => logs.exibir = false"
    />

    <ModalConfirmacao :showModal="exibirModalConfirmacaoRemocao" :titulo="'Confirma Remoção do Item?'"
        :textonao="'Cancelar'"   @nao="()=>setModalRemocaoItem(null)"
        :textosim="'Confirmar'"  @sim="()=>removerItemDaComanda()"
        />

    <ModalConfirmacao :showModal="exibirModalCancelarPagtoMaquininha" :titulo="'Confirma Cancelamento do Pagto Maquininha?'"
        :textonao="'Cancelar'"   @nao="()=>setexibirModalCancelarPagtoMaquininha(null)"
        :textosim="'Confirmar'"  @sim="()=>cancelarPagtoMaquininha()"
        />

    <ModalAddPagtoPassante v-if="exibirModalAddPagtoBaixar" :exibirModalAddPagtoBaixar="exibirModalAddPagtoBaixar"
            :pedido="pedido"
            @fechar="()=>setexibirModalAddPagtoBaixar()" />

    </div>
</template>

<script>

  import ModalConfirmacao from '../ModalConfirmacao.vue'
  import ModalAddPagtoPassante from './ModalAddPagtoPassante.vue'
  import ModalLogs from './ModalLogs.vue'

  export default {
    name: 'BotaoVoltar',
    props:["exibirModalItensComanda","pedido"],
    components:{
        ModalConfirmacao,
        ModalAddPagtoPassante,
        ModalLogs,
    },
    data: () => ({
        exibirModalPagamentos:false,
        exibirModalAddPagtoBaixar:false,
        exibirModalCancelarPagtoMaquininha:false,
        valordesconto:0,
        nsu:null,
        valorFecharComanda:null,
        itemRemocaoSelecionado:null,
        exibirModalConfirmacaoRemocao:false,
        listaItens:null,
        listaDePagamentos:null,
        headersPagamentos:[
            { text: 'Id Transação', value: 'id' },
            { text: 'Status', value: 'status' },
            { text: 'Data', value: 'created_at' },
            { text: 'Valor', value: 'amount' },
            { text: 'cancelar', value: 'closed' },
        ],
        listaTiposPagamentos:[],
        cobrartaxas:{nome:'SIM',id:1},
        listacobrartaxas:[{nome:'SIM',id:1},{nome:'NAO',id:2}],
        headers:[
                    { text: 'Comanda', value: 'idcomanda' },
                    { text: 'Reserva', value: 'numreserva' },
                    { text: 'Local', value: 'idtipolocal' },
                    { text: 'Num', value: 'numerolocal' },
                    { text: 'Produto', value: 'nomecd' },
                    { text: 'Preço Un.', value: 'precounitario' },
                    { text: 'Qtde', value: 'quantidade' },
                    { text: 'Total', value: 'valortotal' },
                    { text: 'Acao', value: 'idcomandaproduto' },
                ],
        pedidoSelecionado:null,
        totalComanda:0,
        totalComandaSemTaxa:0,
        logs: {
            exibir: false,
            data: []
        }
    }),
    async mounted(){
      this.listarTiposPagamentos()
      console.log('pedidos da modal', this.pedido)
        let retorno = await this.getProdutosdoPedido(this.pedido.numpedido)
        console.log(' retorno >>> ',retorno)
        this.pedido.observacaogerais = retorno.observacaogerais
        this.logs.data = await this.getLogsComandaHITS(this.pedido.numpedido)
        this.listaItens = retorno.listadepedidos
        this.totalComanda = 0
        for(let i=0,len=this.listaItens.length;i< len; i++){

            if(this.listaItens[i].valortaxa > 0){
                  let valorComTaxa = (parseFloat(this.listaItens[i].precounitario)*parseFloat(this.listaItens[i].quantidade))
                  valorComTaxa = valorComTaxa +(valorComTaxa  * (this.listaItens[i].valortaxa/100) )
                  this.totalComanda += valorComTaxa
                  this.totalComandaSemTaxa += (parseFloat(this.listaItens[i].precounitario)*parseFloat(this.listaItens[i].quantidade))
            }else{
              this.totalComanda += (parseFloat(this.listaItens[i].precounitario)*parseFloat(this.listaItens[i].quantidade))
              this.totalComandaSemTaxa += (parseFloat(this.listaItens[i].precounitario)*parseFloat(this.listaItens[i].quantidade))
            }

        }

        this.setValorFinalFechar()

    },
    watch:{
        async pedido(){
            let retorno = await this.getProdutosdoPedido(this.pedido.numpedido)
            this.listaItens = retorno.listadepedidos

        }
    },
    methods:{
        async setexibirModalAddPagtoBaixar(){
            this.exibirModalAddPagtoBaixar = !this.exibirModalAddPagtoBaixar
        },
        async setexibirModalCancelarPagtoMaquininha(){
            this.exibirModalCancelarPagtoMaquininha = !this.exibirModalCancelarPagtoMaquininha
        },
        setValorFinalFechar(){

            if(this.cobrartaxas.id == 1){
              this.valorFecharComanda = this.totalComanda
            }else{
              this.valorFecharComanda = this.totalComandaSemTaxa
            }
        },
        enviarPagamento(){

           let obj = {
                      numpedido:this.pedido.numpedido,
                      tipopagamento:this.tipoPagamentoSelecionado,
                      valorpagamento:( this.cobrartaxas.id == 1 ? this.totalComanda: this.totalComandaSemTaxa ),
                      cobrartaxa:this.cobrartaxas.id,
                      nsu:this.nsu
                    }

            this.$http.post(`/pdv/enviarPagtoPassante`,obj).then(resp =>{
                if(resp.status == 200){
                    this.fecharComanda()
                }
            }).catch(error =>console.log('ERRO RETNORO ',error))
        },
        fecharComanda(){

          const dados = {
            id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
            web: true,
          }

            this.$http.post(`/pdv/alterarStatusPedidoEProdutos/${this.pedido.numpedido}/5/f`, dados).then(resp =>{
                this.listaTiposPagamentos = resp.data.tipospagamentos
                this.fechar()
            }).catch(error =>console.log('ERRO RETNORO ',error))
        },
        listarTiposPagamentos(){

            this.$http.post('/pdv/getTiposPagamentos').then(resp =>{
                this.listaTiposPagamentos = resp.data.tipospagamentos
            }).catch(error =>console.log('ERRO RETNORO ',error))
        },


        cancelarPagtoMaquininha(){
            alert('remover')
            console.log(JSON.stringify(this.pedido))
            this.$http.post("stone/cancelarPagamentoMaquininha",{dados:this.pedido})
            .then(resp =>{
                console.log('resp ',resp)
            })
            .catch(error =>{
                console.log(error)
            })
        },

        removerItemDaComanda(){

          this.$http.post("pdv/removerItemDaComanda",{dados:this.itemRemocaoSelecionado})
            .then(()=>{
                let listaDeItensFiltrados = this.listaItens.filter(x =>{
                    return x.idcomandaproduto != this.itemRemocaoSelecionado.idcomandaproduto
                })
                this.listaItens = listaDeItensFiltrados
            })
            .catch(error => {
                console.log(error)
            })

        },
        setModalRemocaoItem(item){
          this.itemRemocaoSelecionado= item
          this.exibirModalConfirmacaoRemocao = !this.exibirModalConfirmacaoRemocao
        },
        async exibirPagamentos(pedido) {
            // this.exibirModalPagamentos = true
            let url = `stone/listarPagamentosMaquininha`
            await this.$http.post(url,pedido)
            .then((resp) =>{
                console.log('resp ',resp)
                this.listaDePagamentos = [resp.data] 
                this.exibirModalPagamentos = true
            }).catch(error =>{
                console.log(error)
            })
            

        },
        imprimirComanda() {
            // this.showModal = true
            let objPedido = this.pedido
            objPedido.itens = this.listaItens
            this.$emit('imprimir',objPedido)
            // setTimeout(() => this.$htmlToPaper('modalImpressao'), 1000);
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        fecharModalPagamentos(){
            this.exibirModalPagamentos = false
        },
        async getProdutosdoPedido(numpedido){
            let objetoRetorno = {}
            const url = `pdv/getProdutosdoPedido/${numpedido}/t/2,3,4,5,6,7,8,9,10,11`
            await this.$http(url).then((resp) =>{
              console.log('pdv/getProdutosdoPedido ',resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.numpedido = resp.data[0].idcomanda
              objetoRetorno.datafeito = resp.data[0].datahoraabertura
              objetoRetorno.datarecebido = resp.data[0].datahoraabertura
              objetoRetorno.imgassinatura = resp.data[0].imgassinatura
              objetoRetorno.idtipolocal = resp.data[0].idtipolocal
              objetoRetorno.numerolocal = resp.data[0].numerolocal
              objetoRetorno.numreserva = resp.data[0].numreserva
              objetoRetorno.coduh = resp.data[0].coduh
              objetoRetorno.nome = resp.data[0].nome
              objetoRetorno.sobrenome = resp.data[0].sobrenome
              objetoRetorno.nomegarcom = resp.data[0].nomegarcom
              objetoRetorno.observacaogerais = resp.data[0].observacaogerais
              objetoRetorno.listadepedidos = resp.data
              for(var i = 0, len = resp.data.length; i < len; i++){
                  objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
              }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })
            console.log(">>>>>> objetoRetorno",objetoRetorno.listadepedidos)
            return objetoRetorno
        },
        async getLogsComandaHITS(idcomanda) {
            const url = `pdv/logHITSComandas/${idcomanda}`
            const response = await this.$http(url)
            .then((res) => res.data )
            .catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return response
        },
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

