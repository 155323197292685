<template>
    
    <v-card  elevation="2" outlined  style="padding-bottom:0px!important;height:10%;max-height:10%;margin-top:1%;" >
        <v-card-text>
            <div :class="'d-flex align-center item '+(pedido.selecionado? 'selecionado':'') " style="margin-bottom:0px;" >
                    
                    <div class="colB" @click="setarPedido()" style="">
                        <div class="colBItemTitle">
                            <div style="width:100%;height:40%;">
                                <div style="display:flex;height:40%;">
                                    <!-- <div style="width:50%;display:flex;align-items:center;" >ID: {{pedido.numpedido }} -- {{pedido.selecionado}}</div> -->
                                    <div style="width:100%;display:flex;align-items:space-between;justify-content:space-between;" >
                                        <div>ID: {{pedido.numpedido }}</div>
                                        <div style="">UH:{{pedido.coduh}}</div>
                                          
                                    </div>
                                    <!-- <div style="width:100%;display:flex;align-items:space-between;justify-content:space-between;" >
                                        <div>{{pedido.hospede[0].nome }}</div>                                   
                                    </div> -->
                                    <div style="width:50%;padding-right:3%;display:flex;justify-content:flex-end;" >
                                        <!-- <div style="border-radius:10px;background-color:#ff0005;width:70%;color:white;font-size:15px;display:flex;align-items:center;justify-content:center;"> -->
                                        <!-- <div :style="'border-radius:10px;'+(pedido.atrasado?'background-color:#ff0005;color:white;':'background-color:#fff;color:black;')+'width:70%;font-size:15px;display:flex;align-items:center;justify-content:center;'">
                                            <div style="width:40%;display:flex;align-items:flex-start;justify-content:flex-start;"><v-icon right :dark="pedido.atrasado?true:false" > mdi-clock </v-icon></div>
                                            <div style="width:60%;display:flex;align-items:flex-start;justify-content:flex-start;">15:30</div>
                                        </div>     -->
                                    </div>
                                </div>
                                <div data-cy="nome-hospede" style="margin-top:5px;" ><span style="font-weight:bold;">Nome:</span> {{pedido.hospede[0].nome }}</div>
                                <div style="margin-top:5px;color:blue;font-weight:bold;" v-if="pedido.hospede[0].passante === true" >PASSANTE</div>
                                <div style="margin-top:5px;" >Data: {{pedido.datahoraabertura | formatarDataHora}}</div>
                            </div>
                        </div>
                        
                        <div  class="colBItemPrice" style="height:30%!important;">
                            <div style=";"> {{pedido.statuscomanda }}</div>
                            <div style=";"> {{pedido.valor | formatarMoeda}}</div>
                        </div>

                    </div>
                        

                    <!-- <ModalConfirmacao :showModal="exibirModalConfirmacaoProd" :titulo="`Remover ${produto.descproduto} do Carrinho?`"
                        textosim="Remover" textonao="Cancelar" @sim="()=>confirmarRetiradaDoCarrinho()" @nao="()=>fecharModal()"  /> -->
            </div>
            
        </v-card-text>
            
            
    </v-card> 



    

    
    
    

</template>

<script>
// import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
export default {
    props:["pedido",'corTexto','index'],
    components: {
    //   ModalConfirmacao,
    },
    data: () => ({
        // exibirModalConfirmacaoProd:false,
    }),
    methods:{
        setarPedido(){
            
            this.$emit('setarPedido', this.pedido)
        }
        // retirarDoCarrinho(){
        //     this.exibirModalConfirmacaoProd = true
        // },
        // fecharModal(){
        //     this.exibirModalConfirmacaoProd = false
        // },
        // confirmarRetiradaDoCarrinho(){
        //     this.exibirModalConfirmacaoProd = false
        //     this.$emit('retirarProdutoCarrinho',this.produto)
        // }
    }
}
</script>

<style scoped>
    .item{
        font-size:1.2em;
        color:rgb(70, 70, 70);
        /* background-color: pink; */
        /* height: 120px; */
        width:330px;
        max-width:100%;
        /* margin-bottom: 2%; */
        /* margin-right:2%; */
        /* border: 1px solid rgb(156, 156, 156);
        border-radius: 10px; */
        /* padding:5px; */
    }

    .item .colB{
        /* background-color:Blue; */
        width: 100%;
        padding-left:2%; 
        height:100%;
        word-break: break-all;
    }

    .item .colB .colBItemTitle{
        /* background-color:red; */
        font-size:18px;
        line-height: 1.1;
        height: 50%;
        display: flex;
    }

    .colBItemPrice{
        /* background-color:Blue; */
        display: flex;
        font-size:18px;
        /* line-height: 1.1; */
        height: 50%;
        /* padding-bottom:10%; */
        /* padding-right: 10px; */
        justify-content: space-between;
        font-weight: bold;
        align-items: flex-end;
    }

    .selecionado{
        background-color:#92b3f5;
    }

</style>