<template>
        <div style="height:100%;display:flex;">
            <CardapioPMSTuor
                :atualizar="atualizarPMS"
                @atualizardados="() => atualizarDados()"
                @atualizarListaItensPdv="(lista) => atualizarlistaItensPdv(lista)"
            />
            <CardapioCDTuor
                :abrirModalEdicaoPDV="abrirModalEdicaoPDV"
                :abrirModalEdicaoCategoria="abrirModalEdicaoCategoria"
                :abrirModalEdicaoProduto="abrirModalEdicaoProduto"
                :atualizar="atualizarCD"
                @atualizardados="() => atualizarDados()"
                @atualizarListaItensPdv="(lista) => atualizarlistaItensPdv(lista)"
                :listaItensProdutoPorPDVPMS="listaItensProdutoPorPDVPMS"
            />
          <v-tour name="cardapio" :steps="steps" :callbacks="callbacksTuor"></v-tour>
        </div>
</template>

<script>
import CardapioPMSTuor from '../../components/cardapio/CardapioPMSTuor.vue'
import CardapioCDTuor from '../../components/cardapio/CardapioCDTuor.vue'

export default {
  name: 'CardapioTuor',
  components:{
    CardapioPMSTuor,
    CardapioCDTuor,
  },                
  data: function() {
    return {
      atualizarPMS: false,
      atualizarCD: false,
      listaItensProdutoPorPDVPMS: null,

      abrirModalEdicaoPDV: false,
      abrirModalEdicaoCategoria: false,
      abrirModalEdicaoProduto: false,

      steps: [
        {
          target: '[data-v-step-cardapio="1"]',
          content: 'Neste lado você consegue vizualizar todos os itens importados do PMS.',
          params: {
            placement: 'right'
          }
        },
        {
          target: '[data-v-step-cardapio="2"]',
          content: 'Você pode facilmente filtrar sua busca por PDV (Departamento) e Categoria do PMS',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-cardapio="3"]',
          content: 'Você pode também filtrar os itens pelo nome do produto',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-cardapio="4"]',
          content: 'Clicando nesse botão, é possivel associar os itens do PMS com a categoria selecionada do Cardápio Digital',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-cardapio="5"]',
          content: 'Clicando aqui é possível editar algumas informaçoes do PDV',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '[data-v-step-cardapio="6"]',
          content: 'Aqui você pode alterar o nome do pdv que será exibido para hóspedes e/ou garçons no cardápio digital',
          params: {
            placement: 'left'
          }
        },
        {
          target: '[data-v-step-cardapio="7"]',
          content: 'É possível desativar/ativar a vizualização do pdv no cardápio digital',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="8"]',
          content: 'Aqui você irá escolher qual impressora os itens desse pdv irão ser impressos, lembrando que a prioridade é a impressora que está registrada na categoria',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="9"]',
          content: 'Você pode tambem alterar a exibição do pdv entre hóspedes e garçons',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="10"]',
          content: 'Aqui você consegue configurar horários e dias de disponibilidade que esse pdv irá ser exibido no cardápio digital para os hóspedes',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="11"]',
          content: 'Após todas as alterações, não esqueça de salvar',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="12"]',
          content: 'Você pode também excluir facilmente um pdv',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="13"]',
          content: 'Também podemos criar um novo PDV, basta clicar aqui',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="14"]',
          content: 'Vamos agora editar uma categoria do cardápio digital',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="15"]',
          content: 'Aqui voce pode selecionar qual a impressora os itens dessa categoria irão sair, lembrando que a prioridade é a impressora que está registrada na categoria',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="16"]',
          content: 'Quando está opção esta ativa, todos os itens dessa categoria irão para a etapa de <strong>Bar</strong>',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="17"]',
          content: 'Quando a opção de integração está ativam significa que os itens dessa categoria irão ser enviados para o PMS, de acordo com a data maxima de integração',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="18"]',
          content: 'Agora vamos editar um produto',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="19"]',
          content: 'Aqui você consgue alterar o nome do produto que será exibido no cardápio digital',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="20"]',
          content: 'Podemos também colocar uma descrição no produto',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step-cardapio="21"]',
          content: 'Clicando "Opcionais por produto" é possível adicionar opcionais ao produto como: "Com Gelo", "Bem passado", etc',
          params: {
            placement: 'top'
          }
        },
      ],
      callbacksTuor: {
        onFinish: this.finalizedTuor,
        onStop: this.finalizedTuor,
        onSkip: this.finalizedTuor,
        onNextStep: (step) => {
          // Edicao PDV
          if(step + 2 === 6) {
            this.abrirModalEdicaoPDV = true
          }

          if(step + 2 === 13) {
            this.abrirModalEdicaoPDV = false
          }


          // Edicao Categoria
          if(step + 3 === 15) {
            this.abrirModalEdicaoCategoria = true
          }

          if(step + 2 === 18) {
            this.abrirModalEdicaoCategoria = false
          }

          // Edicao Produto
          if(step + 2 === 19) {
            this.abrirModalEdicaoProduto = true
          }

          // if(step + 2 === 13) {
          //   this.abrirModalEdicaoCategoria = false
          // }
        },
        onPreviousStep: (step) => {
          // Edicao PDV
          if(step === 12) {
            this.abrirModalEdicaoPDV = true
          }
          if(step === 5) {
            this.abrirModalEdicaoPDV = false
          }

          // Edicao Categoria
          if(step === 17) {
            this.abrirModalEdicaoCategoria = true
          }
          if(step === 7) {
            this.abrirModalEdicaoCategoria = false
          }


          // Edicao Produto
          if(step <= 18) {
            this.abrirModalEdicaoProduto = false
          }
        }

      }

    }
  },
  methods:{
      atualizarDados(){
          this.atualizarPMS = !this.atualizarPMS
          this.atualizarCD  = !this.atualizarCD
      },
      atualizarlistaItensPdv(lista){
          this.listaItensProdutoPorPDVPMS = lista
      },
      finalizedTuor(){
        const tuorLocalStorage = JSON.parse(localStorage.getItem('tuorCardapio')) || {}
        const tuorVizualiado = {...tuorLocalStorage, configuracoes: {cardapio: true}}
        localStorage.setItem('tuorCardapio', JSON.stringify(tuorVizualiado))
        location.replace('/Adm/Cardapio');
      }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Editar Cardápio Exemplo")

    setTimeout(() => {
      this.$tours['cardapio'].start()
    }, 200)
  }


};
</script>
<style scoped>
    
</style>
