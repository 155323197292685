<!--
<template>
  <v-card
    elevation="24"
    max-width="444"
    class="mx-auto"
  >
    <v-system-bar lights-out></v-system-bar>
    <v-carousel
      :continuous="false"
      :cycle="cycle"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="200"
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-sheet
          :color="colors[i]"
          height="100%"
          tile
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <div class="text-h2">
              {{ slide }} Slide
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      
    </v-carousel>
    
  </v-card>
</template>
-->

<template>

  <v-card elevation="24" max-width="444" class="mx-auto" style="width:100%!important;" >
        <v-carousel height="auto"  cycle style="height:200px!important;width:500px!important" class="cara">
            <v-carousel-item
            v-for="(item,i) in items" 
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            
            ></v-carousel-item>
        </v-carousel>
  </v-card>
</template>

<script>
export default {
    props:['items'],
    data: () => ({
      model: 0,
    }),
}
</script>

<style scoped>
   
</style>