<template>
    <div>
        <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
            v-show="carregando"
        >
          <v-skeleton-loader
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-sheet>

        <v-stepper v-model="e1" v-show="!carregando">
          <v-stepper-header>
            <v-stepper-step editable step="1">
              Configurações Email
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
              Comandas não integradas
            </v-stepper-step>

            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <h2>Defina as configurções de envio de e-mail</h2>

              <v-text-field
                v-model="configEmail.host"
                label="Host"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-text-field
                v-model="configEmail.port"
                label="Porta"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-text-field
                v-model="configEmail.nametoexibition"
                label="Nome para exibição"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-text-field
                v-model="configEmail.user"
                label="Email"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-text-field
                v-model="configEmail.pass"
                label="Senha"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-text-field
                v-model="configEmail.replyto"
                label="Email de resposta"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <v-btn color="primary" @click="() => salvarConfiguracoesEmail()">
                Salvar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <h2>Defina quais email receberão os alertas de comandas não integradas</h2>
              
              <div>
                <div v-for="(email, index) in configComandas.emailsReceber" :key="email" style="display: flex; justify-content: center; align-items: center">
                  <v-text-field
                    v-model="email.email"
                    label="Email"
                    outlined
                    dense
                    class="mt-4"
                  ></v-text-field>
                    
                    <div
                      style="display: flex; justify-content: center; align-items: center; margin-left: 1em; background-color: red; color: white; width: 2em; height: 2em; border-radius: 50%; cursor: pointer;"
                     @click="() => removerEmail(index)">
                    <v-icon color="white">mdi-delete</v-icon>
                </div>
                </div>
              </div>


              <div
                style="display: flex; justify-content: space-between; align-items: center;"
              >
                <v-btn color="primary" @click="() => salvarConfiguracoesComandas()">
                  Salvar
                </v-btn>

                <v-btn color="success" @click="() => adicionarEmail()">
                  Adicionar
                </v-btn>
            </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
export default {
  name: "TipoHospedeXPDV",
  components: {},
  props: [],
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  async mounted() {
    this.carregando = true;
    await this.getConfiguracoesEmail();
    await this.getConfiguracoesComandas();
    this.carregando = false;
  },
  watch: {},
  data: () => ({
    carregando: true,
    configEmail: { 
      host: "",
      port: "",
      user: "",
      pass: "",
      replyto: "",
      nametoexibition: "",
    },
    configComandas: {
      emailsReceber: [],
    }
  }),
  methods: {
    async getConfiguracoesEmail() {
      this.carregando = true;
      await this.$http('/pdv/informacoesEmail')
        .then(res => {
          this.carregando = false;
          this.configEmail = res.data[0];
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getConfiguracoesComandas() {
      this.carregando = true;

      await this.$http.post('pdv/buscarparametros', {chave: "EMAILS_RECEBER_COMANDAS"})
        .then((res) => {
          this.carregando = false;
          this.configComandas.emailsReceber = JSON.parse(res.data[0].valor) || [];
        })
        .catch(err => {
          this.$alertar("error","Erro ao buscar emails que receberao alertas de comandas não integradas", "=(")
          console.log(err)
        })
    },
    async salvarConfiguracoesEmail() {
      this.carregando = true;
      await this.$http.post('/pdv/salvarConfiguracaoEmail', this.configEmail)
        .then(() => {
          this.$alertar("success","Configurações de email atualizadas com sucesso", "=)")
          this.carregando = false;
        })
        .catch(err => {
          console.log(err)
          this.carregando = true;
        })
    },
    async salvarConfiguracoesComandas() {
      this.carregando = true;

      const parametro = {
        chave: "EMAILS_RECEBER_COMANDAS",
        valor: JSON.stringify(this.configComandas.emailsReceber)
      }

      this.$http.put('pdv/updateparametros',parametro)
        .then(() => {
          this.$alertar("success","Emails atualizados com sucesso", "=)")
          this.carregando = false;
        })
        .catch(() => {
          this.$alertar("error","Erro ao atualizar emails que receberao alertas de comandas não integradas", "=(")
        })
    },
    removerEmail(index) {
      this.configComandas.emailsReceber.splice(index, 1);
    },
    adicionarEmail() {
      this.configComandas.emailsReceber.push({
        email: "",
      })
    }
  },
};
</script>

<style scoped></style>
