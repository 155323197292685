<template>
  <v-data-table :headers="cabecalhosTurno" :items="comissao.turnos">
    <template v-slot:[`item.turno`]="{ item }">
      <v-text-field
        :ref="`turno_${item.id_comissao_turno}`"
        v-model="editedItem.turno"
        :hide-details="true"
        dense
        single-line
        v-if="item.id_comissao_turno === editedItem.id_comissao_turno"
      ></v-text-field>
      <span v-else>{{ item.turno }}</span>
    </template>

    <template v-slot:[`item.colaboradores`]="{ item }">
      <v-text-field
        :ref="`colaboradores_${item.id_comissao_colaboradores}`"
        v-model="editedItem.colaboradores"
        :hide-details="true"
        dense
        single-line
        v-if="item.id_comissao_turno === editedItem.id_comissao_turno"
      ></v-text-field>
      <span v-else>{{ item.colaboradores }}</span>
    </template>
    <template v-slot:[`item.percentual_faturamento`]="{ item }">
      <v-text-field
        v-model="editedItem.percentual_faturamento"
        :hide-details="true"
        dense
        single-line
        v-if="item.id_comissao_turno === editedItem.id_comissao_turno"
      ></v-text-field>
      <span v-else>{{ item.percentual_faturamento | formatarPercentual }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div style="display: flex; align-items: center">
        <div v-if="item.id_comissao_turno === editedItem.id_comissao_turno">
          <v-icon color="red" class="mr-3" @click="closeEdit()">
            mdi-window-close
          </v-icon>
          <v-icon color="green" @click="salvarTurno()">
            mdi-content-save
          </v-icon>
        </div>
        <div v-else>
          <v-icon class="light-green" @click="editItem(item)" title="Editar">
            mdi-pencil
          </v-icon>
        </div>
      </div>
    </template>
    <template v-slot:[`footer.page-text`]>
      <v-btn v-if="editedIndex === -1" small elevation="" color="success" @click="novoTurno()">
        + NOVO TURNO
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ComissaoTurno",
  props: {
    comissao: {
      type: Object,
    },
  },
  data: () => ({
    cabecalhosTurno: [
      { text: "Turno", value: "turno" },
      { text: "Colaboradores", value: "colaboradores" },
      { text: "Percentual Faturamento", value: "percentual_faturamento" },
      { text: "Ações", value: "actions" },
    ],
    editedItem: {},
    editedIndex: -1,
    defaultItem: {
        id_comissao_turno: 0,
        id_comissao_regra: 0,
        id_turno: 0,
        turno: `Novo Turno`,
        colaboradores: 0,
        percentual_faturamento: 0,
      }
  }),
  computed: {},
  watch: {},
  methods: {
    novoTurno() {
      this.defaultItem.id_comissao_regra = this.comissao.id_comissao_regra
      this.editedItem = Object.assign({}, this.defaultItem);
      this.comissao.turnos.push(this.editedItem);
      this.editedIndex = this.comissao.turnos.length-1;
      this.$nextTick(() => {
        this.$refs[`turno_${this.editedItem.id_comissao_turno}`].focus()
        //this.$refs.turno_0.select()
      });
    },
    turnosValidos() {
      // Verificar se o total dos percentuais não excede 100%
      let totalPercentual = parseFloat(this.editedItem.percentual_faturamento);
      for (let i = 0; i < this.comissao.turnos.length; i++) {
        if (i === this.editedIndex) {
          continue; // Ignorar a linha editada
        }
        const turnoAtual = this.comissao.turnos[i];
        totalPercentual += parseFloat(turnoAtual.percentual_faturamento);
      }
      return (totalPercentual <= 100.0);
    },
    async salvarTurno() {
      if (!this.turnosValidos()) {
        this.$alertar("warning", `Turno inválido. O total do percentual não pode exceder 100%!`, "=(");
        return
      }
      this.$http
        .post("comissao/turno/salvar", this.editedItem)
        .then(async (res) => {
          console.error(`res.data: ${JSON.stringify(res.data)}`)
          if (this.editedItem.id_comissao_turno === 0) {
            this.editedItem.id_comissao_turno = res.data.id_comissao_turno;
            this.editedItem.id_turno = res.data.id_turno;
          }
          if (this.editedIndex > -1) {
            Object.assign(this.comissao.turnos[this.editedIndex], this.editedItem);
            this.editedIndex = -1;
          }
          this.$alertar("success", "Turno salvo!", "=)");
          this.closeEdit();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
          this.closeEdit();
        });
    },
    closeEdit() {
      setTimeout(() => {
        if (this.editedItem.id_comissao_turno === 0) {
          this.comissao.turnos.pop();
        }
        this.editedItem = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.comissao.turnos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$nextTick(() => this.$refs[`turno_${item.id_comissao_turno}`].focus());
    },
  },
  mounted() {},
};
</script>

<style scoped>
.light-green {
  color: green !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-green:hover {
  background-color: lightgreen !important;
}
.right-input input {
  text-align: right;
}
</style>