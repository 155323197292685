<template>

    <div class="d-flex align-center titleh1">
        <h1>{{text}}</h1>
    </div>

</template>

<script>
export default {
    props:["text"]
}
</script>

<style scoped>
    h1{
        font-size:1.3em;
    }

    .titleh1{
        font-size:1.3em;
        color:rgb(70, 70, 70);
        margin-bottom: 2%;
    }
</style>