<template>
  <v-container style="height: 90%; padding: 0 12px 12px 12px !important;">

    <div class="categoryList" style="margin-top:10px;margin-bottom:20px;">
        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="fluxo" @click="setarTela('fluxo')">
            Fluxo dos Pedidos
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="assinatura" @click="setarTela('assinatura')">
            Assinatura
        </v-btn>
        
        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="mesa" @click="setarTela('mesa')">
            Configurações de Mesas
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="cronograma-garcom" @click="abrirOutraAba('Cronograma')">
            Cronograma Garçons
        </v-btn>
        
        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="garconsxmesas" @click="setarTela('cumim')">
            Cumins
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="allinclusive" @click="setarTela('allinclusive')">
            Configuração All Inclusive
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="pracaxperfil" @click="setarTela('pracaxperfil')">
            Configuração Praça X Perfil
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="pracaxpdv" @click="setarTela('pracaxpdv')">
            Configuração Praça X PDV
        </v-btn> 

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="emailhospedefake" @click="setarTela('emailhospedefake')">
            Configurações Hospede
        </v-btn>        

        <v-btn v-if="tipointegracao == 'HITS' && idusuario == 1" small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="emailhospedefake" @click="setarTela('configuracaohits')">
            Configurações Hits
        </v-btn>        

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="emailhospedefake" @click="setarTela('configrobo')">
            Configuracoes Impressão
        </v-btn>     

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="emailhospedefake" @click="setarTela('email')">
            Email
        </v-btn> 

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="gerador-qr-code" @click="setarTela('gerardorqrcode')">
            Gerador de QRCODE
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="impressoras" @click="setarTela('impressoras')">
            Impressoras
        </v-btn>

         <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="importacao" @click="setarTela('importacao')">
            Importação Cardápio do PMS
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="layoutcomanda" @click="abrirOutraAba('LayoutComanda')">
            Layout Comanda
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="Locais" @click="setarTela('Locais')">
            Locais
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="maquininhas" @click="setarTela('maquininhas')">
            Maquininhas de Pagamentos
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="publicidade" @click="setarTela('publicidade')">
            Publicidade Pagina Bem Vindos
        </v-btn>

        <v-btn v-if="idusuario == 1" small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="fluxo" @click="setarTela('roomservice')">
            Room Service
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="impressoras" @click="setarTela('tipohospedexpdv')">
            Tipo de hospede por PDV
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="vizualizacao-hospede" @click="setarTela('vizualizacaohospede')">
          Vizualização Hospéde
        </v-btn>



        


        



        <!-- <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px"
            data-cy="pagamentosconf" @click="setarTela('pagamentosconf')">
            Pagamentos
        </v-btn>

        <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="gateways" @click="setarTela('gateways')">
            Gateways de Pagamento
        </v-btn>    -->



        <!-- <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" @click="setarTela('consumo')">
            Cartão de Consumo
        </v-btn> -->

        <!-- <v-btn small elevation="" color="primary"   style="width:100%;height:7vh;margin-top:10px" data-cy="garconsxmesas" @click="setarTela('garconsxmesas')">
            Garçons X Mesas
        </v-btn> -->

        <!-- -->


    </div>
  </v-container>
</template>

<script>

//   import PedidoAdm from './PedidoAdm.vue'
  export default {
    name: 'PedidosLista',
    props:['ojbPedidos','statuscomanda','corTexto'],
    components:{
        // TitleH1,
        //   PedidoAdm,
    },
    data: () => ({
      // listadeprodutos:[]
      indexultimoselecionado : null,
      idusuario:null,
      tipointegracao:null,
    }),

    methods:{
        abrirOutraAba(nomepagina){
            const routeData = this.$router.resolve({name: nomepagina});
            window.open(routeData.href, '_blank');
        },
        
        setarTela(tela){

          this.$emit('setarTela', tela)
        }

    },

    mounted(){
    //   this.getProdutos()
        this.idusuario = localStorage.getItem('idusuario')
        this.tipointegracao = localStorage.getItem('tipo_integracao')
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
      overflow-y: scroll;
      max-height:100%;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .categoryList::-webkit-scrollbar {
      display: none;
    }
</style>
