import HomeAdm from '../views/adm/HomeAdm.vue'
import Caixa from '../views/adm/Caixa.vue'
import Cozinha from '../views/adm/Cozinha.vue'
import Garcom from '../views/adm/Garcom.vue'
import Bar from '../views/adm/Bar.vue'
import Baixas from '../views/adm/Baixas.vue'
import Pendentes from '../views/adm/Pendentes.vue'
import ExemploTuor from '../views/adm/Exemplo.vue'
import Hospedes from '../views/adm/Hospedes.vue'
import HospedesExemplo from '../views/adm/HospedesExemplo.vue'
import Cardapio from '../views/adm/Cardapio.vue'
import CardapioTuor from '../views/adm/CardapioExemplo.vue'
import LocalPDV from '../views/adm/LocalPDV.vue'
import ComissaoConfig from '../views/adm/ComissaoConfig.vue'
import Configuracoes from '../views/adm/Configuracoes.vue'
import Cronograma from '../views/adm/Cronograma.vue'
import Tutoriais from '../views/adm/Tutoriais.vue'
import NotificacoesAdm from '../views/adm/NotificacoesAdm.vue'
import Relatorio from '../views/adm/Relatorio.vue'
import CadastroUsuario from '../views/adm/CadastroUsuario.vue'
import Mesas from '../views/adm/Mesas.vue'
import Pagamentos from '../views/adm/Pagamentos.vue'
import FazerPedido from '../views/adm/FazerPedido.vue'
import FazerPedidoTuor from "@/views/adm/FazerPedidoTuor.vue";
import ComissaoRegras from '../views/adm/ComissaoRegras.vue'
import ComissaoMetas from '../views/adm/ComissaoMetas.vue'
import ComissaoProdutos from '../views/adm/ComissaoProdutos.vue'
import LayoutComanda from '../views/adm/LayoutComanda.vue'

const rotas_hospedes = 
  {
    path: '/Adm',name: 'Adm',component: HomeAdm,
    children:[
      {path: '/Adm/Caixa',name: 'Caixa',component: Caixa},
      {path: '/Adm/Cozinha',name: 'Cozinha',component: Cozinha},
      {path: '/Adm/Garcom',name: 'Garcom',component: Garcom},
      {path: '/Adm/Bar',name: 'Bar',component: Bar},
      {path: '/Adm/Baixas',name: 'Baixas',component: Baixas},
      {path: '/Adm/Pendentes',name: 'Pendentes',component: Pendentes},
      {path: '/Adm/ExemploTuor',name: 'ExemploTuor',component: ExemploTuor},
      {path: '/Adm/Hospedes',name: 'Hospedes',component: Hospedes},
      {path: '/Adm/HospedesExemplo',name: 'HospedesExemplo',component: HospedesExemplo},
      {path: '/Adm/Cardapio',name: 'Cardapio',component: Cardapio},
      {path: '/Adm/CardapioTuor',name: 'Cardapio',component: CardapioTuor},
      {path: '/Adm/LocalPDV',name: 'LocalPDV',component: LocalPDV},
      {path: '/Adm/ComissaoConfig', name: 'LocalPDV',component: ComissaoConfig},
      {path: '/Adm/ComissaoRegras', name: 'ComissaoRegras',component: ComissaoRegras},
      {path: '/Adm/ComissaoMetas', name: 'ComissaoMetas',component: ComissaoMetas},
      {path: '/Adm/ComissaoProdutos', name: 'ComissaoProdutos',component: ComissaoProdutos},
      {path: '/Adm/Notificacoes',name: 'Notificacoes',component: NotificacoesAdm},
      {path: '/Adm/Configuracoes',name: 'Configuracoes',component: Configuracoes},
      {path: '/Adm/Cronograma',name: 'Cronograma',component: Cronograma},
      {path: '/Adm/Tutoriais',name: 'Tutoriais',component: Tutoriais},
      {path: '/Adm/Relatorio',name: 'Relatorio',component: Relatorio},
      {path: '/Adm/CadastroUsuario',name: 'Cadastro',component: CadastroUsuario},
      {path: '/Adm/Mesas',name: 'Mesas',component: Mesas},
      {path: '/Adm/Pagamentos',name: 'Pagamentos',component: Pagamentos},
      {path: '/Adm/FazerPedido/:idreservasfront/:idhospede/:idcheckin',name: 'FazerPedido',component: FazerPedido},
      {path: '/Adm/FazerPedidoTuor/:idreservasfront/:idhospede/:idcheckin',name: 'FazerPedidoTuor',component: FazerPedidoTuor},
      {path: '/Adm/LayoutComanda',name: 'LayoutComanda',component: LayoutComanda, meta: { mostrarFAQ: false } },
    ]
  }


export default rotas_hospedes
