import HomeHospede from '../views/hospede/HomeHospede'
import Bemvindo from '../views/hospede/Bemvindo.vue'



import Carrinho from '../views/hospede/Carrinho.vue'
import Pedidos from '../views/hospede/Pedidos.vue'
import PedidosFiltrando from '../views/hospede/PedidosFiltrando.vue'
import ProdutosDoPedido from '../views/hospede/ProdutosDoPedido.vue'
import NotificacoesHosp from '../views/hospede/NotificacoesHosp.vue'
import DadosCartao from '../views/hospede/DadosCartao.vue'
import PagamentoAprovado from '../views/hospede/PagamentoAprovado.vue'
import PagamentoNegado from '../views/hospede/PagamentoNegado.vue'
import Pagar from '../views/hospede/Pagar.vue'
import Comprovantes from '../views/hospede/Comprovantes.vue'
import FotoDocPagamento from '../views/hospede/FotoDocPagamento.vue'
import Selfie from '../views/hospede/Selfie.vue'
import EfetuarPagamento from '../views/hospede/EfetuarPagamento.vue'
import DadosPix from '../views/hospede/DadosPix.vue'

import PDVMesas from '../views/hospede/PDVMesas.vue'
import CategoriaMesa from '../views/hospede/CategoriaMesa.vue';
import SubCategoriasMesa from '../views/hospede/SubCategoriasMesa.vue';
import ProdutosMesa from '../views/hospede/ProdutosMesa.vue'
import ProdutoMesa from '../views/hospede/ProdutoMesa.vue'

import Menu from '../views/hospede/Menu.vue'
import Categorias from '../views/hospede/Categorias.vue'
import SubCategorias from '../views/hospede/SubCategorias.vue'
import Produtos from '../views/hospede/Produtos.vue'
import Produto from '../views/hospede/Produto.vue'

const rotas_hospedes =
  {
    path: '/Hospede',name: 'Cardapio',component: HomeHospede,
    children:[
      {path: '/Hospede/Bemvindo',name: 'BemVindo',component: Bemvindo, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Menu',name: 'Menu',component: Menu, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Notificacoes',name: 'Notificacoes',component: NotificacoesHosp, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Categorias/:idpdv',name: 'Categorias',component: Categorias, meta: { mostrarFAQ: false }},
      {path: '/Hospede/SubCategorias/:idpdv/:idcategoriaprodcd',name: 'SubCategorias',component: SubCategorias, meta: { mostrarFAQ: false }},
      // {path: '/Hospede/MenuLista',MenuLista: 'Menu',component: MenuLista},
      {path: '/Hospede/Produtos/:idpdv/:idcategoriaprodcd/:idsubcategoriaprodcd',name: 'Produtos',component: Produtos, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Produto/:idpdv/:idcategoriaprodcd/:idsubcategoriaprodcd/:idproduto',name: 'Produto',component: Produto, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Carrinho',name: 'Carrinho',component: Carrinho, meta: { mostrarFAQ: false }},
      // {path: '/Hospede/Adicionado',name: 'Adicionado',component: Adicionado},
      // {path: '/Hospede/Pedidos',name: 'Pedidos',component: Pedidos},
      {path: '/Hospede/Pedidosparado',name: 'Pedidos',component: Pedidos, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Pedidos',name: 'Pedidos',component: PedidosFiltrando, meta: { mostrarFAQ: false }},
      {path: '/Hospede/PedidosFiltrando',name: 'PedidosFiltrando',component: PedidosFiltrando, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Pedido/:idPedido',name: 'ProdutosDoPedido',component: ProdutosDoPedido, meta: { mostrarFAQ: false }},
      {path: '/Hospede/checkout',name: 'DadosCartao',component: DadosCartao, meta: { mostrarFAQ: false }},
      {path: '/Hospede/pagamento-aprovado',name: 'PagamentoAprovado',component: PagamentoAprovado, meta: { mostrarFAQ: false }},
      {path: '/Hospede/pagamento-negado',name: 'PagamentoNegado',component: PagamentoNegado, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Pagar',name: 'Pagar',component: Pagar, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Comprovantes',name: 'Comprovantes',component: Comprovantes, meta: { mostrarFAQ: false }},
      {path: '/Hospede/FotoDocPagamento',name: 'FotoDocPagamento',component: FotoDocPagamento, meta: { mostrarFAQ: false }},
      {path: '/Hospede/Selfie',name: 'Selfie',component: Selfie, meta: { mostrarFAQ: false }},
      {path: '/Hospede/EfetuarPagamento',name: 'EfetuarPagamento',component: EfetuarPagamento, meta: { mostrarFAQ: false }},
      {path: '/Hospede/DadosPix',name: 'DadosPix',component: DadosPix, meta: { mostrarFAQ: false }},
      {path: '/Hospede/PDVMesa',name: 'PDVMesa',component: PDVMesas, meta: { mostrarFAQ: false }},
      {path: '/Hospede/CategoriasMesa/:idpdv',name: 'Categorias',component: CategoriaMesa, meta: { mostrarFAQ: false }},
      {path: '/Hospede/SubCategoriasMesa/:idpdv/:idcategoriaprodcd',name: 'SubCategorias',component: SubCategoriasMesa, meta: { mostrarFAQ: false }},
      {path: '/Hospede/ProdutosMesa/:idpdv/:idcategoriaprodcd/:idsubcategoriaprodcd',name: 'Produtos',component: ProdutosMesa, meta: { mostrarFAQ: false }},
      {path: '/Hospede/ProdutoMesa/:idpdv/:idcategoriaprodcd/:idsubcategoriaprodcd/:idproduto',name: 'Produto',component: ProdutoMesa, meta: { mostrarFAQ: false }},
      // {path: '/Hospede/Notificacoes',name: 'Notificacoes',component: Notificacoes}
    ]
  }


export default rotas_hospedes
