<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logowrapper">
        <div class="logodiv" style="background-colo: red">
          <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
        </div>
      </div>


          <div>
            <div style="color:white;font-weight:bold;">Usuário</div>
            <v-text-field
              v-model="nome_usuario"
              data-cy="nome_usuario"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white"
            ></v-text-field>
        </div>
        <div>
            <!-- <div style="color:white;font-weight:bold;">{{ehospede?'N° do Apartamento':'Senha'}}</div> -->
            <div style="color:white;font-weight:bold;">Senha</div>
            <v-text-field
              v-model="senha"
              data-cy="senha"
              dense
              autocomplete="off"
              :type="exibirSenha ? 'text' : 'password'"
              outlined
              clearable
              class="inputtext"
              background-color="white"
              v-on:keyup.enter="logar()"
              append-icon="mdi-eye"
              @click:append="exibirSenha = !exibirSenha"
            ></v-text-field>
        </div>
        <v-btn
          large
          block
          color="info"
          class="white--text font-weight-bold"
          data-cy="btn-logar"
          v-on:click="logar()"
        >
          Entrar
          <v-icon right dark>mdi-login</v-icon>
        </v-btn>

        <v-row justify="space-between" class="mx-0 my-5">
          <div class="mt-6 mb-4">
            <a href="/checkin/temp" data-cy="btn-naosouhospede" class="white--text btn-light">
            Não sou hóspede
            </a>
          </div>
          <div class="mt-6 mb-4" @click="vizualizarCardapio">
            <a data-cy="btn-naosouhospede" class="white--text btn-light">
            Vizualizar Cardapio
            </a>
          </div>
          <a href="/" class="white--text mt-6" style="width: 100% !important;">
            <v-btn color="warning"  style="width: 100% !important;" href="/" class="white--text">
              Entrar como hóspede
            </v-btn>
          </a>
          <div class="mt-6 mb-4">
            <a href="/passrecovery" class="white--text">
            Esqueci minha senha
            </a>
          </div>
        </v-row>
        <v-row justify="center" class="mt-2">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>

    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Home",
  data() {
    return {
      nome_usuario: '',
      senha: null,
      tipoLogin: '',
      exibirSenha: false,

      loginViaEmail: false,
      precisaDigitarSenha: true,
      desativarInputSenha: false,
      buscandoApartamento: false,
      debounceSearch: null,
      debounceTimer: null,


      buscaAutomaticaQuarto: false,
    };
  },

  components: {},
  computed: {},
  watch: {
    nome_usuario: {
      handler: 'debouncedHandleNomeUsuarioChange',
    },
  },
  methods: {
    getPerfil(lista,id){
      let found = lista.find(x => x  == id);
      return found
    },
    async vizualizarCardapio() {
      localStorage.setItem("pessoaLogada", "false");

      const response = await this.$http
        .post(this.$dados.BUILD_API_URL + "/hospedes/acessarCardapioSemLogin")
          .then(async res => res.data)
          .catch(() => {
              this.$alertar(
                "warning",
                "Erro ao acessar cardápio",
                "=("
              );
            })

      const token = response.token
      const nomeHotel = response.nomehotel

      localStorage.setItem("token", token);
      localStorage.setItem("nomehotel", nomeHotel);
      this.$http.defaults.headers.common["x-access-token"]  = token

      setTimeout(() => this.$router.push("/Hospede/Menu"),1000)
    },
    logarAdm() {
      this.$http
        .post(this.$dados.BUILD_API_URL + "/usuarios/login", {
          nomeusuario: this.nome_usuario.trim().toLowerCase(),
          senha: this.senha.trim(),
          idhotel: this.$dados.BUILD_ID_HOTEL,
        })
        .then((resp) => {
          console.log(resp);
          localStorage.setItem("usuario", this.nome_usuario);
          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("nome", resp.data.nome);
          localStorage.setItem("idusuario", resp.data.idusuario);
          localStorage.setItem("email", resp.data.email);
          if(resp.data.nomehotel) {
            localStorage.setItem("nomehotel", resp.data.nomehotel);
          }
          localStorage.setItem("idhotel", resp.data.idhotel);
          localStorage.setItem("config", resp.data.config);
          localStorage.setItem("whoIsLogged", "usuario")
          localStorage.setItem("tipo_integracao", resp.data.tipo_integracao);
          localStorage.setItem("perfis", JSON.stringify(resp.data.perfis));
          this.$store.dispatch("set_idperfil", resp.data.perfis)
          localStorage.setItem("tipo_usuario", "ADM");
          this.$store.dispatch("setar_cardapio_usuario_logado", {
            nomeusuario: this.nome_usuario.trim(),
            senha: this.senha.trim(),
            idhotel: this.$dados.BUILD_ID_HOTEL,
          });
          this.$socket.io.opts.query.usuario = this.nome_usuario;
          this.$socket.io.opts.query.tipo_usuario = "ADM";
          this.$socket.io.opts.query.idhotel = resp.data.idhotel;
          this.$store.dispatch("set_titulo", "Cardápio");
          this.$http.defaults.headers.common["x-access-token"] = resp.data.token;
          console.log("resp.data.perfis",resp.data.perfis)

          this.verificaPatchNotes(resp.data)

          this.verificaCadastroUsuarioTuor(resp.data)

          const locaisNotificacao = localStorage.getItem("locaisNotificacao")

          if(!locaisNotificacao){
            localStorage.setItem("locaisNotificacao", JSON.stringify(["CAIXA", "BAR", "COZINHA"]));
          }

          localStorage.setItem("pessoaLogada", "true");

          this.encaminhaParaTelaCerta(resp.data.perfis)
        })
        .catch(() => {
          this.$alertar(
            "warning",
            "Login e Senha de usuário não Encontrados!",
            "=("
          );
        });
    },
    encaminhaParaTelaCerta(perfis) {

      const tuorCardapio = JSON.parse(localStorage.getItem("tuorCardapio")) || {};

      if(!tuorCardapio?.etapas) {
        this.$router.push("/Adm/ExemploTuor");
        return
      }

      //usuario CAIXA
      // if(resp.data.idperfil == 1 || resp.data.idperfil == 2){
      if(this.getPerfil(perfis,1)  || this.getPerfil(perfis,2)){
        console.log(" LOGINNNN 7.1");
        this.$router.push("/Adm/Caixa");
      }

      console.log(" LOGINNNN 8");

      //usuario COZINHA
      // if(resp.data.idperfil == 3){
      if(this.getPerfil(perfis,3)){
        this.$router.push("/Adm/Cozinha");
      }

      //usuario BAR1
      // if(resp.data.idperfil == 4){
      if(this.getPerfil(perfis,4)){
        this.$router.push("/Adm/Bar");
      }

      //usuario BAR2
      // if(resp.data.idperfil == 6){
      if(this.getPerfil(perfis,6)){
        this.$router.push("/Adm/Bar");
      }

      //usuario GARCOM
      // if(resp.data.idperfil == 5){
      if(this.getPerfil(perfis,5)){
        this.$router.push("/Adm/Garcom");
      }
    },
    verificaPatchNotes(data) {
      this.$store.dispatch("set_vizualizou_patch_notes", data.vizualizou_novidades);
    },
    verificaCadastroUsuarioTuor(data) {
      const tuorCardapio = JSON.parse(localStorage.getItem("tuorCardapio")) || {};

      if(!tuorCardapio?.etapas) {
        const sixMounthsBefore = dayjs().subtract(3, 'mounth').format('YYYY-MM-DD')

        if (data.data_criacao && dayjs(data.data_criacao).isBefore(sixMounthsBefore)) {

          const tuorCardapio = {
            fazerPedido: true,
            etapas: true,
            hospedes: true,
            configuracoes: {
              cardapio: true,
            },
          }

          localStorage.setItem("tuorCardapio", JSON.stringify(tuorCardapio));
        }
      }
    },
    logar() {
      if (!this.nome_usuario || !this.senha) {
        return this.$alertar(
          "warning",
          "Login e Senha são campos Obrigatórios!",
          "=("
        );
      }
      this.logarAdm();
    },
  },
  async mounted(){

    this.$http.post(this.$dados.BUILD_API_URL + '/pdv/buscarparametrosPublicos', {chave:'EXIBIR_TELA_BAIXAS'})
      .then(resp =>{
        if(resp.data && resp.data.length > 0){
          localStorage.setItem('exibir_tela_baixas', resp.data[0].valor)
        }
      }).catch(() =>{
        localStorage.setItem('exibir_tela_baixas', 'false')
      })
  },
};
</script>

<style scoped>

.btn-light{
  border-radius: 20px;
  background: white;
  color: #1976d2 !important;
  box-shadow:  20px 20px 60px #1564b3,
              -20px -20px 60px #1d88f2;
  text-decoration: none;
  padding: .7em 1em;
}

.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logowrapper{
  display: grid;
  place-content: center;
}
.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;
}
</style>
