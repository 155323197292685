<template>
    <div>
        <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
            v-show="carregando"
        >
            <v-skeleton-loader
                v-bind="attrs"
                type="article, actions"
            ></v-skeleton-loader>
        </v-sheet>

        <v-stepper v-model="e1" v-show="!carregando">
            <v-stepper-header>
            <v-stepper-step editable step="1">
                Hóspede
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
                DayUse
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" editable>
                Passante
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4" editable>
                Individual
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="5" editable>
                Evento
            </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
            <v-stepper-content step="1">
                <h1>Para qual caixa será enviado o pedido do hóspede?</h1>
                <v-select
                    v-model="caixaSelecionadoHospede" 
                    :items="caixasDisponiveis" 
                    item-text="nome"
                    item-value="id"
                    dense
                    outlined 
                >
                </v-select>
              
                <v-btn color="primary" @click="() => salvar()">
                Salvar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
                <h1>Para qual caixa será enviado o pedido do dayuse?</h1>

                <v-autocomplete
                v-model="caixaSelecionadoDayUse"
                :items="caixasDisponiveis" 
                item-text="nome"
                item-value="id"
                dense
                outlined 
                ></v-autocomplete>

                <v-btn color="primary" @click="() => salvar()">
                Salvar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
                <h1>Para qual caixa será enviado o pedido do passante?</h1>

                <v-autocomplete
                v-model="caixaSelecionadoPassante"
                :items="caixasDisponiveis" 
                item-text="nome"
                item-value="id"
                dense
                outlined 
                ></v-autocomplete>

                <v-btn color="primary" @click="() => salvar()">
                Salvar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
                <h1>Para qual caixa será enviado o pedido do Individual?</h1>

                <v-autocomplete
                v-model="caixaSelecionadoIndividual"
                :items="caixasDisponiveis" 
                item-text="nome"
                item-value="id"
                dense
                outlined 
                ></v-autocomplete>

                <v-btn color="primary" @click="() => salvar()">
                Salvar
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="5">
                <h1>Para qual caixa será enviado o pedido do Evento?</h1>

                <v-autocomplete
                v-model="caixaSelecionadoEvento"
                :items="caixasDisponiveis" 
                item-text="nome"
                item-value="id"
                dense
                outlined 
                ></v-autocomplete>

                <v-btn color="primary" @click="() => salvar()">
                Salvar
                </v-btn>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
export default {
  name: "TipoHospedeXPDV",
  components: {},
  props: [],
  inject: {
    theme: {
        default: { isDark: false },
    },
  },
  async mounted() {
    await this.getCaixasDisponiveis();
  },
  watch: {},
  data: () => ({
    caixasDisponiveis: [],
    caixaSelecionadoHospede: null,
    caixaSelecionadoDayUse: null,
    caixaSelecionadoPassante: null,
    caixaSelecionadoIndividual: null,
    caixaSelecionadoEvento: null,
    carregando: true,
  }),
  methods: {
    async getCaixasDisponiveis() {

      this.carregando = true

      await this.$http
        .get("/pdv/pdvHITSDisponiveis")
        .then((res) => {
          this.caixasDisponiveis = res.data.caixasDisponiveis;
          this.caixaSelecionadoHospede = res.data.selecionados.hospede;
          this.caixaSelecionadoDayUse = res.data.selecionados.dayuse;
          this.caixaSelecionadoPassante = res.data.selecionados.passante;
          this.caixaSelecionadoIndividual = res.data.selecionados.individual;
          this.caixaSelecionadoEvento = res.data.selecionados.evento;
          this.carregando = false
        })
        .catch(() => {
            this.$alertar("warning", "Não foi possível carregar os pdvs disponíveis", "=(");
        });
    },
    async salvar() {
      const data = {
        hospede: this.caixaSelecionadoHospede,
        dayuse: this.caixaSelecionadoDayUse,
        passante: this.caixaSelecionadoPassante,
        individual: this.caixaSelecionadoIndividual,
        evento: this.caixaSelecionadoEvento,
      };

      await this.$http.post("/pdv/salvarTipoHospedeXPDVHITS", data)
      .then(() => {
        this.$alertar("success","Salvo com sucesso", "=)")
      })
      .catch(() => {
        this.$alertar("warning", "Erro ao salvar!", "=(");
      })

    //   await this.getCaixasDisponiveis()
    },
  },
};
</script>

<style scoped></style>
