<template>
  <div>
    <BotaoVoltar :url="'/Hospede/Categorias/'+$route.params.idpdv" />
    <Subtitulo :subtitulo="subtitulo" />
    <SubCategoriaLista :idcategoriaprodcd="$route.params.idcategoriaprodcd" :idpdv="$route.params.idpdv" />
  </div>
</template>

<script>
  import SubCategoriaLista from '../../components/categories/SubCategoriaLista.vue'
  import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'View SubCategoria',
    data: () => ({
      subtitulo:""
    }),
    components: {
      SubCategoriaLista,
      BotaoVoltar,
      Subtitulo,
    },
    mounted(){
        this.$http('pdv/getNomeCategoria/'+this.$route.params.idcategoriaprodcd).then((resp) =>{
          this.subtitulo = resp.data.nomecategoriaprodcd
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi pegar o nome da Categoria","=(")
        })
        this.$store.dispatch("set_titulo",this.$t('titulocardapio.texto'))
    }
  }
</script>
