<template>
    <v-dialog  v-model="exibirModalEdicaoPDV" width="1000" max-width="80vw">
        <v-card id="modalconstrucao" style="max-width: 100%;" >
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2" >
                    <span style="width:10%;color:white;font-weight:bold;" >
                        Editar PDV
                    </span>
                    <div  style="width:85%;display:flex;justify-content:flex-end;padding-right:20px;">
                        <v-btn small color="default">
                            Duplicar cardapio de outro PDV
                        </v-btn>
                    </div>
                    <span style="width:5%;">
                        <v-btn x-small color="red" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <v-flex xs12>
                            <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="uploadCompressor" />
                            <v-avatar slot="offset" class="mx-auto d-block" size="130">
                            <v-icon v-if="!imgSrc" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                            <img v-if="imgSrc" :src="imgSrc" class="imgUpload" />
                            </v-avatar>
                            <div style="display:flex;justify-content:center;margin-top:20px;">
                                <label for="file-upload" >
                                    <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                                    Foto
                                    </div>
                                </label>
                            </div>
                        </v-flex>

                        <div style="padding-bottom:10px;">
                            <h3 data-v-step-cardapio="6">Nome <span style="color:red;">(Máx. 50 Cararcteres)</span></h3>
                            <input   maxlength="50" type="text" data-cy="input-nome" v-model="pdv.descpdv" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                        </div>

                        <div>
                             <v-container>
                                <v-row>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="pdv.ativo" data-cy="switch-ativo" data-v-step-cardapio="7"> </v-switch>
                                                <label>{{pdv.ativo ? 'Ativado': 'Pausado'}}</label>
                                            </div>
                                    </v-col>

                                    <v-col>
                                            <div style="padding-bottom:2px;display:flex;align-items:center;">
                                                <v-switch v-model="pdv.allinclusive" data-cy="switch-allinclusive"> </v-switch>
                                                <label>Disponível para All Inclusive</label>
                                            </div>
                                    </v-col>

                                  <v-col>

                                        <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;">
                                            <label>Impressora</label>
                                            <v-select data-v-step-cardapio="8" v-model="impressora" :items="listadeimpressoras"
                                                item-text="nome" item-value="ip" return-object dense outlined
                                                style="width:60%;height:40px;background-color:white;padding:10px;border-radius:10px;margin-top:-20px;" >
                                            </v-select>
                                        </div>
                                  </v-col>



                                </v-row>


                               <v-row>

                                 <v-col v-if="true">
                                   <div style="padding-bottom:2px;display:flex;align-items:center;margin-top:20px;" data-v-step-cardapio="9">
                                     <v-autocomplete
                                         style="border-radius: 10px;"
                                         v-model="exibirPara"
                                         :items="exibirParaItens"
                                         outlined
                                         dense
                                         chips
                                         small-chips
                                         label="Exibir para"
                                         multiple
                                     ></v-autocomplete>
                                   </div>
                                 </v-col>

                               </v-row>


                              </v-container>
                        </div>

                        <div style="padding-bottom:10px;" data-v-step-cardapio="10">

                            <h3>Disponibilidade</h3>
                            <v-btn small elevation="" color="primary" @click="adicionarDisponibilidade()">Adicionar Disponibilidade</v-btn>

                            <div>
                                 <v-container class="">
                                    <v-row>
                                      <v-col><v-btn @click="setDia('seg')" small elevation="" :color="seg?'success':'default'">Seg</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('ter')" small elevation="" :color="ter?'success':'default'">Ter</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('qua')" small elevation="" :color="qua?'success':'default'">Qua</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('qui')" small elevation="" :color="qui?'success':'default'">Qui</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('sex')" small elevation="" :color="sex?'success':'default'">Sex</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('sab')" small elevation="" :color="sab?'success':'default'">Sab</v-btn></v-col>
                                      <v-col><v-btn @click="setDia('dom')" small elevation="" :color="dom?'success':'default'">Dom</v-btn></v-col>
                                    </v-row>
                                  </v-container>
                            </div>
                            <div style="display:flex;flex-wrap:wrap;">

                                <div v-for="horario in listaHorarios" :key="horario.id" style="display:flex;align-items:flex-end;width:25%;margin-top:10px;">
                                    <div style="border:1px solid #bdbaba;display:flex;flex-direction:column;align-items:flex-end;padding:5px;border-radius:10px;">
                                        <div style="display:flex;align-items:flex-end;padding:5px;border-radius:10px;">
                                            <datetime class="inputsHorario" data-cy="input-horainicio" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}" style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horainicial" >
                                            </datetime>
                                            <span style="margin-right:10px;" >até</span>
                                            <datetime class="inputsHorario" data-cy="input-horafim" value-zone="UTC-3" :input-style="{color:'black',width:'100%','font-size':'18px'}"  style="width:70px;" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time"
                                                v-model="horario.horafinal" >
                                            </datetime>
                                        </div>
                                        <div>
                                            <a>excluir</a>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>






                    </div>
                    <div style="margin-top:10px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" data-v-step-cardapio="11" data-cy="atualizarPdv">
                            SALVAR
                        </v-btn>
                    </div>

                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="error" data-v-step-cardapio="12" data-cy="setExclusaoModal">
                            EXCLUIR PDV
                        </v-btn>
                    </div>

                </v-card>



            </v-card-text>


        </v-card>

    </v-dialog>
</template>

<script>
  export default {
    name: 'ModalEditarPDVTuor',
    props:["exibirModalEdicaoPDV","idpdvcd"],
    data: () => ({
        exibirModalExclusao:false,
        nome:null,
        tempo:null,
        horarioinicial:false,
        horariofinal:false,
        pdv:{
            descpdv:null,
            ativo:null
        },
        imgSrc:null,
        showModalDuplicarCardapioPDV:false,
        listaHorarios:[],
        seg:false,
        ter:false,
        qua:false,
        qui:false,
        sex:false,
        sab:false,
        dom:false,
        listadeimpressoras:[],
        impressora:null,

        exibirPara: [],
        exibirParaItens: [{ text: "Garcom", value: "garcom" }, { text: "Hóspede", value: "hospede" }],
    }),
    async mounted(){
      // const element = document.getElementById('modalconstrucao')
      // element.parentElement.style.overflow = 'hidden'

      this.getImpressoras()
      this.getPdv()
  },
    methods:{

      getImpressoras() {
        const returnMockAPI = [
          {
            "id": 3,
            "nome": "Impressora A",
            "ip": "tcp://0.0.11.33",
            "ativo": true,
            "fl_local": false,
            "fl_impressao_completa": false
          },
          {
            "id": 2,
            "nome": "Impressora B",
            "ip": "tcp://0.0.11.22",
            "ativo": true,
            "fl_local": false,
            "fl_impressao_completa": false
          },
          {
            "id": 1,
            "nome": "Impressora C",
            "ip": "tcp://10.125.121.105.5",
            "ativo": false,
            "fl_local": false,
            "fl_impressao_completa": false
          }
        ]

        this.listadeimpressoras = returnMockAPI
      },

        async uploadCompressor(e) {
            e.preventDefault();
            this.imgSrc = URL.createObjectURL(e.target.files[0]);
        },
        setDia(dia){
            if(dia === 'seg'){ this.seg = !this.seg}
            if(dia === 'ter'){ this.ter = !this.ter}
            if(dia === 'qua'){ this.qua = !this.qua}
            if(dia === 'qui'){ this.qui = !this.qui}
            if(dia === 'sex'){ this.sex = !this.sex}
            if(dia === 'sab'){ this.sab = !this.sab}
            if(dia === 'dom'){ this.dom = !this.dom}
        },
        adicionarDisponibilidade(){
            this.listaHorarios.push({tipo:1,horainicial:'00:00:00',horafinal:'23:50:00'})
        },
        fecharModalDuplicarCardapioPDV(){
            this.showModalDuplicarCardapioPDV = false
        },
        setExclusaoModal(){
            this.exibirModalExclusao = !this.exibirModalExclusao
        },
        async excluirPDV(){
          this.fechar()
        },
        async salvaimg(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }
            let dados = {
                "tipo" : tipoImg,
                "nome" : "imgPDV",
                "dados" : this.imgSrc,
                "tabela": "pdvcd",
                "campo": "img",
                "chave": "idpdv",
                "valor": this.pdv.idpdv
            }
            console.log('dados> ',dados)
            await this.$http.post("pdv/imagemupdate",dados,{timeout:60000 })
                .then((response) => {
                if(response.status == 200){

                    this.imgSrc = response.data.result.imagem[0]
                }else{
                    console.log(JSON.stringify(response))
                }
                })
                .catch((error) => {
                console.log(error.response);
            });
        },
        direcionar(){
            this.$router.push(this.url)
        },
        fechar(){
            this.$emit('fechar')
        },
        getPdv(){
          const pdvMockResponseAPI = [
            {
              "idpdv": 4,
              "descpdv": "PDV Criado dentro do cardapio digital.",
              "ativo": true,
              "allinclusive": false,
              "seg": true,
              "ter": true,
              "qua": true,
              "qui": true,
              "sex": true,
              "sab": true,
              "dom": true,
              "idimpressora": 1,
              "nome": "Esmeralda",
              "ip": "tcp://10.125.121.105.5",
              "img": null,
              "exibir_para": "[\"hospede\",\"garcom\"]"
            }
          ]
          this.pdv.idpdv = pdvMockResponseAPI[0].idpdv
          this.pdv.descpdv = pdvMockResponseAPI[0].descpdv
          this.pdv.ativo = pdvMockResponseAPI[0].ativo
          this.seg =  pdvMockResponseAPI[0].seg
          this.ter =  pdvMockResponseAPI[0].ter
          this.qua =  pdvMockResponseAPI[0].qua
          this.qui =  pdvMockResponseAPI[0].qui
          this.sex =  pdvMockResponseAPI[0].sex
          this.sab =  pdvMockResponseAPI[0].sab
          this.dom =  pdvMockResponseAPI[0].dom
          this.impressora = { id:pdvMockResponseAPI[0].id, nome:pdvMockResponseAPI[0].nome,ip:pdvMockResponseAPI[0].ip }
          this.imgSrc = pdvMockResponseAPI[0].img
          this.exibirPara = JSON.parse(pdvMockResponseAPI[0].exibir_para)
          this.pdv.allinclusive = pdvMockResponseAPI[0].allinclusive


          const disponibilidadeMockApiResponse = [
            {
              "tipo": 1,
              "iditem": 4,
              "horainicial": "00:00:00",
              "horafinal": "23:50:00"
            }
          ]
          this.listaHorarios = disponibilidadeMockApiResponse
          let objlistaHorarios = disponibilidadeMockApiResponse
          this.listaHorarios = []
          for(let a=0,lena= objlistaHorarios.length; a < lena; a++){
            let horariod = objlistaHorarios[a]
            this.listaHorarios.push({tipo:2,horainicial:'2022-07-06T'+horariod.horainicial,horafinal:'2022-07-06T'+horariod.horafinal})
          }
        },
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

