<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <!-- <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1Cp8ckaVO0qeJyZmWLdencNKzZs_Kc96b/preview" />
    </div> 
    
                this.configuracaohits.propriedadeImportacaoProdutos  =  resp.data[0].valor  === 'true'? true : false
                this.configuracaohits.propriedadeImportacaoHospedes  =  resp.data[0].valor6 === 'true'? true : false
                this.configuracaohits.tenantName                     =  resp.data[0].valor2 === 'true'? true : false
                this.configuracaohits.id_hits_swagger                =  resp.data[0].valor3 === 'true'? true : false
                this.configuracaohits.hitsPartnerUserId              =  resp.data[0].valor4 === 'true'? true : false
                this.configuracaohits.apiHitsVersion                 =  resp.data[0].valor5 === 'true'? true : false



    -->
        
        <v-card-text>
                      
                <v-container grid-list-xs>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="1" 
                                label="Id da Propriedade Para Importação de Produtos" v-model="configuracaohits.propriedadeImportacaoProdutos" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="1"
                                label="Id da Propriedade Para Importação de Hospedes" v-model="configuracaohits.propriedadeImportacaoHospedes" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="hotelabc" 
                                label="Tenant Name" v-model="configuracaohits.tenantName" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="xxxx-xxxx-xxxx-xxxx" 
                                label="Codigo Hits" v-model="configuracaohits.id_hits_swagger" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="1" 
                                label="Id Parceito Hits" v-model="configuracaohits.hitsPartnerUserId" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text" placeholder="" 
                                label="Versão da API Hits" v-model="configuracaohits.apiHitsVersion" outlined color>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn small elevation="" color="primary" style="width:100%;height:7vh" @click="salvar()" data-cy="carregarDados" >
                                Salvar
                            </v-btn>
                        </v-col>
                    </v-row>

                 

                </v-container>
              
        
        </v-card-text>

    </v-card>
</template>

<script>
// import dayjs from 'dayjs'
//   import BotaoTutorial from '../layout/BotaoTutorial.vue'
  export default {
    name: 'ConfigHits',
    components: {
        // BotaoTutorial
    },
    props:[],
    async mounted(){
        
        this.buscarDados()

        // this.datainicialimportacao = dayjs().format('YYYY-MM-DD')

        // //buscar o status atual da sincronia de produtos para evitar duplicidades
        // this.$http.post('pdv/buscarparametros', {chave: 'CONFIGURACAOHITS'})
        // .then((res) =>{
        //     this.lblstatusimportacao = res.data[0].valor
        // }).catch(() =>{
        //     this.gatewayativo = null
        // })

        // //conexão socket para atualizar o status da importacao
        // this.sockets.subscribe('atualizar_status_sincronia_produtos',(data)=>{
        //     this.lblstatusimportacao = data.statusimportacao
        // })

        // this.buscarDados()
        // let _self = this
        // this.sockets.subscribe('importacao_finalizada',()=>{
        //   _self.$alertar('success','Itens Atualizados com Sucesso!!!!!!','=D')
        // }
    //   )

    },
    watch:{
      
    },
    data: () => ({
        configuracaohits:{
            propriedadeImportacaoProdutos:null,
            tenantName:null,
            id_hits_swagger:null,
            hitsPartnerUserId:null,
            apiHitsVersion:null,
            propriedadeImportacaoHospedes:null,
        }
    }),
    methods:{

        salvar(){
            let parametro = { chave:'CONFIGURACAOHITS', 
                              valor:this.configuracaohits.propriedadeImportacaoProdutos,
                              valor2:this.configuracaohits.tenantName,
                              valor3:this.configuracaohits.id_hits_swagger,
                              valor4:this.configuracaohits.hitsPartnerUserId,
                              valor5:this.configuracaohits.apiHitsVersion,
                              valor6:this.configuracaohits.propriedadeImportacaoHospedes
                            }
                
            this.$http.put('pdv/updateparametros',parametro)
                .then(() =>{
                    this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
        },
        
        async buscarDados(){

            this.$http.post('pdv/buscarparametros',{chave:'CONFIGURACAOHITS'})
            .then(resp =>{


                console.log('resp >> ',resp.data[0])
                this.configuracaohits.propriedadeImportacaoProdutos  =  resp.data[0].valor
                this.configuracaohits.tenantName                     =  resp.data[0].valor2
                this.configuracaohits.id_hits_swagger                =  resp.data[0].valor3
                this.configuracaohits.hitsPartnerUserId              =  resp.data[0].valor4
                this.configuracaohits.apiHitsVersion                 =  resp.data[0].valor5
                this.configuracaohits.propriedadeImportacaoHospedes  =  resp.data[0].valor6

                console.log('this.configuracaohits >> ',this.configuracaohits)

            }).catch(error =>{
                alert(error)
            })
        }
    }
  }
</script>

<style scoped>
    
</style>