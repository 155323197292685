<template>
  <div>
      <v-sheet
          :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
          class="pa-3"
          v-show="carregando"
      >
        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-sheet>

      <v-stepper v-model="e1" v-show="!carregando">
        <v-stepper-header>
          <v-stepper-step editable step="1">
            Room Service
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step editable step="2">
            Restaurante (mesas)
          </v-stepper-step>

          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h2>Room Service</h2>
  
            <QRCodeRoomService
              :carregando="carregando"
              @setCarregando="setCarregando"
            /> 
          </v-stepper-content>

          <v-stepper-content step="2">
            <h2>Restaurante (mesas)</h2>
            
            <QRCodeRestaurante 
              :carregando="carregando"
              @setCarregando="setCarregando"
            />

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
  </div>
</template>

<script>
import QRCodeRoomService from '../layout/QRCodeRoomService.vue';
import QRCodeRestaurante from '../layout/QRCodeRestaurante.vue';

export default {
  name: "GeradorQRCode",
  components: {
    QRCodeRoomService,
    QRCodeRestaurante
  },
  props: [],
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  async mounted() {
    this.carregando = true;
    this.carregando = false;
  },
  watch: {},
  data: () => ({
    carregando: true,
  }),
  methods: {
    setCarregando(valor) {
      this.carregando = valor;
    }
  },
};
</script>

<style scoped></style>
