<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <div class="categoryList" >
      <SubCategoria  v-for="subcategoria in listadesubcategorias" :key="subcategoria.idcategoriaprod"
        :subcategoria="subcategoria" :idcategoriaprodcd="idcategoriaprodcd" :idpdv="idpdv"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  import SubCategoria from './SubCategoria.vue'
  export default {
    name: 'SubCategoriaLista',
    props:['idcategoriaprodcd','idpdv'],
    components:{
      // TitleH1,
      SubCategoria
    },
    data: () => ({
      listadesubcategorias:[]
    }),

    methods:{

      getSubCategorias(){
          this.$http('pdv/getSubCategorias/'+this.idcategoriaprodcd+'/0/hospede').then((resp) =>{
            if(resp.data.length > 0){
              console.log(resp.data)
              this.listadesubcategorias = resp.data
            }else{
              this.$router.push('/Hospede/Produtos/'+this.idpdv+'/'+this.idcategoriaprodcd+'/0')
            }
          }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
          })
      },

    },

    mounted(){
      this.getSubCategorias()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
    }
</style>
