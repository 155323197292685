<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="350">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-toolbar color="primary" style="color: white" class="mb-2">
        <v-icon color="white" class="mr-2">mdi-mail</v-icon> 
        <v-toolbar-title>Editar Email do Hóspede</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-text-field v-if="exibeCampoEmail" data-cy="input-email" label="Email" v-model="email" :rules="emailRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <span data-cy="modalEditarEmailHospede-response">{{ msgResponse }}</span>
          <v-spacer></v-spacer>
          <v-btn small color="error" @click="fechar()" data-cy="btn-fechar-modalEditarEmailHospede"> Fechar </v-btn>
          <v-btn small color="success" @click="salvar()" data-cy="btn-salvar-modalEditarEmailHospede">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog", "idcheckin", "email"],

  data() {
    return {
        msgResponse : '',
        exibeCampoEmail : true,
        emailRules: [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "Email inválido",
      ],
    };
  },
  methods: {
    fechar() {
      this.$emit("fechar");
      this.msgResponse = ""
    },
    async salvar(){
        if(!this.email.length > 0 || !/.+@.+\..+/.test(this.email)){
            this.msgResponse = "Email inválido."
            return
        }
        let body = {
            email : this.email,
            idcheckin : this.idcheckin 
        }
        await this.$http.post('/hospedes/alteraremail', body).then(response => {
            if(response.status == 200){
                this.email = response.data[0].email
                this.msgResponse = "Alterado com sucesso"
                this.$emit('atualizar')
                setTimeout(() => this.$emit('fechar'), 1000);
                setTimeout(() => this.msgResponse = "", 1100);

            }else{
                this.msgResponse = "Erro alterando o e-mail"
            }
        })
    }
  },
};
</script>