<template>
  <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
      <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
          <BotaoTutorial categoria="13" legenda="Dúvidas Impressoras" urlVideo="https://drive.google.com/file/d/1KgIU8QqiNmpnvPlf9eAT3w5H5aKKWywU/preview" />
      </div>
          <v-card-text style="height:100%;">
            <v-toolbar flat>
                <v-toolbar-title>Localidades</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="openNewItemDialog">Novo Local</v-btn>
              </v-toolbar>

              <v-data-table
                :headers="headers"
                :items="items"
                item-value="id"
                class="elevation-1"
              >
                <template v-slot:top>
                </template>
                <template v-slot:item.color="{ item }">
                  <v-chip :style="{ backgroundColor: item.color, color: getTextColor(item.color) }">
                    {{ item.color }}
                  </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn @click="changeColor(item)" color="primary" small>Editar</v-btn>
                </template>
                <template v-slot:item.ativo="{ item }">
                  <v-checkbox
                    :disabled="item.id_localidade == 1"
                   @click="ativarDesativar(item)"
                    v-model="item.ativo"
                  ></v-checkbox>
                </template>
              </v-data-table>

              <v-dialog v-model="dialog" max-width="290">
                <v-card>
                  <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        v-model="form.descricao"
                        label="Descrição"
                        required
                      ></v-text-field>
                      <v-color-picker v-model="form.color" hide-canvas></v-color-picker>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveItem">Salvar</v-btn>
                    <v-btn color="grey" text @click="closeDialog">Cancelar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>       
          </v-card-text>
  
      </v-card>
  </template>
  
  <script>
    import BotaoTutorial from '../layout/BotaoTutorial.vue'
    export default {
      name: 'Impressoras',
      components: {
          BotaoTutorial
      },
      props:[],
      async mounted(){
            await this.buscarDados();
    },
      watch:{
        
      },
      data: () => ({
        dialog: false,
        dialogTitle: '',
        form: {
          id: null,
          descricao: '',
          id_localidade: '',
          color: '',
        },
        currentItem: null,
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Cor', value: 'color' },
          { text: 'Ação', value: 'action', sortable: false },
          { text: 'Ativo', value: 'ativo' },
        ],
        items: [

        ],
      }),
      methods: {
        async buscarDados() {
          this.$http("pdv/localidades/config").then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                    this.items = resp.data;
                }
            }).catch((error) => {
                this.$alertar("warning", error, "=(");
            });
        },
        openNewItemDialog() {
          this.dialogTitle = 'Novo Local';
          this.form = {
            id: this.items.length + 1,
            descricao: '',
            id_localidade: '',
            color: '',
          };
          this.dialog = true;
        },
        changeColor(item) {
          if (item.id_localidade === 1) {
            alert('Não é permitido alterar esse local por ser um room service!');
            return;
          }
          this.dialogTitle = 'Editar Local';
          this.currentItem = item;
          this.form = { ...item };
          this.dialog = true;
        },
        async saveItem() {
          if (this.dialogTitle === 'Novo Local') {
            const obj = {
              ...this.form,
              ativo: true,
            }
            await this.$http.post("pdv/localidades/", obj).then(async () => {
                await this.buscarDados();
            }).catch((error) => {
              console.log(error)
                this.$alertar("warning", 'Erro ao salvar novo local', "=(");
            });
          } else if (this.dialogTitle === 'Editar Local' && this.currentItem) {
            await this.$http.put("pdv/localidades/", this.form).then(async () => {
                await this.buscarDados();
            }).catch((error) => {
              console.log(error)
                this.$alertar("warning", 'Erro ao salvar local', "=(");
            });
          }
          this.closeDialog();
        },
        closeDialog() {
          this.dialog = false;
          this.currentItem = null;
        },
        getTextColor(backgroundColor) {
          const color = this.hexToRgb(backgroundColor);
          const brightness = (color.r * 299 + color.g * 587 + color.b * 114) / 1000;
          return brightness > 125 ? 'black' : 'white';
        },
        hexToRgb(hex) {
          let r = 0, g = 0, b = 0;
          if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
          } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
          }
          return { r, g, b };
        },
        async ativarDesativar(item) {
          await this.$http.put("pdv/localidades/", item).then(async () => {
                await this.buscarDados();
                if (item.ativo) {
                  this.$alertar("success","Local ativado com sucesso","=)")
                } else {
                  this.$alertar("success","Local desativado com sucesso","=)")
                }
            }).catch(() => {
                this.$alertar("warning", 'Erro ao atualizar local', "=(");
            });
        }
      }
    }
  </script>
  
  <style scoped>
      
  </style>