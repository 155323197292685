<template>
  <v-app>
    
    <!-- <Bar /> -->

    <!-- <v-main> -->
      <div style="height:100%;">
        <div v-if="deveMostrarFAQ">
          <ModalFAQ />
          <div v-if="deveMostrarPatchNotes">
            <ModalPathNotes />
          </div>
        </div>
<!--        <v-btn-->
<!--          @click="teste"-->
<!--        >teste</v-btn>-->
        <router-view/>
      </div>
    <!-- </v-main> -->

  </v-app>
</template>

<script>

// import Bar from './components/layout/Bar.vue'
import ModalFAQ from './components/layout/Modais/ModalFAQ.vue';
import ModalPathNotes from './components/layout/Modais/ModalPathNotes.vue';



export default {
  name: 'App',
  components:{
    ModalFAQ,
    ModalPathNotes,
  },
  data: () => ({
    
    //
  }),
  computed: {
    deveMostrarFAQ(){
      const currentRoute = this.$route;
      // Verifica se a rota atual tem a propriedade meta "mostrarFAQ"
      // Se sim, retorna o valor da propriedade
      // Se não, retorna true para incluir o componente por padrão
      // eslint-disable-next-line no-prototype-builtins
      return !currentRoute.meta.hasOwnProperty('mostrarFAQ') || currentRoute.meta.mostrarFAQ;
    },
    deveMostrarPatchNotes(){
      return !this.$store.getters.get_vizualizou_patch_notes;
    },
  },
  methods: {
    teste() {
      this.$store.dispatch("set_patch_notes", true);
    }
  }
};
</script>

<style >
  
</style>