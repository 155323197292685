<template>
 <div class="divModalComandasMesa">
  <div v-if="comandas.length > 0" class="buttonComandas">
    <button class="buttonComanda" type="button" v-for="(comanda, index) in comandas" :key="index" @click="exibirDetalhesDaComanda(comanda)"> 
      <img class="iconeComanda" src="/imgs/iconeComanda.png" alt="comanda" />
      {{comanda.idcomanda}}</button>
  </div>
    <div v-else class="containerMensagemMesaVazia">
      <h1>Atenção</h1>
      <p>Essa mesa não possui nenhuma comanda aberta no momento</p>
    </div>
  <div class="containerButtons">
    <button type="button" class="buttonVoltar" @click="() => this.$emit('fechar-modal-comandas')">Voltar</button>
    <button type="button" class="buttonAbrirComanda" @click="() => { abrirComanda()}">Abrir Comanda</button>
    <!-- <button type="button" class="buttonFecharMesa" @click="fecharMesa">Fechar Mesa</button> -->
  </div>
 </div>
</template>

<script>
 export default {
  name: 'ModalComandasMesa',
  props: ['comandas', 'mesas', 'mesa'],
  data() {
    return {
     pedidos: [],
    }
  },
  methods: {
  
    abrirComanda(){
          const routeData = this.$router.resolve({name: 'Hospedes'});
          // window.open(routeData.href+'?mesa='+this.mesa.idmesa, '_blank');
          // window.open(routeData.href+'?mesa='+this.mesa.idmesa);
          this.$router.push(routeData.href+'?mesa='+this.mesa.idmesa)
    },


    async exibirDetalhesDaComanda(comanda) {
          await this.$http( `pdv/getProdutosdoPedido/${comanda.idcomanda}/t`).then((resp) => {
              this.pedidos = resp.data;
              console.log(resp.data)
              const dados = {
                pedidos: resp.data,
                exibirModal: true,
              }
              this.$emit('emit-click', dados)

            }).catch(error => {
                console.log(error);
                this.$alertar("warning", "Não foi possivel buscar os produtos do pedido", "=(");
            });
        },


         fecharMesa() {
          if(this.comandas.length > 0) {
            return global.alert("Atenção, não é permitido fechar uma mesa com comandas abertas")
          }
         const  numeroPraca  = this.mesa;
         const data = {
          numeroMesa: numeroPraca,
          status: false,
         }

         this.$http.post(`mesaStatus/cadastrarMesa/`, data).then(()=>{
            this.$emit('mesa-fechada')
            })
            .catch((error)=>{
             console.log(error)
            })
         },
  },
 }
</script>

<style scoped>

.divModalComandasMesa {
  background-color: white;
  box-shadow: 0px 0px 10px  black;
  width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.buttonComanda {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 20px;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  border: 1px solid rgb(151, 151, 151);
}

.iconeComanda {
  width: 50px;
}

.buttonFecharMesa {
  background-color: rgb(161, 0, 0);
  border-radius: 5px;
  color: white;
  padding: 5px;
  width: 150px;
}

.buttonAbrirComanda {
  background-color: rgb(19, 161, 0);
  border-radius: 5px;
  color: white;
  padding: 5px;
  width: 150px;
}

.buttonVoltar {
  background-color: #1976d2;
  border-radius: 5px;
  color: black;
  padding: 5px;
  color: white;
  width: 80px;
}

.containerButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px;
  background-color: #dadada;
}

.containerMensagemMesaVazia {
  text-align: center;
  padding: 20px;
}

.buttonComandas {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

</style>