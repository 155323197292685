<template>
  <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%">
    <v-card-text style="">
        <div style="height:7vh;max-height:7vh;overflow:auto;margin-top:20px;">
            <div style="padding-bottom:2px;display:flex;align-items:center;">
                <label style="font-size:18px;">Gerencie vinculos entre intervalos de mesas e pdv's</label>
                <v-btn color="#1976D2" rounded style="margin-left: 1em;" @click="inserirVinculo"><v-icon color="#f9f9f2">mdi-plus-circle-outline</v-icon></v-btn>
            </div>
        </div>
        <div class="">
            <div  v-for="vinculo,idx in vinculos" :key="idx" style="margin-top:1px;display:flex;flex-direction: column;border-bottom:1px solid #b5b5b5;padding-bottom:10px;margin-bottom: 2em;" data-cy="wrapper-mesas">
                <div style="display:flex;width:100%;align-items: flex-start">

                     <v-container class="">
                        <v-row>
                          <v-col cols="12" md="3">
                              <label for="mesainicial" style="font-size:14px;font-weight:bold; line-height: 1;">PDV</label>
                              <v-select v-if="pdvs.length > 0" color v-model="vinculo.idpdv" :items="pdvs" dense 
                                    item-value="idpdv" item-text="descpdv"></v-select>
                          </v-col>
                          <v-col cols="12" md="3">
                              <label for="mesainicial" style="font-size:14px;font-weight:bold; line-height: 1;">Intervalo de Mesas - Inicio</label>
                              <input id="mesainicial" autocomplete="off" v-model="vinculo.pracainicial"  maxlength="50" type="number"  placeholder="Início" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                          </v-col>
                          <v-col cols="12" md="3">
                              <label for="mesainicial" style="font-size:14px;font-weight:bold; line-height: 1;">Final</label>
                              <input id="mesainicial" autocomplete="off" v-model="vinculo.pracafinal"   maxlength="50" type="number"  placeholder="Fim" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                          </v-col>
                          <v-col cols="12" md="1">
                              <v-btn color="red" rounded style="margin-left: 1em;margin-top:20px;" @click="removerVinculo(vinculo)"><v-icon color="#f9f9f2">mdi-close</v-icon></v-btn>
                          </v-col>
                          <v-col cols="12" md="1">
                              <v-btn color="#1976D2" rounded style="margin-left: 1em;margin-top:20px;" @click="salvarVinculo(vinculo)"><v-icon color="#f9f9f2">mdi-content-save</v-icon></v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    
                </div>

            </div>
        </div>

    </v-card-text>
  </v-card>
  
</template>

<script>
export default {
  data(){
    return {
      pdvs: [],
      vinculos: []
    }
  },

  methods: {
    //metodos para interecao com servidor
    salvarVinculo(vinculo){
      // let pracas
      // if('id' in vinculo){
      //   pracas = {
      //     id: parseInt(vinculo.id),
      //     mesainicial: parseInt(vinculo.mesainicial),
      //     mesafinal: parseInt(vinculo.mesafinal),
      //     pdvs: vinculo.pracas.map(pdv => pdv)
      //   }
      // }else{
      //    pracas = {
      //     mesainicial: parseInt(vinculo.mesainicial),
      //     mesafinal: parseInt(vinculo.mesafinal),
      //     pdvs: vinculo.pracas.map(pdv => pdv)
      //   }
      // }
      if(vinculo.pracafinal <= vinculo.pracainicial){
        this.$alertar('warning', '=(', 'o valor final deve ser maior que o inicial!')
        return 
      }
      this.$http.post('/pdv/addUpPracasXPdvs', {vinculo})
        .then(async res => {
          if(res.status === 200){
            await this.requisitiarVinculos()
            this.$alertar('success', '=)', 'Vinculo inserido com sucesso!')
          }
        })
        .catch(e => {
          console.log('erro em vincular praca de mesas ao pdv', e)
          this.$alertar('warning', '=/', 'Falha no vinculo!')
        })
    },
    async requisitiarVinculos(){
      this.$http('pdv/getPracasXPdvs').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    console.log('resp.data', resp.data)
                    this.vinculos = resp.data 
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })    
    },
    async requisitarPDVS(){
      this.$http('pdv/getPDVSCD/0').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.pdvs = [{idpdv:0,descpdv:'Todos'},...resp.data ]
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })
    },    
    //metodos para manipulacao dos dados no front e da dom
    pdvSelecionado(pdv, vinculo){
      let index = this.vinculos.indexOf(vinculo)
      let idxpdv = this.vinculos[index].pracas.indexOf(pdv.idpdv)
      if(idxpdv != -1){
        return 'pdv selecionado'
      }else{
        return 'pdv'
      }
    },
    inserirRemoverPdv(pdv, vinculo){
      let index = this.vinculos.indexOf(vinculo)
      if(this.vinculos[index].pracas.length > 0){
        let idxpdv = this.vinculos[index].pracas.indexOf(pdv.idpdv)
        if(idxpdv >= 0){
          this.vinculos[index].pracas.splice(idxpdv, 1)
        }else{
          this.vinculos[index].pracas.push(pdv.idpdv)
        }
      }else{
        this.vinculos[index].pracas.push(pdv.idpdv)
      }
    },
    inserirVinculo(){
      this.vinculos.push({mesainicial: '', mesafinal: '', pracas: []})
    },
    removerVinculo(vinculo){
      if(vinculo.id){
        this.$http.post('/pdv/removerPracasXPdvs', {idpraca: vinculo.id})
          .then(res => {
            if(res.status === 200){
              this.$alertar('success', '=)', 'Vinculo excluido com sucesso!')
               let index = this.vinculos.indexOf(vinculo)
               this.vinculos.splice(index, 1)
            }
          })
          .catch(e => {
            if(e.response?.data?.message){
              console.log('erro em vincular praca de mesas ao pdv', e.response.data.message )
              this.$alertar('warning', '=/', e.response.data.message )
            }else{
              this.$alertar('warning', '=/', 'Falha no exclusão vinculo!')
            }
          })
      }
     
    },

  },

  async mounted(){
    await this.requisitiarVinculos()
    await this.requisitarPDVS()
  }
  

}
</script>

<style lang="scss" scoped>

.vinculos-wrapper{
  overflow-y: scroll;
  max-height: 100%;
}

.pdv{
  padding: .5em 1em;
  border-radius: 1em;
  background: #aaa;
  color: #666;
  user-select: none;

  &:hover{
    cursor: pointer;
  }

  &.selecionado{
    background: green;
    color: #f9f9f2;
  }
}

</style>