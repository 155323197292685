import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import dayjs from 'dayjs'
import Vue2Editor from "vue2-editor";
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')



Vue.config.productionTip = false



// CENTRALIZACAO DE DADOS POR HOTEL
import {BUILD_API_URL,BUILD_TIPO_INTEGRACAO,BUILD_ID_HOTEL,BUILD_APIES_URL,BUILD_TOKEN_APIES,BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS,BUILD_ID_CLIENTE} from './constantes.js'
Vue.prototype.$dados = {
  BUILD_API_URL:BUILD_API_URL,
  BUILD_TIPO_INTEGRACAO:BUILD_TIPO_INTEGRACAO,
  BUILD_ID_HOTEL:BUILD_ID_HOTEL,
  BUILD_APIES_URL:BUILD_APIES_URL,
  BUILD_TOKEN_APIES:BUILD_TOKEN_APIES,
  BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS:BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS,
  BUILD_ID_CLIENTE:BUILD_ID_CLIENTE
}

// alert(JSON.stringify(BUILD_API_URL))
// IMPORTACAO AXIOS
import axios from 'axios'
axios.defaults.baseURL = BUILD_API_URL

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['x-access-token'] = token
}

//estava dando erro, quando entrava em configurações
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 403) {
//       localStorage.clear();
//       window.location.href = "/";
//     }

//     // reject with error if response status is not 403
//     return Promise.reject(error);
//   }
// );
Vue.prototype.$http = axios
console.log('axios.defaults.baseURL')
console.log(axios.defaults.baseURL)



// CENTRALIZACAO DA NOTIFICACAO
import {alertar} from '@/services/notificacao.services.js'
Vue.prototype.$alertar = alertar

// CONTROLE DO SOCKET IO
import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client";


//COMPONENTE INPUT DE HORARIO
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(`${BUILD_API_URL}/cd`,{query: { tipo:'CARDAPIO' }, autoConnect: false}),
    vuex: {
      store, 
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    // options: [{ transports: ["websocket"] }],
  })
);



// FILTERS
Vue.filter('formatarData', function(value) {
  if (value) {
      return dayjs(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatarDataCompacta', function(value) {
  if (value) {
      return dayjs(String(value)).format('DD/MM/YY')
  }
})

Vue.filter('formatarDataHora', function(value) {
  if (value) {
      return dayjs(String(value)).format('DD/MM/YYYY HH:mm')
  }
})

Vue.filter('formatarDataYYHora', function(value) {
  if (value) {
      return dayjs(String(value)).format('DD/MM/YY HH:mm')
  }
})

Vue.filter('formatarPercentual', function(value) {
  if (value >= 0) {
      return `${parseFloat(value).toFixed(2)}%`
  }
})

Vue.filter('formatarHoraMinuto', function(value) {
  if (value) {
      return dayjs(String(value)).format('HH:mm')
  }
})

Vue.filter('pretty', function(value) {
  if (value) {
     return JSON.stringify(JSON.parse(value), null, 2);
  }
})
 
Vue.filter('formatarMoeda', function(value) {
  if (value) {
      var v = parseFloat(value).toFixed(2);
      v = v.replace(".", ",");
      v = v.replace(/,(\d)\b/g, ",$10");
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
      return "R$ "+v;
  } else {
      return 'R$ 0,00'
  }
})

import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper);

import i18n from './i18n'

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';
Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
    lang: 'pt-br',
});

Vue.use(Vue2Editor);

Vue.use(VueTour)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  sockets: {
    connect: function() { 
     
    },
    disconnect: function() {
      
    },
  },
  render: h => h(App)
}).$mount('#app')
