<template>

    <div class="ddpms">
        <span style="font-weight:bold;color:#036097;">ITENS EXIBIDOS NO CARDÁPIO DIGITAL</span>
        <v-card  elevation="2" outlined  style="padding-bottom:0px!important;max-height:170px;height:40%" >

            <v-card-text class="" style="margin-top:0px;margin-bottom:0px;">
                <div style="height:70px;display:flex;justify-content:space-between">
                    <div style="width:29%;margin-left:3%;">
                        
                        <label for="">PDV</label>
                        <select v-model="idpdvcd" data-cy="idpdvcd" class="select" @change="getCategoriasCD()">
                            <option :style="!pdv.ativo ? 'color:red': '' " v-for="pdv in listaPDVsCD"  :key="pdv.idpdv" :value="pdv.idpdv" >
                                {{pdv.descpdv +(!pdv.ativo ? ' [PAUSADO]':'' )}}
                            </option>
                            
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                        </div>

                    </div>

                    <div style="width:29%;margin-left:3.5%;">
                        
                        <label for="">Categoria</label>
                        <select v-model="idcategoriacd" data-cy="idcategoriacd" class="select" @change="getSubCategoriasCD()" :disabled="idpdvcd === null">
                            <option :style="!categoria.ativo ? 'color:red': '' " v-for="categoria in listaCategoriasCD"  :key="categoria.idcategoriaprodcd" :value="categoria.idcategoriaprodcd" >
                                {{categoria.nomecategoriaprodcd +(!categoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                        </div>
                        
                    </div>

                    <div style="width:29%;margin-left:3%;">
                        
                        <label for="">Sub Categoria</label>
                        <select v-model="idsubcategoriacd" data-cy="idsubcategoriacd" class="select" @change="getProdutosCD()" :disabled="idpdvcd === null">
                            <option  value="0" >
                               -
                            </option>
                            <option :style="!subcategoria.ativo ? 'color:red': '' " v-for="subcategoria in listaSubCategoriasCD"  :key="subcategoria.idsubcategoriaprodcd" :value="subcategoria.idsubcategoriaprodcd" >
                                {{subcategoria.nomesubcategoriaprodcd +(!subcategoria.ativo ? ' [PAUSADO]':'' ) }}
                            </option>
                        </select>
                        <div>
                            <div style="display:flex;justify-content:space-between;padding-left:10px;padding-right:10px;">
                            </div>
                        </div>

                    </div>
            
                </div>
                <div>
                    <div style="height:50px" id="cardpesquisa">
                        <label>Pesquise por nome do produto</label>
                        <div id="input" >
                            <img src="@/assets/img/lupa.svg" alt="lupa pesquisa">
                                <input 
                                    type="text" 
                                    v-model="pesquisa" 
                                    placeholder="Pesquise por nome do produto"
                                />
                        </div>
                    </div>

                </div>

            </v-card-text>
            
        </v-card>

        <v-card  elevation="2" outlined  class="mt-2" height="80.5%" style="max-height:65vh;">

            <v-card-text class="">                   
                <div style="width:100%;display:flex; flex-wrap:wrap;height:60vh;max-height:60vh;overflow:auto;align-items:flex-start;justity-content:flex-start;align-content:flex-start;">
                       
                    <v-card 
                        v-for="prod in itensFiltrados" 
                        :key="prod.idproduto" 
                        elevation="2" 
                        outlined  
                        class="mt-2 ml-6" 
                        height="120px" 
                        width="28%"
                        style="padding:0px;display:flex;align-items:flex-start;padding-top:10px;padding-left:10px;word-break: break-all;"
                        @click.prevent="adicionarItem(prod)">
                            <v-card-text style="color:#5a5858;font-size:12px;padding:0px;padding-left:5px;padding-right:5px;font-weight:bold;">
                                <v-card-text style="padding:0px" data-cy="pdv-cat-sub-item">
                                    <!-- <div style="display:flex;justify-content:space-between;align-items:flex-end;"> -->
                                        <div style="font-size:14px;font-weight:bolder;color:#036097;" v-show="idpdvcd === null">
                                            {{ prod.nomedepartamentocd }}
                                        </div>
                                        <!-- nomecategoriaprodcd
                                                nomesubcategoriaprodcd -->
                                        <div style="font-weight:bold;color:#036097;" v-show="idpdvcd === null" v-if="prod.nomecategoriaprodcd">
                                            {{ prod.nomecategoriaprodcd }}
                                        </div>
                                        <div style="font-weight:bold;color:#036097;" v-show="idpdvcd === null" v-if="prod.nomesubcategoriaprodcd">
                                            {{ prod.nomesubcategoriaprodcd }}
                                        </div>
                                    <!-- </div> -->
                                </v-card-text>
                                {{prod.nomecd}}
                                <v-spacer></v-spacer>
                                {{prod.precoimportado | formatarMoeda}}
                            </v-card-text>
                    </v-card>
                    
                </div>
            </v-card-text>
            
        </v-card>
    </div>

</template>

<script>



export default {
    name: 'Cardapio Pedido',
    props:[],
    components:{

    },
    data: () => ({
        edicaoemmassa:false,
        listaPDVsCD:[],
        idpdvcd:null,
        listaCategoriasCD:[],
        idcategoriacd:null,
        listaSubCategoriasCD:[],
        idsubcategoriacd:null,
        listaProdutosCD:[],
        pesquisa: '',
        listaIdPDVS: [],
    }),
    async mounted(){
        await this.getPDVSCD()
    },
    computed: {
        itensFiltrados(){
            return this.listaProdutosCD.filter(item => {
                if(this.pesquisa.toLowerCase().trim() === '') {
                    return item.nomecd
                } else {
                    return item.nomecd.toLowerCase().includes(this.pesquisa.toLowerCase())
                }
                    
            })
        },
    },
    methods:{
        adicionarItem(prod){
            this.$emit('add',prod)
        },
        direcionar(){
            this.$router.push(this.url)
        },
        getPDVSCD(){
            // pdv/getPDVSCD/0/1/'+this.tipoLocal+'/hospede
            // /getPDVSCD/:idpdv/:flativo/:idlocal/:tipousuario  
            this.$http('pdv/getPDVSCD/0/').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listaPDVsCD = [ 
                        {
                            idpdv: null,
                            descpdv: "Todos",
                            ativo: true,
                            allinclusive: false,
                            seg: true,
                            ter: true,
                            qua: true,
                            qui: true,
                            sex: true,
                            sab: true,
                            dom: true,
                            idimpressora: null,
                            nome: null,
                            ip: null,
                            img: null
                        },
                        ...resp.data,
                    ]
                    this.idpdvcd = this.listaPDVsCD[0].idpdv
                    this.listaIdPDVS = resp.data.map(item => item.idpdv)
                    await this.getProdutosCD()
                }
            }).catch((error)=>{

                this.$alertar('warning',error,'=(')
            })
        },
        getCategoriasCD(){
            this.listaCategoriasCD = []
            if(this.idpdvcd === null){
                this.idpdvcd = null,
                this.idcategoriacd = null,
                this.idsubcategoriacd = null,
                this.pesquisa =  '',
                this.getProdutosCD()
            } else {    
                const url = `pdv/getCategorias/${this.idpdvcd}/0/garcom`
                this.$http(url).then((resp) =>{
                // this.$http('pdv/getCategorias/'+this.idpdvcd+'/0').then((resp) =>{
                    if(resp.data && resp.data.length > 0){
                        this.listaCategoriasCD = resp.data
                        console.log(this.listaCategoriasCD)
                        this.idcategoriacd = this.listaCategoriasCD[0].idcategoriaprodcd
                        this.getSubCategoriasCD()
                    }
                }).catch(error =>{
                    console.log(error)
                })
            }


        },
        getSubCategoriasCD(){
            localStorage.removeItem('idsubcatcd')
            this.idsubcategoriacd = 0
            this.listaSubCategoriasCD = []
            this.listaProdutosCD = []

            const url = `pdv/getSubCategorias/${this.idcategoriacd}/0/garcom`;

            this.$http(url).then((resp) =>{
            // this.$http('pdv/getSubCategorias/'+this.idcategoriacd+'/0').then((resp) =>{
                this.listaSubCategoriasCD = resp.data
                if(this.listaSubCategoriasCD.length > 0){
                    this.idsubcategoriacd = this.listaSubCategoriasCD[0].idsubcategoriaprodcd
                }
                this.getProdutosCD()
            }).catch(error =>{
                console.log(error)
            })
        },
        getProdutosCD(){
            this.listaProdutosCD = []
            
            localStorage.setItem('idcatcd', this.idcategoriacd)
            localStorage.setItem('idsubcatcd', this.idsubcategoriacd)

            let url = `pdv/getProdutos/${this.idcategoriacd}/${this.idsubcategoriacd}?tipousuario=garcom`

            // let url = `pdv/getProdutos/${this.idcategoriacd}/${this.idsubcategoriacd}`

            // if(this.idpdvcd === null){
            //     url += `&listaIdPDVS=${this.listaIdPDVS}`
            // }


            // this.$http('pdv/getProdutos/'+this.idcategoriacd+'/'+this.idsubcategoriacd + (this.idpdvcd === null ? `?listaIdPDVS=${this.listaIdPDVS}` : '')).then((resp) =>{
            this.$http(url).then((resp) =>{
              this.listaProdutosCD = resp.data
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os Produtos","=(")
            })
        },
    }
  }
</script>


<style lang="scss" scoped >
    .ddpms{
        /* background-color: white; */
        width:63%;
        margin-left:2%;
        height:100%!important;
    }

    .ddpmsprod{
        
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }

    #cardpesquisa {
        margin-left: 3%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #input {
        position: relative;
        border: 1px solid black;
        
        border-radius: 10px;
        max-width: 29%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        
        input {
            padding: 10px;
            width: 100%;
            height: 100%;
            border-radius: 10px;

            &:focus {
                outline: none;
            }
        }

        img {
            width: 17px;
            height: auto;
            margin-left: 10px;
        }
    }

</style>
