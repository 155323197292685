<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logowrapper">
        <div class="logodiv" style="background-colo: red">
          <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
        </div>
        <div v-if="!buscandoCheckins">
          <v-card
              class="mx-auto card"
              width="350"
              v-for="checkin in checkins"
              :key="checkin.id"
              cursor="pointer"
              @click="login(checkin)"
          >
            <v-card-title>
              {{checkin.nome}}
            </v-card-title>

            <v-card-subtitle>
              Quarto: {{checkin.coduh}}
            </v-card-subtitle>

<!--            <v-card-actions>-->
<!--              <v-btn-->
<!--                  icon-->
<!--                  @click="show = !show"-->
<!--              >-->
<!--                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->

<!--            <v-expand-transition>-->
<!--              <div v-show="show">-->
<!--                <v-divider></v-divider>-->

<!--                <v-card-text>-->
<!--                  I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.-->
<!--                </v-card-text>-->
<!--              </div>-->
<!--            </v-expand-transition>-->
          </v-card>
        </div>
        <div v-else>
          <v-card
              class="mx-auto card"
              width="350"
              cursor="pointer"
              @click="avisoCarregando()"
              :loading="true"
          >
            <v-card-title>
              Hospéde
            </v-card-title>

            <v-card-subtitle>
              Quarto: 01
            </v-card-subtitle>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginQRCODE",
  data() {
    return {
      quarto: "",
      checkins: [],
      buscandoCheckins: false,
    };
  },
  components: {},
  methods: {
    async buscarCheckinsPorUH() {
      this.buscandoCheckins = true

      const quarto = encodeURIComponent(this.quarto)
      const url = `${this.$dados.BUILD_API_URL}/hospedes/buscarCheckinsPorUH/${quarto}`
      await this.$http
          .get(url)
          .then(({data}) => {
            this.checkins = data
            if(data.length === 0) {
              this.$alertar("warning", "Erro", "Não foi encontrado nenhum hospéde para este quarto.")
            }
          })
          .catch(() => {
            this.$alertar("warning", "Erro", "Não foi encontrado nenhum checkin para este quarto.")
          })

      this.buscandoCheckins = false;
    },
    async login(checkin) {
      localStorage.setItem("pessoaLogada", "true");
      localStorage.setItem("token", checkin.token);
      localStorage.setItem("nome", checkin.nome);
      localStorage.setItem("email", checkin.email);
      localStorage.setItem("nomehotel", checkin.nomehotel);
      localStorage.setItem("idhotel", checkin.idhotel);
      localStorage.setItem("numreserva", checkin.numreserva);
      localStorage.setItem("coduh", checkin.coduh);
      localStorage.setItem("datacheckin", checkin.datacheckin);
      localStorage.setItem("datacheckoutprev", checkin.datacheckoutprev);
      localStorage.setItem("idreservasfront", checkin.idreservasfront);
      localStorage.setItem("idhospede", checkin.idhospede);
      localStorage.setItem("idcheckin", checkin.idcheckin);
      localStorage.setItem("cartaoconsumo", checkin.cartaoconsumo);
      localStorage.setItem("tipo_usuario", "HOSPEDE");
      localStorage.setItem("passante", checkin.passante);
      localStorage.setItem("login_mesa", "false");
      this.$store.dispatch("set_titulo", "Cardápio");
      this.$http.defaults.headers.common["x-access-token"] = checkin.token;
      this.$router.push("/Hospede/Bemvindo");
    },
    avisoCarregando(){
      this.$alertar("success", "Carregando...", "Aguarde um momento, estamos carregando os dados do seu checkin.")
    },
    async verificarIntegracaoNovoCardapioHospede() {
      this.$http(this.$dados.BUILD_API_URL +'/pdv/buscarIntegracaoNovoCardapioHospedes').then((res) => {
        if(!res.data) {
          return
        }

        if(res.data.ativo) {
          window.location.replace(`https://cardapiodigital.cd.economysoftware.com.br/${res.data.nome_cliente}/login?quarto=${this.quarto}`)
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  async mounted() {
    this.quarto = decodeURIComponent(this.$route.query.quarto);

    await this.verificarIntegracaoNovoCardapioHospede();

    await this.buscarCheckinsPorUH();
  },
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  width: 80vw;
  max-width: 350px;
}

.logowrapper{
  display: grid;
  place-content: center;
}
.logodiv {
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;
}

.card {
  margin-bottom: 16px;
}

.card:hover {
  transform: scale(1.05);
  transition: 0.2s;

}

</style>
