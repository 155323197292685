<template>
    <div class="bodygenerico">
        <div class="bodyFlex">
            <ColunaEsquerda @setarTela="(tela) => setarTela(tela)" /> 
            <ColunaDireita :telaatual="telaatual" @salvar="(parametros) => salvar(parametros)"  /> 
        </div>
    </div>
</template>

<script>

import ColunaEsquerda from '../../components/layout/ColunaEsquerda.vue'
import ColunaDireita from '../../components/layout/ColunaDireita.vue'
// import ColunaPedido from '../../components/pedidos/ColunaPedido.vue'
// import ModalImpressaoPedido from '../../components/layout/ModalImpressaoPedido.vue'

// const dayjs = require('dayjs')

export default {
  name: 'ViewCaixa',
  components:{ 
    ColunaEsquerda,
    ColunaDireita,
    // ColunaPedido,
    // ModalImpressaoPedido
  },
  data: () => ({
    telaatual:'fluxo',
  }),
  methods:{
      continuar(){
          this.$router.push('/Hospede/Menu')
      },
      async setarTela(tela){
        this.telaatual = tela
      },
      salvar(parametros){
            if(parametros.tela == 'fluxo'){
                this.$http.put('pdv/updateparametros',parametros.parametro)
                .then(() =>{
                    this.$alertar("success","Parametro Atualizado com Sucesso!", "=)")
                }).catch(() =>{
                    this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
                })
            }
      }
     

  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Configurações")
  }
};
</script>


<style scoped>
    
</style>
