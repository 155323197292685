<template>
  <v-container>
<!--    <div class="itemList"  v-if="tipoLocal > 0">-->
    <div class="itemList">
        <pdv v-for="pdv in listadepdvs" :key="pdv.idpdv" :pdv="pdv" />
    </div>

    <ModalConfirmacaoLocal v-if="!passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textolocal1="$t('modallocal.btn1')" @funcaoLocal="($event)=>setarLocal($event)"
        :textolocal2="$t('modallocal.btn2')" @funcaoLocal2="()=>setarLocal('RESTAURANTE')"
        :textolocal3="$t('modallocal.btn3')" @funcaoLocal3="()=>setarLocal('EVENTOS')"
        :textolocal4="$t('modallocal.btn4')" @funcaoLocal4="()=>setarLocal('OUTROS')"
        :textocancelar="$t('modallocal.btnvoltar')" @cancelar="() =>{exibirModalLocal = false}"  />

    <ModalConfirmacaoLocal v-if="passante" :showModal="exibirModalLocal" :titulo="$t('modallocal.ondevcesta')" :exibeBtnCancelar="true"
        :textolocal1="'-'"                   @funcaoLocal="($event)=>setarLocal($event)"
        :textolocal2="$t('modallocal.btn2')" @funcaoLocal2="()=>setarLocal('RESTAURANTE')"
        :textolocal3="'-'"                   @funcaoLocal3="()=>setarLocal('EVENTOS')"
        :textolocal4="$t('modallocal.btn4')" @funcaoLocal4="()=>setarLocal('OUTROS')"
        :textocancelar="$t('modallocal.btn3')" @cancelar="() =>{exibirModalLocal = false}"  />
  </v-container>
</template>

<script>
  import ModalConfirmacaoLocal from '../layout/ModalConfirmacaoLocal.vue'
  // import TitleH1 from './TitleH1.vue'
  import Pdv from './Pdv.vue'
  export default {
    name: 'PDVList',
    components:{
      Pdv,
      ModalConfirmacaoLocal
    },
    data: () => ({
      listadepdvs:[],
      exibirModalLocal:true,
      tipoLocal:0,
      passante:false,
    }),
    methods:{

      setarLocal(local){
        this.tipoLocal = local.id_localidade;
        this.exibirModalLocal = false;
        this.getPdvs();
      },

      getPdvs(){
          // this.$http('pdv/getPdvs').then((resp) =>{

        const url = 'pdv/getPDVSCD/0/1/'+this.tipoLocal+'/hospede'

        console.log(url)

          this.$http(url).then((resp) =>{
            this.listadepdvs = resp.data
          }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel exibir os PDVs","=(")
          })
      },

      getProdutosNoCarrinho(){
            this.$http('pdv/getProdutosNoCarrinho').then((resp) =>{
              console.log(">>>>>>>>>>>>>>>>>>>>")
              console.log("resp")
              console.log(resp)
              if(resp.data.itensPedido && resp.data.itensPedido.length > 0){
                if(resp.data.itensPedido[0].idpdv > 0){
                    this.$router.push('/Hospede/Categorias/'+resp.data.itensPedido[0].idpdv)
                }
              }
              // this.listadeprodutos = resp.data.itensPedido
              // this.valorTotal = 0
              // for(var i = 0, len = this.listadeprodutos.length; i < len; i++){
              //     this.valorTotal += this.listadeprodutos[i].precounitario *this.listadeprodutos[i].quantidade
              //     this.idcomanda = this.listadeprodutos[i].idcomanda
              // }
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
            })
        },


    },
    mounted(){
      if(localStorage.getItem('passante')){
        this.passante = localStorage.getItem('passante').toUpperCase() == 'TRUE'? true: false
      }

      if(!localStorage.getItem('pessoaLogada') && !localStorage.getItem('pessoaLogada') === 'false'){
        this.getProdutosNoCarrinho()
        this.getPdvs()
      }

    }
  }
</script>

<style scoped>
    .itemList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
    }
</style>
