var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center"},[_vm._v("Regras de Comissionamento")])]),_c('v-col',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{staticClass:"centered-input",staticStyle:{"min-width":"180px"},attrs:{"disabled":!_vm.ehMaster,"type":"number","dense":"","min":"1","max":"28","label":"Data de Fechamento Mensal","outlined":""},on:{"input":function($event){_vm.diaAlterado = true}},model:{value:(_vm.diaFechamento),callback:function ($$v) {_vm.diaFechamento=$$v},expression:"diaFechamento"}}),(_vm.diaAlterado)?_c('label',{staticStyle:{"color":"red!important"}},[_vm._v("Atenção! A mudança na data de fechamento pode acarretar discrepâncias no relatório de comissões!")]):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"disabled":!_vm.diaAlterado,"small":"","elevation":"","color":"primary"},on:{"click":function($event){return _vm.salvarDataFechamento()}}},[_vm._v(" Salvar ")])],1)],1)],1)],1),_c('v-card',[_c('div',{staticClass:"es-tabs"},[_c('div',{staticClass:"es-tab active",on:{"click":function($event){return _vm.changeTab(0)}}},[_vm._v("Regras Vigentes")]),_c('div',{staticClass:"es-tab",on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v("Regras Inativas")])])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.regra",fn:function(ref){
var item = ref.item;
return [(item.id_comissao_regra === _vm.editedItem.id_comissao_regra)?_c('v-text-field',{ref:("regra_" + (item.id_comissao_regra)),attrs:{"id":("regra_" + (item.id_comissao_regra)),"hide-details":true,"dense":"","single-line":""},model:{value:(_vm.editedItem.regra),callback:function ($$v) {_vm.$set(_vm.editedItem, "regra", $$v)},expression:"editedItem.regra"}}):_c('span',[_vm._v(_vm._s(item.regra))])]}},{key:"item.padrao",fn:function(ref){
var item = ref.item;
return [(item.id_comissao_regra === _vm.editedItem.id_comissao_regra)?_c('v-select',{attrs:{"items":_vm.padraoSimNao,"item-text":"nome","item-value":"valor"},model:{value:(_vm.editedItem.padrao),callback:function ($$v) {_vm.$set(_vm.editedItem, "padrao", $$v)},expression:"editedItem.padrao"}}):_c('span',{class:{ 'es-green': item.padrao }},[_vm._v(_vm._s(item.padrao ? "Sim" : "Não"))])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(item.id_comissao_regra === _vm.editedItem.id_comissao_regra)?_c('v-select',{attrs:{"items":_vm.statusList,"item-text":"nome","item-value":"valor"},model:{value:(_vm.editedItem.ativo),callback:function ($$v) {_vm.$set(_vm.editedItem, "ativo", $$v)},expression:"editedItem.ativo"}}):_c('span',{class:{ 'es-red': !item.ativo }},[_vm._v(_vm._s(item.ativo ? "Ativa" : "Inativa"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(item.id_comissao_regra === _vm.editedItem.id_comissao_regra)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":function($event){return _vm.closeEdit()}}},[_vm._v(" mdi-window-close ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.salvarComissao()}}},[_vm._v(" mdi-content-save ")])],1):_c('div',[(_vm.ehMaster)?_c('v-icon',{staticClass:"light-green",attrs:{"title":"Editar"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{staticClass:"light-blue",attrs:{"title":"Definir Metas"},on:{"click":function($event){return _vm.definirMetas(item)}}},[_vm._v(" mdi-trending-up ")]),_c('v-icon',{staticClass:"light-red",attrs:{"title":"Definir Produtos"},on:{"click":function($event){return _vm.definirProdutos(item)}}},[_vm._v(" mdi-tag-outline ")])],1)])]}},(_vm.ehMaster)?{key:"footer.page-text",fn:function(){return [(_vm.editedIndex === -1)?_c('v-btn',{attrs:{"small":"","elevation":"","color":"success"},on:{"click":function($event){return _vm.novaRegra()}}},[_vm._v(" + NOVA REGRA ")]):_vm._e()]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }