<template>
  <div>
    

    <!-- <BotaoVoltar :url="'/Hospede/SubCategorias/'+$route.params.idpdv+'/'+$route.params.idcategoriaprodcd" /> -->
    <div v-if="ojbPedidos.listadepedidos.length > 0">

        <div style="display:flex;justify-content:flex-end;margin-top:20px;">
            <v-btn medium elevation="" color="info" @click="exibirModalConfirmar = true">{{txtPagar}}</v-btn>
        </div>
        
        <PedidosLista  v-if="ojbPedidos" :ojbPedidos="ojbPedidos"   :corTexto="corTexto"    />

        <div style="display:flex;justify-content:flex-end;margin-top:20px;">
            <v-btn medium elevation="" color="info" @click="exibirModalConfirmar = true">{{txtPagar}}</v-btn>
        </div>

    </div>

    <div v-else style="display:flex;min-height:70vh;justify-content:center;align-items:center;">
        <span style="font-size:20px;text-align:center">{{this.carregando ? this.carregando : $t('naopossuipedidosstatus.texto')}}</span>
    </div>

    <ModalConfirmacao :showModal="exibirModalConfirmar" titulo="Como deseja Pagar?" 
                    textosim="Credito" textonao="Débito" textocancelar="Pix"  :exibeBtnCancelar="true"
                    @sim="()=>setTipoPagamento(1)" 
                    @nao="()=>setTipoPagamento(2)"
                    @cancelar="()=>setTipoPagamento(3)"    />



  </div>
</template>

<script>
  import PedidosLista from '../../components/pedidos/PedidosLista.vue'
  import ModalConfirmacao from '../../components/layout/ModalConfirmacao.vue'
//   import ModalConfirmacaoInput from '../../components/layout/ModalConfirmacaoInput.vue'



  export default {
    name: 'PAGAR',
    components: {
      PedidosLista,
      ModalConfirmacao,
    //   ModalConfirmacaoInput,
    },
    watch:{
        async statuscomanda(valor){
           
            this.ojbPedidos = null
            let idreservasfront = localStorage.getItem('idreservasfront')
            this.carregando = "Carregando itens"
            this.corTexto = this.listaCores[valor].cor
            let idstatus = valor 
            if(valor == 5){
                idstatus = '5,7'
            }
            this.ojbPedidos = await this.getComandasReservaPorStatus(idreservasfront,idstatus)
            this.carregando = null
        },
    },
    data: () => ({
        exibirModalConfirmar:false,
        txtPagar:"Efetuar Pagamento",
        listadepedidos:[],
        ojbPedidos:null,
        statuscomanda:5,
        listaCores:[
            {cor:"#55ad94"},
            {cor:"#55ad94"},
            {cor:"#55ad94"},
            {cor:"#555cdd"},
            {cor:"#d98e00"},
            {cor:"green"},
            {cor:"red"},
            {cor:"#d98e00"},
            {cor:"#d98e00"},
            {cor:"#d98e00"},
        ],
        corTexto:'',
        carregando:null,

    }),
    methods:{
        setTipoPagamento(valor){
            this.$store.dispatch("set_tipopagto" , valor);
            this.exibirModalConfirmar = false;
            if(valor != 3){
                this.iniciarPagamento()
            }else{
                this.direcionarPix()    
            }
        },
        direcionarPix(){
            this.txtPagar = "Iniciando..."
            this.$store.dispatch("set_valorpagto" , this.ojbPedidos.valorTotal);
            this.$store.dispatch("set_comandas" , this.ojbPedidos);
            this.$router.push('/Hospede/DadosPix')
        },
        iniciarPagamento(){
            this.txtPagar = "Iniciando..."

            // let comandas = []
            // for(let i=0,len= dados.listadepedidos.length; i < len ;i++){
            //     comandas += ','+dados.listadepedidos[i]
            // }
            
            this.$store.dispatch("set_valorpagto" , this.ojbPedidos.valorTotal);
            // this.$store.dispatch("set_tipopagto" , 1);
            this.$store.dispatch("set_comandas" , this.ojbPedidos);

            this.$http.post(`pagamento/criarCabecalho`,this.ojbPedidos).then(()=>{
                // this.$alertar("success","Pedido Enviado para Produção","=)")
                this.$http.post(`pagamento/listarGatewaysHotel`,localStorage.getItem('idhotel')).then((resp)=>{
                    let gateways = resp.data
                    let gateway = gateways.filter(x=> x.ativo === true)
                    
                    //Se for Paypal então irá direto para o efetuar o pagamento
                    if(gateway[0].ativo === true && gateway[0].id == 4){
                        this.$router.push('/Hospede/EfetuarPagamento')
                    }else{
                        this.$router.push('/Hospede/checkout')
                    }
                }).catch(()=>{
                    this.$alertar("warning","Não foi possível Consultar o Gateway Ativo","=)")
                })

                // 
                this.txtPagar = "EFETUAR PAGAMENTO"
            })
            .catch((error)=>{
                console.log(error)
                this.$alertar("success",error,"=(")
                this.txtPagar = "EFETUAR PAGAMENTO"
            })
        },
       
        async getPedidosPorStatus(){
            let idreservasfront = localStorage.getItem('idreservasfront')
            this.ojbPedidos = await this.getComandasReservaPorStatus(idreservasfront,this.statuscomanda)
            this.corTexto = this.listaCores[this.statuscomanda].cor
        },
        async getComandasReservaPorStatus(idreservasfront,idstatus){
            let objetoRetorno = {}
            console.log(idstatus)
            await this.$http('pdv/getComandasReservaPorStatus/'+idreservasfront+'/-1/t').then((resp) =>{
              console.log(resp.data)
              objetoRetorno.valorTotal = 0
              objetoRetorno.listadepedidos = resp.data
              console.log("objetoRetorno.listadepedidos")
              console.log(objetoRetorno.listadepedidos)
              for(var i = 0, len = objetoRetorno.listadepedidos.length; i < len; i++){
                  objetoRetorno.valorTotal += parseFloat(objetoRetorno.listadepedidos[i].valor)
                  objetoRetorno.statuscomanda = objetoRetorno.listadepedidos[i].statuscomanda
              }
              console.log("objetoRetorno")
              console.log(objetoRetorno)

            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
        },
        // retirarProdutoCarrinho(produto){
            
        //     let objetoproduto = {
        //         idprodutocomanda:produto.id,
        //         numpedido:produto.idcomanda
        //     }
        //     this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then(() =>{
        //       this.getComandasReservaPorStatus()
        //     }).catch(error =>{
        //       console.log(error)
        //       this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
        //     })
        // },
        // direcionar(){
        //     this.$router.push('/Hospede/Menu')
        // },
        // finalizarPedido(){
        //     this.exibirModalLocal = true
        // }
    },
    mounted(){
        this.$store.dispatch("set_titulo", "Pagar")
        // this.getComandasReservaPorStatus()
        this.getPedidosPorStatus()
    }
  }
</script>

<style scoped>
    .shoppingCarAddLine{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height:20vh;
        border-top: 1px solid rgb(156, 156, 156);
    }

    .shoppingCarAddLineAddText{
        /* background-color: yellow; */
        width: 100%;
        height: 30%;
        display:flex;
        align-items: flex-end;
        justify-content: center;
        color:#4CAF50;
        font-weight: bold;
    }

    

    .shoppingCarAddLineInner{
        /* background-color: blue; */
        display: flex;
        align-items: center;
        /* position: fixed;
        bottom: 0px; */
        width: 100%;
        height: 70%;
        /* border-top: 1px solid rgb(156, 156, 156); */
    }

    .shoppingCarAddLineTotal{
        width:30%;
        /* background-color: blue; */
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;

    }

    .shoppingCarAddLineAdd{
        width:70%;
        /* background-color: red; */
        height:50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoppingCarAddLineAddBtn{
        background-color: #4CAF50;
        height:50px;
        width: 90%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0px 7%;
        font-size: 1.1em;
        color:white;
        font-family: "Roboto", sans-serif!important;
        font-weight: bold;
    }

    .shoppingCarAddLineTotalBox{
        border: 1px solid rgb(156, 156, 156);
        border-radius: 5px;
        display: flex;
        height:50px;
        width: 90%;
        margin-left: 10%;
        align-items: center;
        justify-content: space-between;
    }

    .shoppingCarAddLineTotalBoxA{
        /* background-color: blue; */
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }

    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 50px;
        padding:10px;
        border-radius: 10px;
  
    }
</style>