<template>
  <div>
    <v-row class="mb-2">
      <v-col cols="2">
        <v-btn large elevation="" color="primary" @click="voltar()">
          VOLTAR
        </v-btn>
      </v-col>
      <v-col cols="5">
        <v-select
          v-model="id_comissao_regra"
          item-text="regra"
          item-value="id_comissao_regra"
          :items="comissoes"
          filled
          label="Regras"
          @change="recarregarProdutos()"
        ></v-select>
      </v-col>
    </v-row>
    <div class="top-title">
      <h2 class="text-center pb-2">Produtos na Regra</h2>
      <h2 class="text-center pb-2">Produtos Fora da Regra</h2>
    </div>
    <div class="es-painel">
      <v-card>
        <!-- Filtro Na Regra -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                v-model="idpdvNaRegra"
                :items="pdvsNaRegra"
                item-text="pdv"
                item-value="idpdv"
                label="PDV"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="idCatNaRegra"
                :items="catNaRegra"
                item-text="categoria"
                item-value="idcategoria"
                label="Categoria"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="idSubCatNaRegra"
                :items="subCatNaRegra"
                item-text="subcategoria"
                item-value="idsubcategoria"
                label="Subcategoria"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Produtos na Regra -->
        <v-data-table
          v-model="selecionadosNaRegra"
          :headers="cabsNaRegra"
          :items="produtosNaRegraFiltrado"
          item-key="item_key"
          show-select
          dense
        >
          <template v-slot:[`item.produto`]="{ item }">
            <span>{{ `${item.produto} (${item.codprodutopms})` }}</span>
          </template>
          <template v-slot:[`item.preco`]="{ item }">
            <span>{{ item.preco | formatarMoeda }}</span>
          </template>
        </v-data-table>
      </v-card>
      <!-- Coluna do meio -->
      <div class="es-adicionar-remover">
        <v-icon :disabled="!ehMaster"
          class="light-blue"
          color="primary"
          @click="adicionarSelecionados()"
          title="Adicionar"
          size="36"
        >
          mdi-chevron-double-left
        </v-icon>
        <v-icon :disabled="!ehMaster"
          class="light-blue"
          color="primary"
          @click="removerSelecionados()"
          title="Remover"
          size="36"
        >
          mdi-chevron-double-right
        </v-icon>
      </div>
      <v-card class="mr-2">
        <!-- Filtro Fora da Regra -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                v-model="idpdvForaDaRegra"
                :items="pdvsForaDaRegra"
                item-text="pdv"
                item-value="idpdv"
                label="PDV"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="idCatForaDaRegra"
                :items="catForaDaRegraFiltrada"
                item-text="categoria"
                item-value="idcategoria"
                label="Categoria"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="idSubCatForaDaRegra"
                :items="subCatForaDaRegraFiltrada"
                item-text="subcategoria"
                item-value="idsubcategoria"
                label="Subcategoria"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Produtos Fora da Regra -->
        <v-data-table
          v-model="selecionadosForaDaRegra"
          :headers="cabsForaDaRegra"
          :items="produtosForaDaRegraFiltrado"
          item-key="item_key"
          show-select
          dense
        >
          <template v-slot:[`item.produto`]="{ item }">
            <span>{{ `${item.produto} (${item.codprodutopms})` }}</span>
          </template>
          <template v-slot:[`item.preco`]="{ item }">
            <span>{{ item.preco | formatarMoeda }}</span>
          </template>
          <!-- <template v-slot:[`item.comissionavel`]="{ item }">
            <v-select
              v-if="item.codprodutopms === itemEditadoForaDaRegra.codprodutopms"
              :ref="`foraDaRegra_${item.codprodutopms}`"
              v-model="itemEditadoForaDaRegra.comissionavel"
              :items="comissionavelSimNao"
              item-text="nome"
              item-value="valor"
            >
            </v-select>
            <span v-else :class="{ 'es-red': !item.comissionavel }">{{
              item.comissionavel
                ? `${comissionavelSimNao[0].nome}`
                : `${comissionavelSimNao[1].nome}`
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div style="display: flex; align-items: center">
              <div
                v-if="
                  item.codprodutopms === itemEditadoForaDaRegra.codprodutopms
                "
              >
                <v-icon
                  color="red"
                  class="mr-3"
                  @click="fecharEdicaoForaDaRegra()"
                >
                  mdi-window-close
                </v-icon>
                <v-icon color="green" @click="salvarProdutoForaDaRegra()">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon
                  class="light-green"
                  @click="editarItemForaDaRegra(item)"
                  title="Editar"
                >
                  mdi-pencil
                </v-icon>
              </div>
            </div>
          </template> -->
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComissaoProdutos",
  data: () => ({
    comissao: {},
    items: [],
    defaultItem: {
      idproduto: 0,
      comissionavel: true,
      percentual_comissao: 0.5,
    },
    comissoes: [],
    id_comissao_regra: -1,
    // Na Regra
    cabsNaRegra: [
      { text: "Produto (Cód. PMS)", value: "produto" },
      { text: "PDV", value: "pdv" },
      { text: "Categoria", value: "categoria" },
      { text: "Preço", value: "preco" },
    ],
    itemEditadoNaRegra: {},
    indiceEditadoNaRegra: -1,
    produtosNaRegra: [],
    pdvsNaRegra: [{ idpdv: 1, pdv: "PDV Teste" }],
    idpdvNaRegra: -1,
    catNaRegra: [{ idcategoria: 1, categoria: "Categoria Teste" }],
    idCatNaRegra: -1,
    subCatNaRegra: [{ idsubcategoria: 1, subcategoria: "SubCategoria Teste" }],
    idSubCatNaRegra: -1,
    selecionadosNaRegra: [],
    // Fora da Regra
    cabsForaDaRegra: [
      { text: "Produto (Cód. PMS)", value: "produto" },
      { text: "PDV", value: "pdv" },
      { text: "Categoria", value: "categoria" },
      { text: "Preço", value: "preco" },
      // { text: "Comissionável", value: "comissionavel" },
      // { text: "Ações", value: "actions" },
    ],
    itemEditadoForaDaRegra: {},
    indiceEditadoForaDaRegra: -1,
    produtosForaDaRegra: [],
    pdvsForaDaRegra: [{ idpdv: 1, pdv: "PDV Teste Fora" }],
    idpdvForaDaRegra: -1,
    catForaDaRegra: [{ idcategoria: 1, categoria: "Categoria Teste Fora" }],
    idCatForaDaRegra: -1,
    subCatForaDaRegra: [
      { idsubcategoria: 1, subcategoria: "SubCategoria Teste Fora" },
    ],
    idSubCatForaDaRegra: -1,
    selecionadosForaDaRegra: [],
    comissionavelSimNao: [
      { nome: "Sim", valor: true },
      { nome: "Não", valor: false },
    ],
    ehMaster: false,
  }),
  computed: {
    catForaDaRegraFiltrada() {
      if (this.idpdvForaDaRegra === -1) {
        return this.catForaDaRegra;
      }
      let filtradas = this.catForaDaRegra.filter(
        (x) => x.idpdv === this.idpdvForaDaRegra || x.idcategoria === -1
      );
      return filtradas;
    },
    subCatForaDaRegraFiltrada() {
      if (this.idCatForaDaRegra === -1) {
        return this.subCatForaDaRegra;
      }
      let filtradas = this.subCatForaDaRegra.filter(
        (x) =>
          x.idcategoria === this.idCatForaDaRegra || x.idsubcategoria === -1
      );
      return filtradas;
    },
    produtosNaRegraFiltrado() {
      let filtrados = this.filtrarProdutosUnicos(
        this.produtosNaRegra,
        "idpdv",
        this.idpdvNaRegra,
        "idcategoria",
        this.idCatNaRegra,
        "idsubcategoria",
        this.idSubCatNaRegra
      );
      return filtrados;
    },
    produtosForaDaRegraFiltrado() {
      let filtrados = this.filtrarProdutosUnicos(
        this.produtosForaDaRegra,
        "idpdv",
        this.idpdvForaDaRegra,
        "idcategoria",
        this.idCatForaDaRegra,
        "idsubcategoria",
        this.idSubCatForaDaRegra
      );
      return filtrados;
    },
  },
  methods: {
    recarregarProdutos() {
      localStorage.setItem("id_comissao_regra", this.id_comissao_regra);
      this.buscarProdutosNaRegra();
      this.buscarProdutosForaDaRegra();
    },
    voltar() {
      window.history.back();
    },
    filtrarProdutosUnicos(
      listaItens,
      chave1,
      valor1,
      chave2,
      valor2,
      chave3,
      valor3
    ) {
      const itensUnicos = [];
      let lista = listaItens.filter(
        (x) => valor1 === -1 || x[chave1] === valor1
      );
      lista = lista.filter((x) => valor2 === -1 || x[chave2] === valor2);
      lista = lista.filter((x) => valor3 === -1 || x[chave3] === valor3);
      lista.forEach((itemCorrente) => {
        const produtoExistente = itensUnicos.find(
          (c) => c.idpdv === itemCorrente.idpdv
            && c.idcategoria === itemCorrente.idcategoria
            && c.codprodutopms === itemCorrente.codprodutopms
        );
        if (!produtoExistente) {
          itensUnicos.push({
            idpdv: itemCorrente.idpdv,
            pdv: itemCorrente.pdv,
            idcategoria: itemCorrente.idcategoria,
            categoria: itemCorrente.categoria,
            codprodutopms: itemCorrente.codprodutopms,
            produto: itemCorrente.produto,
            preco: itemCorrente.preco,
            comissionavel: itemCorrente.comissionavel,
            item_key: itemCorrente.idpdv.toString() +itemCorrente.idcategoria.toString() + itemCorrente.codprodutopms
          });
        }
      });
      itensUnicos.sort((a, b) => a.produto.localeCompare(b.produto));
      return itensUnicos;
    },
    fecharEdicaoForaDaRegra() {
      setTimeout(() => {
        this.itemEditadoForaDaRegra = Object.assign({}, this.defaultItem);
        this.indiceEditadoForaDaRegra = -1;
      }, 300);
    },
    editarItemForaDaRegra(item) {
      this.indiceEditadoForaDaRegra = this.items.indexOf(item);
      this.itemEditadoForaDaRegra = Object.assign({}, item);
      this.$nextTick(() =>
        this.$refs[`foraDaRegra_${item.codprodutopms}`].focus()
      );
    },
    async salvarProdutoForaDaRegra() {
      if (this.indiceEditadoForaDaRegra > -1) {
        Object.assign(
          this.produtosForaDaRegra[this.indiceEditadoForaDaRegra],
          this.itemEditadoForaDaRegra
        );
      }
      this.itemEditadoForaDaRegra.id_comissao_regra = this.id_comissao_regra;
      this.$http
        .post("comissao/produto/salvar", this.itemEditadoForaDaRegra)
        .then(async () => {
          this.$alertar("success", "Produto fora da regra salvo!", "=)");
          this.fecharEdicaoForaDaRegra();
          this.buscarProdutosForaDaRegra();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
          this.fecharEdicaoForaDaRegra();
        });
    },
    removerSelecionados() {
      let params = {
        id_comissao_regra: this.id_comissao_regra,
        produtos: this.selecionadosNaRegra.map((x) => ({
          idpdv: x.idpdv, 
          idcategoria: x.idcategoria, 
          codprodutopms: x.codprodutopms
        })),
      };
      this.$http
        .post("comissao/produto/remover", params)
        .then(async () => {
          this.buscarProdutosNaRegra();
          this.buscarProdutosForaDaRegra();
          this.selecionadosNaRegra = [];
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    adicionarSelecionados() {
      let params = {
        id_comissao_regra: this.id_comissao_regra,
        produtos: this.selecionadosForaDaRegra.map((x) => ({
          idpdv: x.idpdv, 
          idcategoria: x.idcategoria, 
          codprodutopms: x.codprodutopms
        })),
      };
      this.$http
        .post("comissao/produto/adicionar", params)
        .then(async () => {
          this.buscarProdutosNaRegra();
          this.buscarProdutosForaDaRegra();
          this.selecionadosForaDaRegra = [];
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    buscarComissoes() {
      this.$http
        .post("comissao/buscar", { vigentes: true })
        .then(async (res) => {
          this.comissoes = res.data;
          this.id_comissao_regra = parseInt(localStorage.getItem("id_comissao_regra"));
          this.buscarProdutosNaRegra();
          this.buscarProdutosForaDaRegra();
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    buscarProdutosNaRegra() {
      this.$http
        .post("comissao/produto/buscar", {
          id_comissao_regra: this.id_comissao_regra,
          naRegra: true,
        })
        .then(async (res) => {
          this.produtosNaRegra = res.data;
          //console.error(`this.produtosNaRegra.length:${this.produtosNaRegra.length}`)
          this.pdvsNaRegra = this.filtrarUnicas(
            this.produtosNaRegra,
            "idpdv",
            "pdv"
          );
          this.catNaRegra = this.filtrarUnicas(
            this.produtosNaRegra,
            "idcategoria",
            "categoria",
            "idpdv"
          );
          this.subCatNaRegra = this.filtrarUnicas(
            this.produtosNaRegra,
            "idsubcategoria",
            "subcategoria",
            "idcategoria"
          );
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
    filtrarUnicas(listaItens, chave, valor, chavePai) {
      const itensUnicos = [];
      listaItens.forEach((itemCorrente) => {
        const itemExistente = itensUnicos.find(
          (c) => parseInt(c[chave]) === parseInt(itemCorrente[chave])
        );
        if (!itemExistente) {
          itensUnicos.push({
            [chave]: itemCorrente[chave],
            [valor]: itemCorrente[valor],
            [chavePai]: itemCorrente[chavePai],
          });
        }
      });
      itensUnicos.sort((a, b) => a[valor].localeCompare(b[valor]));
      itensUnicos.unshift({ [chave]: -1, [valor]: "Todos" });
      return itensUnicos;
    },
    buscarProdutosForaDaRegra() {
      this.$http
        .post("comissao/produto/buscar", {
          id_comissao_regra: this.id_comissao_regra,
          naRegra: false,
        })
        .then(async (res) => {
          this.produtosForaDaRegra = res.data;
          this.pdvsForaDaRegra = this.filtrarUnicas(
            this.produtosForaDaRegra,
            "idpdv",
            "pdv"
          );
          this.catForaDaRegra = this.filtrarUnicas(
            this.produtosForaDaRegra,
            "idcategoria",
            "categoria",
            "idpdv"
          );
          this.subCatForaDaRegra = this.filtrarUnicas(
            this.produtosForaDaRegra,
            "idsubcategoria",
            "subcategoria",
            "idcategoria"
          );
        })
        .catch((error) => {
          this.$alertar("warning", error, "=(");
        });
    },
  },
  mounted() {
    this.$store.dispatch("set_titulo", "Comissão de Produtos");
    this.buscarComissoes();
    this.ehMaster = (JSON.parse(localStorage.getItem('perfis')))?.includes(1);
  },
};
</script>
<style scoped>
.top-title {
  display: flex;
  justify-content: space-around;
  margin: 0.8rem;
}
.light-blue {
  color: blue !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-blue:hover {
  background-color: lightblue !important;
}
.light-green {
  color: green !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-green:hover {
  background-color: lightgreen !important;
}
.light-red {
  color: red !important;
  padding: 0.3rem;
  margin: 0.3rem;
  border-radius: 50%;
  background-color: transparent !important;
}
.light-red:hover {
  background-color: lightcoral !important;
}
.es-painel {
  display: flex;
  flex-direction: row;
}
.es-adicionar-remover {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.es-red {
  color: rgb(253, 253, 253);
  background-color: rgb(248, 53, 53);
  padding: 0.2rem;
  border-radius: 50%;
}
</style>