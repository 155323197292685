<template>
    <div class="container">
        <div class="center">
            <div>
                <img src="@/assets/img/pgtoaprovado.svg" 
                    width="auto" height="130" alt="Pagamento aprovado!" crossorigin />
            </div>
        </div>
        <div class="aprovado">Pagamento aprovado!</div>
        <button type="button" @click="$router.push('/Hospede/menu')" class="btn-menu">Voltar ao menu</button>

    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    }
}
</script>

<style scoped>

.container{
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;

}

.btn-menu{
  background: #1377f2;
  color: white;
  font-weight: bold;
  width: fit-content;
  padding: .5em 1em;
  border-radius: 1em;
  margin: 1em auto;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.aprovado {
    font-weight: normal;
    color: #1377f2 !important;
    font-weight: bold;
    font-size: 32px;
}
</style>