<template>
  <div  class="fundologin">
    <div v-if="exibirPagina" class="bodyformulario">
      <div class="logodiv" style="background-colo: red">
        <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
      </div>
      <v-row justify="center" style="color: white; font-size:16px;" class="ma-2">{{ email }}</v-row>
      <p>
        <span style="color: white; font-size: 20px;">
          Insira sua nova senha.<br />
          Type your new password.
        </span>
      </p>
      <div>
        <v-text-field
          type="password"
          v-model="senha1"
          label="Nova Senha / New Password"
          dense
          outlined
          clearable
          class="inputtext"
          background-color="white"
          placeholder="Nova Senha / New Password"
          :rules="senhasRules"
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="senha2"
          label="Confirmação / Confirmation"
          dense
          outlined
          clearable
          class="inputtext"
          background-color="white"
          placeholder="Confirmação / Confirmation"
          :rules="senha2Rules"
        ></v-text-field>        
        <v-btn
          large
          block
          color="info"
          class="white--text font-weight-bold"
          style="width: 140px"
          @click="alterar()"
        >
          OK
          <v-icon right dark>mdi-pencil</v-icon>
        </v-btn>
<!--         <div style="display: flex; align-items: center;">
          <v-switch v-model="usuarioSistema" color="orange"> </v-switch>
          <label style="color: white;">Usuário Sistema</label>
        </div> -->

        <v-row justify="center" class="mt-4">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>
    </div>
    <div v-if="exibirPagina == false" >
      {{ msgRetorno }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      senha1 : '',
      senha2 : '',
      email : '',
      msgRetorno : '',
      exibirPagina : true,
      senhasRules: [
        (v) => !!v || "Campo obrigatório",
        
        ],
        senha2Rules: [
        (v) => !!v || "Campo obrigatório",
        () => this.senha1 == this.senha2 || "Senhas conferem"
        ]
    };
  },

  components: {},
  methods :{
      async alterar(){
        if(this.senha1 != this.senha2){
          return
        }

        let body = {
          senha : this.senha1,
          email : this.email,
          token : this.$route.query.token,
          us : this.$route.query.us || ''
        }
        await this.$http.post('/auth/alteracaosenhalink', body).then(response =>{
          if(response.status == 200){
            alert('Alterado com sucesso')
            this.$router.push('/')

          }else{
            alert('Erro na alteração de senha')
          }
        })
      },
      async validaSolitacaoAlteracao(){
        await this.$http('/auth/consultaalteracaosenha', {
          params : {
            token : this.$route.query.token,
            us : this.$route.query.us || ''
          }
        }).then(response =>{
          if(response.status != 200){
            this.msgRetorno = "Link Inválido."
            //this.exibirPagina = false
            //this.$router.push('/')
          }else{
            this.email = response.data.email
            
          }
        })
      }
  },
  mounted(){
    console.log(this.$route.query.token)
    console.log(window.origin)
    this.validaSolitacaoAlteracao()
  }
};
</script>

<style scoped>
.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
}
</style>