<template>
    <div style="height:100%;display:flex;">
      <!-- <CardapioPedido @add="(item) => item.opcoes ? '' : add(item)"/> -->
      <CardapioPedido @add="adicionarProdutoCarrinho"/>
      <CardapioCarrinho
        @remove="remove"
        @getProdutosNoCarrinho="getProdutosNoCarrinho"
        :produtos="carrinho"
        :idcomanda="idcomanda"
        :idreservasfront="idreservasfront"
        :idcheckin="idcheckin"
        :hospede="hospede"
        :tipointegracao="tipointegracao"
        :feitoPorFecharComanda="feitoPorFecharComanda"
      />

      <ModalOpcionaisEscolha
        :exibirModal="exibirModalOpcionais"
        :produto="produtoSelecionado"

        @fecharModal="fecharModal"
        @setOpcoesSelecionadas="setOpcoesSelecionadas"
        @add="add"
      />
    </div>
</template>

<script>

import CardapioPedido from '../../components/cardapio/CardapioPedido.vue'
import CardapioCarrinho from '../../components/cardapio/CardapioCarrinho.vue'
import ModalOpcionaisEscolha from '../../components/layout/Modais/ModalOpcionaisEscolha.vue'
import swal from "sweetalert2";

export default {
  name: 'ViewHospedes',
  components:{
    CardapioPedido,
    CardapioCarrinho,
    ModalOpcionaisEscolha
},
  data: () => ({
    carrinho: [],
    valorTotal: 0,
    idcomanda: null,
    dadosComanda: null,
    idreservasfront: null,
    idcheckin:null,
    hospede:null,
    tipointegracao:null,
    feitoPorFecharComanda: false,

    
    exibirModalOpcionais: false,
    produtoSelecionado: null,
  }),
  watch: {
    exibirModalOpcionais(value) {
      if(!value) {
        this.produtoSelecionado = null
      }
    }
  },
  methods:{
    fecharModal() {
      this.exibirModalOpcionais = false;
    },
    async adicionarProdutoCarrinho(produto) {
      this.produtoSelecionado = null;
      this.setOpcoesSelecionadas(null);
      this.limpaOpcoes();
      this.exibirModalOpcionais = false;

      if(produto.opcoes) {
        this.produtoSelecionado = produto;
        this.exibirModalOpcionais = true;
      } else {
        await this.add(produto)
      }
    },
    setOpcoesSelecionadas(value) {
      this.opcoesSelecionada = value;
    },
    async add(produto){
      let index = this.carrinho.findIndex(p => p.idproduto == produto.idproduto)
      if(index == -1){
        const idsprodutoopcoes = this.opcoesSelecionada;

        const prod = await this.$http.post('pdv/getProduto', {idproduto: produto.idproduto}).then(res => res.data)
        this.dadosComanda = await this.$http.post(`pdv/getComandaAberta`, {idreservasfront: this.idreservasfront,idcheckin:this.idcheckin})
        this.dadosComanda = this.dadosComanda ? this.dadosComanda.data:null
        if(this.dadosComanda && this.dadosComanda.pedido && this.dadosComanda.pedido.numpedido > 0){



            let objetoProduto = {
                numpedido: this.dadosComanda.pedido.numpedido,
                quantidade: 1,
                precounitario: produto.precoimportado,
                codgrupoproduto: prod.codgrupoprodpms,
                codproduto: prod.codprodutopms,
                idproduto: produto.idproduto,
                idpdv: produto.idpdv,
                observacao: produto.observacao,
                flbebidaantes: produto.flbebidaantes,
                idreservasfront: this.idreservasfront,
                idcategoriaprodcd:produto.idcategoriaprodcd,
                idsubcategoriaprodcd:produto.idsubcategoriaprodcd,
                id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
                idsprodutoopcoes: idsprodutoopcoes,
            }

            this.carrinho.push(objetoProduto)

            await this.$http.post(`pdv/adicionarProdutoNaComanda`, objetoProduto)
            await this.getProdutosNoCarrinho(this.idreservasfront)

        }else{

          // if(localStorage.getItem("tipo_integracao").toUpperCase() === 'HITS' && !this.hospede.passante){
          //   this.$alertar("blocked-success", "Estamos abrindo uma nova comanda e verificando se a conta está aberta, por favor aguarde!" ,"=)");
          // }
          //
          // console.log(this.hospede.passante)


            const objetoenviar = {
              idhospede: parseInt(this.idhospede),
              idreservasfront: this.idreservasfront,
              idcheckin:this.idcheckin,
              importarHITS: {
                nome: this.hospede.nome,
                uh: this.hospede.coduh,
                passante: this.hospede.passante,
              },
              id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
              abertura_responsavel: 'garcom_web'
            }
            this.dadosComanda = await this.$http.post(`pdv/iniciarComanda`,objetoenviar )
              .catch(err => this.$alertar("warning", err.response.data.message ,"=("))

            this.dadosComanda = this.dadosComanda && this.dadosComanda.data
            this.idcomanda = this.dadosComanda.pedido

            if(this.dadosComanda && this.dadosComanda.pedido > 0){

                let objetoProduto ={
                    numpedido: this.dadosComanda.pedido,
                    quantidade: 1,
                    precounitario: produto.precoimportado,
                    codgrupoproduto: prod.codgrupoprodpms,
                    codproduto: prod.codprodutopms,
                    idproduto: produto.idproduto,
                    idpdv: produto.idpdv,
                    observacao: produto.observacao,
                    flbebidaantes: produto.flbebidaantes,
                    idreservasfront: this.idreservasfront,
                    idcategoriaprodcd:produto.idcategoriaprodcd,
                    idsubcategoriaprodcd:produto.idsubcategoriaprodcd,
                    id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
                    idsprodutoopcoes: idsprodutoopcoes,
                }

                this.carrinho.push(objetoProduto)

                await this.$http.post(`pdv/adicionarProdutoNaComanda`, objetoProduto)
                await this.getProdutosNoCarrinho(this.idreservasfront)


                swal.close()


            }else{
                this.$alertar("warning","Não foi possível iniciar uma nova comanda!","=(")
            }
        }
      }
    },
    exibicaoDasOpcoesEscolhidas(ids, opcoes) { 
      if(ids) {
        const opcoesEscolhidas = opcoes.filter(opcao => ids.includes(opcao.id))
        console.log(opcoesEscolhidas)

        const retorno = opcoesEscolhidas.map(item => ({
          id: item.id,
          descricao: item.descricao,
        }));

        return retorno;
      }

      return [];
    },
    async addOpcionais(produto) {
      await this.add(produto)
    },
    remove(produto){
      let index = this.carrinho.findIndex(p => p.idproduto == produto.idproduto)
      this.carrinho.splice(index, 1)
      let objetoproduto = {
      //o que é idproduto comanda?? qual a diferenca de id e idproduto
              idprodutocomanda: produto.id,
              numpedido: this.idcomanda,
              id_ultimo_usuario_atualizacao: localStorage.getItem("idusuario"),
          }
      this.$http.post('pdv/removerProdutoNaComanda',objetoproduto).then((resp) =>{
        console.log('resp removerProdutoNaComanda', resp)
        this.getProdutosNoCarrinho(this.idreservasfront)
      }).catch(error =>{
        console.log(error)
        this.$alertar("warning","Não foi possivel remover o produto do carrinho","=(")
      })
    },
    getProdutosNoCarrinho(idreservasfront){
      this.$http.post('pdv/getProdutosNoCarrinho', {idreservasfront: idreservasfront,idcheckin:this.idcheckin}).then((resp) =>{

        this.carrinho = resp.data.itensPedido
        this.valorTotal = 0
        for(var i = 0, len = this.carrinho.length; i < len; i++){
            this.valorTotal += this.carrinho[i].precounitario *this.carrinho[i].quantidade
            this.idcomanda = this.carrinho[i].idcomanda
        }

        this.getDadosHospede()

      }).catch(error =>{
        console.log(error)
        this.$alertar("warning","Não foi possivel exibir os produtos no carrinho","=(")
      })
    },
    getDadosHospede(){
        this.$http.post('hospedes/listar',{idcheckin:this.idcheckin}).then((resp) =>{
          this.hospede = resp.data[0]
        }).catch(error =>{
            console.log(error)
            this.$alertar("warning","Não foi possivel recuperar os dados do hospede","=(")
        })
    },
    limpaOpcoes() {
      this.opcoesSelecionada = [];
      this.multiselecao = false;
    }
  },
  async mounted(){
      this.$store.dispatch("set_titulo", "Editar Cardápio")
      const { idreservasfront, idhospede,idcheckin } = this.$route.params
      this.idreservasfront = idreservasfront == 'null'?null:idreservasfront
      this.idhospede = idhospede
      this.idcheckin = idcheckin
      await this.getProdutosNoCarrinho(idreservasfront)

      this.feitoPorFecharComanda = this.$route.query.feitoPorFecharComanda ? true : false
  },
  async beforeMount(){
    this.tipointegracao = this.$dados.BUILD_TIPO_INTEGRACAO
    //  await this.$http.post('pdv/buscarparametros',{chave:'TIPO_INTEGRACAO'})
    //   .then(resp =>{
    //       this.tipointegracao = resp.data[0].valor
    //   }).catch(error =>{
    //       console.log(error)
    //   })
  }
};
</script>


<style scoped>

</style>
