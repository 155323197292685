<template>
  <v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%">
    <v-card-text style="">
        <div style="height:7vh;max-height:7vh;overflow:auto;margin-top:20px;">
            <div style="padding-bottom:2px;display:flex;align-items:center;">
                <label style="font-size:18px;">Gerencie gateways </label>
                <!-- <v-btn 
                  color="#1976D2" 
                  class="tooltip"
                  rounded 
                  style="margin-left: 1em;" 
                  data-cy="btn-inserirGateway"
                  @click="inserirGateway">
                  <v-icon color="#f9f9f2">mdi-plus-circle-outline</v-icon>
                  <p class="tooltiptext">inserir gateway</p>
                
                </v-btn> -->
            </div>
        </div>
        <div class="" v-if="gateways.length > 0">
            <div  v-for="gateway,idx in gateways" :key="idx" data-cy="gateway" style="margin-top:1px;display:flex;flex-direction: column; margin-bottom: .5em; padding-bottom: .5em;">
                <!-- <div style="display:flex;width:100%;align-items: center">
                  <a data-cy="btn-removerGateway" style="margin-bottom: .2em; font-size: 12px" @click="() => removerGateway(gateway)">Excluir</a>
                </div> -->

                <div style="display:flex;width:100%;align-items: flex-end">
                    <span style="display: flex; flex-direction: column; align-items: flex-start; flex-shrink: 2;">
                      <label for="nome" style="font-size:14px;font-weight:bold; line-height: 1; margin-bottom: .5em;">Nome</label>
                      <div>
                          <input id="nome" :disabled="gatewayedicao != gateway" autocomplete="off" v-model="gateway.nome" data-cy="nome" maxlength="200" type="text"  placeholder="Nome" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px; outline: none;" />
                      </div>
                    </span>
                    <span style="display: flex; flex-direction: column; align-items: flex-start; flex-grow: 2; flex-shrink: 1;margin: 0 .5em;">
                      <label for="chaveid" style="font-size:14px;font-weight:bold; line-height: 1; margin-bottom: .5em;">Chave id</label>
                      <div style="width: 100%;">
                          <input id="chaveid" :disabled="gatewayedicao != gateway" autocomplete="off" v-model="gateway.idestabelecimento" data-cy="chaveid" maxlength="200" type="text"  placeholder="Chave Id" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px; outline: none;" />
                      </div>
                    </span>
                    <span style="display: flex; flex-direction: column; align-items: flex-start; flex-grow: 2; flex-shrink: 1">
                      <label for="apikey" style="font-size:14px;font-weight:bold; line-height: 1; margin-bottom: .5em;">Api Key</label>
                      <div style="width: 100%;">
                          <input id="apikey" :disabled="gatewayedicao != gateway" autocomplete="off" v-model="gateway.apikey" data-cy="apikey"  maxlength="200" type="text"  placeholder="Api Key" style="width:100%;height:30px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px; outline: none;" />
                      </div>
                    </span>
                    <span style="display: flex; flex-direction: row; align-items: flex-end; justify-content: space-around; flex: 1;">
                      <v-btn
                      class="tooltip"
                      style="margin: 0 .2em; padding: 0 1em !important;" 
                      color="#D18504" 
                      small 
                      data-cy="btn-editarGateway" 
                      @click="()=>editarGateway(gateway)">
                        <v-icon color="#f9f9f2">mdi-pencil-outline</v-icon>
                        <p class="tooltiptext">editar gateway</p>
                      </v-btn>
                      <v-btn
                      class="tooltip"
                      style="margin: 0 .2em; padding: .5em !important;" 
                      color="#1976D2" 
                      small 
                      data-cy="btn-salvarGateway" 
                      @click="()=>salvarGateway(gateway)">
                        <v-icon color="#f9f9f2">mdi-content-save</v-icon>
                        <p class="tooltiptext">salvar gateway</p>
                      </v-btn>
                      <v-btn
                      :class="gateway.ativo ? 'ativo tooltip' : 'inativo tooltip'"
                      style="margin: 0 .2em; padding: 0 1em !important;" 
                      color="#1976D2" 
                      small 
                      data-cy="btn-aitivarDesativarGatway" 
                      @click="()=>aitivarDesativarGatway(gateway)">
                        <p style="color: #f9f9f2;">{{JSON.stringify(gatewayativo) === JSON.stringify(gateway) ? 'Ativado' : 'Desativado' }}</p>
                        <p class="tooltiptext">{{JSON.stringify(gatewayativo) === JSON.stringify(gateway) ? 'Gateway Ativado' : 'Gateway Desativado' }}</p>
                      </v-btn>
                    </span>
                    
                </div>
                
                <div style="display:flex;width:100%;align-items: center">
                  
                </div>
            </div>
        </div>
        <!-- {{JSON.stringify(gateways, null, '\t')}}<br/> -->
    </v-card-text>
  </v-card>
  
</template>

<script>
export default {
  data(){
    return {
      gateways: [],
      gatewayativo: null,
      gatewayedicao: null
    }
  },

  methods: {
    //metodos para interecao com servidor
    salvarGateway(data){
      // let gateway
      // if('idgateway' in data){
      //   gateway = {
      //     idgateway: parseInt(data.idgateway),
      //     chaveid: data.chaveid,
      //     apikey: data.apikey,
      //     nome: data.nome
      //   }
      // }else{
      //    gateway = {
      //     chaveid: data.chaveid,
      //     apikey: data.apikey,
      //     nome: data.nome
      //   }
      // }
      
      var params = {
          idhotel: localStorage.getItem('hotel'),
          idgateway: data.id,
          idestabelecimento: data.idestabelecimento,
          apikey: data.apikey          
      }
     

      this.$http.post('/pagamento/salvarGatewayHotel', params)
        .then(async res => {
          if(res.status === 200){
            await this.requisitiarGateways()
            this.$alertar('success', '=)', 'Gateway inserido com sucesso!')
          }
        })
        .catch(e => {
          console.log('erro em salvar o gateway', e)
          this.$alertar('warning', '=/', 'Falha na inserção do gateway!')
        })
      this.gatewayedicao = null
    },
    async requisitiarGateways(){
      
      this.$http.post('/pagamento/listarGatewaysHotel').then(async resp =>{
                if(resp.data && resp.data.length > 0){
                    this.gateways = resp.data 
                }
            }).catch((error)=>{
                this.$alertar('warning',error,'=(')
            })    
    },
    async aitivarDesativarGatway(gateway){ 
      if(gateway == this.gatewayativo){
        this.gatewayativo = null
      }else{
        this.gatewayativo = gateway
      }
      //requisicao para updateParametro
      let parametro = {
        idhotel: localStorage.getItem('idhotel'),
        idgateway: gateway.id    
      }

      this.$http.post('/pagamento/ativarGatewayHotel',parametro)
      .then(() =>{
        //this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
        this.requisitiarGateways()
      }).catch(() =>{
        this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
      })
    }, 
    async requisitarParametroAtaul(){
      this.$http.post('pdv/buscarparametros', {chave: 'GATEWAY'})
      .then((res) =>{
        this.gatewayativo = JSON.parse(res.data[0].valor)
      }).catch(() =>{
        this.gatewayativo = null
      })
    },
    //metodos para interecao com front end
    inserirGateway(){
      let gateway = {nome: '', chaveid: '', apikey: ''}
      this.gatewayedicao = gateway
      this.gateways.push(gateway)
    },
    removerGateway(gateway){
      if(gateway.idgateway){
        this.$http.post('/pdv/removerGateway', {gateway})
          .then(res => {
            if(res.status === 200){
              this.$alertar('success', '=)', 'Gateway excluido com sucesso!')
            }
          })
          .catch(e => {
            console.log('erro remover gateway', e)
            this.$alertar('warning', '=/', 'Falha no exclusão do gateway!')
          })
      }
      let index = this.gateways.indexOf(gateway)
      this.gateways.splice(index, 1)
    },
    editarGateway(gateway){
      if(gateway == this.gatewayedicao){
        this.gatewayedicao = null
      }else{
        this.gatewayedicao = gateway
      }
    }
  },

  async mounted(){
    await this.requisitiarGateways()
    // await this.requisitarParametroAtaul()
  }
  

}
</script>

<style lang="scss" scoped>

.ativo{
  background: green !important;
  border: none !important; 
}

.inativo{
  background: gray !important;
  border: none !important;
}

.tooltip:hover .tooltiptext{
  visibility: visible !important;
  opacity: 1 !important;
}


.tooltiptext{

  visibility: hidden;
  width: fit-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  text-transform: capitalize;
  padding: .5em;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 135%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
}
//css indesejado
.v-application p {
  margin-bottom: unset !important;
}
</style>