<template>

    <div class="d-flex align-center categoryItem" @click="direcionar()" data-cy="categoria">
        <div class="colA">
            <!-- <v-img class="colAimg" :src="categoria.imgcategoriaprodcd ? 'data:' + imgTipo + ';base64,'+categoria.imgcategoriaprodcd:null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img> -->
            <v-img class="colAimg" :src="categoria.imgcategoriaprodcd || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
            <p class="colBTitle">{{categoria.nomecategoriaprodcd}}</p>
            <p class="colBOutHour" v-show="categoria.dentro_do_horario == 'false'" style="color:red"> Fora do horário</p>
        </div>
    </div>

</template>

<script>
export default {
    props:["categoria","idpdv"],

    methods:{
        direcionar(){
            this.$router.push('/Hospede/SubCategorias/'+this.idpdv+'/'+this.categoria.idcategoriaprodcd)
        }
    }
}
</script>

<style scoped>
    .categoryItem{
        font-size:1.2em;
        color:rgb(70, 70, 70);
        /* background-color: pink; */
        height: 70px;
        width:330px;
        max-width:100%;
        margin-bottom: 20px;
        margin-right:2%;
        border: 1px solid rgb(156, 156, 156);
        border-radius: 10px;
        padding:5px;
    }
    .categoryItem .colA{
        /* background-color:red; */
        width: 60px;
        padding-left:2%; 
        padding-right: 2%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .categoryItem .colA .colAimg{
        border-radius: 100%;
        height:50px;
        max-height:50px;
        width:50px;
        max-width:50px;
    }

    .categoryItem .colB{
        /* background-color:Blue; */
        width: 270px;
        padding-left:2%; 
        display: flex;
        flex-direction: column;
        padding-right:2%; 
        word-break: break-all;
    }

    .colBTitle {
        font-size: 18px;
        margin: 0;
    }
    .colBOutHour {
        font-size: 14px;
        margin: 0;
    }

</style>