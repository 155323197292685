<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="Dúvidas Impressoras" urlVideo="https://drive.google.com/file/d/1KgIU8QqiNmpnvPlf9eAT3w5H5aKKWywU/preview" />
    </div>
        
        <v-card-text style="height:100%;">
                      
                <div style="height:42vh;max-height:10vh;margin-top:20px;">

                    <div style="padding-bottom:2px;display:flex;align-items:center;">

                        <div style="width:30%;">
                            <label style="font-size:18px;">Maquininhas</label>
                        </div>
                        <div>
                                <v-btn small elevation="" :color="corBotaoCriar" @click="setCampoNovaImpressora()" >{{ textoBotaoCriar }}</v-btn>
                        </div>
                        
                    </div>

                    <div style="display:flex;margin-top:20px;" v-if="flNovaImpressora">
                        
                        <div style="width:40%;padding-right:5%;">
                            <label style="font-size:18px;">Nome:</label>
                            <v-text-field type="" error-count="" placeholder="" label="" append-icon=""  v-model="impressoranova.nome" dense outlined color>
                            </v-text-field>
                        </div>

                        <div style="width:40%;padding-right:5%;">
                            <label style="font-size:18px;">Número Serial:</label>
                            <v-text-field type="" error-count="" placeholder="" label="" append-icon=""  v-model="impressoranova.ip" dense outlined color>
                            </v-text-field>
                        </div>

                        <div style="width:20%;padding-right:5%;padding-top:20px;">
                            <v-btn small elevation="" color="info" @click="salvarNovaImpressora()" large >Salvar</v-btn>
                        </div>
                        
                    </div>

                </div>

                <div style="margin-top:50px;height:70%;">
                    
                        <v-data-table v-if="listaDeImpressoras" :headers="cabecalhoListaDeImpressoras" :items="listaDeImpressoras" items-per-page="5" >
                                 
                                <!-- <template v-slot:[`item.precounitario`]="{ item }">
                                    {{item.precounitario | formatarMoeda}}
                                </template>
                                -->
                                <template v-slot:[`item.id`]="{ item }">
                                    <a v-if="item.ativo" @click="ativarInativarImpressora(item)" style="color:green;" >Inativar</a>
                                    <a v-else @click="ativarInativarImpressora(item)" style="color:red;" >Ativar</a>
                                </template> 
                        
                        </v-data-table>

                </div>

               
        
        </v-card-text>

    </v-card>
</template>

<script>
  import BotaoTutorial from '../layout/BotaoTutorial.vue'
  export default {
    name: 'Impressoras',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        impressoras:[],
        impressoranova:{
            ip:null,
            nome:''
        },
        cabecalhoListaDeImpressoras:[
            { text: 'Nome', value: 'nome' },
            { text: 'IP', value: 'ip' },
            { text: 'Inativar', value: 'id' },
        ],
        listaDeImpressoras:[],
        flNovaImpressora:false,
        textoBotaoCriar:'Criar Nova',
        corBotaoCriar:'info',
    }),
    methods:{
        salvar(){

            // this.$http.put('pdv/updateparametros',parametro)
            // .then(() =>{
                
            //     this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
               
            // }).catch(() =>{
            //     this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            // })
            
            
        },
        async buscarDados(){
            
            this.$http.post('pdv/getImpressoras',{ativo:true}).then(resp =>{
                if(resp.data && resp.data.length > 0){
                    this.listaDeImpressoras = resp.data
                    // this.impressora = this.cabecalhoListaDeImpressoras[0]
                }
            }).catch(error =>{
                alert(error)
            })

        },

        setCampoNovaImpressora(){
            this.flNovaImpressora = !this.flNovaImpressora
            if(this.flNovaImpressora){
                this.textoBotaoCriar = 'Cancelar'
                this.corBotaoCriar = 'error'
            }else{
                this.textoBotaoCriar = 'Criar Nova'
                this.corBotaoCriar = 'info'
            }
        },

        salvarNovaImpressora(){

            let objeto = {
                nome:this.impressoranova.nome,
                ip:this.impressoranova.ip,
                ativo:true
            }

            this.$http.post('pdv/salvarImpressora', objeto).then(() =>{

                this.buscarDados()
                this.impressoranova.nome = null
                this.impressoranova.ip = null
                this.flNovaImpressora = false
                this.corBotaoCriar = 'info'

            }).catch(error =>{
                alert(error)
            })

        },


        ativarInativarImpressora(dados){
        
            let objeto = {
                id:dados.id,
                ativo:!dados.ativo
            }
       
            this.$http.post('pdv/atualizarImpressora', objeto).then(() =>{
                
                this.buscarDados()
                if(dados.ativo) {
                    this.$alertar("success","Impressora Inativada!","=)")
                } else {
                    this.$alertar("success","Impressora Ativada!","=)")
                }
            }).catch(error =>{
                alert(error)
            })

        }

    }
  }
</script>

<style scoped>
    
</style>