<template>

  <div class="containerMesa">
    <div>
    <div>

       <v-container class="">
          <v-row>
            <v-col cols="12" md="2">
            </v-col>
            <v-col cols="12" md="4">
              <v-select  class="SelectPraca" v-model="praca" :items="listaDePracas" @change="mudarPraca" style="width:100%"
                  item-text="nome" item-value="id" return-object dense outlined hide-details aria-hidden label="Praça">
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-select  class="SelectPraca" v-model="statusmesaselecionado" :items="listaDeStatus" @change="mudarPraca"  style="width:100%"
                  item-text="nome" item-value="id" return-object dense outlined hide-details aria-hidden label="Status">
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
            </v-col>
          </v-row>
        </v-container>

     
  </div>

  <div class="mainContainer" v-if="listaDeMesas && listaDeMesas.length > 0" >
      <div type="button"  v-for="(mesa, index) in listaDeMesas" :key="index" @click="selectMesa(mesa)" style="z-index: 1 !important">
        <button v-if="mesa.statusmesa === false" class="mesasFechada">{{mesa.idmesa}}</button>
        <button v-else class="mesasAbertas">{{mesa.idmesa}}</button>
      </div>
  </div>

  <Pagination
    v-if="total > 0"
    :total="total"
    :pagina="pagina"
    :offset="qtdeItensPaginas"
    :limit="qtdeItensPaginas"
    @change-page="changePage"
  />

</div>
<!-- <ModalComandasMesa 
            @emit-click="exibirPedidos" 
            @fechar-modal-comandas="fecharModalComandas" 
            @mesa-fechada="fecharMesa" 
            :comandas="comandasMesa" 
            :mesas="pracasRenderizar" 
            :mesa="mesaParaFechar"/> -->

      <v-dialog  v-model="exibirModalMesasComandas" width="80vw" max-width="500px">
          <ModalComandasMesa 
            @emit-click="exibirPedidos" 
            @fechar-modal-comandas="fecharModalComandas" 
            :comandas="comandasMesa" 
            :mesa="mesaSelecionada"/>

      </v-dialog >

<!-- 
<ModalPedidosComanda 
            @fechar="fecharPedidos" 
            @fechar-modal-comandas="fecharModalComandasAposTransferirMesa" 
            v-if="pedidos.length > 0" 
            :pedido="pedidos" 
            :mesa="mesaParaFechar"
            @imprimir="(comanda) => imprimirComanda(comanda)"
          /> -->
      <v-dialog  v-model="exibirModalPedidos" width="1000" max-width="80vw">
          <ModalPedidosComanda 
            @fechar="fecharPedidos" 
            v-if="pedidos.length > 0" 
            :pedido="pedidos" 
            :mesa="mesaSelecionada"
            @imprimir="(comanda) => imprimirComanda(comanda)"
          />
            <div v-else class="containerNotificacaoAbrirMesa">
              <p>Essa comanda não possui nenhum pedido</p>
            </div>
      </v-dialog >

      
        
      <ModalImpressaoPedido
        :showModal="modalImpressao" 
        :pedidoSelecionado="pedidoSelecionado"
      />
<!-- 
      


      

      <v-dialog  v-model="confirmarAbrirMesa" width="80vw" max-width="500px">
        <div class="containerNotificacaoAbrirMesa">
          <h2>Essa mesa está fechada, deseja abrir?</h2>
          <div>
            <button type="button" class="buttonNotificacaoAbrirMesa"  @click="abrirMesa()">confirmar</button>
            <button type="button" class="buttonNotificacaoAbrirMesa" @click="() => confirmarAbrirMesa  = false">Cancelar</button>
          </div>
        </div>

      </v-dialog >
       -->

</div>
</template>

<script>
  import Pagination from '../../components/Paginação/Pagination.vue';
  import ModalComandasMesa from '../../components/layout/Modais/ModalComandasMesa.vue';
  import ModalPedidosComanda from '../../components/layout/Modais/ModalPedidosComanda.vue';
  import ModalImpressaoPedido from '../../components/layout/ModalImpressaoPedido.vue';
  // import io from 'socket.io-client';

  const dayjs = require('dayjs')

export default {
  components: {
    Pagination,
    ModalComandasMesa,
    ModalPedidosComanda,
    ModalImpressaoPedido
  },
    data() {
        return {
          listaDePracas: [],
          praca: null,
          listaDeMesas:[],
          total: 0,
          pagina:1,
          qtdeItensPaginas:24,
          mesaSelecionada:null,
          comandasMesa:[],
          exibirModalMesasComandas:false,
          exibirModalPedidos: false,
          pedidos: [],
          statusmesaselecionado:{id:0,nome:'Todas'},
          listaDeStatus:[
            {id:0,nome:'Todas'},
            {id:1,nome:'Em uso'},
            {id:2,nome:'Disponiveis'},
          ],
          modalImpressao: false,
          // pracasRenderizar: [],
          // mesas: [],
          // mesa: null,
          // 
          // listPracas: [],
          // pracasExibir: [],
          // 
          // modalMesa: false,
          // intervaloValorGasto: [0, 1000],
          // nomehospede: "",
          // numeromesa: null,
          // mesasfiltradas: [],
          // mesasStatus: [],
          // 
          // comandasMesa: [],
          // mesaParaAbrir: [],
          // confirmarAbrirMesa: false,

          // 
          // idstatuspadraotela: 2,
          // mesaParaFechar: null,
          // socket: io(),
          
          // pedidoSelecionado: {},
          
        };
    },
    watch: {
        //usado para filtrar os elementos (mesas)
        // nomehospede: function () {
        //     if (!this.nomehospede || this.nomehospede.trim() == "") {
        //         this.mesasfiltradas = this.mesas;
        //         return;
        //     }
        //     const nomehospede = this.nomehospede.toLowerCase();
        //     this.mesasfiltradas = this.mesas.filter((mesa) => {
        //         return Object.values(mesa).some((attr) => String(attr).toLowerCase().includes(nomehospede));
        //     });
        // },
        // numeromesa: function () {
        //     if (!this.numeromesa || this.numeromesa.trim() == "") {
        //         this.mesasfiltradas = this.mesas;
        //         return;
        //     }
        //     const numeromesa = this.numeromesa;
        //     this.mesasfiltradas = this.mesas.filter((mesa) => {
        //         return Object.values(mesa).some((attr) => String(attr).toLowerCase() == numeromesa);
        //     });
        // },
        // intervaloValorGasto: function () {
        //     this.mesasfiltradas = this.mesas.filter((mesa) => {
        //         return Object.values(mesa).some((attr) => {
        //             if (String(attr).toLowerCase() >= this.intervaloValorGasto[0] && String(attr).toLowerCase() <= this.intervaloValorGasto[1]) {
        //                 return true;
        //             }
        //         });
        //     });
        // }
    },
    methods: {

        buscarCronogramaFaixaPraca(){

           

            this.$http.post('pdv/getCronogramaFaixaPraca')
            .then((resp) =>{ 

                    if( resp.data &&  resp.data.length>0){
                        console.log('getCronogramaFaixaPraca sim')
                        this.listaDePracas = resp.data;
                        this.praca = resp.data[0]
                        this.total = this.praca.pracafinal - this.praca.pracainicial
                        this.getMesasCronograma()
                    }else{
                      console.log('getCronogramaFaixaPraca não')
                    }

                })
            .catch((error) =>{ 
              console.log('error 2',error)
              this.$alertar("success","Não foi Possível buscar as faixas de Praças!", "=(") 
            })
        },

        getMesasCronograma(){
            console.log('getMesasCronograma')
             let obj = {
                praca:this.praca,
                pagina:this.pagina,
                qtdeItensPaginas:this.qtdeItensPaginas
            }

            if(this.statusmesaselecionado.id > 0){
              obj.mesasdisponiveis = this.statusmesaselecionado.id == 2? true:false
            }

            this.$http.post('pdv/getMesasCronograma',obj).then((resp) =>{
                console.log('resp.data >> getMesasCronograma',resp.data)
                this.listaDeMesas = resp.data
                
                if(resp.data && resp.data[0].qtde_mesas){
                    this.total = resp.data[0].qtde_mesas
                }

            }).catch(error=>{
              console.log('getMesasCronograma error')
              console.log(error)
            })
        },

        mudarPraca() {
          this.pagina= 1
          this.getMesasCronograma()
        },

        changePage(value) {
              this.pagina = value+1;
              this.getMesasCronograma()
        },

        async selectMesa(mesa) {

            this.mesaSelecionada = mesa
            console.log('mesa ', this.mesaSelecionada)
            console.log(`pdv/buscarComandaMesa/${this.mesaSelecionada.idmesa}`)

            await this.$http(`pdv/buscarComandaMesa/${this.mesaSelecionada.idmesa}`).then((resp) => {
                this.comandasMesa = resp.data;
                this.exibirModalMesasComandas = true;
            }).catch(error => {
                  console.log(error);
                  this.$alertar("warning", "Não foi possivel buscar os protudos do pedido", "=(");
            });

            // const { status, numeroPraca } = mesa;
            // this.mesaParaFechar = numeroPraca;

            // if (!status) {
            //   this.mesaParaAbrir = mesa;
            // return this.confirmarAbrirMesa = true;
            // }
          
            // await this.$http(`pdv/buscarComandaMesa/${numeroPraca}`).then((resp) => {
            //     this.comandasMesa = resp.data;
            //     this.exibirModalMesasComandas = true;
            //   }).catch(error => {
            //       console.log(error);
            //       this.$alertar("warning", "Não foi possivel buscar os protudos do pedido", "=(");
            //   });

        },

        exibirPedidos(payload) {
          this.exibirModalPedidos = payload.exibirModal
          this.pedidos = payload.pedidos;
        },

        fecharModalComandas() {
          this.exibirModalMesasComandas = false;
        },


        fecharMesa() {

          const  numeroPraca  = this.mesaParaFechar;
          const list = [...this.listPracas]
            this.listPracas.forEach((mesa, index) => {
              if(mesa.numeroPraca === numeroPraca) {
              list[index].status = false;
              }
            })
            this.listPracas = list;
            this.listItems(list, this.offset, this.limit);
            this.exibirModalMesasComandas = false;
      
        },

        fecharPedidos() {
          this.exibirModalPedidos = false;
        },


        async imprimirComanda(comandas){
          const idComanda = comandas[0].idcomanda
          await this.setarPedido(idComanda)
          setTimeout(() => this.$htmlToPaper('modalImpressao'), 1000);
        },

        async setarPedido(idcomanda){
          this.pedido = await this.getProdutosdoPedido(idcomanda)

          this.pedidoSelecionado = {
            nomehotel:localStorage.getItem('nomehotel'),
            idcomanda:this.pedido.numpedido,
            nomehospede:this.pedido.nome,
            numerolocal:this.pedido.numerolocal,
            datapedido:dayjs(this.pedido.datafeito).format('DD/MM/YYYY HH:mm'),
            quantidade:this.pedido.listadepedidos.length,
            precototal:this.pedido.valorTotal,
            itens:this.pedido.listadepedidos,
          }

          this.modalImpressao = true
      },

      async getProdutosdoPedido(numpedido){
            let objetoRetorno = {}
            await this.$http('pdv/getProdutosdoPedido/'+numpedido+'/t').then((resp) =>{
              objetoRetorno.valorTotal = 0
              objetoRetorno.valorTotalSemTaxa = 0
              objetoRetorno.numpedido = resp.data[0].idcomanda
              objetoRetorno.marcado_como_entregue = resp.data[0].marcado_como_entregue
              objetoRetorno.datafeito = resp.data[0].datahoraabertura
              objetoRetorno.datarecebido = resp.data[0].datarecebidogarcom
              objetoRetorno.imgassinatura = resp.data[0].imgassinatura
              objetoRetorno.idtipolocal = resp.data[0].idtipolocal
              objetoRetorno.numerolocal = resp.data[0].numerolocal
              objetoRetorno.numreserva = resp.data[0].numreserva
              objetoRetorno.coduh = resp.data[0].coduh
              objetoRetorno.nome = resp.data[0].nome
              objetoRetorno.sobrenome = resp.data[0].sobrenome
              objetoRetorno.idgarcominicial = resp.data[0].idgarcominicial
              objetoRetorno.nomegarcominicial = resp.data[0].nomegarcominicial
              objetoRetorno.idgarcomatual = resp.data[0].idgarcomatual
              objetoRetorno.idstatuscomanda = resp.data[0].idstatuscomanda
              objetoRetorno.nomegarcom = resp.data[0].nomegarcom
              objetoRetorno.nomepdv = resp.data[0].nomepdv
              objetoRetorno.passante = resp.data[0].passante
              objetoRetorno.id_tipo_hospede_hits = resp.data[0].id_tipo_hospede_hits
              
              objetoRetorno.listadepedidos = resp.data
              for(var i = 0, len = resp.data.length; i < len; i++){
                  // objetoRetorno.valorTotal += (parseFloat(resp.data[i].precounitario) * parseFloat(resp.data[i].quantidade))
                  if(resp.data[i].valortotal > 0){
                    objetoRetorno.valorTotal += (parseFloat(resp.data[i].valortotal))
                    //console.error(`objetoRetorno.listadepedidos[i].valorsemtaxa:${objetoRetorno.listadepedidos[i].valorsemtaxa}`);
                    objetoRetorno.valorTotalSemTaxa += (parseFloat(objetoRetorno.listadepedidos[i].valorsemtaxa))
                  }
              }
              //console.error(`objetoRetorno.valorTotalSemTaxa:${objetoRetorno.valorTotalSemTaxa}`);
            }).catch(error =>{
              console.log(error)
              this.$alertar("warning","Não foi possivel exibir os pedidos","=(")
            })

            return objetoRetorno
      },


        // groupByKey(list, key) {
        //     return list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});
        // },

       

      //   gerarMesas() {
      //     let list = [];
      //     for (let i = this.praca.pracainicial -1; i < this.praca.pracafinal; i++) {
      //         const obj = {
      //           numeroPraca: i + 1,
      //           id: this.praca.id,
      //           status: false,
      //         }
      //         list = [...list, obj];
      //     } 

      //  list.forEach((mesasCompletas, index) => {
      //     this.mesasStatus.forEach((mesaStatus) => {
      //       if(mesasCompletas.numeroPraca === mesaStatus.numero_mesa) {
      //         list[index].status = mesaStatus.status_mesa
      //       }
      //     })
      //  })  
      //  this.listPracas = list;
      //  this.listItems(list, this.offset, this.limit);
      //   },

      

      
   
        // fecharModalComandasAposTransferirMesa() {
        //   this.exibirModalMesasComandas = false;
        // },

        // async abrirMesa() {
        //  const { numeroPraca } = this.mesaParaAbrir;
        //  const data = {
        //   numeroMesa: numeroPraca,
        //   status: true,
        //  }

        //  this.$http.post(`mesaStatus/cadastrarMesa/`, data).then(()=>{
        //    this.confirmarAbrirMesa = false;
        //    const list = [...this.listPracas]
        //     this.listPracas.forEach((mesa, index) => {
        //      if(mesa.numeroPraca === numeroPraca) {
        //       list[index].status = true;
        //      }
        //     })
        //     this.listPracas = list;
        //     this.listItems(list, this.offset, this.limit);
        //     })
        //     .catch((error)=>{
        //      console.log(error)
        //     })
        // },

        // async exibirDetalhesDaComanda(mesa) {
        //   await this.$http( `pdv/getProdutosdoPedido/${mesa.idcomanda}/t`).then((resp) => {
        //        console.log(resp.data);
        //     }).catch(error => {
        //         console.log(error);
        //         this.$alertar("warning", "Não foi possivel buscar os protudos do pedido", "=(");
        //     });
        // },
       
        

        



        // listItems(items, page, limitItems){
        //     const pageActual = page + 1;
        //     let result = [];
        //     let totalPage = Math.ceil( items.length / limitItems );
        //     let count = ( pageActual * limitItems ) - limitItems;
        //     let delimiter = count + limitItems;
            
        //     if(pageActual <= totalPage){
        //       for(let i=count; i<delimiter; i++){
        //         if(items[i] != null){
        //           result.push(items[i]);
        //         }
        //         count++;
        //       }
        //     }
        //     this.pracasRenderizar = result;
        //     return result;
        // },  

  

       

        // async abrirModalMesa(mesa) {
        //     this.mesa = mesa;
        //     await this.$http(`pdv/getProdutosdoPedido/${mesa.idcomanda}/t`).then((resp) => {
        //         this.mesa["pedidos"] = resp.data;
        //     }).catch(error => {
        //         console.log(error);
        //         this.$alertar("warning", "Não foi possivel buscar os protudos do pedido", "=(");
        //     });
        //     this.modalMesa = true;
        // },

        // fecharModalMesa() {
        //     this.modalMesa = false;
        //     this.mesa = null;
        // },

        // async getMesas() {
        //     let objetoRetorno = {};
        //     await this.$http("pdv/getMesas").then((resp) => {
        //         objetoRetorno = resp.data;
        //     }).catch(error => {
        //         console.log(error);
        //         this.$alertar("warning", "Não foi possivel exibir as mesas", "=(");
        //     });
        //     return objetoRetorno;
        // },

        // async requisitarMesas() {
        //     let mesas = await this.getMesas();
        //     this.mesasfiltradas = mesas;
        //     this.mesas = mesas;
        // },

        // alterarIntervaloValorGasto(tipo) {
        //     tipo === 0 && this.$set(this.intervaloValorGasto, 0, this.$event);
        //     tipo === 1 && this.$set(this.intervaloValorGasto, 1, this.$event);
        // },

        // limparFiltros() {
        //     this.mesasfiltradas = this.mesas;
        //     this.nomehospede = null;
        //     this.numeromesa = null;
        //     this.intervaloValorGasto = [0, 1000];
        // },

        

        //  async requisitarMesasStatus() {
        
        //   await this.$http.post("mesaStatus/buscarStatusMesas/").then((resp) => {
        //          this.mesasStatus = resp.data;
        //       }).catch(error => {
        //           console.log(error);
        //           this.$alertar("warning", "Não foi possivel requisitar os status das mesas", "=(");
        //       });
        //  }
    },
    async mounted() {
      const self_ = this
      
      await this.buscarCronogramaFaixaPraca();
      
      this.sockets.subscribe('notificar_troca_status_pedido',() => {
          self_.getMesasCronograma()
      });

      // await this.requisitarMesasStatus()
      // this.sockets.subscribe("atualizarMesa", async () => {
      //     await this.requisitarMesasStatus()
      //     this.gerarMesas();
      //     this.changePage(0);
      
      // })
      //   this.$store.dispatch("set_titulo", "Mesas");
      //   await this.requisitarMesas();
        
    },
    // beforeDestroy() {
    //     this.sockets.unsubscribe("notificar_mesas");
    // },
}
</script>

<style lang="scss" scoped>

.containerMesa {
  margin-bottom: 50px;
}

.wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filtro{
  padding: 1em;
  background-color: #f9f9f2;
  border-radius: .5em;
  height: auto;
  box-shadow:  0px 10px 20px rgba(128,128,128,.25);
}

.mesasFechada{
    background-image: url('/imgs/mesafechada.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
    margin: 20px;
    color: white;
}

.mesasAbertas{
    background-image: url('/imgs/mesaaberta.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
    margin: 20px;
    color: white;
}

.radiusContainer {
  width: 100%;
  height: 15px;
  background-color: rebeccapurple;
}

.numeroMesa {
  align-self: center;
}

.mainContainer {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  width: 90%;
  max-width: 900px;
}

.SelectPraca {
  width: 50%;
  margin: auto;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.226);
}

.statusFalse {
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
  border: 1px solid black;
}

.statusTrue {
  background-color: green;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
  border: 1px solid black;
}


.containerStatus {
  width: 100%;
  padding: 5px;
}

.containerNumeroMesa {
  background-color: rgb(236, 0, 0);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

}

.containerNotificacaoAbrirMesa {
  background-color: #f9f9f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 10px;
}

.buttonNotificacaoAbrirMesa {
  background-color: rgb(60, 60, 255);
  border-radius: 5px;
  margin: 10px;
  color: white;
  padding: 5px;
  margin-top: 20px;
}
</style>