<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="1200"
        scrollable
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            v-on="on"
            class="item-ping"
        >
          <v-icon color="white" center >mdi-notebook-edit-outline</v-icon>
        </div>
      </template>

      <v-card>
        <v-card-title class="text-h4 blue white--text">
          Notas de atualizacação
        </v-card-title>

        <v-card-text style="height: 700px;">
          <v-timeline
              :dense="$vuetify.breakpoint.smAndDown" >
            <v-timeline-item
                v-for="(patch, index) of patchNotes"
                :key="index"
                :color="patch.color"
            >
              <span
                  :class="`headline font-weight-bold ${patch.color}--text`"
                  slot="opposite">{{ patch.date }}</span>
              <v-card>
                <v-card-title :class="patch.color">
                  <h2 class="text-md-h6 white--text font-weight-light">
                    {{ patch.title }}
                  </h2>
                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="10"
                    >
                      {{ patch.description }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1 white--text"
              @click="marcarComoVizualizado"
          >
            Marcar como vizualizado
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      patchNotes: [
        {
          title: "Criação de opcionais por produto",
          date: "03/01/2023",
          description: "Adição da funcionalidade para criar e gerenciar opcionais específicos para cada produto.",
          color: this.randomizeColor()
        },
        {
          title: "Personalização de novo autoatendimento",
          date: "03/01/2023",
          description: "Introdução de recursos para personalização da tela de boas-vindas e esquema de cores no novo sistema de autoatendimento.",
          color: this.randomizeColor()
        },
        {
          title: "Ativação e redirecionamento para novo autoatendimento",
          date: "03/01/2023",
          description: "Implementação de mecanismos para ativar e redirecionar usuários para o novo sistema de autoatendimento de forma intuitiva.",
          color: this.randomizeColor()
        },
        {
          title: "Tour de utilização para novos usuários",
          date: "03/01/2023",
          description: "Integração de um tour interativo para orientar novos usuários sobre as funcionalidades essenciais do sistema.",
          color: this.randomizeColor()
        },
        {
          title: "Ajustes no relatório de comissão de janeiro",
          date: "03/01/2023",
          description: "Realização de ajustes específicos no relatório de comissão referente ao mês de janeiro.",
          color: this.randomizeColor()
        },
        {
          title: "Relatório de comandas completo",
          date: "03/01/2023",
          description: "Aprimoramento do relatório de comandas para incluir a exibição de produtos removidos, integrados no histórico com datas correspondentes.",
          color: this.randomizeColor()
        },
        {
          title: "Notificação de itens não impressos no aplicativo",
          date: "03/01/2023",
          description: "Implementação de notificações para informar usuários sobre itens não impressos no aplicativo.",
          color: this.randomizeColor()
        },
        {
          title: "Escolha de integração com PMS com data limite",
          date: "03/01/2023",
          description: "Adição da opção de escolher integração ou não integração com o PMS, com a inclusão de uma data limite para decisão.",
          color: this.randomizeColor()
        },
        {
          title: "Novo campo de valor total no relatório de pedidos analíticos",
          date: "03/01/2023",
          description: "Inclusão de um novo campo no relatório de pedidos analíticos para exibir o valor total da transação.",
          color: this.randomizeColor()
        },
        {
          title: "Status do item com possibilidade de troca",
          date: "03/01/2023",
          description: "Melhoria no relatório de pedidos analíticos, permitindo a troca de status para itens não entregues.",
          color: this.randomizeColor()
        },
        {
          title: "Otimização na forma de salvar imagens",
          date: "03/01/2023",
          description: "Otimização do processo de salvamento de imagens para PDV, categoria, subcategoria e produto.",
          color: this.randomizeColor()
        },
        {
          title: "Otimização no pedido com integração de tempo de produção",
          date: "03/01/2023",
          description: "Aprimoramento na realização de pedidos via aplicativo, especialmente quando a opção de 'Integração de Tempo de Produção' está ativa.",
          color: this.randomizeColor()
        },
        {
          title: "Otimização na inserção de pagamentos no aplicativo",
          date: "03/01/2023",
          description: "Melhoria no processo de inserção de pagamentos para tornar a experiência mais eficiente.",
          color: this.randomizeColor()
        },
        {
          title: "Possibilidade de troca de visibilidade entre garçom e hóspede",
          date: "03/01/2023",
          description: "Adição da funcionalidade que permite aos usuários trocar a visibilidade de PDVs, categorias e subcategorias entre os modos 'Garçom' e 'Hóspede'.",
          color: this.randomizeColor()
        }
      ]
    }
  },
  methods: {
    randomizeColor () {
      const colors = [
          'deep-purple',
          'cyan',
          'green',
          'pink',
          'amber',
          'orange'

      ];

      const randomIndex = Math.floor(Math.random() * colors.length);

      return colors[randomIndex]
    },
    async marcarComoVizualizado() {
      const url = `/pdv/marcarComoVizualizadoPatchNotes`

      const data = {
        idusuario: localStorage.getItem('idusuario'),
      }

      await this.$http.patch(url, data)
          .then(() => {
            this.$store.dispatch("set_patch_notes", false)
            this.dialog = false
          })
          .catch((error) => {
            this.$alertar("warning", "Atenção", error?.response?.data?.message || "Erro ao marcar como vizualizado")
          })
    },
  }
}
</script>

<style scoped>
@keyframes ping {
  0% {
    transform: translateY(0px);
    scale: 1.3;
  }

  100% {
    transform: translateY(-10px);
    scale: 1.5;
  }
}

.item-ping {
  animation: ping 1s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-play-state: running;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #f8d90d;
  border-radius: 999px;
  padding: 5px;
  position:absolute;
  z-index: 9;
  bottom: 30px;
  right: 40px;
}
</style>