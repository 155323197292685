<template>
    <transition name="modal" v-if="showModal" @close="showModal = false">
        <div class="modal-mask" >
            <div class="modal-wrapper">
                <div class="modal-container">

                <div class="modal-header">
                    <slot name="header">
                    <!-- <div style="width:100%;background-color:green;display:flex;justify-content:flex-end;margin-top:10px;" @click="showModal = false" >
                        <v-badge  color="red accent-4" icon="mdi-close"  ></v-badge>
                    </div> -->
                    </slot>
                </div>

                <div class="modal-body">
                    <slot name="body">
                    {{titulo}}
                    </slot>
                </div>

                <div class="modal-footer" >
                    <slot name="footer">
                      <v-btn v-for="local in localidades" :key="local.id_localidade" :color="`${local.color}`" style="color: white;font-size:12px;" width="240px" data-cy="modal-confirmacao-sim" @click="funcaoLocal(local)" class="mb-2 mt-2">
                        {{local.descricao}}
                      </v-btn>
                      <!-- <v-btn  v-if="textolocal1 != '-'" color="green" style="color: white;font-size:12px;" width="240px" data-cy="modal-confirmacao-sim" @click="funcaoLocal1()" class="mb-2 mt-2">
                        {{textolocal1}}
                      </v-btn>
                      <v-btn v-if="textolocal2 != '-'" color="orange" style="color: white;font-size:12px;" width="240px" data-cy="modal-confirmacao-nao" @click="funcaoLocal2()" class="mb-2 mt-2">
                          {{textolocal2}}
                      </v-btn>
                       <v-btn v-if="textolocal3 != '-'"  style="color: white;background-color: #8f8000 !important;border-color: #8f8000 !important;font-size:12px;" width="240px" data-cy="modal-confirmacao-nao" @click="funcaoLocal3()" class="mb-2 mt-2">
                          {{textolocal3}}
                      </v-btn>
                      <v-btn  v-if="textolocal4 != '-'" color="#964B00" style="color: white;font-size:12px;" width="240px" data-cy="modal-confirmacao-sim" @click="funcaoLocal4()" class="mb-2 mt-2">
                        {{textolocal4}}
                      </v-btn> -->
                      <v-btn v-if="exibeBtnCancelar" color="red" style="color: white;font-size:12px;" data-cy="modal-confirmacao-cancelar"  width="240px" @click="cancelar()" class="mb-2 mt-2">
                          <!-- <v-icon>mdi-alert</v-icon> --> {{textocancelar?textocancelar:'Voltar'}}
                      </v-btn>
                    </slot>
                </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

  export default {
    name: 'BotaoVoltar',
    props:["showModal","titulo","textolocal1","textolocal2","textolocal3", "textolocal4","textocancelar", "exibeBtnCancelar", "hospede"],
    data() {
      return {
        localidades: [],
      }
    },
    mounted(){
            this.$http("pdv/localidades/").then(async (resp) => {
                if (resp.data && resp.data.length > 0) {
                  this.localidades = resp.data;
                  if (this.hospede?.passante) {
                    this.localidades = this.localidades.filter((local) => !local.descricao.toLowerCase().includes('quarto') && !local.descricao.toLowerCase().includes('quartos'))
                  }
                }
            }).catch((error) => {
                this.$alertar("warning", error, "=(");
            });
    },
    methods:{
        direcionar(){
            this.$router.push(this.url)
        },
        funcaoLocal2(){
          this.$emit('funcaoLocal2')
        },
        funcaoLocal(dados){
          this.$emit('funcaoLocal', dados)
        },
        funcaoLocal1(){
          this.$emit('funcaoLocal1')
        },
        funcaoLocal3(){
          this.$emit('funcaoLocal3')
        },
        funcaoLocal4(){
          this.$emit('funcaoLocal4')
        },
        cancelar(){
          this.$emit('cancelar')
        }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.botaoModalLocalLaranja{background-color: rgb(245, 149, 40);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}
</style>

