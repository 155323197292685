<template>
    <v-dialog v-model="exbirModalAdicionarDesconto"  width="1000" max-width="80vw" >
      <v-card style="max-width: 100%;padding:0px!important;" >
        <v-toolbar color="blue" class="mb-2">
          <v-flex  style="display: flex" class="pa-2">
            <span style="width:95%;color:white;font-weight:bold;" >
              Descontos da comanda
            </span>
            <span style="width:5%;"> 
              <v-btn x-small color="red" @click="fechar()" title="Fechar">
                <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>

        <v-card-text style="margin:0px!important;" >
          <v-card style="padding:10px;margin:0px!important;">
            <div style="width:100%;">
              <div>
                <v-container class="">
                  <v-row>
                    <h3>OBS: O desconto indicado abaixo será distribuído entre os preços dos produtos na comanda.</h3>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div style="padding-bottom:10px;">
                        <h3>Valor: </h3>
                        <input maxlength="50" data-cy="input-nome" type="number" v-model="valorNovoDesconto" placeholder="Digite o valor do desconto" style="width:100%;height:50px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;" />
                      </div>
                    </v-col>
                    <v-col>
                      <div style="padding-bottom:10px;">
                        <h3>Tipo desconto:</h3>
                        <v-select color v-model="tipoDescontoSelecionado" :items="listaTiposDesconto" item-text="label" item-value="value" label="tipo de desconto" return-object></v-select>
                      </div>
                    </v-col>
                    <v-col>
                      <div style="padding-top:30px;">
                        <v-btn 
                          color="primary" 
                          @click="adicionarDesconto()" 
                          data-cy="criarCategoria"
                        >
                          Adicionar
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>    

              <div>
                <v-container class="">
                  <v-row>
                    <v-col>
                      <div style="padding-bottom:10px;">
                        <v-data-table
                          :headers="headers"
                          :items="listaDescontos"
                          class="elevation-1"
                          item
                        >
                        <template v-slot:[`item.tipo`]="{ item }">
                            {{ item.tipo.label }}
                          </template>
                          <template v-slot:[`item.action`]="{ item }">
                            <v-btn small elevation="" color="error" @click="removerDescontoSelecionado(item)">Remover</v-btn>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                    
                  </v-row>
                </v-container>
              </div>    
            </div>

            <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
              <v-btn 
                color="primary" 
                data-cy="criarCategoria" 
                @click="() => salvarDescontos()"
              >
                Salvar desconto para comanda
              </v-btn>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
</template> 

<script>

  export default {
    name: 'ModalddPagtoPassante',
    props:["exbirModalAdicionarDesconto", "pedido", "integracao"],
    data: () => ({
      idcomanda: null,
      tipoDescontoSelecionado:null,
      listaTiposDesconto:[
        { label: "Valor Fixo (R$)", value: "valor" },
        { label: "Percentual (%)", value: "percentual"}
      ],
      valorNovoDesconto:null,
      listaDescontos:[],
      headers: [
        { text: "Valor", value: "valor" },
        { text: "Tipo", value: "tipo" },
        { text: "Remover", value: "action" },
      ],
      totalPedido: 0,
    }),
    async mounted(){
      this.idcomanda = this.pedido.numpedido
      this.totalPedido = this.pedido.valorTotalSemTaxa
      await this.listarDescontosSalvos()

      if(this.integracao === 'ADMH') {
        this.listaTiposDesconto = [
          { label: "Valor Fixo (R$)", value: "valor" },
        ];
      }
    },
    methods:{
      fechar(){
        this.$emit("fechar")
      },
      adicionarDesconto() {
        if(!this.valorNovoDesconto){
          return this.$alertar("warning", "Informe o valor do desconto!", "=(");
        }

        if(!this.tipoDescontoSelecionado){
          return this.$alertar("warning", "Informe o tipo do desconto!", "=(");
        }

        if(this.tipoDescontoSelecionado.value == "percentual" && this.valorNovoDesconto > 100){
          return this.$alertar("warning", "O valor do desconto não pode ser maior que 100%!", "=(");
        }
        if(this.tipoDescontoSelecionado.value == "valor" && this.valorNovoDesconto > this.totalPedido){
          return this.$alertar("warning", "O valor do desconto não pode ser maior que o valor total do pedido!", "=(");
        }

        if(this.listaDescontos.length > 0){
          return this.$alertar("warning", "Já foi adicionado um desconto para essa comanda!", "=(");
        }

        const desconto = {
          valor: this.valorNovoDesconto,
          tipo: this.tipoDescontoSelecionado,
        };

        this.listaDescontos.push(desconto);
      },
      removerDescontoSelecionado(item) {
        const index = this.listaDescontos.indexOf(item);
        this.listaDescontos.splice(index, 1);
      },
      async salvarDescontos() {
        const data = {
          idcomanda: this.idcomanda,
          descontos: this.listaDescontos.map(item => {
            return {
              valor: item.valor,
              tipo: item.tipo.value,
            };
          }),
          hospede: !this.pedido.passante
        }

        this.$http.post("/pdv/salvarDescontos", data)
        .then(() => {
          this.$alertar("success", "Desconto adicionado com sucesso!", "=)");
          this.$emit("listarComandas")
        })
        .catch((err) => {
          this.$alertar("warning",  err.response?.data?.message || "Erro ao salvar descontos!", "=(");
        });
      },
      async listarDescontosSalvos() {
        this.$http.get(`/pdv/listarDescontos/${this.idcomanda}`)
        .then(({data}) => {
          this.listaDescontos = data.map(item => {
            const tipos = {
              valor: "Valor Fixo (R$)",
              percentual: "Percentual (%)"
            }
            return {
              valor: item.valor,
              tipo: {
                label: tipos[item.tipodesconto],
                value: item.tipodesconto,
              }
            };
          });
        })
      }
    }
  }
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.botaoModalLocal{
    width:100%;
    height: 50px;
    display: block;
    margin-bottom:30px;
    border:0px;
    font-weight: bold;
    color:white;
}

.botaoModalLocalAmarelo{background-color: rgb(239,175,62);}
.botaoModalLocalVerde{background-color: rgb(0,165,51);}
.botaoModalLocalAzul{background-color: rgb(63,81,181);}
.botaoModalLocalVermelho{background-color: rgb(216, 37, 37);}
.headerTitulo{font-weight: bold;}
.numeroForm{height: 50px!important;}


  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    border: 1px solid #819ab4;
    margin-right:10px;
    width: 100px;
  }
</style>

