<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px;" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1Cp8ckaVO0qeJyZmWLdencNKzZs_Kc96b/preview" />
    </div>
        
        <v-card-text>
                      
                <v-container grid-list-xs>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field type="date" error-count="" placeholder="" 
                                label="Atualização a Partir desta data" append-icon=""  v-model="datainicialimportacao" outlined color></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn small elevation="" color="primary" style="width:100%;height:7vh" @click="carregarDados()" data-cy="carregarDados"
                                :disabled="lblstatusimportacao && (lblstatusimportacao != '' && lblstatusimportacao != 'Concluida') ?true:false">
                                Importar Itens Cardápio do PMS
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <h2>Status da Importação:</h2>
                        </v-col>
                        <v-col cols="12" md="6">
                            <h2>{{lblstatusimportacao}}</h2>
                        </v-col>
                    </v-row>

                </v-container>
              
        
        </v-card-text>

    </v-card>
</template>

<script>
import dayjs from 'dayjs'
  import BotaoTutorial from '../layout/BotaoTutorial.vue'
  export default {
    name: 'Assinatura Config',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){

        this.datainicialimportacao = dayjs().format('YYYY-MM-DD')

        //buscar o status atual da sincronia de produtos para evitar duplicidades
        this.$http.post('pdv/buscarparametros', {chave: 'SINCRONIA_PRODUTOS'})
        .then((res) =>{
            this.lblstatusimportacao = res.data[0].valor
        }).catch(() =>{
            this.gatewayativo = null
        })

        //conexão socket para atualizar o status da importacao
        this.sockets.subscribe('atualizar_status_sincronia_produtos',(data)=>{
            this.lblstatusimportacao = data.statusimportacao
        })

        this.buscarDados()
        let _self = this
        this.sockets.subscribe('importacao_finalizada',()=>{
          _self.$alertar('success','Itens Atualizados com Sucesso!!!!!!','=D')
        }
      )

    },
    watch:{
      
    },
    data: () => ({
        config:{
            assinaturaobrigatoria:true,
        },
        datainicialimportacao:null,
        lblstatusimportacao:''

    }),
    methods:{
        carregarDados(){

            if(!this.datainicialimportacao){
                this.datainicialimportacao = dayjs().format('YYYY-MM-DD')
            }

            let params = {datainicialimportacao:this.datainicialimportacao}
            this.$http.post('pdv/gruposItem/cargaDados',params,{timeout: 600000})
                .then(() =>{
                    this.$alertar('success','Importação Solicitada!','Aguarde!')
                })
                .catch((error) =>{
                    console.log(error)
                    this.$alertar('warning','Não foi Possivel Atualizar os Itens!','=(')
                })
                            
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'OBRIGATORIOASSINATURA'})
            .then(resp =>{
                this.config.assinaturaobrigatoria = resp.data[0].valor === 'true'? true : false
            }).catch(error =>{
                alert(error)
            })

           

        }
    }
  }
</script>

<style scoped>
    
</style>