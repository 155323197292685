<template>
<v-card  elevation="2" outlined  style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px" >
    <div style="width:100%;display:flex;justify-content:flex-end;align-items:flex-end;">
        <BotaoTutorial categoria="13" legenda="" urlVideo="https://drive.google.com/file/d/1tdJq21NN_6-vL6NMcJ9Lvil7b7q_Q5Jz/preview" />
    </div>
        
        <v-card-text>
                      
                <div style="height:15vh;max-height:15vh;overflow:auto;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:center;">
                        <v-switch v-model="todospdvallinclusive" data-cy="todospdvallinclusive"> </v-switch>
                        <label style="font-size:18px;">Todos os Pdvs Estão disponiveis Para Pensão All Inclusive?</label>
                    </div>
                </div>


                <div style="height:42vh;max-height:20vh;overflow:auto;margin-top:20px;">
                    <div style="padding-bottom:2px;display:flex;align-items:flex-start;flex-direction:column;">
                        <label for="tempominuto" style="font-size:20px;margin-bottom:10px;">Selecione o Tipo de Pensão que será Considerado como All Inclusive </label>
                        <div style="width:50%;">
                            <select v-model="idpensao" data-cy="idpensao" class="select" >
                                <option v-for="pensao in listaPensao"  :key="pensao.id" :value="pensao.id" >
                                    {{pensao.nome}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div style="width:100%;height:8vh;position:absulute;margin-bottom:0px;display:flex;align-items:center;justify-content:space-between;">
                        <!-- <v-btn small elevation="true" color="warning" style="width:40%;height:7vh" v-if="exibirnao"   @click="nao()" >{{textonao}}</v-btn> -->
                        <v-btn small elevation="" color="primary"   style="width:40%;height:7vh"                      @click="salvar()" data-cy="salvar">SALVAR</v-btn>
                </div>
        
        </v-card-text>

    </v-card>
</template>

<script>
  import BotaoTutorial from '../layout/BotaoTutorial.vue'
  
  export default {
    name: 'Fluxo Pedido',
    components: {
        BotaoTutorial
    },
    props:[],
    async mounted(){
        this.buscarDados()
    },
    watch:{
      
    },
    data: () => ({
        todospdvallinclusive:false,
        idpensao:1,
        listaPensao:null
    }),
    methods:{
        salvar(){

            // let obj = {
            //     tela:'fluxo',
            //     parametro:{
            //         chave:'FLUXOPEDIDOS',
            //         valor:this.config.aprovacaocaixa ? 'true' : 'false'
            //     }
            // }

            let parametro = {
                    chave:'ALLINCLUSIVE',
                    valor:this.todospdvallinclusive? 'true' : 'false',
                    valor2:this.idpensao
                }

            this.$http.put('pdv/updateparametros',parametro)
            .then(() =>{
                this.$alertar("success","Parametros Atualizado com Sucesso!", "=)")
            }).catch(() =>{
                this.$alertar("success","Não foi Possível atualizar com Sucesso!", "=(")
            })
            
            // this.$emit('salvar',obj)
        },
        async buscarDados(){
            this.$http.post('pdv/buscarparametros',{chave:'ALLINCLUSIVE'})
            .then(resp =>{
                this.todospdvallinclusive = resp.data[0].valor === 'true' ? true:false
                this.idpensao = resp.data[0].valor2
            }).catch(error =>{
                alert(error)
            })

            this.$http.get('pdv/buscarTiposPensao')
            .then(resp =>{
                this.listaPensao = resp.data
            }).catch(error =>{
                alert(error)
            })

        }
    }
  }
</script>

<style scoped>
    .select{
      
        -webkit-appearance: none; /* Remove estilo padrão do Chrome */   
        -moz-appearance: none;/* Remove estilo padrão do FireFox */  
        appearance: none; /* Remove estilo padrão do FireFox*/   
          
        background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-appearance.gif) no-repeat #fff;/* Imagem de fundo (Seta) */   
        background-position: 98% center;  /*Posição da imagem do background*/  
       

        border:1px solid black;
        width: 100%;
        max-width:1000px;
        height: 40px;
        padding:10px;
        padding-right: 20%;
        border-radius: 10px;
  
    }
</style>