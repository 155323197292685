<template>
    <v-card class="container" elevation="2" outlined style="padding-bottom:5px!important;height:95%;max-height:95%;margin-top:1%;padding:10px">

      <div class="qrcode-generator" >
        <h2>Gerador de QR Code</h2>

        <div class="input-container">
          <label for="url">Quarto</label>
          <input v-model="quarto" id="url" type="text" />
        </div>

      <div class="input-container">
        <label for="url">Mensagem</label>
        <input v-model="mensagem" id="url" type="text" />
      </div>

        <div class="input-container">
          <label for="color">Cor:</label>
          <input v-model="color" id="color" type="color" />
        </div>

        <div class="input-container">
          <label for="size">Tamanho:</label>
          <input v-model="size" id="size" type="range" min="100" max="300" />
        </div>

        <button @click="downloadQRCode">Baixar QR Code</button>

        </div>

        <div ref="imgDownload" class="img-download">
          <h1 :style="'color: ' + color">UH: {{quarto}}</h1>

          <p :style="'color: ' + color">{{mensagem}}</p>

          <div class="qrcode">
            <qrcode-vue :value="url" :size="size" level="H" :foreground="color"></qrcode-vue>
          </div>
        </div>
    </v-card>
  </template>

  <script>
  import QrcodeVue from 'qrcode.vue'
  import html2canvas from 'html2canvas';

    export default {
      name: 'QRCodeRoomService',
      components: {
        QrcodeVue
      },
      props:[],
      async mounted(){
      },
      data: () => ({
        quarto: "",
        url: window.location.origin + "/loginqrcode?quarto=01",
        color: "#000000",
        mensagem: "",

        size: 300,
      }),
      methods:{
        downloadQRCode() {
          const divToConvert = this.$refs.imgDownload;
          html2canvas(divToConvert, {
            backgroundColor: null,
          }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${this.quarto}-qrcode.png`;
            link.click();
          });
        },
        downloadQRCode2() {
          const qrCode = document.querySelector('canvas');
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const text = this.quarto;
          const size = this.size;

          canvas.width = size;
          canvas.height = size + 100;

          ctx.fillStyle = '#fff';
          ctx.font = 'bold 50px sans-serif';
          ctx.textAlign = 'center';

          ctx.fillText(text, size / 2, size + 50);

          ctx.drawImage(qrCode, 0, 0, size, size);

          const link = document.createElement('a');
          link.download = `${this.quarto}-qrcode.png`;
          link.href = canvas.toDataURL();
          link.click();
        },
      },
      watch:{
        quarto: function(val) {
          const quarto = encodeURIComponent(val);
          const newURL = `${window.location.origin}/loginqrcode?quarto=${quarto}`;
          this.url = newURL;
          console.log(this.url);
        }
      },
    }
  </script>

  <style scoped>

  .img-download {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
  }

  .img-download h1 {
    font-size: 2rem;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }

  .qrcode-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    padding: 2rem;
    border-radius: 8px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .input-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  label {
    font-weight: bold;
  }

  input[type="text"],
  input[type="color"],
  input[type="range"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.25rem;
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #0056b3;
  }
  </style>
