<template>
  <div class="estiloColuna">
      <div class="topoColuna">
        <Subtitulo style="max-width: 90%; width: 90%;display: flex;" :subtitulo="'Lista de produtos'" :corTexto="'#fff'" />
      </div>
      <v-text-field
        data-v-step-etapas="1"
        style="margin: 1em"
        outlined 
        hide-details
        placeholder="Pesquise aqui pelo Produto" 
        id="input-busca-listapedidos"
        v-model="busca" 
        @input="e => filtarObjProdutos(e)"/>
      <ProdutosListaAdm :ojbProdutos="ojbProdutosAux" corTexto="#55ad94" />
  </div>
</template>

<script>
  import Subtitulo from '../../components/layout/Subtitulo.vue'
  import ProdutosListaAdm from '../../components/produtos/ProdutosListaAdm.vue'
  export default {
    name: 'ColunaListaProdutos',
    components: {
      ProdutosListaAdm,
      Subtitulo,
    },
    props:["urlVideo", "ojbProdutos", 'buscainicial', 'tela'],
    async mounted(){
      this.ojbProdutosAux = this.ojbProdutos;
      const titulo = this.tela.toUpperCase()

      if(this.locaisPermitidos.includes(titulo)){
        this.telasComNotificacao = true
      }

    },
    watch: {
      ojbProdutos(){
        this.ojbProdutosAux = this.ojbProdutos;
        const titulo = this.tela.toUpperCase()

      if(this.locaisPermitidos.includes(titulo)){
        this.telasComNotificacao = true
      }
    }
  },
    beforeMount(){
      const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []

      const titulo = this.tela.toUpperCase()

      const locaisPermitidos = ['CAIXA', 'BAR', 'COZINHA'];

      if(locaisPermitidos.includes(titulo)){

        const exibirNotificacao = locaisNotificacao.includes(titulo)

        this.showPopUp = exibirNotificacao
      }


    },
    data: () => ({
      busca: null,
      ojbPedidosdb: {},
      showPopUp: true,
      telasComNotificacao: false,
      locaisPermitidos: ['CAIXA', 'BAR', 'COZINHA'],
      ojbProdutosAux: [],
    }),
    methods:{
        direcionar(){
            this.$router.push(this.url)
        },
        setarPedido(pedido){
          console.log('pedido', pedido)
          this.$emit('setarPedido', pedido)
        },
        filtarObjProdutos(e){
          this.ojbProdutosAux = this.ojbProdutos;
          const filtro = e;
          const filtrarProduto = this.ojbProdutosAux.filter((obj) => obj.produto.toLowerCase().includes(filtro.toLowerCase()))
          this.ojbProdutosAux = filtrarProduto
          console.log(filtro)
          console.log(this.busca)
          console.log(filtrarProduto)
        },
      switchOnOffPopUp(){
        const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []
        const titulo = this.tela.toUpperCase()

        const locaisPermitidos = this.locaisPermitidos

        if(!locaisPermitidos.includes(titulo)){
          return
        }

        const index = locaisNotificacao.indexOf(titulo)


        if(index > -1){
          locaisNotificacao.splice(index, 1)
        }else{
          locaisNotificacao.push(titulo)
        }

        this.showPopUp = !this.showPopUp;
        localStorage.setItem("locaisNotificacao", JSON.stringify(locaisNotificacao))

        window.location.reload()
      },
      atribuirValorPopUp(){
        const locaisNotificacao = localStorage.getItem("locaisNotificacao") ? JSON.parse(localStorage.getItem("locaisNotificacao")) : []

        const titulo = this.tela.toUpperCase()

        const locaisPermitidos = this.locaisNotificacao

        if(locaisPermitidos.includes(titulo)){
          const exibirNotificacao = locaisNotificacao.includes(titulo)
          this.showPopUp = exibirNotificacao
        }
      },  
    },

  }
</script>

<style scoped>
    .estiloColuna{
        background-color: white;
        /* padding-top:10px; */
        width: 28%!important;
        margin-right:2%!important;
        min-height: 90vh;
        max-height: 90vh;
        border:1px solid rgb(156, 156, 156);
        border-radius:5px;
        
    }

    .topoColuna{
      width:100%;
      height:10%;
      border:none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color:#1976D2;
      display:flex;
      align-items: center;
    }
</style>