<template>
  <div>
    <BotaoVoltar v-if="pessoaLogada" :url="'/Hospede/Bemvindo'" />
    <BotaoVoltar v-else :url="'/'" />
    <Subtitulo :subtitulo="$t('locais.texto')" />
    <PdvList />
  </div>
</template>

<script>
  import PdvList from '../../components/pdvs/PdvList'
  import BotaoVoltar from '../../components/layout/BotaoVoltar.vue'
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'PDV',

    components: {
      PdvList,
      BotaoVoltar,
      Subtitulo,
    },
    data: () => {
      return {
        pessoaLogada: false,
      }
    },
    mounted(){

      if(localStorage.getItem("pessoaLogada") && localStorage.getItem("pessoaLogada") == "true"){
        this.pessoaLogada = true
      }

        this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
    }
  }
</script>
