<template>
  <v-container>
    <!-- <TitleH1 text="Categorias" /> -->
    <!-- <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="ojbPedidos.statuscomanda" :corTexto="corTexto" />
    <Subtitulo v-if="ojbPedidos && ojbPedidos.listadepedidos && ojbPedidos.listadepedidos.length > 0" :subtitulo="'Total: R$ '+ojbPedidos.valorTotal.toFixed(2)" :corTexto="corTexto" /> -->

    <div class="categoryList" style="margin-top:10px;margin-bottom:20px;" data-cy="lista-pedidos" v-if="ojbProdutos && ojbProdutos.length">
      <ProdutoAdm data-cy="produtoAdmh" v-for="(produto, index) in ojbProdutos" :key="index"  :index="index"
        @setarPedido="(pedido)=>setarPedido(pedido)" :produto="produto"
       data-v-step-etapas="2"
       />
    </div>
  </v-container>
</template>

<script>
  // import TitleH1 from './TitleH1.vue'
  // import Subtitulo from '../../components/layout/Subtitulo.vue'
  import ProdutoAdm from './ProdutoAdm.vue'
  export default {
    name: 'ProdutosLista',
    props:['ojbProdutos','statuscomanda','corTexto'],
    components:{
      // TitleH1,
      // Subtitulo,
      ProdutoAdm
    },
    data: () => ({
      // listadeprodutos:[]
      indexultimoselecionado : null
    }),

    methods:{

      setarPedido(pedido){
          console.log('entrou aqui .>>>')
          this.$emit('setarPedido', pedido)
      }

    },

    mounted(){
      // this.getProdutos()
    }



  }
</script>

<style scoped>
    .categoryList{
      /* background-color:green; */
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      width:100%;
      padding:0px!important;
      overflow:auto;
      max-height:65vh;
    }
</style>
