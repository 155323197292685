<template>
    <!-- <div style="position: absolute;width:100%; height:100%; opacity: 1; background-color: transparent"> -->
    <div :style="`
      width:100%; 
      height:100vh; 
      opacity: 1; 
      background-color: transparent;
      ${drawer ? 'position: fixed; top: 0; left: 0; z-index: 10' : 'position: absolute;'}
      `" id="modalFAQ">
      <v-btn
        color="blue"
        dark
        @click.stop="drawer = !drawer"
        style="margin: 0; top:50%; right: 0; transform: rotate(-90deg) translateY(100%) ;z-index:99 !important; position: fixed;"
        id="abrirFAQ"
      >
        Abrir FAQ
      </v-btn>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        width="700"
        style="z-index:9999 !important;"
      >

      <div class="header">
          <h1>Bem vindo (a) 👋</h1>
          <p>Como podemos te ajudar?</p>
      </div>
      
      <div style="height:50px" id="cardpesquisa">
          <div id="input" >
              <img src="@/assets/img/lupa.svg" alt="lupa pesquisa">
                  <input 
                      type="text" 
                      v-model="pesquisa" 
                      placeholder="Pesquise aqui"
                  />
          </div>
      </div>
        
        <v-list style="padding:0; margin:0;">
          <v-list-item
            v-for="titulo in titulos"
            :key="titulo.title"
            style="width:100%;padding:0;margin:0"
            
          >
            <v-expansion-panels>
              <v-expansion-panel
              style="border-radius:0;"          
              >
                <v-expansion-panel-header >  
                   <v-list-item-title class="title"> <span><img :src="titulo.icon" alt=""></span> <span>{{ titulo.title }}</span> </v-list-item-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>


                    <v-expansion-panels
                      v-for="video in videoFiltrado"
                      :key="video.id + 'video'"
                      v-show="titulo.title === 'Vídeos'"
                    >
                      <v-expansion-panel
                      style="border-radius:0;"
                      >
                        <v-expansion-panel-header class="subtitle">  
                           <v-list-item-title> {{ video.titulo }} </v-list-item-title>
                          
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <iframe allowfullscreen class="iframe-video" style="max-width:800px;"
                            :src="video.video">
                          </iframe>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      </v-expansion-panels>




                    <v-expansion-panels
                      v-for="pergunta in faqFiltrado"
                      :key="pergunta.id + 'faq'"
                      v-show="titulo.title === 'FAQ'"
                    >
                      <v-expansion-panel
                        style="border-radius:0;"  
                      >
                        <v-expansion-panel-header class="subtitle">  
                           <v-list-item-title> {{ pergunta.pergunta }} </v-list-item-title>
                          
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-html="pergunta.resposta"></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>



                    </v-expansion-panels>

                    <!-- <v-expansion-panels
                      v-for="content in filtroConjunto"
                      :key="content.id"
                    >
                      <v-expansion-panel
                      style="border-radius:0;"  
                      >
                        <v-expansion-panel-header>  
                           <v-list-item-title v-show="titulo.title === 'FAQ'"> {{ content.pergunta }} </v-list-item-title>
                           <v-list-item-title v-show="titulo.title === 'Vídeos'"> {{ content.titulo }} </v-list-item-title>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <div v-html="content.resposta" v-show="titulo.title === 'FAQ'"></div>
                          <iframe 
                            v-show="titulo.title === 'Vídeos'" 
                            allowfullscreen 
                            class="iframe-video" 
                            style="max-width:800px;"
                            :src="content.video"
                          >
                          </iframe>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> -->


                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-list>
        <ModalPathNotes />
      </v-navigation-drawer>
  </div>
  </template>


<script>
import CamIcon from '@/assets/img/camera.png'  
import FaqIcon from '@/assets/img/faq.png'
import ModalPathNotes from "@/components/layout/Modais/ModalPathNotes.vue";


export default {
  components: {
    ModalPathNotes
  },
  data() {
    return {
      pesquisa: '',
      drawer: null,
      titulos: [
        { 
          title: 'Vídeos', 
          icon: CamIcon,
          content: []
        },
        { 
          title: 'FAQ', 
          icon: FaqIcon,
          content: []
        },
      ],
      videos: [],
      faq: [],
    }
  },
  computed: {
  filtroConjunto() {
    return this.titulos.map(titulo => {
      if (titulo.title === 'Vídeos') {
        titulo.content = this.videos.filter(item => {
          if (this.pesquisa.toLowerCase().trim() === '') {
            return item.titulo
          } else {
            return item.titulo.toLowerCase().includes(this.pesquisa.toLowerCase())
          }
        })
      } else if (titulo.title === 'FAQ') {
        titulo.content = this.faq.filter(item => {
          if (this.pesquisa.toLowerCase().trim() === '') {
            return item.pergunta
          } else {
            return item.pergunta.toLowerCase().includes(this.pesquisa.toLowerCase())
          }
        })
      }
      return titulo.content;
    })
  },
  faqFiltrado(){
      return this.faq.filter(item => {
        if(this.pesquisa.toLowerCase().trim() === '') {
            return item.pergunta
        } else {
            return item.pergunta.toLowerCase().includes(this.pesquisa.toLowerCase())
        }
      })
    },
    videoFiltrado(){
      return this.videos.filter(item => {
        if(this.pesquisa.toLowerCase().trim() === '') {
            return item.titulo
        } else {
            return item.titulo.toLowerCase().includes(this.pesquisa.toLowerCase())
        }
      })
    },
},
  beforeMount() {
    this.$http.post('https://apies.economysoftware.com.br/faq/pergunta/endpoint/select', { idsistema: 2 } )
      .then(response => {
        function setWidthAndHeight(text) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'text/html');
          const images = doc.getElementsByTagName('img');
          for (const image of images) {
            image.setAttribute('style', 'width:100%');
          }
          return doc.body.innerHTML;
        }
        response.data.map(item => {
          item.resposta = setWidthAndHeight(item.resposta)
        })
        this.faq = response.data
      })
    this.$http.post('https://apies.economysoftware.com.br/tutorial/select', { sistema: 2 })
      .then(response => {
        this.videos = response.data
      })
  }
}
</script>

<style lang="scss" scoped>

  .iframe-video {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }

  #cardpesquisa {
    margin: auto;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border-radius: 12px;
    margin-top: -3.5%;
    margin-bottom: 32px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    background-color: white;
  }

  #input {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    
    input {
      padding: 10px;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      &:focus {
        outline: none;
      }
    }

    img {
        width: 17px;
        height: auto;
        margin-left: 10px;
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #3489da;
    height: 17%;
    color: white;
    font-size: 1.5em;

    img {
      width: 50px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.5em;
    font-weight: bold;

    span {
      margin-left: 10px;

      img {
        width: 24px;
      }
    }
  }

  .subtitle {
    font-weight: bold;
  }
  
</style>