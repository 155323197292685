<template>
  <div>
    <div>
      <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
        <v-btn depressed color="error" @click="voltar()">{{$t('voltar.texto')}}</v-btn>
      </div>
    </div>
    <Subtitulo :subtitulo="subtitulo" />
      <div class="categoryList">
      <div v-for="produto in listadeprodutos" :key="produto.idproduto" class="d-flex align-center item" @click="produto.dentro_do_horario == 'false' ?  ''  : direcionar(produto.idproduto) " data-cy="produto">
        <div class="colA">
          <v-img class="colAimg" :src="produto.img ? produto.img : null || '/imgs/food.png'" aspect-ratio="1.7" ></v-img>
        </div>
        <div class="colB">
          <div class="colBItemTitle">{{produto.nomecd || produto.descproduto }}</div>
          <div v-if="produto.quantidade" class="colBItemPrice">
            {{produto.quantidade}} x {{produto.precoimportado | formatarMoeda}}
          </div>
          <div v-else class="colBItemPrice">
            <p v-if="produto.dentro_do_horario == 'false'" style="color:red">Fora do horário</p>
            <p v-else>{{produto.precoimportado | formatarMoeda}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Subtitulo from '../../components/layout/Subtitulo.vue'

  export default {
    name: 'ProdutosMesa',
    data: () => ({
      subtitulo:"",
      listadeprodutos:[]
    }),
    watch:{
      listadeprodutos(val){
        this.listadeprodutos = val
      }
    },
    components: {
      Subtitulo,
    },
    methods:{
      voltar() {
        const idsubcategoria = this.$route.params.idsubcategoriaprodcd;
        const validacao = Number(idsubcategoria) && Number(idsubcategoria) != 0 ? false : true;


        if(validacao){
          this.$router.go(-2);
        }else{
          this.$router.go(-1);
        }

      },  
      async getProdutos(){
        await this.$http('pdv/getProdutos/'+this.$route.params.idcategoriaprodcd+'/'+this.$route.params.idsubcategoriaprodcd+'?tipousuario=hospede').then((resp) =>{
          this.listadeprodutos = resp.data
        }).catch(error =>{
          console.log(error)
          this.$alertar("warning","Não foi possivel exibir as Categorias","=(")
        })
      },
      direcionar(idproduto){
        const idpdv = this.$route.params.idpdv;
        const idcategoriaprod = this.$route.params.idcategoriaprodcd;
        const idsubcategoria = this.$route.params.idsubcategoriaprodcd;

        const url = `/Hospede/ProdutoMesa/${idpdv}/${idcategoriaprod}/${idsubcategoria}/${idproduto}`;

        this.$router.push(url)
      }
    },
    mounted(){
      this.$http('pdv/getNomeSubCategoria/'+this.$route.params.idsubcategoriaprodcd).then((resp) =>{
        this.subtitulo = resp.data.nomecategoriaprodcd
      }).catch(error =>{
        console.log(error)
        this.$alertar("warning","Não foi pegar o nome da SubCategoria","=(")
      })
      this.$store.dispatch("set_titulo", this.$t('titulocardapio.texto'))
      this.getProdutos()
    },
  }
</script>

<style scoped>
.categoryList{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
  padding:0px!important;
}
.item{
  font-size:1.2em;
  color:rgb(70, 70, 70);
  height: 70px;
  width:330px;
  max-width:90%;
  margin-bottom: 2%;
  margin-right:2%;
  border: 1px solid rgb(156, 156, 156);
  border-radius: 10px;
  padding:5px;
}
.item .colA{
  width: 60px;
  padding-left:2%; 
  padding-right: 2%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item .colA .colAimg{
  border-radius: 100%;
  height:50px;
  max-height:50px;
  width:50px;
  max-width:50px;
}

.item .colB{
  width: 270px;
  padding-left:2%; 
  height:100%;
  word-break: break-all;
}

.item .colB .colBItemTitle{
  font-size:18px;
  line-height: 1.1;
  height: 70%;
}

.item .colB .colBItemPrice{
  display: flex;
  font-size:18px;
  line-height: 1.1;
  height: 30%;
  padding-bottom:10%;
  padding-right: 10px;
  justify-content: flex-end;
  font-weight: bold;
}

</style>