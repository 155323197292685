<template>
  <div>
    <div style="display:flex;justify-content:flex-end;margin-bottom:10px;">
        <v-btn depressed color="error" @click="direcionar()">{{$t('voltar.texto')}}</v-btn>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'BotaoVoltar',
    props:["url"],
    mounted(){

    },
    methods:{
        direcionar(){
            this.$router.push(this.url)
        }
    }
  }
</script>
